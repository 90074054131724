import React from "react";
import { Modal, Input, Radio, Form, Button } from "antd";
import { ruleFee, ruleCycle, ruleTwoDecimal, ruleRequired } from "./rule";

// 完全不受控组件（fully uncontrolled component） 使用时加key
class PageDialog extends React.Component {
  constructor(props) {
    super(props);
    const {
      id,
      fee,
      cycle,
      profit,
      feePercent,
      serviceFee,
      overduePercent,
      status,
    } = props;
    this.state = {
      id,
      fee,
      cycle,
      profit,
      feePercent,
      serviceFee,
      overduePercent,
      status,
    };
  }
  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        return;
      }
      this.props.commit({
        id: this.state.id,
        status: fieldsValue.status,
        cycle: +fieldsValue.cycle,
        fee: parseInt(fieldsValue.fee * 100, 10),
        profit: parseInt(fieldsValue.profit * 100, 10),
        feePercent: parseInt(fieldsValue.feePercent * 100, 10),
        serviceFee: parseInt(fieldsValue.serviceFee * 100, 10),
        overduePercent: parseInt(fieldsValue.overduePercent * 100, 10),
      });
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      fee,
      cycle,
      profit,
      feePercent,
      serviceFee,
      overduePercent,
      status,
    } = this.state;

    return (
      <Modal
        title="New Role"
        visible={this.props.isShow}
        footer={[]}
        onCancel={this.props.handleCancel}
        className={"roles"}
      >
        <Form name="basic" onSubmit={this.onSubmit}>
          <Form.Item label="Amount">
            {getFieldDecorator("fee", { ...ruleFee, initialValue: fee })(
              <Input allowClear key="fee" />
            )}
          </Form.Item>
          <Form.Item label="Deadline configuration">
            {getFieldDecorator("cycle", { ...ruleCycle, initialValue: cycle })(
              <Input allowClear key="cycle" />
            )}
          </Form.Item>
          <Form.Item label="Interest fee">
            {getFieldDecorator("profit", {
              ...ruleTwoDecimal,
              initialValue: profit,
            })(<Input allowClear key="profit" />)}
          </Form.Item>
          <Form.Item label="Daily interest rate">
            {getFieldDecorator("feePercent", {
              ...ruleTwoDecimal,
              initialValue: feePercent,
            })(<Input key="feePercent" suffix="%" />)}
          </Form.Item>
          <Form.Item label="Service fee">
            {getFieldDecorator("serviceFee", {
              ...ruleTwoDecimal,
              initialValue: serviceFee,
            })(<Input allowClear key="serviceFee" />)}
          </Form.Item>
          <Form.Item label="Overdue rate">
            {getFieldDecorator("overduePercent", {
              ...ruleTwoDecimal,
              initialValue: overduePercent,
            })(<Input key="overduePercent" suffix="%" />)}
          </Form.Item>
          <Form.Item label="Configuration status">
            {getFieldDecorator("status", {
              ...ruleRequired,
              initialValue: status,
            })(
              <Radio.Group key="status">
                <Radio value={1}>online</Radio>
                <Radio value={0}>offline</Radio>
              </Radio.Group>
            )}
          </Form.Item>

          <Form.Item style={{ textAlign: "right" }}>
            <Button
              htmlType="button"
              style={{ marginRight: 10 }}
              onClick={this.props.handleCancel}
            >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default Form.create({ name: "register" })(PageDialog);
