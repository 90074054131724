/*eslint-disable*/
import React from 'react';
import { Button,Table,DatePicker,Pagination,message } from 'antd';
import './index.css';
import {Link} from 'react-router-dom'
import axios from 'axios'
import moment from 'moment';
import {withRouter} from 'react-router-dom'
import { exportMethod } from '../../utils/axios'

const { RangePicker } = DatePicker
const dateFormat = 'YYYY-MM-DD'||undefined;
var getSelect = []
function showTotal(total) {
  return `Total ${total} items`;
}

 class OfflineDate extends React.Component{
    constructor(props){
        super(props);
        this.state={
          current:1,
          pageSize:10,
          ishide:false,
          isshow:true,
          boolean:true,
          botboolean:true,
          isDisabled:false,
          btnWord:'export'
        }
      }
    rangePickerChange = (moment, string) => {
    this.setState({
        startDate: string[0],
        endDate: string[1]
    })
    }
    creditChange=(e)=>{
        var value = e.target.value;
        this.setState({
            creditInput:value,
        });
      }
    clearFirst = () => {
    this.setState({
        dates: []
    })
    }
  disabledDate = (current, disabledDate) => {
    // console.log(this.state.data1)
    return moment(current, 'YYYY-MM-DD') < moment(disabledDate, 'YYYY-MM-DD')
  }
  disabledDate = (current) => {
    if (!this.state.dates || this.state.dates.length === 0) {
      return false
    }

    const tooLate = this.state.dates[0] && current.diff(this.state.dates[0], 'days') > 31
    const tooEarly = this.state.dates[0] && this.state.dates[0].diff(current, 'days') > 31

    return tooEarly || tooLate
  }

  calendarChange = (dates) => {
    this.setState({
      dates
    })
  }
      reloadClick=()=>{
        this.setState({
          startDate:undefined,
          endDate:undefined,
          creditInput:'',
          boolean:true,
          botboolean:true
        },()=>{
          this.secondTable()
          this.showtable()
        });
       
      }
      pageChange=(page)=>{
        this.setState({
          current: page
          },()=>{
            this.searchClick()
          })
      }
      showtable=()=>{
        this.setState({
          isshow:false,
          ishide:true
        })
        axios
          .post(`/api/bankPayment/getOffPaymentData`,{

        })
        .then(res=>{
            if (res.data.code === 200) {
              const data1 = [];
              const data = res.data.data
              data1.push(data);
              this.setState({
                tableData: data1,
                boolean: false
              })
            }
           else if(res.data.code ==800){
              this.props.history.push({ pathname: "/"});
            }else{
              // message.error(res.data.msg);
            }
          })
      }
      downExcel=()=>{
        this.state.isDisabled = true;
        this.export();
        let count = 60;
        let timer = setInterval(()=>{
          if(count <=0){
            this.setState({
              btnWord:'exports',
              isDisabled:false
            })
            clearInterval(timer);
          }else{
            count --;
            this.setState({
              btnWord:count +'s'
            })
          }
        },1000)
      }
      export=()=>{
          
        let myObj = {
          method: 'post',
          url:`/api/bankPayment/outExcelOffPaymentData`,
          data:{
            beginTime:this.state.startDate,
            endTime:this.state.endDate,
            id:this.state.creditInput,
          },
          fileName: this.state.startDate+'-'+this.state.endDate,
          }
          exportMethod(myObj)
      }
//页面初始化加载下面的table
      secondTable=()=>{
        axios
          .post(`/api/bankPayment/getOffPaymentDataByOperator`,{

          beginTime:this.state.startDate,
          endTime:this.state.endDate,
          id:this.state.creditInput,
          pageSize: this.state.pageSize,
          current: this.state.current,
        })
        .then(res=>{
            if (res.data.code === 200) {
                const data1 = [];
                const data = res.data.data.resultData
                if(data.length<1){
                  this.setState({
                  botboolean: false
                  })
                }else{
                  for (let i = 0; i < data.length; i++) {
                    data[i].key = i;
                    data1.push(data[i]);
                  }
                }
                this.setState({
                  tableScdData: data1,
                  total: res.data.data.page.total,
                  botboolean: false
                })
            } else if(res.data.code ==800){
                this.props.history.push({ pathname: "/"});
              }else{
                // message.error(res.data.msg);
              }
            })
      }
      onShowSizeChange=(current, pageSize)=>{

        this.setState({
          botboolean:true,
          pageSize: pageSize
        },()=>{
          this.secondTable()
        })
    }
    getselect=()=>{
        axios
        .post(`/api/bankPayment/listOffPayManager`,{
    
      })
      .then(res=>{
        getSelect=[]
        if(res.data.code === 200){
          for(let i=0;i<res.data.data.length;i++ ){
            
              if(i==0){
                getSelect.push(<option value=''>please select one</option> )
                }
                getSelect.push(       
                <option value={res.data.data[i].operate_id}>{res.data.data[i].operator}</option>
            )
            
          this.setState({
            getSelect:getSelect
          })
          }
        }else if(res.data.code ==800){
          this.props.history.push({ pathname: "/"});
        }else{
        }
      })
      }
      componentDidMount() {
        this.getselect()
        this.secondTable()
      }
          //搜索
    searchClick=()=>{ 
//第一个table

      axios
        .post(`/api/bankPayment/getOffPaymentData`,{


      })
      .then(res=>{
        if (res.data.code === 200) {
          const data1 = [];
          const data = res.data.data
          data1.push(data);
          this.setState({
            tableData: data1,
            boolean: false
          })
      } else if(res.data.code ==800){
          this.props.history.push({ pathname: "/"});
        }else{
          // message.error(res.data.msg);
        }
      })


      //第二个table
      axios
        .post(`/api/bankPayment/getOffPaymentDataByOperator`,{

        beginTime:this.state.startDate,
        endTime:this.state.endDate,
        pageSize:this.state.pageSize,
        current: this.state.current,
        id:this.state.creditInput
      })
      .then(res=>{
        if (res.data.code === 200) {
          const data1 = [];
          const data = res.data.data.resultData
          if(data.length<1){
            this.setState({
                botboolean: false
            })
          }else{
            for (let i = 0; i < data.length; i++) {
              data[i].key = i;
              data1.push(data[i]);
            }
          }
          this.setState({
            tableScdData: data1,
            total: res.data.data.page.total,
            botboolean: false
          })
      } else if(res.data.code ==800){
          this.props.history.push({ pathname: "/"});
        }else{
          // message.error(res.data.msg);
        }
      })
    }

    render(){
        const columns1 = [
            {
              title: 'Completed Order',
              dataIndex: 'completedOrder',
              key: 'completedOrder',
            },
            {
              title: 'Clear Num',
              dataIndex: 'clearNum',
              key: 'clearNum',
            },
            {
              title: 'Clear Amount',
              dataIndex: 'clearAmount',
              key: 'clearAmount',
              render: (value)=> {
                var num = value/100;
                num = num + '';
                if(num.indexOf(".")!=-1){
                  return num;
                }else{
                    var str = "";
                    for(var i=num.length- 1,j=1;i>=0;i--,j++){
                        if(j%3==0 && i!=0){//每隔三位加逗号，过滤正好在第一个数字的情况
                            str+=num[i]+",";//加千分位逗号
                            continue;
                        }
                        str+=num[i];//倒着累加数字
                    }
                    return str.split('').reverse().join("");
                }
            }
            }, {
              title: 'Cancel Num',
              dataIndex: 'cancelNum',
              key: 'cancelNum'
            }, {
              title: 'Cancel Amount',
              dataIndex: 'cancelAmount',
              key: 'cancelAmount',
              render: (value)=> {
                var num = value/100;
                num = num + '';
                if(num.indexOf(".")!=-1){
                  return num;
                }else{
                    var str = "";
                    for(var i=num.length- 1,j=1;i>=0;i--,j++){
                        if(j%3==0 && i!=0){//每隔三位加逗号，过滤正好在第一个数字的情况
                            str+=num[i]+",";//加千分位逗号
                            continue;
                        }
                        str+=num[i];//倒着累加数字
                    }
                    return str.split('').reverse().join("");
                }
            }
            }, {
              title: 'Abnormal Num',
              dataIndex: 'abnormalNum',
              key: 'abnormalNum'
            }, {
              title: 'Abnormal Amount',
              dataIndex: 'abnormalAmount',
              key: 'abnormalAmount',
              render: (value)=> {
                var num = value/100;
                num = num + '';
                if(num.indexOf(".")!=-1){
                  return num;
                }else{
                    var str = "";
                    for(var i=num.length- 1,j=1;i>=0;i--,j++){
                        if(j%3==0 && i!=0){//每隔三位加逗号，过滤正好在第一个数字的情况
                            str+=num[i]+",";//加千分位逗号
                            continue;
                        }
                        str+=num[i];//倒着累加数字
                    }
                    return str.split('').reverse().join("");
                }
            }
            },
            
          ];






        const columns = [
            {
              title: 'Operator',  
              dataIndex: 'userName',
              key: 'userName',
            },
            {
              title: 'Completed Order',
              dataIndex: 'completedOrder',
              key: 'completedOrder',
            },
            {
              title: 'Clear Num',
              dataIndex: 'clearNum',
              key: 'clearNum'
            }, {
              title: 'Clear Amount',
              dataIndex: 'clearAmount',
              key: 'clearAmount',
              render: (value)=> {
                var num = value/100;
                num = num + '';
                if(num.indexOf(".")!=-1){
                  return num;
                }else{
                    var str = "";
                    for(var i=num.length- 1,j=1;i>=0;i--,j++){
                        if(j%3==0 && i!=0){//每隔三位加逗号，过滤正好在第一个数字的情况
                            str+=num[i]+",";//加千分位逗号
                            continue;
                        }
                        str+=num[i];//倒着累加数字
                    }
                    return str.split('').reverse().join("");
                }
            }
            }, {
              title: 'Cancel Num',
              dataIndex: 'cancelNum',
              key: 'cancelNum'
            }, {
              title: 'Cancel Amount',
              dataIndex: 'cancelAmount',
              key: 'cancelAmount',
              render: (value)=> {
                var num = value/100;
                num = num + '';
                if(num.indexOf(".")!=-1){
                  return num;
                  }else{
                    var str = "";
                    for(var i=num.length- 1,j=1;i>=0;i--,j++){
                        if(j%3==0 && i!=0){//每隔三位加逗号，过滤正好在第一个数字的情况
                            str+=num[i]+",";//加千分位逗号
                            continue;
                        }
                        str+=num[i];//倒着累加数字
                    }
                    return str.split('').reverse().join("");
                  }
                 
                
            }
            }, {
              title: 'Abnormal Num',
              dataIndex: 'abnormalNum',
              key: 'abnormalNum'
            }, {
              title: 'Abnormal Amount',
              dataIndex: 'abnormalAmount',
              key: 'abnormalAmount',
              render: (value)=> {
                var num = value/100;
                num = num + '';
                if(num.indexOf(".")!=-1){
                  return num;
                  }else{
                    var str = "";
                    for(var i=num.length- 1,j=1;i>=0;i--,j++){
                        if(j%3==0 && i!=0){//每隔三位加逗号，过滤正好在第一个数字的情况
                            str+=num[i]+",";//加千分位逗号
                            continue;
                        }
                        str+=num[i];//倒着累加数字
                    }
                    return str.split('').reverse().join("");
                  }
                 
                
            }
            }, 

          ];
        return(
            <div className="audit_content">
                <div className="bottom"style={{justifyContent:'space-between',marginTop:20}}>
                    <div className="mid_left" style={{width:'30%',marginRight:'6.5%'}}>
                        <span className="description mid_span"  style={{width:105}}>Operator time:</span>
                        <RangePicker
                        onChange={ this.rangePickerChange }
                        disabledDate={ this.disabledDate }
                        style={{ width: '290px' }}
                        placeholder={['Effective date','Effective date']}
                        onCalendarChange={ this.calendarChange }
                        onOpenChange={ this.clearFirst }
                        value={this.state.startDate===undefined||this.state.endDate===undefined||this.state.startDate===""||this.state.endDate===""?null:[moment(this.state.startDate, dateFormat), moment(this.state.endDate, dateFormat)]}
                        />
                    </div>  
                    <div className="mid_left" style={{width:'30%'}}>
                        <span className="description right" style={{width:90}}>Operator:</span>
                        <select className="form_select"  value={this.state.creditInput} onChange={this.creditChange} style={{width: 290,marginLeft:0}}>
                            {getSelect}
                        </select>
                    </div>
                    <div className="mid_right"  style={{display:'flex',alignItems:'flex-end',width:'30%',flexShrink:2,marginLeft:'5%',marginRight:'5%'}}>
                        <Button type="primary" style={{width:170,marginLeft:'15%',marginRight:50,height:40,border:'none'}}  className="common_color" onClick={this.searchClick}>Search</Button>
                        <Button type="primary" style={{width:170,height:40,marginRight:50,border:'none',}} className="common_color_scd" onClick={this.reloadClick}>Refresh</Button>
                        <Button type="primary" style={{width:150,marginRight:20,height:40,backgroundColor:this.state.isDisabled?'':'#EF5A91',border:'none'}} onClick={this.downExcel} disabled={this.state.isDisabled}>{this.state.btnWord}</Button>
                    </div>
             
            </div> 
                <div className="table">
                <div className="content_bot" style={{textAlign:'center',marginBottom:40}}>
                <p className="content_bot_p">Total</p>

                {this.state.isshow&&
                 <Button type="primary" style={{width:170,height:40,border:'none'}} className="common_color_scd" onClick={this.showtable}>Details</Button>
                 }
                {this.state.ishide&&
                   <Table columns={columns1} loading={this.state.boolean} dataSource={this.state.tableData} pagination={false} scroll={{ x: 1300 }}/>
                }
               
                </div>
               
                <Table columns={columns}  loading={this.state.botboolean} dataSource={this.state.tableScdData} pagination={false} />
                </div>
                <Pagination
                  defaultCurrent={1} 
                  onShowSizeChange={this.onShowSizeChange} //pagesize改变时回调函数
                  pageSizeOptions={["10","50","100"]}   //设置pagesize区间
                  current={this.state.current}
                  total={this.state.total} 
                  onChange={this.pageChange} 
                  showTotal={showTotal}
                  pageSize={this.state.pageSize}
                  style={{textAlign:'right',marginTop:20}}
                  // showSizeChanger={true}
                  />
            </div>
        )
    }
}
export default withRouter(OfflineDate)