/*eslint-disable*/
import React from 'react';
import { Input, Button, Table, Modal, Pagination, DatePicker, Tooltip, message } from 'antd';
import moment from 'moment';
import axios from 'axios'
import './index.css';
import $ from 'jquery';
import {withRouter} from 'react-router-dom'
import EventEmitter from '../../../utils/events'

function showTotal(total) {
  return `Total ${total} items`;
}
var getSelect = []
var collectors = []
const { RangePicker } = DatePicker;
const { Search } = Input;
const dateFormat = 'YYYY-MM-DD' || undefined;
class OverduePaid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      from: '',
      to: '',
      current: 1,
      pageSize: 10,
      boolean: true,
      oldOrNew: '',
    };
  }
  //订单输入框
  searchIpt(e) {
    var value = e.target.value;
    this.setState({ searchVal: value.replace(/\s+/g,"") })
  }
   //手机号输入框
   searchIpt1(e) {
    var value = e.target.value;
    this.setState({mobileNum: value.replace(/\s+/g, "")})
}
  //新老客搜索
  oldOrderNew(e) {
    var value = e.target.value;
    this.setState({
      oldOrNew: value,
    });
  }
  //时间控件
  rangeOnChange = (dates, dateStrings) => {
    this.setState({ from: dateStrings[0], to: dateStrings[1] })
  }
      //重置按钮
      reloadClick=(e)=>{
        this.setState({
          fstSelect:'',
          scdSelect:'',
          fouSelect:'',
          searchVal:'',
          mobileNum:'',
          from:undefined,
          to:undefined,
          id:'',
          boolean:true,
          oldOrNew: ''
        },()=>{
          this.onload()
        });
      }
  //页码改变时触发
  pageChange = (page) => {
    this.setState({
      current: page
    }, () => {
      this.searchClick()
    })
  }
  onShowSizeChange = (current, pageSize) => {
    this.setState({
      pageSize: pageSize
    }, () => {
        this.onload()
    })
  }
  
selectCollector=()=>{
  axios
    .post(`/api/remindData/getCollectionUsers`,{
  })
  .then(res=>{
    if(res.data.code === 200){
      collectors=[]
    let data = res.data.data.resultDate
    for(let i=0;i<data.length;i++ ){
      collectors.push(       
        <option data-userId={data[i].id} value={data[i].userName}></option>
    )
    // console.log('select',collector)
    this.setState({
      collectors:collectors
    })
    }
    }else if(res.data.code ==800){
      this.props.history.push({ pathname: "/"});
    }
    else{
    }
  })
  
}
//选择collector
selectscdd(e){
  var value = e.target.value;
  var userInfo = $("#iptt").val();
  var getUserId = $("option[value='" + userInfo + "']").data("userid");
  $("input[name='userId']").val(getUserId);
  this.setState({
    scdSelect:value,
    id:getUserId
  });
}
  componentDidMount() {
    this.selectCollector()
    this.onload()
  }
  //页面初始化
  onload = () => {
    axios
        .post(`/api/loanUrge/getCollectionClearOrderList`,{
        pageSize: this.state.pageSize,
        current: this.state.current,
      })
      .then(res => {
        if (res.data.code === 200) {
          const data = [];
          if(res.data.data.resultDate.length<1){
            this.setState({
              boolean: false,
            })
          }else{
            for (let i = 0; i < res.data.data.resultDate.length; i++) {
              let time = res.data.data.resultDate[i].shouldback_time;
              //new Date().getTimezoneOffset() 获取当前时间时间戳   （0 * 60 * 60 * 1000） 0 即为时区
              let east0time = new Date(time).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
              let times = moment(new Date(east0time)).format("YYYY-MM-DD HH:mm:ss");
              let reviewers = res.data.data.resultDate[i].reviewers;
              if (reviewers == null) {
                reviewers = res.data.data.resultDate[i].finalReceiver;
              }
              let collectionTimes = res.data.data.resultDate[i].collectionTime;
              if (collectionTimes != null) {
                collectionTimes = moment(new Date(new Date(collectionTimes).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000))).format("YYYY-MM-DD HH:mm:ss");
              }
              let date2 = new Date();
              let date1 = new Date(times.replace(/-/g, "/"));
              var date3 = date2.getTime() - date1.getTime()
              let days = Math.floor(date3 / (24 * 3600 * 1000))
              let urgePhoneAnswer = res.data.data.resultDate[i].urgePhoneAnswer;
              let urgeResult = res.data.data.resultDate[i].collectionResult;
              let urgeResultDesc;
              if (urgePhoneAnswer != null && urgePhoneAnswer == 1) {
                if (urgeResult == 1) {
                  urgeResultDesc = "promise to repay";
                } else if (urgeResult == 2) {
                  urgeResultDesc = "claim that has paid back";
                } else if (urgeResult == 3) {
                  urgeResultDesc = "no intention to repay";
                } else if (urgeResult == 4) {
                  urgeResultDesc = "not commitment";
                } else if (urgeResult == 5) {
                  urgeResultDesc = "have issue/difficult";
                } else if (urgeResult == 6) {
                  urgeResultDesc = "inform client";
                } else if (urgeResult == 7) {
                  urgeResultDesc = "can't inform client";
                } else if (urgeResult == 8) {
                  urgeResultDesc = "third party promise repayment";
                } else if (urgeResult == 9) {
                  urgeResultDesc = "third party feel disgusted";
                } else if (urgeResult == 10) {
                  urgeResultDesc = "requested callback";
                } else if (urgeResult == 11) {
                  urgeResultDesc = "language barrier";
                } else if (urgeResult == 12) {
                  urgeResultDesc = "ineffective communication";
                } else if (urgeResult == 99) {
                  urgeResultDesc = "others";
                }
              } else {
                if (urgeResult == 1) {
                  urgeResultDesc = "switched off";
                } else if (urgeResult == 2) {
                  urgeResultDesc = "not reachable";
                } else if (urgeResult == 3) {
                  urgeResultDesc = "suspicious fraud";
                } else if (urgeResult == 99) {
                  urgeResultDesc = "others";
                }
              }
              data.push({
                key: i,
                type: res.data.data.resultDate[i].app_code,
                number: res.data.data.resultDate[i].indent_no,
                name: res.data.data.resultDate[i].first_name,
                phone: res.data.data.resultDate[i].mobile,
                bvn: res.data.data.resultDate[i].dentity_card,
                amount: res.data.data.resultDate[i].balance / 100,
                shouldbackFee: res.data.data.resultDate[i].shouldback_fee / 100,
                alsoNeedBacks: res.data.data.resultDate[i].actualback_amt / 100,
                alsoNeedBackp: (res.data.data.resultDate[i].shouldback_fee - res.data.data.resultDate[i].actualback_amt) / 100,
                overdueFee: res.data.data.resultDate[i].overdue_fee/100,
                reduceAmt: res.data.data.resultDate[i].reduce_amt / 100,
                status: res.data.data.resultDate[i].status,
                days: days,
                time: times,
                reminders: reviewers,
                finalReceiver: res.data.data.resultDate[i].finalReceiver,
                times: res.data.data.resultDate[i].repayment_count,
                collectionDetails: res.data.data.resultDate[i].collectionDetails,
                collectionResultDesc: urgeResultDesc,
                collectionTime: collectionTimes,
                id: res.data.data.resultDate[i].id,
                uid: res.data.data.resultDate[i].uid,
                counts: res.data.data.resultDate[i].repayment_count,
              });
            }
          }
          
          this.setState({
            tableData: data,
            length: res.data.data.page.total,
            boolean: false,
          })
        }
        else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {

        }
      })
  }
  text(record){
    window.location='/#/details/index?id='+record.id+'&uid='+record.uid
    let test = [{path: '/details/index',key: `/details/index/${record.id}/${record.uid}`,number: `${record.id}/${record.uid}`}]
    EventEmitter.trigger('btn', test[0])
    EventEmitter.trigger('idanduid', test[0].number)
  }
  //搜索按钮
  searchClick = () => {
    this.setState({
      boolean: true,
    })
    axios
        .post(`/api/loanUrge/getCollectionClearOrderList`,{
        pageSize: this.state.pageSize,
        current: this.state.current,
        indentNo: this.state.searchVal,
        mobile:this.state.mobileNum,
        expireDay: this.state.fstSelect,
        collectionUserId: this.state.id,
        beginTime: this.state.from,
        endTime: this.state.to,
        newOrOld: this.state.oldOrNew,
        collectionUserId:this.state.id,
        expireDay:this.state.fstSelect
      })
      .then(res => {
        if (res.data.code === 200) {
          const data = [];
          if (res.data.data.resultDate.length < 1) {
            this.setState({
              boolean: false,
            })
          } else {
            
            for (let i = 0; i < res.data.data.resultDate.length; i++) {
              let time = res.data.data.resultDate[i].shouldback_time;
              //new Date().getTimezoneOffset() 获取当前时间时间戳   （0 * 60 * 60 * 1000） 0 即为时区
              let east0time = new Date(time).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
              let times = moment(new Date(east0time)).format("YYYY-MM-DD HH:mm:ss");
              let reviewers = res.data.data.resultDate[i].reviewers;
              if (reviewers == null) {
                reviewers = res.data.data.resultDate[i].finalReceiver;
              }
              let collectionTimes = res.data.data.resultDate[i].collectionTime;
              if (collectionTimes != null) {
                collectionTimes = moment(new Date(new Date(collectionTimes).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000))).format("YYYY-MM-DD HH:mm:ss");
              }
              let date2 = new Date();
              let date1 = new Date(times.replace(/-/g, "/"));
              var date3 = date2.getTime() - date1.getTime()
              let days = Math.floor(date3 / (24 * 3600 * 1000))
              let urgePhoneAnswer = res.data.data.resultDate[i].urgePhoneAnswer;
              let urgeResult = res.data.data.resultDate[i].collectionResult;
              let urgeResultDesc;
              if (urgePhoneAnswer != null && urgePhoneAnswer == 1) {
                if (urgeResult == 1) {
                  urgeResultDesc = "promise to repay";
                } else if (urgeResult == 2) {
                  urgeResultDesc = "claim that has paid back";
                } else if (urgeResult == 3) {
                  urgeResultDesc = "no intention to repay";
                } else if (urgeResult == 4) {
                  urgeResultDesc = "not commitment";
                } else if (urgeResult == 5) {
                  urgeResultDesc = "have issue/difficult";
                } else if (urgeResult == 6) {
                  urgeResultDesc = "inform client";
                } else if (urgeResult == 7) {
                  urgeResultDesc = "can't inform client";
                } else if (urgeResult == 8) {
                  urgeResultDesc = "third party promise repayment";
                } else if (urgeResult == 9) {
                  urgeResultDesc = "third party feel disgusted";
                } else if (urgeResult == 10) {
                  urgeResultDesc = "requested callback";
                } else if (urgeResult == 11) {
                  urgeResultDesc = "language barrier";
                } else if (urgeResult == 12) {
                  urgeResultDesc = "ineffective communication";
                } else if (urgeResult == 99) {
                  urgeResultDesc = "others";
                }
              } else {
                if (urgeResult == 1) {
                  urgeResultDesc = "switched off";
                } else if (urgeResult == 2) {
                  urgeResultDesc = "not reachable";
                } else if (urgeResult == 3) {
                  urgeResultDesc = "suspicious fraud";
                } else if (urgeResult == 99) {
                  urgeResultDesc = "others";
                }
              }
              data.push({
                key: i,
                type: res.data.data.resultDate[i].app_code,
                number: res.data.data.resultDate[i].indent_no,
                name: res.data.data.resultDate[i].first_name,
                phone: res.data.data.resultDate[i].mobile,
                bvn: res.data.data.resultDate[i].dentity_card,
                amount: res.data.data.resultDate[i].balance / 100,
                shouldbackFee: res.data.data.resultDate[i].shouldback_fee / 100,
                alsoNeedBacks: res.data.data.resultDate[i].actualback_amt / 100,
                alsoNeedBackp: (res.data.data.resultDate[i].shouldback_fee  - res.data.data.resultDate[i].actualback_amt) / 100,
                overdueFee: res.data.data.resultDate[i].overdue_fee / 100,
                reduceAmt: res.data.data.resultDate[i].reduce_amt / 100,
                status: res.data.data.resultDate[i].status,
                days: days,
                time: times,
                reminders: reviewers,
                finalReceiver: res.data.data.resultDate[i].finalReceiver,
                times: res.data.data.resultDate[i].repayment_count,
                collectionDetails: res.data.data.resultDate[i].collectionDetails,
                collectionResultDesc: urgeResultDesc,
                collectionTime: collectionTimes,
                id: res.data.data.resultDate[i].id,
                uid: res.data.data.resultDate[i].uid,
                counts: res.data.data.resultDate[i].repayment_count,
              });

            }
          }

          this.setState({
            tableData: data,
            length: res.data.data.page.total,
            boolean: false
          })
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        }
        else {

        }
      })
  }
  selectFst(e) {
    var value = e.target.value;
    this.setState({
      fstSelect: value,
    });
  }

  selectFou(e) {
    var value = e.target.value;
    this.setState({
      fouSelect: value,
    });
  }
  selectThd = () => {

  }

  render() {
    const columns = [
      {
        title: 'Order No',
        dataIndex: 'number',
        key: 'number',

      },
      {
        title: 'Customer name',
        dataIndex: 'name',
        key: 'name',
        // render:(text,record) =>  this.collector(record)

      },
      {
        title: 'mobile phone BVN',
        dataIndex: 'phone',
        key: 'phone',

      },
      {
        title: 'Successful repayment count',
        dataIndex: 'counts',
        key: 'counts',

      },
      {
        title: 'Loan amount',
        dataIndex: 'amount',
        key: 'amount',

      },
      {
        title: 'Should repayment date',
        dataIndex: 'time',
        key: 'time',

      },
      {
        title: 'Due amount',
        dataIndex: 'shouldbackFee',
        key: 'shouldbackFee',

      },
      {
        title: 'Repayment amount',
        dataIndex: 'alsoNeedBacks',
        key: 'alsoNeedBacks',

      },
      {
        title: 'Remaining balance',
        dataIndex: 'alsoNeedBackp',
        key: 'alsoNeedBackp',

      },
      {
        title: 'Reduce amount',
        dataIndex: 'reduceAmt',
        key: 'reduceAmt',

      },
      {
        title: 'Overdue amount',
        dataIndex: 'overdueFee',
        key: 'overdueFee',

      },
      {
        title: 'Overdue days',
        dataIndex: 'days',
        key: 'days',

      },
      {
        title: 'Collector',
        dataIndex: 'reminders',
        key: 'reminders',
        width: 100,
      },
      {
        title: 'Last Collector',
        dataIndex: 'finalReceiver',
        key: 'finalReceiver',
      },
      {
        title: 'Last collection time',
        dataIndex: 'collectionTime',
        key: 'collectionTime',

      },
      {
        title: 'Collection detail',
        dataIndex: 'collectionDetails',
        key: 'collectionDetails',
      },
      {
        title: 'Collection Result',
        dataIndex: 'collectionResultDesc',
        key: 'collectionResultDesc',

      },
      {
        title: 'Operation',
        dataIndex: 'operating',
        key: 'operating',
        width: 70,
        id: 'id',
        uid: 'uid',
        // fixed:'right',
        render: (text, record, index) => (<span style={{ display: 'flex', flexDirection: 'column' }}>
          <Button type="primary" style={this.state.detailsbutton} className="detailsbutton common_color" size="small" onClick={() => this.text(record)}>details</Button>
        </span>)
      },
    ];
    return (
      <div className="overduepaid">
        <div className="overduepaid_top">
          <div className="overduepaid_mid">
            <div className="top collection_order_top" style={{paddingLeft:0,justifyContent:'flex-start'}}>
              <div className="top_left" style={{ width: '30%', flexShrink: 1 }}>
                <div className="search">
                <span className="description left">Order No:</span>
                  <Search
                    placeholder="Order No"
                    onSearch={value => console.log(value)}
                    style={{ width: 290 }}
                    value={this.state.searchVal}
                    onChange={this.searchIpt.bind(this)}
                  />
                </div>
              </div>

              <div className="top_mid" style={{ width: '30%', flexShrink: 2, marginLeft: '5%', marginRight: '5%' }}>
                <div className="rangepick">
                  <span className="description mid_span">Statistics Time</span>
                  <RangePicker
                    style={{ width: 290, }}
                    ranges={{
                      Today: [moment(), moment()],
                      'This Month': [moment().startOf('month'), moment().endOf('month')],
                    }}
                    showTime
                    format={dateFormat}
                    onChange={this.rangeOnChange}
                    value={this.state.from === undefined || this.state.to === undefined || this.state.from === "" || this.state.to === "" ? null : [moment(this.state.from, dateFormat), moment(this.state.to, dateFormat)]}
                  />
                </div>
              </div>
              
              <div className="top_right" style={{width:'30%',flexShrink:1}}>
                                <span className="description left">Collector:</span>
                                <input list='browsers' style={{width: 279}} placeholder='all'
                                                className="form_select_mohu" id={this.state.id} id="iptt"
                                                value={this.state.scdSelect} onChange={this.selectscdd.bind(this)}/>
                                            <datalist id="browsers">
                                                {collectors}
                                            </datalist>
                                 </div>
                        </div>
            <div className="overdue_mid_bot">
              <div className="mid_left" style={{ width: '30%' }}>
                <span className="description left">Overdue Grade:</span>
                <select className="form_select" value={this.state.fstSelect} onChange={this.selectFst.bind(this)}>
                  <option value="all">all</option>
                  <option value="1">S1</option>
                  <option value="2">S2</option>
                  <option value="3">M1</option>
                  <option value="4">M2</option>
                  <option value="5">M3</option>
                  <option value="6">M4</option>
                </select>
              </div>
              <div className="top_mid" style={{width: '30%', flexShrink: 2, marginLeft: '5%', marginRight: '5%'}}>
                <span className="description right" style={{width: 120}}>oldOrNew:</span>
                <select className="form_select" value={this.state.oldOrNew}
                        onChange={this.oldOrderNew.bind(this)}>
                  <option value="">all</option>
                  <option value="1">new User</option>
                  <option value="2">old User</option>
                </select>
              </div>
              <div className="top_right" style={{width:'30%',flexShrink:1}}>
                <span className="description left">Phone:</span>
                  <Search
                    placeholder="Phone"
                    onSearch={value => console.log(value)}
                    style={{width: 290}}
                    value={this.state.mobileNum}
                    onChange={this.searchIpt1.bind(this)}
                  />
              </div>
            </div>
            <div className="bottom">
              <Button type="primary" style={{ width: 170, marginRight: 20, height: 40, border: 'none' }} className="common_color" onClick={this.searchClick.bind(this)}>Search</Button>
              <Button type="primary" style={{ width: 170, height: 40,  border: 'none' }} className="common_color_scd" onClick={this.reloadClick}>Refresh</Button>
            </div>
          </div>
        </div>
        <div className="overdue_table">
          <Table columns={columns} dataSource={this.state.tableData} loading={this.state.boolean} onChange={this.handleChange} pagination={false} />
          <Pagination

            showSizeChanger                          //展示改变pagesize样式
            onShowSizeChange={this.onShowSizeChange} //pagesize改变时回调函数
            pageSizeOptions={["10", "50", "100"]}   //设置pagesize区间
            defaultCurrent={1}
            current={this.state.current}
            total={this.state.length}
            onChange={this.pageChange}
            pageSize={this.state.pageSize}
            showTotal={showTotal}
            style={{ textAlign: 'right', marginTop: 20 }}
          />
        </div>
      </div>
    );
  }
}
export default withRouter(OverduePaid)