/*eslint-disable*/
import React from "react";
import {
  Input,
  Button,
  Table,
  Modal,
  Pagination,
  DatePicker,
  Tooltip,
  message,
} from "antd";
import "./index.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import EventEmitter from "../../utils/events";
import moment from "moment";

var getSelect = [];
const { RangePicker } = DatePicker;
const { Search } = Input;
const dateFormat = "YYYY-MM-DD" || undefined;
function showTotal(total) {
  return `Total ${total} items`;
}

class DebitRecord extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fstSelect: "",
      scdSelect: "",
      tableName: "",
      testSelect: "",
      sixSelect: "",
      fouSelect: "",
      searchVal: "",
      current: 1,
      modalscdinput: "",
      modalthdinput: "",
      datepiker: "",
      scddatepiker: "",
      fstinpt: "",
      disval: "",
      adjustdisval: "",
      adddisval: "",
      order_statues: "please select one",
      fsttextarea: "原订单还款超出应还，需抵扣客户其他订单",
      pageSize: 10,
      boolean: false,
      scdtextarea:
        "第三方反馈错(Third-party feedback error, the status was modified, and Amount was added.)",
      thdtextarea: "流水状态正常，未更新订单状态",
      reviewreason: "",
      isShowOperating: true,
      daiKouModle: false,
    };
  }
  onShowSizeChange = (current, pageSize) => {
    this.setState(
      {
        pageSize: pageSize,
      },
      () => {
        this.searchClick();
      }
    );
  };
  //页码改变时触发
  pageChange = (page) => {
    this.setState(
      {
        current: page,
      },
      () => {
        this.searchClick();
      }
    );
  };
  rangeOnChange = (dates, dateStrings) => {
    this.setState({ from: dateStrings[0], to: dateStrings[1] });
  };
  dateonChange = (date, dateString) => {
    this.setState({ datepiker: dateString });
  };
  scddateonChange = (date, dateString) => {
    this.setState({ scddatepiker: dateString });
  };
  handleCancel = () => {
    this.setState({
      fstvisible: false,
      scdvisible: false,
      thdvisible: false,
      disval: "",
      adjustdisval: "",
      adddisval: "",
      datepiker: "",
      scddatepiker: "",
      modalinput: "",
      modalscdinput: "",
      modalthdinput: "",
      fsttextarea: "原订单还款超出应还，需抵扣客户其他订单",
      scdtextarea:
        "第三方反馈错(Third-party feedback error, the status was modified, and Amount was added.)",
      thdtextarea: "流水状态正常，未更新订单状态",
      modalfst: "",
      debitamount: "",
      modalscd: "",
      btn3loading: false,
      btn2loading: false,
    });
  };
  //重置按钮
  reloadClick(e) {
    this.setState({
      fstSelect: "",
      scdSelect: "",
      tableName: "",
      testSelect: "",
      fouSelect: "",
      searchVal: "",
      from: undefined,
      to: undefined,
      boolean: false,
      sixSelect: "",
    },()=>{
        this.searchClick()
    });
  }
  adjust = () => {
    this.setState({
      fstvisible: true,
    });
  };
  onload = () => {
    // this.searchClick()
    axios
      .post(`/api/userRepay/getLoanRepayFlowTableNameList`, {})
      .then((res) => {
        if (res.data.code === 200) {
          getSelect = [
            <option value="r_loan_repay_flow">r_loan_repay_flow</option>,
          ];
          let data = res.data.data;
          for (let i = 0; i < data.length; i++) {
            getSelect.push(<option value={data[i]}>{data[i]}</option>);
            // console.log("select", getSelect);
            this.setState({
              getSelect: getSelect,
            });
          }
        }
      });
  };
  componentDidMount() {
    this.onload();
  }
  text(record) {
    this.setState({
      scdvisible: true,
      adjustdisval: record.id,
      modalfst: record.indentNo,
      scddatepiker: record.created_time,
      debitamount: record.amount / 100,
    });
  }
  //输入框
  searchIpt(e) {
    var value = e.target.value;
    this.setState({ searchVal: value.replace(/\s+/g, "") });
  }
  //选择订单状态
  selectFst = (e) => {
    var value = e.target.value;
    this.setState({
      fstSelect: value,
    });
  };
  selectScd = (e) => {
    var value = e.target.value;
    this.setState({
      scdSelect: value,
    });
  };
  tableName = (e) => {
    var value = e.target.value;
    this.setState({
      tableName: value,
    });
  };
  selectTest(e) {
    var value = e.target.value;
    this.setState({
      testSelect: value,
    });
  }
  selectsix = (e) => {
    var value = e.target.value;
    this.setState({
      sixSelect: value,
    });
  };

  //选择apptype
  selectFou = (e) => {
    var value = e.target.value;
    this.setState({
      fouSelect: value,
    });
  };

  handleData(time) {
    if (!time) {
      return false;
    } else {
      // 大于当前日期不能选 time > moment()
      // 小于当前日期不能选 time < moment().subtract(1, "days")
      // 只能选前7后7 time < moment().subtract(7, "days") || time > moment().add(7, 'd')
      return time > moment();
    }
  }

  todetail = (record) => {
    this.props.history.push({
      pathname: "../../details/index",
      state: { record },
    });
  };

  searchClick = () => {
    this.setState({
      boolean: true,
    });
    axios
      .post(`/api/userRepay/getLoanRepayFlowList`, {
        pageSize: this.state.pageSize,
        current: this.state.current,
        mobile: this.state.searchVal,
        bankNo: this.state.sixSelect,
        indentNo: this.state.fouSelect,
        repayFlowNo: this.state.fstSelect,
        // status:this.state.testSelect,
        beginTime: this.state.from,
        endTime: this.state.to,
        remark: this.state.scdSelect,
        tableName: this.state.tableName,
      })
      .then((res) => {
        if (res.data.code === 200) {
          const data1 = [];
          const data = res.data.data.resultDate;
          if (data.length < 1) {
            this.setState({
              boolean: false,
            });
          } else {
            for (let i = 0; i < data.length; i++) {
              data[i].key = i;
              data1.push(data[i]);
            }
          }
          this.setState({
            tableData: data1,
            total: res.data.data.page.total,
            boolean: false,
          });
          if (
            res.data.data.tableName &&
            res.data.data.tableName.indexOf("r_loan_repay_flow_") == 0
          ) {
            this.setState({
              isShowOperating: false,
            });
          } else {
            this.setState({
              isShowOperating: true,
            });
          }
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
          // message.error(res.data.msg);
        }
      });
  };
  ModalInput = (e) => {
    let value = e.target.value;
    this.setState({
      modalinput: value,
    });
  };
  FstModal = (e) => {
    let value = e.target.value;
    this.setState({
      modalfst: value,
    });
  };
  amountModal = (e) => {
    let value = e.target.value;
    this.setState({
      debitamount: value,
    });
  };
  ModalScd = (e) => {
    let value = e.target.value;
    this.setState({
      modalscd: value,
    });
  };
  ModalScdInput = (e) => {
    let value = e.target.value;
    this.setState({
      modalscdinput: value,
    });
  };
  ModalThdInput = (e) => {
    let value = e.target.value;
    this.setState({
      modalthdinput: value,
    });
  };
  fsttxarea = (e) => {
    let value = e.target.value;
    this.setState({
      fsttextarea: value,
    });
  };
  scdtxarea = (e) => {
    let value = e.target.value;
    this.setState({
      scdtextarea: value,
    });
  };
  thdtxarea = (e) => {
    let value = e.target.value;
    this.setState({
      thdtextarea: value,
    });
  };
  //offine按钮
  offline = (record) => {
    this.setState({
      thdvisible: true,
      adddisval: record.id,
      // modalinput:record.indentNo
    });
  };
  addOk = () => {
    if (this.state.modalscd == "") {
      message.error("原订单号不能为空");
      return;
    } else if (this.state.thdtextarea == "") {
      message.error("备注不能为空");
      return;
    }
    this.setState({
      btn3loading: true,
    });
    axios
      .post(`/api/userRepay/addLoanRepayFlowForRecord`, {
        id: this.state.adddisval,
        indentNo: this.state.modalscd,
        remark: this.state.thdtextarea,
      })
      .then((res) => {
        if (res.data.code === 200) {
          message.success(res.data.msg);
          this.setState({
            thdvisible: false,
            thdtextarea: "",
            modalscd: "",
            btn3loading: false,
          });
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
          message.error(res.data.msg);
          this.setState({
            thdvisible: false,
            thdtextarea: "",
            modalscd: "",
            btn3loading: false,
          });
        }
      });
    this.searchClick();
  };
  adjustOk = () => {
    if (this.state.modalfst == "") {
      message.error("原订单号不能为空");
      return;
    } else if (this.state.scddatepiker == "") {
      message.error("还款时间不能为空");
      return;
    } else if (this.state.debitamount == "") {
      message.error("代扣流水金额不能为空");
      return;
    } else if (this.state.scdtextarea == "") {
      message.error("备注不能为空");
      return;
    }
    this.setState({
      btn2loading: true,
    });
    axios
      .post(`/api/userRepay/updateLoanRepayFlowStatus`, {
        id: this.state.adjustdisval,
        tableName: this.state.tableName,
        repayTime: this.state.scddatepiker,
        indentNo: this.state.modalfst,
        remark: this.state.scdtextarea,
        amount: this.state.debitamount,
      })
      .then((res) => {
        if (res.data.code === 200) {
          message.success(res.data.msg);
          this.setState({
            scdvisible: false,
            modalfst: "",
            scddatepiker: "",
            scdtextarea:
              "第三方反馈错(Third-party feedback error, the status was modified, and Amount was added.)",
            btn2loading: false,
            debitamount: "",
          });
          this.searchClick();
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
          message.error(res.data.msg);
          this.setState({
            scdvisible: false,
            modalfst: "",
            scddatepiker: "",
            scdtextarea:
              "第三方反馈错(Third-party feedback error, the status was modified, and Amount was added.)",
            btn2loading: false,
            debitamount: "",
          });
        }
      });
    this.searchClick();
  };
  handleOk = () => {
    if (this.state.modalscdinput == "") {
      message.error("原订单号不能为空");
      return;
    } else if (this.state.modalthdinput == "") {
      message.error("现订单号不能为空");
      return;
    } else if (this.state.datepiker == "") {
      message.error("还款时间不能为空");
      return;
    } else if (this.state.fsttextarea == "") {
      message.error("备注不能为空");
      return;
    }
    this.setState({
      btn2loading: true,
    });
    axios
      .post(`/api/userRepay/updateLoanRepayFlow`, {
        // loanId: this.state.disval,
        repayTime: this.state.datepiker,
        indentNo: this.state.modalinput,
        indentNoNew: this.state.modalthdinput,
        money: this.state.modalscdinput,
        remark: this.state.fsttextarea,
      })
      .then((res) => {
        if (res.data.code === 200) {
          message.success(res.data.msg);
          this.setState({
            fstvisible: false,
            datepiker: "",
            modalinput: "",
            modalscdinput: "",
            modalthdinput: "",
            fsttextarea: "",
            btn2loading: false,
          });
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
          message.error(res.data.msg);
          this.setState({
            fstvisible: false,
            datepiker: "",
            modalinput: "",
            modalscdinput: "",
            modalthdinput: "",
            fsttextarea: "",
            btn2loading: false,
          });
        }
      });
    this.searchClick();
  };
  handleDaiKouRule = () => {
    this.setState({
      daiKouModle: true,
    });
  };
  handleDaiKouOk = () => {
    this.setState({
      daiKouModle: false,
    });
  };
  handleDaiKouCancel = () => {
    this.setState({
      daiKouModle: false,
    });
  };

  render() {
    const columns = [
      {
        title: "流水号",
        dataIndex: "transaction_no",
        key: "transaction_no",
      },
      {
        title: "代扣金额",
        dataIndex: "amount",
        key: "amount",
        render: (value) => {
          var num = value / 100;
          num = num + "";
          if (num.indexOf(".") != -1) {
            return num;
          } else {
            var str = "";
            for (var i = num.length - 1, j = 1; i >= 0; i--, j++) {
              if (j % 3 == 0 && i != 0) {
                //每隔三位加逗号，过滤正好在第一个数字的情况
                str += num[i] + ","; //加千分位逗号
                continue;
              }
              str += num[i]; //倒着累加数字
            }
            return str.split("").reverse().join("");
          }
        },
      },

      {
        title: "订单号",
        dataIndex: "indentNo",
        key: "indentNo",
      },
      {
        title: "手机号",
        dataIndex: "mobile",
        key: "mobile",
      },
      //   {
      //     title: "银行账号",
      //     dataIndex: "bankNo",
      //     key: "bankNo",
      //   },
      {
        title: "还款方式",
        dataIndex: "type",
        key: "type",
      },
      {
        title: "状态",
        dataIndex: "status",
        key: "status",
      },
      {
        title: "备注/描述",
        dataIndex: "remark",
        key: "remark",
      },
      {
        title: "操作时间",
        dataIndex: "created_time",
        key: "created_time",
      },

      {
        title: "操作",
        dataIndex: "operating",
        key: "operating",
        width: 70,
        id: "id",
        uid: "uid",
        fixed: "right",
        render: (text, record, index) => (
          <span style={{ display: "flex", flexDirection: "row" }}>
            <Button
              type="primary"
              style={{
                display: record.status == "支付成功 " ? "none" : "block",
              }}
              className="detailsbutton common_color"
              size="small"
              onClick={() => this.text(record)}
            >
              修改
            </Button>
          </span>
        ),
      },
    ];
    return (
      <div className="credit_order">
        <div className="myorder_content">
          <div className="flutter">
            <div className="top">
              <div className="top_left" style={{ width: "20%", flexShrink: 1 }}>
                <select
                  className="form_select"
                  id="tableName"
                  value={this.state.tableName}
                  style={{ width: 292, marginRight: 12 }}
                  onChange={this.tableName}
                >
                  {getSelect}
                </select>
                {/* <div className="search">
                  <Search
                    placeholder="手机号"
                    onSearch={value => console.log(value)}
                    style={{width: 220}}
                    value={this.state.searchVal}
                    onChange={this.searchIpt.bind(this)}
                  />
                </div> */}
              </div>

              <div
                className="top_right"
                style={{ width: "20%", flexShrink: 2 }}
              >
                <input
                  type="text"
                  placeholder="流水号"
                  value={this.state.fstSelect}
                  onChange={this.selectFst}
                  className="modal_input"
                  style={{ width: 220, marginLeft: 0 }}
                />
                {/* <input
                  type="text"
                  placeholder="银行账号"
                  value={this.state.sixSelect}
                  onChange={this.selectsix}
                  className="modal_input"
                  style={{ width: 220, marginLeft: 0 }}
                /> */}
              </div>

              <div className="top_mid" style={{ width: "20%", flexShrink: 1 }}>
                <div className="rangepick">
                  <RangePicker
                    style={{ width: 220 }}
                    ranges={{
                      Today: [moment(), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                    }}
                    showTime
                    format={dateFormat}
                    onChange={this.rangeOnChange}
                    value={
                      this.state.from === undefined ||
                      this.state.to === undefined ||
                      this.state.from === "" ||
                      this.state.to === ""
                        ? null
                        : [
                            moment(this.state.from, dateFormat),
                            moment(this.state.to, dateFormat),
                          ]
                    }
                  />
                </div>
              </div>

              <div className="mid_right" style={{ width: "20%" }}>
                <input
                  type="text"
                  placeholder="订单号"
                  value={this.state.fouSelect}
                  onChange={this.selectFou}
                  className="modal_input"
                  style={{ width: 220, marginLeft: 0 }}
                />
              </div>

              <div className="mid_left" style={{ width: "20%" }}></div>
            </div>

            <div className="order_mid" style={{ justifyContent: "center" }}>
              {/* <div className="mid_left" style={{ width: "20%" }}>
                <input
                  type="text"
                  placeholder="备注/描述"
                  value={this.state.scdSelect}
                  onChange={this.selectScd}
                  className="modal_input"
                  style={{ width: 220, marginLeft: 0 }}
                />
              </div> */}

              {/* <div className="mid_left" style={{ width: "20%" }}></div> */}

              <div className="bottom">
                <Button
                  type="primary"
                  style={{
                    width: 170,
                    marginRight: 20,
                    height: 40,
                    border: "none",
                  }}
                  className="common_color"
                  onClick={this.searchClick.bind(this)}
                >
                  搜索
                </Button>
                <Button
                  type="primary"
                  style={{
                    width: 170,
                    marginRight: 20,
                    height: 40,
                    border: "none",
                  }}
                  className="common_scd"
                  onClick={this.reloadClick.bind(this)}
                >
                  刷新
                </Button>
                {/* <Button type="primary" style={{width:170,height:40,backgroundColor:'#1CA9BA',border:'none'}} onClick={this.adjust.bind(this)}>调整</Button> */}
              </div>
            </div>
          </div>
        </div>
        {/* <Button onClick={this.handleDaiKouRule}>代扣规则优化</Button> */}
        <Table
          columns={columns}
          dataSource={this.state.tableData}
          loading={this.state.boolean}
          onChange={this.handleChange}
          pagination={false}
        />
        <Modal
          title="Tip"
          visible={this.state.daiKouModle}
          onOk={this.handleDaiKouOk}
          onCancel={this.handleDaiKouCancel}
          footer={[
            null,
            <Button key="submit" type="primary" onClick={this.handleDaiKouOk}>
              Ok
            </Button>,
          ]}
        >
          Please manual withholding withholding again after xx-xx-xx！
        </Modal>

        <Modal
          title="修改"
          visible={this.state.scdvisible}
          onOk={this.adjustOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={this.state.btn2loading}
              onClick={this.adjustOk}
            >
              Confirm
            </Button>,
          ]}
        >
          <input value={this.state.adjustdisval} style={{ display: "none" }} />
          <div className="model_last">
            <span className="long record_span">还到的订单号</span>
            <input
              className="modal_input"
              style={{ marginLeft: "10%", width: 290 }}
              type="text"
              value={this.state.modalfst}
              onChange={this.FstModal}
            />
          </div>
          <div className="model_last">
            <span className="long record_span">代扣流水金额</span>
            <input
              className="modal_input"
              style={{ marginLeft: "10%", width: 290 }}
              type="text"
              value={this.state.debitamount}
              onChange={this.amountModal}
            />
          </div>
          <div className="model_last">
            <span className="long record_span">还款时间:</span>
            <DatePicker
              style={{ marginLeft: "10%", width: 290 }}
              disabledDate={this.handleData}
              value={
                this.state.scddatepiker === undefined ||
                this.state.scddatepiker === ""
                  ? null
                  : moment(this.state.scddatepiker, dateFormat)
              }
              onChange={this.scddateonChange}
            />
          </div>
          <div className="model_last">
            <span className="long record_span">备注:</span>
            <textarea
              style={{ marginLeft: "10%" }}
              className="modal_textarea_new"
              placeholder="第三方反馈错(Third-party feedback error, the status was modified, and Amount was added.)"
              value={this.state.scdtextarea}
              onChange={this.scdtxarea}
            />
          </div>
        </Modal>

        <Pagination
          showQuickJumper
          defaultCurrent={1}
          current={this.state.current}
          total={this.state.total}
          onChange={this.pageChange}
          showTotal={showTotal}
          style={{ textAlign: "right", marginTop: 20 }}
          pageSize={this.state.pageSize}
          showSizeChanger={true}
          onShowSizeChange={this.onShowSizeChange} //pagesize改变时回调函数
          pageSizeOptions={["10", "50", "100"]} //设置pagesize区间
        />
      </div>
    );
  }
}
export default withRouter(DebitRecord);
