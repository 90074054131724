/*eslint-disable*/
import React from 'react';
import { Input,Button,Table,Modal,Pagination,DatePicker,Tooltip,message } from 'antd';
import './index.css';
import axios from 'axios'
import {Link} from 'react-router-dom'
import moment from 'moment';
import EventEmitter from '../../../utils/events'
import {withRouter} from 'react-router-dom'

const { RangePicker } = DatePicker;
  const { Search } = Input;
  const dateFormat = 'YYYY-MM-DD'||undefined;
var getSelect = []
var credit = []
var Select = []
var whitchshow = []

  function showTotal(total) {
      return `Total ${total} items`;
    }
 class OrderManagement extends React.Component {
    constructor(props){
      super(props);
      this.state={
        fstSelect:2,
        scdSelect:'',
        thdSelect:2,
        fouSelect:'',
        fstinpt:'',
        thdinpt:'',
        searchVal:'',
        mobileNum:'',
        current:1,
        length:0,
        boolean:true,
        selectedRowKeys:[],
        tableData:[],
        fstvisible: false,
        scdvisible: false,
        isToggleOn: true,
        isToggleOff:false,
        thdvisible: false,
        order_statues:'please select one',
        from:'',
        to:'',
        rangvalue:'',
        fsttextarea:'',
        modalfstSelect:0,
        modalscdSelect:'',
        inptdis: {display: "none"},
        reviewbutton: {display: "block"},
        reassignbutton: {display: "block" },
        detailsbutton: {display: "block"},
        reviewreason:'',
        pageSize:10,
        modify:false
      }
      this.whichActive = this.whichActive.bind(this);
      this.whichotherActive = this.whichotherActive.bind(this);
    }
    //选择table触发
    handleChange = (pagination, filters, sorter) => {
      this.setState({
    
      });
    };
    rangeOnChange=(dates, dateStrings)=>{
      this.setState({ from: dateStrings[0],to:dateStrings[1] })
    }
    whichActive() {
      this.setState({
        isToggleOn: true,
        isToggleOff:false,
        thdSelect:2
      },()=>{
        this.searchClick()
      });
    }
    whichotherActive() {
      this.setState({
        isToggleOff: true,
        isToggleOn:false,
        thdSelect:1
      },()=>{
        this.searchClick()
      });
    }
    //页码改变时触发
    pageChange=(page)=> { 
      
    this.setState({
      current: page
      },()=>{
        this.searchClick()
      })
    }

    onShowSizeChange=(current, pageSize)=>{

      this.setState({
        pageSize: pageSize
      },()=>{
        this.onload()
      })
  }

    //重置按钮
    reloadClick(e){
      this.setState({
        fstSelect:2,
        scdSelect:'',
        thdSelect:2,
        fouSelect:'',
        returnSelect:'',
        searchVal:'',
        mobileNum:'',
        from:undefined,
        to:undefined,
        boolean:true,
      },()=>{
        this.onload()
      });
     

    }

    //获取select
    select=()=>{
      axios
        .post(`/api/system/getManagerListByType`,{

        roleType : 2,
        roleManagerType : 12,
      })
      .then(res=>{
        if(res.data.code === 200){
        getSelect=[]
        let data = res.data.data
        for(let i=0;i<res.data.data.length;i++ ){
          if(i==0){getSelect.push(<option value='0'>Assigned People(all)</option> )
          }
          getSelect.push(       
            <option id={data} value={data[i].id}>{data[i].userName}</option>
        )
        // console.log('select',getSelect)
        this.setState({
          getSelect:getSelect
        })
        }
        }else if(res.data.code ==800){
          // this.props.history.push({ pathname: "/"});
        }
      })

      axios
      .post(`/api/common/getCommonListByCode`,{
        code:'NO_PASS_RETURN'
    })
    .then(res=>{
      credit=[]
      if(res.data.code === 200){
        let data = res.data
        for(let i=0;i<res.data.data.length;i++ ){
          if(i==0){
            credit.push(<option value=''>Please select one</option> )
          }
          credit.push(       
            <option value={res.data.data[i].name}>{res.data.data[i].name}</option>
        )
        this.setState({
          credit:credit
        })
        }
      }else if(res.data.code ==800){
        this.props.history.push({ pathname: "/"});
      }else{
      }
    })
    }
    changeTime =(params)=> {
      let time1 = params.time
      let time2 = params.when
      let date1=new Date(time1);
      let date2=new Date(time2);
      var date3=date2.getTime()-date1.getTime() 
      let days=Math.floor(date3/(24*3600*1000))
      var leave1=date3%(24*3600*1000)    //计算天数后剩余的毫秒数
      let hours=Math.floor(leave1/(3600*1000))
      var leave2=leave1%(3600*1000)        //计算小时数后剩余的毫秒数
      let minutes=Math.floor(leave2/(60*1000))
      if(days==0&&hours<4){
        return <span style={{color:'black'}}>{params.sure}</span>
      }else if(days==0&&hours>=4&&hours<8){
        return <span style={{color:'purple'}}>{params.sure}</span>
      }else if(days==0&&hours>=8){
        return <span style={{color:'red'}}>{params.sure}</span>
      }else{
        return <span style={{color:'red'}}>{params.sure}</span>
      }
     
    }
    changeRisk =(params)=>{
      if(params.risk == 15){
        return <span style={{color:'red'}}>reject</span>
      }else if(params.risk == -1){
        return <span style={{color:'red'}}>reject</span>
      }else if(params.risk == -2){
        return <span style={{color:'red'}}>reject</span>
      }else if(params.risk == 1){
        return <span style={{color:'blue'}}>pass</span>
      }else if(params.risk == 2){
        return <span style={{color:'blue'}}>pass</span>
      }else if(params.risk == 3){
        return <span style={{color:'orange'}}>warning</span>
      }else{
        return
      }
    }


  componentDidMount(){
      this.select()
      this.onload()
      this.getselect()
  }
  getselect=()=>{
    axios
    .post(`/api/appshadow/findAppShadowList`,{

  })
  .then(res=>{
     Select=[]
    if(res.data.code === 200){
      for(let i=0;i<res.data.data.list.length;i++ ){
        
          if(i==0){
            Select.push(<option value=''>please select one</option> )
            }
            Select.push(       
            <option value={res.data.data.list[i].appCode}>{res.data.data.list[i].appCode}</option>
        )
        
      this.setState({
        Select:Select
      })
      }
    }else if(res.data.code ==800){
      this.props.history.push({ pathname: "/"});
    }else{
    }
  })
  }
  //页面初始化
  onload=()=>{
    axios
      .post(`/api/loan/credit/getLoanFrontAuditList`,{
        orderStatus:1,
        pageSize: this.state.pageSize,
        current: this.state.current,
    })
    .then(res=>{
      if(res.data.code === 200){
        const data = [];
        for (let i = 0; i < res.data.data.resultDate.length; i++) {
          let newtime = res.data.data.resultDate[i].auditUserTime;
             //new Date().getTimezoneOffset() 获取当前时间时间戳   （0 * 60 * 60 * 1000） 0 即为时区
             let timeaudit= new Date(newtime).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
            let auditimes=moment(new Date(timeaudit)).format("YYYY-MM-DD HH:mm:ss");
          if(res.data.data.resultDate[i].auditUserTime == null){
            auditimes = ''
          }else{
            auditimes = auditimes
          }
          let time=res.data.data.resultDate[i].createdTime;
         //new Date().getTimezoneOffset() 获取当前时间时间戳   （0 * 60 * 60 * 1000） 0 即为时区
         let east0time = new Date(time).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
         let times=moment(new Date(east0time)).format("YYYY-MM-DD HH:mm:ss");
          let state=res.data.data.resultDate[i].status;
          let date1=new Date();
          let date2=new Date();
          if(state==2){
            date1=new Date(times.replace(/-/g,"/"));  //订单创建时间
          }else{
            date1=new Date(times.replace(/-/g,"/"));  //订单创建时间
            date2=new Date(auditimes.replace(/-/g,"/"));  //人工复审时间
          }
          var date3=date2.getTime()-date1.getTime() 
              let days=Math.floor(date3/(24*3600*1000))
              //计算出小时数
              var leave1=date3%(24*3600*1000)    //计算天数后剩余的毫秒数
              let hours=Math.floor(leave1/(3600*1000))
              //计算相差分钟数
              var leave2=leave1%(3600*1000)        //计算小时数后剩余的毫秒数
              let minutes=Math.floor(leave2/(60*1000))
              if(days==0&&hours<4){
                    // return "<font color='black'>"+hours+"h"+minutes+"m</font>"
                      date3=hours+"h"+minutes+"m"
              }else if(days==0&&hours>=4&&hours<8){
                    // return "<font color='purple'>"+hours+"h"+minutes+"m</font>"
                      date3=hours+"h"+minutes+"m"
                }else if(days==0&&hours>=8){
                    // return "<font color='red'>"+hours+"h"+minutes+"m</font>"
                      date3=hours+"h"+minutes+"m"
                }else{
                    // return "<font color='red'>"+days+"d"+hours+"h"+minutes+"m</font>"
                      date3=+days+"d"+hours+"h"+minutes+"m";
                }
                let status = res.data.data.resultDate[i].status
                let statusDescription = ''
                if(status == 2){
                  statusDescription = 'Pending Manual Review'
                  this.setState({
                    reviewbutton: {display: "block"},
                    reassignbutton: {display: "block" },
                    detailsbutton: {display: "block"},
                  })
                }else if(status == 32){
                  statusDescription = 'Reject by Manual Review'
                  this.setState({
                    reviewbutton: {display: "none"},
                    reassignbutton: {display: "none" },
                    detailsbutton: {display: "block"},
                  })
                }else if(status == 33){
                  statusDescription = 'Return by Manual Review'
                  this.setState({
                    reviewbutton: {display: "none"},
                    reassignbutton: {display: "none" },
                    detailsbutton: {display: "block"},
                  })
                }else if(status == 1){
                  statusDescription = 'Pending Auto Review'
                  this.setState({
                    reviewbutton: {display: "none"},
                    reassignbutton: {display: "none" },
                    detailsbutton: {display: "block"},
                  })
                }
               
                  data.push({
                    key: i,
                    type:res.data.data.resultDate[i].appCode,
                    number: res.data.data.resultDate[i].indentNo,
                    name:res.data.data.resultDate[i].firstName,
                    phone: res.data.data.resultDate[i].mobile,
                    bvn: res.data.data.resultDate[i].dentityCard,
                    amount: res.data.data.resultDate[i].balance/100,
                    days: res.data.data.resultDate[i].cycle,
                    time: res.data.data.resultDate[i].createdTime,
                    times:res.data.data.resultDate[i].repaymentCount,
                    state:statusDescription,
                    risk:res.data.data.resultDate[i].auditRiskStatus,
                    people:res.data.data.resultDate[i].reviewers,
                    when:res.data.data.resultDate[i].auditUserTime,
                    detail:res.data.data.resultDate[i].auditDetail,
                    reson:res.data.data.resultDate[i].auditUserReson,
                    now:res.data.data.resultDate[i].creditUserName,
                    sure:date3,
                    id:res.data.data.resultDate[i].id,
                    uid:res.data.data.resultDate[i].uid,
                  });
                }
            this.setState({
              tableData:data,
              length:res.data.data.page.total,
              boolean:false,
              fsttextarea:'',
              modalscdSelect:''
            })

      }
      else if(res.data.code ==800){
        this.props.history.push({ pathname: "/"});
      }
      else{

      }
    })
  }

   //订单输入框
    searchIpt(e){
      var value = e.target.value;
      this.setState({ searchVal: value.replace(/\s+/g,"") })
    }
      //手机输入框
      searchIpt1(e){
        var value = e.target.value;
        this.setState({ mobileNum: value.replace(/\s+/g,"") })
      }
    //选择订单状态
    selectFst(e){
      var value = e.target.value;
      this.setState({
        fstSelect:value,
      });
    }
    //选择分配人员
    selectScd(e){
      var value = e.target.value;
      this.setState({
        scdSelect:value,
      });
    }
    //选择审核时效
    selectThd(e){
      var value = e.target.value;
      this.setState({
        returnSelect:value,
      });
    }
    //选择apptype
    selectFou(e){
      var value = e.target.value;
      this.setState({
        fouSelect:value,
      });
    }
    
    //
    text(record,item){
      // var tempwindow=window.open('_blank')
      window.location='/#/details/index?id='+record.id+'&uid='+record.uid
      // console.log(window.location)
      let test = [{path: '/details/index',key: `/details/index/${record.id}/${record.uid}`,number: `${record.id}/${record.uid}`}]
      EventEmitter.trigger('btn', test[0])
      EventEmitter.trigger('idanduid', test[0].number)
    }
    //搜索
    searchClick=()=>{
      this.setState({
        boolean:true,
      })
      axios
    .post(`/api/loan/credit/getLoanFrontAuditList`,{
      orderStatus:1,
      pageSize: this.state.pageSize,
      current: this.state.current,
      indentNo:this.state.searchVal,
      mobile:this.state.mobileNum,
      status:this.state.fstSelect,
      creditUserId:this.state.scdSelect,
      orderByCreateTime:this.state.thdSelect,
      appCode:this.state.fouSelect,
      beginTime:this.state.from,
      endTime:this.state.to,
      returnMsg:this.state.returnSelect
    })
    .then(res=>{
      if(res.data.code === 200){
        const data = [];
        if(res.data.data.resultDate.length<1){
          // alert(123)
          this.setState({
            boolean:false,
           
          })
        }else{
          for (let i = 0; i < res.data.data.resultDate.length; i++) {
            let newtime = res.data.data.resultDate[i].auditUserTime;
            //new Date().getTimezoneOffset() 获取当前时间时间戳   （0 * 60 * 60 * 1000） 0 即为时区
            let timeaudit= new Date(newtime).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
            let auditimes=moment(new Date(timeaudit)).format("YYYY-MM-DD HH:mm:ss");
            if(res.data.data.resultDate[i].auditUserTime == null){
              auditimes = ''
            }else{
              auditimes = auditimes
            }
            let time=res.data.data.resultDate[i].createdTime;
           //new Date().getTimezoneOffset() 获取当前时间时间戳   （0 * 60 * 60 * 1000） 0 即为时区
           let east0time = new Date(time).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
           let times=moment(new Date(east0time)).format("YYYY-MM-DD HH:mm:ss");
          
           
            let state=res.data.data.resultDate[i].status;
            let date1=new Date();
            let date2=new Date();
            if(state==2){
              date1=new Date(times.replace(/-/g,"/"));  //订单创建时间
            }else{
              date1=new Date(times.replace(/-/g,"/"));  //订单创建时间
              date2=new Date(auditimes.replace(/-/g,"/"));  //人工复审时间
            }
           var date3=date2.getTime()-date1.getTime() 
                let days=Math.floor(date3/(24*3600*1000))
                //计算出小时数
                var leave1=date3%(24*3600*1000)    //计算天数后剩余的毫秒数
                let hours=Math.floor(leave1/(3600*1000))
                //计算相差分钟数
                var leave2=leave1%(3600*1000)        //计算小时数后剩余的毫秒数
                let minutes=Math.floor(leave2/(60*1000))
                if(days==0&&hours<4){
                      // return "<font color='black'>"+hours+"h"+minutes+"m</font>"
                        date3=hours+"h"+minutes+"m"
                }else if(days==0&&hours>=4&&hours<8){
                      // return "<font color='purple'>"+hours+"h"+minutes+"m</font>"
                        date3=hours+"h"+minutes+"m"
                  }else if(days==0&&hours>=8){
                      // return "<font color='red'>"+hours+"h"+minutes+"m</font>"
                        date3=hours+"h"+minutes+"m"
                  }else{
                      // return "<font color='red'>"+days+"d"+hours+"h"+minutes+"m</font>"
                        date3=+days+"d"+hours+"h"+minutes+"m";
                  }
                  let status = res.data.data.resultDate[i].status
                  let statusDescription = ''
                  if(status == 2){
                    statusDescription = 'Pending Manual Review'
                    this.setState({
                      reviewbutton: {display: "block"},
                      reassignbutton: {display: "block" },
                      detailsbutton: {display: "block"},
                    })
                  }else if(status == 32){
                    statusDescription = 'Reject by Manual Review'
                    this.setState({
                      reviewbutton: {display: "none"},
                      reassignbutton: {display: "none" },
                      detailsbutton: {display: "block"},
                    })
                  }else if(status == 33){
                    statusDescription = 'Return by Manual Review'
                    this.setState({
                      reviewbutton: {display: "none"},
                      reassignbutton: {display: "none" },
                      detailsbutton: {display: "block"},
                    })
                  }else if(status == 1){
                    statusDescription = 'Pending Auto Review'
                    this.setState({
                      reviewbutton: {display: "none"},
                      reassignbutton: {display: "none" },
                      detailsbutton: {display: "block"},
                    })
                  }
              data.push({
                key: i,
                    type:res.data.data.resultDate[i].appCode,
                    number: res.data.data.resultDate[i].indentNo,
                    name:res.data.data.resultDate[i].firstName,
                    phone: res.data.data.resultDate[i].mobile,
                    bvn: res.data.data.resultDate[i].dentityCard,
                    amount: res.data.data.resultDate[i].balance/100,
                    days: res.data.data.resultDate[i].cycle,
                    time: res.data.data.resultDate[i].createdTime,
                    times:res.data.data.resultDate[i].repaymentCount,
                    state:statusDescription,
                    risk:res.data.data.resultDate[i].auditRiskStatus,
                    people:res.data.data.resultDate[i].reviewers,
                    when:res.data.data.resultDate[i].auditUserTime,
                    detail:res.data.data.resultDate[i].auditDetail,
                    reson:res.data.data.resultDate[i].auditUserReson,
                    now:res.data.data.resultDate[i].creditUserName,
                    sure:date3,
                    id:res.data.data.resultDate[i].id,
                    uid:res.data.data.resultDate[i].uid,
              });
              
            } 
        }
        this.setState({
          tableData:data,
          length:res.data.data.page.total,
          boolean:false,
        })  
      }else if(res.data.code ==800){
        this.props.history.push({ pathname: "/"});
      }
      else{

      }
    })
    }

    //控制审核按钮model
    showFstModal = (record) => {
      var id = record.id
      this.setState({
        fstinpt:id,
        fstvisible: true,
      });
      // this.gradeChange()
    };

    //第一个textarea
    fsttxarea=(e)=>{
      let value = e.target.value;
      this.setState({ fsttextarea: value })
    }

     //控制重新分配按钮model
      showScdModal = (record) => {
        var id = record.id
        this.setState({
        thdinpt:id,
        scdvisible: true,
        });
      };
      //第个modal提交按钮
  
    fsthandleOk = (e) => {
     
      if(this.state.fsttextarea==''){
        alert("Review details can't be empty.")
        return false
      }
      else if(this.state.modalfstSelect==0){
        alert("Review status can't be empty.")
        return false
      }
      this.setState({
        modify:true
      })
      var objS = document.getElementById("modal_fstid");
      var grade = objS.options[objS.selectedIndex].value;
      // console.log(e);//
     
      if(this.state.modalfstSelect == 1){
        axios
          .post(`/api/loan/credit/passAudit`,{

          id:this.state.fstinpt,
          remark:this.state.fsttextarea,
        })
        .then(res=>{
          let data = res.data
          if(res.data.code === 200){

            this.setState({
              modify:false,
              fstvisible: false,
              scdvisible: false,
              fsttextarea:'',
              modalfstSelect:'',
              fstSelect:2,
              order_statues:'please select one',
              inptdis: {
                display:"none",     
            }
              })
            this.reloadClick()
            message.success(res.data.msg)
          }else if(res.data.code ==800){
            this.props.history.push({ pathname: "/"});
          }else if(res.data.code ===405){
           message.error(res.data.msg)
           this.setState({
            fsttextarea:'',
            modify:false,
            modalfstSelect:'',
            fstSelect:2,
            order_statues:'please select one',
            fstvisible: false,
            scdvisible: false,
            inptdis: {
              display:"none",     
          }
            })
          }

          else{
            message.error(res.data.msg)
            this.reloadClick()
            this.setState({
              modify:false,
              fstvisible: false,
              scdvisible: false,
              })
          }
        })
      }else{
        if(this.state.modalscdSelect==0){
          alert("Reasons can't be empty.")
          return false
        }
        var objS = document.getElementById("modal_fstid");
        axios
          .post(`/api/loan/credit/passAudit`,{

          id:this.state.fstinpt,
          reason:this.state.fsttextarea,
          refuseValue:this.state.reviewreason,
          refuseReason:this.state.modalscdSelect,
        })
        .then(res=>{
          if(res.data.code === 200){
            this.reloadClick()
            this.setState({
              modify:false,
            fsttextarea:'',
            modalfstSelect:'',
            fstvisible: false,
            scdvisible: false,
            fstSelect:2,
            order_statues:'please select one',
            inptdis: {
              display:"none",     
          }
            })
            whitchshow=[]
            grade = 0
            message.success(res.data.msg)
          }else if(res.data.code ==800){
            this.props.history.push({ pathname: "/"});
          }else if(res.data.code ===405){
            message.error(res.data.msg)
           }
          else{
            // alert(123)
            this.setState({
              modify:false,
              fsttextarea:'',
              modalfstSelect:'',
              fstvisible: false,
              scdvisible: false,
              fstSelect:2,
              order_statues:'please select one',
              inptdis: {
                display:"none",   
            }
              })
              whitchshow=[]
              grade = 0
          }
        })
      }
    };
    scdhandleOk = (e) => {
      axios
        .post(`/api/loan/credit/allOcate`,{

        orderId:this.state.thdinpt,
        creditUserId:this.state.modalthdSelect,
      })
      .then(res=>{
        if(res.data.code==200){
          this.onload()
        }else if(res.data.code ==800){
          this.props.history.push({ pathname: "/"});
        }else{
        }
      })
      this.setState({
        scdvisible:false,
        modalthdSelect:'',
        
      })
    }
    thdhandleOk = (e) => {

      let arr = this.state.selectedRowKeys;
      let lastarr = arr.join(',');
      axios
        .post(`/api/loan/credit/allOcate`,{
        orderId:lastarr,
        creditUserId:this.state.modalthdSelect,
      })
      .then(res=>{
        if(res.data.code==200){
          this.onload()
          this.setState({
            selectedRowKeys:''
          })
        }else if(res.data.code ==800){
          this.props.history.push({ pathname: "/"});
        }else{
        }
      })
      this.setState({
        thdvisible: false,
        selectedRowKeys:[],
        modalthdSelect:'',
      });
    }
    handleCancel = e => {
      console.log(e);
      this.setState({
        modalscdSelect:'',
        fstvisible: false,
        scdvisible: false,
        modify:false,
        thdvisible: false,
        fsttextarea:'',
        modalfstSelect:0,
        fstSelect:2,
        order_statues:'please select one',
        inptdis: {display: "none"},
        modalthdSelect:'',
        selectedRowKeys:[]
      });
    };
    // todetail=(record)=>{
      
    //   this.props.history.push({ pathname: "../../details/index",state:{record}});
   
  
    // }
    //modal第一个select
    gradeChange=(e)=>{
      var value = e.target.value;
      var code1 = '';
      this.setState({
        modalfstSelect:value
      })
      var objS = document.getElementById("modal_fstid");
      var grade = objS.options[objS.selectedIndex].value;
      if(grade==0){
        code1='please select one'
        this.setState({
        inptdis: {display:"none",}, 
        order_statues:'please select one'
      }) 
      }else if(grade==1){
        code1='Manual Approved'
        this.setState({
          inptdis: {display:"none",},
          order_statues:'Manual Approved'
        }) 
      }
      else{
        this.setState({
          inptdis: {
            display:"inline-block"
        }, }) }
      if(grade == 2){
        code1='NO_PASS_RETURN'
        this.setState({
          order_statues:'Return by Manual Review',
          reviewreason:'returnReason'
        })
      }else if(grade == 4){
        code1='NO_PASS_REJECT_IMMEDIATELY'
        
        this.setState({
          order_statues:'Reject by Manual Review',
          reviewreason:'rejectImmReason'
        })
      }
      else if(grade == 3){
        code1 = 'NO_PASS_REJECT'
        this.setState({
          order_statues:'Reject by Manual Review',
          reviewreason:'rejectReason'

        })
      }
      else if(grade == 5){
        code1 = 'NO_PASS_BLACK_LIST'
        this.setState({
          order_statues:'Reject by Manual Review',
          reviewreason:'blackListReason'
        })
      }
      axios
        .post(`/api/common/getCommonListByCode`,{
          code:code1
      })
      .then(res=>{
        whitchshow=[]
        if(res.data.code === 200){
          let data = res.data
          for(let i=0;i<res.data.data.length;i++ ){
            if(i==0){
              whitchshow.push(<option value=''>Please select one</option> )
            }
            whitchshow.push(       
              <option value={res.data.data[i].number}>{res.data.data[i].name}</option>
          )
          this.setState({
            whitchshow:whitchshow
          })
          }
        }else if(res.data.code ==800){
          this.props.history.push({ pathname: "/"});
        }else{
        }
      })
      
    }
    //modal第二个select
    modelselectTwo=(e)=>{
      var value = e.target.value;
      this.setState({
        modalscdSelect:value
      })
    }
     //modal第三个select
     modelselectThree=(e)=>{
      var value = e.target.value;
      this.setState({
        modalthdSelect:value
      })
     
    }
    redistribution=()=>{
      if(this.state.selectedRowKeys.length<1){
        message.error('please select one');
        return false
      }
      this.setState({
        thdvisible: true,
        });
    }

    onSelectChange = selectedRowKeys => {
      console.log('selectedRowKeys changed: ', selectedRowKeys);
      this.setState({ selectedRowKeys });
    };


    render() {

      const { selectedRowKeys } = this.state;
      const rowSelection = {
        selectedRowKeys,
        onChange: this.onSelectChange,
         columnWidth:10
      };



      // const { selectedRowKeys } = this.state;
      // const rowSelection = {
      //   selectedRowKeys,
      //   onChange: (selectedRowkeys,selectedRows) =>{
      //     console.log(selectedRowkeys,selectedRows)
      //     // this.setState({
      //     //   // selectedRowIds : selectedRowkeys.map(Number),
      //     //   selectedRowKeys
      //     // })
      //     this.setState({ selectedRowKeys:selectedRowkeys, });
      //   },
      //   // columnWidth:40
        
      // };
      const columns = [
        {
          title: 'app type',
          dataIndex: 'type',
          key: 'type',
          width:100
        },
        {
          title: 'order number',
          dataIndex: 'number',
          key: 'number',
          width:150,
        },
        {
          title: 'Customer name',
          dataIndex: 'name',
          key: 'name',
          width:103

        },
        {
          title: 'Mobile phone',
          dataIndex: 'phone',
          key: 'phone',
          width:99
        },
        {
          title: 'Bvn',
          dataIndex: 'bvn',
          key: 'bvn',
          width:105
        },
        {
          title: 'Loan amount',
          dataIndex: 'amount',
          key: 'amount',
          width:97
        },
        {
          title: 'Loan term',
          dataIndex: 'days',
          key: 'days',
          width:96
        },
        {
          title: 'Order creation time',
          dataIndex: 'time',
          key: 'time',
          width:99
        },
        {
          title: 'Successful repayment count',
          dataIndex: 'times',
          key: 'times',
          width:98
        },
        {
          title: 'Loan Status',
          dataIndex: 'state',
          key: 'state',
          width:99
        },
        {
          title: 'Risk rule result',
          dataIndex: 'risk',
          key: 'risk',
          width:99,
          render:(text,record) =>  this.changeRisk(record)

        },
        {
          title: 'Reviewer',
          dataIndex: 'people',
          key: 'people',
          width:100
        },
        {
          title: 'Review time',
          dataIndex: 'when',
          key: 'when',
          width:100
        },
 
        {
          title: 'Review details',
          dataIndex: 'detail',
          key: 'detail',
          width:97,
          render: (record) => {
            return (
              <Tooltip title={record}>
                <div className="twoEllipsis">
                  {record}
                </div>
              </Tooltip>
            )
        }
      },
        {
          title: 'Disapproval reason',
          dataIndex: 'reson',
          key: 'reson',
          width:98,
          render: (record) => {
            return (
              <Tooltip title={record}>
                <div className="twoEllipsis">
                  {record}
                </div>
              </Tooltip>
            )
        }
        },
        {
          title: 'Current reviewer',
          dataIndex: 'now',
          key: 'now',
          width:100
        },
        {
          title: 'Pending time',
          dataIndex: 'sure',
          key: 'sure',
          width:100,
          render:(text,record) =>  this.changeTime(record)
        },
       
        {
          title: 'operating',
          dataIndex: 'operating',
          key: 'operating',
          id:'id',
          uid:'uid',
          width:160,
          fixed: 'right',
          render: (text, record, index) => (<span style={{ display: 'flex' , flexDirection: 'row', justifyContent:'flex-start',}}> 
            <Button type="primary" style={this.state.reviewbutton} className="reviewbutton common_color" size="small" onClick={() => this.showFstModal(record)}>Review</Button>
            <Button type="primary"   style={this.state.detailsbutton} className="detailsbutton common_color" size="small"  onClick={() => this.text(record)}>details</Button>
            </span>)
        },
      ];
      return (
        <div className="credit_order">
          <div className="credit_content">
          <div className="flutter">
            <div className="top">
              <div className="top_left" style={{width:'30%',flexShrink:1}}>
                <div className="search">
                  <span className="description left">Order No:</span>
                  <Search
                    placeholder="Order No"
                    onSearch={value => console.log(value)}
                    style={{width: 290}}
                    value={this.state.searchVal}
                    onChange={this.searchIpt.bind(this)}
                  />
                 </div>
              </div>

              <div className="top_mid" style={{width:'30%',flexShrink:2,marginLeft:'5%',marginRight:'5%'}}>
                <div className="rangepick">
                    <span className="description mid_span">Created time:</span>
                    <RangePicker
                       style={{width: 290,}}
                      ranges={{
                      Today: [moment(), moment()],
                      'This Month': [moment().startOf('month'), moment().endOf('month')],
                      }}
                      showTime
                      format={dateFormat}
                      onChange={this.rangeOnChange}
                      value={this.state.from===undefined||this.state.to===undefined||this.state.from===""||this.state.to===""?null:[moment(this.state.from, dateFormat), moment(this.state.to, dateFormat)]}
                    />
                </div>
              </div>

              <div className="top_right" style={{width:'30%',flexShrink:1}}>
                <span className="description right">App type:</span>
                <select className="form_select"  value={this.state.fouSelect} onChange={this.selectFou.bind(this)}>
                 {Select}
                </select>
              </div>
            </div>

            <div className="mid" style={{justifyContent:'left'}}>
              <div className="mid_left" style={{width:'30%'}}>
                <span className="description left">Loan status:</span>
                <select className="form_select" value={this.state.fstSelect} onChange={this.selectFst.bind(this)}>
                  <option value="2,32,33">Loan status</option>
                  <option value="2">Pending Manual Review</option>
                  <option value="32">Reject by Manual Review</option>
                  <option value="33">Return by Manual Review</option>
                  <option value="1">Pending Auto Review</option>
                </select>
              </div>

              <div className="mid_mid" style={{width:'30%',marginLeft:'5%',marginRight:'5%'}}>
                  <span className="description mid_span">Assigned Personal:</span>
                  <select  className="form_select" id="getselect" value={this.state.scdSelect} onChange={this.selectScd.bind(this)}>
                      {getSelect}
                  </select>
              </div>

              <div className="top_mid" style={{width:'30%'}} >
                  <span className="description right">Return reason:</span>
                  <select className="form_select" value={this.state.returnSelect} onChange={this.selectThd.bind(this)}>
                    {credit}
                  </select>
              </div>
            </div>    
            <div className="mid" style={{justifyContent: 'start'}}>
              <div className="mid_left" style={{width:'30%'}}>
              <span className="description left">Phone:</span>
                  <Search
                    placeholder="Phone"
                    onSearch={value => console.log(value)}
                    style={{width: 290}}
                    value={this.state.mobileNum}
                    onChange={this.searchIpt1.bind(this)}
                  />
              </div>
            </div>
            <div className="bottom">
              <Button type="primary" style={{width:170,marginRight:20,height:40,border:'none'}}  className="common_color" onClick={this.searchClick.bind(this)}>Search</Button>
              <Button type="primary" style={{width:170,height:40,border:'none'}} className="common_color_scd" onClick={this.reloadClick.bind(this)}>Refresh</Button>
              <Button style={{marginLeft:20,width:170,height:40,border:'none'}} className="common_color_thd"onClick={this.redistribution} type="primary">Batch re-allocate</Button>
              <div className="collection_mid_top"  >
                    <div className="collection_mid_top_right">
                        <div className="right_btn">
                            <span className={`both_button ${this.state.isToggleOn ? 'active' : ''}`}  onClick={this.whichActive}>↓ Pending time</span>
                            <span className={`both_button ${this.state.isToggleOff ? 'active' : ''}`} onClick={this.whichotherActive}>↑ Pending time</span>
                        </div>
                    </div>
                </div>
            </div>      
          </div>
          </div>
          <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>

               
          </div>
         
          <Table columns={columns} dataSource={this.state.tableData} scroll={{x:1925,y:710}}  rowKey={record=>record.id} loading={this.state.boolean}  onChange={this.handleChange} rowSelection={rowSelection} pagination={false}/>


          <Modal
          title="Review"
          visible={this.state.fstvisible}
          width={700}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              cancel
            </Button>,
             <Button key="submit" type="primary" loading={this.state.modify} onClick={this.fsthandleOk}>
             ok
           </Button>,
          ]}

         
        >
        <input type="text" value={this.state.fstinpt} style={{display:"none"}} />
        <span style={{fontSize:15,fontWeight:'bold'}}>Review status:</span>
          <select className="model_fst" id="modal_fstid"  value={this.state.modalfstSelect} onChange={this.gradeChange}>
            <option value="0">Please select one</option>
            <option value="1">Approved</option>
            {/* <option value="2">Return——Incomplete information</option> */}
            <option value="3">Reject——Reapply 30 days</option>
            <option value="4">Reject——Reapply immediately</option>
            {/* <option value="5">Reject——Move to blacklist</option> */}
          </select>
          <br/>
        <span style={{fontSize:15,fontWeight:'bold'}}>Loan status:</span>
          <input className="order_model_scd" disabled='disabled' value={this.state.order_statues}/>
          <br/>
        <span style={this.state.inptdis} className="long">Reasons for rejection:</span>
        <select style={this.state.inptdis} className="model_fst_special" value={this.state.modalscdSelect} onChange={this.modelselectTwo.bind(this)}>
           {whitchshow}
        </select>
        <br/>
        <div className="model_last">
          <label style={{fontSize:15,fontWeight:'bold'}}>Review details:</label>
          <textarea style={{width:'65%',marginLeft:'3%',height:80}} value={this.state.fsttextarea} onChange={this.fsttxarea}/>
        </div>
        </Modal>

         <Modal
          title="Assigned Personal"
          visible={this.state.scdvisible}
          onOk={this.scdhandleOk}
          onCancel={this.handleCancel}
        >
        <input type="text" value={this.state.thdinpt} style={{display:"none"}} />
        <lable style={{fontSize:15,fontWeight:'bold'}}>Assigned Person:</lable>
          <select className="model_fst" id="modal_select" value={this.state.modalthdSelect} onChange={this.modelselectThree.bind(this)}>
          {getSelect}
          </select>
        </Modal>

          <Modal
          title="Assigned Personal"
          visible={this.state.thdvisible}
          onOk={this.thdhandleOk}
          onCancel={this.handleCancel}
        >
        <lable style={{fontSize:15,fontWeight:'bold'}}>Assigned Person:</lable>
          <select className="model_fst" id="modal_select" value={this.state.modalthdSelect} onChange={this.modelselectThree.bind(this)}>
          {getSelect}
          </select>
        </Modal>
        <Pagination
          showSizeChanger                          //展示改变pagesize样式
          onShowSizeChange={this.onShowSizeChange} //pagesize改变时回调函数
          pageSizeOptions={["10","50","100"]}   //设置pagesize区间
          defaultCurrent={1} 
          current={this.state.current}
          total={this.state.length} 
          onChange={this.pageChange} 
          pageSize={this.state.pageSize}
          showTotal={showTotal}
          style={{textAlign:'right',marginTop:20}}
          />
        </div>
      );
    }
  }
  export default withRouter(OrderManagement);