/*eslint-disable*/
import React from 'react';
import { Button,Table,DatePicker,Pagination,message } from 'antd';
import './index.css';
import axios from 'axios'
import moment from 'moment';
import {withRouter} from 'react-router-dom'

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD'||undefined;

function showTotal(total) {
  return `Total ${total} items`;
}
function formatMoney(s, type){
  if (/[^0-9\.]/.test(s))
    return "0";
  if (s == null || s == "null" || s == "")
    return "0";
  s = s.toString().replace(/^(\d*)$/, "$1.");
  s = (s + "00").replace(/(\d*\.\d\d)\d*/, "$1");
  s = s.replace(".", ",");
  var re = /(\d)(\d{3},)/;
  while (re.test(s))
    s = s.replace(re, "$1,$2");
  s = s.replace(/,(\d\d)$/, ".$1");
  if (type == 0) {
    var a = s.split(".");
    if (a[1] == "00") {
      s = a[0];
    }
  }
  s = s.replace(".", ",");
  return s;
}
class monthPerson extends React.Component{
    constructor(props){
        super(props);
        this.state={
          from:'',
          to:'',
          tableData:[], 
          current:1,
          length:1,
          pageSize:10,
          boolean:true
        }
      }
      componentDidMount(){
        this.secondTable();
      }
      //设置时间
      rangeOnChange=(dates, dateStrings)=>{
        this.setState({ from: dateStrings[0],to:dateStrings[1] })
      }
    showmodal=()=>{
        // alert(this.index)
    }
    pageChange=(page)=>{
      this.setState({
        current: page
        },()=>{
          this.searchClick()
        })
    }
    onShowSizeChange=(current, pageSize)=>{

      this.setState({
        pageSize: pageSize
      },()=>{
        this.secondTable()
      })
  }
  // changeRisk =(params)=>{
  //   var va = params.date
  //   if (va==0){return <span style={{color:'black'}}>January</span>}
  //   if (va==1){return <span style={{color:'black'}}>February</span>}
  //   if (va==2){return <span style={{color:'black'}}>March</span>}
  //   if (va==3){return <span style={{color:'black'}}>April</span>}
  //   if (va==4){return <span style={{color:'black'}}>May</span>}
  //   if (va==5){return <span style={{color:'black'}}>June</span>}
  //   if (va==6){return <span style={{color:'black'}}>July</span>}
  //   if (va==7){return <span style={{color:'black'}}>August</span>}
  //   if (va==8){return <span style={{color:'black'}}>Septemper</span>}
  //   if (va==9){return <span style={{color:'black'}}>October</span>}
  //   if (va==10){return <span style={{color:'black'}}>November</span>}
  //   if (va==11){return <span style={{color:'black'}}>December</span>}
  //   else {return <span style={{color:'red'}}>Total</span> }
  //   }
    collector =(params)=>{
      var va = params.overduegrade
        if (va==1){
            return <span style={{color:'black'}}>S1-Old</span>
        }else if (va==2){
            return <span style={{color:'black'}}>S2-Old</span>
        }else if (va==3){
            return <span style={{color:'black'}}>M1-Old</span>
        }else if (va==4){
            return <span style={{color:'black'}}>M2</span>
        }else if (va==5){
            return <span style={{color:'black'}}>M3</span>
        }else if (va==6){
            return <span style={{color:'black'}}>M4</span>
        }else if (va==11){
            return <span style={{color:'black'}}>S1-New</span>
        }else if (va==12){
            return <span style={{color:'black'}}>S2-New</span>
        }else if (va==13){
            return <span style={{color:'black'}}>M1-New</span>
        }else {
            return <span style={{color:'black'}}></span>
        }
      }

      amount =(params)=>{
        var num1 = params.Totalount
        return  formatMoney(num1,0);
      }
      amount1 =(params)=>{
        var num1 = params.Totalamount
        return  formatMoney(num1,0);
      }
      amount2 =(params)=>{
        var num1 = params.Repaymentmnt  
        return  formatMoney(num1,0);
      }

//页面初始化加载的table
  secondTable=()=>{

    let id = localStorage.id;
    axios
      .post(`/api/collectionData/getOrderDetails`,{
      beginTime:this.state.from,
      endTime:this.state.to,
      pageSize: this.state.pageSize,
      current: this.state.current,
      uid:id,
    })
    .then(res=>{
      if(res.data.code === 200){
        const data1 = [];
        const data = res.data.data.resultDate
        if(data.length < 1 ){
          this.setState({
            boolean:false
          })
        }else{
          for (let i = 0; i < data.length; i++) {
            data1.push({
              key: i,
              date:data[i].collectionMonth,
              Collector:data[i].userName,
              overduegrade:data[i].overdueGrade,
              Allocateder:data[i].totalCount,
              Totalount:data[i].totalAmount/100,
              Totalorder:data[i].turnoverVolumeCount,
              Totalamount:data[i].turnoverAmount/100,
              Repaymentder:data[i].totalBillCollection,
              Repaymentmnt:data[i].totalAmountDue/100,
              Recoveryamount:data[i].paymentRecoveryRate,
              Recoveryrate:data[i].orderNumberRecoveryRate,
              totalExtendOrder:data[i].totalExtendOrder,
              totalExtendAmount:data[i].totalExtendAmount/100
            });
           
          } 
        }
        
        this.setState({
          tableScdData:data1,
          boolean:false
        })
      }else if(res.data.code ==800){
        this.props.history.push({ pathname: "/"});
      }else{

      }
    })
    
  }


          //搜索
          searchClick=()=>{ 
            let id = localStorage.id;
                  axios
                    .post(`/api/collectionData/getOrderDetails`,{
                      beginTime:this.state.from,
                      endTime:this.state.to,
                      pageSize: this.state.pageSize,
                      current: this.state.current,
                      uid:id,
                  })
                  .then(res=>{
                    if(res.data.code === 200){
                      const data1 = [];
                      const data = res.data.data.resultDate
                      if(data.length < 1 ){
                        this.setState({
                          boolean:false
                        })
                      }else{
                        for (let i = 0; i < data.length; i++) {
                          data1.push({
                            key: i,
                            date:data[i].collectionMonth,
                            Collector:data[i].userName,
                            overduegrade:data[i].overdueGrade,
                            Allocateder:data[i].totalCount,
                            Totalount:data[i].totalAmount/100,
                            Totalorder:data[i].turnoverVolumeCount,
                            Totalamount:data[i].turnoverAmount/100,
                            Repaymentder:data[i].totalBillCollection,
                            Repaymentmnt:data[i].totalAmountDue/100,
                            Recoveryamount:data[i].paymentRecoveryRate,
                            Recoveryrate:data[i].orderNumberRecoveryRate,
                            totalExtendOrder:data[i].totalExtendOrder,
                            totalExtendAmount:data[i].totalExtendAmount/100
                          });
                        } 
                      }
                      this.setState({
                        tableScdData:data1,
                        boolean:false
                      })
                      
                    }else if(res.data.code ==800){
                      this.props.history.push({ pathname: "/"});
                    }else{
                    }
                  })
                  // localStorage.removeItem('userId')
                  
                }
    render(){
        

        const columns = [
          {
            title: 'date',
            dataIndex: 'date',
            key: 'date',
            // render:(text,record) =>  this.changeRisk(record)

          }, 
          {
            title: 'Collector',
            dataIndex: 'Collector',
            key: 'Collector',

          }, 
          {
            title: 'Overdue grade',
            dataIndex: 'overduegrade',
            key: 'overduegrade',
            render:(text,record) =>  this.collector(record)

          }, 
          {
            title: 'Total Allocate Order',
            dataIndex: 'Allocateder',
            key: 'Allocateder'
          }, 
          {
            title: 'Total Allocate Amount',
            dataIndex: 'Totalount',
            key: 'Totalount',
            render:(text,record) =>  this.amount(record)

          },
          // {
          //     title: 'Total Transfer Order',
          //     dataIndex: 'Totalorder',
          //     key: 'Totalorder'
          // },
          // {
          //     title: 'Total Transfer Amount',
          //     dataIndex: 'Totalamount',
          //     key: 'Totalamount',
          //     render:(text,record) =>  this.amount1(record)

          // },
          {
              title: 'Total Repayment Order',
              dataIndex: 'Repaymentder',
              key: 'Repaymentder'
          }, 
          {
              title: 'Total Repayment Amount',
              dataIndex: 'Repaymentmnt',
              key: 'Repaymentmnt',
              render:(text,record) =>  this.amount2(record)

          }, 
          {
              title: 'Amount Recovery Rate',
              dataIndex: 'Recoveryamount',
              key: 'Recoveryamount'
          },
          {
              title: 'Order Recovery Rate',
              dataIndex: 'Recoveryrate',
              key: 'Recoveryrate'
            }, 
            {
              title: 'Total extend order',
              dataIndex: 'totalExtendOrder',
              key: 'totalExtendOrder',
    
            },
            {
              title: 'Total extend fee',
              dataIndex: 'totalExtendAmount',
              key: 'totalExtendAmount',
    
            },
           
          ];
        return(
            <div className="audit_content">
                <div className="commom_top" style={{display:'flex',alignItems:'center'}}>
                <div className="rangepick">
                    <span className="description mid_span">Created time:</span>
                    <RangePicker
                        style={{width: 290,}}
                        ranges={{
                        Today: [moment(), moment()],
                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                        }}
                        showTime
                        format={dateFormat}
                        onChange={this.rangeOnChange}
                        value={this.state.from===undefined||this.state.to===undefined||this.state.from===""||this.state.to===""?null:[moment(this.state.from, dateFormat), moment(this.state.to, dateFormat)]}
                            />
                        </div>
                <Button type="primary" style={{width:170,marginRight:20,marginLeft:'15%',height:40,border:'none'}} className="common_color" onClick={this.searchClick.bind(this)}>Search</Button>
                </div>
                <div className="table">
                <Table columns={columns} dataSource={this.state.tableScdData} loading={this.state.boolean} pagination={false} scroll={{ x: '100%' }}/>
                </div>

                  <Pagination
                  showSizeChanger                          //展示改变pagesize样式
                  onShowSizeChange={this.onShowSizeChange} //pagesize改变时回调函数
                  pageSizeOptions={["10","50","100"]}   //设置pagesize区间
                  defaultCurrent={1} 
                  current={this.state.current}
                  total={this.state.length} 
                  onChange={this.pageChange} 
                  pageSize={this.state.pageSize}
                  showTotal={showTotal}
                  style={{textAlign:'right',marginTop:20}}
                  />
            </div>
        )
    }
}
export default withRouter(monthPerson)