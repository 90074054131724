/*eslint-disable*/
import React from 'react';
import { Input, Button, Table, Modal, Pagination, DatePicker, Tooltip, message } from 'antd';
import axios from 'axios'
import './index.scss'
import moment from 'moment';
import {getTotalTelemarketSelect,getTodayTelemarketData} from '../../api/telemarket'
import { withRouter } from 'react-router-dom'
var Select = [] //催收员下拉框数据

function showTotal(total) {
    return `Total ${total} items`;
}
var getselect = []
const dateFormat = 'YYYY-MM-DD' || undefined;
const { RangePicker } = DatePicker;
const { Search } = Input;

class TelemarketData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 1,
            total: 1,
            from:'',
            to:'',
            pageSize: 10,
            tableData: [],
            boolean: true,
            totalData:[]
        }
    }
    componentDidMount(){
        this.getselect();
            this.getTableData()  
    }
        //改变页面条数时的回调
        onShowSizeChange=(current, pageSize)=>{

            this.setState({
              pageSize: pageSize
            },()=>{
              this.doSearch()
            })
        }
    //请求下拉框数据
    getselect = () => {
        getTotalTelemarketSelect()
            .then(res => {
                Select = []
                if (res.data.code === 200) {
                    for (let i = 0; i < res.data.data.resultDate.length; i++) {

                        if (i == 0) {
                            Select.push(<option value=''>please select one</option>)
                        }
                        Select.push(
                            <option value={res.data.data.resultDate[i].id}>{res.data.data.resultDate[i].loginName}</option>
                        )
                        this.setState({
                            Select: Select
                        })
                    }
                } else if (res.data.code == 800) {
                    this.props.history.push({ pathname: "/" });
                } else {
                }
            })
    }
    //搜索数据
    doSearch=()=>{
        let params = {
            overdueGrade:this.state.overdueGrade,
            operatorId:this.state.operatorId
        }
        this.getTableData(params)
    }
    //重置搜索框
    clearSearch=()=>{
        this.setState({
            overdueGrade:'',
            operatorId:''
        },()=>{
            this.getTableData()
        })
    }
    //请求表格数据
    getTableData(params){
        this.setState({
            boolean:true
        })
        getTodayTelemarketData({
        ...params,
        pageNum: this.state.current,
        pageSize: this.state.pageSize,
    }).then(res=>{
        if(res.data.code ===200){
            this.setState({
                boolean:false,
                tableData:res.data.data.resultData,
                total:res.data.data.page.total
            })
        }else if (res.data.code == 800) {
            this.props.history.push({ pathname: "/" });
        }
    })
    }
          //跳转到日详情
          goDayDetail=(record)=>{
            window.location = `/#/telemarketing/telemarket_data/dayDetail?operatorId=${record.operatorId}`
            let test = [{ path: '/telemarketing/telemarket_data/dayDetail', key: `/telemarketing/telemarket_data/dayDetail/${record.operatorId}`, number: `${record.operatorId}` }]
            EventEmitter.trigger('btn', test[0])
          }
    //表单值双向绑定
    fstaddonChange = (e) => {
        let InputName = e.target.name;
        let InputValue = e.target.value;
        this.setState({
            [InputName]: InputValue.replace(/\s+/g, ""),
        })
    }
    pageChange = (page) => {
        this.setState({
            current: page
        }, () => {
            this.doSearch()
        })
    }
    //时间选择器范围
    rangeOnChange=(dates, dateStrings)=>{
        console.log(dateStrings,'时间选择器')
        this.setState({ from: dateStrings[0],to:dateStrings[1] })
      }
    render() {
        const columns = [
            {
                title: 'Role',
                dataIndex: 'overdueGrade',
                key: 'overdueGrade',
                render: (value) => {
                    switch (value) {
                        case 31:
                            return '电销-新客'
                            break;
                            case 32:
                                return '电销-老客'
                                break;
                        default:
                            return '——'
                            break;
                    }
                }

            },
            {
                title: 'Telemarketer',
                dataIndex: 'operatorName',
                key: 'operatorName',
                render: (value) => {
                    return value || '——'
                }
            },
            {
                title: 'Existing customers',
                dataIndex: 'currentCount',
                key: 'currentCount',
                render: (value) => {
                    if(value==0){
                        return 0
                    }else{
                        return value || '——'
                    }
                }

            },
            {
                title: 'Today Allocate',
                dataIndex: 'allotCount',
                key: 'allotCount',
                render: (value) => {
                    if(value==0){
                        return 0
                    }else{
                        return value || '——'
                    }
                }
            },
            {
                title: 'Today Calls',
                dataIndex: 'cellCount',
                key: 'cellCount',
                render: (value) => {
                    if(value==0){
                        return 0
                    }else{
                        return value || '——'
                    }
                }

            },
            {
                title: 'Today effective calls',
                dataIndex: 'effectiveCount',
                key: 'effectiveCount',
                render: (value) => {
                    if(value==0){
                        return 0
                    }else{
                        return value || '——'
                    }
                }

            },
            {
                title: 'Today apply Orders',
                dataIndex: 'loanCount',
                key: 'loanCount',
                render: (value) => {
                    if(value==0){
                        return 0
                    }else{
                        return value || '——'
                    }
                }

            },
            {
                title: 'Today loans',
                dataIndex: 'paymentCount',
                key: 'paymentCount',
                render: (value) => {
                    if(value==0){
                        return 0
                    }else{
                        return value || '——'
                    }
                }

            },
            {
                title: 'Today loan rate',
                dataIndex: 'paymentRate',
                key: 'paymentRate',
                render: (value) => {
                    if(value==null){
                        return '——'
                    }else{
                        return (value*100).toFixed(2) + '%'
                    }
                }

            }
        ];
        return (
            <div className="audit_content">
                <div className="commom_top" style={{ alignItems: 'center',marginBottom:30 }}>
                <label>Telemarketer:</label>
                    <select className="menu_form_select_new" name="operatorId" value={this.state.operatorId} onChange={this.fstaddonChange}>
                        {Select}
                    </select>
                <label>Role：</label>
                    <select className="menu_form_select_new" name="overdueGrade" value={this.state.overdueGrade} onChange={this.fstaddonChange}>
                        <option value=''>all</option>
                        <option value='31'>Telemarketing-New</option>
                        <option value='32'>Telemarketing-Old</option>
                    </select>
                    <Button type="primary" style={{
                        width: 170,
                        marginLeft: '4%',
                        height: 40,
                        border: 'none'
                    }}
                    className="common_color_fst"
                    onClick={this.doSearch}
                    >Search</Button>
                    <Button type="primary" style={{
                        width: 170,
                        marginLeft: '4%',
                        height: 40,
                        border: 'none'
                    }} 
                    className="common_color_thd"
                    onClick={this.clearSearch}
                    >Clear</Button>

                </div>
                <div className="table">
                    <Table columns={columns} dataSource={this.state.tableData} loading={this.state.boolean}
                    rowKey={record=>record.id}
                        pagination={false} />
                </div>
               
                <Pagination
                    // showQuickJumper
                    onShowSizeChange={this.onShowSizeChange}//pagesize改变时回调函数
                    pageSizeOptions={["10","50","100"]}   //设置pagesize区间
                    defaultCurrent={1}
                    current={this.state.current}
                    total={this.state.total}
                    onChange={this.pageChange}
                    showTotal={showTotal}
                    style={{ textAlign: 'right', marginTop: 20 }}
                    showSizeChanger={true}
                    pageSize={this.state.pageSize}
                />
            </div>
        )
    }
}
export default withRouter(TelemarketData)