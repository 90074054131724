/*eslint-disable*/
import React from 'react';
import { Input,Button,Table,Modal,Pagination,DatePicker,Tooltip,message } from 'antd';
import moment from 'moment';
import axios from 'axios'
import './index.css';
import $ from 'jquery';
import {withRouter} from 'react-router-dom'

function showTotal(total) {
  return `Total ${total} items`;
}
var selector = []
var collector = []
// function showTotal(total) {
//   return `Total ${total} items`;
// }
// var getSelect = []
// const { RangePicker } = DatePicker;
//   const { Search } = Input;
//   const dateFormat = 'YYYY-MM-DD'||undefined;
 class getRemindDataToday extends  React.Component {
  constructor(props) {
    super(props);
    this.state = {
        from:'',
        to:'',
        current:1,
        pageSize:20,
        isshow:true,
        ishide:false,
        boolean:true,
        topboolean:true,
        id:'',
        total:''
      };
}

componentDidMount() {
  this.setState({
  },()=>{
    this.secondTable()
    this.table()
  })
}
modelselectOne(e){
  var value = e.target.value;
  this.setState({
    modalscdSelect:value,
  });
  
}
fsthandleOk=()=>{
  console.log(this.state.id)
  axios
    .post(`/api/remindData/changeOverdueGrade`,{
      id:this.state.id,
      overdueGrade: this.state.fstSelect,
  })
  .then(res=>{
    if(res.data.code === 200){
      this.setState({
        boolean:true
      })
      this.secondTable()
      this.table()
    }
    else if(res.data.code ==800){
      this.props.history.push({ pathname: "/"});
    }else{

    }
  })
  this.setState({
    fstvisible: false,
    modalscdSelect:'',
    id:''
  });
}
handleCancel = e => {
  console.log(e);
  this.setState({
    fstvisible: false,
   
  });
};
selectFst=(e)=>{
  var value = e.target.value;
  this.setState({
    fstSelect:value,
  });
}

modelShow=()=>{

  this.setState({
    fstvisible:true,
  })
  axios
    .post(`/api/remindData/getCollectionUsers`,{
  })
  .then(res=>{
    if(res.data.code === 200){
      collector=[]
    let data = res.data.data.resultDate
    for(let i=0;i<data.length;i++ ){
      collector.push(       
        <option data-userId={data[i].id} value={data[i].userName}></option>
    )
    // console.log('select',collector)
    this.setState({
      collector:collector
    })
    }
    }else if(res.data.code ==800){
      this.props.history.push({ pathname: "/"});
    }
    else{
    }
  })
}
      //输入框
      searchIpt(e){
        var value = e.target.value;
        this.setState({ searchVal: value })
      }
       //页码改变时触发
       pageChange=(page)=>{
        this.setState({
          current: page
          },()=>{
            this.searchClick()
          })
      }
      searchClick=()=>{
        this.setState({
          
        })
        this.secondTable()
      }
       //选择collector
       selectscdd(e){
        var value = e.target.value;
        var userInfo = $("#iptt").val();
        var getUserId = $("option[value='" + userInfo + "']").data("userid");
        $("input[name='userId']").val(getUserId);
        this.setState({
          scdSelect:value,
          id:getUserId
        });
      }
      onShowSizeChange=(current, pageSize)=>{

        this.setState({
          pageSize: pageSize
        },()=>{
          this.secondTable()
          this.table()
        })
    }

  secondTable=()=>{
    
 
    let id = localStorage.id;
    axios
      .post(`/api/remindData/getRemindOrderToday`,{
        pageSize: this.state.pageSize,
        current: this.state.current,
    })
    .then(res=>{
      if(res.data.code === 200){
        const data1 = [];
        const data = res.data.data.resultDate
        if(data.length<1){
          this.setState({
            boolean:false
          })
        }else{
          for (let i = 0; i < data.length; i++) {
            data1.push({
              key: i,
              userName:data[i].userName,
              grade:data[i].overdueGrade,
              distributionQuantity:data[i].distributionQuantity,
              afterDistributionRepayQuantity:data[i].afterDistributionRepayQuantity,
              remindRepaymentRate:data[i].remindRepaymentRate,
              currentUnrepayOrderQuantity:data[i].currentUnrepayOrderQuantity,
              maxFirstOverdueRate:data[i].maxFirstOverdueRate,
              currentRemindedOrderQuantity:data[i].currentRemindedOrderQuantity,
              currentNoRemindOrderQuantity:data[i].currentNoRemindOrderQuantity,
              todayExtendOrder:data[i].todayExtendOrder,
            });
           
          } 
        }
       
        this.setState({
          tableScdData:data1,
          total:res.data.data.total,
          boolean:false
        })
      }else if(res.data.code ==800){
        this.props.history.push({ pathname: "/"});
      }else{

      }
    })
    
  }

  table=()=>{
    let id = localStorage.id;
    axios
      .post(`/api/remindData/getRemindOrderTodayTotal`,{
        pageSize: this.state.pageSize,
        current: this.state.current,
    })
    .then(res=>{
      if(res.data.code === 200){
        const data1 = [];
        const data = res.data.data.resultDate
        if(data.length<1){
          this.setState({
            topboolean:false
          })
        }else{
          for (let i = 0; i < data.length; i++) {
            data1.push({
              key: i,
              customerCurrentDate:data[i].customerCurrentDate,
              expiredTotalNum:data[i].expiredTotalNum,
              beforeDistributionRepayNum:data[i].beforeDistributionRepayNum,
              distributedOrderNum:data[i].distributedOrderNum,
              distributedRepayOrderNum:data[i].distributedRepayOrderNum,
              repayTotalNum:data[i].repayTotalNum,
              remindRepaymentRateToday:data[i].remindRepaymentRateToday,
              maxFirstOverdueRateToday:data[i].maxFirstOverdueRateToday,
              totalExtendOrder:data[i].totalExtendOrder,
            });
           
          } 
        }
        
        this.setState({
          totalData:data1,
          topboolean:false
        }) 
      }else if(res.data.code ==800){
        this.props.history.push({ pathname: "/"});
      }else{

      }
    })
    
  }

  refreshTable = () =>{
    this.setState({
      topboolean:true,
      boolean:true
    })
    this.secondTable()
    this.table()

  }


    render() {
      const botcolumns = [
        {
          title: 'Date',
          dataIndex: 'customerCurrentDate',
          key: 'customerCurrentDate',
        },
        {
          title: 'Total Due',
          dataIndex: 'expiredTotalNum',
          key: 'expiredTotalNum',


        },
        {
          title: 'Repaid before Allocate',
          dataIndex: 'beforeDistributionRepayNum',
          key: 'beforeDistributionRepayNum',
        },
        {
          title: 'Total Allocate',
          dataIndex: 'distributedOrderNum',
          key: 'distributedOrderNum',


        },
        {
          title: 'Repaid after Allocate',
          dataIndex: 'distributedRepayOrderNum',
          key: 'distributedRepayOrderNum',
        },
        {
          title: 'Total Repaid',
          dataIndex: 'repayTotalNum',
          key: 'repayTotalNum',


        },
        {
          title: 'Today extend order',
          dataIndex: 'totalExtendOrder',
          key: 'totalExtendOrder',
          width:100,
        },
        {
          title: 'Remind Repayment Rate',
          dataIndex: 'remindRepaymentRateToday',
          key: 'remindRepaymentRateToday',
        },
        {
          title: 'Max First Overdue Rate',
          dataIndex: 'maxFirstOverdueRateToday',
          key: 'maxFirstOverdueRateToday',
        },
      
      ];

      const columns = [
        {
          title: 'Reminder Name',
          dataIndex: 'userName',
          key: 'userName',

        },
          {
              title: 'Overdue grade',
              dataIndex: 'grade',
              key: 'grade',
              render: (value) => {
                  if(value==21){
                      return 'S0-Old'
                  }else if(value==1){
                      return 'S1-Old'
                  }else if(value==2){
                      return 'S2-Old'
                  }else if(value==3){
                      return 'M1-Old'
                  }else if(value==4){
                      return 'M2'
                  }else if(value==5){
                      return 'M3'
                  }else if(value==6){
                      return 'M4'
                  }else if(value==22){
                      return 'S0-New'
                  }else if(value==11){
                      return 'S1-New'
                  }else if(value==12){
                      return 'S2-New'
                  }else if(value==13){
                      return 'M1-New'
                  }else{
                      return '--'
                  }

              }
          },
        {
          title: 'Total Allocate',
          dataIndex: 'distributionQuantity',
          key: 'distributionQuantity',

        }, 
        {
          title: 'Repaid after Allocate',
          dataIndex: 'afterDistributionRepayQuantity',
          key: 'afterDistributionRepayQuantity',
        }, 
        {
          title: 'remind repayment rate',
          dataIndex: 'remindRepaymentRate',
          key: 'remindRepaymentRate'
        }, 
        {
          title: 'Today extend order',
          dataIndex: 'todayExtendOrder',
          key: 'todayExtendOrder',
          width:100,
        },
        {
          title: 'Unreturned',
          dataIndex: 'currentUnrepayOrderQuantity',
          key: 'currentUnrepayOrderQuantity'
        },
        {
            title: 'Overdue Rate',
            dataIndex: 'maxFirstOverdueRate',
            key: 'maxFirstOverdueRate'
        },
        {
            title: 'Reminded',
            dataIndex: 'currentRemindedOrderQuantity',
            key: 'currentRemindedOrderQuantity'
        },
        {
            title: 'Not Remind',
            dataIndex: 'currentNoRemindOrderQuantity',
            key: 'currentNoRemindOrderQuantity'
        }, 
    
      
         
        ];
        return (
          <div className="overduepaid" style={{paddingTop:0}}>
          <div className="overduepaid_top">
              <div className="overduepaid_mid">
                  <div className="bottom" style={{marginTop:0,justifyContent:'flex-end'}}>
                      <Button type="primary" style={{width:170,height:40,border:'none'}}  className="common_color" onClick={this.refreshTable}>Refresh</Button>
                  </div> 
              </div>
          </div>
          <div className="overdue_table">
              {/* <Table columns={columns} dataSource={this.state.tableData} scroll={{x:'100%'}} onChange={this.handleChange} pagination={false}/> */}
              <Table columns={botcolumns} dataSource={this.state.totalData} loading={this.state.topboolean} onChange={this.handleChange} pagination={false}/>
             
          </div>
          <div className="overdue_table">
              <Table columns={columns} dataSource={this.state.tableScdData} scroll={{x:'100%'}}onChange={this.handleChange} loading={this.state.boolean} pagination={false}/>
              <Pagination
                showSizeChanger                          //展示改变pagesize样式
                onShowSizeChange={this.onShowSizeChange} //pagesize改变时回调函数
                pageSizeOptions={["10","50","100"]}   //设置pagesize区间
                defaultCurrent={1} 
                current={this.state.current}
                total={this.state.total} 
                onChange={this.pageChange} 
                pageSize={this.state.pageSize}
                showTotal={showTotal}

                style={{textAlign:'right',marginTop:20}}
                />
              {/* <Table columns={botcolumns} dataSource={this.state.totalData} loading={this.state.topboolean} onChange={this.handleChange} pagination={false}/> */}
          </div>
          <Modal
          title="Reset"
          visible={this.state.fstvisible}
          onOk={this.fsthandleOk}
          // width={700}
          onCancel={this.handleCancel}
        >

         <span style={this.state.inptdis}  className="long">Collector</span>
        <input list='browsers' style={{marginLeft:56,width:279}} placeholder='all' className="form_select_mohu"  id="iptt" value={this.state.scdSelect} onChange={this.selectscdd.bind(this)}/>
        <datalist id="browsers">
          {collector}
        </datalist>

        <br/>
        <div className="model_last">
        <span className="description left"  className="long">Overdue Grade:</span>
                <select className="form_select" style={{width:276,marginLeft:10}}  value={this.state.fstSelect} onChange={this.selectFst.bind(this)}>
                  <option value=""></option>
                  <option value="0">S0</option>
                  <option value="1">S1</option>
                  <option value="2">S2</option>
                  <option value="3">M1</option>
                  <option value="4">M2</option>
                  <option value="5">M3</option>
                  <option value="6">M4</option>
                </select>
        </div>
        </Modal>
      </div>
        );
    }
}
export default withRouter(getRemindDataToday)