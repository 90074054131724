/*eslint-disable*/
import React from 'react';
import {Input, Button,Switch ,Tree , Table, Modal,Radio, Pagination, DatePicker, Tooltip, message} from 'antd';
import './index.css';
import axios from 'axios'
import moment from 'moment';
import {withRouter} from 'react-router-dom'
import Zmage from 'react-zmage'
import EventEmitter from '../../utils/events'
var getSelect = []
var treeData = []
let test = []
const dateFormat = 'YYYY-MM-DD'||undefined;
const { RangePicker } = DatePicker;
function showTotal(total) {
    return `Total ${total} items`;
}

var getselect = []
const {Search} = Input;
const key = [];
 class ContactUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 1,
            data: [],
            tableData: [],
            searchVal: '',
            scdsearchVal: '',
            current: 1,
            total: 1,
            pageSize: 10,
            boolean: true,
            fstSelect:1,
            thdval:'',
            modify:false,
            scdval:''
        }
    }
        //页码改变时触发
        pageChange=(page)=> { 
      
            this.setState({
              current: page
              },()=>{
                this.searchClick()
              })
        }


        rangeOnChange=(dates, dateStrings)=>{
            this.setState({ from: dateStrings[0],to:dateStrings[1] })
        }
        rangeOnChange1=(dates, dateStrings)=>{
            this.setState({ useStart: dateStrings[0],useEnd:dateStrings[1] })
        }

        processhandleOk=()=>{
           
            if(this.state.textareaVal==""){
                alert("Reply content cannot be empty")
                return false
            }
            this.setState({
                modify:true
            })
        axios
        .post(`/api/contact/processed`, {
            id:this.state.processval,
            replyContent:this.state.textareaVal,
            status:this.state.modalfstSelectVal
        })
        .then(res => {
            if (res.data.code === 200) {        
                message.success(res.data.msg)
                this.searchClick()
                this.setState({
                    fstvisible:false,
                    modify:false
                })
            } else if (res.data.code == 800) {
                this.props.history.push({pathname: "/"});
            } else {
                message.error(res.data.msg)
                this.searchClick()
                this.setState({
                    fstvisible:false,
                    modify:false
                })
            }
        })
    }
     /*删除modal点击ok*/ 
    deletehandleOk=()=>{
        axios
        .post(`/api/contact/contactDel`, {
            id:this.state.delval,
        })
        .then(res => {
            if (res.data.code === 200) {        
                message.success(res.data.msg)
                this.searchClick()
                this.setState({
                    thdvisible:false
                })
            } else if (res.data.code == 800) {
                this.props.history.push({pathname: "/"});
            } else {
                message.error(res.data.msg)
                this.searchClick()
                this.setState({
                    thdvisible:false
                })
            }
        })
    }
    
    Add=()=>{
        this.setState({
            scdvisible:true,
        })
    }


   
        modelselectThree=(e)=>{
            var value = e.target.value;
            this.setState({
              modalthdSelect:value
            })
           
          }
    process=(record)=>{
        this.setState({
            fstvisible:true,
            processval:record.id
        })
        axios
        .post(`/api/contact/findOne`, {
            id:record.id,
        })
        .then(res => {
            if (res.data.code === 200) {
                if(res.data.data.replyContent==null){
                    this.setState({
                        textareaVal:"",
                    }) 
                }else{
                    this.setState({
                        textareaVal:res.data.data.replyContent,
                    }) 
                }        
                this.setState({
                    modalfstSelectVal:res.data.data.status
                })
            } else if (res.data.code == 800) {
                this.props.history.push({pathname: "/"});
            } else {
                message.error(res.data.msg)
                this.searchClick()
                this.setState({
                    thdvisible:false
                })
            }
        })
    }
    handleCancel=()=>{
        this.setState({
            fstvisible:false,
            scdvisible:false,
            thdvisible:false,
            fouvisible:false,
            modify:false,
        })
    }
    refresh=()=>{
        this.setState({
            searchVal:'',
            scdsearchVal:'',
            from:'',
            useStart:'',
            useEnd:'',
            thdSelectVal:'',
            fstSelectVal:'',
            scdSelectVal:'',
            to:''
        },()=>{
          this.searchClick()
        })
      }
    delete=(record)=>{
        this.setState({
            named : record.name,
            thdvisible:true,
            delval:record.id
        })
    }

//页面初始化加载下面的table
    secondTable = () => {
        this.searchClick()
    }
    //搜索
    searchClick = () => {
        this.setState({
            boolean: true
        })
        axios
            .post(`/api/contact/contactList`, {
                pageNum:this.state.current,
                pageSize:this.state.pageSize,
                phone:this.state.searchVal,
                email:this.state.scdsearchVal,
                beginTime:this.state.from,
                endTime:this.state.to,
                handleBeginTime:this.state.useStart,
                handleEndTime:this.state.useEnd,
                type:this.state.thdSelectVal,
                status:this.state.fstSelectVal,
                handleName:this.state.scdSelectVal,
            })
            .then(res => {
                if (res.data.code === 200) {

                    const data1 = [];
                    const data = res.data.data.resultData

                    for (let i = 0; i < data.length; i++) {
                        data[i].key = i;
                        data1.push(data[i]);
                    }
                    this.setState({
                        tableScdData: data1,
                        boolean: false,
                        length:res.data.data.page.total
                    })
                } else if (res.data.code == 800) {
                    this.props.history.push({pathname: "/"});
                } else {
                    
                }
            })
    }
    handClick = (item) => {
        console.log('1111111122222233333', item)
    }




        //输入框
        searchIpt=(e)=>{
            var value = e.target.value;
            this.setState({
                searchVal:value.replace(/\s+/g,"") ,
            });
          }
           //输入框
        scdsearchIpt=(e)=>{
            var value = e.target.value;
            this.setState({ scdsearchVal: value.replace(/\s+/g,"") })
          }
        fstSelectOnchange=(e)=>{
            var value = e.target.value;
            this.setState({
               fstSelectVal:value ,
            });
        }
        modalfstSelectOnchange=(e)=>{
            var value = e.target.value;
            this.setState({
               modalfstSelectVal:value ,
            });
        }
        
        textareaOnchange=(e)=>{
            var value = e.target.value;
            this.setState({
                textareaVal:value ,
            });
        }
        scdSelectOnchange=(e)=>{
            var value = e.target.value;
            this.setState({
                scdSelectVal:value ,
            });
        }
        thdSelectOnchange=(e)=>{
            var value = e.target.value;
            this.setState({
                thdSelectVal:value ,
            });
        }
    componentDidMount() {
        this.secondTable()
        this.getselect()
    }
    getselect=()=>{
        axios
        .post(`/api/contact/getService`,{
  
      })
      .then(res=>{
        getSelect=[]
        if(res.data.code === 200){
          for(let i=0;i<res.data.data.length;i++ ){
            
              if(i==0){
                getSelect.push(<option value=''>All</option> )
                }
                getSelect.push(       
                <option value={res.data.data[i].id}>{res.data.data[i].loginName}</option>
            )
            
          this.setState({
            getSelect:getSelect
          })
          }
        }else if(res.data.code ==800){
          this.props.history.push({ pathname: "/"});
        }else{
        }
      })
      }

    render() {
       
      const { selectedRowKeys } = this.state;
      const rowSelection = {
        selectedRowKeys,
        onChange: this.onSelectChange,
         columnWidth:10
      };
        const columns = [

          
            {
                title: 'Phone',
                dataIndex: 'mobile',
                key: 'mobile',
                render: (value) => {
                    return value || '——'
                }
                

            },
            // {
            //     title: 'Email',
            //     dataIndex: 'email',
            //     key: 'email',
            //     width:300,
            //     render: (value) => {
            //         return value || '——'
            //     }
            // },
            {
                title: 'Type',
                dataIndex: 'type',
                key: 'type',
                id:'id',
                render: (value) => {
                    if (value == 1) {
                        return "Loan problem";
                    } if (value == 2) {
                        return "Function problem";
                    } if (value == 3) {
                        return "Suggestion";
                    } if (value == 4) {
                        return "Others";
                    }
                }
            },
            {
                title: 'Content',
                dataIndex: 'content',
                key: 'content',
                width:300,
                render: (record) => {
                    return (
                      <Tooltip title={record}>
                        <div className="twoEllipsis">
                          {record}
                        </div>
                      </Tooltip>
                    )
                }
            },
            {
                title: 'Picture',
                dataIndex: 'srcPath',
                key: 'srcPath',
                render: (value) => {
                    return <Zmage src={value} style={{width:180,height:90}}/>
                }
            },
            {
                title: 'Created time',
                dataIndex: 'createdTime',
                key: 'createdTime',
                render: (value) => {
                    if(value==null){
                        return ''
                      }else{
                        let timeaudit= new Date(value).getTime() 
                        let auditimes=moment(new Date(timeaudit)).format("YYYY-MM-DD HH:mm:ss");
                        return auditimes
                      }
                }
            },
            {
                title: 'status',
                dataIndex: 'status',
                key: 'status',
                id:'id',
                render: (value) => {
                    if (value == 2) {
                        return <span color='blue'>Processed</span>;
                    }else {
                        return <span color='red'>Unprocessed</span>;
                    }
                }
            },
            {
                title: 'Reply content',
                dataIndex: 'replyContent',
                key: 'replyContent',
                render: (value) => {
                    return value || '——'
                }
                

            },
            {
                title: 'Operator',
                dataIndex: 'handleName',
                key: 'handleName',
                render: (value) => {
                    return value || '——'
                }
            },
            {
                title: 'Operate time',
                dataIndex: 'handleTime',
                key: 'handleTime',
                render: (value) => {
                    if(value==null){
                        return ''
                      }else{
                        let timeaudit= new Date(value).getTime()
                        let auditimes=moment(new Date(timeaudit)).format("YYYY-MM-DD HH:mm:ss");
                        return auditimes
                      }
                }
            },
            { title: 'Operation', dataIndex: '', key: 'operation', render: (text, record) => 
            <span style={{ display: 'flex' , flexDirection: 'row', justifyContent:'center',margin:0}}>
                {/* <a className='edit_button ' href="javascript:;" onClick={() => this.delete(record)}>Delete</a>*/}
                 <a className='edit_button ' href="javascript:;" onClick={() => this.process(record)}>Process</a>
              </span>
           
            },
        ];
        return (
            <div className="menu_content">
             <div style={{width:'98%',paddingLeft:20,paddingTop:20}}>
                <div className="table_top">
                        <div className="mid_left" style={{width:"20%"}}>
                            <span style={{width:50,}} className="description left">Phone:</span>
                            <input type="text" placeholder='Phone' value={this.state.searchVal} onChange={this.searchIpt} className="form_select"  style={{width: "70%",}}/>
                        </div>
                        <div className="mid_left" style={{width:"25%"}}>
                        <span style={{width: 100,}} className="description left">Problem type:</span>
                            <select  value = {this.state.thdSelectVal}   className='product_select' onChange={this.thdSelectOnchange} style={{width: "70%",}}>
                                <option value=''>All</option>
                                <option value='1'>Loan problem</option>
                                <option value='2'>Function problem</option>
                                <option value='3'>Suggestion</option>
                                <option value='4'>Others</option>
                            </select>
                        </div>
                        <div className="mid_left" style={{width:"25%"}}>
                            <span style={{width: 100,}} className="description left">Created time:</span>
                            <RangePicker
                              style={{width: "70%",}}
                              ranges={{
                              Today: [moment(), moment()],
                              'This Month': [moment().startOf('month'), moment().endOf('month')],
                              }}
                              showTime
                              format={dateFormat}
                              onChange={this.rangeOnChange}
                              value={this.state.from===undefined||this.state.to===undefined||this.state.from===""||this.state.to===""?null:[moment(this.state.from, dateFormat), moment(this.state.to, dateFormat)]}
                            />
                        </div>
                        <div className="mid_left" style={{marginLeft:"5%",width:"25%"}}>
                            <span style={{width: 100,}} className="description left">Operate time:</span>
                            <RangePicker
                              style={{width: "70%",}}
                              ranges={{
                              Today: [moment(), moment()],
                              'This Month': [moment().startOf('month'), moment().endOf('month')],
                              }}
                              showTime
                              format={dateFormat}
                              onChange={this.rangeOnChange1}
                              value={this.state.useStart===undefined||this.state.useEnd===undefined||this.state.useStart===""||this.state.useEnd===""?null:[moment(this.state.useStart, dateFormat), moment(this.state.useEnd, dateFormat)]}
                            />                        
                          </div>
                    </div>

                    <div className="table_top" style={{marginTop:20}}>
                        <div className="mid_left" style={{width:"20%"}}>
                            <span style={{width:50,}} className="description left">Status:</span>
                            <select  value = {this.state.fstSelectVal}   className='product_select' onChange={this.fstSelectOnchange} style={{width: "70%",marginRight:20}}>
                                <option value=''>All</option>
                                <option value='1'>Unprocess</option>
                                <option value='2'>Processed</option>
                            </select>
                        </div>
                        <div className="mid_left" style={{width:"25%"}}>
                            <span style={{width: 70,}} className="description left">Operator:</span>
                            <select  value = {this.state.scdSelectVal}   className='product_select' onChange={this.scdSelectOnchange} style={{width: "70%",marginRight:20}}>
                                {getSelect}
                            </select>
                        </div>
                        {/* <div className="mid_left" style={{width:"25%"}}>
                            <span style={{width: 100,}} className="description left">Problem type:</span>
                            <select  value = {this.state.thdSelectVal}   className='product_select' onChange={this.thdSelectOnchange} style={{width: "70%",}}>
                                <option value=''>All</option>
                                <option value='1'>Loan problem</option>
                                <option value='2'>Function problem</option>
                                <option value='3'>Suggestion</option>
                                <option value='4'>Others</option>
                            </select>
                        </div> */}
                        <div style={{display:'flex',}}>
                            <Button type="primary" style={{width:170,marginLeft:"5%",height:40,border:'none'}} className="common_color" onClick={this.searchClick}>Search</Button>
                            <Button type="primary" style={{width:170,marginLeft:"5%",height:40,border:'none'}} className="common_color" onClick={this.refresh}>Refresh</Button>
                        </div>
                        
                    </div>
                 </div>
                <div className="table">
                    <Table columns={columns} className='old_table'  dataSource={this.state.tableScdData} loading={this.state.boolean}  pagination={false}/>
                </div>
                <Pagination
                defaultCurrent={1} 
                current={this.state.current}
                total={this.state.length} 
                onChange={this.pageChange}  
                pageSize={this.state.pageSize}
                showTotal={showTotal}
                style={{textAlign:'right',marginTop:20}}
                />
                <Modal
                title="process"
                visible={this.state.fstvisible} 
                onCancel={this.handleCancel}
                footer={[
                    <Button key="back" onClick={this.handleCancel}>
                      cancel
                    </Button>,
                     <Button key="submit" type="primary" loading={this.state.modify} onClick={this.processhandleOk}>
                     ok
                   </Button>,
                  ]}
                >
                <input value={this.state.processval} style={{display:'none'}}/>
                <div className="mid_left" style={{width:"100%",display:'flex',alignItems:'center',marginBottom:10}} >
                    <span style={{width:62,}} className="description left">Content:</span>
                    <textarea style={{width:"70%",height:100,}} className='product_select' value={this.state.textareaVal} onChange={this.textareaOnchange} />
                </div>
               
               <div className="mid_left" style={{width:"100%"}} >
                    <span style={{width:62,}} className="description left">Status:</span>
                    <select  value = {this.state.modalfstSelectVal}   className='product_select' onChange={this.modalfstSelectOnchange} style={{width: "70%",marginRight:20}}>
                        <option value='1'>Unprocess</option>
                        <option value='2'>Processed</option>
                        
                    </select>
                </div>
                
                </Modal>


             

            </div>

        )
    }
}
export default withRouter(ContactUs)