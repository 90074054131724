/*eslint-disable*/
import React from 'react';
import { Input, Button, Table, Modal, Pagination, DatePicker, Tooltip, message } from 'antd';
import axios from 'axios'
import '../index.scss'
import moment from 'moment';
import { withRouter } from 'react-router-dom'
import {getSmsData,getSmsTotal} from '../../../api/smsMarketApi'
import EventEmitter from '../../../utils/events'
import { exportMethod } from '../../../utils/axios';


function showTotal(total) {
    return `Total ${total} items`;
}
var getselect = []
const dateFormat = 'YYYY-MM-DD' || undefined;
const { RangePicker } = DatePicker;
const { Search } = Input;

class Smsdata extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 1,
            total: 1,
            from: '',
            to: '',
            pageSize: 10,
            tableData: [],
            boolean: true,
            totalData: []
        }
    }
    componentDidMount() {
        // this.setState({
        //     from: moment().startOf('month'),
        //     to: moment().endOf('month'),
        // }, () => {
            this.getTableData()
            this.getTotalData()
        // })
    }
        //改变页面条数时的回调
        onShowSizeChange=(current, pageSize)=>{

            this.setState({
              pageSize: pageSize
            },()=>{
              this.doSearch()
            })
        }
    //搜索数据
    doSearch = () => {
        let params = {
            batchNo: this.state.batchNo,
            theme: this.state.theme,
        }
        this.getTableData(params)
        this.getTotalData()
    }
    //重置搜索框
    clearSearch = () => {
        this.setState({
            from:'',
            to: '',
            batchNo: '',
            theme: ''
        }, () => {
            this.getTableData()
            this.getTotalData()
        })
    }
    //请求表格数据
    getTableData(params) {
        this.setState({
            boolean: true
        })
        let starttime = this.state.from
        let endtime = this.state.to
        getSmsData({
            ...params,
            beginTime: starttime,
            endTime: endtime,
            current: this.state.current,
            pageSize: this.state.pageSize,
        }).then(res => {
            if (res.data.code === 200) {
                this.setState({
                    boolean: false,
                    tableData: res.data.data.resultDate,
                    total: res.data.data.page.total
                })
            } else if (res.data.code == 800) {
                this.props.history.push({ pathname: "/" });
            }
        })
    }
    //跳转到日详情
    goDetail = (record) => {
        console.log(record)
        window.location = `/#/sms_marketing/sms_dataDetail?id=${record.id}&uploadTime=${record.createTime}&batchNo=${record.batchNo}&clientTotal=${record.clientTotal}`
        let test = [{ path: '/sms_marketing/sms_dataDetail', key: `/sms_marketing/sms_dataDetail/${record.id}`, number: `${record.id}` }]
        EventEmitter.trigger('btn', test[0])
    }
    //请求统计数据
    getTotalData() {
        let starttime = this.state.from
        let endtime = this.state.to
        getSmsTotal({
            beginTime: starttime,
            endTime: endtime,
        }).then(res => {
            if (res.data.code === 200) {
                this.setState({
                    totalData: res.data.data.resultDate,
                })
            } else if (res.data.code == 800) {
                this.props.history.push({ pathname: "/" });
            }
        })
    }
    //下载记录
    downLoadRecord = (record) => {
        console.log(record)
        let title = record.theme + record.createTime.substr(0, 10);
        let myObj = {
            method: 'post',
            url: '/api/sms/marketing/total/download',
            data: { batchNo: record.batchNo, theme: record.theme, smsMarketingId: record.id },
            fileName: title + '.xls',
        }
        exportMethod(myObj)
    }
    //表单值双向绑定
    fstaddonChange = (e) => {
        let InputName = e.target.name;
        let InputValue = e.target.value;
        this.setState({
            [InputName]: InputValue.replace(/\s+/g, ""),
        })
    }
    pageChange = (page) => {
        this.setState({
            current: page
        }, () => {
            this.doSearch()
        })
    }
    //时间选择器范围
    rangeOnChange = (dates, dateStrings) => {
        console.log(dateStrings, '时间选择器')
        this.setState({ from: dateStrings[0], to: dateStrings[1] })
    }
    render() {
        const columns = [
            {
                title: '批次',
                dataIndex: 'batchNo',
                key: 'batchNo',
                render: (value) => {
                    return value || '——'
                }

            },
            {
                title: '主题',
                dataIndex: 'theme',
                key: 'theme',
                render: (value) => {
                    return value || '——'
                }
            },
            {
                title: '总客户数',
                dataIndex: 'clientTotal',
                key: 'clientTotal',
                render: (value) => {
                    return value || '——'
                }

            },
            {
                title: '有效天数',
                dataIndex: 'indate',
                key: 'indate',
                render: (value) => {
                    return value || '——'
                }

            },
            {
                title: '上传时间',
                dataIndex: 'createTime',
                key: 'createTime',
                render: (value) => {
                    return value || '——'
                }

            },
            {
                title: '进件量',
                dataIndex: 'purchaseCount',
                key: 'purchaseCount',
                render: (value) => {
                    if(value==0){
                        return 0
                    }else{
                        return value || '——'
                    }
                }

            },
            {
                title: '放款量',
                dataIndex: 'loanCount',
                key: 'loanCount',
                render: (value) => {
                    if(value==0){
                        return 0
                    }else{
                        return value || '——'
                    }
                }

            },
            {
                title: '放款率',
                dataIndex: 'loanRate',
                key: 'loanRate',
                render: (value) => {
                    return value || '——'
                }

            },
            {
                title: '操作', width: '16.5%', dataIndex: '', pid: 'pid', key: 'z',
                render: (text, record) =>
                    <div>
                        <span>
                            <a className='edit_button' href="javascript:;" onClick={() => this.goDetail(record)}>详情</a>
                        </span>
                        <span>
                            <a className='edit_button' href="javascript:;" onClick={() => this.downLoadRecord(record)}>下载</a>
                        </span>
                    </div>

            },
        ];
        return (
            <div className="audit_content">
                <div className="commom_top" style={{ alignItems: 'center', marginBottom: 30 }}>
                    <label>批次：</label>
                    <Search
                        placeholder="批次ID"
                        onSearch={value => console.log(value)}
                        style={{ width: 290 }}
                        name="batchNo"
                        value={this.state.batchNo}
                        onChange={this.fstaddonChange}
                    />
                    <label>主题：</label>
                    <Search
                        placeholder="Theme"
                        onSearch={value => console.log(value)}
                        style={{ width: 290, marginRight: 10 }}
                        value={this.state.theme}
                        name="theme"
                        onChange={this.fstaddonChange}
                    />
                    <label>上传时间：</label>
                    <RangePicker
                        style={{ width: 290, }}
                        ranges={{
                            Today: [moment(), moment()],
                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                        }}
                        showTime
                        onChange={this.rangeOnChange}
                        format={dateFormat}
                        value={this.state.from === undefined || this.state.to === undefined || this.state.from === "" || this.state.to === "" ? null : [moment(this.state.from, dateFormat), moment(this.state.to, dateFormat)]}
                    />
                    <Button type="primary" style={{
                        width: 170,
                        marginLeft: '4%',
                        height: 40,
                        border: 'none'
                    }}
                        className="common_color_fst"
                        onClick={this.doSearch}
                    >Search</Button>
                    <Button type="primary" style={{
                        width: 170,
                        marginLeft: '4%',
                        height: 40,
                        border: 'none'
                    }}
                        className="common_color_thd"
                        onClick={this.clearSearch}
                    >Refresh</Button>

                </div>
                <div className="pre_content_top">
                    <div className="pre_content_fst pre_content">
                        <p className='pre_content_p'>{this.state.totalData.batchTotal}</p>
                        <p className='pre_content_botp'>总批次数量</p>
                    </div>
                    <span className="pre_content_span"></span>
                    <div className="pre_content_scd pre_content">
                        <p className='pre_content_p'>{this.state.totalData.clientTotal}</p>
                        <p className='pre_content_botp'>总名单数量</p>
                    </div>
                    <span className="pre_content_span"></span>
                    <div className="pre_content_thd pre_content">
                        <p className='pre_content_p'>{this.state.totalData.purchaseCount}</p>
                        <p className='pre_content_botp'>总进件量</p>
                    </div>
                    <span className="pre_content_span"></span>
                    <div className="pre_content_fou pre_content">
                        <p className='pre_content_p'>{this.state.totalData.loanCount}</p>
                        <p className='pre_content_botp'>总放款量</p>
                    </div>
                    <span className="pre_content_span"></span>
                    <div className="pre_content_fiv pre_content">
                        <p className='pre_content_p'>{this.state.totalData.loanRate}</p>
                        <p className='pre_content_botp'>放款率</p>
                    </div>
                </div>
                <div className="table">
                    <Table columns={columns} dataSource={this.state.tableData} loading={this.state.boolean}
                        rowKey={record => record.batchNo}
                        pagination={false} />
                </div>

                <Pagination
                    // showQuickJumper
                    onShowSizeChange={this.onShowSizeChange}//pagesize改变时回调函数
                    pageSizeOptions={["10","50","100"]}   //设置pagesize区间
                    defaultCurrent={1}
                    current={this.state.current}
                    total={this.state.total}
                    onChange={this.pageChange}
                    showTotal={showTotal}
                    style={{ textAlign: 'right', marginTop: 20 }}
                    showSizeChanger={true}
                    pageSize={this.state.pageSize}
                />
            </div>
        )
    }
}
export default withRouter(Smsdata)