/*eslint-disable*/
import React from 'react';
import {Input, Button,Switch ,Tree , Table, Modal,Radio, Pagination, DatePicker, Tooltip, message} from 'antd';
import './index.css';
import axios from 'axios'
import moment from 'moment';
import {withRouter} from 'react-router-dom'
import EventEmitter from '../../utils/events'
var getSelect = []
var treeData = []
let test = []
function showTotal(total) {
    return `Total ${total} items`;
}

var getselect = []
const {Search} = Input;
const key = [];
 class Channel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 1,
            data: [],
            tableData: [],
            searchVal: '',
            current: 1,
            total: 1,
            pageSize: 10,
            boolean: true,
            fstSelect:'',
            thdval:'',
            value4:0,
        }
    }
        //页码改变时触发
        pageChange=(page)=> { 
      
            this.setState({
              current: page
              },()=>{
                this.searchClick()
              })
            }

            onChange4 = e => {
                console.log('radio checked', e.target.value);
                this.setState({
                    value4: e.target.value,
                });
              };
              editonChange4 = e => {
                console.log('radio checked', e.target.value);
                this.setState({
                    editvalue4: e.target.value,
                });
              };


    /*添加modal点击ok*/ 
    addhandleOk=()=>{
       
        axios
        .post(`/api/channel/channelAdd`, {
            code:this.state.fstSelect,
            name:this.state.thdval,
            status:this.state.value4,
        })
        .then(res => {
            if (res.data.code === 200) {        
                message.success(res.data.msg)
                this.setState({
                    value4:0,
                    thdval:'',
                    scdvisible:false,
                    fstSelect:''
                })
                this.searchClick()
            } else if (res.data.code == 800) {
                this.props.history.push({pathname: "/"});
            } else {
                message.error(res.data.msg)
                this.setState({
                    value4:0,
                    thdval:'',
                    scdvisible:false,
                    fstSelect:''
                })
                this.searchClick()
            }
        })
    }
    change=(record)=>{
        this.setState({
            named : record.name,
            delval:record.id,
            fouvisible:true
        })
    }
    edit=(record)=>{
        axios
        .post(`/api/channel/channelGet`, {
            id:record.id,
        })
        .then(res => {
            if (res.data.code === 200) {        
                this.setState({
                    editfstSelect:record.code,
                    editthdval:record.name,
                    editvalue4:record.status,
                })
            } else if (res.data.code == 800) {
                this.props.history.push({pathname: "/"});
            } else {
            }
        })
        this.setState({
            id:record.id,
            fstvisible:true
        })
    }
    edithandleOk=(record,e)=>{
        axios
        .post(`/api/channel/channelEdit`, {
            id:this.state.id,
            code:this.state.editfstSelect,
            name:this.state.editthdval,
            status:this.state.editvalue4,
        })
        .then(res => {
            if (res.data.code === 200) {        
                message.success(res.data.msg)
              
                this.setState({
                    editfstSelect:'',
                    editthdval:'',
                    editvalue4:0
                })
                this.secondTable()
            } else if (res.data.code == 800) {
                this.props.history.push({pathname: "/"});
            } else {
                message.error(res.data.msg)
                  this.setState({
                    editfstSelect:'',
                    editthdval:'',
                    editvalue4:0
                })
            }
        })
        this.setState({
            fstvisible:false,
        })

    }
     /*删除modal点击ok*/ 
    deletehandleOk=()=>{
        axios
        .post(`/api/channel/channelDel`, {
            id:this.state.delval,
        })
        .then(res => {
            if (res.data.code === 200) {        
                message.success(res.data.msg)
                this.searchClick()
                this.setState({
                    thdvisible:false
                })
            } else if (res.data.code == 800) {
                this.props.history.push({pathname: "/"});
            } else {
                message.error(res.data.msg)
                this.searchClick()
                this.setState({
                    thdvisible:false
                })
            }
        })
    }
    changehandleOk=()=>{
        axios
        .post(`/api/channel/updateChannelStatus`, {
            id:this.state.delval,
        })
        .then(res => {
            if (res.data.code === 200) {        
                message.success(res.data.msg)
                this.searchClick()
                this.setState({
                    fouvisible:false
                })
            } else if (res.data.code == 800) {
                this.props.history.push({pathname: "/"});
            } else {
                message.error(res.data.msg)
                this.searchClick()
                this.setState({
                    fouvisible:false
                })
            }
        })
    }
    
    Add=()=>{
        this.setState({
            scdvisible:true,
        })
    }


   
        modelselectThree=(e)=>{
            var value = e.target.value;
            this.setState({
              modalthdSelect:value
            })
           
          }

    handleCancel=()=>{
        this.setState({
            fstvisible:false,
            scdvisible:false,
            thdvisible:false,
            fouvisible:false,
        })
    }
    delete=(record)=>{
        this.setState({
            named : record.name,
            thdvisible:true,
            delval:record.id
        })
    }

//页面初始化加载下面的table
    secondTable = () => {
        this.searchClick()
    }
    //搜索
    searchClick = () => {
        this.setState({
            boolean: true
        })
        axios
            .post(`/api/channel/channelList`, {
                pageNum:this.state.current,
                pageSize:this.state.pageSize,
                name:this.state.searchVal
            })
            .then(res => {
                if (res.data.code === 200) {

                    const data1 = [];
                    const data = res.data.data.resultData

                    for (let i = 0; i < data.length; i++) {
                        data[i].key = i;
                        data1.push(data[i]);
                    }
                    this.setState({
                        tableScdData: data1,
                        boolean: false,
                        length:res.data.data.page.total
                    })
                } else if (res.data.code == 800) {
                    this.props.history.push({pathname: "/"});
                } else {
                    
                }
            })
    }
    handClick = (item) => {
        console.log('1111111122222233333', item)
    }




        //输入框
        searchIpt(e){
            var value = e.target.value;
            this.setState({ searchVal: value.replace(/\s+/g,"") })
          }
    scdonChange=(e)=>{
        let value=e.target.value;
        this.setState({
            scdval:value
        })
    }
    thdonChange=(e)=>{
        let value=e.target.value;
        this.setState({
            thdval:value
        })
    }
    editthdonChange=(e)=>{
        let value=e.target.value;
        this.setState({
            editthdval:value
        })
    }
    fstselect=(e)=>{
        var value = e.target.value;
            this.setState({
                fstSelect:value
            })
      }
      editfstselect=(e)=>{
        var value = e.target.value;
            this.setState({
                editfstSelect:value
            })
      }
    componentDidMount() {
        this.secondTable()
    }

    render() {
       
      const { selectedRowKeys } = this.state;
      const rowSelection = {
        selectedRowKeys,
        onChange: this.onSelectChange,
         columnWidth:10
      };
        const columns = [

            {
                title: 'code',
                dataIndex: 'code',
                key: 'code',
                render: (value) => {
                    return value || '——'
                }
            },
            {
                title: 'name',
                dataIndex: 'name',
                key: 'name',
                render: (value) => {
                    return value || '——'
                }
                

            },
            {
                title: 'status',
                dataIndex: 'status',
                key: 'status',
                id:'id',
                render: (value) => {
                    if(value==1){
                        return <span style={{color:'orange'}}>close</span>
                    } else if(value==-1){
                        return <span style={{color:'red'}}>deleted</span>
                    } else if(value==0){
                        return <span style={{color:'blue'}}>open</span>
                    } 
                }
            },
            {
                title: 'creation time',
                dataIndex: 'createdTime',
                key: 'createdTime',
                render: (value) => {
                    let timeaudit= new Date(value).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
                    let auditimes=moment(new Date(timeaudit)).format("YYYY-MM-DD HH:mm:ss");
                    return auditimes
                }
            },
            {
                title: 'update time',
                dataIndex: 'updatedTime',
                key: 'updatedTime',
                render: (value) => {
                    let timeaudit= new Date(value).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
                    let auditimes=moment(new Date(timeaudit)).format("YYYY-MM-DD HH:mm:ss");
                    return auditimes
                }
            },   
            { title: 'Operation', dataIndex: '', key: 'operation', render: (text, record) => 
            <span style={{ display: 'flex' , flexDirection: 'row', justifyContent:'center',margin:0}}>
            
                <a className='edit_button' href="javascript:;"   onClick={() => this.change(record)}>{record.status==0? "close" : 'open'}</a>
                <a className='edit_button' href="javascript:;" onClick={() => this.edit(record)}>edit</a>
                 <a className='edit_button' href="javascript:;" onClick={() => this.delete(record)}>Delete</a>
              </span>
           
            },
        ];
        return (
            <div className="menu_content">
             <div style={{width:'98%',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                <div>
                    <Search
                        placeholder="name"
                        onSearch={value => console.log(value)}
                        style={{width: 290,marginRight:10,marginLeft:10}}
                        value={this.state.searchVal}
                        onChange={this.searchIpt.bind(this)}
                    />
                    <Button type="primary" style={{width:170,marginRight:20,height:40,border:'none'}} className="common_color" onClick={this.searchClick}>Search</Button>
                </div>
                
                    <Button className='tab2_button' type="primary" style={{ marginTop: 15 }} onClick={this.Add}>Add</Button></div>
                <div className="table">
                    <Table columns={columns} className='old_table'  dataSource={this.state.tableScdData} loading={this.state.boolean}  pagination={false}/>
                </div>
                <Pagination
                defaultCurrent={1} 
                current={this.state.current}
                total={this.state.length} 
                onChange={this.pageChange}  
                pageSize={this.state.pageSize}
                showTotal={showTotal}
                style={{textAlign:'right',marginTop:20}}
                />


                <Modal
                title="add" 
                visible={this.state.scdvisible} 
                onOk={this.addhandleOk}
                onCancel={this.handleCancel}
                >
                <div style={{marginTop:10}}>
                    <span className='product_span'>name:</span>
                    <Input style={{marginTop:5}} value = {this.state.thdval} allowClear  onChange={this.thdonChange} />
                </div>
                {/* <div style={{marginTop:10}}>
                    <span className='product_span'>code:</span>
                    <Input style={{marginTop:5}} value = {this.state.fstSelect} type='number' allowClear  onChange={this.fstselect} />
                </div> */}
                    <Radio.Group onChange={this.onChange4} value={this.state.value4}>
                    <Radio value={0}>enable</Radio>
                    <Radio value={1}>disable</Radio>
                </Radio.Group>
                <br />

                
                </Modal>
                <Modal
                title="Edit" 
                visible={this.state.fstvisible}
                onOk={this.edithandleOk}
                onCancel={this.handleCancel}
                >
                <div style={{marginTop:10}}>
                    <span className='product_span'>name:</span>
                    <Input style={{marginTop:5}} value = {this.state.editthdval} allowClear  onChange={this.editthdonChange} />
                </div>
                {/* <div style={{marginTop:10}}>
                    <span className='product_span'>code:</span>
                    <Input style={{marginTop:5}} value = {this.state.editfstSelect} type='number' allowClear  onChange={this.editfstselect} />
                </div> */}
                    <Radio.Group onChange={this.editonChange4} value={this.state.editvalue4}>
                    <Radio value={0}>enable</Radio>
                    <Radio value={1}>disable</Radio>
                </Radio.Group>
                <br />
                </Modal>
                <Modal
                visible={this.state.fouvisible} 
                onOk={this.changehandleOk}
                onCancel={this.handleCancel}
                >
                <input value={this.state.delval} style={{display:'none'}}/>
               <p>Make sure you want to change {this.state.named}?</p>
                
                </Modal>
                
                <Modal
                visible={this.state.thdvisible} 
                onOk={this.deletehandleOk}
                onCancel={this.handleCancel}
                >
                <input value={this.state.delval} style={{display:'none'}}/>
               <p>Make sure you want to delete {this.state.named}?</p>
                
                </Modal>
            </div>

        )
    }
}
export default withRouter(Channel)