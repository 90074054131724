/*eslint-disable*/
import {
    Button,
    DatePicker,
    message,
    Input,
    Pagination,
    Table,
    Tooltip,
    Modal,
  } from "antd";
  import axios from "axios";
  import moment from "moment";
  import React from "react";
  import "./index.css";
  import { withRouter } from "react-router-dom";
  import EventEmitter from "../../utils/events";
  const DateFormat = "YYYY-MM-DD HH:mm:ss" || undefined;
  const { RangePicker } = DatePicker;
  const { Search } = Input;
  const dateFormat = "YYYY-MM-DD" || undefined;
  var whitchshow = [];
  
  function showTotal(total) {
    return `Total ${total} items`;
  }
  class Abnormal extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        fouSelect: 1,
        searchemail: "",
        searchname: "",
        searchphone: "",
        searchVal: "",
        searchacc: "",
        current: 1,
        fstinpt: "",
        pageSize: 10,
        modal_text: "",
        boolean: true,
      };
    }
    //页码改变时触发
    pageChange = (page) => {
      this.setState(
        {
          current: page,
        },
        () => {
          this.searchClick();
        }
      );
    };
    rangeOnChange = (dates, dateStrings) => {
      this.setState({ from: dateStrings[0], to: dateStrings[1] });
    };
    dateonChange = (date, dateString) => {
      this.setState({ datepiker: dateString });
    };
    scdhandleOk = () => {
      if (this.state.datepiker == "") {
        alert("Repayment time can not be empty");
        return false;
      }
      axios
        .post(`/api/loanOrder/modifyOrderStatus`, {
          id: this.state.scdmodalinpt,
          type: "1",
          repayTime: this.state.datepiker,
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.setState({
              scdvisible: false,
              type: "",
              datepiker: "",
            });
            message.success(res.data.msg);
            this.searchClick();
          } else if (res.data.code == 800) {
            this.props.history.push({ pathname: "/" });
          } else {
            message.error(res.data.msg);
            this.setState({
              scdvisible: false,
              type: "",
              datepiker: "",
            });
            this.searchClick();
          }
        });
    };
    handleCancel = () => {
      this.setState({
        fstvisible: false,
        scdvisible: false,
        fstSelect: "",
        type: "",
        datepiker: "",
      });
    };
    //重置按钮
    reloadClick(e) {
      this.setState(
        {
          fouSelect: 1,
          searchemail: "",
          searchname: "",
          searchphone: "",
          searchVal: "",
          searchacc: "",
          from: undefined,
          to: undefined,
          boolean: true,
        },
        () => {
          this.searchClick();
        }
      );
    }
  
    componentDidMount() {
      this.searchClick();
    }
    text(record) {
      window.location =
        "/#/details/index?id=" + record.loanRecordId + "&uid=" + record.uid;
      let test = [
        {
          path: "/details/index",
          key: `/details/index/${record.loanRecordId}/${record.uid}`,
          number: `${record.loanRecordId}/${record.uid}`,
        },
      ];
      EventEmitter.trigger("btn", test[0]);
      EventEmitter.trigger("idanduid", test[0].number);
    }
    modify(record) {
      console.log(record);
      this.setState({
        scdvisible: true,
        scdmodalinpt: record.loanRecordId,
        type: "1",
      });
    }
    again(record) {
      this.setState({
        modal_text: "I am sure to refinance",
        fstvisible: true,
        thdmodalinpt: record.loanRecordId,
      });
    }
    reject(record) {
      console.log(record);
      this.setState({
        modal_text: "I have confirmed that the loan failed",
        thdmodalinpt: record.loanRecordId,
        fstvisible: true,
        type: "2",
      });
    }
    fsthandleOk = () => {
      if (this.state.type == 2) {
        axios
          .post(`/api/loanOrder/modifyOrderStatus`, {
            id: this.state.thdmodalinpt,
            type: "2",
          })
          .then((res) => {
            if (res.data.code === 200) {
              this.setState({
                fstvisible: false,
                type: "",
              });
              message.success(res.data.msg);
              this.searchClick();
            } else if (res.data.code == 800) {
              this.props.history.push({ pathname: "/" });
            } else {
              message.error(res.data.msg);
              this.setState({
                fstvisible: false,
                type: "",
              });
              this.searchClick();
            }
          });
      } else {
        axios
          .post(`/api/loanFront/passLoanNew`, {
            loanRecordId: this.state.thdmodalinpt,
          })
          .then((res) => {
            if (res.data.code === 200) {
              this.setState({
                fstvisible: false,
                type: "",
              });
              message.success(res.data.msg);
              this.searchClick();
            } else if (res.data.code == 800) {
              this.props.history.push({ pathname: "/" });
            } else {
              message.error(res.data.msg);
              this.setState({
                fstvisible: false,
                type: "",
              });
              this.searchClick();
            }
          });
      }
    };
    selectFst(e) {
      var value = e.target.value;
      this.setState({
        fstSelect: value,
      });
    }
  
    //选择apptype
    selectFou(e) {
      var value = e.target.value;
      this.setState({
        fouSelect: value,
      });
    }
    //输入框
    searchIpt(e) {
      var value = e.target.value;
      this.setState({ searchVal: value.replace(/\s+/g, "") });
    }
  
    //手机号输入框
    searchphoneIpt(e) {
      var value = e.target.value;
      this.setState({ searchphone: value.replace(/\s+/g, "") });
    }
    searchaccIpt(e) {
      var value = e.target.value;
      this.setState({ searchacc: value.replace(/\s+/g, "") });
    }
  
    //email输入框
    searchemailIpt(e) {
      var value = e.target.value;
      this.setState({ searchemail: value.replace(/\s+/g, "") });
    }
  
    //名字输入框
    searchnameIpt(e) {
      var value = e.target.value;
      this.setState({ searchname: value.replace(/\s+/g, "") });
    }
    //搜索
    searchClick = () => {
      axios
        .post(`/api/loanOrder/getLendingLoanList`, {
          pageSize: this.state.pageSize,
          current: this.state.current,
          status: this.state.fouSelect,
          beginTime: this.state.from,
          endTime: this.state.to,
          email: this.state.searchemail,
          accountNo: this.state.searchacc,
          flowNo: this.state.searchphone,
          mobile: this.state.searchname,
          indentNo: this.state.searchVal,
        })
        .then((res) => {
          if (res.data.code === 200) {
            const data1 = [];
            const data = res.data.data.resultDate;
  
            for (let i = 0; i < data.length; i++) {
              data[i].key = i;
              data1.push(data[i]);
            }
            this.setState({
              tableData: data1,
              total: res.data.data.page.total,
              boolean: false,
            });
          } else if (res.data.code == 800) {
            this.props.history.push({ pathname: "/" });
          } else {
          }
        });
    };
    render() {
      const columns = [
        {
          title: "Order No",
          dataIndex: "orderNo",
          key: "orderNo",
        },
        {
          title: "Pay PaystackNo",
          dataIndex: "flowNo",
          key: "flowNo",
        },
        {
          title: "Account No",
          dataIndex: "accountNo",
          key: "accountNo",
        },
        {
          title: "Bank email",
          dataIndex: "email",
          key: "email",
        },
        {
          title: "Order Created Time",
          dataIndex: "createdTime",
          key: "createdTime",
          render: (value) => {
            if (value == null) {
              return "";
            } else {
              let timeaudit =
                new Date(value).getTime() +
                new Date().getTimezoneOffset() * 60 * 1000 -
                0 * 60 * 60 * 1000;
              let auditimes = moment(new Date(timeaudit)).format(
                "YYYY-MM-DD HH:mm:ss"
              );
              return auditimes;
            }
          },
        },
        {
          title: "Phone",
          dataIndex: "mobile",
          key: "mobile",
        },
        {
          title: "Amount",
          dataIndex: "amount",
          key: "amount",
          render: (value) => {
            var num = value / 100;
            num = num + "";
            var str = "";
            for (var i = num.length - 1, j = 1; i >= 0; i--, j++) {
              if (j % 3 == 0 && i != 0) {
                //每隔三位加逗号，过滤正好在第一个数字的情况
                str += num[i] + ","; //加千分位逗号
                continue;
              }
              str += num[i]; //倒着累加数字
            }
            return str.split("").reverse().join("");
          },
        },
        {
          title: "Transaction time",
          dataIndex: "transTime",
          key: "transTime",
          render: (value) => {
            if (value == null) {
              return "";
            } else {
              let timeaudit =
                new Date(value).getTime() +
                new Date().getTimezoneOffset() * 60 * 1000 -
                0 * 60 * 60 * 1000;
              let auditimes = moment(new Date(timeaudit)).format(
                "YYYY-MM-DD HH:mm:ss"
              );
              return auditimes;
            }
          },
        },
  
        {
          title: "Operation",
          dataIndex: "Operation",
          key: "Operation",
          width: 70,
          render: (text, record) => (
            <span style={{ display: "flex", flexDirection: "row" }}>
              <div>
                <Button
                  type="primary"
                  style={{ width: 60, margin: 2 }}
                  className="detailsbutton common_color"
                  size="small"
                  onClick={() => this.again(record)}
                >
                  Again
                </Button>
              </div>
            </span>
          ),
        },
        {
          title: "Operation",
          dataIndex: "operating",
          key: "operating",
          width: 140,
          id: "loanRecordId",
          uid: "uid",
          // fixed:'right',
          render: (text, record, index) => (
            <span style={{ display: "flex", flexDirection: "row" }}>
              <div>
                <Button
                  type="primary"
                  style={{ width: 60, margin: 2 }}
                  className="detailsbutton common_color"
                  size="small"
                  onClick={() => this.modify(record)}
                >
                  Modify
                </Button>
                <Button
                  type="primary"
                  style={{ width: 60, margin: 2 }}
                  className="detailsbutton common_color"
                  size="small"
                  onClick={() => this.reject(record)}
                >
                  Reject
                </Button>
              </div>
            </span>
          ),
        },
      ];
      return (
        <div className="credit_order">
          <div className="myorder_content">
            <div className="flutter">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div className="mid_left" style={{ width: "20%" }}>
                  <Search
                    placeholder="Order No"
                    onSearch={(value) => console.log(value)}
                    style={{ width: "100%" }}
                    value={this.state.searchVal}
                    onChange={this.searchIpt.bind(this)}
                  />
                </div>
                <div
                  className="mid_left"
                  style={{ width: "20%", marginLeft: "5%" }}
                >
                  <Search
                    placeholder="Paystack No"
                    onSearch={(value) => console.log(value)}
                    style={{ width: "100%" }}
                    value={this.state.searchphone}
                    onChange={this.searchphoneIpt.bind(this)}
                  />
                </div>
                <div style={{ width: "50%", marginLeft: "5%" }}>
                <Button type="primary" style={{width:170,marginRight:20,height:40,border:'none'}}  className="common_color" onClick={this.searchClick.bind(this)}>Search</Button>
                    <Button type="primary" style={{width:170,height:40,border:'none'}} className="common_color_scd" onClick={this.reloadClick.bind(this)}>Refresh</Button>
                </div>
                {/* <div className="mid_left" style={{width:'20%',marginLeft: '5%'}}>
                          <Search
                              placeholder="Account No"
                              onSearch={value => console.log(value)}
                              style={{width: "100%"}}
                              value={this.state.searchacc}
                              onChange={this.searchaccIpt.bind(this)}
                          />
                      </div>
                      
                      <div className="top_mid" style={{width:'20%',flexShrink:2,marginLeft:'5%',}}>
                          <div className="rangepick" >
                              <RangePicker
                              style={{width: "100%",}}
                              ranges={{
                              Today: [moment(), moment()],
                              'This Month': [moment().startOf('month'), moment().endOf('month')],
                              }}
                              showTime
                              format={dateFormat}
                              onChange={this.rangeOnChange}
                              value={this.state.from===undefined||this.state.to===undefined||this.state.from===""||this.state.to===""?null:[moment(this.state.from, dateFormat), moment(this.state.to, dateFormat)]}
                              />
                          </div>
                      </div>
                  </div>
                  <div className="top" style={{justifyContent:'flex-start',marginTop:20}}>
                      <div className="mid_left" style={{width: '20%', }}>
                          <Search
                              placeholder="Bank email"
                              onSearch={value => console.log(value)}
                              style={{width: "100%"}}
                              value={this.state.searchemail}
                              onChange={this.searchemailIpt.bind(this)}
                          />
                      </div>
          
                      <div className="mid_left" style={{width: '20%',marginLeft: '5%'}}>
                          <Search
                              placeholder="Phone"
                              onSearch={value => console.log(value)}
                              style={{width: "100%"}}
                              value={this.state.searchname}
                              onChange={this.searchnameIpt.bind(this)}
                          />
                      </div>
              
                      <div className="top_right" style={{width:'20%',flexShrink:1,marginLeft: '5%'}}>
                          <select className="abnormal_form_select" value={this.state.fouSelect} style={{width: "100%"}} onChange={this.selectFou.bind(this)}>
                          <option value="1">Payment Abnormal</option>
                          <option value="2">Failed Payment</option>
                          <option value="3">paystack-pay failure</option>
                          <option value="0">All</option>
                          </select>
                      </div> */}
              </div>
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={this.state.tableData}
            loading={this.state.boolean}
            onChange={this.handleChange}
            pagination={false}
          />
          <Pagination
            // showQuickJumper
            defaultCurrent={1}
            current={this.state.current}
            total={this.state.total}
            onChange={this.pageChange}
            showTotal={showTotal}
            style={{ textAlign: "right", marginTop: 20 }}
            pageSize={this.state.pageSize}
          />
          <Modal
            visible={this.state.fstvisible}
            onOk={this.fsthandleOk}
            onCancel={this.handleCancel}
            okText="CONFIRM"
            cancelText="CANCEL"
          >
            <input style={{ display: "none" }} value={this.state.thdmodalinpt} />
            <p>{this.state.modal_text}</p>
          </Modal>
          <Modal
            visible={this.state.scdvisible}
            onOk={this.scdhandleOk}
            onCancel={this.handleCancel}
            okText="CONFIRM"
            cancelText="CANCEL"
          >
            <input style={{ display: "none" }} value={this.state.scdmodalinpt} />
            <span>Repayment time:</span>
            <DatePicker
              style={{ marginLeft: "4%", width: 290 }}
              showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
              value={
                this.state.datepiker === undefined || this.state.datepiker === ""
                  ? null
                  : moment(this.state.datepiker, DateFormat)
              }
              onChange={this.dateonChange}
            />
          </Modal>
        </div>
      );
    }
  }
  export default withRouter(Abnormal);
  