/*eslint-disable*/
import React from 'react';
import {Input, Button,Switch ,Tree , Table, Modal,Radio, Pagination, DatePicker, Tooltip, message} from 'antd';
import './index.css';
import axios from 'axios'
import moment from 'moment';
import {withRouter} from 'react-router-dom'
import EventEmitter from '../../utils/events'
var getSelect = []
var treeData = []
let test = []
function showTotal(total) {
    return `Total ${total} items`;
}

const options = [
    { label: 'Normal', value: 0 },
    { label: 'Disable', value: -1 },
  ];
  const optionsWithDisabled = [
    { label: 'General user', value: 0 },
    { label: 'system administrator', value: 1 },
  ];
var getselect = []
const { TreeNode } = Tree;
const {Search} = Input;
const key = [];
 class BankManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 1,
            data: [],
            tableData: [],
            selectedRowKeys:[],
            searchVal: '',
            current: 1,
            total: 1,
            pageSize: 10,
            boolean: true,
            ishide: false,
            isshow: true,
            ids:'',
            mobilephone:'',
            opened: false,
            arr: [],
            autoExpandParent: true,
            checkedKeys: [],
            selectedKeys: [],
            value1:0,
            value2:0,
            treeInfo: [],//用来存放数组的数据
            childrenIds: [], //存放所有子节点的数组
        }
    }
    
     




        //页码改变时触发
        pageChange=(page)=> { 
      
            this.setState({
              current: page
              },()=>{
                this.searchClick()
              })
            }


    /*添加modal点击ok*/ 
    addhandleOk=()=>{
        axios
        .post(`/api/bank/addBank`, {
            code:this.state.fstAddVal,
            name:this.state.scdAddVal,
        })
        .then(res => {
            if (res.data.code === 200) {        
                message.success(res.data.msg)
                this.searchClick()
                this.setState({
                    fstAddVal:'',
                    scdvisible:false,
                    scdAddVal:''
                })
                this.searchClick()
            } else if (res.data.code == 800) {
                this.props.history.push({pathname: "/"});
            } else {
                message.error(res.data.msg)
                this.setState({
                    fstAddVal:'',
                    scdvisible:false,
                    scdAddVal:''
                })
                this.searchClick()
            }
        })
    }
     /*删除modal点击ok*/ 
    deletehandleOk=()=>{
        axios
        .post(`/api/bank/delBank`, {
            id:this.state.delval,
        })
        .then(res => {
            if (res.data.code === 200) {        
                message.success(res.data.msg)
                this.searchClick()
                this.setState({
                    thdvisible:false
                })
            } else if (res.data.code == 800) {
                this.props.history.push({pathname: "/"});
            } else {
                message.error(res.data.msg)
                this.searchClick()
                this.setState({
                    thdvisible:false
                })
            }
        })
    }
   

    Add=()=>{
        this.setState({
            scdvisible:true,
        })
    }




    handleCancel=()=>{
        this.setState({
            fstvisible:false,
            scdvisible:false,
            thdvisible:false,
            fouvisible:false,
        })
    }
    delete=(record)=>{
        this.setState({
            named : record.name,
            thdvisible:true,
            delval:record.id
        })
    }

//页面初始化加载下面的table
    secondTable = () => {
        this.searchClick()
    }
    //搜索
    searchClick = () => {
        this.setState({
            boolean: true
        })
        axios
            .post(`/api/bank/bankList`, {
                pageNum:this.state.current,
                pageSize:this.state.pageSize,
                name:this.state.searchVal
            })
            .then(res => {
                if (res.data.code === 200) {

                    const data1 = [];
                    const data = res.data.data.resultData

                    for (let i = 0; i < data.length; i++) {
                        data[i].key = i;
                        data1.push(data[i]);
                    }
                    this.setState({
                        tableScdData: data1,
                        boolean: false,
                        length:res.data.data.page.total
                    })
                } else if (res.data.code == 800) {
                    this.props.history.push({pathname: "/"});
                } else {
                    
                }
            })
    }
    handClick = (item) => {
        console.log('1111111122222233333', item)
    }
    fstonChange=(e)=>{
        let value=e.target.value;
        this.setState({
            fstval:value
        })
    }

    /*添加modal的input开始 */
    fstAddOnChange=(e)=>{
        let value=e.target.value;
        this.setState({
            fstAddVal:value
        })
    }
    scdAddOnChange=(e)=>{
        let value=e.target.value;
        this.setState({
            scdAddVal:value
        })
    }
    /*添加modal的input结束 */

        //输入框
        searchIpt(e){
            var value = e.target.value;
            this.setState({ searchVal: value.replace(/\s+/g,"") })
          }
    scdonChange=(e)=>{
        let value=e.target.value;
        this.setState({
            scdval:value
        })
    }
    thdonChange=(e)=>{
        let value=e.target.value;
        this.setState({
            thdval:value
        })
    }
    componentDidMount() {
        this.secondTable()
    }

    render() {
        const columns = [
            {
                title: 'bank code',
                dataIndex: 'code',
                key: 'code',
                render: (value) => {
                    return value || '——'
                }
            },
            {
                title: 'bank name',
                dataIndex: 'name',
                key: 'name',
                render: (value) => {
                    return value || '——'
                }

            },
            {
                title: 'creation time',
                dataIndex: 'createdTime',
                key: 'createdTime',
                render: (value) => {
                    let timeaudit= new Date(value).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
                    let auditimes=moment(new Date(timeaudit)).format("YYYY-MM-DD HH:mm:ss");
                    return auditimes
                }
            },
           
            {
                title: 'status',
                dataIndex: 'status',
                key: 'status',
                id:'id',
                render: (value) => {
                    if(value==1){
                        return <span style={{color:'blue'}}>normal</span>
                    } else if (value == -1){
                        return <span style={{color:'red'}}>deleted</span>
                    }
                }
            },
            { title: 'Operation', dataIndex: '', key: 'operation', render: (text, record) => 
            <span style={{ display: 'flex' , flexDirection: 'row', justifyContent:'center',margin:0}}>
                 <a className='edit_button' href="javascript:;" onClick={() => this.delete(record)}>Delete</a>
              </span>
           
            },
        ];
        return (
            <div className="menu_content">
             <div style={{width:'98%',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                <div>
                    <Search
                        placeholder="bank name"
                        onSearch={value => console.log(value)}
                        style={{width: 290,marginRight:10,marginLeft:10}}
                        value={this.state.searchVal}
                        onChange={this.searchIpt.bind(this)}
                    />
                    <Button type="primary" style={{width:170,marginRight:20,height:40,border:'none'}}  className="common_color" onClick={this.searchClick}>Search</Button>
                </div>
                
                    <Button className='tab2_button' type="primary" style={{ marginTop: 15 }} onClick={this.Add}>Add</Button></div>
                <div className="table">
                    <Table columns={columns} className='old_table'  dataSource={this.state.tableScdData} loading={this.state.boolean}  pagination={false}/>,
                </div>
                <Pagination
          defaultCurrent={1} 
          current={this.state.current}
          total={this.state.length} 
          onChange={this.pageChange} 
          pageSize={this.state.pageSize}
          showTotal={showTotal}
          style={{textAlign:'right',marginTop:20}}
          />
                <Modal
                title="New Role" 
                visible={this.state.scdvisible} 
                onOk={this.addhandleOk}
                onCancel={this.handleCancel}
                >
               <div className='product_div'>
                    <div className='common_div'>
                        <span className='product_span'>bank No</span>
                        <Input value = {this.state.fstAddVal} type='number' allowClear  onChange={this.fstAddOnChange} />
                    </div>
                    <div className='common_div'>
                        <span className='product_span'>bank name</span>
                        <Input value = {this.state.scdAddVal}  allowClear  onChange={this.scdAddOnChange} />
                    </div>
                </div>
                </Modal>

                <Modal
                visible={this.state.thdvisible} 
                onOk={this.deletehandleOk}
                onCancel={this.handleCancel}
                >
                <input value={this.state.delval} style={{display:'none'}}/>
               <p>Make sure you want to delete {this.state.named}?</p>
                
                </Modal>
            </div>

        )
    }
}
export default withRouter(BankManagement)