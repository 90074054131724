import React from "react";
import {
  Input,
  Button,
  Table,
  Modal,
  Pagination,
  DatePicker,
  message,
  Row,
  Col,
} from "antd";
import moment from "moment";
import axios from "axios";
import "./index.css";
import { withRouter } from "react-router-dom";
import EventEmitter from "../../../utils/events";
import { thousandSignNumber, formatT0Time } from "../../../utils/index";

function showTotal(total) {
  return `Total ${total} items`;
}

var getSelect = [];
var getSelect1 = [];

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD" || undefined;
class ReduceAudit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      from: "",
      to: "",
      current: 1,
      pageSize: 10,
      searchVal: "",
      searchOrderNo: "",
      searchPhone: "",
      indentNo: "",
      appCode: "",
      loanStatuses: "",
      id: "",
      uid: "",
      topboolean: true,
      reviewbutton: { display: "block" },
      detailsbutton: { display: "block" },
      fstSelect: 0,
      selectedRowKeys: [],
      approvedVisible:false,
      selectedVisible:false,
      scdafter:false,
      scdafterCancel:false,
      isShow:false,
      sortorder:0,
      actualshouldbackFee:null
    };
  }

  //排序
  handleChange = (pagination, filters, sorter) => {
    console.log(sorter.order);
    if(sorter.order == 'ascend'){
      this.state.sortorder = 2
    }else if(sorter.order == 'descend'){
      this.state.sortorder = 1
    }else{
      this.state.sortorder = 0 
    }
    this.searchClick()
  };

  // 输入框
  handleSearchOrderNo(e) {
    var value = e.target.value;
    this.setState({ searchOrderNo: value.replace(/\s+/g, "") });
  }

  // 输入框
  handleSearchPhone(e) {
    var value = e.target.value;
    this.setState({ searchPhone: value.replace(/\s+/g, "") });
  }

  handleActualshouldbackFee(e) {
    var value = e.target.value;
    this.setState({ actualshouldbackFee: value.replace(/\s+/g, "") });
  }
  
  //时间控件
  rangeOnChange = (dates, dateStrings) => {
    this.setState({ from: dateStrings[0], to: dateStrings[1] });
  };
  //页码改变时触发
  pageChange = (page) => {
    this.setState(
      {
        current: page,
      },
      () => {
        this.searchClick();
      }
    );
  };
  onShowSizeChange = (current, pageSize) => {
    this.setState(
      {
        pageSize: pageSize,
      },
      () => {
        this.searchClick();
      }
    );
  };
  //获取select
  select = () => {
    axios
      .post(`/api/reduce/getApplyUserList`, {
        type: "1",
        roleType: 2,
        roleManagerType: 12,
      })
      .then((res) => {
        if (res.data.code === 200) {
          getSelect = [];
          let data = res.data.data;
          for (let i = 0; i < res.data.data.length; i++) {
            if (i == 0) {
              getSelect.push(<option value="">AuditUser(all)</option>);
            }
            getSelect.push(
              <option id={data} value={data[i].id}>
                {data[i].userName}
              </option>
            );
            // console.log("select", getSelect);
            this.setState({
              getSelect: getSelect,
            });
          }
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        }
      });
  };

  secondSelect = () => {
    axios
      .post(`/api/reduce/getAuditUserList`, {
        type: "2",
        roleType: 2,
        roleManagerType: 12,
      })
      .then((res) => {
        if (res.data.code === 200) {
          getSelect1 = [];
          let data = res.data.data;
          for (let i = 0; i < res.data.data.length; i++) {
            if (i == 0) {
              getSelect1.push(<option value="0">ApplyUser(all)</option>);
            }
            getSelect1.push(
              <option id={data} value={data[i].id}>
                {data[i].userName}
              </option>
            );
            // console.log("select", getSelect1);
            this.setState({
              getSelect1: getSelect1,
            });
          }
        } else if (res.data.code == 800) {
          // this.props.history.push({ pathname: "/"});
        }
      });
  };
  //重置按钮
  reloadClick(e) {
    this.setState(
      {
        fstSelect: 0,
        scdSelect: "",
        scdSelectt: "",
        thdSelect: "",
        fouSelect: "",
        searchVal: "",
        from: undefined,
        to: undefined,
        id: "",
        selectFou: "",
        reduceAmt_val: "",
        reduceRson: "",
        fsttextarea: "",
        hideid: "",
        hideidno: "",
        topboolean: true,
      },
      () => {
        this.searchClick();
      }
    );
  }
  scdhandleOk = (e) => {
    // if (this.state.getVal < this.state.reduceAmt_val) {
    //   message.error(
    //     "The amount of the reduction cannot be greater than the amount of repayment."
    //   );
    //   return false;
    // } else {
    axios
      .post(`/api/reduce/isPassReduce`, {
        id: this.state.hideid,
        reduceAmt: this.state.reduceAmt_val,
        isPass: true,
        recordId: this.state.hidrecordId,
      })
      .then((res) => {
        message.success(res.data.msg);
        if (res.data.code == 200) {
          this.setState({
            scdvisible: false,
            hideid: "",
            reduceAmt_val: "",
            hidrecordId: "",
            selectedRowKeys:""
          });
          // this.reloadClick();
          this.searchClick();
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
          this.reloadClick();
        }
      });
    // this.setState({
    //   scdvisible: false,
    // });
    // }
  };
  onShowSizeChange = (current, pageSize) => {
    this.setState(
      {
        pageSize: pageSize,
      },
      () => {
        this.searchClick();
      }
    );
  };

  componentDidMount() {
    this.select();
    this.secondSelect();
    this.setState({}, () => {
      this.searchClick();
    });
  }
  redecu_reson(e) {
    var value = e.target.value;
    this.setState({
      reduceRson: value,
    });
  }

  //控制重新分配按钮model
  showScdModal = (record) => {
    let a = record.id;
    let b = record.indentNo;
    let c = record.applyReduceAmt / 100;
    let d = record.remark;
    let e = record.reduceReason;
    let f = record.actualshouldbackFee / 100;
    let g = record.recordId;
    this.setState({
      hide: true,
      show: false,
      scdvisible: true,
      record: record,
      getVal: f,
      hideid: a,
      hideidno: b,
      reduceAmt_val: c,
      fsttextarea: d,
      reduceRson: e,
      hidrecordId: g,
    });
  };

  handleCancel = (e) => {
    this.setState({
      scdvisible: false,
      selectedVisible:false
    });
    console.log(e);
    this.setState({
      thdvisible: false,
      fstvisible: false,
      scdvisible: false,
      scdSelect: "",
      id: "",
      approvedVisible:false,
      selectedRowKeys:""
    });
  };

  handleUnapproved = (e) => {
    axios
      .post(`/api/reduce/isPassReduce`, {
        id: this.state.hideid,
        recordId: this.state.hidrecordId,
        reduceAmt: this.state.reduceAmt_val,
        isPass: false,
      })
      .then((res) => {
        if (res.data.code == 200) {
          message.error(res.data.msg);
          this.setState({
            boolean: true,
            selectedRowKeys:""
          });
          this.reloadClick();
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
          this.reloadClick();
        }
      });
    this.setState({
      scdvisible: false,
    });
    this.setState({
      thdvisible: false,
      fstvisible: false,
      scdvisible: false,
      scdSelect: "",
      id: "",
    });
  };
  text(record, item) {
    window.location =
      "/#/details/index?id=" + record.recordId + "&uid=" + record.uid;
    let test = [
      {
        path: "/details/index",
        key: `/details/index/${record.recordId}/${record.uid}`,
        number: `${record.recordId}/${record.uid}`,
      },
    ];
    EventEmitter.trigger("btn", test[0]);
    EventEmitter.trigger("idanduid", test[0].number);
  }
  showFstModal = (record) => {
    var id = record.id;
    this.setState({
      fstinpt: id,
      fstvisible: true,
    });
    // this.gradeChange()
  };

  changeTime = (params) => {};
  //搜索按钮
  searchClick = (page) => {
    this.setState({
      topboolean: true,
    });
    axios
      .post(`/api/reduce/getReduceList`, {
        pageSize: this.state.pageSize,
        current: page ? 1 : this.state.current,
        beginTime: this.state.from,
        endTime: this.state.to,
        searchParam: this.state.searchVal,
        appCode: this.state.fouSelect,
        loanStatuses: this.state.fstSelect,
        indentNo: this.state.searchOrderNo,
        mobile: this.state.searchPhone,
        id: this.state.thdSelect,
        uid: this.state.scdSelect,
        sortorder:this.state.sortorder,
        actualshouldbackFee:this.state.actualshouldbackFee
      })
      .then((res) => {
        if (res.data.code === 200) {
          const data1 = [];
          const data = res.data.data.resultDate;
          if (res.data.data.resultDate.length < 1) {
            this.setState({
              topboolean: false,
            });
          } else {
            for (let i = 0; i < data.length; i++) {
              let status = res.data.data.resultDate[i].loanStatus;
              let statusDescription = "";
              if (status == 21) {
                statusDescription = "Overdue Status";
              } else if (status == 22) {
                statusDescription = "Overdue Repayment";
              } else if (status == 51) {
                statusDescription = "Bad Debt";
              } else {
                statusDescription = "";
              }
              let auditStatus = res.data.data.resultDate[i].auditResult;
              let auditResultDescription = "";
              if (auditStatus == 0) {
                auditResultDescription = "pending";
              } else if (auditStatus == -1) {
                auditResultDescription = "reject";
              } else if (auditStatus == 1) {
                auditResultDescription = "pass";
              } else {
                auditResultDescription = "";
              }
              let newtime = data[i].applyTime;
              //new Date().getTimezoneOffset() 获取当前时间时间戳   （0 * 60 * 60 * 1000） 0 即为时区
              let timeapply =
                new Date(newtime).getTime() +
                new Date().getTimezoneOffset() * 60 * 1000 -
                0 * 60 * 60 * 1000;
              let applytimes = moment(new Date(timeapply)).format(
                "YYYY-MM-DD HH:mm:ss"
              );

              if (res.data.data.resultDate[i].applyTime == null) {
                applytimes = "";
              } else {
                applytimes = applytimes;
              }

              let newtime1 = data[i].auditTime;
              //new Date().getTimezoneOffset() 获取当前时间时间戳   （0 * 60 * 60 * 1000） 0 即为时区
              let timeapply1 =
                new Date(newtime1).getTime() +
                new Date().getTimezoneOffset() * 60 * 1000 -
                0 * 60 * 60 * 1000;
              let auditTime = moment(new Date(timeapply1)).format(
                "YYYY-MM-DD HH:mm:ss"
              );

              if (res.data.data.resultDate[i].auditTime == null) {
                auditTime = "";
              } else {
                auditTime = auditTime;
              }
              data1.push({
                key: i,
                appCode: data[i].appCode,
                indentNo: data[i].indentNo,
                firstName: data[i].firstName,
                mobile: data[i].mobile,
                dentityCard: data[i].dentityCard,
                balance: data[i].balance,
                shouldbackFee: data[i].shouldbackFee,
                actualbackAmt: data[i].actualbackAmt,
                actualshouldbackFee: data[i].actualshouldbackFee,
                loanStatus: statusDescription,
                applyReduceAmt: data[i].applyReduceAmt,
                reduceAmt: data[i].reduceAmt,
                reduceReason: data[i].reduceReason,
                remark: data[i].remark,
                applyUser: data[i].applyUser,
                applyTime: data[i].applyTime,
                auditUser: data[i].auditUser,
                auditTime: data[i].auditTime,
                recordId: data[i].recordId,
                id: data[i].id,
                uid: data[i].uid,
                loanTerm: data[i].loanTerm,
                overdueDays: data[i].overdueDays,
                auditResult: data[i].auditResult,
              });
            }
          }
          this.setState({
            current: res.data.data.page.current,
            totalData: data1,
            topboolean: false,
            length: res.data.data.page.total,
            isShow:res.data.data.isshow
          });
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
        }
      });
  };

  //选择分配人员
  selectFst(e) {
    var value = e.target.value;
    this.setState({
      fstSelect: value,
    });
  }
  changeAmt = (e) => {
    var value = e.target.value;
    this.setState({
      reduceAmt_val: value,
    });
  };
  selectScd(e) {
    var value = e.target.value;
    this.setState({
      scdSelect: value,
    });
  }
  selectThd(e) {
    var value = e.target.value;
    this.setState({
      thdSelect: value,
    });
  }
  selectFou(e) {
    var value = e.target.value;
    this.setState({
      fouSelect: value,
    });
  }
  modelAppre =()=>{
    if(this.state.selectedRowKeys.length == 0){
      message.error('Please select list data first')
    }else{
      this.setState({
        approvedVisible:true
      })
    }
      
  }
  handleselectedModalOk =() =>{
    this.setState({
      selectedVisible:false
    })
    this.handleCancel()
  }
  // handleselectedModalCancel =() =>{
  //   this.setState({
  //     selectedVisible:false
  //   })
  // }
  handleApprovedModalOk =() =>{
    this.setState({
      scdafter: true,
    });
    let rows = this.state.selectedRows
    let id = []
    let recordId = []
    rows.map(item =>{
      id.push(item.id)
      recordId.push(item.recordId)
    })
    axios.post(`/api/reduce/batchReductionOperation`,{
      isPass:true,
      id:id,
      recordId:recordId
    }).then(res=>{
      console.log(res);
      if(res.data.code == 200){
        message.success(res.data.msg)
        this.setState({
          approvedVisible:false,
          scdafter: false
        })
        this.handleCancel()
        this.searchClick();
      }else if(res.data.code == 400){
        this.setState({
        // 列表选择有问题请重新选择
          selectedVisible:true
        })
        this.setState({
          approvedVisible:false
        })
      }
    })
  }
  handleApprovedCancel =() =>{
    this.setState({
      approvedVisible:false,
    })
  }
  handleApprovedModalCancel =() =>{
    this.setState({
      scdafterCancel:true
    });
    let rows = this.state.selectedRows
    let id = []
    let recordId = []
    rows.map(item =>{
      id.push(item.id)
      recordId.push(item.recordId)
    })
    axios.post(`/api/reduce/batchReductionOperation`,{
      isPass:false,
      id:id,
      recordId:recordId
    }).then(res=>{
      console.log(res);
      if(res.data.code == 200){
        message.success(res.data.msg)
        this.setState({
          approvedVisible:false,
          scdafterCancel:false
        })
        this.handleCancel()
        this.searchClick();
      }else if(res.data.code == 400){
        this.setState({
        // 列表选择有问题请重新选择
          selectedVisible:true
        })
        this.setState({
          approvedVisible:false
        })
      }
    })
  }
  render() {
    const styleOne = {
      style:{
        marginLeft: 20,
        width: 170,
        height: 40,
        border: "none",
        display:this.state.isShow == false ? 'none' : 'block'
      }
    }
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: (selectedRowkeys, selectedRows) => {
        this.setState({
          selectedRowKeys: selectedRowkeys,
          selectedRows: selectedRows,
        });
      },
      columnWidth: 40,
    };
    const columns = [
      {
        title: "Order No",
        dataIndex: "indentNo",
        key: "indentNo",
      },
      {
        title: "Customer name",
        dataIndex: "firstName",
        key: "firstName",
        width: 50,
      },
      {
        title: "phone",
        dataIndex: "mobile",
        key: "mobile",
      },
      {
        title: "loan amount",
        dataIndex: "balance",
        key: "balance",
        render: (value) => {
          var num = value / 100;
          num = num + "";
          if (num.indexOf(".") != -1) {
            return num;
          } else {
            var str = "";
            for (var i = num.length - 1, j = 1; i >= 0; i--, j++) {
              if (j % 3 == 0 && i != 0) {
                //每隔三位加逗号，过滤正好在第一个数字的情况
                str += num[i] + ","; //加千分位逗号
                continue;
              }
              str += num[i]; //倒着累加数字
            }
            return str.split("").reverse().join("");
          }
        },
      },
      {
        title: "loan term",
        dataIndex: "loanTerm",
        key: "loanTerm",
      },
      {
        title: "should repayment amount",
        dataIndex: "shouldbackFee",
        key: "shouldbackFee",
        render: (text, record) => thousandSignNumber(text / 100),
      },
      {
        title: "repayment amount",
        dataIndex: "actualbackAmt",
        key: "actualbackAmt",
        render: (value) => {
          var num = value / 100;
          num = num + "";
          if (num.indexOf(".") != -1) {
            return num;
          } else {
            var str = "";
            for (var i = num.length - 1, j = 1; i >= 0; i--, j++) {
              if (j % 3 == 0 && i != 0) {
                //每隔三位加逗号，过滤正好在第一个数字的情况
                str += num[i] + ","; //加千分位逗号
                continue;
              }
              str += num[i]; //倒着累加数字
            }
            return str.split("").reverse().join("");
          }
        },
      },
      {
        title: "remaining balance",
        dataIndex: "actualshouldbackFee",
        key: "actualshouldbackFee",
        render: (text, record) => thousandSignNumber(text / 100),
        sorter:true
      },
      {
        title: "overdue day",
        dataIndex: "overdueDays",
        key: "overdueDays",
      },
      {
        title: "apply time",
        dataIndex: "applyTime",
        render: (text, record) => formatT0Time(text),
      },
      {
        title: "Status",
        dataIndex: "auditResult",
        key: "auditResult",
        render: (text, record) => {
          let auditResultGrade = "";
          if (text === 0) {
            auditResultGrade = "pending order";
          } else if (text === -1) {
            auditResultGrade = "reject order";
          } else if (text === 1) {
            auditResultGrade = "pass order";
          }
          return auditResultGrade;
        },
      },
      {
        title: "reduce amount",
        dataIndex: "reduceAmt",
        key: "reduceAmt",
        render: (value) => {
          var num = value / 100;
          num = num + "";
          if (num.indexOf(".") != -1) {
            return num;
          } else {
            var str = "";
            for (var i = num.length - 1, j = 1; i >= 0; i--, j++) {
              if (j % 3 == 0 && i != 0) {
                //每隔三位加逗号，过滤正好在第一个数字的情况
                str += num[i] + ","; //加千分位逗号
                continue;
              }
              str += num[i]; //倒着累加数字
            }
            return str.split("").reverse().join("");
          }
        },
      },
      {
        title: "audit user",
        dataIndex: "auditUser",
        key: "auditUser",
      },
      {
        title: "audit time",
        dataIndex: "auditTime",
        key: "auditTime",
      },
      {
        title: "Operation",
        dataIndex: "operating",
        key: "operating",
        width: 70,
        id: "id",
        status: "auditResult",
        uid: "uid",
        recordId: "recordId",
        fixed: "right",
        render: (text, record, index) => (
          <span style={{ display: "flex", flexDirection: "row" }}>
            <Button
              type="primary"
              style={{ display: record.auditResult === 0 ? "block" : "none" }}
              className="reassignbutton"
              size="small"
              onClick={() => this.showScdModal(record)}
            >
              review
            </Button>
            <Button
              type="primary"
              style={this.state.detailsbutton}
              className="detailsbutton"
              size="small"
              onClick={() => this.text(record)}
            >
              details
            </Button>
          </span>
        ),
      },
    ];
    return (
      <div className="overduepaid">
        <div className="overduepaid_top">
          <div className="overduepaid_mid">
            <Row className="search_form">
              <Col span={8}>
                <div className="search">
                  <p className="form_label">Order No:</p>
                  <Input
                    placeholder="Order No"
                    style={{ width: 292, marginRight: 12 }}
                    value={this.state.searchOrderNo}
                    onChange={this.handleSearchOrderNo.bind(this)}
                  />
                </div>
              </Col>
              <Col span={8}>
                <div className="search">
                  <p className="form_label">Phone:</p>
                  <Input
                    placeholder="Phone"
                    style={{ width: 292, marginRight: 12 }}
                    value={this.state.searchPhone}
                    onChange={this.handleSearchPhone.bind(this)}
                  />
                </div>
              </Col>
              <Col span={8}>
                <div className="top_right">
                  <p className="form_label">Audit user :</p>
                  <select
                    className="form_select"
                    id="getselect"
                    value={this.state.scdSelect}
                    style={{ width: 292, marginRight: 12 }}
                    onChange={this.selectScd.bind(this)}
                  >
                    {getSelect}
                  </select>
                </div>
              </Col>
              <Col span={8}>
                <div className="top_mid">
                  <p className="form_label">Status:</p>
                  <select
                    className="form_select"
                    value={this.state.fstSelect}
                    onChange={this.selectFst.bind(this)}
                  >
                    {/* <option value="3">all</option> */}
                    <option value="1">Pass Order</option>
                    <option value="-1">Reject Order</option>
                    <option value="2">Pending Order(cleared)</option>
                    <option value="0">Pending Order(uncleared)</option>
                  </select>
                </div>
              </Col>
              <Col span={8}>
                <div className="rangepick">
                  <p className="form_label">Apply Time:</p>
                  <RangePicker
                    style={{ width: 290 }}
                    ranges={{
                      Today: [moment(), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                    }}
                    showTime
                    format={dateFormat}
                    onChange={this.rangeOnChange}
                    value={
                      this.state.from === undefined ||
                      this.state.to === undefined ||
                      this.state.from === "" ||
                      this.state.to === ""
                        ? null
                        : [
                            moment(this.state.from, dateFormat),
                            moment(this.state.to, dateFormat),
                          ]
                    }
                  />
                </div>
              </Col>
              <Col span={8}>
                <div className="search">
                  <p className="form_label">Remaining Balance:</p>
                  <Input
                    placeholder="Remaining Balance"
                    style={{ width: 292, marginRight: 12 }}
                    value={this.state.actualshouldbackFee}
                    onChange={this.handleActualshouldbackFee.bind(this)}
                  />
                </div>
              </Col>
            </Row>

            <div className="bottom">
              <Button
                type="primary"
                className="common_color_bacth"
                // style={{
                //   marginRight: 20,
                //   width: 170,
                //   height: 40,
                //   border: "none",
                // }}
                {...styleOne}
                onClick={this.modelAppre}
              >
                Bacth Approved
              </Button>
              <Button
                type="primary"
                className="common_color"
                style={{
                  width: 170,
                  height: 40,
                  marginLeft: 20,
                  border: "none",
                }}
                onClick={this.searchClick.bind(this, 1)}
              >
                Search
              </Button>
              
              {/* <Button
                type="primary"
                style={{
                  width: 170,
                  height: 40,
                  backgroundColor: "#1CA9BA",
                  border: "none",
                }}
                onClick={this.reloadClick.bind(this)}
              >
                Refresh
              </Button> */}
            </div>
          </div>
        </div>
        <div className="overdue_table">
          <Modal
            title="reduce review"
            visible={this.state.scdvisible}
            onOk={this.scdhandleOk}
            onCancel={this.handleCancel}
            footer={[
              <Button key="back" onClick={this.handleUnapproved}>
                Unapproved
              </Button>,
              <Button key="submit" type="primary" onClick={this.scdhandleOk}>
                Approved
              </Button>,
            ]}
          >
            <input value={this.state.hideid} style={{ display: "none" }} />
            <input value={this.state.hidrecordId} style={{ display: "none" }} />
            <input value={this.state.hideidno} style={{ display: "none" }} />
            {this.state.show && (
              <p>
                The current order reduction application is under review, please
                be patient!
              </p>
            )}
            {this.state.hide && (
              <div>
                <div className="both_div">
                  <span>Appler:</span>
                  <input
                    value={this.state.record.applyUser}
                    disabled
                    className="reduce_inpt"
                  />
                </div>
                <div className="both_div">
                  <span>reduce reason:</span>
                  <input
                    value={this.state.record.remark}
                    disabled
                    className="reduce_inpt"
                  />
                </div>
                <div className="both_div">
                  <span>should repayment amount:</span>
                  <input
                    value={this.state.record.shouldbackFee / 100}
                    disabled
                    className="reduce_inpt"
                  />
                </div>
                <div className="both_div">
                  <span>repayment amount:</span>
                  <input
                    value={this.state.record.actualbackAmt / 100}
                    disabled
                    className="reduce_inpt"
                  />
                </div>
                <div className="both_div">
                  <span>remaining balance:</span>
                  <input
                    value={this.state.record.actualshouldbackFee / 100}
                    disabled
                    className="reduce_inpt"
                  />
                </div>
                <div className="both_div">
                  <span>reduce amount:</span>
                  <input
                    value={this.state.record.actualshouldbackFee / 100}
                    onChange={this.changeAmt}
                    disabled
                    className="reduce_inpt"
                  />
                </div>

                {/* <div className="both_div">
                  <span>remark:</span>
                  <textarea
                    style={{ width: "65%", marginLeft: "3%", height: 80 }}
                    disabled
                    value={this.state.fsttextarea}
                    onChange={this.fsttxarea}
                  />
                </div> */}
              </div>
            )}
          </Modal>

          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={this.state.totalData}
            scroll={{ x: "100%" }}
            loading={this.state.topboolean}
            onChange={this.handleChange}
            pagination={false}
          />
          <Pagination
            showSizeChanger //展示改变pagesize样式
            onShowSizeChange={this.onShowSizeChange} //pagesize改变时回调函数
            pageSizeOptions={["10", "50", "100"]} //设置pagesize区间
            defaultCurrent={1}
            current={this.state.current}
            total={this.state.length}
            onChange={this.pageChange}
            pageSize={this.state.pageSize}
            showTotal={showTotal}
            style={{ textAlign: "right", marginTop: 20 }}
          />
          {/* 您批量选择的订单类型等级不一致，请重新选择 */}
          <Modal
            title="Bacth reduce approved"
            visible={this.state.approvedVisible}
            onCancel={this.handleApprovedCancel}
            footer={[
              <Button 
                key="back" 
                // loading={this.state.scdafter}
                onClick={this.handleApprovedModalCancel
                }>
                Unapproved
              </Button>,
              <Button
                key="submit"
                type="primary"
                // loading={this.state.scdafter}
                onClick={this.handleApprovedModalOk}
              >
                Approved
              </Button>,
            ]}
          >
            <p>
              Has the application for exemption of this group of people been approved?
            </p>
          </Modal>
          <Modal
            title="Bacth reduce approved"
            visible={this.state.selectedVisible}
            footer={[
              <Button
                key="submit"
                type="primary"
                // loading={this.state.scdafter}
                onClick={this.handleselectedModalOk}
              >
                ok
              </Button>,
            ]}
          >
            <p>
              There is a problem with the order you selected, please select again.
            </p>
          </Modal>
        </div>
      </div>
    );
  }
}
export default withRouter(ReduceAudit);
