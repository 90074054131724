export const ruleFee = {
  rules: [
    {
      required: true,
      message: "Please input amount",
    },
    {
      pattern: /^((\d+)|(\d+\.\d{1,2}))$/,
      message: "Incorrect amount format",
    },
  ],
};

export const ruleCycle = {
  rules: [
    {
      required: true,
      message: "Please input deadline configuration",
    },
    {
      pattern: /^\d{1,5}$/,
      message: "Only numbers can be entered and the maximum length is 5",
    },
  ],
};

export const ruleTwoDecimal = {
  rules: [
    {
      required: true,
      message: "Please input",
    },
    {
      pattern: /^((\d+)|(\d+\.\d{1,2}))$/,
      message: "Only two decimals can be entered",
    },
  ],
};

export const ruleRequired = {
  rules: [
    {
      required: true,
      message: "Please input",
    },
  ],
};
