/*eslint-disable*/
import React from 'react';
import { Input,Button,Table,Modal,Pagination,DatePicker,Tooltip,message } from 'antd';
import moment from 'moment';
import axios from 'axios'
import './index.css';
import {withRouter} from 'react-router-dom'
import EventEmitter from '../../../utils/events'

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD"||undefined;
function showTotal(total) {
    return `Total ${total} items`;
}
class HistoryRemindDataDetail extends  React.Component {
    constructor(props) {
        super(props);
        this.state = {
            from:'',
            to:'',
            current:1,
            pageSize:10,
            isshow:true,
            ishide:false,
            boolean:true,
            topboolean:true,
            total:0,
            role:'',
            collectionId:'',
            queryType:'',
        };
    }
    componentDidMount() {
        this.setState({
        },()=>{
            this.searchClick()
        })
    }
    rangeOnChange=(dates, dateStrings)=>{
        this.setState({ from: dateStrings[0],to:dateStrings[1] })
    }
    searchIpt(e){
        var value = e.target.value;
        this.setState({ searchVal: value })
    }
    pageChange=(page)=>{
        this.setState({
            current: page
        },()=>{
            this.searchClick()
        })
    }
    onShowSizeChange=(current, pageSize)=>{

        this.setState({
            pageSize: pageSize
        },()=>{
            this.searchClick()
        })
    }

    searchClick=()=>{
        this.setState({
            topboolean:true,
            boolean:true
        })
        axios
            .post(`/api/remindData/getHistoryRemindDataList`,{
                beginTime:this.state.from,
                endTime:this.state.to,
                pageSize: 10,
                current: 1,
                role:this.state.role,
                collectionId:localStorage.id
            })
            .then(res=>{
                if(res.data.code === 200){
                    const data1 = [];
                    const data = res.data.data.resultDate
                    if(data.length<1){
                        this.setState({
                            topboolean:false
                        })
                    }else{
                        for (let i = 0; i < data.length; i++) {
                            data1.push({
                                key: i,
                                totalAllocate:data[i].totalAllocate,
                                repaidAfterAllocate:data[i].repaidAfterAllocate,
                                remindRepaymentRate:data[i].remindRepaymentRate <= 0 ? "0%" : data[i].remindRepaymentRate+"%",
                                todayExtendOrder:data[i].todayExtendOrder,
                                totalOverdue:data[i].totalOverdue,
                                overdueRate:data[i].overdueRate <= 0 ? "0%" : data[i].overdueRate+"%",
                                reminded:data[i].reminded,
                                notRemind:data[i].notRemind
                            });

                        }
                    }

                    this.setState({
                        totalData:data1,
                        topboolean:false
                    })
                }else if(res.data.code ==800){
                    this.props.history.push({ pathname: "/"});
                }else{

                }
            })
        axios
            .post(`/api/remindData/getHistoryRemindDataList`,{
                beginTime:this.state.from,
                endTime:this.state.to,
                pageSize: this.state.pageSize,
                current: this.state.current,
                role:this.state.role,
                collectionId:localStorage.id,
                queryType:2
            })
            .then(res=>{
                if(res.data.code === 200){
                    const data1 = [];
                    const data = res.data.data.resultDate
                    if(data.length<1){
                        this.setState({
                            boolean:false
                        })
                    }else{
                        for (let i = 0; i < data.length; i++) {
                            data1.push({
                                key: i,
                                date:data[i].date,
                                collector:data[i].collector,
                                totalAllocate:data[i].totalAllocate,
                                repaidAfterAllocate:data[i].repaidAfterAllocate,
                                remindRepaymentRate:data[i].remindRepaymentRate <= 0 ? "0%" : data[i].remindRepaymentRate+"%",
                                todayExtendOrder:data[i].todayExtendOrder,
                                totalOverdue:data[i].totalOverdue,
                                overdueRate:data[i].overdueRate <= 0 ? "0%" : data[i].overdueRate+"%",
                                reminded:data[i].reminded,
                                notRemind:data[i].notRemind
                            });
                        }
                    }

                    this.setState({
                        tableScdData:data1,
                        total:res.data.data.page.total,
                        boolean:false
                    })
                }else if(res.data.code ==800){
                    this.props.history.push({ pathname: "/"});
                }else{
                }
            })

    }

    render() {
        const botcolumns = [
            {
                title: 'Total allocate',
                dataIndex: 'totalAllocate',
                key: 'totalAllocate',

            },
            {
                title: 'Repaid after allocate',
                dataIndex: 'repaidAfterAllocate',
                key: 'repaidAfterAllocate',

            },
            {
                title: 'Remind repayment rate',
                dataIndex: 'remindRepaymentRate',
                key: 'remindRepaymentRate',

            },
            {
                title: 'Total extend order',
                dataIndex: 'todayExtendOrder',
                key: 'todayExtendOrder',
            },
            {
                title: 'Total overdue',
                dataIndex: 'totalOverdue',
                key: 'totalOverdue',

            },
            {
                title: 'Overdue rate',
                dataIndex: 'overdueRate',
                key: 'overdueRate',

            },
            {
                title: 'Reminded',
                dataIndex: 'reminded',
                key: 'reminded',
                width:100,
            },
            {
                title: 'Not remind',
                dataIndex: 'notRemind',
                key: 'notRemind',

            }
        ];

        const columns = [
            {
                title: 'Date',
                dataIndex: 'date',
                key: 'date'
            },
            {
                title: 'Collector',
                dataIndex: 'collector',
                key: 'collector',

            },
            {
                title: 'Total allocate',
                dataIndex: 'totalAllocate',
                key: 'totalAllocate',

            },
            {
                title: 'Repaid after allocate',
                dataIndex: 'repaidAfterAllocate',
                key: 'repaidAfterAllocate',

            },
            {
                title: 'Remind repayment rate',
                dataIndex: 'remindRepaymentRate',
                key: 'remindRepaymentRate',

            },
            {
                title: 'Total extend order',
                dataIndex: 'todayExtendOrder',
                key: 'todayExtendOrder',
            },
            {
                title: 'Total overdue',
                dataIndex: 'totalOverdue',
                key: 'totalOverdue',

            },
            {
                title: 'Overdue rate',
                dataIndex: 'overdueRate',
                key: 'overdueRate',

            },
            {
                title: 'Reminded',
                dataIndex: 'reminded',
                key: 'reminded',
                width:100,
            },
            {
                title: 'Not remind',
                dataIndex: 'notRemind',
                key: 'notRemind',

            }
        ];
        return (
            <div className="overduepaid">
                <div className="overduepaid_top">
                    <div className="overduepaid_mid">
                        <div className="bottom" style={{justifyContent:'left',alignItems:'center',marginTop:0}}>
                            <div className="top_mid" style={{width:'30%',flexShrink:2,marginRight:'5%'}}>
                                <div className="rangepick">
                                    <span className="description mid_span">Created time:</span>
                                    <RangePicker
                                        style={{width: 290,}}
                                        ranges={{
                                            Today: [moment(), moment()],
                                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                                        }}
                                        showTime
                                        format={dateFormat}
                                        onChange={this.rangeOnChange}
                                        value={this.state.from===undefined||this.state.to===undefined||this.state.from===""||this.state.to===""?null:[moment(this.state.from, dateFormat), moment(this.state.to, dateFormat)]}
                                    />
                                </div>
                            </div>
                            <Button type="primary" style={{width:170,marginRight:20,height:40,backgroundColor:'#009688',border:'none'}} onClick={this.searchClick.bind(this)}>Search</Button>
                        </div>
                    </div>
                </div>
                <div className="overdue_table">
                    <Table columns={botcolumns} dataSource={this.state.totalData} onChange={this.handleChange} pagination={false}/>
                </div>
                <div className="overdue_table">
                    <Table columns={columns} dataSource={this.state.tableScdData} loading={this.state.boolean} scroll={{x:'100%'}}onChange={this.handleChange} pagination={false}/>
                    <Pagination

                        showSizeChanger
                        onShowSizeChange={this.onShowSizeChange}
                        pageSizeOptions={["10","50","100"]}
                        defaultCurrent={1}
                        current={this.state.current}
                        total={this.state.total}
                        onChange={this.pageChange}
                        pageSize={this.state.pageSize}
                        showTotal={showTotal}

                        style={{textAlign:'right',marginTop:20}}
                    />
                </div>
            </div>
        );
    }
}
export default withRouter(HistoryRemindDataDetail)