import moment from "moment";

// 金额处理，加千分号展示
// change "47000" to "47,000"
export function thousandSignNumber(num) {
  var decimalPart = '';
  num = num.toString();
  if (num.indexOf('.') != -1) {
    decimalPart = '.' + num.split('.')[1];
    num = parseInt(num.split('.')[0]);
  }
  var array = num.toString().split('');
  var index = -3;
  while (array.length + index > 0) {
    array.splice(index, 0, ',');
    index -= 4;
  }
  return array.join('') + decimalPart;
};

// 时间格式转化
// change "2021-03-23T04:07:23.000+0000" to "2021-03-23 04:07:23"
export function formatT0Time(newtime) {
  if (newtime) {
    let timeaudit =
    new Date(newtime).getTime() +
    new Date().getTimezoneOffset() * 60 * 1000 -
    0 * 60 * 60 * 1000;
    let shouldback = moment(new Date(timeaudit)).format(
    "YYYY-MM-DD HH:mm:ss"
    );
    return shouldback
  } else {
    return '--'    
  }
};

// 获取催收结果
//
export function getCollectionResult(urgeResult) {
  let urgeResultDesc;
  switch (urgeResult) {
    case 1:
      urgeResultDesc = "promise to pay";
      break;
    case 2:
      urgeResultDesc = "claim that has paid back";
      break;
    case 3:
      urgeResultDesc = "not commitment";
      break;
    case 4:
      urgeResultDesc = "Can not connected";
      break;
    case 5:
      urgeResultDesc = "Hang up after connecting";
      break;
    case 6:
      urgeResultDesc = "Others";
      break;
    default:
      break;
  }
  return urgeResultDesc;
}
