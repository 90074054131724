/*eslint-disable*/
import React from 'react';
import { Button,Table,DatePicker,Pagination } from 'antd';
import './index.css';
import axios from 'axios'
import moment from 'moment';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router-dom'
import EventEmitter from '../../../utils/events'

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD'||undefined;

function showTotal(total) {
  return `Total ${total} items`;
}

class creditAuditime extends React.Component{
    constructor(props){
        super(props);
        this.state={
          filteredInfo: null,
          sortedInfo: null,
          from:'',
          to:'',
          data:[],
          tableData:[],
          current:1,
          total:1,
          pageSize:10,
          boolean:true,
          botboolean:true,
          ishide:false,
          isshow:true,
        }
      }
      //设置时间
      rangeOnChange=(dates, dateStrings)=>{
        this.setState({ from: dateStrings[0],to:dateStrings[1] })
      }
    

      reloadClick(e){
        this.setState({
          from:undefined,
          to:undefined,
          boolean:true,
          botboolean:true,
        },()=>{
          this.secondTable()
          this.showtable()
        });
       
      }
    showmodal=()=>{
        alert(this.index)
    }
    pageChange=(page)=>{
      this.setState({
        current: page,
        boolean:true,
        },()=>{
          this.searchClick()
        })
    }
    onShowSizeChange=(current, pageSize)=>{

      this.setState({
        boolean:true,
        pageSize: pageSize
      },()=>{
        this.secondTable()
      })
  }


    showtable=()=>{
      this.setState({
        isshow:false,
        ishide:true
      })
      axios
        .post(`/api/loan/credit/getCreditDataByAuditTime`,{
  
        pageSize: this.state.pageSize,
        current: this.state.current,
      })
      .then(res=>{
        if(res.data.code === 200){
          const data1 = [];
          const data = res.data.data.resultDate
          if(data.length<1){
            this.setState({
              boolean:false
            })
          }else{
            for (let i = 0; i < data.length; i++) {
              data1.push({
                key: i,
                allocateCount:data[i].allocateCount,
                toReviewCount:data[i].toReviewCount,
                reviewedCountNew:data[i].reviewedCountNew,
                passCountNew:data[i].passCountNew,
                rejectCountNew:data[i].rejectCountNew,
                returnCount_returnMemberNew:data[i].returnCount_returnMemberNew,
                passRateNew:data[i].passRateNew,
                reviewedCountOld:data[i].reviewedCountOld,
                passCountOld:data[i].passCountOld,
                rejectCountOld:data[i].rejectCountOld,
                returnCount_returnMemberOld:data[i].returnCount_returnMemberOld,
                passRateOld:data[i].passRateOld,
                reviewedCount:data[i].reviewedCount,
                passCount:data[i].passCount,
                rejectCount:data[i].rejectCount,
                returnCount_returnMember:data[i].returnCount_returnMember,
                passRate:data[i].passRate,
                workEffectiveness:data[i].workEffectiveness,
                avgReviewEffectiveness:data[i].avgReviewEffectiveness,
              
              });
              // console.log('aaaaa',data1)
             
            }
          }
         
          this.setState({
            tableData:data1,
            boolean:false
          })
        }else if(res.data.code ==800){
          this.props.history.push({ pathname: "/"});
        }else{
        }
      })
    }


//页面初始化加载下面的table
  secondTable=()=>{

    axios
      .post(`/api/loan/credit/getCreditReviewerDataByAuditTime`,{

      pageSize: this.state.pageSize,
      current: this.state.current,
    })
    .then(res=>{
      if(res.data.code === 200){
        const data1 = [];
        const data = res.data.data.resultDate
        if(data.length<1){
          this.setState({
            botboolean:false
          })
        }else{
          for (let i = 0; i < data.length; i++) {
            data1.push({
              key: i,
              reviewer:data[i].reviewer,
              allocateCount:data[i].allocateCount,
              toReviewCount:data[i].toReviewCount,
              reviewedCountNew:data[i].reviewedCountNew,
              passCountNew:data[i].passCountNew,
              rejectCountNew:data[i].rejectCountNew,
              returnCount_returnMemberNew:data[i].returnCount_returnMemberNew,
              passRateNew:data[i].passRateNew,
              reviewedCountOld:data[i].reviewedCountOld,
              passCountOld:data[i].passCountOld,
              rejectCountOld:data[i].rejectCountOld,
              returnCount_returnMemberOld:data[i].returnCount_returnMemberOld,
              passRateOld:data[i].passRateOld,
              reviewedCount:data[i].reviewedCount,
              passCount:data[i].passCount,
              rejectCount:data[i].rejectCount,
              returnCount_returnMember:data[i].returnCount_returnMember,
              passRate:data[i].passRate,
              workEffectiveness:data[i].workEffectiveness,
              avgReviewEffectiveness:data[i].avgReviewEffectiveness,
              reviewerId:data[i].reviewerId,
  
            });
            localStorage.userId = data[i].reviewerId
                          
           
            // console.log('aaaaa',data1)
           
          }
        }
        
        this.setState({
          tableScdData:data1,
          total:res.data.data.page.total,
          botboolean:false
        })
      }else if(res.data.code ==800){
        this.props.history.push({ pathname: "/"});
      }else{
      }

    })
  }
          //搜索
          searchClick=()=>{ 
            this.setState({
              boolean:true,
              botboolean:true,
            })
            //第一个table
                  axios
                    .post(`/api/loan/credit/getCreditDataByAuditTime`,{

                    beginTime:this.state.from,
                    endTime:this.state.to,
                    pageSize: this.state.pageSize,
                    current: this.state.current,
                  })
                  .then(res=>{
                    if(res.data.code === 200){
                      const data1 = [];
                      const data = res.data.data.resultDate
                      if(data.length<1){
                        this.setState({
                          boolean:false
                        })
                      }else{
                        for (let i = 0; i < data.length; i++) {
                          data1.push({
                            key: i,
                            allocateCount:data[i].allocateCount,
                            toReviewCount:data[i].toReviewCount,
                            reviewedCountNew:data[i].reviewedCountNew,
                            passCountNew:data[i].passCountNew,
                            rejectCountNew:data[i].rejectCountNew,
                            returnCount_returnMemberNew:data[i].returnCount_returnMemberNew,
                            passRateNew:data[i].passRateNew,
                            rejectCountOld:data[i].rejectCountOld,
                            returnCount_returnMemberOld:data[i].returnCount_returnMemberOld,
                            passRateOld:data[i].passRateOld,
                            reviewedCountOld:data[i].reviewedCountOld,
                            passCountOld:data[i].passCountOld,
                            reviewedCount:data[i].reviewedCount,
                            passCount:data[i].passCount,
                            rejectCount:data[i].rejectCount,
                            returnCount_returnMember:data[i].returnCount_returnMember,
                            passRate:data[i].passRate,
                            workEffectiveness:data[i].workEffectiveness,
                            avgReviewEffectiveness:data[i].avgReviewEffectiveness,
                          });
                          // console.log('aaaaa',data1)
                         
                        }
                      }
                     
                      this.setState({
                        tableData:data1,
                        boolean:false
                      })
                    }else if(res.data.code ==800){
                      this.props.history.push({ pathname: "/"});
                    }else{
                    }
                  })
            
            
                  //第二个table
                  axios
                    .post(`/api/loan/credit/getCreditReviewerDataByAuditTime`,{

                    beginTime:this.state.from,
                    endTime:this.state.to,
                    pageSize: this.state.pageSize,
                    current: this.state.current,
                  })
                  .then(res=>{
                    if(res.data.code === 200){
                     
                      const data1 = [];
                      const data = res.data.data.resultDate
                     if(data.length<1){
                       this.setState({
                         botboolean:false
                       })
                     }else{
                      for (let i = 0; i < data.length; i++) {
                        data1.push({
                          key: i,
                          reviewer:data[i].reviewer,
                          allocateCount:data[i].allocateCount,
                          toReviewCount:data[i].toReviewCount,
                          reviewedCountNew:data[i].reviewedCountNew,
                          passCountNew:data[i].passCountNew,
                          rejectCountNew:data[i].rejectCountNew,
                          returnCount_returnMemberNew:data[i].returnCount_returnMemberNew,
                          passRateNew:data[i].passRateNew,
                          reviewedCountOld:data[i].reviewedCountOld,
                          passCountOld:data[i].passCountOld,
                          rejectCountOld:data[i].rejectCountOld,
                          returnCount_returnMemberOld:data[i].returnCount_returnMemberOld,
                          passRateOld:data[i].passRateOld,
                          reviewedCount:data[i].reviewedCount,
                          passCount:data[i].passCount,
                          rejectCount:data[i].rejectCount,
                          returnCount_returnMember:data[i].returnCount_returnMember,
                          passRate:data[i].passRate,
                          workEffectiveness:data[i].workEffectiveness,
                          avgReviewEffectiveness:data[i].avgReviewEffectiveness,
                          reviewerId:data[i].reviewerId,
                        });
                       
                      } 
                     }
                      
                      this.setState({
                        tableScdData:data1,
                        botboolean:false,
                        total:res.data.data.page.total,
                      })
                    }else if(res.data.code ==800){
                      this.props.history.push({ pathname: "/"});
                    }else{
                    }
                  })
                }
                handClick=(item)=>{
                  console.log('1111111122222233333',item)
                }
                toShopDetails=(record)=>{
                  window.location='/#/credit_system/credit_auditime/day_audit?id='+record.reviewerId
                  let test = [{path: '/credit_system/credit_auditime/day_audit',key: `/credit_system/credit_auditime/day_audit/${record.reviewerId}`,number: `${record.reviewerId}`}]
                  EventEmitter.trigger('btn', test[0])
                  EventEmitter.trigger('idanduid', test[0].number)}
      componentDidMount() {
        this.setState({
          from:moment().startOf('month'),
          to: moment()
        },()=>{
          this.secondTable()
        })
      }
    render(){
        const columns1 = [
            {
              title: 'Total Allocate',
              dataIndex: 'allocateCount',
              key: 'allocateCount',
              className:'fontfamily',
            },
            {
              title: 'Pending Review',
              dataIndex: 'toReviewCount',
              key: 'toReviewCount',
            },
            {
              title: 'New client Review',
              width: 72,
              dataIndex: 'reviewedCountNew',
              key: 'reviewedCountNew'
            }, {
              title: 'New client Approve',
              width: 60,
              dataIndex: 'passCountNew',
              key: 'passCountNew'
            }, {
              title: 'New client Reject',
              width: 60,
              dataIndex: 'rejectCountNew',
              key: 'rejectCountNew'
            }, {
              title: 'New client Return',
              width: 120,
              dataIndex: 'returnCount_returnMemberNew',
              key: 'returnCount_returnMemberNew'
            }, {
              title: 'New client Approve Rate',
              width: 60,
              dataIndex: 'passRateNew',
              key: 'passRateNew'
            }, {
              title: 'Old client Review',
              width: 70,
              dataIndex: 'reviewedCountOld',
              key: 'reviewedCountOld'
            }, {
              title: 'Old client Approve',
              width: 60,
              dataIndex: 'passCountOld',
              key: 'passCountOld'
            }, {
              title: 'Old client Reject',
              width: 60,
              dataIndex: 'rejectCountOld',
              key: 'rejectCountOld'
            }, {
              title: 'Old client Return',
              width: 120,
              dataIndex: 'returnCount_returnMemberOld',
              key: 'returnCount_returnMemberOld'
            }, {
              title: 'Old client Approve Rate',
              width: 60,
              dataIndex: 'passRateOld',
              key: 'passRateOld'
            }, {
              title: 'Total Review',
              width: 72,
              dataIndex: 'reviewedCount',
              key: 'reviewedCount'
            }, {
              title: 'Total Approve',
              dataIndex: 'passCount',
              key: 'passCount'
            }, {
              title: 'Total Reject',
              dataIndex: 'rejectCount',
              key: 'rejectCount'
            }, {
              title: 'Total Return',
              width: 120,
              dataIndex: 'returnCount_returnMember',
              key: 'returnCount_returnMember'
            }, {
              title: 'Approve Rate',
              width: 72,
              dataIndex: 'passRate',
              key: 'passRate'
            }, 
            // {
            //   title: 'Capacity',
            //   dataIndex: 'workEffectiveness',
            //   key: 'workEffectiveness'
            // }, {
            //   title: 'Review Time',
            //   width: 50,
            //   dataIndex: 'avgReviewEffectiveness',
            //   key: 'avgReviewEffectiveness'
            // },
          ];






        const columns = [
            {
              title: 'Reviewer',
              dataIndex: 'reviewer',
              key: 'reviewer',
              width:93,
              fixed: 'left',

            },
            {
              title: 'Total Allocate',
              dataIndex: 'allocateCount',
              key: 'allocateCount',
              width:92,

            },
            {
              title: 'Pending Review',
              dataIndex: 'toReviewCount',
              key: 'toReviewCount',
              width:92,

            }, {
              title: 'New client Review',
              dataIndex: 'reviewedCountNew',
              width:95,
              key: 'reviewedCountNew'
            }, {
              title: 'New client Approve',
              dataIndex: 'passCountNew',
              width:94,
              key: 'passCountNew'
            }, {
              title: 'New client Reject',
              dataIndex: 'rejectCountNew',
              width:94,
              key: 'rejectCountNew'
            }, {
              title: 'New client Return',
              dataIndex: 'returnCount_returnMemberNew',
              width:138,
              key: 'returnCount_returnMemberNew'
            }, {
              title: 'New client Approve Rate',
              dataIndex: 'passRateNew',
              width:94,
              key: 'passRateNew'
            }, {
              title: 'Old client Review',
              dataIndex: 'reviewedCountOld',
              width:95,
              key: 'reviewedCountOld'
            }, {
              title: 'Old client Approve',
              dataIndex: 'passCountOld',
              width:95,
              key: 'passCountOld'
            }, {
              title: 'Old client Reject',
              dataIndex: 'rejectCountOld',
              width:95,
              key: 'rejectCountOld'
            }, {
              title: 'Old client Return',
              dataIndex: 'returnCount_returnMemberOld',
              width:138,
              key: 'returnCount_returnMemberOld'
            }, {
              title: 'Old client Approve Rate',
              dataIndex: 'passRateOld',
              width:95,
              key: 'passRateOld'
            }, {
              title: 'Total Review',
              dataIndex: 'reviewedCount',
              width:96,
              key: 'reviewedCount'
            }, {
              title: 'Total Approve',
              width:94,
              dataIndex: 'passCount',
              key: 'passCount'
            }, {
              title: 'Total Reject',
              dataIndex: 'rejectCount',
              width:93,
              key: 'rejectCount'
            }, {
              title: 'Total Return',
              dataIndex: 'returnCount_returnMember',
              width:138,
              key: 'returnCount_returnMember'
            }, {
              title: 'Approve Rate',
              width:92,
              dataIndex: 'passRate',
              key: 'passRate'
            }, 
            // {
            //   title: 'Capacity',
            //   dataIndex: 'workEffectiveness',
            //   width:93,
            //   key: 'workEffectiveness'
            // }, {
            //   title: 'Review Time',
            //   dataIndex: 'avgReviewEffectiveness',
            //   width:93,
            //   key: 'avgReviewEffectiveness'
            // },
            {
              title: 'Operation',
              key: 'operation',
              width:90,
              fixed: 'right',
              reviewerId:'reviewerId',
              render: (record) =><a onClick={() => this.toShopDetails(record)} style={{margin:10,fontSize:12}} >detail</a>
            },
          ];
        return(
            <div className="audit_content">
                <div className="commom_top" style={{alignItems:'center'}}>
                <RangePicker
                ranges={{
                Today: [moment(), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                }}
                showTime
                format={dateFormat}
                onChange={this.rangeOnChange}
                value={this.state.from===undefined||this.state.to===undefined||this.state.from===""||this.state.to===""?null:[moment(this.state.from, dateFormat), moment(this.state.to, dateFormat)]}
                />
                <Button type="primary" style={{width:170,marginLeft:'15%',marginRight:50,height:40,border:'none'}}  className="common_color" onClick={this.searchClick.bind(this)}>Search</Button>
                <Button type="primary" style={{width:170,height:40,border:'none',}} className="common_color_scd" onClick={this.reloadClick.bind(this)}>Refresh</Button>
                </div>
                <div className="table">
                <div className="content_bot" style={{textAlign:'center',marginBottom:40}}>
                <p className="content_bot_p">Total</p>
                {this.state.isshow&&
                 <Button type="primary" style={{width:170,height:40,border:'none'}}className="common_color_scd" onClick={this.showtable}>Details</Button>
                 }
                {this.state.ishide&&
                  <Table columns={columns1} loading={this.state.boolean} dataSource={this.state.tableData} pagination={false} scroll={{ x: 1300 }}/>
                }
               
                </div>
                <Table columns={columns} dataSource={this.state.tableScdData} loading={this.state.botboolean}  pagination={false} scroll={{ x: 2100, y: 600 }} />,

                </div>

                <Pagination
                  // showQuickJumper
                  onShowSizeChange={this.onShowSizeChange} //pagesize改变时回调函数
                  pageSizeOptions={["10","50","100"]}   //设置pagesize区间
                  defaultCurrent={1} 
                  current={this.state.current}
                  total={this.state.total} 
                  onChange={this.pageChange} 
                  showTotal={showTotal}
                  style={{textAlign:'right',marginTop:20}}
                  showSizeChanger={true}
                  pageSize={this.state.pageSize}
                  />
            </div>
        )
    }
}
export default withRouter(creditAuditime)