import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import Schema from 'async-validator'
import { Table, Switch, Pagination, Button, Modal, Input, message } from 'antd';
import { validateSymbol, validateNumber } from '../../../utils/validate'
import {getsmsData,editsmsData} from '../../../api/systemApi'
const { TextArea } = Input;
const rules = {
    threshold: [//阀值
        { required: true, message: 'please enter threshold ' },
        { validator: validateNumber }
    ],
    theme: [//主题
        { required: true, message: 'please enter theme ' },
        // {validator:validateSymbol}
    ],
    copywriting: [//文案
        { required: true, message: 'please enter copywriting ' },
        // {validator:validateSymbol}
    ],
}
const { confirm } = Modal;
function showTotal(total) {
    return `Total ${total} items`;
}
class Tab1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 1,
            total: 1,
            pageSize: 10,
            tableData: [],
            boolean: true,
            btnLoading: false,
        }
    }
    showConfirm = (record) => { //编辑按钮前确认
        var that = this;
        confirm({
            title: 'Are you sure to perform the operation?',
            // icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            onOk() {
                that.firsthandleOk();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }
    offlineConfirm = (record) => { //编辑按钮前确认
        var that = this;
        confirm({
            title: 'Are you sure to perform the operation?',
            // icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            onOk() {
                that.offlineRule(record);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }
    pageChange = (page) => { //分页变化
        this.setState({
            current: page
        }, () => {
            this.getTableData()
        })
    }

    componentDidMount() { //生命周期
        this.getTableData();
    }
    showDialog() { //打开新增弹窗
        this.setState({
            modelTitle: 'Add SMS',
            firstVisible: true
        })
    }
    handleCancel = () => { //关闭弹窗
        this.setState({
            firstVisible: false,
            threshold: '',
            theme: '',
            copywriting: '',
            id: '',
            btnLoading: false,
            modelTitle: "Add SMS",
        })

    }
    getTableData = () => { //获取表格数据
        this.setState({
            boolean: true
        })
        getsmsData({
            current: this.state.current,
            pageSize: this.state.pageSize,
            type: '1'
        }).then(res => {
            if (res.data.code === 200) {
                const data1 = [];
                const data = res.data.data.resultDate
                for (let i = 0; i < data.length; i++) {
                    data[i].key = i;
                    data1.push({
                        key: i + 1,
                        copywriting: data[i].copywriting,
                        createTime: data[i].createTime,
                        id: data[i].id,
                        remark: data[i].remark,
                        status: data[i].status,
                        theme: data[i].theme,
                        threshold: data[i].threshold,
                        type: data[i].type,
                        updateTime: data[i].updateTime,
                        operatorName:data[i].operatorName
                    })
                }
                this.setState({
                    tableData: data1,
                    boolean: false,
                    length: res.data.data.page.total
                })
            } else if (res.data.code == 800) {
                this.props.history.push({ pathname: "/" });
            }
        })
    }
    //表单值双向绑定
    fstaddonChange = (e) => {
        let InputName = e.target.name;
        let InputValue = e.target.value;
        this.setState({
            [InputName]: InputValue.replace(/\s+/g, ""),
        })
    }
    fstaddonChange1 = (e) => { //可以输入空格
        let InputName = e.target.name;
        let InputValue = e.target.value;
        this.setState({
            [InputName]: InputValue,
        })
    }
    //表单回显
    edit = (record) => {
        this.setState({
            firstVisible: true,
            threshold: record.threshold,
            theme: record.theme,
            copywriting: record.copywriting,
            id: record.id,
            modelTitle: 'Edit SMS'
        })
    }
    //表单提交
    firsthandleOk = () => {
        let params = {
            threshold: this.state.threshold,
            theme: this.state.theme,
            copywriting: this.state.copywriting,
            type: 1,
        }
        if (this.state.id != '') { //如果是编辑的话，添加id
            params['id'] = this.state.id
        }
        const validator = new Schema(rules);
        validator.validate({ threshold: this.state.threshold, theme: this.state.theme, copywriting: this.state.copywriting }, (errors, fields) => {
            if (!errors) {
                //新增编辑同一接口
                this.setState({
                    btnloading: true
                })
                editsmsData({ ...params }).then(res => {
                    if (res.data.code == 200) {
                        this.setState({
                            btnloading: false
                        })
                        message.success(res.data.msg);
                        this.getTableData();
                        this.handleCancel()
                    } else {
                        message.error(res.data.msg)
                        this.setState({
                            btnloading: false
                        })
                    }
                })
            } else {
                message.error(errors[0].message)
            }
        })

    }

    //下线上线切换
    offlineRule(record) {
        console.log(record)
        let newStatus = record.status == 1 ? 2 : 1;
        editsmsData({
            threshold: record.threshold,
            theme: record.theme,
            copywriting: record.copywriting,
            status: newStatus,
            type: 1,
            id: record.id
        }).then(res => {
            if (res.data.code == 200) {
                message.success(res.data.msg);
                this.getTableData();
            } else {
                message.error(res.data.msg)
            }
        })
    }

    render() {
        const columns = [
            {
                title: '规则编号',
                dataIndex: 'key',
                key: 'key',
            },
            {
                title: '规则说明',
                dataIndex: 'remark',
                key: 'remark',
            },
            {
                title: '阀值',
                dataIndex: 'threshold',
                key: 'threshold',
            },
            {
                title: '主题',
                dataIndex: 'theme',
                key: 'theme',
            },
            {
                title: '短信文案',
                dataIndex: 'copywriting',
                key: 'copywriting',
                width: '12%',
            },
            {
                title: '更新时间',
                dataIndex: 'updateTime',
                key: 'updateTime',
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                width: '6%',
                render(status) {
                    let config = {
                        '1': '上线',
                        '2': '下线'
                    }
                    return config[status]
                }
            },
            {
                title: '操作人',
                dataIndex: 'operatorName',
                key: 'operatorName',
                render: (value) => {
                    return value?value: '——'
                }
            },
            {
                title: '操作', width: '26.5%', dataIndex: '', pid: 'pid', key: 'z',
                render: (text, record) =>
                    <div>
                        {record.status == 2 && <span>
                            <a className='edit_button' href="javascript:;" onClick={() => this.edit(record)}>编辑文案</a>
                        </span>}
                        <span>
                            <a className='edit_button' href="javascript:;" onClick={() => this.offlineConfirm(record)}>{record.status == 1 ? '下线' : '上线'}</a>
                        </span>
                    </div>

            },
        ];
        return (
            <div>
                <Button type="primary" style={{
                    width: 170,
                    marginLeft: '4%',
                    height: 40,
                    float: 'right',
                    marginTop: '-60px',
                    marginBottom: '20px',
                }} onClick={this.showDialog.bind(this)} className="common_color">新增</Button>
                <Table columns={columns} dataSource={this.state.tableData} loading={this.state.boolean} pagination={false} />
                <Pagination
                    defaultCurrent={1}
                    current={this.state.current}
                    total={this.state.length}
                    onChange={this.pageChange}
                    showTotal={showTotal}
                    pageSize={this.state.pageSize}
                    style={{ textAlign: 'right', marginTop: 20 }}
                />
                {/* 弹窗 */}
                <Modal
                    // destroyOnClose={true}
                    title={this.state.modelTitle}
                    visible={this.state.firstVisible}
                    onOk={this.showConfirm}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            Cancel
                        </Button>,
                        <Button key="submit" type="primary" loading={this.state.btnloading} onClick={this.showConfirm}>
                            Confirm
                        </Button>,
                    ]}
                >

                    <div className={'formItem'}>
                        <label>规则说明:</label>
                        <Input value={'最后一笔结清，第X天还未进件用户'} disabled style={{ marginTop: 10 }} />
                    </div>
                    <div className={'formItem'}>
                        <label>阀值:</label>
                        <Input value={this.state.threshold} name="threshold" placeholder='正整数' allowClear style={{ marginTop: 10 }} disabled={this.state.id ? true : false} onChange={this.fstaddonChange} />
                    </div>
                    <div className={'formItem'}>
                        <label>主题:</label>
                        <Input value={this.state.theme} name="theme" placeholder='单行输入' allowClear style={{ marginTop: 10 }} onChange={this.fstaddonChange1} maxLength={30} />
                    </div>
                    <div className={'formItem'}>
                        <label>短信文案:</label>
                        <TextArea rows={3} value={this.state.copywriting} name="copywriting" onChange={this.fstaddonChange1} maxLength={480} />
                    </div>
                </Modal>
            </div>
        );
    }
}

export default withRouter(Tab1);