/*eslint-disable*/
import React from "react";
import { Table, Button, Pagination, Divider, Modal, message } from "antd";
import "./index.css";
import axios from "axios";
import moment from "moment";
import $ from "jquery";
function showTotal(total) {
  return `Total ${total} items`;
}
var getselect = [];
let msgSelect = [];
export default class Tab5 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bank_name: "",
      bank_no: "",
      bank_account: "",
      recipient_no: "",
      tableData: [],
      modelvisible: false,
      current: 1,
      total: 1,
      boolean: true,
      username: "",
      overduedays: "",
      loantime: "",
      overamount: "",
      loanamount: "",
      deuamount: "",
      loanterm: "",
      repaymentamt: "",
      repaymentdata: "",
      balance: "",
      fstvisible: false,
      isShow: false,
      collectedname: "",
      collectedcell: "",
      urge_type: 1,
      object_select: "",
      collecrslt: "",
      fstextarea: "",
      after: false,
      paramsObj: {},
      reduceShow: true,
      reduceVisible: false,
      debitVisible: false,
      objectShow: true,
      objectCellShow: true,
      template_select: "",
      phoneTotal: "",
      phoneCurrent: 1,
      phoneVisible: false,
      bookShow: "none",
      bookBoolean: true,
      reduceRemark: "",
      isShowAddressBook:false
    };
  }

  showReduceModal = () => {
    let a = this.props.id;
    axios
      .post(`/api/reduce/selectReduce`, {
        loanId: a,
      })
      .then((res) => {
        if (res.data.code == 200) {
          this.setState({
            reduceShow: true,
            reduceVisible: true,
            hideid: a,
          });
        } else {
          this.setState({
            reduceShow: false,
            reduceVisible: true,
          });
        }
      });
  };
  redecu_reson(e) {
    var value = e.target.value;
    this.setState({
      reduceRson: value,
    });
  }

  //   changeAmt = (e) => {
  //     var value = e.target.value;
  //     this.setState({
  //       reduceAmt_val: value,
  //     });
  //   };
  changeReduceMark = (e) => {
    let value = e.target.value;
    this.setState({ reduceRemark: value });
  };
  reduceOk = (e) => {
    this.setState({
      btnloading: true,
    });
    console.log(this.state.reduceRemark);
    if (this.state.reduceShow && this.state.reduceRemark == "") {
      alert("reduce reason can't be empty.");
      this.setState({
        btnloading: false,
      });
      return false;
    }
    axios
      .post(`/api/reduce/saveReduce`, {
        loanId: this.state.hideid,
        loanIndentNo: this.state.hideidno,
        reduceAmt: this.state.remian_balance,
        remark: this.state.reduceRemark,
      })
      .then((res) => {
        if (res.data.code == 200) {
          this.setState({
            reduceVisible: false,
            btnloading: false,
            reduceRemark: "",
          });
          //this.reloadClick();
          message.success("success");
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else if (res.data.code == 400) {
          message.error(res.data.msg);
          this.setState({
            btnloading: false,
          });
        } else {
          this.setState({
            reduceVisible: false,
            btnloading: false,
            reduceRemark: "",
          });
        }
      });
    this.setState({
      reduceVisible: false,
    });
  };
  //页码改变时触发
  pageChange = (page) => {
    this.setState(
      {
        current: page,
      },
      () => {
        this.tableonload();
      }
    );
  };

  //通讯录
  phoneChangePage = (page) => {
    this.setState({
      phoneCurrent: page,
    });
  };

  showPhoneModel = () => {
    this.setState({
      phoneVisible: true,
      addressBtnLoading: true,
    });

    let uid = this.props.uid;
    axios
      .post(`/api/userAddressBook/getUserAddressBook`, {
        uid: uid,
      })
      .then((res) => {
        let data = res.data.data;
        if (res.data.code == 200) {
          let data1 = [];
          for (let i = 0; i < data.length; i++) {
            data1.push({
              key: i,
              name: data[i].name,
              mobile: data[i].mobile,
            });
            this.setState({
              phoneTableData: data1,
              bookBoolean: false,
              addressBtnLoading: false,
            });
          }
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        }
      });
  };
  phonehandleOk = () => {
    this.setState({
      phoneVisible: false,
      addressBtnLoading:false
    });
  };

  add = () => {
    let uid = this.props.uid;
    let id = this.props.id;
    axios
      .post(`/api/loan/credit/getLoanOrderInformation`, {
        id: id,
        uid: uid,
      })
      .then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data.record;
          let time1 = data.createdTime;
          let time2 = data.shouldbackTime;
          let time3 = data.arriveTime;
          let times4 = "";
          let times5 = "";
          let a =
            new Date(time1).getTime() +
            new Date().getTimezoneOffset() * 60 * 1000 -
            0 * 60 * 60 * 1000;
          let b =
            new Date(time2).getTime() +
            new Date().getTimezoneOffset() * 60 * 1000 -
            0 * 60 * 60 * 1000;
          let c =
            new Date(time3).getTime() +
            new Date().getTimezoneOffset() * 60 * 1000 -
            0 * 60 * 60 * 1000;
          //new Date().getTimezoneOffset() 获取当前时间时间戳   （0 * 60 * 60 * 1000） 0 即为时区
          let times = moment(new Date(time1)).format("YYYY-MM-DD HH:mm:ss");
          let times2 = moment(new Date(time2)).format("YYYY-MM-DD HH:mm:ss");
          let times3 = moment(new Date(time3)).format("YYYY-MM-DD HH:mm:ss");
          if (data.arriveTime == null) {
            times4 = "";
          } else {
            times4 = times3;
          }
          if (data.shouldbackTime == null) {
            times5 = "";
          } else {
            times5 = times2;
          }
          let timess = data.shouldbackTime;
          //new Date().getTimezoneOffset() 获取当前时间时间戳   （0 * 60 * 60 * 1000） 0 即为时区
          let east0time =
            new Date(timess).getTime() +
            new Date().getTimezoneOffset() * 60 * 1000 -
            0 * 60 * 60 * 1000;
          let timess1 = moment(new Date(timess)).format("YYYY-MM-DD HH:mm:ss");
          let date2 = new Date(
            moment(new Date()).format("YYYY-MM-DD").replace(/-/g, "/")
          );
          let date1 = new Date(
            moment(new Date(timess1)).format("YYYY-MM-DD").replace(/-/g, "/")
          );
          var date3 = date2.getTime() - date1.getTime();
          let days = Math.floor(date3 / (24 * 3600 * 1000));
          let amounta = data.shouldbackFee;
          let amountb = data.actualbackAmt;
          let balance = (amounta - amountb) / 100;

          this.setState({
            username: res.data.data.record.firstName,
            overduedays: days,
            loantime: times4,
            overamount: data.overdueFee / 100,
            loanamount: data.balance / 100,
            deuamount: data.shouldbackFee / 100,
            loanterm: data.cycle,
            repaymentamt: data.alreadyBackAmt / 100,
            repaymentdata: times5,
            balance: balance,
          });
        }
        if (res.data.code == 800) {
          window.location.href = "/";
        }
      });
    this.setState({
      fstvisible: true,
    });
    this.statuscall();
  };
  decodePatams = (url) => {
    //解码url信息
    url = url == null ? window.location.href : url;
    var search = url.substring(url.lastIndexOf("?") + 1);
    var obj = {};
    var reg = /([^?&=]+)=([^?&=]*)/g;
    search.replace(reg, function (rs, $1, $2) {
      var name = decodeURIComponent($1);
      var val = decodeURIComponent($2);
      val = String(val);
      obj[name] = val;
    });
    this.setState({
      paramsObj: obj,
    });
  };
  getMsg = () => {
    msgSelect = [];
    let uid = this.props.uid;
    let id = this.props.id;
    //获取短信发送数量
    // axios
    //   .post(`/api/loanUrge/getSendMessageInfo`, {
    //     loanId: id,
    //   })
    //   .then((res) => {
    //     if (res.data.code == 200) {
    //       this.setState({
    //         smsnumber: res.data.data.todaySmsCount,
    //         emailnumber: "0",
    //       });
    //     }
    //     if (res.data.code == 800) {
    //       window.location.href = "/";
    //     }
    //   });
    //获取发送短信下拉框信息
    axios
      .post(`/api/urgeSMS/configList`, {
        loanId: id,
      })
      .then((res) => {
        if (res.data.code === 200) {
          for (let i = 0; i < res.data.data.length; i++) {
            if (i == 0) {
              msgSelect.push(<option value="">Please select one</option>);
            }
            msgSelect.push(
              <option value={res.data.data[i].copywriting}>
                {res.data.data[i].copywriting}
              </option>
            );
            this.setState({
              msgSelect: msgSelect,
            });
          }
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
        }
      });
  };
  fstselect = (e) => {
    var value = e.target.value;
    if (value == 6) {
      this.getMsg();
    }
    this.setState({ urge_type: value });
  };
  fsthandleOk = () => {
    this.setState({
      after: true,
    });
    if (this.state.object_select == "") {
      alert("collection object can't be empty.");
      this.setState({
        after: false,
        // fstvisible:false,
      });
      return false;
    } else if (this.state.objectShow && this.state.collectedname == "") {
      alert("name can't be empty.");
      this.setState({
        after: false,
        // fstvisible:false,
      });
      return false;
    } else if (this.state.collectedcell == "") {
      alert("cell can't be empty.");
      this.setState({
        after: false,
        // fstvisible:false,
      });
      return false;
    } else if (this.state.urge_type == 1 && this.state.collecrslt == "") {
      alert("Collection Result can't be empty.");
      this.setState({
        after: false,
        // fstvisible:false,
      });
      return false;
    } else if (this.state.urge_type == 1 && this.state.fstextarea == "") {
      alert("collection detail can't be empty.");
      this.setState({
        after: false,
        // fstvisible:false,
      });
      return false;
    } else if (this.state.urge_type == 6 && this.state.template_select == "") {
      alert("Collection Templates can't be empty.");
      this.setState({
        after: false,
        // fstvisible:false,
      });
      return false;
    }

    let id = this.props.id;
    if (this.state.urge_type == 1) {
      axios
        .post(`/api/loanUrge/saveUrge`, {
          indentId: id,
          urgeName: this.state.collectedname,
          urgeMobile: this.state.collectedcell,
          urgeDetail: this.state.fstextarea,
          urgeType: this.state.urge_type,
          urgeResult: this.state.collecrslt,
          urgeObject: this.state.object_select,
        })
        .then((res) => {
          if (res.data.code == 200) {
            message.success("success");
            this.tableonload();
          } else {
            message.error(res.data.msg);
          }
          this.setState({
            fstvisible: false,
            collectedname: "",
            collectedcell: "",
            fstextarea: "",
            urge_type: 1,
            collecrslt: "",
            object_select: "",
            modal_textarea: "",
            template_select: "",
            after: false,
          });
          if (res.data.code == 800) {
            window.location.href = "/";
          }
        });
    } else {
      axios
        .post(`/api/loanUrge/sendMessage`, {
          loanId: id,
          urgeName: this.state.collectedname,
          urgeMobile: this.state.collectedcell,
          messageDetail: this.state.modal_textarea,
          urgeObject: this.state.object_select,
          messageType: 1,
        })
        .then((res) => {
          if (res.data.code == 200) {
            message.success("success");
            this.tableonload();
          } else {
            message.error(res.data.msg);
          }
          this.setState({
            fstvisible: false,
            collectedname: "",
            collectedcell: "",
            fstextarea: "",
            urge_type: 1,
            collecrslt: "",
            object_select: "",
            modal_textarea: "",
            template_select: "",
            after: false,
            objectShow: true,
            objectCellShow: true,
          });
          if (res.data.code == 800) {
            window.location.href = "/";
          }
        });
    }
  };

  scdselect = (e) => {
    let uid = this.props.uid;
    let id = this.props.id;
    axios
      .post(`/api/loan/credit/getLoanOrderInformation`, {
        id: id,
        uid: uid,
      })
      .then((res) => {
        if (res.data.code == 200) {
          let collectedn = "";
          let collectedc = "";
          let status = this.state.object_select;
          let objectShow_val = true;
          let objectCellShow_val = true;

          if (status == 1) {
            collectedn = res.data.data.userInfo.firstName;
            collectedc = res.data.data.user.mobile;
          } else if (status == 2) {
            collectedn = res.data.data.userInfo.familyMemberName;
            collectedc = res.data.data.userInfo.familyMemberPhone;
          } else if (status == 3) {
            collectedn = res.data.data.userInfo.otherContactName;
            collectedc = res.data.data.userInfo.otherContactPhone;
          } else if (status == 4) {
            collectedn = res.data.data.userInfo.emplayer;
            collectedc = res.data.data.userInfo.officialNumber;
          } else {
            collectedn = "";
            collectedc = "";
            objectShow_val = false;
            objectCellShow_val = false;
          }
          this.setState({
            objectShow: objectShow_val,
            objectCellShow: objectCellShow_val,
            collectedname: collectedn,
            collectedcell: collectedc,
          });
        }

        if (res.data.code == 800) {
          window.location.href = "/";
        }
      });

    var value = e.target.value;
    this.setState({ object_select: value });
  };

  namecollected = (e) => {
    var value = e.target.value;
    this.setState({ collectedname: value });
  };

  resultcoll = (e) => {
    var value = e.target.value;
    this.setState({ collecrslt: value });
  };
  fsttext = (e) => {
    var value = e.target.value;
    this.setState({ fstextarea: value });
  };
  scdtext = (e) => {
    var value = e.target.value;
    this.setState({ scdextarea: value });
  };
  statuscall = (e) => {
    axios
      .post(`/api/common/getCommonListByCode`, {
        code: "ANSWERED_CODE",
      })
      .then((res) => {
        getselect = [];
        if (res.data.code === 200) {
          for (let i = 0; i < res.data.data.length; i++) {
            if (i == 0) {
              getselect.push(<option value="">Please select one</option>);
            }
            getselect.push(
              <option value={res.data.data[i].number}>
                {res.data.data[i].name}
              </option>
            );
            this.setState({
              getselect: getselect,
            });
          }
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
        }
      });
  };

  cellcollected = (e) => {
    var value = e.target.value;
    const reg = /^\d*?$/; // 以任意数字开头和结尾，且中间出现零个或多个数字
    if (reg.test(value)) {
      this.setState({
        collectedcell: value,
      });
    }
  };
  modaltextarea = (e) => {
    var value = e.target.value;
    this.setState({ modal_textarea: value });
  };
  componentDidMount() {
    this.tableonload();
    this.decodePatams();
    // if (localStorage.roleId == 6 || localStorage.roleId == 11 || localStorage.roleId == 2 || localStorage.roleId == 12) {
    //   console.log('1')
    //   this.setState({
    //     bookShow: "block",
    //   });
    // }
  }
  handleCancel = () => {
    this.setState({
      fstvisible: false,
      reduceVisible: false,
      debitVisible: false,
      scdcollectedname: "",
      scdcollectedcell: "",
      modal_textarea: "",
      collectedname: "",
      collectedcell: "",
      fstextarea: "",
      urge_type: 1,
      collecrslt: "",
      object_select: "",
      scdextarea: "",
      after: false,
      objectShow: true,
      objectCellShow: true,
      reduceRemark: "",
    });
  };

  template_change = (e) => {
    // var select = document.getElementById("s1");
    // var value = select.value;
    // var options = select.options;
    // var value1 = options[0].value;
    // var text1 = options[0].text;
    // var index = select.selectedIndex;
    // var selectedText = options[index].text;
    // var collectionTemplates = $("#s1").find("option:selected").text();
    this.setState({
      template_select: e.target.value,
      modal_textarea: e.target.value,
    });
  };

  //table 初始化
  tableonload = () => {
    let uid = this.props.uid;
    let id = this.props.id;

    axios
      .post(`/api/loanUrge/getAllUrgeByUid`, {
        id: id,
        uid: uid,
        pageSize: 10,
        current: this.state.current,
      })
      .then((res) => {
      // console.log('2')
        if (res.data.code == 200) {
          let data = res.data.data;
          let bankdata = [];
          if (res.data.data.isTrue == true) {
            this.setState({
              isShow: true,
            });
          }

          if(res.data.data.isShowAddressBook == true){
            this.setState({
              isShowAddressBook:true
            })
          }
          this.setState({
            should_amount: data.shouldBackFee / 100,
            repay_amount: data.actualBackAmt / 100,
            remian_balance: data.actualShouldBackFee / 100,
            hideidno: data.indentNo,
            debit_money: data.actualShouldBackFee / 100,
            mostvalue: data.actualShouldBackFee / 100,
          });
          if (res.data.data.resultDate.length < 1) {
            this.setState({
              boolean: false,
            });
          } else {
            for (let i = 0; i < res.data.data.resultDate.length; i++) {
              let status = data.resultDate[i].urgeType;
              let object = data.resultDate[i].urgeObject;
              let Answer = data.resultDate[i].urgePhoneAnswer;
              let Result = data.resultDate[i].urgeResult;
              let value = "";
              let value1 = "";
              let value2 = "";
              let value3 = "";
              if (status == 1) {
                value = "Telephone";
              } else if (status == 3) {
                value = "visit client";
              } else if (status == 4) {
                value = "litigation";
              } else if (status == 5) {
                value = "e-mail";
              } else if (status == 6) {
                value = "SMS";
              } else if (status == 99) {
                value = "others";
              } else {
                value = "";
              }

              if (object == 0) {
                value1 = "unknown";
              } else if (object == 1) {
                value1 = "client";
              } else if (object == 2) {
                value1 = "first relationship";
              } else if (object == 3) {
                value1 = "second relationship";
              } else if (object == 4) {
                value1 = "company phone";
              } else if (object == 5) {
                value1 = "adress of book";
              } else if (object == 99) {
                value1 = "others";
              } else {
                value1 = "";
              }

              if (Answer == 0) {
                value2 = "unknown";
              } else if (Answer == 1) {
                value2 = "answered";
              } else if (Answer == 2) {
                value2 = "decline";
              } else if (Answer == 3) {
                value2 = "out of service";
              } else if (Answer == 4) {
                value2 = "power off";
              } else if (Answer == 5) {
                value2 = "vacant number";
              } else if (Answer == 6) {
                value2 = "no answer";
              } else if (Answer == 7) {
                value2 = "busy";
              } else if (Answer == 8) {
                value2 = "others";
              } else {
                value2 = "";
              }

              if (Result == 1) {
                value3 = "promise to pay";
              } else if (Result == 2) {
                value3 = "claim that has paid back";
              } else if (Result == 3) {
                value3 = "not commitment";
              } else if (Result == 4) {
                value3 = "Can not connected";
              } else if (Result == 5) {
                value3 = "Hang up after connecting";
              } else {
                value3 = "Others";
              }

              let time1 = data.resultDate[i].createdTime;
              let a =
                new Date(time1).getTime() +
                new Date().getTimezoneOffset() * 60 * 1000 -
                0 * 60 * 60 * 1000;
              let times1 = moment(new Date(a)).format("YYYY-MM-DD HH:mm:ss");
              if (res.data.data.resultDate == []) {
                times1 = "";
              } else {
                times1 = times1;
              }
              bankdata.push({
                Collectionmethod: value,
                key: i,
                Ordernumber: data.resultDate[i].indentNo,
                Operator: data.resultDate[i].operatorName,
                Collectiontime: times1,
                Collectionobject: value1,
                Collectioncall: data.resultDate[i].urgeMobile,
                objectname: data.resultDate[i].urgeName,
                Answerstatus: value2,
                Collectionresult: value3,
                Collectiondetails: data.resultDate[i].urgeDetail,
                Remarks: data.resultDate[i].remark,
              });
            }
          }

          this.setState({
            tableData: bankdata,
            total: res.data.data.page.total,
            boolean: false,
          });
        } else if (res.data.code == 800) {
          window.location.href = "/";
        }
      });
  };
  //显示debit
  showDebitModal = (record) => {
    this.setState({
      debitVisible: true,
    });
    // this.gradeChange()
  };
  debitMoney = (e) => {
    var value = e.target.value.replace(/[^0-9]/g, "");
    if (value > this.state.mostvalue) {
      value = this.state.mostvalue;
    }
    this.setState({
      debit_money: value,
    });
  };
  debitOk = (e) => {
    // console.log(e);//
    if (this.state.debit_money < 100) {
      alert("Debit amount must exceed 100");
      return false;
    }
    var data = {};
    if (this.state.debit_money == this.state.mostvalue) {
      data = {
        id: this.props.id,
        uid: this.props.uid,
      };
    } else {
      data = {
        money: this.state.debit_money,
        id: this.props.id,
        uid: this.props.uid,
      };
    }
    axios.post(`/api/loanUrge/withhold`, data).then((res) => {
      if (res.data.code == 200) {
        message.success(res.data.msg);
      } else if (res.data.code == 800) {
        this.props.history.push({ pathname: "/" });
      } else {
        message.error(res.data.msg);
      }
    });
    this.setState({
      debitVisible: false,
    });
  };
  render() {
    const columns = [
      {
        title: "Order no",
        dataIndex: "Ordernumber",
        key: "Ordernumber",
      },
      {
        title: "Collector",
        dataIndex: "Operator",
        key: "Operator",
      },
      {
        title: "Collection time",
        dataIndex: "Collectiontime",
        key: "Collectiontime",
      },
      {
        title: "Collection method",
        dataIndex: "Collectionmethod",
        key: "Collectionmethod",
      },
      {
        title: "Collection object",
        dataIndex: "Collectionobject",
        key: "Collectionobject",
      },
      {
        title: "Collection object name",
        dataIndex: "objectname",
        key: "objectname",
      },
      {
        title: "Collection Phone",
        dataIndex: "Collectioncall",
        key: "Collectioncall",
      },
      {
        title: "Collection result",
        dataIndex: "Collectionresult",
        key: "Collectionresult",
      },
      {
        title: "Collection details",
        dataIndex: "Collectiondetails",
        key: "Collectiondetails",
      },
    ];
    const phoneColumns = [
      {
        title: "name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "mobile",
        dataIndex: "mobile",
        key: "mobile",
      },
    ];
    return (
      <div className="order_infor">
        <div>
          <h2>Collection Information</h2>
          <div className="button_show">
            <Button
              className="details_button"
              type="primary"
              size="large"
              style={{
                width: 150,
                marginRight: 20,
                display: this.state.isShow ? "inline-block" : "none",
              }}
              onClick={this.add}
            >
              add
            </Button>
            <Button
              className="details_button"
              type="primary"
              size="large"
              style={{
                width: 150,
                marginRight: 20,
                display: this.state.isShow ? "inline-block" : "none",
              }}
              onClick={this.showReduceModal}
            >
              reduce
            </Button>
            <Button
              className="details_button"
              type="primary"
              size="large"
              style={{
                width: 150,
                marginRight: 20,
                display: this.state.isShow ? "inline-block" : "none",
              }}
              onClick={this.showDebitModal}
            >
              debit
            </Button>
            <Button
              type="primary"
              className="details_button"
              type="primary"
              size="large"
              loading={this.state.addressBtnLoading}
              style={{
                width: 150,
                marginRight: 20,
                float: "right",
                display: this.state.isShowAddressBook ? "inline-block" : "none",
              }}
              onClick={this.showPhoneModel}
            >
              Address book
            </Button>
          </div>

          <Table
            columns={columns}
            dataSource={this.state.tableData}
            loading={this.state.boolean}
            onChange={this.handleChange}
            pagination={false}
          />
        </div>
        <Pagination
          // showQuickJumper
          defaultCurrent={1}
          current={this.state.current}
          total={this.state.total}
          onChange={this.pageChange}
          showTotal={showTotal}
          style={{ textAlign: "right", marginTop: 20 }}
          // showSizeChanger={true}
        />
        <Modal
          title="Address book"
          visible={this.state.phoneVisible}
          onOk={this.phonehandleOk}
          onCancel={this.phonehandleOk}
        >
          <Table
            columns={phoneColumns}
            dataSource={this.state.phoneTableData}
            loading={this.state.bookBoolean}
            onChange={this.phoneHandleChange}
            pagination={{
              // 分页
              simple: true,
              current: this.state.phoneCurrent,
              pageSize: 20,
              total: this.state.phoneTotal,
              onChange: this.phoneChangePage,
            }}
          />
        </Modal>
        <Modal
          title="add record"
          visible={this.state.fstvisible}
          width={800}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={this.state.after}
              onClick={this.fsthandleOk}
            >
              ok
            </Button>,
          ]}
        >
          <div>
            <div className="width_div">
              <div className="collection_div">
                <div>
                  <span>Customer name:</span>
                  <span className="collection_color">
                    {this.state.username}
                  </span>
                </div>
                <div>
                  <span>Overdue days:</span>
                  <span className="collection_color">
                    {this.state.overduedays}days
                  </span>
                </div>
              </div>
              <div className="collection_div">
                <div>
                  <span>loan time:</span>
                  <span className="collection_color">
                    {this.state.loantime}
                  </span>
                </div>
                <div>
                  <span>Overdue amount:</span>
                  <span className="collection_color">
                    {this.state.overamount}
                  </span>
                </div>
              </div>
              <div className="collection_div">
                <div>
                  <span>loan amount:</span>
                  <span className="collection_color">
                    {this.state.loanamount}
                  </span>
                </div>
                <div>
                  <span>due amount:</span>
                  <span className="collection_color">
                    {this.state.deuamount}
                  </span>
                </div>
              </div>
              <div className="collection_div">
                <div>
                  <span>loan term:</span>
                  <span className="collection_color">
                    {this.state.loanterm}
                  </span>
                </div>
                <div>
                  <span>repayment amount:</span>
                  <span className="collection_color">
                    {this.state.repaymentamt}
                  </span>
                </div>
              </div>
              <div className="collection_div">
                <div>
                  <span>should repayment date:</span>
                  <span className="collection_color">
                    {this.state.repaymentdata}
                  </span>
                </div>
                <div>
                  <span>remaining balance:</span>
                  <span className="collection_color">{this.state.balance}</span>
                </div>
              </div>
            </div>
            <Divider style={{ backgroundColor: "#775bee" }} />
            <div className="width_div">
              <div className="collection_div">
                <div className="collection_div_child">
                  <span>collection methods:</span>
                  <select
                    value={this.state.urge_type}
                    className="collection_input"
                    onChange={this.fstselect}
                  >
                    <option value="1" selected="selected">
                      phone
                    </option>
                    <option value="6">SMS</option>
                  </select>
                </div>
                <div className="collection_div_child">
                  <span>collection object:</span>
                  <select
                    value={this.state.object_select}
                    className="collection_input"
                    onChange={this.scdselect}
                  >
                    <option value=""></option>
                    <option value="1">client</option>
                    <option value="2">first relationship</option>
                    <option value="3">second relationship</option>
                    <option value="4">company phone</option>
                    {/* <option value="5">address of book</option> */}
                    <option value="99">Other</option>
                  </select>
                </div>
              </div>
              <div className="collection_div">
                {this.state.objectShow && (
                  <div className="collection_div_child">
                    <span>name of person collected:</span>
                    <input
                      value={this.state.collectedname}
                      className="collection_input"
                      disabled
                      onChange={this.namecollected}
                    />
                  </div>
                )}
                <div className="collection_div_child">
                  <span>cell of person collected:</span>
                  <input
                    value={this.state.collectedcell}
                    className="collection_input"
                    disabled={this.state.objectCellShow}
                    onChange={this.cellcollected}
                  />
                </div>
              </div>
              {this.state.urge_type == 1 && (
                <div className="collection_div">
                  <div className="collection_div_child">
                    <span>Collection Result:</span>
                    <select
                      value={this.state.collecrslt}
                      id="urgeResult"
                      className="collection_input"
                      onChange={this.resultcoll}
                    >
                      {getselect}
                    </select>
                  </div>
                </div>
              )}
              <div
                className="collection_div"
                style={{
                  display: this.state.urge_type == 1 ? "block" : "none",
                }}
              >
                <div className="collection_div_child ">
                  <span>collection detail:</span>
                  <textarea
                    value={this.state.fstextarea}
                    className="collection_input collection_remark"
                    onChange={this.fsttext}
                  ></textarea>
                </div>
                {/* <div className="collection_div_child collection_remark">
                  <span>remark:</span>
                  <textarea
                    value={this.state.scdextarea}
                    className="collection_input collection_remark"
                    onChange={this.scdtext}
                  ></textarea>
                </div> */}
              </div>
              <div
                className="collection_div"
                style={{
                  display: this.state.urge_type == 6 ? "block" : "none",
                }}
              >
                <div className="collection_div_child collection_special">
                  <span>Collection Templates:</span>
                  <select
                    value={this.state.template_select}
                    style={{ width: "79%" }}
                    id="s1"
                    onChange={this.template_change}
                  >
                    {msgSelect}
                  </select>
                </div>
              </div>
              <div
                className="collection_div"
                style={{
                  display: this.state.urge_type == 6 ? "block" : "none",
                }}
              >
                <div className="collection_div_child collection_special">
                  <span>SMS Information:</span>
                  <textarea
                    maxLength="320"
                    value={this.state.modal_textarea}
                    onChange={this.modaltextarea}
                    style={{ width: "79%" }}
                    className="collection_input collection_remark "
                    disabled
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          title="reduce apply"
          visible={this.state.reduceVisible}
          onOk={this.reduceOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              cancel
            </Button>,

            <Button
              key="submit"
              type="primary"
              loading={this.state.btnloading}
              onClick={this.reduceOk}
            >
              ok
            </Button>,
          ]}
        >
          <input value={this.state.hideid} style={{ display: "none" }} />
          <input value={this.state.hideidno} style={{ display: "none" }} />
          {!this.state.reduceShow && (
            <p>
              The current order reduction application is under review, please be
              patient!
            </p>
          )}
          {this.state.reduceShow && (
            <div>
              <div className="both_div">
                <span>should repayment amount:</span>
                <input
                  value={this.state.should_amount}
                  disabled
                  className="reduce_inpt"
                />
              </div>
              <div className="both_div">
                <span>repayment amount:</span>
                <input
                  value={this.state.repay_amount}
                  disabled
                  className="reduce_inpt"
                />
              </div>
              <div className="both_div">
                <span>remaining balance:</span>
                <input
                  value={this.state.remian_balance}
                  disabled
                  className="reduce_inpt"
                />
              </div>
              <div className="both_div">
                <span>reduce amount:</span>
                <input
                  value={this.state.remian_balance}
                  disabled
                  onChange={this.changeAmt}
                  className="reduce_inpt"
                />
              </div>
              <div className="both_div">
                <span>reduce reason:</span>
                <textarea
                  style={{ width: "65%", marginLeft: "3%", height: 80 }}
                  value={this.state.reduceRemark}
                  onChange={this.changeReduceMark}
                />
              </div>
            </div>
          )}
        </Modal>
        <Modal
          title="debit"
          visible={this.state.debitVisible}
          onOk={this.debitOk}
          onCancel={this.handleCancel}
        >
          <div className="both_div">
            <span>debit amount:</span>
            <input
              value={this.state.debit_money}
              onChange={this.debitMoney}
              className="reduce_inpt"
            />
          </div>
        </Modal>
      </div>
    );
  }
}
