/*eslint-disable*/
import React from 'react';
import { Button,Table,DatePicker,Pagination,message } from 'antd';
import './index.css';
import axios from 'axios'
import moment from 'moment';
import {withRouter} from 'react-router-dom'

const { RangePicker } = DatePicker;
var getSelect = []
function showTotal(total) {
  return `Total ${total} items`;
}

class getReminds0 extends React.Component{
    constructor(props){
        super(props);
        this.state={

          tableData:[], 
          current:1,
          length:1,
          pageSize:10,
          boolean:true
          
        }
      }
      componentDidMount(){
        this.select()
        this.secondTable();
      }
      //设置时间

    showmodal=()=>{
        alert(this.index)
    }
    pageChange=(page)=>{
      this.setState({
        current: page
        },()=>{
          this.searchClick()
        })
    }
    onShowSizeChange=(current, pageSize)=>{

      this.setState({
        pageSize: pageSize
      },()=>{
        this.secondTable()
      })
  }

  selectFst=(e)=>{
    var value = e.target.value;
    this.setState({
      fstSelect:value,
    });
  }
//页面初始化加载的table
  secondTable=()=>{
      let id = localStorage.id;
      console.log('adsadsdsadsa',id)
      axios
        .post(`/api/remindData/getRemindOrderHistoryDetails`,{
        pageSize: this.state.pageSize,
        current: this.state.current,
        id:id,
      })
      .then(res=>{
        if(res.data.code === 200){
          const data1 = [];
          const data = res.data.data.resultDate
          if(res.data.data.resultDate.length < 1){
            this.setState({
              boolean:false
            })
          }else{
            for (let i = 0; i < data.length; i++) {
              data1.push({
                key: i,
                remindDate:data[i].remindDate,
                collectionName:data[i].collectionName,
                  grade:data[i].overdueGrade,
                distributionOrder:data[i].distributionOrder,
                distributedRepayOrder:data[i].distributedRepayOrder,
                remindRepayRate:data[i].remindRepayRate,
                currentUnrepayOrder:data[i].currentUnrepayOrder,
                firstOverdueRate:data[i].firstOverdueRate,
                remindedOrder:data[i].remindedOrder,
                unremindUnrepay:data[i].unremindUnrepay,
                extendOrder:data[i].extendOrder,
              });
           
            } 
          }
       
          this.setState({
            tableScdData:data1,
            length:res.data.data.page.total,
            boolean:false
          })
        }else if(res.data.code ==800){
          this.props.history.push({ pathname: "/"});
        }else{
  
        }
      })

  }

       //获取select
       select=()=>{
        axios
          .post(`/api/remindData/getRemindUsers`,{
        })
        .then(res=>{
          if(res.data.code === 200){
            getSelect=[]
          let data = res.data.data.resultDate
          for(let i=0;i<data.length;i++ ){
            if(i==0){
              getSelect.push(<option value=''>all</option> )
            }
            getSelect.push(       
              <option value={data[i].id}>{data[i].userName}</option>
          )
          // console.log('select',collector)
          this.setState({
            getSelect:getSelect
          })
          }
          }else if(res.data.code ==800){
            this.props.history.push({ pathname: "/"});
          }
          else{
          }
        })
        
      }
          //搜索
          searchClick=()=>{ 
            let id = localStorage.id;
            this.setState({
              boolean:true
            })
                  axios
                    .post(`/api/remindData/getRemindOrderHistoryDetails`,{

                    pageSize: this.state.pageSize,
                    collectionId:this.state.fstSelect,
                    current: this.state.current,
                    id:id,
                  })
                  .then(res=>{
                    if(res.data.code === 200){
                      const data1 = [];
                      const data = res.data.data.resultDate
                      if(res.data.data.resultDate.length < 1){
                        this.setState({
                          boolean:false
                        })
                      }else{
                        for (let i = 0; i < data.length; i++) {
                          data1.push({
                            key: i,
                            remindDate:data[i].remindDate,
                            collectionName:data[i].collectionName,
                              grade:data[i].overdueGrade,
                            distributionOrder:data[i].distributionOrder,
                            distributedRepayOrder:data[i].distributedRepayOrder,
                            remindRepayRate:data[i].remindRepayRate,
                            currentUnrepayOrder:data[i].currentUnrepayOrder,
                            firstOverdueRate:data[i].firstOverdueRate,
                            remindedOrder:data[i].remindedOrder,
                            unremindUnrepay:data[i].unremindUnrepay,
                            extendOrder:data[i].extendOrder,
                          });
                          console.log('aaaaa',data1)
                       
                        } 
                      }  
                       this.setState({
                        tableScdData:data1,
                        boolean:false
                      })
                     
                    }else if(res.data.code ==800){
                      this.props.history.push({ pathname: "/"});
                    }else{
                    }
                  })
                  
                }
    render(){
        

        const columns = [
           {
              title: 'Date',
              dataIndex: 'remindDate',
              key: 'remindDate'
            }, 
            {
              title: 'Collector',
              dataIndex: 'collectionName',
              key: 'collectionName'
            },
            {
                title: 'Overdue grade',
                dataIndex: 'grade',
                key: 'grade',
                render: (value) => {
                    if(value==21){
                        return 'S0-Old'
                    }else if(value==1){
                        return 'S1-Old'
                    }else if(value==2){
                        return 'S2-Old'
                    }else if(value==3){
                        return 'M1-Old'
                    }else if(value==4){
                        return 'M2'
                    }else if(value==5){
                        return 'M3'
                    }else if(value==6){
                        return 'M4'
                    }else if(value==22){
                        return 'S0-New'
                    }else if(value==11){
                        return 'S1-New'
                    }else if(value==12){
                        return 'S2-New'
                    }else if(value==13){
                        return 'M1-New'
                    }else{
                        return '--'
                    }

                }
            },
            {
              title: 'Total Allocate',
              dataIndex: 'distributionOrder',
              key: 'distributionOrder'
            }, 
            {
              title: 'Repaid after Allocate',
              dataIndex: 'distributedRepayOrder',
              key: 'distributedRepayOrder'
            }, 
            {
              title: 'remind repayment rate',
              dataIndex: 'remindRepayRate',
              key: 'remindRepayRate'
            },
            {
              title: 'Today extend order',
              dataIndex: 'extendOrder',
              key: 'extendOrder',
              width:100,
            },
            {
              title: 'Total Overdue',
              dataIndex: 'currentUnrepayOrder',
              key: 'currentUnrepayOrder'
            },
            {
                title: 'Overdue Rate',
                dataIndex: 'firstOverdueRate',
                key: 'firstOverdueRate'
            }, 
            {
                title: 'Reminded',
                dataIndex: 'remindedOrder',
                key: 'remindedOrder'
            },
            {
                title: 'Not Remind',
                dataIndex: 'unremindUnrepay',
                key: 'unremindUnrepay'
            },
          ];
        return(
            <div className="audit_content">
                <div className="commom_top">
                    <div className="mid_left" >
                        <span className="description left" style={{width:150}}>Collection reminder:</span>

                        <select className="form_select" value={this.state.fstSelect} onChange={this.selectFst.bind(this)}>
                            {getSelect}
                        </select>
                    </div>
                <Button type="primary" style={{width:170,marginRight:20,marginLeft:'15%',height:40,border:'none'}}  className="common_color" onClick={this.searchClick.bind(this)}>Search</Button>
                </div>
                <div className="table">
                <Table columns={columns} dataSource={this.state.tableScdData} loading={this.state.boolean} pagination={false} scroll={{ x: '100%' }}/>
                </div>

                  <Pagination
                  // showQuickJumper
                  showSizeChanger                          //展示改变pagesize样式
                  onShowSizeChange={this.onShowSizeChange} //pagesize改变时回调函数
                  pageSizeOptions={["10","50","100"]}   //设置pagesize区间
                  defaultCurrent={1} 
                  current={this.state.current}
                  total={this.state.length} 
                  onChange={this.pageChange} 
                  pageSize={this.state.pageSize}
                  showTotal={showTotal}
                  style={{textAlign:'right',marginTop:20}}
                  />
            </div>
        )
    }
}
export default withRouter(getReminds0)