/*eslint-disable*/
import React from 'react';
import { Input, Button, Table, Modal,Icon, Pagination, DatePicker, Upload, Tooltip, message } from 'antd';
import axios from 'axios'
import './index.scss'
import moment from 'moment';
import Schema from 'async-validator'
import {validateNumber} from '../../utils/validate'
import{getsmsMarketData,submitCsv} from '../../api/smsMarketApi'
import { withRouter } from 'react-router-dom'


function showTotal(total) {
    return `Total ${total} items`;
}
var getselect = []
const dateFormat = 'YYYY-MM-DD' || undefined;
const { RangePicker } = DatePicker;
const { Search } = Input;
const rules = {
    fileList:[//文件
        {required: true, message: 'please upload a file '},
    ],
    indate:[//有效时间
        {required: true, message: 'please enter indate '},
        {validator:validateNumber,message:'indate should be number or positive integer'}
    ],
    // theme:[//主题
    //     {required: true, message: 'please enter theme '},
    // ],
}
class SmsManage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 1,
            total: 1,
            from: '',
            to: '',
            pageSize: 10,
            fileList:[],
            tableData: [],
            boolean: true,
            theme:'',
            indate:'',
        }
    }
    componentDidMount() {
        this.setState({
            from: moment().startOf('month'),
            to: moment().endOf('month'),
        }, () => {
            this.getTableData()
        })
    }
    //搜索数据
    doSearch = () => {
        let params = {
            batchNo: this.state.batchNo,
            theme: this.state.theme,
        }
        this.getTableData(params)
    }
    //重置搜索框
    clearSearch = () => {
        this.setState({
            from: moment().startOf('month'),
            to:  moment().endOf('month'),
            batchNo: '',
            theme: ''
        }, () => {
            this.getTableData()
        })
    }
    //改变页面条数时的回调
    onShowSizeChange=(current, pageSize)=>{

        this.setState({
          pageSize: pageSize
        },()=>{
          this.doSearch()
        })
    }
    //关闭弹窗
    handleCancel=()=>{
        this.setState({
            uploadvisible:false,
            fileList:'',
            theme:'',
            indate:'',
            isdisabled:'',
            theme1:''
        })
    }
    //打开弹窗
    openModal=()=>{
            this.setState({
                uploadvisible:true,
            })
    }
    //表单提交
    uploadhandleOkToday=()=>{
        const validator = new Schema(rules);
        validator.validate({ fileList:this.state.fileList,theme:this.state.theme,indate:this.state.indate}, (errors, fields) => {
            if (!errors) {
                this.setState({
                    scdafter:true
                })
                const { fileList} = this.state;
                const formData = new FormData();
                formData.append('file', fileList[0]);  
                formData.append('theme',this.state.theme1);
                formData.append('indate',this.state.indate)
                console.log('fileList',fileList)
                submitCsv(formData)
                .then(res => {
                    if (res.data.code === 200) {        
                        message.success(res.data.msg)
                        this.doSearch()
                        this.setState({ 
                            uploadvisible:false,
                            uploadvisibletoday:false,
                            fileList:'',
                            scdafter:false,
                            isdisabled:false,
                            theme:'',
                            indate:'',
                            theme1:''
                        })
                    } else if (res.data.code == 800) {
                        this.props.history.push({pathname: "/"});
                    } else {
                        message.error(res.data.msg)
                        this.setState({
                          uploadvisible:false,
                          uploadvisibletoday:false,
                          scdafter:false,
                          fileList:'',
                          isdisabled:false
                        })
                    }
                })

            }else {
                message.error(errors[0].message)
               }
        })
      }
    //请求表格数据
    getTableData(params) {
        this.setState({
            boolean: true
        })
        let starttime = moment(this.state.from).format("YYYY-MM-DD")
        let endtime = moment(this.state.to).format("YYYY-MM-DD")
        getsmsMarketData({
            ...params,
            beginTime: starttime,
            endTime: endtime,
            current: this.state.current,
            pageSize: this.state.pageSize,
        }).then(res => {
            if (res.data.code === 200) {
                this.setState({
                    boolean: false,
                    tableData: res.data.data.resultDate,
                    length: res.data.data.page.total
                })
            } else if (res.data.code == 800) {
                this.props.history.push({ pathname: "/" });
            }
        })
    }
    //表单值双向绑定
    fstaddonChange = (e) => {
        let InputName = e.target.name;
        let InputValue = e.target.value;
        this.setState({
            [InputName]: InputValue.replace(/\s+/g, ""),
        })
    }
    fstaddonChange1= (e) => { //可以输入空格
        let InputName = e.target.name;
        let InputValue = e.target.value;
        this.setState({
            [InputName]: InputValue,
        })
    }
    pageChange = (page) => {
        this.setState({
            current: page
        }, () => {
            this.doSearch()
        })
    }
    //时间选择器范围
    rangeOnChange = (dates, dateStrings) => {
        console.log(dateStrings, '时间选择器')
        this.setState({ from: dateStrings[0], to: dateStrings[1] })
    }
    render() {
        const columns = [
            {
                title: '批次ID',
                dataIndex: 'batchNo',
                key: 'batchNo',
                render: (value) => {
                    return value || '——'
                }

            },
            {
                title: '主题',
                dataIndex: 'theme',
                key: 'theme',
                render: (value) => {
                    return value || '——'
                }
            },
            {
                title: '名单数量',
                dataIndex: 'clientTotal',
                key: 'clientTotal',
                render: (value) => {
                    return value || '——'
                }

            },
            {
                title: '上传时间',
                dataIndex: 'createTime',
                key: 'createTime',
                render: (value) => {
                    return value || '——'
                }

            }
        ];
        const { uploading, fileList } = this.state;
        const props = {
            onRemove: (file) => {
                this.setState((state) => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                    };
                });
            },
            beforeUpload: (file) => {
                this.setState(state => ({
                    fileList: [...state.fileList, file],
                    isdisabled: true
                }));
                return false;
            },
            fileList,
        };
        return (
            <div className="audit_content">
                <div className="commom_top" style={{ alignItems: 'center', marginBottom: 30 }}>
                    <label>批次：</label>
                    <Search
                        placeholder="批次ID"
                        onSearch={value => console.log(value)}
                        style={{ width: 290 }}
                        name="batchNo"
                        value={this.state.batchNo}
                        onChange={this.fstaddonChange}
                    />
                    <label>主题：</label>
                    <Search
                        placeholder="Theme"
                        onSearch={value => console.log(value)}
                        style={{ width: 290, marginRight: 10 }}
                        value={this.state.theme}
                        name="theme"
                        onChange={this.fstaddonChange1}
                    />
                    <label>上传时间：</label>
                    <RangePicker
                        style={{ width: 290, }}
                        ranges={{
                            Today: [moment(), moment()],
                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                        }}
                        showTime
                        onChange={this.rangeOnChange}
                        format={dateFormat}
                        value={this.state.from === undefined || this.state.to === undefined || this.state.from === "" || this.state.to === "" ? null : [moment(this.state.from, dateFormat), moment(this.state.to, dateFormat)]}
                    />
                    <Button type="primary" style={{
                        width: 170,
                        marginLeft: '4%',
                        height: 40,
                    }}
                    className="common_color"
                        onClick={this.doSearch}
                    >Search</Button>
                <Button type="primary" style={{
                        width: 170,
                        marginLeft: '4%',
                        height: 40,
                        border:'none'
                    }}
                    className="common_color_scd"
                        onClick={this.clearSearch}
                    >Refresh</Button>

                </div>
                <div className="upLoadBtn">
                    <Button type="primary" style={{
                        width: 170,
                        height: 40,
                        float:"right",
                    }} 
                    className="common_color_fst"
                    onClick={this.openModal}
                    >上传名单</Button>
                    </div>
                <div className="table">
                    <Table columns={columns} dataSource={this.state.tableData} loading={this.state.boolean}
                        rowKey={record => record.batchNo}
                        pagination={false} />
                </div>

                <Pagination
                    // showQuickJumper
                    onShowSizeChange={this.onShowSizeChange}//pagesize改变时回调函数
                    pageSizeOptions={["10","50","100"]}   //设置pagesize区间
                    defaultCurrent={1}
                    current={this.state.current}
                    total={this.state.length}
                    onChange={this.pageChange}
                    showTotal={showTotal}
                    style={{ textAlign: 'right', marginTop: 20 }}
                    showSizeChanger={true}
                    pageSize={this.state.pageSize}
                />
                <Modal
                    title="upload"
                    onCancel={this.handleCancel}
                    visible={this.state.uploadvisible}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            cancel
            </Button>,
                        <Button key="submit" type="primary" loading={this.state.scdafter} onClick={this.uploadhandleOkToday}>
                            ok
            </Button>,
                    ]}>
                          <div className={'formItem'}>
                    <label>上传文件：</label>
                    <Upload {...props}
                        accept=".csv"
                        disabled={this.state.isdisabled}>
                        <Button type="primary" style={{ width: 120, marginRight: 20, height: 40,  }}  className="common_color" onClick={this.changeupload}>
                            <Icon type="upload" /> 选择文件
                        </Button>
                    </Upload>
                    </div>
                    <div className={'formItem'}>

                    <label>主题：</label>
                    <Input name="theme1" onChange={this.fstaddonChange} value={this.state.theme1} maxLength={20}/>
                    </div>
                    <div className={'formItem'}>
                    <label>有效时间：</label>
                    <Input  addonAfter="天" name="indate" onChange={this.fstaddonChange} value={this.state.indate} maxLength={10}/>
                    </div>
                </Modal>
            </div>
        )
    }
}
export default withRouter(SmsManage)