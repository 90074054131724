/*eslint-disable*/
import React from 'react';
import { Input,Button,Table,Modal,Pagination,DatePicker,Tooltip,message } from 'antd';
import './index.css';
import axios from 'axios'
import {withRouter} from 'react-router-dom'
import EventEmitter from '../../../utils/events'

import {Link} from 'react-router-dom'
import moment from 'moment';
const { RangePicker } = DatePicker;
  const { Search } = Input;
  const dateFormat = 'YYYY-MM-DD'||undefined;
var whitchshow = []
var credit = []
var Select = []

   
  function showTotal(total) {
    return `Total ${total} items`;
  }
class MyOrder extends React.Component {
    constructor(props){
      super(props);
      this.state={
        fstSelect:'',
        scdSelect:'',
        thdSelect:2,
        fouSelect:'',
        searchVal:'',
        mobileNum:'',
        current:1,
        fstinpt:'',
        order_statues:'please select one',
        modalfstSelect1:0,
        inptdis: {
          display:"none"
      },
      fsttextarea:'',
      pageSize:10,
      boolean:true,
      reviewbutton: {display: "block"},
      detailsbutton: {display: "block"},
      reviewreason:'',
      modify:false
      }
    }
    onShowSizeChange=(current, pageSize)=>{

      this.setState({
        pageSize: pageSize
      },()=>{
        this.onload()
      })
  }
        //页码改变时触发
        pageChange=(page)=>{
          this.setState({
            current: page
            },()=>{
              this.searchClick()
            })
        }
        rangeOnChange=(dates, dateStrings)=>{
          this.setState({ from: dateStrings[0],to:dateStrings[1] })
        }
    //选择table触发
    handleChange = (pagination, filters, sorter) => {
      console.log('Various parameters', pagination, filters, sorter);
      this.setState({
      });
    };
    clearFilters = () => {
      this.setState({ filteredInfo: null });
    };
  


    changeTime =(params)=> {
      let time1 = params.time
      let time2 = params.when
      let date1=new Date(time1);
      let date2=new Date(time2);
      var date3=date2.getTime()-date1.getTime() 
      let days=Math.floor(date3/(24*3600*1000))
      var leave1=date3%(24*3600*1000)    //计算天数后剩余的毫秒数
      let hours=Math.floor(leave1/(3600*1000))
      var leave2=leave1%(3600*1000)        //计算小时数后剩余的毫秒数
      let minutes=Math.floor(leave2/(60*1000))
      if(days==0&&hours<4){
        return <span style={{color:'black'}}>{params.sure}</span>
      }else if(days==0&&hours>=4&&hours<8){
        return <span style={{color:'purple'}}>{params.sure}</span>
      }else if(days==0&&hours>=8){
        return <span style={{color:'red'}}>{params.sure}</span>
      }else{
        return <span style={{color:'red'}}>{params.sure}</span>
      }
     
    }
    changeRisk =(params)=>{
      console.log('pams',params)
      if(params.risk == 15){
        return <span style={{color:'red'}}>reject</span>
      }else if(params.risk == -1){
        return <span style={{color:'red'}}>reject</span>
      }else if(params.risk == -2){
        return <span style={{color:'red'}}>reject</span>
      }else if(params.risk == 1){
        return <span style={{color:'blue'}}>pass</span>
      }else if(params.risk == 2){
        return <span style={{color:'blue'}}>pass</span>
      }else if(params.risk == 3){
        return <span style={{color:'orange'}}>warning</span>
      }else{
        return
      }
    }

    handleCancel=()=>{
      this.setState({
        fstvisible: false,
        scdvisible: false,
        modify:false,
        modalfstSelect1:0,
        modalscdSelect:0,
        fstSelect:0,
        fsttextarea:'',
        order_statues:'please select one',
        inptdis: {display: "none"},
      });
    }
    //重置按钮
    reloadClick(e){
      this.setState({
        fstSelect:2,
        scdSelect:'',
        thdSelect:2,
        fouSelect:'',
        searchVal:'',
        mobileNum:'',
        from:undefined,
        to:undefined,
        returnSelect:''
      },()=>{
        this.onload()
      });
     
    }
    showFstModal = (record) => {
      var id = record.id
      this.setState({
        fstinpt:id,
        fstvisible: true,
      });
      // this.gradeChange()
    };
    //modal第一个select
    gradeChange=(e)=>{
      var value = e.target.value;
      var code1 = '';
      this.setState({
        modalfstSelect1:value
      })
      var objS = document.getElementById("modal_fstid1");
      var grade = objS.options[objS.selectedIndex].value;
      if(grade==0){
        code1='please select one'
        this.setState({
          inptdis: {
            display:"none",
             
        }, order_statues:'please select one'}) 
       
      }else if(grade==1){
        code1='Manual Approved'
        this.setState({
          inptdis: {
            display:"none",
            
        }, order_statues:'Manual Approved'}) 
       
      }
      else{
        this.setState({
          inptdis: {
            display:"inline-block"
        }, }) }
      if(grade == 2){
        code1='NO_PASS_RETURN'
        this.setState({
          order_statues:'Return by Manual Review',
          reviewreason:'returnReason'

        })
      }else if(grade == 4){
        code1='NO_PASS_REJECT_IMMEDIATELY'
        this.setState({
          order_statues:'Reject by Manual Review',
          reviewreason:'rejectImmReason'

        })
      }
      else if(grade == 3){
        code1 = 'NO_PASS_REJECT'
        this.setState({
          order_statues:'Reject by Manual Review',
          reviewreason:'rejectReason'

        })
      }
      else if(grade == 5){
        code1 = 'NO_PASS_BLACK_LIST'
        this.setState({
          order_statues:'Reject by Manual Review',
          reviewreason:'blackListReason'

        })
      }
      axios
        .post(`/api/common/getCommonListByCode`,{

          code:code1
      })
      .then(res=>{
        whitchshow=[]
        if(res.data.code === 200){
          for(let i=0;i<res.data.data.length;i++ ){
            if(i==0){
              whitchshow.push(<option value=''>Please select one</option> )
            }
            whitchshow.push(       
              <option value={res.data.data[i].number}>{res.data.data[i].name}</option>
          )
          this.setState({
            whitchshow:whitchshow
          })
          }
        }else if(res.data.code ==800){
          this.props.history.push({ pathname: "/"});
        }else{
        }
      })
      
    }
    onload=()=>{
      axios
        .post(`/api/loan/credit/getLoanFrontAuditList`,{
          orderStatus:2,
          pageSize: 10,
          current: this.state.current,
      })
      .then(res=>{
        if(res.data.code === 200){
          const data = [];
          if(res.data.data.resultDate.length<1){
            this.setState({
              boolean:false
            })
          }else{
            for (let i = 0; i < res.data.data.resultDate.length; i++) {
              let newtime = res.data.data.resultDate[i].auditUserTime;
              //new Date().getTimezoneOffset() 获取当前时间时间戳   （0 * 60 * 60 * 1000） 0 即为时区
              let timeaudit= new Date(newtime).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
              let auditimes=moment(new Date(timeaudit)).format("YYYY-MM-DD HH:mm:ss");
              if(res.data.data.resultDate[i].auditUserTime == null){
                auditimes = ''
              }else{
                auditimes = auditimes
              }
              let time=res.data.data.resultDate[i].createdTime;
              //new Date().getTimezoneOffset() 获取当前时间时间戳   （0 * 60 * 60 * 1000） 0 即为时区
              let east0time = new Date(time).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
              let times=moment(new Date(east0time)).format("YYYY-MM-DD HH:mm:ss");
             
              let state=res.data.data.resultDate[i].status;
              let date1=new Date();
              let date2=new Date();
              if(state==2){
                date1=new Date(times.replace(/-/g,"/"));  //订单创建时间
              }else{
                date1=new Date(times.replace(/-/g,"/"));  //订单创建时间
                date2=new Date(auditimes.replace(/-/g,"/"));  //人工复审时间
              }
             var date3=date2.getTime()-date1.getTime() 
              
           
                  let days=Math.floor(date3/(24*3600*1000))
                  //计算出小时数
                  var leave1=date3%(24*3600*1000)    //计算天数后剩余的毫秒数
                  let hours=Math.floor(leave1/(3600*1000))
                  //计算相差分钟数
                  var leave2=leave1%(3600*1000)        //计算小时数后剩余的毫秒数
                  let minutes=Math.floor(leave2/(60*1000))
                  if(days==0&&hours<4){
                        // return "<font color='black'>"+hours+"h"+minutes+"m</font>"
                          
                          date3=hours+"h"+minutes+"m"
                          // $('.color').css('color','black');
                  }else if(days==0&&hours>=4&&hours<8){
                        // return "<font color='purple'>"+hours+"h"+minutes+"m</font>"
                       
                       
                          date3=hours+"h"+minutes+"m"
                          // $('.color').css('color','purple');
                        // color='purple'
                    }else if(days==0&&hours>=8){
                        // return "<font color='red'>"+hours+"h"+minutes+"m</font>"
                       
                          date3=hours+"h"+minutes+"m"
                          // $('.color').css('color','red');
                    }else{
                        // return "<font color='red'>"+days+"d"+hours+"h"+minutes+"m</font>"
                       
                          date3=+days+"d"+hours+"h"+minutes+"m";
                          // $('.color').css('color','red');
                        
                    }
                    let status = res.data.data.resultDate[i].status
                    let statusDescription = ''
                    if(status == 2){
                      statusDescription = 'Pending Manual Review'
                      this.setState({
                        reviewbutton: {display: "block"},
                        detailsbutton: {display: "block"},
                      })
                    }else if(status == 32){
                      statusDescription = 'Reject by Manual Review'
                      this.setState({
                        reviewbutton: {display: "none"},
                        detailsbutton: {display: "block"},
                      })
                    }else if(status == 33){
                      statusDescription = 'Return by Manual Review'
                      this.setState({
                        reviewbutton: {display: "none"},
                        detailsbutton: {display: "block"},
                      })
                    }else if(status == 1){
                      statusDescription = 'Pending Auto Review'
                      this.setState({
                        reviewbutton: {display: "none"},
                        detailsbutton: {display: "block"},
                      })
                    }
                data.push({
                  key: i,
                  type:res.data.data.resultDate[i].appCode,
                  number: res.data.data.resultDate[i].indentNo,
                  name:res.data.data.resultDate[i].firstName,
                  phone: res.data.data.resultDate[i].mobile,
                  bvn: res.data.data.resultDate[i].dentityCard,
                  amount: res.data.data.resultDate[i].balance/100,
                  days: res.data.data.resultDate[i].cycle,
                  time: res.data.data.resultDate[i].createdTime,
                  times:res.data.data.resultDate[i].repaymentCount,
                  state:statusDescription,
                  risk:res.data.data.resultDate[i].auditRiskStatus,
                  people:res.data.data.resultDate[i].reviewers,
                  when:res.data.data.resultDate[i].auditUserTime,
                  detail:res.data.data.resultDate[i].auditDetail,
                  reson:res.data.data.resultDate[i].auditUserReson,
                  sure:date3,
                  id:res.data.data.resultDate[i].id,
                  uid:res.data.data.resultDate[i].uid,
  
                });
               
              }
          }
         
            this.setState({
              tableData:data,
              length:res.data.data.page.total,
              boolean:false
            })
            // message.success(res.data.msg);
        }else if(res.data.code ==800){
          this.props.history.push({ pathname: "/"});
        }else{
          // message.error(res.data.msg);
        }
      })
    }
        selectThd(e){
          var value = e.target.value;
          this.setState({
            thdSelect:value,
          });
        }
    componentDidMount(){
     this.onload()
     this.select()
     this.getselect()
    }
    getselect=()=>{
      axios
      .post(`/api/appshadow/findAppShadowList`,{
  
    })
    .then(res=>{
       Select=[]
      if(res.data.code === 200){
        for(let i=0;i<res.data.data.list.length;i++ ){
          
            if(i==0){
              Select.push(<option value=''>please select one</option> )
              }
              Select.push(       
              <option value={res.data.data.list[i].appCode}>{res.data.data.list[i].appCode}</option>
          )
          
        this.setState({
          Select:Select
        })
        }
      }else if(res.data.code ==800){
        this.props.history.push({ pathname: "/"});
      }else{
      }
    })
    }
    text(record){
         // var tempwindow=window.open('_blank')
         window.location='/#/details/index?id='+record.id+'&uid='+record.uid
         // console.log(window.location)
         let test = [{path: '/details/index',key: `/details/index/${record.id}/${record.uid}`,number: `${record.id}/${record.uid}`}]
         EventEmitter.trigger('btn', test[0])
         EventEmitter.trigger('idanduid', test[0].number)
    }
    //输入框
    searchIpt(e){
      var value = e.target.value;
      this.setState({ searchVal: value.replace(/\s+/g,"") })
    }
      //号码输入框
      searchIpt1(e){
        var value = e.target.value;
        this.setState({ mobileNum: value.replace(/\s+/g,"") })
      }
    //选择订单状态
    selectFst(e){
      var value = e.target.value;
      this.setState({
        fstSelect:value,
      });
    }
    //选择审核时效
    selectThd(e){
      var value = e.target.value;
      this.setState({
        thdSelect:value,
      });
    }
    //选择apptype
    selectFou(e){
      var value = e.target.value;
      this.setState({
        fouSelect:value,
      });
    }

        //modal第二个select
        modelselectTwo=(e)=>{
          var value = e.target.value;
          this.setState({
            modalscdSelect:value
          })
        }
            //第一个textarea
    fsttxarea=(e)=>{
      let value = e.target.value;
      this.setState({ fsttextarea: value })
    }
          //第个modal提交按钮
          fsthandleOk = (e) => {
            this.setState({
              modify:true
            })
            if(this.state.fsttextarea==''){
              alert("Review details can't be empty.")
              return false
            }
            else if(this.state.modalfstSelect1==0){
              alert("Review status can't be empty.")
              return false
            }
         
            var objS = document.getElementById("modal_fstid1");
            var grade = objS.options[objS.selectedIndex].value;
            // console.log(e);//
            if(this.state.modalfstSelect1 == 1){
              axios
                .post(`/api/loan/credit/passAudit`,{

                id:this.state.fstinpt,
                remark:this.state.fsttextarea,
              })
              .then(res=>{
                let data = res.data
                if(res.data.code === 200){
                  this.onload()
                  this.reloadClick()
                  this.setState({
                    modify:false,
                    fsttextarea:'',
                    modalfstSelect1:'',
                    fstSelect:2,
                    order_statues:'please select one',
                    fstvisible: false,
                    scdvisible: false,
                    inptdis: {
                      display:"none",     
                  }
                    })
                  message.success(res.data.msg);
                }else if(res.data.code ==800){
                  this.props.history.push({ pathname: "/"});
                }
                else if(res.data.code ===405){
                  message.success(res.data.msg)
                  this.setState({
                    modify:false,
                  })
                 }else{
                  message.error(res.data.msg);
                }
              })
            }else{
              
               if(this.state.modalscdSelect==0){
                alert("Reasons can't be empty.")
                return false
              }
              var objS = document.getElementById("modal_fstid1");
              var seltext = objS.options[objS.selectedIndex].innerText;
              axios
                .post(`/api/loan/credit/passAudit`,{

                id:this.state.fstinpt,
                reason:this.state.fsttextarea,
                refuseValue:this.state.reviewreason,
                refuseReason:this.state.modalscdSelect,
              })
              .then(res=>{
                if(res.data.code === 200){
                  this.onload()
                  this.reloadClick()
                  this.setState({
                  modify:false,
                  fsttextarea:'',
                  modalfstSelect1:0,
                  fstSelect:0,
                  order_statues:'please select one',
                  fstvisible: false,
                  scdvisible: false,
                  modalscdSelect:0,
                  inptdis: {
                    display:"none",     
                }
                  })
                  whitchshow=[]
                  grade = 0
                  message.success(res.data.msg);
                }else if(res.data.code ==800){
                  this.props.history.push({ pathname: "/"});
                }
                else if(res.data.code ===405){
                  message.success(res.data.msg)
                 }else{
                  this.setState({
                    fsttextarea:'',
                    modalfstSelect1:0,
                    fstSelect:0,
                    fsttextarea:'',
                    fstvisible: false,
                    scdvisible: false,
                    order_statues:'please select one',
                    inptdis: {
                      display:"none",   
                  }
                    })
                    whitchshow=[]
                    grade = 0
                    message.error(res.data.msg);
                }
              })
            }
          };

  //获取select
  select=()=>{
    axios
    .post(`/api/common/getCommonListByCode`,{
      code:'NO_PASS_RETURN'
  })
  .then(res=>{
    credit=[]
    if(res.data.code === 200){
      let data = res.data
      for(let i=0;i<res.data.data.length;i++ ){
        if(i==0){
          credit.push(<option value=''>Please select one</option> )
        }
        credit.push(       
          <option value={res.data.data[i].name}>{res.data.data[i].name}</option>
      )
      this.setState({
        credit:credit
      })
      }
    }else if(res.data.code ==800){
      this.props.history.push({ pathname: "/"});
    }else{
    }
  })
  }
          searchClick=()=>{
            this.setState({
              boolean:true
            })
            axios
            .post(`/api/loan/credit/getLoanFrontAuditList`,{
            orderStatus:2,
            pageSize: this.state.pageSize,
            current: this.state.current,
            indentNo:this.state.searchVal,
            mobile:this.state.mobileNum,
            status:this.state.fstSelect,
            // creditUserId:this.state.scdSelect,
            orderByCreateTime:this.state.thdSelect,
            appCode:this.state.fouSelect,
            beginTime:this.state.from,
            endTime:this.state.to,
            returnMsg:this.state.returnSelect
          })
          .then(res=>{
            if(res.data.code === 200){
              const data = [];
              if(res.data.data.resultDate.length<1){
                this.setState({
                  boolean:false
                })
              }
              else{
                for (let i = 0; i < res.data.data.resultDate.length; i++) {
                  let newtime = res.data.data.resultDate[i].auditUserTime;
                  //new Date().getTimezoneOffset() 获取当前时间时间戳   （0 * 60 * 60 * 1000） 0 即为时区
                  let timeaudit= new Date(newtime).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
                  let auditimes=moment(new Date(timeaudit)).format("YYYY-MM-DD HH:mm:ss");
                  if(res.data.data.resultDate[i].auditUserTime == null){
                    auditimes = ''
                  }else{
                    auditimes = auditimes
                  }
                  let time=res.data.data.resultDate[i].createdTime;
                  //new Date().getTimezoneOffset() 获取当前时间时间戳   （0 * 60 * 60 * 1000） 0 即为时区
                  let east0time = new Date(time).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
                  let times=moment(new Date(east0time)).format("YYYY-MM-DD HH:mm:ss");
                 
                 
                  let state=res.data.data.resultDate[i].status;
                  let date1=new Date();
                  let date2=new Date();
                  if(state==2){
                    date1=new Date(times.replace(/-/g,"/"));  //订单创建时间
                  }else{
                  
                    date1=new Date(times.replace(/-/g,"/"));  //订单创建时间
                    date2=new Date(auditimes.replace(/-/g,"/"));  //人工复审时间
                  }
                 var date3=date2.getTime()-date1.getTime() 
                      let days=Math.floor(date3/(24*3600*1000))
                      //计算出小时数
                      var leave1=date3%(24*3600*1000)    //计算天数后剩余的毫秒数
                      let hours=Math.floor(leave1/(3600*1000))
                      //计算相差分钟数
                      var leave2=leave1%(3600*1000)        //计算小时数后剩余的毫秒数
                      let minutes=Math.floor(leave2/(60*1000))
                      if(days==0&&hours<4){
                            // return "<font color='black'>"+hours+"h"+minutes+"m</font>"
                              date3=hours+"h"+minutes+"m"
                      }else if(days==0&&hours>=4&&hours<8){
                            // return "<font color='purple'>"+hours+"h"+minutes+"m</font>"
                              date3=hours+"h"+minutes+"m"
                        }else if(days==0&&hours>=8){
                            // return "<font color='red'>"+hours+"h"+minutes+"m</font>"
                              date3=hours+"h"+minutes+"m"
                        }else{
                            // return "<font color='red'>"+days+"d"+hours+"h"+minutes+"m</font>"
                              date3=+days+"d"+hours+"h"+minutes+"m";
                        }
                        let status = res.data.data.resultDate[i].status
                        let statusDescription = ''
                        if(status == 2){
                          statusDescription = 'Pending Manual Review'
                          this.setState({
                            reviewbutton: {display: "block"},
                            detailsbutton: {display: "block"},
                          })
                        }else if(status == 32){
                          statusDescription = 'Reject by Manual Review'
                          this.setState({
                            reviewbutton: {display: "none"},
                            detailsbutton: {display: "block"},
                          })
                        }else if(status == 33){
                          statusDescription = 'Return by Manual Review'
                          this.setState({
                            reviewbutton: {display: "none"},
                            detailsbutton: {display: "block"},
                          })
                        }else if(status == 1){
                          statusDescription = 'Pending Auto Review'
                          this.setState({
                            reviewbutton: {display: "none"},
                            detailsbutton: {display: "block"},
                          })
                        }
                    data.push({
                      key: i,
                      type:res.data.data.resultDate[i].appCode,
                      number: res.data.data.resultDate[i].indentNo,
                      name:res.data.data.resultDate[i].firstName,
                      phone: res.data.data.resultDate[i].mobile,
                      bvn: res.data.data.resultDate[i].dentityCard,
                      amount: res.data.data.resultDate[i].balance/100,
                      days: res.data.data.resultDate[i].cycle,
                      time: res.data.data.resultDate[i].createdTime,
                      times:res.data.data.resultDate[i].repaymentCount,
                      state:statusDescription,
                      risk:res.data.data.resultDate[i].auditRiskStatus,
                      people:res.data.data.resultDate[i].reviewers,
                      when:res.data.data.resultDate[i].auditUserTime,
                      detail:res.data.data.resultDate[i].auditDetail,
                      reson:res.data.data.resultDate[i].auditUserReson,
                      sure:date3,
                      id:res.data.data.resultDate[i].id,
                      uid:res.data.data.resultDate[i].uid,
                    });
                  
                  } 
              }
            
                this.setState({
                  tableData:data,
                  length:res.data.data.page.total,
                  boolean:false
                })
                // message.success(res.data.msg);
            }else if(res.data.code ==800){
              this.props.history.push({ pathname: "/"});
            }else{
              // message.error(res.data.msg);
            }
          })
          }
          selectScd=(e)=>{
            let value = e.target.value;
            this.setState({
              returnSelect:value
            })
          }
    render() {

      const columns = [
        {
          title: 'app type',
          dataIndex: 'type',
          key: 'type',
    
        },
        {
          title: 'Order No',
          dataIndex: 'number',
          key: 'number',
          width:50
        },
        {
          title: 'Customer name',
          dataIndex: 'name',
          key: 'name',
  
        },
        {
          title: 'mobile phone',
          dataIndex: 'phone',
          key: 'phone',
     
        },
        {
          title: 'BVN',
          dataIndex: 'bvn',
          key: 'bvn',
     
        },
        {
          title: 'loan amount',
          dataIndex: 'amount',
          key: 'amount',
  
        },
        {
          title: 'loan term',
          dataIndex: 'days',
          key: 'days',

        },
        {
          title: 'order created time',
          dataIndex: 'time',
          key: 'time',

        },
        {
          title: 'Success Repayment Count',
          dataIndex: 'times',
          key: 'times',

        },
        {
          title: 'Loan status',
          dataIndex: 'state',
          key: 'state',
 
        },
        {
          title: 'Risk Rules Result',
          dataIndex: 'risk',
          key: 'risk',
          render:(text,record) =>  this.changeRisk(record)
 
        },
        {
          title: 'Reviewer',
          dataIndex: 'people',
          key: 'people',

        },
        {
          title: 'Review time',
          dataIndex: 'when',
          key: 'when',

        },
        {
          title: 'Review details',
          dataIndex: 'detail',
          key: 'detail',
          render: (record) => {
            return (
              <Tooltip title={record}>
                <div className="twoEllipsis">
                  {record}
                </div>
              </Tooltip>
            )
        }
        },
        {
          title: 'Disapproval reason',
          dataIndex: 'reson',
          key: 'reson',
          render: (record) => {
            return (
              <Tooltip title={record}>
                <div className="twoEllipsis">
                  {record}
                </div>
              </Tooltip>
            )
        }

        },
        {
          title: 'Pending time',
          dataIndex: 'sure',
          key: 'sure',
          render:(text,record) =>  this.changeTime(record)
    
        },
        {
          title: 'Operation',
          dataIndex: 'operating',
          key: 'operating',
          width:70,
          id:'id',
          uid:'uid',
          fixed:'right',
          render: (text, record, index) => (<span style={{ display: 'flex' , flexDirection: 'row'}}> 
          <Button type="primary"  className="reviewbutton common_color" style={this.state.reviewbutton} size="small" onClick={() => this.showFstModal(record)}>Review</Button>
          <Button type="primary"   style={this.state.detailsbutton} className="detailsbutton common_color" size="small"  onClick={()=>this.text(record)}>
               details
            </Button>
            </span>)
        },
      ];
      return (
        <div className="credit_order">
          <div className="myorder_content">



  <div className="flutter">
            <div className="top">
              <div className="top_left" style={{width:'30%',flexShrink:1}}>
                <div className="search">
                  <span className="description left">Order No:</span>
                  <Search
                    placeholder="Order No"
                    onSearch={value => console.log(value)}
                    style={{width: 290}}
                    value={this.state.searchVal}
                    onChange={this.searchIpt.bind(this)}
                  />
                 </div>
              </div>

              <div className="top_mid" style={{width:'30%',flexShrink:2,marginLeft:'5%',marginRight:'5%'}}>
                <div className="rangepick">
                    <span className="description mid_span">Created time:</span>
                    <RangePicker
                       style={{width: 290,}}
                      ranges={{
                      Today: [moment(), moment()],
                      'This Month': [moment().startOf('month'), moment().endOf('month')],
                      }}
                      showTime
                      format={dateFormat}
                      onChange={this.rangeOnChange}
                      value={this.state.from===undefined||this.state.to===undefined||this.state.from===""||this.state.to===""?null:[moment(this.state.from, dateFormat), moment(this.state.to, dateFormat)]}
                    />
                </div>
              </div>
              <div className="top_right" style={{width:'30%',flexShrink:1}}>
                <span className="description right">App type:</span>
                <select className="form_select"  value={this.state.fouSelect} onChange={this.selectFou.bind(this)}>
                 {Select}
                </select>
              </div>
            </div>
      
            <div className="order_mid">
              <div className="mid_left" style={{width:'30%'}}>
                <span className="description left">Loan status:</span>
                <select className="form_select" value={this.state.fstSelect} onChange={this.selectFst.bind(this)}>
                  <option value="2,32,33">Loan status</option>
                  <option value="2">Pending Manual Review</option>
                  <option value="32">Reject by Manual Review</option>
                  <option value="33">Return by Manual Review</option>
                  <option value="1">Pending Auto Review</option>
                </select>
              </div>


              <div className="top_mid" style={{width:'30%',marginLeft: "5%",marginRight: "5%"}} >
                  <span className="description mid_span">Return reason:</span>
                  <select className="form_select" value={this.state.returnSelect} onChange={this.selectScd.bind(this)}>
                    {credit}
                  </select>
              </div>
              <div className="mid_right" style={{width:'30%'}}>
                  <span className="description right">Order sort:</span>
                  <select className="form_select" value={this.state.thdSelect} onChange={this.selectThd.bind(this)}>
                    <option value="1">Order by pending time ↑</option>
                    <option value="2" selected = "selected">Order by pending time ↓</option>
                  </select>
              </div>
             
            </div>    
            <div className="mid" style={{justifyContent: 'start'}}>
              <div className="mid_left" style={{width:'30%'}}>
              <span className="description left">Phone:</span>
                  <Search
                    placeholder="Phone"
                    onSearch={value => console.log(value)}
                    style={{width: 290}}
                    value={this.state.mobileNum}
                    onChange={this.searchIpt1.bind(this)}
                  />
              </div>
            </div>
            <div className="bottom">
              <Button type="primary" style={{width:170,marginRight:20,height:40,border:'none'}}  className="common_color" onClick={this.searchClick.bind(this)}>Search</Button>
              <Button type="primary" style={{width:170,height:40,border:'none'}}className="common_color_scd" onClick={this.reloadClick.bind(this)}>Refresh</Button>
            </div>      
          </div>
          </div>
          <Table columns={columns} dataSource={this.state.tableData} loading={this.state.boolean} onChange={this.handleChange} pagination={false} />
            
          <Modal
          title="Review"
          width={700}
          onCancel={this.handleCancel}
          visible={this.state.fstvisible}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              cancel
            </Button>,
             <Button key="submit" type="primary" loading={this.state.modify} onClick={this.fsthandleOk}>
             ok
           </Button>,
          ]}
         
        >
        <input type="text" value={this.state.fstinpt} style={{display:"none"}} />
        <span style={{fontSize:15,fontWeight:'bold'}}>Review status</span>
        <select className="model_fst" id="modal_fstid1"  value={this.state.modalfstSelect1} onChange={this.gradeChange}>
            <option value="0">Please select one</option>
            <option value="1">Approved</option>
            <option value="2">Return——Incomplete information</option>
            <option value="3">Reject——Reapply 30 days</option>
            <option value="4">Reject——Reapply immediately</option>
            {/* <option value="5">Reject——Move to blacklist</option> */}
          </select>
          <br/>
        <span style={{fontSize:15,fontWeight:'bold'}}>Loan status</span>
        <input className="order_model_scd" disabled='disabled' value={this.state.order_statues}/>
          <br/>
          <span style={this.state.inptdis}>Reason for rejection</span>
        <select style={this.state.inptdis} className="model_fst" value={this.state.modalscdSelect} onChange={this.modelselectTwo.bind(this)}>
           {whitchshow}
        </select>
        <br/>
        <div className="model_last">
          <label style={{fontSize:15,fontWeight:'bold'}}>Review details</label>
          <textarea style={{width:'68%',marginLeft:'3%',height:80}} value={this.state.fsttextarea} onChange={this.fsttxarea}/>
        </div>
        </Modal>
        <Pagination
          // showQuickJumper
          defaultCurrent={1} 
          onShowSizeChange={this.onShowSizeChange} //pagesize改变时回调函数
          pageSizeOptions={["10","50","100"]}   //设置pagesize区间
          current={this.state.current}
          total={this.state.length} 
          onChange={this.pageChange} 
          showTotal={showTotal}
          style={{textAlign:'right',marginTop:20}}
          pageSize={this.state.pageSize}
          showSizeChanger={true}
          />
        </div>
      );
    }
  }
  export default withRouter(MyOrder)