import request from './request'
export function getTotalTelemarketData(data) { //total_telemarket 表格数据
    return request({
      url: '/promotion/telemarketingList',
      method: 'post',
      data
    })
}
export function getTotalTelemarketSelect(data) { //total_telemarket 下拉框
    return request({
      url: '/promotion/getTelemarketingUsers',
      method: 'post',
      data
    })
}
export function TotalTelemarketFormInput(data) { //total_telemarket 下拉框
    return request({
      url: '/promotion/manualAllot',
      method: 'post',
      data
    })
}

export function getMytelemarketData(data) { //my_telemarket 表格数据
    return request({
      url: '/promotion/commissionerTelemarketingList',
      method: 'post',
      data
    })
}
export function getMytelemarketTotal(data) { //my_telemarket 统计数据
    return request({
      url: '/promotion/individualStatistics',
      method: 'post',
      data
    })
}
export function MytelemarketFormInput(data) { //my_telemarket 提交表单
    return request({
      url: '/promotion/updateTelemarketingOrder',
      method: 'post',
      data
    })
}
export function getTelemarketData(data) { //telemarket_data 获取表格数据
    return request({
      url: '/promotion/statisticsByPeriod',
      method: 'post',
      data
    })
}
export function getTelemarketDataTotal(data) { //my_telemarket 统计数据
    return request({
      url: '/promotion/totalStatisticsByPeriod',
      method: 'post',
      data
    })
}
export function getTelemarketMonthData(data) { //my_telemarket 月表格数据
    return request({
      url: '/promotion/statisticsByEveryMonth',
      method: 'post',
      data
    })
}

export function getTelemarketDayData(data) { //my_telemarket 日表格数据
    return request({
      url: '/promotion/statisticsByEveryDay',
      method: 'post',
      data
    })
}
export function getTodayTelemarketData(data) { //today_telemarket 表格数据
    return request({
      url: '/promotion/everydayStatisticsByPeriod',
      method: 'post',
      data
    })
}


