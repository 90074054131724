/*eslint-disable*/
import React from 'react';
import { Row, Col, Tag,Modal,Input,Button,message   } from 'antd';
import {Link, withRouter} from 'react-router-dom';
import EventEmitter from './../../utils/events'
import './index.css';
import fstimg from '../../../public/img/person.png';
import scdimg from '../../../public/img/change.png';
import thdimg from '../../../public/img/exit.png';
import axios from 'axios'

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: 'Lebron James',
      tagArr: [] // 存放tag标签的数组
    }
  }
  componentDidMount(){
    let _this = this
    this.setState({
      userName:localStorage.loginUser
    })
    EventEmitter.on('tabList',function(data){
      let tagArr = _this.state.tagArr
      tagArr.push(data)
      let uniqueArr = _this.unique(tagArr,'eventKey')  //对数组去重
      console.log('uniqueArr',uniqueArr)
      _this.setState({
        tagArr: uniqueArr
      })
    })
  }
  logout = () => {
    localStorage.removeItem("loginUser");
    localStorage.removeItem("authToken");
    localStorage.removeItem("key");
    axios.post(`/api/system/loginout`, {}).then((res) => {
      if (res.data.code === 200) {
        this.props.history.push({ pathname: "/" });
      } else if (res.data.code == 800) {
        this.props.history.push({ pathname: "/" });
      }
    });
  };
  //去重方法
  unique = (arr, idName) => {
    let obj = {};   //  维护不重复id
    let result = [];  //  最终数组
    arr.forEach((ele, index) => {
      if (!obj[ele[idName]]) {
        result.push(ele);
        obj[ele[idName]] = true;
      }
    })
    return result
  }

  renderTag =(data)=> {
    // console.log("d222222222ata",data)
    return  data && data.map(item=>{
      return <Tag closable onClose={() => this.handleClose(item.eventKey)} key={item.eventKey}>
         <Link to={item.eventKey}>{item.title}</Link>
         </Tag>
      
    })
  }
  handleClose = (removedTag) => {
    console.log('removedTag',removedTag)
    const tagArr = this.state.tagArr.filter(tag => tag.eventKey !== removedTag);
    console.log('tagArr',tagArr[tagArr.length-1].eventKey);
    this.props.history.push(tagArr[tagArr.length-1].eventKey)
    let uniqueArr = this.unique(tagArr,'eventKey')  //对数组去重
    console.log('uniqueArr',uniqueArr)
    this.setState({
      tagArr: uniqueArr
    })
  };
   //获取老户名
   oldOnchange=(e)=>{
    let value = e.target.value;
    this.setState({
      oldpassword : value
    })
  }
  //获取新密码
  passOnchange=(e)=>{
    let value = e.target.value;
    this.setState({
      password : value
  });
  }
  //获取新确认密码
  newpassOnchange=(e)=>{
    let value = e.target.value;
    this.setState({
      newpassword : value
  });
  }
  change=()=>{
    this.setState({
      fstvisible:true
    })
  }
  handleCancel=()=>{
    this.setState({
      fstvisible:false,
      oldpassword:'',
      password:'',
      newpassword:'',
    })
  }
  fsthandleOk=()=>{
    if(this.state.oldpassword==this.state.password){
      alert('The new password cannot be the same as the old password')
      return false
    }
    if(this.state.password!=this.state.newpassword){
      alert('Two password entries are inconsistent')
      return false
    }
    axios
      .post(`/api/system/resetPassword`,{
        oldPassword: this.state.oldpassword,
        newPassword: this.state.password,
      })
      .then(res=>{
        if(res.data.code === 200){
          message.success(res.data.msg);
          this.logout()
        }
        else{
          message.error(res.data.msg);
        }
        this.setState({
          fstvisible:false,
          oldpassword:'',
          password:'',
          newpassword:'',
        })
      })
  }
  render() {
    return (
      <div className="header" >
        <Row className="header-top">
          <Col span={24} >
            <div className='header_div'>
              <div className='header_fst'>
                <img src={fstimg}/>
                <span className="quit" >Personal Data</span>
              </div>
              <div className='header_scd'>
                <img src={scdimg}/>
                <span className="quit" onClick={this.change}>Change Password</span>
              </div>
              <div className='header_thd'>
                <img src={thdimg}/>
                <span className="quit" onClick={this.logout}>log out</span>
              </div>
            </div>
            
          </Col>
        </Row>
                <Modal
                  title="change password"
                  visible={this.state.fstvisible}
                  onOk={this.fsthandleOk}
                  onCancel={this.handleCancel}
                >
                <input type="text" value={this.state.fstinpt} style={{display:"none"}} />
                <input type="text" value={this.state.scdinpt} style={{display:"none"}} />
                <Input placeholder="old password"  value={this.state.oldpassword} type="password"  onChange={this.oldOnchange}/><br/>  
                <Input placeholder="new password" style={{marginTop:35}} type="password" value={this.state.password}  onChange={this.passOnchange}/><br/> 
                <Input placeholder="confirm password" style={{marginTop:35}} type="password" value={this.state.newpassword} onChange={this.newpassOnchange}/><br/>               
                </Modal>
      </div>
    )
  }
}
export default withRouter(Header);