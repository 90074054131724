/*eslint-disable*/
import React from 'react';
import {Button, DatePicker, Icon, Input, message, Modal, Pagination, Table, Tooltip, Upload} from 'antd';
import './index.css';
import axios from 'axios'
import {withRouter} from 'react-router-dom'
import EventEmitter from '../../utils/events'
import moment from 'moment';
import $ from 'jquery';

const {RangePicker} = DatePicker;
const {Search} = Input;
const dateFormat = 'YYYY-MM-DD' || undefined;
var whitchshow = []

function showTotal(total) {
    return `Total ${total} items`;
}

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
}

function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");
    }
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
        message.error("Image must smaller than 1MB!");
    }
    return isJpgOrPng && isLt1M;
}

class Refund extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fstSelect: '',
            searchVal: '',
            current: 1,
            fileList: [],
            pageSize: 10,
            boolean: false,
            modify: false,
            scdafter: false,
            fstafter: false,
            isdisabled: false,
            creditInput: '',
            modalinput: '',
            hide: true,
            showprocess: false,
            uploadButton: false,
            modalscdSelect: '',
            evidence:''
        }
    }

    onShowSizeChange = (current, pageSize) => {

        this.setState({
            pageSize: pageSize
        }, () => {
            this.searchClick()
        })
    }
    modaltextarea = (e) => {
        var value = e.target.value;
        this.setState({modal_textarea: value})
    }
    //页码改变时触发
    pageChange = (page) => {
        this.setState({
            current: page
        }, () => {
            this.searchClick()
        })
    }
    rangeOnChange = (dates, dateStrings) => {
        this.setState({from: dateStrings[0], to: dateStrings[1]})
    }
    rangeOnChange1 = (dates, dateStrings) => {
        this.setState({operate: dateStrings[0], operateto: dateStrings[1]})
    }


    //重置按钮
    reloadClick(e) {
        this.setState({
            searchVal: '',
            fstInput: '',
            scdInput: '',
            from: undefined,
            to: undefined,
            operate: undefined,
            operateto: undefined,
            boolean: false,
            fstSelect: '',
            thdInput: '',
            creditInput: '',
            operator: '',
            targetNo: ''
        });

    }

    whitch_selectshow = (e) => {
        var value = e.target.value;
        var select = document.getElementById("s1");
        var options = select.options;
        var index = select.selectedIndex
        var selectedText = select.options[index].text
        console.log(selectedText)

        var collectionTemplates = $("#s1").find("option:selected").text();

        this.setState({
            modal_textarea: collectionTemplates,
            modalscdSelect: value
        })

    }

    componentDidMount() {
        this.searchClick()
    }

    //输入框
    searchIpt = (e) => {
        var value = e.target.value;
        this.setState({searchVal: value.replace(/\s+/g, "")})
    }
    selectfst = (e) => {
        var value = e.target.value;
        this.setState({
            fstSelect: value,
        });
    }
    ModalTarget = (e) => {
        var value = e.target.value;
        this.setState({
            modaltarget: value,
        });
    }
    uploadhandleOk = () => {
        if (this.state.fileList == '') {
            alert("Upload content cannot be empty")
            return false
        }
        this.setState({
            scdafter: true
        })

        const {fileList} = this.state;
        const formData = new FormData();
        formData.append('file', fileList[0]);


        console.log('fileList', fileList)
        axios({
            url: '/api/refund/uploadExcel',
            method: 'post',
            data: formData
        })
            .then(res => {
                if (res.data.code === 200) {
                    message.success(res.data.msg)
                    this.searchClick()
                    this.setState({
                        uploadvisible: false,
                        fileList: '',
                        scdafter: false,
                        isdisabled: false
                    })
                } else if (res.data.code == 800) {
                    this.props.history.push({pathname: "/"});
                } else {
                    message.error(res.data.msg)
                    this.setState({
                        uploadvisible: false,
                        scdafter: false,
                        fileList: '',
                        isdisabled: false
                    })
                }
            })
    }

    handleData(time) {
        if (!time) {
            return false
        } else {
            // 大于当前日期不能选 time > moment()
            // 小于当前日期不能选 time < moment().subtract(1, "days")
            // 只能选前7后7 time < moment().subtract(7, "days") || time > moment().add(7, 'd')
            return time > moment()
        }
    }

    todetail = (record) => {
        window.location = '/#/details/index?id=' + record.id + '&uid=' + record.uid
        let test = [{
            path: '/details/index',
            key: `/details/index/${record.id}/${record.uid}`,
            number: `${record.id}/${record.uid}`
        }]
        EventEmitter.trigger('btn', test[0])
        EventEmitter.trigger('idanduid', test[0].number)
    }
    creditChange = (e) => {
        let value = e.target.value;
        this.setState({
            creditInput: value.replace(/\s+/g, ""),
        });
    }
    target = (e) => {
        let value = e.target.value;
        this.setState({
            targetNo: value.replace(/\s+/g, ""),
        });
    }
    ModalScdInput = (e) => {
        let value = e.target.value;
        this.setState({
            modalscdinput: value.replace(/\s+/g, ""),
        });
    }
    search = () => {
        axios
            .post(`/api/refund/findTargetNo`, {
                repayFlowNo: this.state.modalscdinput,
            })
            .then(res => {
                if (res.data.code === 200) {

                    message.success(res.data.msg);
                    this.setState({
                        modaltarget: res.data.data.amount / 100,
                        targetAmount: res.data.data.amount / 100
                    })

                } else if (res.data.code == 800) {
                    this.props.history.push({pathname: "/"});
                } else {
                    message.error(res.data.msg);
                    this.setState({})
                }
            })
    }
    ModalThdInput = (e) => {
        var value = e.target.value;
        this.setState({
            modalthdinput: value,
        })
    }
    ModalInput = (e) => {

        var value = e.target.value;
        if (value == 2) {
            this.setState({
                hide: false
            })
        } else {
            this.setState({
                hide: true
            })
        }
        this.setState({
            modalinput: value,
        }, () => {
            axios
                .post(`/api/refund/getRemarkList`, {
                    status: this.state.modalinput
                })
                .then(res => {
                    whitchshow = []
                    if (res.data.code === 200) {
                        for (let i = 0; i < res.data.data.length; i++) {
                            if (i == 0) {
                                whitchshow.push(<option value=''>Please select one</option>)
                            }
                            whitchshow.push(
                                <option value={res.data.data[i].number}>{res.data.data[i].name}</option>
                            )
                            this.setState({
                                whitchshow: whitchshow
                            })
                        }
                    } else if (res.data.code == 800) {
                        this.props.history.push({pathname: "/"});
                    } else {
                    }
                })

        });
    }

    Operate = (e) => {
        let value = e.target.value;
        this.setState({
            operator: value.replace(/\s+/g, ""),
        });
    }
    searchClick = () => {
        this.setState({
            boolean: true
        })
        axios
            .post(`/api/refund/findList`, {

                pageSize: this.state.pageSize,
                current: this.state.current,
                refundNo: this.state.searchVal,
                customerName: this.state.fstInput,
                mobile: this.state.scdInput,
                loanRecordNo: this.state.thdInput,
                repayFlowNo: this.state.targetNo,
                status: this.state.fstSelect,
                beginTime: this.state.from,
                endTime: this.state.to,
                operateBeginTime: this.state.operate,
                operateEndTime: this.state.operateto,
                operator: this.state.operator,
                applyName: this.state.creditInput,
                // button:'refund_process,upload_button'
            })
            .then(res => {
                //     if(res.data.data.permission.refund_processButton==true){
                //       this.setState({
                //         showprocess:true
                //       })
                //   } if(res.data.data.permission.upload_buttonButton==true){
                //     this.setState({
                //       uploadButton:true
                //     })
                // }
                if (res.data.code === 200) {
                    const data1 = [];
                    const data = res.data.data.resultData
                    if (data.length < 1) {
                        this.setState({
                            boolean: false
                        })
                    } else {
                        for (let i = 0; i < data.length; i++) {
                            data[i].key = i;
                            data1.push(data[i]);
                        }
                    }
                    this.setState({
                        tableData: data1,
                        total: res.data.data.page.total,
                        boolean: false
                    })
                } else if (res.data.code == 800) {
                    this.props.history.push({pathname: "/"});
                } else {
                    // message.error(res.data.msg);
                }
            })
    }

    fstInputChange = (e) => {
        let value = e.target.value;
        this.setState({
            fstInput: value,
        });
    }
    scdInputChange = (e) => {
        let value = e.target.value;
        this.setState({
            scdInput: value.replace(/\s+/g, ""),
        });
    }
    thdInputChange = (e) => {
        let value = e.target.value;
        this.setState({
            thdInput: value.replace(/\s+/g, ""),
        });
    }
    //offine按钮
    modify = (record) => {
        this.setState({
            processvisible: true,
            modifyId: record.id,
            modalthdinput: record.apRefundAmount / 100,
            modalscdinput: record.repayFlowNo
        })
    }

    changeupload = () => {
        this.setState({
            uploadvisible: true
        })
    }

    processReturn = (msg) => {
        alert(msg);
        this.setState({
            fstafter: false
        })
        return false
    }

    handleImageChange = (info) => {
        if (info.file.status === "uploading") {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === "done") {
            getBase64(info.file.originFileObj, (imgUrl) => {
                let temp = this.state
                temp.evidence = info.file.response.data
                this.setState({
                    data: temp,
                    loading: false,
                })
            });
        }
    };

    process = (record) => {
        this.setState({
            fstafter: true
        })
        if (this.state.modalinput == '') {
            return this.processReturn('select cannot be empty')
        }
        if (this.state.modalscdSelect == '') {
            return this.processReturn('please select one')
        }
        if (this.state.modalinput == 1) {
            if (this.state.modalthdinput == '') {
                return this.processReturn('Refund Amount cannot be empty')
            } else if (this.state.modalscdinput == '') {
                return this.processReturn('Target No cannot be empty')
            } else if (this.state.modaltarget == '') {
                return this.processReturn('Target Amount cannot be empty')
            } else if (this.state.modal_textarea == '') {
                return this.processReturn('Remark cannot be empty')
            }
            if (this.state.modalthdinput > this.state.targetAmount) {
                return this.processReturn('The refund amount should not be greater than the target repayment amount')
            }
        } else if (this.state.modalinput == 2) {
            if (this.state.modal_textarea == '') {
                return this.processReturn('Remark cannot be empty')
            }
        }
        axios
            .post(`/api/refund/refundProcess `, {
                id: this.state.modifyId,
                status: this.state.modalinput,
                refundAmount: this.state.modalthdinput,
                repayFlowNo: this.state.modalscdinput,
                targetAmount: this.state.modaltarget,
                remark: this.state.modal_textarea,
                evidence:this.state.evidence
            })
            .then(res => {
                if (res.data.code === 200) {
                    message.success(res.data.msg);
                    this.setState({
                        scdafter: false,
                        processvisible: false,
                        modalscdinput: '',
                        modaltarget: '',
                        modalinput: '',
                        modal_textarea: '',
                        modalscdSelect: '',
                        hide: true,
                        evidence:''
                    })
                    this.searchClick()
                } else if (res.data.code == 800) {
                    this.props.history.push({pathname: "/"});
                } else {
                    message.error(res.data.msg);
                    this.setState({
                        scdafter: false,
                        processvisible: false,
                        modalscdinput: '',
                        modaltarget: '',
                        modalinput: '',
                        modal_textarea: '',
                        modalscdSelect: '',
                        hide: true,
                        evidence:''
                    })
                    this.searchClick()
                }
            })

    }
    handleCancel = () => {
        this.setState({
            modalinput: '',
            uploadvisible: false,
            processvisible: false,
            scdafter: false,
            fstafter: false,
            isdisabled: false,
            fileList: '',
            modal_textarea: '',
            modalscdinput: '',
            modaltarget: '',
            hide: true,
            modalscdSelect: '',
            evidence:''
        });
    }

    render() {

        const columns = [
            {
                title: 'Upload time',
                dataIndex: 'upload_time',
                key: 'upload_time',
                render: (value) => {
                    let timeaudit = new Date(value).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
                    let auditimes = moment(new Date(timeaudit)).format("YYYY-MM-DD");
                    if (value == null) {
                        return auditimes = ''
                    } else {
                        return auditimes = auditimes
                    }
                }
            },
            {
                title: 'Timestamp',
                dataIndex: 'applyTime',
                key: 'applyTime',
                render: (value) => {
                    let timeaudit = new Date(value).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
                    let auditimes = moment(new Date(timeaudit)).format("YYYY-MM-DD");
                    if (value == null) {
                        return auditimes = ''
                    } else {
                        return auditimes = auditimes
                    }
                }
            },
            {
                title: 'Order number',
                dataIndex: 'loanRecordNo',
                key: 'loanRecordNo',

            },
            {
                title: 'Check No',
                dataIndex: 'checkNo',
                key: 'checkNo',
            },
            {
                title: 'Customer name',
                dataIndex: 'customerName',
                key: 'customerName',

            },
            {
                title: 'Phone',
                dataIndex: 'mobile',
                key: 'mobile',

            },
            {
                title: 'Date of auto',
                dataIndex: 'autoDebitTime',
                key: 'autoDebitTime',
                render: (value) => {
                    let timeaudit = new Date(value).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
                    let auditimes = moment(new Date(timeaudit)).format("YYYY-MM-DD");
                    if (value == null) {
                        return auditimes = ''
                    } else {
                        return auditimes = auditimes
                    }
                }
            },
            {
                title: 'Amount of auto debit',
                dataIndex: 'autoDebitAmount',
                key: 'autoDebitAmount',
                render: (value) => {
                    if (value) {
                        var num = value / 100;
                        num = num + '';
                        if (num.indexOf(".") != -1) {
                            return num;
                        } else {
                            var str = "";
                            for (var i = num.length - 1, j = 1; i >= 0; i--, j++) {
                                if (j % 3 == 0 && i != 0) {//每隔三位加逗号，过滤正好在第一个数字的情况
                                    str += num[i] + ",";//加千分位逗号
                                    continue;
                                }
                                str += num[i];//倒着累加数字
                            }
                            return str.split('').reverse().join("");
                        }

                    } else {
                        return ''
                    }
                }
            },
            {
                title: 'Date of offline',
                dataIndex: 'offlineTime',
                key: 'offlineTime',
                render: (value) => {
                    let timeaudit = new Date(value).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
                    let auditimes = moment(new Date(timeaudit)).format("YYYY-MM-DD");
                    if (value == null) {
                        return auditimes = ''
                    } else {
                        return auditimes = auditimes
                    }
                }
            },
            {
                title: 'Amount of offline',
                dataIndex: 'offlineAmount',
                key: 'offlineAmount',
                render: (value) => {
                    if (value) {
                        var num = value / 100;
                        num = num + '';
                        if (num.indexOf(".") != -1) {
                            return num;
                        } else {
                            var str = "";
                            for (var i = num.length - 1, j = 1; i >= 0; i--, j++) {
                                if (j % 3 == 0 && i != 0) {//每隔三位加逗号，过滤正好在第一个数字的情况
                                    str += num[i] + ",";//加千分位逗号
                                    continue;
                                }
                                str += num[i];//倒着累加数字
                            }
                            return str.split('').reverse().join("");
                        }
                    } else {
                        return ''
                    }

                }

            },
            {
                title: 'Line number',
                dataIndex: 'lineNumber',
                key: 'lineNumber',
            },

            {
                title: 'Ap-refund amount',
                dataIndex: 'apRefundAmount',
                key: 'apRefundAmount',
                render: (value) => {
                    if (value) {
                        var num = value / 100;
                        num = num + '';
                        if (num.indexOf(".") != -1) {
                            return num;
                        } else {
                            var str = "";
                            for (var i = num.length - 1, j = 1; i >= 0; i--, j++) {
                                if (j % 3 == 0 && i != 0) {//每隔三位加逗号，过滤正好在第一个数字的情况
                                    str += num[i] + ",";//加千分位逗号
                                    continue;
                                }
                                str += num[i];//倒着累加数字
                            }
                            return str.split('').reverse().join("");
                        }
                    } else {
                        return ''
                    }

                }
            },
            {
                title: 'Apply name',
                dataIndex: 'collector',
                key: 'collector',
            },
            {
                title: 'Comment',
                dataIndex: 'comment',
                key: 'comment',
                render: (record) => {
                    return (
                        <Tooltip title={record}>
                            <div className="twoEllipsis">
                                {record}
                            </div>
                        </Tooltip>
                    )
                }
            },
            {
                title: 'State',
                dataIndex: 'status',
                key: 'status',
                render: (value) => {
                    if (value == 0) {
                        return 'Unprocess'
                    } else if (value == 1) {
                        return 'Had Refund'
                    } else if (value == 2) {
                        return 'Had Reject'
                    }else if (value == 3){
                        return 'Refund failed'
                    }else if (value == 4){
                        return 'Refund exception'
                    }
                }
            },
            {
                title: 'Remark',
                dataIndex: 'remark',
                key: 'remark',
                width: 97,
                render: (record) => {
                    return (
                        <Tooltip title={record}>
                            <div className="twoEllipsis">
                                {record}
                            </div>
                        </Tooltip>
                    )
                }
            },
            {
                title: 'Refund No',
                dataIndex: 'refundRecordNo',
                key: 'refundRecordNo',
            },
            {
                title: 'Target No',
                dataIndex: 'repayFlowNo',
                key: 'repayFlowNo',
            },
            {
                title: 'Refund amount',
                dataIndex: 'refundAmount',
                key: 'refundAmount',
                render: (value) => {
                    if (value) {
                        var num = value / 100;
                        num = num + '';
                        if (num.indexOf(".") != -1) {
                            return num;
                        } else {
                            var str = "";
                            for (var i = num.length - 1, j = 1; i >= 0; i--, j++) {
                                if (j % 3 == 0 && i != 0) {//每隔三位加逗号，过滤正好在第一个数字的情况
                                    str += num[i] + ",";//加千分位逗号
                                    continue;
                                }
                                str += num[i];//倒着累加数字
                            }
                            return str.split('').reverse().join("");
                        }
                    } else {
                        return ''
                    }


                }
            },
            {
                title: 'Operator',
                dataIndex: 'operator',
                key: 'operator',
            },
            {
                title: 'Operate time',
                dataIndex: 'operateTime',
                key: 'operateTime',
                render: (value) => {
                    let timeaudit = new Date(value).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
                    let auditimes = moment(new Date(timeaudit)).format("YYYY-MM-DD");
                    if (value == null) {
                        return auditimes = ''
                    } else {
                        return auditimes = auditimes
                    }
                }
            },
            {
                title: 'Operation',
                dataIndex: 'operating',
                key: 'operating',
                width: 70,
                id: 'id',
                uid: 'uid',
                fixed: 'right',
                render: (text, record, index) => (<span style={{display: 'flex', flexDirection: 'row'}}>
            {/* {this.state.showprocess&& */}
                <Button type="primary" className="detailsbutton common_color" size="small" onClick={() => this.modify(record)} style={{display: (record.status == 0||record.status == 3||record.status == 4) ? "block" : "none"}}>
                {record.status == 0 ? "Process":"Re-process"}
                </Button>
            {/* } */}
                    <Button type="primary" className="detailsbutton common_color" size="small"
                            onClick={() => this.todetail(record)}>
               details
            </Button>
            </span>)
            },
        ];
        const {uploading, fileList} = this.state;
        const props = {
            onRemove: (file) => {
                this.setState((state) => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                    };
                });
            },
            beforeUpload: (file) => {
                this.setState(state => ({
                    fileList: [...state.fileList, file],
                    isdisabled: true
                }));

                return false;
            },
            fileList,
        };

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };

        const uploadButton = (
            <div style={formItemLayout}>
                <Icon type={this.state.loading ? "loading" : "plus"} />
                <div className="ant-upload-text">Upload</div>
            </div>
        );
        return (
            <div className="credit_order">
                <div className="myorder_content">
                    <div className="flutter"
                         style={{backgroundColor: '#fff', padding: 20, paddingTop: 30, paddingBottom: 30}}>
                        <div className="top new_top">
                            <div className="top_left" style={{width: '23%'}}>
                                <div className="search">
                                    <input type="text" placeholder='Refund No' value={this.state.searchVal}
                                           onChange={this.searchIpt} className="modal_input"
                                           style={{width: '80%', marginLeft: 0, marginRight: 0}}/>
                                </div>
                            </div>

                            <div className="top_mid" style={{width: '23%', marginLeft: '2%', marginRight: '2%'}}>
                                <input type="text" placeholder='Customer name' value={this.state.fstInput}
                                       onChange={this.fstInputChange} className="modal_input"
                                       style={{width: '80%', marginLeft: 0, marginRight: 0}}/>
                            </div>
                            <div className="mid_left" style={{width: '23%'}}>
                                <input type="text" placeholder='Phone' value={this.state.scdInput}
                                       onChange={this.scdInputChange} className="modal_input"
                                       style={{width: '80%', marginLeft: 0}}/>
                            </div>
                            <div className="mid_left" style={{width: '23%', marginLeft: '2%'}}>
                                <input type="text" placeholder='Order No' value={this.state.thdInput}
                                       onChange={this.thdInputChange} className="modal_input"
                                       style={{width: '80%', marginLeft: 0}}/>
                            </div>


                        </div>

                        <div className="order_mid">
                            <div className="mid_left" style={{width: '23%'}}>
                                <input type="text" placeholder='Apply name' value={this.state.creditInput}
                                       onChange={this.creditChange} className="modal_input"
                                       style={{width: '80%', marginLeft: 0}}/>
                            </div>
                            <div className="mid_left" style={{width: '23%', marginLeft: '2%', marginRight: '2%'}}>
                                <input type="text" placeholder='Target No' value={this.state.targetNo}
                                       onChange={this.target} className="modal_input"
                                       style={{width: '80%', marginLeft: 0}}/>
                            </div>
                            <div className="mid_left" style={{width: '23%',}}>
                                <input type="text" placeholder='Operator' value={this.state.operator}
                                       onChange={this.Operate} className="modal_input"
                                       style={{width: '80%', marginLeft: 0}}/>
                            </div>
                            <div className="mid_right" style={{width: '25%', flexShrink: 2, marginLeft: '2%'}}>
                                <span className="description right" style={{width: 40}}>Status:</span>
                                <select className="form_select" style={{marginRight: 0, width: '60%'}}
                                        value={this.state.fstSelect} onChange={this.selectfst}>
                                    <option value="">All</option>
                                    <option value="0">Unprocess</option>
                                    <option value="1">Had Refund</option>
                                    <option value="2">Had Reject</option>
                                    <option value="3" >Refund failed</option>
                                    <option value="4" >Refund exception</option>
                                </select>
                            </div>
                        </div>

                        <div className="bottom" style={{justifyContent: 'space-between', marginTop: 20}}>
                            <div className="top_right" style={{width: '30%', marginRight: '2%'}}>
                                <div className="rangepick">
                                    <span className="description mid_span" style={{width: 105}}>Upload time:</span>
                                    <RangePicker
                                        style={{width: '70%',}}
                                        ranges={{
                                            Today: [moment(), moment()],
                                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                                        }}
                                        showTime
                                        disabledDate={this.handleData}
                                        format={dateFormat}
                                        onChange={this.rangeOnChange}
                                        value={this.state.from === undefined || this.state.to === undefined || this.state.from === "" || this.state.to === "" ? null : [moment(this.state.from, dateFormat), moment(this.state.to, dateFormat)]}
                                    />
                                </div>
                            </div>

                            <div className="top_mid" style={{width: '30%'}}>
                                <div className="rangepick">
                                    <span className="description mid_span" style={{width: 105}}>Operate time:</span>
                                    <RangePicker
                                        style={{width: 290,}}
                                        ranges={{
                                            Today: [moment(), moment()],
                                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                                        }}
                                        showTime
                                        disabledDate={this.handleData}
                                        format={dateFormat}
                                        onChange={this.rangeOnChange1}
                                        value={this.state.operate === undefined || this.state.operateto === undefined || this.state.operate === "" || this.state.operateto === "" ? null : [moment(this.state.operate, dateFormat), moment(this.state.operateto, dateFormat)]}
                                    />

                                </div>
                            </div>

                            <div className="mid_right" style={{display: 'flex', alignItems: 'flex-end', width: '30%',}}>
                                <Button type="primary" style={{width: 120, marginRight: 20, height: 40, border: 'none'}}
                                        className="common_color" onClick={this.searchClick.bind(this)}>search</Button>
                                <Button type="primary" style={{width: 120, marginRight: 20, height: 40, border: 'none'}}
                                        className="common_color_scd"
                                        onClick={this.reloadClick.bind(this)}>refresh</Button>
                                {/* {this.state.uploadButton&& */}
                                    <Button type="primary" style={{
                                    width: 120,
                                    height: 40,
                                    border: 'none'
                                }} className="common_color_thd" onClick={this.changeupload}>upload</Button>
                                {/* } */}
                            </div>
                        </div>


                    </div>

                </div>
                <div style={{backgroundColor: '#fff'}}>

                    <Table columns={columns} dataSource={this.state.tableData} scroll={{x: 1500}}
                           loading={this.state.boolean} onChange={this.handleChange} pagination={false}/>
                    <Pagination
                        showQuickJumper
                        defaultCurrent={1}
                        current={this.state.current}
                        total={this.state.total}
                        onChange={this.pageChange}
                        showTotal={showTotal}
                        style={{textAlign: 'right', marginTop: 20}}
                        pageSize={this.state.pageSize}
                        showSizeChanger={true}
                        onShowSizeChange={this.onShowSizeChange} //pagesize改变时回调函数
                        pageSizeOptions={["10", "50", "100"]}   //设置pagesize区间
                    />
                </div>

                <Modal
                    title="upload"
                    onCancel={this.handleCancel}
                    visible={this.state.uploadvisible}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            cancel
                        </Button>,
                        <Button key="submit" type="primary" loading={this.state.scdafter} onClick={this.uploadhandleOk}>
                            ok
                        </Button>,
                    ]}>
                    <Upload {...props}
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,"
                            disabled={this.state.isdisabled}>
                        <Button type="primary" style={{
                            width: 120,
                            marginRight: 20,
                            height: 40,
                            backgroundColor: '#009688',
                            border: 'none'
                        }} onClick={this.changeupload}>
                            <Icon type="upload"/> upload
                        </Button>
                    </Upload>
                </Modal>


                <Modal
                    title="Refund Process"
                    onCancel={this.handleCancel}
                    visible={this.state.processvisible}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            cancel
                        </Button>,
                        <Button key="submit" type="primary" loading={this.state.fstafter} onClick={this.process}>
                            ok
                        </Button>,
                    ]}>
                    <input value={this.state.disval} style={{display: 'none'}}/>
                    <div className="model_last">
                        <span className="long" style={{width: 140}}>Select:</span>
                        <select className="model_fst" id='modal_fstid' placeholder="Select"
                                style={{marginLeft: '2%', width: 290}} value={this.state.modalinput}
                                onChange={this.ModalInput}>
                            <option value=""></option>
                            <option value="1">Refund</option>
                            <option value="2">Reject</option>
                        </select>
                    </div>
                    {this.state.hide &&
                    <div>
                        <div className="model_last">
                            <span className="long">Refund Amount:</span>
                            <input className="modal_input" placeholder="Refund Amount"
                                   style={{marginLeft: '7%', width: 290}} value={this.state.modalthdinput}
                                   onChange={this.ModalThdInput}/>
                        </div>
                        <div className="model_last">
                            <span className="long" style={{width: 140}}>Target No:</span>
                            <Search
                                placeholder="Target No"
                                onSearch={this.search}
                                enterButton="Search"
                                size="middle"
                                style={{width: 290, marginLeft: '1.6%'}}
                                value={this.state.modalscdinput}
                                onChange={this.ModalScdInput}
                            />
                        </div>
                        <div className="model_last">
                            <span className="long">Target Amount:</span>
                            <input className="modal_input" placeholder="Target Amount" disabled
                                   style={{marginLeft: '7%', width: 290}} value={this.state.modaltarget}
                                   onChange={this.ModalTarget}/>
                        </div>
                    </div>
                    }
                    <div className="model_last">
                        <span className="long">Remark</span>
                        <select className="model_fst" id='s1' style={{marginLeft: '19%', width: 290, marginTop: 0}}
                                value={this.state.modalscdSelect} onChange={this.whitch_selectshow}>
                            {whitchshow}
                        </select>
                    </div>

                    <div>
                        <lable style={{ fontSize: 15, fontWeight: "bold" }}>
                            upload evidence:{" "}
                        </lable>
                        <div style={{width: '50%', marginLeft: '35%', height: 120}} >
                            <Upload
                                name="file"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action="/api/sysAd/upLoadFile"
                                beforeUpload={beforeUpload}
                                onChange={this.handleImageChange}
                                // disabled={!this.state.canEdit}
                            >
                                {this.state.evidence ? (
                                    <img
                                        src={this.state.evidence}
                                        alt="avatar"
                                        style={{ width: "50%" }}
                                    />
                                ) : (
                                    uploadButton
                                )}
                            </Upload>
                        </div>
                    </div>
                    <div className="model_last">
                        <label style={{fontSize: 15, fontWeight: 'bold', visibility: 'hidden'}}>Review details:</label>
                        <textarea style={{width: 290, marginLeft: '8%', height: 80}} value={this.state.modal_textarea}
                                  onChange={this.modaltextarea}/>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default withRouter(Refund)
