/*eslint-disable*/
import React from 'react';
import {Input, Button,Switch , Table, Modal,Radio, Pagination, DatePicker, Tooltip, message} from 'antd';
import './index.css';
import axios from 'axios'
import moment from 'moment';
import {withRouter} from 'react-router-dom'
import EventEmitter from '../../utils/events'
var getSelect = []

function showTotal(total) {
    return `Total ${total} items`;
}

var getselect = []
const {Search} = Input;
const key = [];
 class MenuManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 1,
            data: [],
            tableData: [],
            searchVal: '',
            current: 1,
            total: 1,
            pageSize: 10,
            boolean: true,
            ishide: false,
            isshow: true,
            ids:'',
            mobilephone:'',
            opened: false,
            arr: [],
            istrue:true,
            switchStatus: [],
            modalthdSelect:0,
            // isfalse:true,

        }
    }

    outOnChange=(value,record,ifCheck)=>{
        console.log('record',record)
        console.log('ifcheck',ifCheck)
        axios
        .post(`/api/system/updateResourceStatus`, {
            id:record.id,
        })
        .then(res => {
            if (res.data.code === 200) {        
                message.success(res.data.msg)
            } else if (res.data.code == 800) {
                this.props.history.push({pathname: "/"});
            } else {
                message.error(res.data.msg)
            }
        })
    }

    OnChange=(value,record,ifCheck)=>{
        console.log('record',record)
        console.log('ifcheck',ifCheck)
        axios
        .post(`/api/system/updateResourceStatus`, {
            id:record.id,
        })
        .then(res => {
            if (res.data.code === 200) {        
                message.success(res.data.msg)
            } else if (res.data.code == 800) {
                this.props.history.push({pathname: "/"});
            } else {
                message.error(res.data.msg)
            }
        })
    }

    edit=(record)=>{
        console.log(record)
        axios
        .post(`/api/system/resourceGet`, {
            id:record.id,
        })
        .then(res => {
            if (res.data.code === 200) {        
                this.setState({
                    fstval:res.data.data.resourceName,
                    scdval:res.data.data.resourceUrl,
                    thdval:res.data.data.resourceOrder
                })
            } else if (res.data.code == 800) {
                this.props.history.push({pathname: "/"});
            } else {
                message.error(res.data.msg)
            }
        })
        this.setState({
            fstvisible:true,
            pid:record.pid,
            id:record.id
        })
    }
    Add=()=>{
        this.setState({
            scdvisible:true,
        })
        this.select()
    }
    redis=()=>{
        axios
        .post(`/api/system/refreshRedisCache`, {

        })
        .then(res => {
            if (res.data.code === 200) {        
                message.success(res.data.msg)
            } else if (res.data.code == 800) {
                this.props.history.push({pathname: "/"});
            } else {
                message.error(res.data.msg)
            }
        })
    }
    edithandleOk=(record,e)=>{
        axios
        .post(`/api/system/resourceEdit`, {
            id:this.state.id,
            pid:this.state.pid,
            resourceName:this.state.fstval,
            resourceUrl:this.state.scdval,
            resourceOrder:this.state.thdval,
        })
        .then(res => {
            if (res.data.code === 200) {        
                message.success(res.data.msg)
                this.searchClick()
                this.setState({
                    fstval:'',
                    scdval:'',
                    thdval:''
                })
            } else if (res.data.code == 800) {
                this.props.history.push({pathname: "/"});
            } else {
                message.error(res.data.msg)
                  this.setState({
                    fstval:'',
                    scdval:'',
                    thdval:''
                })
            }
        })
        this.setState({
            fstvisible:false,
        })
        key.splice(key.indexOf(record.key), 1);//再次点击的时候从数组删除上次存入的key值。
        this.setState({
        arr: key
        })
        document.getElementById('op').innerHTML = 'Open'
    }


    handleOk=(record)=>{
        let type = ''
        if(this.state.modalthdSelect==0){
            type=0
        }else{
            type=1
        }
        axios
        .post(`/api/system/resourceAdd`, {
            pid:this.state.modalthdSelect,
            resourceName:this.state.addfstval,
            resourceUrl:this.state.addscdval,
            resourceOrder:this.state.addthdval,
            resourceType:type  
        })
        .then(res => {
            if (res.data.code === 200) {        
                message.success(res.data.msg)
                this.searchClick()
                this.setState({
                    modalthdSelect:0,
                    addfstval:'',
                    addscdval:'',
                    scdvisible:false,
                    addthdval:''
                })
            } else if (res.data.code == 800) {
                this.props.history.push({pathname: "/"});
            } else {
                message.error(res.data.msg)
                this.setState({
                    modalthdSelect:0,
                    addfstval:'',
                    scdvisible:false,
                    addscdval:'',
                    addthdval:''
                })
            }
        })

        key.splice(key.indexOf(record.key), 1);//再次点击的时候从数组删除上次存入的key值。
        this.setState({
        arr: key
        })
        document.getElementById('op').innerHTML = 'Open'
    }


    opened = (e, record) => {
        axios
        .post(`/api/system/resourceChild`, {
            
            id:record.id
        })
        .then(res => {
            if (res.data.code === 200) {        

                const data1 = [];
                const data = res.data.data
                let status = data.map(item => {
                    return item.resourceStatus
                  })
                  this.setState({
                    switchStatus: status //赋值给一个参数，这个参数数组下面要用到
                  })
                  
                for (let i = 0; i < data.length; i++) {
                 
                    data[i].key = i;
                    data1.push({
                        key: i,
                        date: data[i].resourceName,
                        name:data[i].resourceUrl,
                        stat:data[i].resourceStatus,
                        upgradeNum:data[i].resourceOrder,
                        id:data[i].id,
                        pid:data[i].pid
                      });
                }
                this.setState({
                    tableData: data1,
                    boolean: false
                })
            } else if (res.data.code == 800) {
                this.props.history.push({pathname: "/"});
            } else {
            }
        })

        if (e.target.innerHTML == 'Open') {
        
        key.push(record.key);//点击的每一行的key的值保存下来。
        
        this.setState({
        
        arr: key
        
        })
       
        e.target.innerHTML = 'Closed'
        
        
        } else if (e.target.innerHTML == 'Closed') {
        
        key.splice(key.indexOf(record.key), 1);//再次点击的时候从数组删除上次存入的key值。
        
        this.setState({
        
        arr: key
        
        })
        
        e.target.innerHTML = 'Open'
        }
        
      

        }

   
        modelselectThree=(e)=>{
            var value = e.target.value;
            this.setState({
              modalthdSelect:value
            })
           
          }
             //获取select
    select=()=>{
        var obj=document.getElementById('getselect');
        axios
          .post(`/api/system/resourceList`,{
  
        })
        .then(res=>{
          if(res.data.code === 200){
          getSelect=[]
          let data = res.data.data
          for(let i=0;i<res.data.data.length;i++ ){
            if(i==0){
                getSelect.push(<option value='0'>Top Menu</option> )
              }
            getSelect.push(       
              <option  value={data[i].id}>{data[i].resourceName}</option>
          )
          this.setState({
            getSelect:getSelect,
            // addpid:data[i].pid
          })
          }
          }else if(res.data.code ==800){
            // this.props.history.push({ pathname: "/"});
          }
        })
      }
    handleCancel=()=>{
        this.setState({
            fstvisible:false,
            scdvisible:false,
            modalthdSelect:0,
            addfstval:'',
            addscdval:'',
            addthdval:'',
        })

    }


//页面初始化加载下面的table
    secondTable = () => {
        this.searchClick()
    }
    //搜索
    searchClick = () => {
        this.setState({
            boolean: true
        })
        axios
            .post(`/api/system/resourceList`, {
            })
            .then(res => {
                if (res.data.code === 200) {

                    const data1 = [];
                    const data = res.data.data

                    for (let i = 0; i < data.length; i++) {
                        data[i].key = i;
                        let status = data.map(item => {
                            return item.resourceStatus
                          })
                          this.setState({
                            switchStatus: status 
                          })
                          
                        
                        data1.push({
                            key: i,
                            firstName:i+1,
                            middleName: data[i].resourceName,
                            surName:data[i].resourceUrl,
                            gender:data[i].resourceStatus,
                            bvnNo:data[i].resourceOrder,
                            id:data[i].id,
                            pid:data[i].pid
                          });
                    }
                    this.setState({
                        tableScdData: data1,
                        boolean: false
                    })
                } else if (res.data.code == 800) {
                    this.props.history.push({pathname: "/"});
                } else {
                }
            })
    }
    handClick = (item) => {
        console.log('1111111122222233333', item)
    }
    fstonChange=(e)=>{
        let value = e.target.value
        this.setState({
            fstval:value
        })
    }
    scdonChange=(e)=>{
        let value=e.target.value;
        this.setState({
            scdval:value
        })
    }
    thdonChange=(e)=>{
        let value=e.target.value;
        this.setState({
            thdval:value
        })
    }

    addfstonChange=(e)=>{
        let value = e.target.value
        this.setState({
            addfstval:value
        })
    }
    addscdonChange=(e)=>{
        let value=e.target.value;
        this.setState({
            addscdval:value
        })
    }
    addthdonChange=(e)=>{
        let value=e.target.value;
        this.setState({
            addthdval:value
        })
    }
    componentDidMount() {
        this.secondTable()
        // this.select()
    }

    render() {
        const expandedRowRender = () => {
            const columns = [
              { title: 'Date', width:'25.5%', dataIndex: 'date', key: 'date' },
              { title: 'Name',width:'21%', dataIndex: 'name', key: 'name' },
              {
                title: 'Status',
                key: 'state',
                width:'12%',
                dataIndex: 'stat',
                
                render: (value,record,ifCheck) => (
                  <span>
                   <Switch   onChange ={(ifCheck)=>{this.OnChange(value,record,ifCheck)}}  defaultChecked = {record.stat ==0 ? true : false} />
                  </span>
                ),
              },
              { title: 'Upgrade Status',width:'11%', dataIndex: 'upgradeNum', key: 'upgradeNum' },
              { title: 'Upgrade Status',width:'30.5%', dataIndex: '',pid:'pid', key: 'z',
              render: (text, record) => 
                <span>
                   <a className='edit_button' href="javascript:;" onClick={() => this.edit(record)}>Edit</a>
                </span>
             },
            ];
        

            return <Table columns={columns} className='new_table'  dataSource={this.state.tableData} style={{margin:0,marginTop:'-4px',marginBottom:'-6px',width:'100%'}} pagination={false} />;
          };
        const columns = [
            {
                title: 'ID',
                dataIndex: 'firstName',
                key: 'firstName',
                render: (value) => {
                    return value || '——'
                }

            },
            {
                title: 'Menu name',
                dataIndex: 'middleName',
                key: 'middleName',
                render: (value) => {
                    return value || '——'
                }
            },
            {
                title: 'Menu link',
                dataIndex: 'surName',
                key: 'surName',
                render: (value) => {
                    return value || '——'
                }

            },
            {
                title: 'Menu status',
                dataIndex: 'gender',
                key: 'gender',
                render: (value,record,ifCheck) => {
                    return <span>
                            <Switch onChange ={(ifCheck)=>{this.outOnChange(value,record,ifCheck)}} defaultChecked = {record.gender ==1 ? false : true}/>
                           </span>
                }

            },
            {
                title: 'Queue sort',
                dataIndex: 'bvnNo',
                key: 'bvnNo',
                render: (value) => {
                    return value || '——'
                }
            },
            { title: 'Operation', dataIndex: '', key: 'operation',pid:'pid', render: (text, record) => 
            <span style={{ display: 'flex' , flexDirection: 'row', justifyContent:'center',margin:0}}>
                 <a className='open_button common_color' id='op' href="javascript:;" onClick={(e) => this.opened(e, record)}>Open</a>
                 <a className='edit_button ' href="javascript:;" onClick={() => this.edit(record)}>Edit</a>
              
              </span>
           
            },
        ];
        return (
            <div className="menu_content">
                 <div style={{width:'98%',display:'flex',justifyContent:'space-between'}}>
                    <p  className='details_tab1_top_p'>Menu Management List</p>
                    <div>
                        <Button className='tab2_button' type="primary" style={{ marginTop: 15 }} onClick={this.Add}>Add</Button>
                        <Button className='tab2_button' type="primary" style={{ marginTop: 15,marginLeft:20 }} onClick={this.redis}>Redis</Button>
                    </div>
                </div>
                <div className="table">
                    <Table columns={columns} className='old_table' expandedRowKeys={this.state.arr} expandedRowRender={expandedRowRender}  dataSource={this.state.tableScdData} loading={this.state.boolean}  pagination={false}/>,
                </div>
                <Modal
                title="Edit Menu" 
                visible={this.state.fstvisible}
                onOk={this.edithandleOk}
                onCancel={this.handleCancel}
                >
                <input value={this.state.pid} style={{display:'none'}}/>
                <input value={this.state.id} style={{display:'none'}}/>
                <Input value = {this.state.fstval} allowClear style={{marginTop:10}} onChange={this.fstonChange} />
                <br />
                <Input value = {this.state.scdval} allowClear style={{marginTop:10}} onChange={this.scdonChange} />
                <br />
                <Input value = {this.state.thdval} allowClear style={{marginTop:10}} onChange={this.thdonChange} />
                <br />
                
                </Modal>

                <Modal
                title="add" 
                visible={this.state.scdvisible} 
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                >
                 {/* <input value={this.state.addpid} style={{display:'none'}}/> */}
                <select className="menu_model_fst" id="modal_select" value={this.state.modalthdSelect} onChange={this.modelselectThree}>
                    {getSelect}
                </select>   
                <Input value = {this.state.addfstval} placeholder='Menu name' allowClear style={{marginTop:10}} onChange={this.addfstonChange} />
                <br />
                <Input value = {this.state.addscdval} placeholder='Link address' disabled = {this.state.modalthdSelect ==0 ? true : false} allowClear style={{marginTop:10}} onChange={this.addscdonChange} />
                <br />
                <Input value = {this.state.addthdval} allowClear placeholder='Sequence' style={{marginTop:10}} onChange={this.addthdonChange} />
                <br />
                
                </Modal>
            </div>

        )
    }
}
export default withRouter(MenuManagement)