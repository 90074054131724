/*eslint-disable*/
import React from "react";
import { Input, Button, message } from "antd";
import "./index.css";
import axios from "axios";
import EventEmitter from "../../utils/events";
import login_img from "../../../public/img/login_img.png";
import kuang from "../../../public/img/kuang.png";
import logo from "../../../public/img/logo.png";
import user from "../../../public/img/login_user.png";
import password from "../../../public/img/login_password.png";

export default class Login extends React.Component {
  //初始化
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
    };
  }
  //获取用户名
  userOnchange = (e) => {
    let value = e.target.value;
    this.setState({
      username: value,
    });
  };
  //获取密码
  passOnchange = (e) => {
    let value = e.target.value;
    this.setState({
      password: value,
    });
  };
  handler = (response) => {
    let menuList = [];
    let home = {};
    let child = {};
    for (let i = 0; i < response.data.resourceList.length; i++) {
      let children = [];
      home = {
        title: response.data.resourceList[i].resourceName,
        key: response.data.resourceList[i].resourceUrl,
        children: children,
      };
      for (
        let c = 0;
        c < response.data.resourceList[i].childResource.length;
        c++
      ) {
        child = {
          title: response.data.resourceList[i].childResource[c].resourceName,
          key: response.data.resourceList[i].childResource[c].resourceUrl,
        };
        children.push(child);
      }
      menuList.push(home);
    }
    EventEmitter.trigger("menu", menuList);
    console.log("menu", menuList);
    localStorage.loginUser = response.data.manager.userName;
    localStorage.setItem("key", JSON.stringify(menuList));
    localStorage.authToken = response.token;
    localStorage.roleId = response.data.manager.userRoleId;
    localStorage.userId = response.data.manager.id;
    if (response.data.needModifyPwd == "1") {
      this.props.history.push({ pathname: "/modifyPassword" });
    } else {
      console.log('进入')
      this.props.history.push({ pathname: "/welcome" });
    }
  };

  componentDidMount() {
    axios.post(`/api/system/checklogin`, {}).then((res) => {
      if (res.data.code == 200) {
        this.handler(res.data);
      }
    });
  }

  //提交按钮
  onSubmit = () => {
    axios
      .post(`/api/system/login`, {
        username: this.state.username,
        password: this.state.password,
      })
      .then((res) => {
        if (res.data.code === 200) {
          this.handler(res.data);

          message.success(res.data.msg);
        } else {
          message.error(res.data.msg);
        }
      });
  };
  //监听按回车触发提交
  onInputKeyUp = (e) => {
    if (e.keyCode === 13) {
      this.onSubmit();
    }
  };

  render() {
    return (
      <div className="login">
        <div className="logintop_div">
          <div className="login-content_top">
            <p className="login_p1">9ja Cash Management System</p>
            <h1 className="login_h1">
              Stand high and aim far, be earnest and down-to-earth
            </h1>
          </div>
          <div className="login-content">
            <h2 className="login_content_h2">LOGIN</h2>
            <div className="login_content_div">
              <div className="login_content_div_inside">
                <img className="login_content_div_img" src={user} />
              </div>
              <Input
                placeholder="username"
                type="text"
                value={this.state.username}
                onKeyUp={(e) => this.onInputKeyUp(e)}
                onChange={this.userOnchange}
              />
              <br />
            </div>

            <div className="login_content_div">
              <div className="login_content_div_inside">
                <img className="login_content_div_img" src={password} />
              </div>
              <Input
                placeholder="password"
                type="password"
                value={this.state.password}
                onKeyUp={(e) => this.onInputKeyUp(e)}
                onChange={this.passOnchange}
              />
              <br />
            </div>
            <Button
              className="login_button"
              type="primary"
              onClick={this.onSubmit}
            >
              LOGIN
            </Button>
          </div>
        </div>
        <p className="copyright">Copyright © SokoLending company limited</p>
        <img className="background" src={login_img} />
      </div>
    );
  }
}
