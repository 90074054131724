/*eslint-disable*/
import React from 'react';
import { Input, Button, Table, Modal, Pagination, DatePicker, Tooltip, message } from 'antd';
import axios from 'axios'
import '../index.scss'
import moment from 'moment';
import { withRouter } from 'react-router-dom'
import {getTelemarketMonthData} from '../../../api/telemarket'
import { exportMethod } from '../../../utils/axios';


function showTotal(total) {
    return `Total ${total} items`;
}
var getselect = []
const dateFormat = 'YYYY-MM-DD' || undefined;
const { RangePicker } = DatePicker;
const { Search } = Input;

class TelemarketData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 1,
            total: 1,
            from:'',
            to:'',
            pageSize: 10,
            tableData: [],
            boolean: true,
            totalData:[]
        }
    }
    componentDidMount(){
        this.getUrlParams();
        this.setState({
            from:moment().startOf('month'),
            to: moment().endOf('month'),
          },()=>{
            this.getTableData()
          })   
    }
        //改变页面条数时的回调
        onShowSizeChange=(current, pageSize)=>{

            this.setState({
              pageSize: pageSize
            },()=>{
              this.doSearch()
            })
        }
    //取url参数
     getQueryString = (url)=> {
        url = url == null ? window.location.href : url;
        console.log(url,'生命周期')
  
      var search = url.substring(url.lastIndexOf("?") + 1);
      var obj = {};
      var reg = /([^?&=]+)=([^?&=]*)/g;
      search.replace(reg, function (rs, $1, $2) {
          var name = decodeURIComponent($1);
          var val = decodeURIComponent($2);               
          val = String(val);
          obj[name] = val;
          return rs;
      });
    return obj
        }
    //搜索数据
    doSearch=()=>{
        let params = {
            operatorId:this.state.operatorId,
            overdueGrade:this.state.overdueGrade
        }
        this.getTableData(params)
    }
    //重置搜索框
    clearSearch=()=>{
        this.setState({
            from:moment().startOf('month'),
            to:moment().endOf('month'),
            operatorId:'',
            overdueGrade:''
        },()=>{
            this.getTableData()
        })
    }
    //获取url传过来的数据
    getUrlParams=()=>{
       let params = this.getQueryString(); 
        this.setState({
            operatorId:params['operatorId']
        })
    }
    //请求表格数据
    getTableData(params){
        this.setState({
            boolean:true
        })
        let starttime = moment(this.state.from).format("YYYY-MM-DD")
        let endtime = moment(this.state.to).format("YYYY-MM-DD")
        getTelemarketMonthData({
        operatorId:this.state.operatorId,
        beginTime: starttime,
        endTime: endtime,
        pageNum: this.state.current,
        pageSize: this.state.pageSize,
    }).then(res=>{
        if(res.data.code ===200){
            this.setState({
                boolean:false,
                tableData:res.data.data.resultData,
                total:res.data.data.page.total
            })
        }else if (res.data.code == 800) {
            this.props.history.push({ pathname: "/" });
        }
    })
    }
    //表单值双向绑定
    fstaddonChange = (e) => {
        let InputName = e.target.name;
        let InputValue = e.target.value;
        this.setState({
            [InputName]: InputValue.replace(/\s+/g, ""),
        })
    }
    pageChange = (page) => {
        this.setState({
            current: page
        }, () => {
            this.doSearch()
        })
    }
    //时间选择器范围
    rangeOnChange=(dates, dateStrings)=>{
        console.log(dateStrings,'时间选择器')
        this.setState({ from: dateStrings[0],to:dateStrings[1] })
      }
    render() {
        const columns = [
            {
                title: 'Month',
                dataIndex: 'statDate',
                key: 'statDate',
            },
            {
                title: 'Allocate',
                dataIndex: 'allotCount',
                key: 'allotCount',
                render: (value) => {
                    if(value==0){
                        return 0
                    }else{
                        return value || '——'
                    }
                }
            },
            {
                title: 'Calls',
                dataIndex: 'cellCount',
                key: 'cellCount',
                render: (value) => {
                    if(value==0){
                        return 0
                    }else{
                        return value || '——'
                    }
                }

            },
            {
                title: 'Effective calls',
                dataIndex: 'effectiveCount',
                key: 'effectiveCount',
                render: (value) => {
                    if(value==0){
                        return 0
                    }else{
                        return value || '——'
                    }
                }

            },
            {
                title: 'Effective rate',
                dataIndex: 'effectiveRate',
                key: 'effectiveRate',
                render: (value) => {
                    if(value==null){
                        return '——'
                    }else{
                        return (value*100).toFixed(2) + '%'
                    }
                }

            },
            {
                title: 'Apply Orders',
                dataIndex: 'loanCount',
                key: 'loanCount',
                render: (value) => {
                    if(value==0){
                        return 0
                    }else{
                        return value || '——'
                    }
                }

            },
            {
                title: 'Loans',
                dataIndex: 'paymentCount',
                key: 'paymentCount',
                render: (value) => {
                    if(value==0){
                        return 0
                    }else{
                        return value || '——'
                    }
                }

            },
            {
                title: 'Loan rate',
                dataIndex: 'paymentRate',
                key: 'paymentRate',
                render: (value) => {
                    if(value==null){
                        return '——'
                    }else{
                        return (value*100).toFixed(2) + '%'
                    }
                }

            }
        ];
        return (
            <div className="audit_content">
                {/* <div className="commom_top" style={{ alignItems: 'center',marginBottom:30 }}>
                    <label>Created time:</label>
                    <RangePicker
                        style={{ width: 290, }}
                        ranges={{
                            Today: [moment(), moment()],
                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                        }}
                        showTime
                        onChange={this.rangeOnChange}
                        format={dateFormat}
                        value={this.state.from === undefined || this.state.to === undefined || this.state.from === "" || this.state.to === "" ? null : [moment(this.state.from, dateFormat), moment(this.state.to, dateFormat)]}
                    />
                    <Button type="primary" style={{
                        width: 170,
                        marginLeft: '4%',
                        height: 40,
                        border: 'none'
                    }}
                    className="common_color_fst"
                    onClick={this.doSearch}
                    >Search</Button>
                    <Button type="primary" style={{
                        width: 170,
                        marginLeft: '4%',
                        height: 40,
                        border: 'none'
                    }} 
                    className="common_color_thd"
                    onClick={this.clearSearch}
                    >Refresh</Button>

                </div> */}
                <div className="table">
                    <Table columns={columns} dataSource={this.state.tableData} loading={this.state.boolean}
                    rowKey={record=>record.id}
                        pagination={false} />
                </div>
               
                <Pagination
                    // showQuickJumper
                    onShowSizeChange={this.onShowSizeChange}//pagesize改变时回调函数
                    pageSizeOptions={["10","50","100"]}   //设置pagesize区间
                    defaultCurrent={1}
                    current={this.state.current}
                    total={this.state.total}
                    onChange={this.pageChange}
                    showTotal={showTotal}
                    style={{ textAlign: 'right', marginTop: 20 }}
                    showSizeChanger={true}
                    pageSize={this.state.pageSize}
                />
            </div>
        )
    }
}
export default withRouter(TelemarketData)