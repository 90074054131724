/*eslint-disable*/
import React from 'react';
import {Input, Button,Switch ,Tree , Table, Modal,Radio, Pagination, DatePicker, Tooltip, message} from 'antd';
import './index.css';
import axios from 'axios'
import moment from 'moment';
import {withRouter} from 'react-router-dom'
import EventEmitter from '../../utils/events'
var getSelect = []
var treeData = []
let test = []
function showTotal(total) {
    return `Total ${total} items`;
}


  const optionsWithDisabled = [
    { label: 'Open', value: 1 },
    { label: 'Close', value: 0 },
  ];
var getselect = []
const { TreeNode } = Tree;
const {Search} = Input;
const key = [];
 class optionConfig  extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 1,
            data: [],
            tableData: [],
            searchVal: '',
            current: 1,
            total: 1,
            pageSize: 10,
            boolean: true,
            mobilephone:'',
            opened: false,
            value1:0,
            value2:0,
            fstSelect:0,
        }
    }
        //页码改变时触发
        pageChange=(page)=> { 
      
            this.setState({
              current: page
              },()=>{
                this.searchClick()
              })
            }


    onChange2 = e => {
        console.log('radio checked', e.target.value);
        this.setState({
            value2: e.target.value,
        });
      };


    /*添加modal点击ok*/ 
    addhandleOk=()=>{
       
        axios
        .post(`/api/common/addCommon`, {
            code:this.state.fstSelect,
            name:this.state.scdval,
            number:this.state.thdval,
        })
        .then(res => {
            if (res.data.code === 200) {        
                message.success(res.data.msg)
                this.searchClick()
                this.setState({
                    scdval:'',
                    thdval:'',
                    fstSelect:0,
                    scdvisible:false,
                })
                this.searchClick()
            } else if (res.data.code == 800) {
                this.props.history.push({pathname: "/"});
            } else {
                message.error(res.data.msg)
                this.setState({
                    scdval:'',
                    thdval:'',
                    scdvisible:false,
                    fstSelect:0
                })
                this.searchClick()
            }
        })
    }
               //获取select
               select=()=>{
                var obj=document.getElementById('getselect');
                axios
                  .post(`/api/common/codeList`,{
                })
                .then(res=>{
                  if(res.data.code === 200){
                  getSelect=[]
                  let data = res.data.data
                  for(let i=0;i<res.data.data.length;i++ ){
                    getSelect.push(       
                      <option id={data} value={data[i]}>{data[i]}</option>
                  )
                  // console.log('select',getSelect)
                  this.setState({
                    getSelect:getSelect,
                  })
                  }
                  }else if(res.data.code ==800){
                    // this.props.history.push({ pathname: "/"});
                  }
                })
              }

     /*删除modal点击ok*/ 
    deletehandleOk=()=>{
        axios
        .post(`/api/common/delCommon`, {
            id:this.state.delval,
        })
        .then(res => {
            if (res.data.code === 200) {        
                message.success(res.data.msg)
                this.searchClick()
                this.setState({
                    thdvisible:false
                })
            } else if (res.data.code == 800) {
                this.props.history.push({pathname: "/"});
            } else {
                message.error(res.data.msg)
                this.searchClick()
                this.setState({
                    thdvisible:false
                })
            }
        })
    }
    edit=(record)=>{
        this.select()
        axios
        .post(`/api/common/commonGet`, {
            id:record.id,
        })
        .then(res => {
            if (res.data.code === 200) {        
              
                this.setState({
                    fstvisible:true,
                    delval:record.id,
                    editscdval : record.name,
                    editfstSelect : record.code,
                    editthdval : record.number,
                })
            } else if (res.data.code == 800) {
                this.props.history.push({pathname: "/"});
            } else {
                message.error(res.data.msg)
            }
        })
      
    }
    edithandleOk=()=>{
        axios
        .post(`/api/common/commonEdit`, {
            id:this.state.delval,
            code:this.state.editfstSelect,
            name:this.state.editscdval,
            number:this.state.editthdval
        })
        .then(res => {
            if (res.data.code === 200) {        
                message.success(res.data.msg)
              
                this.setState({
                    editfstSelect:0,
                    editscdval:'',
                    editthdval:''
                })
                this.searchClick()
            } else if (res.data.code == 800) {
                this.props.history.push({pathname: "/"});
            } else {
                message.error(res.data.msg)
                  this.setState({
                    editfstSelect:0,
                    editscdval:'',
                    editthdval:''
                })
            }
        })
        this.setState({
            fstvisible:false,
        })

    }
    menuhandleOk=()=>{
        alert(this.state.checkedKeys)
        axios
        .post(`/api/system/roleResourceUpdate`, {
            id:this.state.menuid,
            resourceIds:this.state.checkedKeys
            
        })
        .then(res => {
            if (res.data.code === 200) {        
                message.success(res.data.msg)
              
                this.setState({
                    fouvisible:false
                })
                this.searchClick()
            } else if (res.data.code == 800) {
                this.props.history.push({pathname: "/"});
            } else {
                message.error(res.data.msg)
                this.setState({
                    fouvisible:false
                })
            }
        })
        this.setState({
            fstvisible:false,
        })
    }
    Add=()=>{
        this.select()
        this.setState({
            scdvisible:true,
        })
    }


   
        modelselectThree=(e)=>{
            var value = e.target.value;
            this.setState({
              modalthdSelect:value
            })
           
          }

    handleCancel=()=>{
        this.setState({
            fstvisible:false,
            scdvisible:false,
            thdvisible:false,
            fouvisible:false,
        })
    }
    delete=(record)=>{
        this.setState({
            named : record.name,
            thdvisible:true,
            delval:record.id
        })
    }

//页面初始化加载下面的table
    secondTable = () => {
        this.searchClick()
    }
    //搜索
    searchClick = () => {
        this.setState({
            boolean: true
        })
        axios
            .post(`/api/common/commonList`, {
                pageNum:this.state.current,
                pageSize:this.state.pageSize,
                name:this.state.searchVal
            })
            .then(res => {
                if (res.data.code === 200) {

                    const data1 = [];
                    const data = res.data.data.resultData

                    for (let i = 0; i < data.length; i++) {
                        data[i].key = i;
                        data1.push(data[i]);
                    }
                    this.setState({
                        tableScdData: data1,
                        boolean: false,
                        length:res.data.data.page.total
                    })
                } else if (res.data.code == 800) {
                    this.props.history.push({pathname: "/"});
                } else {
                    
                }
            })
    }
    handClick = (item) => {
        console.log('1111111122222233333', item)
    }
    fstonChange=(e)=>{
        let value=e.target.value;
        this.setState({
            fstval:value
        })
    }

    /*添加modal的input开始 */
    fstAddOnChange=(e)=>{
        let value=e.target.value;
        this.setState({
            fstAddVal:value
        })
    }
    scdAddOnChange=(e)=>{
        let value=e.target.value;
        this.setState({
            scdAddVal:value
        })
    }
    /*添加modal的input结束 */

        //输入框
        searchIpt(e){
            var value = e.target.value;
            this.setState({ searchVal: value.replace(/\s+/g,"") })
          }
    scdonChange=(e)=>{
        let value=e.target.value;
        this.setState({
            scdval:value
        })
    }
    editscdonChange=(e)=>{
        let value=e.target.value;
        this.setState({
            editscdval:value
        })
    }
    editthdonChange=(e)=>{
        let value=e.target.value;
        this.setState({
            editthdval:value
        })
    }
    
    thdonChange=(e)=>{
        let value=e.target.value;
        this.setState({
            thdval:value
        })
    }
    fstselect=(e)=>{
        var value = e.target.value;
            this.setState({
                fstSelect:value
            })
      }
    editstselect=(e)=>{
        var value = e.target.value;
        this.setState({
            editfstSelect:value
        })
    }
    componentDidMount() {
        this.secondTable()
    }

    render() {
       
      const { selectedRowKeys } = this.state;
      const rowSelection = {
        selectedRowKeys,
        onChange: this.onSelectChange,
         columnWidth:10
      };
        const columns = [

            {
                title: 'code',
                dataIndex: 'code',
                key: 'code',
                render: (value) => {
                    return value || '——'
                }
            },
            {
                title: 'name',
                dataIndex: 'name',
                key: 'name',
                render: (value) => {
                    return value || '——'
                }

            },
            {
                title: 'creation time',
                dataIndex: 'createdTime',
                key: 'createdTime',
                render: (value) => {
                    let timeaudit= new Date(value).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
                    let auditimes=moment(new Date(timeaudit)).format("YYYY-MM-DD HH:mm:ss");
                    return auditimes
                }
            },
            {
                title: 'number',
                dataIndex: 'number',
                key: 'number',
                render: (value) => {
                    return value;
                },
            },
           
          
          
           
           
            { title: 'Operation', dataIndex: '', key: 'operation', render: (text, record) => 
            <span style={{ display: 'flex' , flexDirection: 'row', justifyContent:'center',margin:0}}>
                 <a className='edit_button' href="javascript:;" onClick={() => this.edit(record)}>edit</a>
                 <a className='edit_button' href="javascript:;" onClick={() => this.delete(record)}>Delete</a>
              </span>
           
            },
        ];
        return (
            <div className="menu_content">
             <div style={{width:'98%',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                <div>
                    <Search
                        placeholder="Name"
                        onSearch={value => console.log(value)}
                        style={{width: 290,marginRight:10,marginLeft:10}}
                        value={this.state.searchVal}
                        onChange={this.searchIpt.bind(this)}
                    />
                    <Button type="primary" style={{width:170,marginRight:20,height:40,border:'none'}} className="common_color" onClick={this.searchClick}>Search</Button>
                </div>
                
                    <Button className='tab2_button' type="primary" style={{ marginTop: 15 }} onClick={this.Add}>Add</Button></div>
                <div className="table">
                    <Table columns={columns} className='old_table'  dataSource={this.state.tableScdData} loading={this.state.boolean}  pagination={false}/>,
                </div>
                <Pagination
          defaultCurrent={1} 
          current={this.state.current}
          total={this.state.length} 
          onChange={this.pageChange}  
          pageSize={this.state.pageSize}
          showTotal={showTotal}
          style={{textAlign:'right',marginTop:20}}
          />
                <Modal
                title="Edit" 
                visible={this.state.fstvisible}
                onOk={this.edithandleOk}
                onCancel={this.handleCancel}
                >
                <input value={this.state.delval} style={{display:'none'}}/>
                <div className='product_div'>
                    <div className='common_div' style={{width:'100%'}}>
                        <span className='product_span'>code:</span>
                        <select className='product_select' value={this.state.editfstSelect} onChange={this.editstselect}>
                            {getSelect}
                        </select>
                    </div>
                    
                </div>
                <br />
                <div className='product_div'>
                    <div className='common_div' style={{width:'100%'}}>
                        <span className='product_span'>name:</span>
                        <Input value = {this.state.editscdval} allowClear  onChange={this.editscdonChange} />
                    </div>
                </div>
                <br />
                <div className='product_div'>
                    <div className='common_div' style={{width:'100%'}}>
                        <span className='product_span'>number:</span>
                        <Input value = {this.state.editthdval} allowClear  onChange={this.editthdonChange} />
                    </div>
                </div>
                </Modal>

                <Modal
                title="New" 
                visible={this.state.scdvisible} 
                onOk={this.addhandleOk}
                onCancel={this.handleCancel}
                >
               <div className='product_div'>
                    <div className='common_div' style={{width:'100%'}}>
                        <span className='product_span'>code:</span>
                        <select className='product_select' value={this.state.fstSelect} onChange={this.fstselect}>
                            {getSelect}
                        </select>
                    </div>
                    
                </div>
                <br />
                <div className='product_div'>
                    <div className='common_div' style={{width:'100%'}}>
                        <span className='product_span'>name:</span>
                        <Input value = {this.state.scdval} allowClear  onChange={this.scdonChange} />
                    </div>
                </div>
                <br />
                <div className='product_div'>
                    <div className='common_div' style={{width:'100%'}}>
                        <span className='product_span'>number:</span>
                        <Input value = {this.state.thdval} allowClear  onChange={this.thdonChange} />
                    </div>
                </div>
                
                </Modal>

                <Modal
                visible={this.state.thdvisible} 
                onOk={this.deletehandleOk}
                onCancel={this.handleCancel}
                >
                <input value={this.state.delval} style={{display:'none'}}/>
               <p>Make sure you want to delete {this.state.named}?</p>
                
                </Modal>
            </div>

        )
    }
}
export default withRouter(optionConfig)