import React from 'react';
import './index.css';
// import Paginations from '../../utils/pagination';
class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        {/* <Paginations/> */}
      </div>
    )
  }
}
export default Footer;