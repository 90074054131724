/*eslint-disable*/
import React from 'react';
import { Input,Button,Table,Modal,Pagination,DatePicker,Tooltip,message,Icon,Upload } from 'antd';
import './index.css';
import axios from 'axios'
import {withRouter} from 'react-router-dom'
import EventEmitter from '../../utils/events'
import rule from '../../../public/img/bank_rule.png'
import location_red from '../../../public/img/rule.png';
import location_red_today from '../../../public/img/rule.png';
import { exportMethod } from '../../utils/axios'
import {Link} from 'react-router-dom'
import moment from 'moment';
const { RangePicker } = DatePicker;
  const { Search } = Input;
  const dateFormat = 'YYYY-MM-DD'||undefined;
var whitchshow = []

  function showTotal(total) {
    return `Total ${total} items`;
  }

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  
  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
      message.error("Image must smaller than 1MB!");
    }
    return isJpgOrPng && isLt1M;
  }
 class Bank extends React.Component {
    constructor(props){
      super(props);
      this.state={
        fstSelect:'',
        scdSelect:'',
        searchVal:'',
        current:1,
        fstinpt:'',
        fileList: [],
        pageSize:10,
        boolean:false,
        modify:false,
        scdafter:false,
        isdisabled:false,
        debit_money:'',
        creditInput:'',
        isDisabled:false,
        btnWord:'export',
        claimed:'',
        claimOrderNo:"",
        selectedCollector:"",
        imgUrl:"",
        mobile:'',
        name:'',
        credit:'',
        uploadDate:[]
      }
    }

    onShowSizeChange=(current, pageSize)=>{

      this.setState({
        pageSize: pageSize
      },()=>{
        this.searchClick()
      })
  }
        //页码改变时触发
        pageChange=(page)=>{
          this.setState({
            current: page
            },()=>{
              this.searchClick()
            })
        }
        rangeOnChange=(dates, dateStrings)=>{
          console.log(dates)
          console.log(dateStrings)
          this.setState({ from: dateStrings[0],to:dateStrings[1] })
        }
        rangeOnChange1=(dates, dateStrings)=>{
          this.setState({ operate: dateStrings[0],operateto:dateStrings[1] })
        }

        rangeOnChange2=(dates, dateStrings)=>{
          if(dates.length==0){
            this.setState({uploadDate:[],uploadStartTime:'',uploadEndTime:''})
          }else{
            this.setState({ uploadStartTime: dateStrings[0],uploadEndTime:dateStrings[1] })
          }
        }
    handleCancel=()=>{
      this.setState({
        scdvisible: false,
        modalinput:'',
        thdvisible: false,
        rulevisible: false,
        uploadvisible:false,
        uploadvisibletoday:false,
        modify:false,
        scdafter:false,
        isdisabled:false,
        fileList:'',
        associatedVisible:false,
        cancelVisible:false,
        debit_money:'',

      });
    }
    //重置按钮
    reloadClick(e){
      this.setState({
        searchVal: "",
        fstInput: "",
        scdInput: "",
        from: undefined,
        to: undefined,
        operate: undefined,
        operateto: undefined,
        uploadStartTime: undefined,
        uploadEndTime: undefined,
        boolean: false,
        fstSelect: "",
        thdInput: "",
        creditInput: "",
        uploader: "",
        uploadTimeStart: undefined,
        uploadTimeEnd: undefined,
        uploadTime: "",
        mobile:'',
        name:'',
        credit:''
      },()=>{
          this.searchClick();
      });
    
    }

    onload=()=>{
      this.searchClick()
    }
    componentDidMount(){
     this.onload()
//    if(localStorage.roleId==1||localStorage.roleId==4||localStorage.roleId==9||localStorage.roleId==17){
//     this.setState({
//       ishide:true,
//       ishiden:true
//     })
//  }
}

    //输入框
    searchIpt(e){
      var value = e.target.value;
      this.setState({ searchVal: value.replace(/\s+/g,"") })
    }
    selectfst= (e) =>{
      var value = e.target.value;
      this.setState({
        fstSelect:value,
      });
    }
    selectClaim = (e) =>{
      let value = e.target.value
      this.setState({
        claimed:value
      })
    }
    handleReduceCancel =() =>{
      this.setState({
        thdvisible:false
      })
    }
    handleImageChange = (info) => {
      if (info.file.status === "uploading") {
        this.setState({ loading: true });
        return;
      }
      if (info.file.status === "done") {
        getBase64(info.file.originFileObj, (imgUrl) => {        
          let temp = this.state
          temp.imgUrl = info.file.response.data
          this.setState({
            data: temp,
            loading: false,
          })
        });
      }
    };

    handleReduceOk =() =>{
      if(this.state.claimOrderNo == '' || this.state.imgUrl == ''){
        message.error('Please enter the ClaimOrderNo or ClaimVoucher')
      }else{
        let params = {
          bankFlowId:this.state.modifyId,
          claimOrderNo:this.state.claimOrderNo,
          remark:this.state.selectedCollector,
          imageUrl:this.state.imgUrl
        }
        axios({
          url:'/api/bankFlowClaimed/addBankFlowClaim',
          method:'post',
          data:params
        }).then(res=>{
          console.log(res);
          if(res.data.code == 200){
            message.success(res.data.msg)
            this.searchClick()
            this.setState({
              thdvisible:false,
              selectedCollector:'',
              claimOrderNo:'',
              imgUrl:''
            })
          }
        })
      }
     
    }
    uploadhandleOk=()=>{
      if(this.state.fileList==''){
        alert("Upload content cannot be empty")
        return false
      }
      this.setState({
        scdafter:true
    })
   
    const { fileList} = this.state;
    const formData = new FormData();
    formData.append('file', fileList[0]);  
    
    

      
    console.log('fileList',fileList)
    axios({
        url: '/api/bankPayment/uploadExcel',
        method: 'post',
        data: formData
    })
    .then(res => {
        if (res.data.code === 200) {        
            message.success(res.data.msg)
            this.searchClick()
            this.setState({ 
                uploadvisible:false,
                uploadvisibletoday:false,
                fileList:'',
                scdafter:false,
                isdisabled:false
            })
        } else if (res.data.code == 800) {
            this.props.history.push({pathname: "/"});
        } else {
            message.error(res.data.msg)
            this.setState({
              uploadvisible:false,
              uploadvisibletoday:false,
              scdafter:false,
              fileList:'',
              isdisabled:false
            })
        }
    })
    }

    uploadhandleOkToday=()=>{
      if(this.state.fileList==''){
        alert("Upload content cannot be empty")
        return false
      }
      this.setState({
        scdafter:true
    })
   
    const { fileList} = this.state;
    const formData = new FormData();
    formData.append('file', fileList[0]);  
    
    

      
    console.log('fileList',fileList)
    axios({
        url: '/api/bankPayment/uploadExcelToday',
        method: 'post',
        data: formData
    })
    .then(res => {
        if (res.data.code === 200) {        
            message.success(res.data.msg)
            this.searchClick()
            this.setState({ 
                uploadvisible:false,
                uploadvisibletoday:false,
                fileList:'',
                scdafter:false,
                isdisabled:false
            })
        } else if (res.data.code == 800) {
            this.props.history.push({pathname: "/"});
        } else {
            message.error(res.data.msg)
            this.setState({
              uploadvisible:false,
              uploadvisibletoday:false,
              scdafter:false,
              fileList:'',
              isdisabled:false
            })
        }
    })
    }
    handleData(time){
      if(!time){
          return false
      }else{
      // 大于当前日期不能选 time > moment()
      // 小于当前日期不能选 time < moment().subtract(1, "days")
      // 只能选前7后7 time < dateStrings[0].subtract(7, "days") || dateStrings[0] > moment().add(7, 'd')
          return time > moment()
      }
  }
          showrule=()=>{
            this.setState({
              
            })
          }
          todetail=(record)=>{
            this.props.history.push({ pathname: "../../details/index",state:{record}});
          }

          getTable =  () =>{
            this.searchClick(1)
          }

          searchClick=(current)=>{
            this.setState({
              boolean: true
            })
            axios
            .post(`/api/bankPayment/bankFlowList`,{
          
                pageSize: this.state.pageSize,
                current:  current || this.state.current,
                indentNo: this.state.searchVal,
                description: this.state.fstInput,
                status: this.state.fstSelect,
                loanRecordId: this.state.scdInput,
                beginTime: this.state.from,
                endTime: this.state.to,
                operator: this.state.thdInput,
                operateBeginTime: this.state.operate,
                operateEndTime: this.state.operateto,
                credit: this.state.creditInput,
                uploader: this.state.uploader,
                uploadTimeStart: this.state.uploadTimeStart,
                uploadTimeEnd: this.state.uploadTimeEnd,
                uploadTime: this.state.uploadTime,
                claimed:this.state.claimed,
                mobile:this.state.mobile,
                name:this.state.name,
                credit:this.state.credit * 100,
                uploadStartTime: this.state.uploadStartTime,
                uploadEndTime: this.state.uploadEndTime,
          })
          .then(res=>{
            if (res.data.code === 200) {
              const data1 = [];
              const data = res.data.data.resultData
              if(data.length<1){
                this.setState({
                boolean: false  
                })
              }else{
                for (let i = 0; i < data.length; i++) {
                  data[i].key = i;
                  data1.push(data[i]);
                }
              }
              this.setState({
                tableData: data1,
                total: res.data.data.page.total,
                boolean: false
              })
          } else if(res.data.code ==800){
              this.props.history.push({ pathname: "/"});
            }else{
              // message.error(res.data.msg);
            }
          })
          }
      
          fstInputChange=(e)=>{
            let value = e.target.value;
            this.setState({
              fstInput:value,
            });
          }
          handleCollectorChange = (e) => {
            let  value = e.target.value;
            this.setState({
              claimOrderNo: value.replace(/(^\s*)|(\s*$)/g, "")
            });
          }
          handleselectedCollectorChange = (e) =>{
            let value = e.target.value;
            this.setState({
              selectedCollector:value
            })
          }
          scdInputChange=(e)=>{
            let value = e.target.value;
            this.setState({
              scdInput:value.replace(/\s+/g,""),
            });
          }
          thdInputChange=(e)=>{
            let value = e.target.value;
            this.setState({
              thdInput:value.replace(/\s+/g,""),
            });
          }

          mobileInputChange=(e)=>{
            let value = e.target.value;
            this.setState({
              mobile:value.replace(/\s+/g,""),
            });
          }

          


          creditChange=(e)=>{
            let value = e.target.value;
            this.setState({
              creditInput:value.replace(/\s+/g,""),
            });
          }
          debitMoney=(e)=>{
            var value = e.target.value;
            this.setState({
              debit_money:value,
            });
           
          }
          //offine按钮
          modify=(record)=>{
            this.setState({
              thdvisible:true,
              modifyId:record.id,
              claimOrderNo:'',
              selectedCollector:'',
            })
          }
          cancel=(record)=>{
            this.setState({
              cancelVisible:true,
              cancelId:record.id
            })
          }
          Associate=(record)=>{
            this.setState({
              associatedVisible:true,
              associatedId:record.id
            })
          }
          downExcel=()=>{
            console.log(this.state.uploadStartTime,'1')
            if(this.state.uploadStartTime == undefined || this.state.uploadStartTime == ''){
              message.error('Please choose Upload Time')
            }else{
              this.state.isDisabled = true;
              this.export();
              let count = 60;
              let timer = setInterval(()=>{
                if(count <=0){
                  this.setState({
                    btnWord:'export',
                    isDisabled:false
                  })
                  clearInterval(timer);
                }else{
                  count --;
                  this.setState({
                    btnWord:count +'s'
                  })
                }
              },1000)
            }
          }
          export=()=>{
          
            let myObj = {
              method: 'post',
              url:`/api/bankPayment/exportExcel`,
              data:{
                indentNo:this.state.searchVal,
                description:this.state.fstInput,
                status:this.state.fstSelect,
                loanRecordId:this.state.scdInput,
                beginTime:this.state.from,
                endTime:this.state.to,
                operator:this.state.thdInput,
                operateBeginTime:this.state.operate,
                operateEndTime:this.state.operateto,
                credit:this.state.creditInput,
                uploader: this.state.uploader,
                uploadTime: this.state.uploadTime,
                uploadStartTime: this.state.uploadStartTime,
                uploadEndTime: this.state.uploadEndTime,
              },
              fileName: 'Bank Statements.xls',
              }
              exportMethod(myObj)
          }
          
          changeupload=()=>{
            this.setState({
              uploadvisible:true
            })
          }
          changeuploadtoday=()=>{
            this.setState({
              uploadvisibletoday:true
            })
          }
          demo=()=>{
            let myObj1 = {
              method: 'post',
              url:`/api/bankPayment/downloadTemplate`,
              data:{},
              fileName: 'Templet upload.xls',
              }
              exportMethod(myObj1)
          }
          today=()=>{
            let myObj1 = {
              method: 'post',
              url:`/api/bankPayment/downloadTemplateToday`,
              data:{},
              fileName: 'Templet upload.xls',
              }
              exportMethod(myObj1)
          }
          text=(record)=>{
            this.setState({
              scdvisible:true
            })
            axios
            .post(`/api/bankFlowClaimed/bankFlowList?id=` + record.id)
          .then(res=>{
            if (res.data.code === 200) {
              const data1 = [];
              const data = res.data.data
                for (let i = 0; i < data.length; i++) {
                  data[i].key = i;
                  data1.push(data[i]);
                }
              this.setState({
                phoneTableData: data1,
                boolean: false
              })
          } else if(res.data.code ==800){
              this.props.history.push({ pathname: "/"});
            }else{
              // message.error(res.data.msg);
            }
          })
          }
          modifyhandleOk=(record)=>{
            this.setState({
              modify:true
          })
            axios
            .post(`/api/bankPayment/adjust`,{
              id:this.state.modifyId
          })
          .then(res=>{
            if (res.data.code === 200) {
              message.success(res.data.msg);
              this.setState({
                thdvisible:false,
                modify:false,
              })
              this.searchClick()
          } else if(res.data.code ==800){
              this.props.history.push({ pathname: "/"});
            }else{
              message.error(res.data.msg);
              this.setState({
                thdvisible:false,
                modify:false
              })
              this.searchClick()
            }
          })
          }


          cancelhandleOk=(record)=>{

            axios
            .post(`/api/bankPayment/cancelAssociated`,{
              id:this.state.cancelId
          })
          .then(res=>{
            if (res.data.code === 200) {
              message.success(res.data.msg);
              this.setState({
                cancelVisible:false,
              })
              this.searchClick()
          } else if(res.data.code ==800){
              this.props.history.push({ pathname: "/"});
            }else{
              message.error(res.data.msg);
              this.setState({
                cancelVisible:false,
              })
              this.searchClick()
            }
          })
         
          }
          associatedhandleOk=(record)=>{

            axios
            .post(`/api/bankPayment/associateOrder`,{
              id:this.state.associatedId,
              orderNo:this.state.debit_money
          })
          .then(res=>{
            if (res.data.code === 200) {
              message.success(res.data.msg);
              this.setState({
                associatedVisible:false,
              })
              this.searchClick()
          } else if(res.data.code ==800){
              this.props.history.push({ pathname: "/"});
            }else{
              message.error(res.data.msg);
              this.setState({
                associatedVisible:false,
              })
              this.searchClick()
            }
          })
          
         
          }
          
    render() {
      const phoneColumns = [
        {
          title: 'Check No',
          dataIndex: 'claimed',
          key: 'claimed',
        //   render: (value) => {
        //     let timeaudit= new Date(value).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
        //     let auditimes=moment(new Date(timeaudit)).format("YYYY-MM-DD");
        //     return auditimes
        // }
      },
        {
            title: 'Effective Date',
            dataIndex: 'effectiveDate',
            key: 'effectiveDate',
            render: (value) => {
              let timeaudit= new Date(value).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
              let auditimes=moment(new Date(timeaudit)).format("YYYY-MM-DD");
              return auditimes
          }
        },
        {
          title: "Credit",
          dataIndex: "credit",
          key: "credit",
          render: (value) => {
            var num = value / 100;
            num = num + "";
            if (num.indexOf(".") != -1) {
              return num;
            } else {
              var str = "";
              for (var i = num.length - 1, j = 1; i >= 0; i--, j++) {
                if (j % 3 == 0 && i != 0) {
                  //每隔三位加逗号，过滤正好在第一个数字的情况
                  str += num[i] + ","; //加千分位逗号
                  continue;
                }
                str += num[i]; //倒着累加数字
              }
              return str.split("").reverse().join("");
            }
          },
        },
        {
          title: 'Claim Order No',
          dataIndex: 'claimOrderNo',
          key: 'claimOrderNo',
      },
      {
          title: 'Claimant',
          dataIndex: 'claimant',
          key: 'claimant',
        //   render: (value) => {
        //     if(value==0){
        //       return 'Cancel'
        //     }
        //      else if(value==1||value==2){
        //       return 'Associated'
        //      }else{
        //       return 'Refund'
        //      }
        // }
      },
      {
        title: 'Claim time',
        dataIndex: 'createdTime',
        key: 'createdTime',
        render: (value) => {
          let timeaudit= new Date(value).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
          let auditimes=moment(new Date(timeaudit)).format("YYYY-MM-DD hh:mm:ss");
          return auditimes
        },
    },
    {
      title: 'remark',
      dataIndex: 'remark',
      key: 'remark',
      },
    {
      title: 'Claim voucher',
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      render: (value) => {
          if(value==''||value==undefined){
              return ''
          }
          else {
           return (<a href={value} target="_blank">图片链接</a>)
          }
        }
      },
    ];

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const uploadButton = (
      <div style={formItemLayout}>
        <Icon type={this.state.loading ? "loading" : "plus"} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    const columns = [
        {
          title: "upload time",
          dataIndex: "createdTime",
          key: "createdTime",
          render: (value) => {
            let timeaudit =
              new Date(value).getTime() +
              new Date().getTimezoneOffset() * 60 * 1000 -
              0 * 60 * 60 * 1000;
            let auditimes = moment(new Date(timeaudit)).format(
              "YYYY-MM-DD HH:mm:ss"
            );
            if (value == null) {
              return (auditimes = "");
            } else {
              return (auditimes = auditimes);
            }
          },
        },
        {
          title: "uploader",
          dataIndex: "uploader",
          key: "uploader",
        },
        {
          title: "Check No",
          dataIndex: "indentNo",
          key: "indentNo",
        },
        {
          title: "mobile",
          dataIndex: "mobile",
          key: "mobile",
        },
        {
          title: "name",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "Effective Date",
          dataIndex: "effectiveDate",
          key: "effectiveDate",
          render: (value) => {
            let timeaudit =
              new Date(value).getTime() +
              new Date().getTimezoneOffset() * 60 * 1000 -
              0 * 60 * 60 * 1000;
            let auditimes = moment(new Date(timeaudit)).format("YYYY-MM-DD");
            if (value == null) {
              return (auditimes = "");
            } else {
              return (auditimes = auditimes);
            }
          },
        },
  
        {
          title: "Description",
          dataIndex: "description",
          key: "description",
        },
        //   {
        //     title: "Debit",
        //     dataIndex: "debit",
        //     key: "debit",
        //     render: (value) => {
        //       var num = value / 100;
        //       num = num + "";
        //       if (num.indexOf(".") != -1) {
        //         return num;
        //       } else {
        //         var str = "";
        //         for (var i = num.length - 1, j = 1; i >= 0; i--, j++) {
        //           if (j % 3 == 0 && i != 0) {
        //             //每隔三位加逗号，过滤正好在第一个数字的情况
        //             str += num[i] + ","; //加千分位逗号
        //             continue;
        //           }
        //           str += num[i]; //倒着累加数字
        //         }
        //         return str.split("").reverse().join("");
        //       }
        //     },
        //   },
        {
          title: "Credit",
          dataIndex: "credit",
          key: "credit",
          render: (value) => {
            var num = value / 100;
            num = num + "";
            if (num.indexOf(".") != -1) {
              return num;
            } else {
              var str = "";
              for (var i = num.length - 1, j = 1; i >= 0; i--, j++) {
                if (j % 3 == 0 && i != 0) {
                  //每隔三位加逗号，过滤正好在第一个数字的情况
                  str += num[i] + ","; //加千分位逗号
                  continue;
                }
                str += num[i]; //倒着累加数字
              }
              return str.split("").reverse().join("");
            }
          },
        },
        {
          title: "status",
          dataIndex: "status",
          key: "status",
          render: (value) => {
            if (value == 0) {
              return (value = "Not associated");
            } else if (value == 1 || value == 2) {
              return "Associated";
            } else {
              return "Had refund";
            }
          },
        },
        {
          title: "Order No",
          dataIndex: "loanRecordNo",
          key: "loanRecordNo",
        },
        {
          title: "Operator",
          dataIndex: "operator",
          key: "operator",
        },
        {
          title: "Operate time",
          dataIndex: "operateTime",
          key: "operateTime",
          render: (value) => {
            let timeaudit =
              new Date(value).getTime() +
              new Date().getTimezoneOffset() * 60 * 1000 -
              0 * 60 * 60 * 1000;
            let auditimes = moment(new Date(timeaudit)).format("YYYY-MM-DD");
            if (value == null) {
              return (auditimes = "");
            } else {
              return (auditimes = auditimes);
            }
          },
        },
        {
          title: "Claim Status",
          dataIndex: "claimed",
          key: "claimed",
          render: (value) => {
            if (value == 0 || value == null) {
              return (value = "UnClaimed");
            } else  if (value == 1){
              return (value = "Claimed");

            }
          },
        },
       
        {
          title: "Operate",
          dataIndex: "operating",
          key: "operating",
          width: 70,
          id: "id",
          uid: "uid",
          fixed: "right",
          render: (text, record, index) => (
            <span style={{ display: "flex", flexDirection: "row" }}>
            <Button 
            type="primary"    
            className="detailsbutton common_color" 
            size="small"  
            onClick={()=>this.modify(record)}
            style={{display:record.status == 0 ? "block":"none"}}>
               Claim
            </Button>


            <Button type="primary"    className="detailsbutton common_color" size="small"  onClick={()=>this.text(record)}>
              Claim details
            </Button>
             
            {/* <Button type="primary"    className="detailsbutton common_color" size="small" style={{display:record.status==0?"block":"none"}} onClick={()=>this.Associate(record)}>
               Associate
            </Button>
            <Button type="primary"    className="detailsbutton common_color"style={{display:record.status==2?"block":"none"}} size="small"  onClick={()=>this.cancel(record)}>
               Cancel
            </Button> */}
            </span>
          ),
        },
      ];
      const { uploading, fileList } = this.state;
      const props = {
        onRemove: (file) => {
          this.setState((state) => {
            const index = state.fileList.indexOf(file);
            const newFileList = state.fileList.slice();
            newFileList.splice(index, 1);
            return {
              fileList: newFileList,
            };
          });
        },
        beforeUpload: (file) => {
          this.setState(state => ({
            fileList: [...state.fileList, file],
            isdisabled:true
          }));
         
          return false;
        },
        fileList,
      };
      return (
        <div className="credit_order">
          <div className="myorder_content">
          <div
            className="flutter"
            style={{
              backgroundColor: "#fff",
              padding: 20,
              paddingTop: 30,
              paddingBottom: 30,
            }}
          >
            <div className="top">
              <div className="top_left" style={{ width: "30%", flexShrink: 1 }}>
                <div className="search">
                  <span className="description left" style={{ width: 90 }}>
                    Check No:
                  </span>
                  <Search
                    placeholder="Check No"
                    onSearch={this.search}
                    style={{ width: 290 }}
                    value={this.state.searchVal}
                    onChange={this.searchIpt.bind(this)}
                  />
                </div>
              </div>

              <div
                className="top_mid"
                style={{
                  width: "30%",
                  flexShrink: 2,
                  marginLeft: "5%",
                  marginRight: "5%",
                }}
              >
                <span className="description left" style={{ width: 85 }}>
                  Description:
                </span>
                <input
                  type="text"
                  placeholder="Description"
                  value={this.state.fstInput}
                  onChange={this.fstInputChange}
                  className="modal_input"
                  style={{ width: 290, marginLeft: 0 }}
                />
              </div>
              <div
                className="top_right"
                style={{ width: "30%", flexShrink: 1 }}
              >
                <div className="rangepick">
                  <span className="description mid_span" style={{ width: 105 }}>
                    Effective Date:
                  </span>
                  <RangePicker
                    style={{ width: 290 }}
                    ranges={{
                      Today: [moment(), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                    }}
                    showTime
                    disabledDate={this.handleData}
                    format={dateFormat}
                    onChange={this.rangeOnChange}
                    value={
                      this.state.from === undefined ||
                      this.state.to === undefined ||
                      this.state.from === "" ||
                      this.state.to === ""
                        ? null
                        : [
                            moment(this.state.from, dateFormat),
                            moment(this.state.to, dateFormat),
                          ]
                    }
                  />
                </div>
              </div>
            </div>

            <div className="order_mid">
              <div className="mid_left" style={{ width: "30%" }}>
                <span className="description mid_span" style={{ width: 90 }}>
                  Order No:
                </span>
                <input
                  type="text"
                  placeholder="Order No"
                  value={this.state.scdInput}
                  onChange={this.scdInputChange}
                  className="modal_input"
                  style={{ width: 290, marginLeft: 0 }}
                />
              </div>

              <div
                className="mid_right"
                style={{
                  width: "30%",
                  flexShrink: 2,
                  marginLeft: "5%",
                  marginRight: "5%",
                }}
              >
                <span className="description right" style={{ width: 85 }}>
                  Status:
                </span>
                <select
                  className="form_select"
                  style={{ marginRight: 0, width: 292 }}
                  value={this.state.fstSelect}
                  onChange={this.selectfst}
                >
                  <option value="">All</option>
                  <option value="1">Associated</option>
                  <option value="0">Not associated</option>
                  <option value="3">Had refund</option>
                </select>
              </div>

              <div className="top_mid" style={{ width: "30%" }}>
                <div className="rangepick">
                  <span className="description mid_span" style={{ width: 105 }}>
                    Operate time:
                  </span>
                  <RangePicker
                    style={{ width: 290 }}
                    ranges={{
                      Today: [moment(), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                    }}
                    showTime
                    disabledDate={this.handleData}
                    format={dateFormat}
                    onChange={this.rangeOnChange1}
                    value={
                      this.state.operate === undefined ||
                      this.state.operateto === undefined ||
                      this.state.operate === "" ||
                      this.state.operateto === ""
                        ? null
                        : [
                            moment(this.state.operate, dateFormat),
                            moment(this.state.operateto, dateFormat),
                          ]
                    }
                  />
                </div>
              </div>
            </div>

            <div
              className="bottom"
              style={{ justifyContent: "space-between", marginTop: 20 }}
            >
              <div className="mid_left" style={{ width: "30%" }}>
                <span className="description right" style={{ width: 90 }}>
                  Operator:
                </span>
                <input
                  type="text"
                  placeholder="Operator"
                  value={this.state.thdInput}
                  onChange={this.thdInputChange}
                  className="modal_input"
                  style={{ width: 290, marginLeft: 0 }}
                />
              </div>
              <div
                className="mid_left"
                style={{ width: "30%", marginLeft: "5%", marginRight: "5%" }}
              >
                {/* <span className="description right" style={{ width: 85 }}>
                  credit:
                </span>
                <input
                  type="text"
                  placeholder="credit"
                  value={this.state.creditInput}
                  onChange={this.creditChange}
                  className="modal_input"
                  style={{ width: 290, marginLeft: 0 }}
                /> */}
                <span className="description right" style={{ width: 85 }}>
                  uploader:
                </span>
                <input
                  type="text"
                  placeholder="uploader"
                  value={this.state.uploader}
                  onChange={(e) => {
                    this.setState({ uploader: e.target.value });
                  }}
                  className="modal_input"
                  style={{ width: 290, marginLeft: 0 }}
                />
              </div>
              <div
                className="mid_right"
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  width: "30%",
                }}
              >
                <span className="description mid_span" style={{ width: 105 }}>
                  upload time:
                </span>
                <RangePicker
                    style={{ width: 290 }}
                    ranges={{
                      Today: [moment(), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                    }}
                    showTime
                    disabledDate={this.handleData}
                    format={dateFormat}
                    onChange={this.rangeOnChange2}
                    value={
                      this.state.uploadStartTime === undefined ||
                      this.state.uploadEndTime === undefined ||
                      this.state.uploadStartTime === "" ||
                      this.state.uploadEndTime === ""
                        ? null
                        : [
                            moment(this.state.uploadStartTime, dateFormat),
                            moment(this.state.uploadEndTime, dateFormat),
                          ]
                    }
                  />
              </div>
             
            </div>

            <div
              className="bottom"
              style={{ justifyContent: "space-between", marginTop: 20 }}
            >
              <div className="mid_left" style={{ width: "30%" }}>
                <span className="description right" style={{ width: 90 }}>
                  Phone:
                </span>
                <input
                  type="text"
                  placeholder="phone"
                  value={this.state.mobile}
                  onChange={this.mobileInputChange}
                  className="modal_input"
                  style={{ width: 290, marginLeft: 0 }}
                />
              </div>
              <div
                className="mid_left"
                style={{ width: "30%", marginLeft: "5%", marginRight: "5%" }}
              >
                <span className="description right" style={{ width: 85 }}>
                Name:
                </span>
                <input
                  type="text"
                  placeholder="Name"
                  value={this.state.name}
                  onChange={(e) => {
                    this.setState({ name: e.target.value });
                  }}
                  className="modal_input"
                  style={{ width: 290, marginLeft: 0 }}
                />
              </div>
              <div
                className="mid_right"
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  width: "30%",
                }}
              >
                <span className="description mid_span" style={{ width: 105 }}>
                Repayment Amount:
                </span>
                <input
                  type="text"
                  placeholder="credit"
                  value={this.state.credit}
                  onChange={(e) => {
                    this.setState({ credit: e.target.value});
                  }}
                  className="modal_input"
                  style={{ width: 290, marginLeft: 0 }}
                />
              </div>
             
            </div>
            <div
                className="mid_right"
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  width: "30%",
                  marginTop:20
                }}
              >
                <span className="description mid_span" style={{ width: 90 }}>
                  Claim  status:
                </span>
                <select
                  className="form_select"
                  style={{ marginRight: 0, width: 292 }}
                  value={this.state.claimed}
                  onChange={this.selectClaim}
                >
                  <option value="">Claim status</option>
                  <option value="1">Claimed</option>
                  <option value="0">UnClaimed</option>
                </select>
            </div>


            
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                marginTop: 20,
              }}
            >
              <div
                className="top_mid inside_top"
                style={{
                  width: "15%",
                  marginRight: 0,
                  justifyContent: "flex-start",
                }}
                onClick={this.demo}
              >
                
              </div>

              <div
                className="top_mid inside_top"
                style={{
                  width: "15%",
                  marginRight: 0,
                  justifyContent: "flex-start",
                }}
                onClick={this.today}
              >
                
              </div>
              <div
                className="mid_right"
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  width: "30%",
                }}
              >
                 <Button type="primary" style={{width:170,marginRight:20,height:40,border:'none'}}  className="common_color" onClick={this.getTable.bind(this)}>Search</Button>
                    <Button type="primary" style={{width:170,height:40,border:'none'}} className="common_color_scd" onClick={this.reloadClick.bind(this)}>Refresh</Button>
              </div>
            </div>
          </div>
          </div>
          <div style={{backgroundColor:'#fff'}}>
              <div style={{textAlign:'right',paddingTop:20,paddingBottom:20,display:'flex',flexDirection:'row',justifyContent:'flex-end'}} >
              {/* <Button type="primary" className="common_color" style={{width:150,marginRight:20,height:40,border:'none'}} onClick={this.changeupload}>
              upload
              </Button> */}
              
             {/* <Button type="primary" style={{width:150,marginRight:20,height:40,backgroundColor:'#009688',border:'none'}} onClick={this.changeuploadtoday}>
              upload Today
              </Button>*/}

                {/* <Button type="primary" style={{width:150,marginRight:20,height:40,backgroundColor:'#009688',border:'none'}} onClick={this.downExcel}>
                    export
                </Button> */}
                <Button type="primary"  style={{width:150,marginRight:20,height:40,backgroundColor:this.state.isDisabled?'':'#009688',border:'none'}} onClick={this.downExcel} disabled={this.state.isDisabled}>{this.state.btnWord}</Button>

              </div>
            <Table columns={columns} dataSource={this.state.tableData} scroll={{x:1500}} loading={this.state.boolean} onChange={this.handleChange} pagination={false} />
            <Pagination
          showQuickJumper
          defaultCurrent={1} 
          current={this.state.current}
          total={this.state.total} 
          onChange={this.pageChange} 
          showTotal={showTotal}
          style={{textAlign:'right',marginTop:20}}
          pageSize={this.state.pageSize}
          showSizeChanger={true}
          onShowSizeChange={this.onShowSizeChange} //pagesize改变时回调函数
          pageSizeOptions={["10","50","100"]}   //设置pagesize区间
          />
          </div>
          
                        
        <Modal
          width='750px'
          title="Record"
          visible={this.state.scdvisible}
          onCancel={this.handleCancel}
          
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Cancel
            </Button>,
          ]}
          >
          <Table  columns={phoneColumns} dataSource={this.state.phoneTableData} onChange={this.phoneHandleChange} pagination={false} />
        </Modal>
        <Modal
          title="Claim"
          visible={this.state.thdvisible}
          onOk={this.handleReduceOk}
          onCancel={this.handleReduceCancel}
        >
          <div>
            <lable style={{ fontSize: 15, fontWeight: "bold" }}>
            Claim Order No:{" "}
            </lable>
            <Input
              style={{ width: '50%',marginLeft:'9%' }}
              value={this.state.claimOrderNo} 
              onChange={this.handleCollectorChange.bind(this)}
            ></Input>
          </div>
          <div style={{marginTop:20}}>
            <lable style={{ fontSize: 15, fontWeight: "bold",verticalAlign: 'top'}}>
            Claim remarks:{" "}
            </lable>
            <textarea style={{width:'50%',marginLeft:'11%',height:80,border:"1px solid #d9d9d9"}} value={this.state.selectedCollector} onChange={this.handleselectedCollectorChange.bind(this)}
            />
          </div>
          <div>
            <lable style={{ fontSize: 15, fontWeight: "bold" }}>
            Claim voucher:{" "}
            </lable>
            <div style={{width: '50%', marginLeft: '35%', height: 120}} >
            <Upload
                name="file"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action="/api/sysAd/upLoadFile"
                beforeUpload={beforeUpload}
                onChange={this.handleImageChange}
                // disabled={!this.state.canEdit}
              >
                {this.state.imgUrl ? (
                  <img
                    src={this.state.imgUrl}
                    alt="avatar"
                    style={{ width: "50%" }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </div>
          </div>

        </Modal>
        <Modal
          title="Rule"
          visible={this.state.rulevisible}
          onOk={this.phonehandleOk}
          width={1100}
          onCancel={this.handleCancel}>
        </Modal>
        <Modal
          title="upload"
          onCancel={this.handleCancel}
          visible={this.state.uploadvisible}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              cancel
            </Button>,
            <Button key="submit" type="primary" loading={this.state.scdafter} onClick={this.uploadhandleOk}>
              ok
            </Button>,
          ]}>
            <Upload {...props}
             accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,"
             disabled={this.state.isdisabled}>
              <Button type="primary" style={{width:120,marginRight:20,height:40,backgroundColor:'#009688',border:'none'}} onClick={this.changeupload}>
                <Icon type="upload" /> upload
              </Button>
            </Upload>
        </Modal>
        <Modal
          title="upload"
          onCancel={this.handleCancel}
          visible={this.state.uploadvisibletoday}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              cancel
            </Button>,
            <Button key="submit" type="primary" loading={this.state.scdafter} onClick={this.uploadhandleOkToday}>
              ok
            </Button>,
          ]}>
            <Upload {...props}
             accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,"
             disabled={this.state.isdisabled}>
              <Button type="primary" style={{width:120,marginRight:20,height:40,backgroundColor:'#009688',border:'none'}} onClick={this.changeuploadtoday}>
                <Icon type="upload" /> upload
              </Button>
            </Upload>
        </Modal>

        <Modal
          title="Cancel"
          visible={this.state.cancelVisible}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Cancel
            </Button>,
             <Button key="submit" type="primary"  onClick={this.cancelhandleOk}>
             Confirm
           </Button>,
          ]}
          >
          <input style={{display:'none'}} value={this.state.modifyId}/>
        <p>Are you sure to cancel the manual association?</p>
        </Modal>

        <Modal
          title="Associated"
          visible={this.state.associatedVisible}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Cancel
            </Button>,
             <Button key="submit" type="primary"  onClick={this.associatedhandleOk}>
             Confirm
           </Button>,
          ]}
          >
          <input style={{display:'none'}} value={this.state.associatedId}/>
          <div className="both_div">
                <span>orderNo:</span>
                <input value={this.state.debit_money} onChange={this.debitMoney} className="reduce_inpt"/> 
                </div>
        </Modal>
        </div>
      );
    }
  }
  export default withRouter(Bank)