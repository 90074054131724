/*eslint-disable*/
import { Button, DatePicker,message, Input, Pagination, Table, Tooltip,Modal } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React from 'react';
import './index.css';
import {withRouter} from 'react-router-dom'
import EventEmitter from '../../utils/events'
const DateFormat = 'YYYY-MM-DD'||undefined;
const { RangePicker } = DatePicker;
  const { Search } = Input;
  const dateFormat = 'YYYY-MM-DD'||undefined;
var getSelect = []
var getselect = []

  function showTotal(total) {
    return `Total ${total} items`;
  }
 class PendingCard extends React.Component {
    constructor(props){
      super(props);
      this.state={
        fouSelect:'',
        searchphone:'',
        searchVal:'',
        current:1,
        fstinpt:'',
        pageSize:10,
        modal_text:'',
        cellstatus:"",
        scdafter:false
      }
    }
        //页码改变时触发
        pageChange=(page)=>{
          this.setState({
            current: page
            },()=>{
              this.searchClick()
            })
        }


    handleCancel=()=>{
      this.setState({
        fstvisible: false,
        scdvisible: false,
        fstSelect:'',
        type: "",
        datepiker:"",
        cellstatus:"",
        scdextarea:"",
        collecrslt:"",
        scdafter:false,
        fstvisible:false
      });
    }
    //重置按钮
    reloadClick(e){
      this.setState({
        fouSelect:'',
        searchemail:'',
        searchname:'',
        searchphone:'',
        searchVal:'',
        searchacc:'',
        from:undefined,
        to:undefined,
        from1:undefined,
        to1:undefined,
        boolean:true,
        fiveSelect:''
      },()=>{
        this.searchClick()

      });
    }
    getselect=()=>{
        axios
        .post(`/api/contact/getService`,{
    
      })
      .then(res=>{
        getSelect=[]
        if(res.data.code === 200){
          for(let i=0;i<res.data.data.length;i++ ){
            
              if(i==0){
                getSelect.push(<option value=''>please select one</option> )
                }
                getSelect.push(       
                <option value={res.data.data[i].userName}>{res.data.data[i].userName}</option>
            )
            
          this.setState({
            getSelect:getSelect
          })
          }
        }else if(res.data.code ==800){
          this.props.history.push({ pathname: "/"});
        }else{
        }
      })
      }
    componentDidMount(){
        this.searchClick()
        this.getselect()
        // oncopy = document.selection.empty()
        // document.oncontextmenu = new Function("event.returnValue=false");
        // document.onselectstart = new Function("event.returnValue=false");
    }
    resultcoll=(e)=>{
        var value = e.target.value;
        this.setState({ collecrslt: value }) 
    }
    scdtext=(e)=>{
        var value = e.target.value;
        this.setState({ scdextarea: value }) 
    }
    handleOk=()=>{
        this.setState({
            scdafter:true
        })
        axios
        .post(`/api/customerService/remindBindCard`,{
            loanRecordId:this.state.thdmodalinpt,
            callStatus:this.state.cellstatus,
            remindResult:this.state.collecrslt,
            remark:this.state.scdextarea
      })
      .then(res=>{
        if(res.data.code === 200){
          this.setState({
            cellstatus:"",
            scdextarea:"",
            collecrslt:"",
            scdafter:false,
            fstvisible:false
          })
          this.searchClick()
        }else if(res.data.code ==800){
          this.props.history.push({ pathname: "/"});
        }else{
            this.setState({
                cellstatus:"",
                scdextarea:"",
                collecrslt:"",
                scdafter:false,
                fstvisible:false
              })
              this.searchClick()
              
        }
      })
    }
    rangeOnChange=(dates, dateStrings)=>{
        this.setState({ from: dateStrings[0],to:dateStrings[1] })
      }
      rangeOnChange1=(dates, dateStrings)=>{
        this.setState({ from1: dateStrings[0],to1:dateStrings[1] })
      }
    text(record){
      window.location='/#/details/index?id='+record.loanRecordId+'&uid='+record.uid
      let test = [{path: '/details/index',key: `/details/index/${record.loanRecordId}/${record.uid}`,number: `${record.loanRecordId}/${record.uid}`}]
      EventEmitter.trigger('btn', test[0])
      EventEmitter.trigger('idanduid', test[0].number)
    }
    statuscall=(e)=>{
        var value = e.target.value;
        if(value==""){
            return false
        }
        this.setState({ cellstatus: value },()=>{
        if(this.state.cellstatus == 1){
            this.setState({
                collecrslt:'1'
            })
        }else if(this.state.cellstatus == 2){
            this.setState({
                collecrslt:'2'
            })
        }else if(this.state.cellstatus == ""){
            this.setState({
                collecrslt:'3'
            })
        }

        axios
          .post(`/api/customerService/getRemindDetail`,{
  
            code:value
        })
        .then(res=>{
          getselect=[]
          if(res.data.code === 200){
            for(let i=0;i<res.data.data.length;i++ ){
                if(i==0){
                    getselect.push(<option value=''>Please select one</option> )
                  }
                getselect.push(       
                  <option value={res.data.data[i].number}>{res.data.data[i].name}</option>
              )
            this.setState({
              getselect:getselect
            })
            }
          }else if(res.data.code ==800){
            this.props.history.push({ pathname: "/"});
          }else{
          }
        })
        })
      
    }
    remind(record){
        console.log(record)
       this.setState({
        fstvisible:true,
        thdmodalinpt:record.loanRecordId
       })
    }
 
    selectFst(e){
      var value = e.target.value;
      this.setState({
        fstSelect:value,
      });
    }

    selectFou(e){
      var value = e.target.value;
      this.setState({
        fouSelect:value,
      });
    }
    selectFive(e){
        var value = e.target.value;
        this.setState({
          fiveSelect:value,
        });
      }
      selectFiv(e){
        var value = e.target.value;
        this.setState({
          fivSelect:value,
        });
      }
  //输入框
     searchIpt(e) {
        var value = e.target.value;
        this.setState({searchVal: value.replace(/\s+/g,"")})
    }

    //手机号输入框
    searchphoneIpt(e) {
        var value = e.target.value;
        this.setState({searchphone: value.replace(/\s+/g,"")})
    }


          //搜索
    searchClick = () => {
      this.setState({
        boolean:true
      })
        axios
            .post(`/api/customerService/getPendingBindCard`, {
                pageSize: this.state.pageSize,
                current: this.state.current,
                status:this.state.fouSelect,
                beginTime:this.state.from,
                endTime:this.state.to,
                remindBeginTime:this.state.from1,
                remindEndTime:this.state.to1,
                operateName:this.state.fiveSelect,
                phone:this.state.searchphone,
                orderNo:this.state.searchVal,
                pendingTime:this.state.fivSelect
            })
            .then(res => {
                if (res.data.code === 200) {

                    const data1 = [];
                    const data = res.data.data.resultDate

                    for (let i = 0; i < data.length; i++) {
                        data[i].key = i;
                        data1.push(data[i]);
                    }
                    this.setState({
                        tableData: data1,
                        total: res.data.data.page.total,
                        boolean: false
                    })
                } else if (res.data.code == 800) {
                    this.props.history.push({pathname: "/"});
                } else {
                }
            })
    }
    render() {

      const columns = [
        {
          title: 'Order No',
          dataIndex: 'loanRecordNo',
          key: 'loanRecordNo',
        },
        
        {
          title: 'Full name',
          dataIndex: 'userName',
          key: 'userName',
        },
        {
          title: 'Phone',
          dataIndex: 'mobile',
          key: 'mobile',
        },
        {
            title: 'Card No',
            dataIndex: 'bankCardNo',
            key: 'bankCardNo',
          },
        {
          title: 'Amount',
          dataIndex: 'balance',
          key: 'balance',
          render: (value)=> {
            var num = value/100;
            num = num + '';
            var str = "";
            for(var i=num.length- 1,j=1;i>=0;i--,j++){
                if(j%3==0 && i!=0){//每隔三位加逗号，过滤正好在第一个数字的情况
                    str+=num[i]+",";//加千分位逗号
                    continue;
                }
                str+=num[i];//倒着累加数字
            }
            return str.split('').reverse().join("");
        }
        },
        {
          title: 'Pending time',
          dataIndex: 'pendingTime',
          key: 'pendingTime',
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          render : (value)=>{
            if(value == 1){
                return  'Reminded';
            }
            if(value==2){
                return 'Not remind';
            } 
        }
        },
        {
          title: 'Call status',
          dataIndex: 'callStatus',
          key: 'callStatus',
          render : (value)=>{
            if(value == 1){
                return  'Answered';
            }
            if(value==2){
                return 'No answer';
            } 
            if(value==3){
                return 'other';
            }
        }
        },
        {
          title: 'Remind result',
          dataIndex: 'remindResults',
          key: 'remindResults',

        },
        {
            title: 'Remark',
            dataIndex: 'remark',
            width:200,
            key: 'remark',
            render: (record) => {
                return (
                  <Tooltip title={record}>
                    <div className="twoEllipsis">
                      {record}
                    </div>
                  </Tooltip>
                )
            }
          },
          {
            title: 'Operator',
            dataIndex: 'operateName',
            key: 'operateName',
          },
        {
          title: 'Operation',
          dataIndex: 'operating',
          key: 'operating',
          id:'id',
          loanRecordId:'loanRecordId',
          uid:'uid',
          width:140,
          // fixed:'right',
          render: (text, record, index) => (<span style={{ display: 'flex' , flexDirection: 'row'}}> 
            <div>
                <Button type="primary"   style={{width:60,margin:2}} className="detailsbutton common_color" size="small"  onClick={()=>this.remind(record)}>
                    Remind
                </Button>
                <Button type="primary"   style={{width:60,margin:2}} className="detailsbutton common_color" size="small"  onClick={()=>this.text(record)}>
                    Details
                </Button>
            </div>
                
            </span>)
        },
      ];
      return (
        <div className="credit_order" id="onCopy">
          <div className="myorder_content">
            <div className="flutter">
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <div className="mid_left" style={{width: '20%'}}>
                        <Search
                            placeholder="Order No"
                            onSearch={value => console.log(value)}
                            style={{width: "100%"}}    
                            value={this.state.searchVal}
                            onChange={this.searchIpt.bind(this)}
                        />
                    </div>
                    <div className="mid_left" style={{width: '20%', marginLeft: '5%'}}>
                        <Search
                            placeholder="Phone"
                            onSearch={value => console.log(value)}
                            style={{width: "100%"}}
                            value={this.state.searchphone}
                            onChange={this.searchphoneIpt.bind(this)}
                        />
                    </div>
                    <div className="mid_left" style={{width:'20%',marginLeft: '5%'}}>
                        <RangePicker
                            style={{width: "100%"}}
                            ranges={{
                            Today: [moment(), moment()],
                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                            }}
                            placeholder={['credit time', 'credit time']}
                            showTime
                            format={dateFormat}
                            onChange={this.rangeOnChange}
                            value={this.state.from===undefined||this.state.to===undefined||this.state.from===""||this.state.to===""?null:[moment(this.state.from, dateFormat), moment(this.state.to, dateFormat)]}
                            />                    
                    </div>
                    
                    <div className="top_mid" style={{width:'20%',flexShrink:2,marginLeft:'5%',}}>
                        <div className="rangepick" >
                        <RangePicker
                            style={{width: "100%"}}
                            ranges={{
                            Today: [moment(), moment()],
                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                            }}
                            placeholder={['remind time', 'remind time']}
                            showTime
                            format={dateFormat}
                            onChange={this.rangeOnChange1}
                            value={this.state.from1===undefined||this.state.to1===undefined||this.state.from1===""||this.state.to1===""?null:[moment(this.state.from1, dateFormat), moment(this.state.to1, dateFormat)]}
                            />                        
                        </div>
                </div>
                </div>
                <div className="top" style={{justifyContent:'flex-start',marginTop:20}}>
                  
        
                    <div className="mid_left" style={{width: '20%'}}>
                        <select className="abnormal_form_select" value={this.state.fiveSelect} style={{width: "100%"}} onChange={this.selectFive.bind(this)}>
                            {getSelect}
                        </select>
                      
                    </div>
            
                    <div className="top_right" style={{width:'20%',flexShrink:1,marginLeft: '5%'}}>
                        <select className="abnormal_form_select" value={this.state.fouSelect} style={{width: "100%"}} onChange={this.selectFou.bind(this)}>
                            <option value="1">Reminded</option>
                            <option value="2">Not remind</option>
                            <option value="">All</option>
                        </select>
                    </div>
                    <div className="top_right" style={{width:'20%',flexShrink:1,marginLeft: '5%'}}>
                        <input className="abnormal_form_select" type='number' placeholder="pending times"  value={this.state.fivSelect} style={{width: "100%"}} onChange={this.selectFiv.bind(this)}/>  
                    </div>
                    <div className="bottom" style={{width:'20%',flexShrink:1,marginLeft: '5%',marginTop:0}}>
                    <Button type="primary" style={{width:170,marginRight:20,height:40,border:'none'}}  className="common_color" onClick={this.searchClick.bind(this)}>Search</Button>
                    <Button type="primary" style={{width:170,height:40,border:'none'}} className="common_color_scd" onClick={this.reloadClick.bind(this)}>Refresh</Button>
                    </div> 
                </div>
                    
                   
        </div>
    </div>
          <Table columns={columns}  dataSource={this.state.tableData}  loading={this.state.boolean} onChange={this.handleChange} pagination={false} />
            
        
        <Pagination
        
          // showQuickJumper
          defaultCurrent={1} 
          current={this.state.current}
          total={this.state.total} 
          onChange={this.pageChange} 
          showTotal={showTotal}
          style={{textAlign:'right',marginTop:20}}
          pageSize={this.state.pageSize}
          />
          <Modal
          visible={this.state.fstvisible}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              cancel
            </Button>,
            <Button key="submit" type="primary" loading={this.state.scdafter} onClick={this.handleOk}>
              ok
            </Button>,
          ]}
          onCancel={this.handleCancel}
          
        >
        <input style={{display:'none'}} value={this.state.thdmodalinpt}/>
        <div className='collection_div' style={{flexDirection:"column"}}> 
            <div className='collection_div_child' style={{width:'90%'}}>
                <span>Call Status:</span>
                <select value={this.state.cellstatus} style={{width:'75%'}} className='collection_input' onChange={this.statuscall}>
                <option value="" ></option>
                    <option value="1" >Answered</option>
                    <option value="2" >No answered</option>
                    <option value="3" >Other</option>
                </select>
            </div>
            <div className='collection_div_child'  style={{width:'90%'}}>
                <span>Remind Result:</span>
                <select value={this.state.collecrslt} id='urgeResult' style={{width:'75%'}} className='collection_input' onChange={this.resultcoll}>
                    {getselect}
                </select>
            </div>
            <div className='collection_div_child collection_remark' style={{width:'90%'}}>
                <span>remark:</span>
                <textarea value={this.state.scdextarea} className='collection_input collection_remark'style={{width:'75%'}} onChange={this.scdtext}></textarea>
            </div>
        </div>
        </Modal>
        </div>
      );
    }
  }
  export default withRouter(PendingCard)