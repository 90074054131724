/*eslint-disable*/
import React from "react";
import { Button, Table, Modal, Pagination, message, Tooltip } from "antd";
import "./index.css";
import axios from "axios";
import moment from "moment";

function showTotal(total) {
  return `Total ${total} items`;
}

export default class Tab2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      current: 1,
      total: 1,
      pageSize: 10,
      boolean: true,
      refundvisible: false,
      refundData: [],
      refundBoolean: true,
      orderNo: "",
    };
  }
  componentDidMount() {
    this.onloadTable();
    this.getOrderNo();
  }
  getOrderNo() {
    let id = this.props.id;
    let uid = this.props.uid;
    axios
      .post(`/api/loan/credit/getLoanOrderInformation`, {
        id: id,
        uid: uid,
      })

      .then((res) => {
        if (res.data.code === 200) {
          if (JSON.stringify(res.data.data) == "{}") {
            return false;
          } else {
            if (res.data.data.record) {
              let data = res.data.data.record;
              this.setState({
                orderNo: data.indentNo,
              });
            } else {
              return false;
            }
          }
        }
      });
  }
  onloadTable = () => {
    axios
      .post(`/api/loan/credit/getUserLoan`, {
        uid: this.props.uid,
        status: 0,
        pageSize: 10,
        current: this.state.current,
      })
      .then((res) => {
        let tableData = [];
        if (res.data.code === 200) {
          const data1 = [];
          const data = res.data.data.resultDate;
          if (data == []) {
            data1.push({
              key: "",
              indentNo: "",
              statusDescription: "",
              balance: "",
              cycle: "",
              arriveTime: "",
              shouldbackTime: "",
              lastbackTime: "",
              shouldbackFee: "",
              actualbackAmt: "",
              reduceAmt: "",
              coupon_amount: "",
              leftbackAmt: "",
              reviewers: "",
              auditUserTime: "",
              auditDetail: "",
            });
            return;
          } else {
            for (let i = 0; i < data.length; i++) {
              let time1 = data[i].arrive_time;
              let a =
                new Date(time1).getTime() +
                new Date().getTimezoneOffset() * 60 * 1000 -
                0 * 60 * 60 * 1000;
              let times1 = moment(new Date(a)).format("YYYY-MM-DD HH:mm:ss");
              if (data[i].arrive_time == null) {
                times1 = "";
              } else {
                times1 = times1;
              }
              let time2 = data[i].shouldback_time;
              let b =
                new Date(time2).getTime() +
                new Date().getTimezoneOffset() * 60 * 1000 -
                0 * 60 * 60 * 1000;
              let times2 = moment(new Date(b)).format("YYYY-MM-DD HH:mm:ss");
              if (data[i].shouldback_time == null) {
                times2 = "";
              } else {
                times2 = times2;
              }
              let time3 = data[i].lastback_time;
              let c =
                new Date(time3).getTime() +
                new Date().getTimezoneOffset() * 60 * 1000 -
                0 * 60 * 60 * 1000;
              let times3 = moment(new Date(c)).format("YYYY-MM-DD HH:mm:ss");
              if (data[i].lastback_time == null) {
                times3 = "";
              } else {
                times3 = times3;
              }
              let times4 = data[i].audit_user_time;
              if (times4 == null) {
                times4 = "";
              } else {
                let d =
                  new Date(times4).getTime() +
                  new Date().getTimezoneOffset() * 60 * 1000 -
                  0 * 60 * 60 * 1000;
                times4 = moment(new Date(d)).format("YYYY-MM-DD HH:mm:ss");
              }
              let createdTime_val =
                new Date(data[i].created_time).getTime() +
                new Date().getTimezoneOffset() * 60 * 1000 -
                0 * 60 * 60 * 1000;
              let createdTime = moment(new Date(createdTime_val)).format(
                "YYYY-MM-DD HH:mm:ss"
              );
              if (data[i].created_time == null) {
                createdTime = "";
              } else {
                createdTime = createdTime;
              }
              let leftamt =
                (data[i].shouldback_fee -
                  data[i].actualback_amt -
                  data[i].reduce_amt) /
                100;
              data1.push({
                key: i,
                indentNo: data[i].indent_no,
                statusDescription: data[i].statusDescription,
                balance: data[i].balance / 100,
                cycle: data[i].cycle,
                arriveTime: times1,
                shouldbackTime: times2,
                lastbackTime: times3,
                shouldbackFee: data[i].shouldback_fee / 100,
                actualbackAmt: data[i].actualback_amt / 100,
                leftbackAmt: leftamt,
                reduceAmt: data[i].reduce_amt / 100,
                coupon_amount: data[i].coupons_fee / 100,
                reviewers: data[i].login_name,
                auditUserTime: times4,
                auditDetail: data[i].audit_detail,
                createdTime: createdTime,
                refundIndentNo: data[i].refundIndentNo,
              });
              // console.log('aaaaa',data1)
            }
          }
          console.log("total", res.data.data.page.total);
          this.setState({
            tableData: data1,
            total: res.data.data.page.total,
            boolean: false,
          });
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
        }
      });
  };
  handleChange = (pagination, filters, sorter) => {
    // console.log('Various parameters', pagination, filters, sorter);
    this.setState({});
  };
  pageChange = (page) => {
    this.setState(
      {
        current: page,
      },
      () => {
        this.onloadTable();
      }
    );
  };
  showRefund = (record) => {
    const id = record.indentNo;
    this.refundTable(id);
    this.setState({
      refundVisible: true,
    });
  };

  handleRefundCancel = () => {
    this.setState({
      refundVisible: false,
    });
  };
  refundTable = (id) => {
    axios
      .post(`/api/refund/findRefundRecord`, {
        uid: this.props.uid,
        indentNo: id,
      })
      .then((res) => {
        if (res.data.code === 200) {
          const data1 = [];
          const data = res.data.data;

          for (let i = 0; i < data.length; i++) {
            data[i].key = i;
            data1.push(data[i]);
          }

          this.setState({
            refundData: data1,
            refundBoolean: false,
          });
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
          // message.error(res.data.msg);
        }
      });
  };
  render() {
    const columns = [
      {
        title: "Order No",
        dataIndex: "indentNo",
        key: "indentNo",
      },
      {
        title: "Order Status",
        dataIndex: "statusDescription",
        key: "statusDescription",
        renderer: function (value) {
          if (value == "Reject by Manual Review") {
            return "<font color='red'>" + value;
          }
          if (
            value == "Normal Repayment" ||
            value == "Overdue Repayment" ||
            value == "Early Repayment"
          ) {
            return "<font color='green'>" + value;
          }

          if (value == "Overdue Status" || value == "Bad Debt") {
            return "<font color='orange'>" + value;
          } else {
            return "<font color='black'>" + value;
          }
        },
      },
      {
        title: "Loan Amount",
        dataIndex: "balance",
        key: "balance",
      },
      {
        title: "Loan Term",
        dataIndex: "cycle",
        key: "cycle",
      },
      {
        title: "order create time",
        dataIndex: "createdTime",
        key: "createdTime",
      },

      {
        title: "Date Of Arrival",
        dataIndex: "arriveTime",
        key: "arriveTime",
      },
      {
        title: "Repayment Time",
        dataIndex: "shouldbackTime",
        key: "shouldbackTime",
      },
      {
        title: "Amount Due",
        dataIndex: "shouldbackFee",
        key: "shouldbackFee",
      },
      {
        title: "Actual Repayment Date",
        dataIndex: "lastbackTime",
        key: "lastbackTime",
      },
      {
        title: "Repaid Amount",
        dataIndex: "actualbackAmt",
        key: "actualbackAmt",
      },
      {
        title: "Remain Amount",
        dataIndex: "leftbackAmt",
        key: "leftbackAmt",
        // renderer: function (value, record) {
        //     var amt = record.shouldbackFee - record.actualbackAmt
        //     return amt / 100;
        // }
      },
      {
        title: "Reduce Amount",
        dataIndex: "reduceAmt",
        key: "reduceAmt",
      },
      {
        title: "Coupon Amount",
        dataIndex: "coupon_amount",
        key: "coupon_amount",
      },
      {
        title: "refund",
        dataIndex: "refund",
        key: "refund",
        render: (text, record, index) => (
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <Button
              type="primary"
              disabled={record.refundIndentNo ? false : true}
              className="reassignbutton"
              size="small"
              onClick={() => this.showRefund(record)}
            >
              detail
            </Button>
          </span>
        ),
      },
    ];
    const refundColumns = [
      {
        title: "refund No",
        dataIndex: "indentNo",
        key: "indentNo",
      },
      {
        title: "Order No",
        dataIndex: "loanRecordNo",
        key: "loanRecordNo",
      },
      {
        title: "Target No",
        dataIndex: "repayFlowNo",
        key: "repayFlowNo",
      },
      {
        title: "Refund amount",
        dataIndex: "refundAmount",
        key: "refundAmount",
        render: (value) => {
          var num = value / 100;
          num = num + "";
          if (num.indexOf(".") != -1) {
            return num;
          } else {
            var str = "";
            for (var i = num.length - 1, j = 1; i >= 0; i--, j++) {
              if (j % 3 == 0 && i != 0) {
                //每隔三位加逗号，过滤正好在第一个数字的情况
                str += num[i] + ","; //加千分位逗号
                continue;
              }
              str += num[i]; //倒着累加数字
            }
            return str.split("").reverse().join("");
          }
        },
      },
      {
        title: "Remark",
        dataIndex: "refundReason",
        key: "refundReason",
        render: (record) => {
          return (
            <Tooltip title={record}>
              <div className="twoEllipsis">{record}</div>
            </Tooltip>
          );
        },
      },
      {
        title: "status",
        dataIndex: "status",
        key: "status",
        render: (value) => {
          if (value == 1) {
            return "Refunding";
          } else if (value == 2) {
            return "Refund successful";
          } else if (value == 3) {
            return "Refund failed";
          } else if (value == 4) {
            return "Refund exception";
          }
        },
      },
      {
        title: "operator",
        dataIndex: "operator",
        key: "operator",
      },
      {
        title: "refund time",
        dataIndex: "operateTime",
        key: "operateTime",
        render: (value) => {
          let timeaudit =
            new Date(value).getTime() +
            new Date().getTimezoneOffset() * 60 * 1000 -
            0 * 60 * 60 * 1000;
          let auditimes = moment(new Date(timeaudit)).format("YYYY-MM-DD");
          if (value == null) {
            return (auditimes = "");
          } else {
            return (auditimes = auditimes);
          }
        },
      },
      {
        title: "evidence",
        dataIndex: "evidence",
        key: "evidence",
      },
    ];
    return (
      <div className="order_infor">
        <div className="bottom_div">
          <div className="details_tab1_top">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {this.state.orderNo && (
                <p className="details_tab1_top_p">
                  Historical Loan Order (Order number：{this.state.orderNo}）
                </p>
              )}
            </div>

            <Table
              columns={columns}
              dataSource={this.state.tableData}
              loading={this.state.boolean}
              onChange={this.handleChange}
              pagination={false}
            />
            <Pagination
              // showQuickJumper
              defaultCurrent={1}
              current={this.state.current}
              total={this.state.total}
              onChange={this.pageChange}
              showTotal={showTotal}
              style={{ textAlign: "right", marginTop: 20 }}
              pageSize={this.state.pageSize}
              // showSizeChanger={true}
            />
          </div>
        </div>
        <Modal
          title="refund detail"
          visible={this.state.refundVisible}
          onCancel={this.handleRefundCancel}
          width={800}
          footer={[]}
        >
          <Table
            columns={refundColumns}
            dataSource={this.state.refundData}
            loading={this.state.refundBoolean}
            pagination={false}
          />
        </Modal>
      </div>
    );
  }
}
