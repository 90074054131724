/*eslint-disable*/
import React from 'react';
import { Input,Button,Table,Modal,Pagination,DatePicker,Tooltip,message } from 'antd';
import moment from 'moment';
import axios from 'axios'
import './index.css';
import $ from 'jquery';
import {withRouter} from 'react-router-dom'
import EventEmitter from '../../../utils/events'

function showTotal(total) {
  return `Total ${total} items`;
}
function formatMoney(s, type){
  if (/[^0-9\.]/.test(s))
    return "0";
  if (s == null || s == "null" || s == "")
    return "0";
  s = s.toString().replace(/^(\d*)$/, "$1.");
  s = (s + "00").replace(/(\d*\.\d\d)\d*/, "$1");
  s = s.replace(".", ",");
  var re = /(\d)(\d{3},)/;
  while (re.test(s))
    s = s.replace(re, "$1,$2");
  s = s.replace(/,(\d\d)$/, ".$1");
  if (type == 0) {
    var a = s.split(".");
    if (a[1] == "00") {
      s = a[0];
    }
  }
  s = s.replace(".", ",");
  return s;
}
var getSelect = []
var collector = []

const { RangePicker } = DatePicker;
  const { Search } = Input;
  const dateFormat = 'YYYY-MM-DD'||undefined;
 class ReceivingHistorical extends  React.Component {
    constructor(props) {
        super(props);
        this.state = {
            from:'',
            to:'',
            current:1,
            pageSize:10,
            isshow:true,
            ishide:false,
            boolean:true,
            topboolean:true,
            id:'',
          };
    }
      showtable=()=>{
        let starttime = moment(this.state.from).format("YYYY-MM-DD")
        let endtime = moment(this.state.to).format("YYYY-MM-DD")
  
        this.setState({
          isshow:false,
          ishide:true
        })
        axios
        // .post(`/collectionData/getCollectionHistoryDataTotal`,{
          .post(`/api/collectionData/getCollectionHistoryDataTotal`,{
            overdueGrade:this.state.fstSelect,
            collectionUserid:this.state.id,
            beginTime: starttime,
            endTime: endtime,
            type:1
        })
        .then(res=>{
          if(res.data.code === 200){
          const  data1 = []
            let data = res.data.data.resultDate
            if(data.length<1){
              this.setState({
                topboolean:false
              })
            }else{
              for(let i=0; i<data.length;i++){
                data1.push({
                  key: i,
                  Totalallocateorder:data[i].totalCountSum,
                  Totalallocateamount: data[i].totalAmountSum/100,
                  Totaltransferorder:data[i].turnoverVolumeCountSum,
                  Totaltransferamount: data[i].turnoverAmountSum/100,
                  totalrepaymentordfer: data[i].totalBillCollectionSum,
                  totalrepaymentamount: data[i].totalAmountDueSum/100,
                  amountRecovery: data[i].paymentRecoveryRateSum,
                  coveryrate:data[i].orderNumberRecoveryRateSum,
                  id:data[i].id,
                  totalExtendOrder:data[i].totalExtendOrder,
                  totalExtendAmount:data[i].totalExtendAmount/100
                });
              }
            }
            this.setState({
              totalData:data1,
              topboolean:false
            })  
    
          }
          else if(res.data.code ==800){
            this.props.history.push({ pathname: "/"});
          }else{
    
          }
        })
      }
      text=(record)=>{
        window.location='/#/collection/getCollectionWorkHistory/personaudit?id='+record.id
        let test = [{path: '/collection/getCollectionWorkHistory/personaudit',key: `/collection/getCollectionWorkHistory/personaudit/${record.id}`,number: `${record.id}`}]
        EventEmitter.trigger('btn', test[0])
        EventEmitter.trigger('idanduid', test[0].number)
        localStorage.id = record.id
      }
      collector =(params)=>{
        console.log('aaaaaaaaaaaaaa',params)
        var va = params.grade
          if (va==1){
              return <span style={{color:'black'}}>S1-Old</span>
          }else if (va==2){
              return <span style={{color:'black'}}>S2-Old</span>
          }else if (va==3){
              return <span style={{color:'black'}}>M1-Old</span>
          }else if (va==4){
              return <span style={{color:'black'}}>M2</span>
          }else if (va==5){
              return <span style={{color:'black'}}>M3</span>
          }else if (va==6){
              return <span style={{color:'black'}}>M4</span>
          }else if (va==11){
              return <span style={{color:'black'}}>S1-New</span>
          }else if (va==12){
              return <span style={{color:'black'}}>S2-New</span>
          }else if (va==13){
              return <span style={{color:'black'}}>M1-New</span>
          }else {
              return <span style={{color:'black'}}></span>
          }
        }
        amount =(params)=>{
          var num1 = params.Allocateamount
          return  formatMoney(num1,0);
        }
        amount1 =(params)=>{
          var num1 = params.Totalamount
          return  formatMoney(num1,0);
        }
        amount2 =(params)=>{
          var num1 = params.repaymentamount
          return  formatMoney(num1,0);
        }
        amount3 =(params)=>{
          var num1 = params.Totalallocateamount
          return  formatMoney(num1,0);
        }
        amount4 =(params)=>{
          var num1 = params.Totaltransferamount
          return  formatMoney(num1,0);
        }
        amount5 =(params)=>{
          var num1 = params.totalrepaymentamount
          return  formatMoney(num1,0);
        }
      componentDidMount() {
        this.setState({
          from:moment().startOf('month'),
          to: moment()
        },()=>{
          this.onloadTable()
        })
        this.select()
      }

     select=()=>{
      axios
      // .post(`/remindData/getCollectionUsers`,{
        .post(`/api/remindData/getCollectionUsers`,{
      })
      .then(res=>{
        if(res.data.code === 200){
          collector=[]
        let data = res.data.data.resultDate
        for(let i=0;i<data.length;i++ ){
          collector.push(       
            <option data-userId={data[i].id} value={data[i].userName}></option>
        )
        // console.log('select',collector)
        this.setState({
          collector:collector
        })
        }
        }else if(res.data.code ==800){
          this.props.history.push({ pathname: "/"});
        }
        else{
        }
      })
      
    }
      onloadTable=()=>{
        console.log('aaaaaaa',this.state.from)
    
        let starttime = moment(this.state.from).format("YYYY-MM-DD")
        let endtime = moment(this.state.to).format("YYYY-MM-DD")
        // console.log(starttime)
        axios
        // .post(`/collectionData/getCollectionHistoryData`,{
          .post(`/api/collectionData/getCollectionHistoryData`,{
            pageSize: this.state.pageSize,
            current: this.state.current,
            beginTime: starttime,
            endTime: endtime,
            type:1,
        })
        .then(res=>{
          if(res.data.code === 200){
          const  data1 = []
            let data = res.data.data.resultDate
            if(data.length<1){
              this.setState({
                boolean:false
              })
            }else{
              for(let i=0; i<data.length;i++){
                data1.push({
                  key: i,
                  Collector:data[i].userName,
                  grade: data[i].overdueGrade,
                  Allocateorder:data[i].totalCount,
                  Allocateamount: data[i].totalAmount/100,
                  Totalorder: data[i].turnoverVolumeCount,
                  Totalamount: data[i].turnoverAmount/100,
                  Repaymentorder: data[i].totalBillCollection,
                  repaymentamount:data[i].totalAmountDue/100,
                  Recoveryamount:data[i].paymentRecoveryRate,
                  Recoveryorder:data[i].orderNumberRecoveryRate,
                  id:data[i].id,
                  uid:data[i].uid,
                  ontotalExtendOrder:data[i].totalExtendOrder,
                  ontotalExtendAmount:data[i].totalExtendAmount/100
                });
              }
            }
            this.setState({
              tableData:data1,
              length:res.data.data.page.total,
              boolean:false
            })  
    
          }
          else if(res.data.code ==800){
            this.props.history.push({ pathname: "/"});
          }else{
    
          }
        })
      }
      rangeOnChange=(dates, dateStrings)=>{
        this.setState({ from: dateStrings[0],to:dateStrings[1] })
      }
        pageChange=(page)=> {   
          this.setState({
            current: page
            },()=>{
              this.searchClick()
            })
          }
        onShowSizeChange=(current, pageSize)=>{
            this.setState({
              pageSize: pageSize
            },()=>{
              this.onloadTable()
            })
        }
    reloadClick(e){
      this.setState({
        fstSelect:'',
        scdSelect:'',
        fouSelect:'',
        modalscdSelect:'',
        from:undefined,
        to:undefined,
        boolean:true,
        topboolean:true,
        id:''
      },()=>{
        this.showtable()
        this.onloadTable()
      });
    }
    searchClick=()=>{

      let starttime = moment(this.state.from).format("YYYY-MM-DD")
      let endtime = moment(this.state.to).format("YYYY-MM-DD")
      // console.log(starttime)
      this.setState({
        boolean:true,
        topboolean:true
      })
      axios
      // .post(`/collectionData/getCollectionHistoryData`,{
        .post(`/api/collectionData/getCollectionHistoryData`,{
          pageSize: this.state.pageSize,
          current: this.state.current,
          beginTime: starttime,
          endTime: endtime,
          overdueGrade:this.state.fstSelect,
          collectionUserid:this.state.id,
          type:2,
      })
      .then(res=>{
        if(res.data.code === 200){
        const  data1 = []
          let data = res.data.data.resultDate
          for(let i=0; i<data.length;i++){
            data1.push({
              key: i,
              Collector:data[i].userName,
              grade: data[i].overdueGrade,
              Allocateorder:data[i].totalCount,
              Allocateamount: data[i].totalAmount/100,
              Totalorder: data[i].turnoverVolumeCount,
              Totalamount: data[i].turnoverAmount/100,
              Repaymentorder: data[i].totalBillCollection,
              repaymentamount:data[i].totalAmountDue/100,
              Recoveryamount:data[i].paymentRecoveryRate,
              Recoveryorder:data[i].orderNumberRecoveryRate,
              id:data[i].id,
              uid:data[i].uid,
              ontotalExtendOrder:data[i].totalExtendOrder,
              ontotalExtendAmount:data[i].totalExtendAmount/100
            });
          }
          console.log(data1)
          this.setState({
           
            tableData:data1,
            length:res.data.data.page.total,
            boolean:false
          })  
  
        }
        else if(res.data.code ==800){
          this.props.history.push({ pathname: "/"});
        }else{
  
        }
      })
      axios
      // .post(`/collectionData/getCollectionHistoryDataTotal`,{
        .post(`/api/collectionData/getCollectionHistoryDataTotal`,{
          overdueGrade:this.state.fstSelect,
          collectionUserid:this.state.id,
          beginTime: starttime,
          endTime: endtime,
          type:1
      })
      .then(res=>{
        if(res.data.code === 200){
        const  data1 = []
          let data = res.data.data.resultDate
          for(let i=0; i<data.length;i++){
            data1.push({
              key: i,
              Totalallocateorder:data[i].totalCountSum,
              Totalallocateamount: data[i].totalAmountSum/100,
              Totaltransferorder:data[i].turnoverVolumeCountSum,
              Totaltransferamount: data[i].turnoverAmountSum/100,
              totalrepaymentordfer: data[i].totalBillCollectionSum,
              totalrepaymentamount: data[i].totalAmountDueSum/100,
              amountRecovery: data[i].paymentRecoveryRateSum,
              coveryrate:data[i].orderNumberRecoveryRateSum,
              id:data[i].id,
              totalExtendOrder:data[i].totalExtendOrder,
              totalExtendAmount:data[i].totalExtendAmount/100
            });
          }
          console.log(data1)
          this.setState({
            totalData:data1,
            topboolean:false
          })  
  
        }
        else if(res.data.code ==800){
          this.props.history.push({ pathname: "/"});
        }else{
  
        }
      })
    }
    selectFst=(e)=>{
      var value = e.target.value;
      this.setState({
        fstSelect:value,
      });
    }
      selectscd(e){
        var value = e.target.value;
        var userInfo = $("#ipt").val();
        var getUserId = $("option[value='" + userInfo + "']").data("userid");
        $("input[name='userId']").val(getUserId);
        console.log(getUserId)
        this.setState({
          scdSelect:value,
          id:getUserId
        });
      }
 
    render() {
      
      const botcolumns = [
        {
          title: 'Total Allocate Order',
          dataIndex: 'Totalallocateorder',
          key: 'Totalallocateorder',
        },
        {
          title: 'Total Allocate Amount',
          dataIndex: 'Totalallocateamount',
          key: 'Totalallocateamount',
          render:(text,record) =>  this.amount3(record)

        },
        // {
        //   title: 'Total Transfer Order',
        //   dataIndex: 'Totaltransferorder',
        //   key: 'Totaltransferorder',
        // },
        // {
        //   title: 'Total Transfer Amount',
        //   dataIndex: 'Totaltransferamount',
        //   key: 'Totaltransferamount',
        //   render:(text,record) =>  this.amount4(record)

        // },
        {
          title: 'Total Repayment Order',
          dataIndex: 'totalrepaymentordfer',
          key: 'totalrepaymentordfer',
        },
        {
          title: 'Total repayment amount',
          dataIndex: 'totalrepaymentamount',
          key: 'totalrepaymentamount',
          render:(text,record) =>  this.amount5(record)

        },
        {
          title: 'Amount Recovery Rate',
          dataIndex: 'amountRecovery',
          key: 'amountRecovery',
        },
        {
          title: 'Order Recovery Rate',
          dataIndex: 'coveryrate',
          key: 'coveryrate',
        },
        {
          title: 'Total extend order',
          dataIndex: 'totalExtendOrder',
          key: 'totalExtendOrder',

        },
        {
          title: 'Total extend fee',
          dataIndex: 'totalExtendAmount',
          key: 'totalExtendAmount',

        },
      ];
      const columns = [
        {
          title: 'Collector',
          dataIndex: 'Collector',
          key: 'Collector',
        },
        {
          title: 'Overdue grade',
          dataIndex: 'grade',
          key: 'grade',
          render:(text,record) =>  this.collector(record)

        },
        {
          title: 'Total Allocate Order',
          dataIndex: 'Allocateorder',
          key: 'Allocateorder',
  
        },
        {
          title: 'Total Allocate Amount',
          dataIndex: 'Allocateamount',
          key: 'Allocateamount',
          render:(text,record) =>  this.amount(record)

        },
        // {
        //   title: 'Total Transfer Order',
        //   dataIndex: 'Totalorder',
        //   key: 'Totalorder',
     
        // },
        // {
        //   title: 'Total Transfer Amount',
        //   dataIndex: 'Totalamount',
        //   key: 'Totalamount',
        //   render:(text,record) =>  this.amount1(record)

        // },
        {
          title: 'Total Repayment Order',
          dataIndex: 'Repaymentorder',
          key: 'Repaymentorder',

        },
        {
          title: 'Total repayment amount',
          dataIndex: 'repaymentamount',
          key: 'repaymentamount',
          render:(text,record) =>  this.amount2(record)

        },
        {
          title: 'Amount Recovery Rate',
          dataIndex: 'Recoveryamount',
          key: 'Recoveryamount',

        },
        {
          title: 'Order Recovery Rate',
          dataIndex: 'Recoveryorder',
          key: 'Recoveryorder',
        },
        {
          title: 'Total extend order',
          dataIndex: 'ontotalExtendOrder',
          key: 'ontotalExtendOrder',

        },
        {
          title: 'Total extend fee',
          dataIndex: 'ontotalExtendAmount',
          key: 'ontotalExtendAmount',

        },
        {
          title: 'Operation',
          dataIndex: 'operating',
          key: 'operating',
          width:70,
          id:'id',
          uid:'uid',
          // fixed:'right',
          render: (text, record, index) => (<span style={{ display: 'flex' , flexDirection: 'column'}}> 
          <Button type="primary"   style={this.state.detailsbutton} className="detailsbutton common_color" size="small"  onClick={()=>this.text(record)}>details</Button>
            </span>)
        },
      ];
        return (
            <div className="overduepaid" style={{paddingTop:0}}>
                <div className="overduepaid_top" style={{backgroundColor:'#fff',}}>
                    <div className="overduepaid_mid" style={{paddingTop:40,paddingBottom:40}}>
                        <div className="top" style={{paddingLeft:20}}>
                          <div className="top_left" style={{width:'30%',flexShrink:1}}>
                            <div className="search">
                            <span className="description left">Overdue Grade:</span>
                                <select className="form_select" value={this.state.fstSelect} onChange={this.selectFst.bind(this)}>
                                    <option value="0">all</option>
                                    <option value='1'>S1-Old</option>
                                    <option value='2'>S2-Old</option>
                                    <option value='3'>M1-Old</option>
                                    <option value='4'>M2</option>
                                    <option value='5'>M3</option>
                                    <option value='6'>M4</option>
                                    <option value='11'>S1-New</option>
                                    <option value='12'>S2-New</option>
                                    <option value='13'>M1-New</option>
                                </select>
                            </div>
                          </div>

                          <div className="top_mid" style={{width:'30%',flexShrink:2,marginLeft:'5%',marginRight:'5%'}}>
                            <div className="rangepick">
                                <span className="description mid_span">Created time:</span>
                                <RangePicker
                                  style={{width: 290,}}
                                  ranges={{
                                  Today: [moment(), moment()],
                                  'This Month': [moment().startOf('month'), moment().endOf('month')],}}
                                  
                                  showTime
                                  format={dateFormat}
                                  onChange={this.rangeOnChange}
                                  value={this.state.from===undefined||this.state.to===undefined||this.state.from===""||this.state.to===""?null:[moment(this.state.from, dateFormat), moment(this.state.to, dateFormat)]}
                                />
                            </div>
                          </div>

                        <div className="mid_right" style={{width:'30%'}}>
                            <span className="description right">Collector:</span>
                            <input list='browsers' placeholder='all' className="form_select_mohu" id="ipt" value={this.state.scdSelect} onChange={this.selectscd.bind(this)}/>
                            <datalist id="browsers">
                              {collector}
                            </datalist>
                        </div>
                        </div>
                        
                        <div className="bottom">
                            <Button type="primary" style={{width:170,marginRight:20,height:40,border:'none'}} className="common_color" onClick={this.searchClick.bind(this)}>Search</Button>
                            <Button type="primary" style={{width:170,height:40,border:'none'}}className="common_color_scd" onClick={this.reloadClick.bind(this)}>Refresh</Button>
                        </div>  
                    </div>
                </div>
                <div className="content_bot" style={{textAlign:'center',marginBottom:40,marginTop:40}}>
                <p className="content_bot_p">Total</p>
                {this.state.isshow&&
                 <Button type="primary" style={{width:170,height:40,border:'none'}}className="common_color_scd" onClick={this.showtable}>Details</Button>
                 }
               {this.state.ishide&&
               <Table columns={botcolumns} dataSource={this.state.totalData} loading={this.state.topboolean} onChange={this.handleChange} pagination={false}/>
              }
               
            </div>
                <div className="overdue_table">
                    <Table columns={columns} loading={this.state.boolean} dataSource={this.state.tableData} onChange={this.handleChange} pagination={false}/>
                    <Pagination
                      showSizeChanger                         
                      onShowSizeChange={this.onShowSizeChange} 
                      pageSizeOptions={["10","50","100"]}   
                      defaultCurrent={1} 
                      current={this.state.current}
                      total={this.state.length} 
                      onChange={this.pageChange} 
                      pageSize={this.state.pageSize}
                      showTotal={showTotal}
                      style={{textAlign:'right',marginTop:20}}
                      />
                </div>
               
               
            </div>
        );
    }
}
export default withRouter(ReceivingHistorical)