/*eslint-disable*/
import React from "react";
import { Table, Pagination } from "antd";
import "./index.css";
import axios from "axios";
import moment from "moment";

function showTotal(total) {
  return `Total ${total} items`;
}
export default class Tab4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      extendData: [],
      extendBoolean: true,
      current: 1,
      total: 1,
      pageSize: 10,
    };
  }
  componentDidMount() {
    this.tableonload();
  }
  pageChange = (page) => {
    this.setState(
      {
        current: page,
      },
      () => {
        this.tableonload();
      }
    );
  };
  //table初始化
  tableonload = () => {
    let uid = this.props.uid;
    axios
      .post(`/api/userRepay/getExtensionByUid`, {
        uid: uid,
        pageSize: 10,
        current: this.state.current,
      })
      .then((res) => {
        if (res.data.code === 200) {
          const data1 = [];
          const data = res.data.data.resultDate;
          if (data) {
            for (let i = 0; i < data.length; i++) {
              data[i].key = i;
              data1.push(data[i]);
            }
          }
          this.setState({
            extendData: data1,
            total: res.data.data.page.total,
            extendBoolean: false,
          });
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
          // message.error(res.data.msg);
        }
      });
  };

  render() {
    //第五个table
    const extendColumns = [
      {
        title: "order No",
        dataIndex: "loan_record_no",
        key: "loan_record_no",
      },
      {
        title: "trading number",
        dataIndex: "transaction_no",
        key: "transaction_no",
      },
      {
        title: "payment method",
        dataIndex: "way_code",
        key: "way_code",
      },
      {
        title: "transaction amount",
        dataIndex: "transaction_amount",
        key: "transaction_amount",
        render: (value) => {
          var num = value / 100;
          num = num + "";
          if (num.indexOf(".") != -1) {
            return num;
          } else {
            var str = "";
            for (var i = num.length - 1, j = 1; i >= 0; i--, j++) {
              if (j % 3 == 0 && i != 0) {
                //每隔三位加逗号，过滤正好在第一个数字的情况
                str += num[i] + ","; //加千分位逗号
                continue;
              }
              str += num[i]; //倒着累加数字
            }
            return str.split("").reverse().join("");
          }
        },
      },
      {
        title: "transaction time",
        dataIndex: "completionTime",
        key: "completionTime",
        render: (value) => {
          let timeaudit =
            new Date(value).getTime() +
            new Date().getTimezoneOffset() * 60 * 1000 -
            0 * 60 * 60 * 1000;
          let auditimes = moment(new Date(timeaudit)).format("YYYY-MM-DD");
          if (value == null) {
            return (auditimes = "");
          } else {
            return (auditimes = auditimes);
          }
        },
      },

      {
        title: "operator",
        dataIndex: "operator",
        key: "operator",
      },
      {
        title: "operator time",
        dataIndex: "updated_time",
        key: "updated_time",
        render: (value, record) => {
          let timeaudit =
            new Date(value).getTime() +
            new Date().getTimezoneOffset() * 60 * 1000 -
            0 * 60 * 60 * 1000;
          let auditimes = moment(new Date(timeaudit)).format("YYYY-MM-DD");
          if (!record.operator) {
            return "";
          } else {
            if (value == null) {
              return (auditimes = "");
            } else {
              return (auditimes = auditimes);
            }
          }
        },
      },
    ];
    return (
      <div className="order_infor">
        <h2>Extend Information</h2>
        <Table
          columns={extendColumns}
          dataSource={this.state.extendData}
          loading={this.state.extendBoolean}
          pagination={false}
        />
        <Pagination
          // showQuickJumper
          defaultCurrent={1}
          current={this.state.current}
          total={this.state.total}
          onChange={this.pageChange}
          showTotal={showTotal}
          style={{ textAlign: "right", marginTop: 20 }}
          pageSize={this.state.pageSize}
          // showSizeChanger={true}
        />
      </div>
    );
  }
}
