/*eslint-disable*/
import React from 'react';
import { Input, Button, Table, Modal, Pagination, DatePicker, Tooltip, message } from 'antd';
import axios from 'axios'
import '../index.scss'
import moment from 'moment';
import { withRouter } from 'react-router-dom'
import { exportMethod } from '../../../utils/axios';


function showTotal(total) {
    return `Total ${total} items`;
}
var getselect = []
const dateFormat = 'YYYY-MM-DD' || undefined;
const { RangePicker } = DatePicker;
const { Search } = Input;

class Smsdata extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            boolean: true,
            id:'',
            uploadTime:'',
            totalData:[]
        }
    }
    componentDidMount() {
    this.getUrlParams();
    setTimeout(()=>{
        this.getTableData();
    },500)
  
    }
    //搜索数据
    doSearch = () => {
        let params = {
            batchNo: this.state.batchNo,
            theme: this.state.theme,
        }
        this.getTableData(params)
    }
    //取url参数
    getQueryString = (url) => {
        url = url == null ? window.location.href : url;
        console.log(url, '生命周期')

        var search = url.substring(url.lastIndexOf("?") + 1);
        var obj = {};
        var reg = /([^?&=]+)=([^?&=]*)/g;
        search.replace(reg, function (rs, $1, $2) {
            var name = decodeURIComponent($1);
            var val = decodeURIComponent($2);
            val = String(val);
            obj[name] = val;
            return rs;
        });
        return obj;
    }
       //获取url传过来的数据
       getUrlParams=()=>{
        let params = this.getQueryString(); 
         this.setState({
             id:params['id'],
             uploadTime:params['uploadTime'].substr(0,10),
             batchNo:params['batchNo'],
             clientTotal:params['clientTotal']
         })
     }
        //请求表格数据
        getTableData(){
            this.setState({
                boolean: true
            })
       axios.get(`/api/sms/marketing/details/${this.state.id}`)
              .then(res => {
                if (res.data.code === 200) {
                    this.setState({
                        boolean: false,
                        tableData: res.data.data.resultDate,
                    })
                } else if (res.data.code == 800) {
                    this.props.history.push({ pathname: "/" });
                }
            })
        }

        //表单值双向绑定
        fstaddonChange = (e) => {
            let InputName = e.target.name;
            let InputValue = e.target.value;
            this.setState({
                [InputName]: InputValue.replace(/\s+/g, ""),
            })
        }
        pageChange = (page) => {
            this.setState({
                current: page
            }, () => {
                this.doSearch()
            })
        }
        //时间选择器范围
        rangeOnChange = (dates, dateStrings) => {
            console.log(dateStrings, '时间选择器')
            this.setState({ from: dateStrings[0], to: dateStrings[1] })
        }
        render() {
            const columns = [
                {
                    title: '日期',
                    dataIndex: 'statDate',
                    key: 'statDate',
                    render: (value) => {
                        return value || '——'
                    }

                },
                {
                    title: '进件量',
                    dataIndex: 'purchaseCount',
                    key: 'purchaseCount',
                    render: (value) => {
                        if(value==0){
                            return 0
                        }else{
                            return value || '——'
                        }
                    }
                },
                {
                    title: '放款量',
                    dataIndex: 'loanCount',
                    key: 'loanCount',
                    render: (value) => {
                        if(value==0){
                            return 0
                        }else{
                            return value || '——'
                        }
                    }

                },
                {
                    title: '放款率',
                    dataIndex: 'loanRate',
                    key: 'loanRate',
                    render: (value) => {
                        return value || '——'
                    }

                },
            ];
            return (
                <div className="audit_content">
                    <div className="pre_content_top smsDetail">
                        <div className="pre_content_fst pre_content">
                            <p className='pre_content_p'>{this.state.batchNo}</p>
                            <p className='pre_content_botp'>批次</p>
                        </div>
                        <span className="pre_content_span"></span>
                        <div className="pre_content_scd pre_content">
                            <p className='pre_content_p'>{this.state.clientTotal}</p>
                            <p className='pre_content_botp'>名单数量</p>
                        </div>
                        <span className="pre_content_span"></span>
                        <div className="pre_content_thd pre_content">
                            <p className='pre_content_p'>{this.state.uploadTime}</p>
                            <p className='pre_content_botp'>上传时间</p>
                        </div>
                    </div>
                    <div className="table">
                        <Table columns={columns} dataSource={this.state.tableData} loading={this.state.boolean}
                            rowKey={record => record.batchNo}
                            pagination={false} />
                    </div>

                    {/* <Pagination
                    // showQuickJumper
                    defaultCurrent={1}
                    current={this.state.current}
                    total={this.state.total}
                    onChange={this.pageChange}
                    showTotal={showTotal}
                    style={{ textAlign: 'right', marginTop: 20 }}
                    showSizeChanger={true}
                    pageSize={this.state.pageSize}
                /> */}
                </div>
            )
        }
    }
    export default withRouter(Smsdata)