import React, { Component } from "react";
import "../index.css";
import "./index.scss";
import Tab1 from "./tab1";
import Tab2 from "./tab2";
import Tab3 from "./tab3"; //展期配置
import Tab4 from "./tab4"; //短信配置
import Tab5 from "./tab5"; //催收目标
import Tab6 from "./tab6"; //放款配置
import Tab7 from "./tab7"; //悬浮窗管理
import { Tabs } from "antd";

const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}

class SystemSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstVisible: true,
    };
  }

  showDialog() {
    this.setState({});
  }

  render() {
    return (
      <div>
        <Tabs defaultActiveKey="1" onChange={callback}>
          <TabPane tab="短信设置" key="1">
            <Tab1 show={this.state.firstVisible} />
          </TabPane>
          <TabPane tab="电销设置" key="2">
            <Tab2 />
          </TabPane>
          <TabPane tab="展期配置" key="3">
            <Tab3 show={this.state.firstVisible} />
          </TabPane>
          <TabPane tab="后台催收短信" key="4">
            <Tab4 />
          </TabPane>
          <TabPane tab="催收目标配置" key="5">
            <Tab5 />
          </TabPane>
          <TabPane tab="放款配置" key="6">
            <Tab6 />
          </TabPane>
          <TabPane tab="悬浮窗管理" key="7">
            <Tab7 />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default SystemSettings;
