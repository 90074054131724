/*eslint-disable*/
import React from 'react';
import { Input,Button,Table,Modal,Pagination,DatePicker,Tooltip,message } from 'antd';
import moment from 'moment';
import axios from 'axios'
import './index.css';
import $ from 'jquery';
import {withRouter} from 'react-router-dom'
import EventEmitter from '../../../utils/events'

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD"||undefined;
function showTotal(total) {
  return `Total ${total} items`;
}
class getRemindDataHistory extends  React.Component {
  constructor(props) {
    super(props);
    this.state = {
        from:'',
        to:'',
        current:1,
        pageSize:10,
        isshow:true,
        ishide:false,
        boolean:true,
        topboolean:true,
        total:'',
        id:'',
      };
}
componentDidMount() {
  this.setState({
  },()=>{
    this.secondTable()
    this.table()
  })
}
rangeOnChange=(dates, dateStrings)=>{
  this.setState({ from: dateStrings[0],to:dateStrings[1] })
}
 searchIpt(e){
  var value = e.target.value;
  this.setState({ searchVal: value })
}
 pageChange=(page)=>{
  this.setState({
    current: page
    },()=>{
      this.searchClick()
    })
}
onShowSizeChange=(current, pageSize)=>{

  this.setState({
    pageSize: pageSize
  },()=>{
    this.secondTable()
    this.table()
  })
}

secondTable=()=>{
    
 
  axios
    .post(`/api/remindData/getRemindOrderHistory`,{
      pageSize: this.state.pageSize,
      current: this.state.current,
  })
  .then(res=>{
    if(res.data.code === 200){
      const data1 = [];
      const data = res.data.data.resultDate
      if(data.length<1){
        this.setState({
          boolean:false
        })
      }
      for (let i = 0; i < data.length; i++) {
        data1.push({
          key: i,
          date:data[i].date,
          expiredOrder:data[i].expiredOrder,
          beforeDistributionRepay:data[i].beforeDistributionRepay,
          distributionOrder:data[i].distributionOrder,
          distributedRepay:data[i].distributedRepay,
          repayOrder:data[i].repayOrder,
          overdueOrder:data[i].overdueOrder,
          overdueRate:data[i].overdueRate,
          firstOverdueRate:data[i].firstOverdueRate,
          id:data[i].id,
          extendOrder:data[i].extendOrder,
        });
        this.setState({
          tableScdData:data1,
          total:res.data.data.page.total,
          boolean:false
        })
      } 
    }else if(res.data.code ==800){
      this.props.history.push({ pathname: "/"});
    }else{

    }
  })
  
}

table=()=>{
  axios
    .post(`/api/remindData/getRemindOrderHistoryTotal`,{
      pageSize: this.state.pageSize,
      current: this.state.current,
  })
  .then(res=>{
    if(res.data.code === 200){
      const data1 = [];
      const data = res.data.data.resultDate
      if(data.length<1){
        this.setState({
          topboolean:false
        })
      }else{
        for (let i = 0; i < data.length; i++) {
          data1.push({
            key: i,
            overdueGrade:'--',
            expiredOrder:data[i].expiredOrder,
            beforeDistributionRepay:data[i].beforeDistributionRepay,
            distributionOrder:data[i].distributionOrder,
            distributedRepay:data[i].distributedRepay,
            repayOrder:data[i].repayOrder,
            overdueOrder:data[i].overdueOrder,
            overdueRate:data[i].overdueRate,
            firstOverdueRate:data[i].firstOverdueRate,
            extendOrder:data[i].extendOrder,
          });
         
        } 
      }
      
      this.setState({
        totalData:data1,
        topboolean:false
      }) 
    }else if(res.data.code ==800){
      this.props.history.push({ pathname: "/"});
    }else{

    }
  })
  
}
todetail=(record)=>{
  window.location='/#/remind/getRemindDataHistory/s0?id='+record.id
  let test = [{path: '/remind/getRemindDataHistory/s0',key: `/remind/getRemindDataHistory/s0/${record.id}`,number: `${record.id}`}]
  EventEmitter.trigger('btn', test[0])
  EventEmitter.trigger('idanduid', test[0].number)  
  localStorage.id = record.id
  console.log(record.id);
  // window.location.href='/#/collection/getCollectionOrderManagement/month?id='+record.id+'&uid='+record.uid
}


 searchClick=()=>{
   this.setState({
    topboolean:true,
    boolean:true
   })
   axios
    .post(`/api/remindData/getRemindOrderHistoryTotal`,{
      beginTime:this.state.from,
      endTime:this.state.to,
      pageSize: this.state.pageSize,
      current: this.state.current,
  })
  .then(res=>{
    if(res.data.code === 200){
      const data1 = [];
      const data = res.data.data.resultDate
      if(data.length<1){
        this.setState({
          topboolean:false
        })
      }else{
        for (let i = 0; i < data.length; i++) {
          data1.push({
            key: i,
            overdueGrade:'--',
            expiredOrder:data[i].expiredOrder,
            beforeDistributionRepay:data[i].beforeDistributionRepay,
            distributionOrder:data[i].distributionOrder,
            distributedRepay:data[i].distributedRepay,
            repayOrder:data[i].repayOrder,
            overdueOrder:data[i].overdueOrder,
            overdueRate:data[i].overdueRate,
            firstOverdueRate:data[i].firstOverdueRate,
            extendOrder:data[i].extendOrder,
          });
         
        } 
      }
      
      this.setState({
        totalData:data1,
        topboolean:false
      }) 
    }else if(res.data.code ==800){
      this.props.history.push({ pathname: "/"});
    }else{

    }
  })
  axios
  .post(`/api/remindData/getRemindOrderHistory`,{
    pageSize: this.state.pageSize,
    current: this.state.current,
    beginTime:this.state.from,
    endTime:this.state.to
})
.then(res=>{
  if(res.data.code === 200){
    const data1 = [];
    const data = res.data.data.resultDate
    if(data.length<1){
      this.setState({
        boolean:false
      })
    }else{
      for (let i = 0; i < data.length; i++) {
        data1.push({
          key: i,
          date:data[i].date,
            expiredOrder:data[i].expiredOrder,
            beforeDistributionRepay:data[i].beforeDistributionRepay,
            distributionOrder:data[i].distributionOrder,
            distributedRepay:data[i].distributedRepay,
            repayOrder:data[i].repayOrder,
            overdueOrder:data[i].overdueOrder,
            overdueRate:data[i].overdueRate,
            firstOverdueRate:data[i].firstOverdueRate,
            id:data[i].id,
            extendOrder:data[i].extendOrder,
        });       
      } 
    }
    
    this.setState({
      tableScdData:data1,
      total:res.data.data.page.total,
      boolean:false
    })
  }else if(res.data.code ==800){
    this.props.history.push({ pathname: "/"});
  }else{
  }
})

}

    render() {


        const botcolumns = [
         
          {
            title: 'Date',
            dataIndex: 'overdueGrade',
            key: 'overdueGrade',
      
          },
            {
                title: 'Total Due',
                dataIndex: 'expiredOrder',
                key: 'expiredOrder',
          
              },
            {
              title: 'Repaid before Allocate',
              dataIndex: 'beforeDistributionRepay',
              key: 'beforeDistributionRepay',
        
            },
            {
              title: 'Total Allocate',
              dataIndex: 'distributionOrder',
              key: 'distributionOrder',
            },
            {
              title: 'Repaid after Allocate',
              dataIndex: 'distributedRepay',
              key: 'distributedRepay',
      
            },
            {
                title: 'Total Repaid',
                dataIndex: 'repayOrder',
                key: 'repayOrder',
        
              },
              {
                title: 'Today extend order',
                dataIndex: 'extendOrder',
                key: 'extendOrder',
                width:100,
              },
            {
              title: 'Total Overdue',
              dataIndex: 'overdueOrder',
              key: 'overdueOrder',
         
            },
            {
              title: 'Overdue Rate',
              dataIndex: 'overdueRate',
              key: 'overdueRate',
         
            },
            {
              title: 'First Overdue Rate',
              dataIndex: 'firstOverdueRate',
              key: 'firstOverdueRate',
      
            },
          
          ];

          const columns = [
            {
                title: 'Date',
                dataIndex: 'date',
                key: 'date',
          
              },
            {
              title: 'Total Due',
              dataIndex: 'expiredOrder',
              key: 'expiredOrder',
        
            },
            {
              title: 'Repaid before Allocate',
              dataIndex: 'beforeDistributionRepay',
              key: 'beforeDistributionRepay',
            },
            {
              title: 'Total Allocate',
              dataIndex: 'distributionOrder',
              key: 'distributionOrder',
      
            },
            {
                title: 'Repaid after Allocate',
                dataIndex: 'distributedRepay',
                key: 'distributedRepay',
        
              },
            {
              title: 'Total Repaid',
              dataIndex: 'repayOrder',
              key: 'repayOrder',
         
            },
            {
              title: 'Today extend order',
              dataIndex: 'extendOrder',
              key: 'extendOrder',
              width:100,
            },
            {
              title: 'Total Overdue',
              dataIndex: 'overdueOrder',
              key: 'overdueOrder',
         
            },
            {
              title: 'Overdue Rate',
              dataIndex: 'overdueRate',
              key: 'overdueRate',
      
            },
            {
              title: 'First Overdue Rate',
              dataIndex: 'firstOverdueRate',
              key: 'firstOverdueRate',
      
            },
            {
              title: 'operating',
              dataIndex: 'operating',
              key: 'operating',
              id:'id',
              // width:20,
              // fixed: 'right',
              render: (text, record, index) => (<span style={{ display: 'flex' , flexDirection: 'column', justifyContent:'center',}}> 
            <Button type="primary" style={this.state.detailsbutton} className="detailsbutton common_color" size="small" onClick={() => this.todetail(record)}>details</Button>
                 
                </span>)
            },
          ];
          return (
            <div className="overduepaid">
            <div className="overduepaid_top">
                <div className="overduepaid_mid">
                    <div className="bottom" style={{justifyContent:'left',alignItems:'center',marginTop:0}}>
                    <div className="top_mid" style={{width:'30%',flexShrink:2,marginRight:'5%'}}>
                <div className="rangepick">
                    <span className="description mid_span">Created time:</span>
                    <RangePicker
                       style={{width: 290,}}
                      ranges={{
                      Today: [moment(), moment()],
                      'This Month': [moment().startOf('month'), moment().endOf('month')],
                      }}
                      showTime
                      format={dateFormat}
                      onChange={this.rangeOnChange}
                      value={this.state.from===undefined||this.state.to===undefined||this.state.from===""||this.state.to===""?null:[moment(this.state.from, dateFormat), moment(this.state.to, dateFormat)]}
                    />
                </div>
              </div>
                    <Button type="primary" style={{width:170,marginRight:20,height:40,border:'none'}}  className="common_color" onClick={this.searchClick.bind(this)}>Search</Button>
                    </div>  
                </div>
            </div>
            <div className="overdue_table">
                <Table columns={botcolumns} dataSource={this.state.totalData} loading={this.state.topboolean} onChange={this.handleChange} pagination={false}/>
            </div>
            <div className="overdue_table">
                <Table columns={columns} dataSource={this.state.tableScdData} loading={this.state.boolean} scroll={{x:'100%'}}onChange={this.handleChange} pagination={false}/>
                <Pagination
                  
                  showSizeChanger                        
                  onShowSizeChange={this.onShowSizeChange} 
                  pageSizeOptions={["10","50","100"]}   
                  defaultCurrent={1} 
                  current={this.state.current}
                  total={this.state.total} 
                  onChange={this.pageChange} 
                  pageSize={this.state.pageSize}
                  showTotal={showTotal}

                  style={{textAlign:'right',marginTop:20}}
                  />
            </div>
        </div>
          );
    }
}
export default withRouter(getRemindDataHistory)