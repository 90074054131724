/*eslint-disable*/
import React from 'react';
import { Input,Button,Table,Modal,Pagination,DatePicker,Tooltip,message } from 'antd';
import './index.css';
import axios from 'axios'
import fstimg from '../../../public/img/preloan_fst.png';
import scdimg from '../../../public/img/preloan_scd.png';
import thdimg from '../../../public/img/preloan_thd.png';
import fouimg from '../../../public/img/preloan_fou.png';
import fivimg from '../../../public/img/preloan_fiv.png';
import {withRouter} from 'react-router-dom'
import EventEmitter from '../../utils/events'


import {Link} from 'react-router-dom'
import moment from 'moment';
const { RangePicker } = DatePicker;
  const { Search } = Input;
  const dateFormat = 'YYYY-MM-DD'||undefined;
var whitchshow = []
var getselect = []
var Select = []

   
  function showTotal(total) {
    return `Total ${total} items`;
  }
  function formatMoney(s, type){
    if (/[^0-9\.]/.test(s))
      return "0";
    if (s == null || s == "null" || s == "")
      return "0";
    s = s.toString().replace(/^(\d*)$/, "$1.");
    s = (s + "00").replace(/(\d*\.\d\d)\d*/, "$1");
    s = s.replace(".", ",");
    var re = /(\d)(\d{3},)/;
    while (re.test(s))
      s = s.replace(re, "$1,$2");
    s = s.replace(/,(\d\d)$/, ".$1");
    if (type == 0) {
      var a = s.split(".");
      if (a[1] == "00") {
        s = a[0];
      }
    }
    s = s.replace(".", ",");
    return s;
  }
class PendingLoan extends React.Component {
    constructor(props){
      super(props);
      this.state={
        fouSelect:'',
        searchVal:'',
        current:1,
        from:'',
        to:'',
        selectedRowKeys:[],
        updatatime:'',
        pageSize:10,
        boolean:true,
        oldOrNew:''
      }
    }
    oldOrderNew(e){
      var value = e.target.value;
      this.setState({
        oldOrNew:value,
      });
    }
        //页码改变时触发
        pageChange=(page)=>{
          this.setState({
            current: page
            },()=>{
              this.searchClick()
            })
        }
        rangeOnChange=(dates, dateStrings)=>{
          this.setState({ from: dateStrings[0],to:dateStrings[1] })
        }
    //选择table触发
    handleChange = (pagination, filters, sorter) => {
      console.log('Various parameters', pagination, filters, sorter);
      this.setState({
      });
    };
    clearFilters = () => {
      this.setState({ filteredInfo: null });
    };
  
    getselect=()=>{
      axios
      .post(`/api/appshadow/findAppShadowList`,{
  
    })
    .then(res=>{
       Select=[]
      if(res.data.code === 200){
        for(let i=0;i<res.data.data.list.length;i++ ){
          
            if(i==0){
              Select.push(<option value=''>please select one</option> )
              }
              Select.push(       
              <option value={res.data.data.list[i].appCode}>{res.data.data.list[i].appCode}</option>
          )
          
        this.setState({
          Select:Select
        })
        }
      }else if(res.data.code ==800){
        this.props.history.push({ pathname: "/"});
      }else{
      }
    })
    }

    handleCancel=()=>{
      this.setState({
        fstvisible: false,
        scdvisible: false,
        thdvisible: false,
        fouvisible: false,
        selectedRowKeys:'',
        fsttextarea:'',
        oldOrNew:''
      });
    }
    //重置按钮
    reloadClick(e) {
        this.setState(
        {
            fouSelect: "",
            searchVal: "",
            orderNo: "",
            mobile: "",
            from: undefined,
            to: undefined,
            boolean: true,
        },
        () => {
            this.searchClick();
        }
        );
    }
        //控制审核按钮model
        showFstModal = (record) => {
          var id = record.id
          var uid = record.uid
          this.setState({
            fstvisible: true,
          });
          // this.gradeChange()
        };

    onSelectChange = selectedRowKeys => {
          console.log('selectedRowKeys changed: ', selectedRowKeys);
          this.setState({ selectedRowKeys });
    };
    componentDidMount(){
     this.onload()
     this.onloadParams()
     this.getselect()
    }
    //详情页
    text=(record)=>{
      window.location='/#/details/index?id='+record.id+'&uid='+record.uid
      let test = [{path: '/details/index',key: `/details/index/${record.id}/${record.uid}`,number: `${record.id}/${record.uid}`}]
      EventEmitter.trigger('btn', test[0])
      EventEmitter.trigger('idanduid', test[0].number)
    }

     //refuse按钮
     refuse=(record)=>{
      var tempwindow=window.open('_blank')
      // tempwindow.location='/web/#/details/index?id='+record.id+'&uid='+record.uid
      tempwindow.location='/#/details/index?id='+record.id+'&uid='+record.uid
    }
    //输入框
    searchIpt(e){
      var value = e.target.value;
      this.setState({ searchVal: value.replace(/\s+/g,"") })
    }

    onloadParams=()=>{
      axios
      .post(`/api/loanFront/getLoadFrontLoanData`)
      .then(res => {
          if (res.data.code === 200) {
            const data = res.data.data
            if(data.balanceConfig==null){
              return false
            }
            let newtime = data.balanceConfig.updatedTime;
            let timeaudit= new Date(newtime).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
            let auditimes=moment(new Date(timeaudit)).format("YYYY-MM-DD HH:mm:ss");
           
              this.setState({
                amountfst:data.balanceConfig.keyValue,
                amountscd: data.amountscd || "0.00",
                singloan:data.noPayOrderNum,
                amounthd:data.noPayOrderMoney,
                updatatime:auditimes
              })
          } else if (res.data.code == 800) {
              this.props.history.push({pathname: "/"});
          } else {
          }
      })
    }
    //选择apptype
    selectFou(e){
      var value = e.target.value;
      this.setState({
        fouSelect:value,
      });
    }
    onload=()=>{
      this.searchClick()
    }
    //搜索
    searchClick = () => {
      this.setState({
        boolean: true
    })
      axios
          .post(`/api/loanFront/getLoadFrontLoanList`, {
            beginTime: this.state.from,
            endTime: this.state.to,
            pageSize: this.state.pageSize,
            current: this.state.current,
            appCode: this.state.fouSelect,
            schParam: this.state.searchVal,
            OldOrNew: this.state.oldOrNew,
            orderNo: this.state.orderNo,
            mobile: this.state.mobile,
          })
          .then(res => {

              if (res.data.code === 200) {
                const data1 = [];
                const data = res.data.data.resultDate
                  if(data.length<1){
                    this.setState({
                      boolean: false
                  })
                  }else{

                    for (let i = 0; i < data.length; i++) {
                      data[i].key = i;
                      data1.push(data[i]);
                  }
                  }
                  this.setState({
                      tableScdData: data1,
                      total: res.data.data.page.total,
                      boolean: false
                  })
              } else if (res.data.code == 800) {
                  this.props.history.push({pathname: "/"});
              } else {
              }
          })
  }
  //点击批量分配按钮
  selected = () => {
    let arr = this.state.selectedRowKeys;
    if (arr.length < 1) {
      message.error("please select one");
    } else {
      this.fsthandleOk();
    }
  };
  //第一个Modal提交
  fsthandleOk=()=>{
    let arr = this.state.selectedRowKeys;
    let lastarr = arr.join(',');
    axios
      .post(`/api/loanFront/batchPassLoan`, {
          ids:lastarr
      })
      .then(res => {
          if (res.data.code === 200) {
            message.success(res.data.msg);
            this.searchClick()
          } else if (res.data.code == 800) {
              this.props.history.push({pathname: "/"});
          } else {
            message.error(res.data.msg);
            this.searchClick()
          }
         
      })
      this.setState({
        fstvisible:false,
        boolean:true,
        selectedRowKeys:''
      })
     
  }
  //点击payall按钮
  payall=()=>{
    this.setState({
      scdvisible:true
    })
  }
  //第二个提交按钮
  scdhandleOk=()=>{
    axios
    .post(`/api/loanFront/allPassLoan`,)
    .then(res => {
        if (res.data.code === 200) {
          message.success(res.data.msg);
          this.searchClick()
        } else if (res.data.code == 800) {
            this.props.history.push({pathname: "/"});
        } else {
          message.error(res.data.msg);
          this.searchClick()
        }
       
    })
    this.setState({
      scdvisible:false,
      boolean:true,
      selectedRowKeys:''
    })
   
  }
    //点击loan按钮
    loan=(record)=>{
      this.setState({
        thdvisible:true,
        disinpt:record.id
      })
    }
    //第三个提交按钮
    thdhandleOk=()=>{
      axios
      .post(`/api/loanFront/passLoan`,{
        loanRecordId:this.state.disinpt
      })
      .then(res => {
          if (res.data.code === 200) {
            message.success(res.data.msg);
            this.searchClick()
          } else if (res.data.code == 800) {
              this.props.history.push({pathname: "/"});
          } else {
            message.error(res.data.msg);
            this.searchClick()
          }
         
      })
      this.setState({
        thdvisible:false,
        boolean:true,
        selectedRowKeys:''
      })
    }
    //点击图片刷新
    refresh=()=>{
      axios
      .post(`/api/loanFront/getLoadFrontLoanData`)
      .then(res => {
          if (res.data.code === 200) {
            const data = res.data.data
            if(data.balanceConfig==null){
              return false
            }
            let newtime = data.balanceConfig.updatedTime;
            let timeaudit= new Date(newtime).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
            let auditimes=moment(new Date(timeaudit)).format("YYYY-MM-DD HH:mm:ss");
           
              this.setState({
                amountfst:data.balanceConfig.keyValue,
                // amountscd:
                singloan:data.noPayOrderNum,
                amounthd:data.noPayOrderMoney,
                updatatime:auditimes
              })
          } else if (res.data.code == 800) {
              this.props.history.push({pathname: "/"});
          } else {
          }
      })
    }
    //第一个textarea
    fsttxarea=(e)=>{
      let value = e.target.value;
      this.setState({ fsttextarea: value })
    }
    onShowSizeChange=(current, pageSize)=>{

      this.setState({
        pageSize: pageSize
      },()=>{
        this.searchClick()
      })
  }
     //点击refuse按钮
     refuse=(record)=>{
      this.setState({
        fouvisible:true,
        scddisinpt:record.id
      })
    }
    //第四个提交按钮
    fouhandleOk=()=>{
      axios
      .post(`/api/loanFront/payNotPass`,{
        reason:this.state.fsttextarea,
        id:this.state.scddisinpt
      })
      .then(res => {
          if (res.data.code === 200) {
            message.success(res.data.msg);
            this.searchClick()
          } else if (res.data.code == 800) {
              this.props.history.push({pathname: "/"});
          } else {
            message.error(res.data.msg);
            this.searchClick()
          }
         
      })
      this.setState({
        fouvisible:false,
        boolean:true,
        fsttextarea:''
      })
     
    }
    render() {
      const { selectedRowKeys } = this.state;
      const rowSelection = {
        selectedRowKeys,
        onChange: this.onSelectChange,
         columnWidth:20,
        //  getCheckboxProps: record => ({
        //   disabled: record.status === 0, // Column configuration not to be checked
        //   name: record.name,
        // }),
      };

      const columns = [
        {
          title: "app type",
          dataIndex: "appCode",
          key: "appCode",
        },
        {
          title: "Order No",
          dataIndex: "indentNo",
          key: "indentNo",
        },
        {
          title: "Customer name",
          dataIndex: "firstName",
          key: "firstName",
        },
        {
          title: "mobile phone",
          dataIndex: "mobile",
          key: "mobile",
        },
        {
          title: "BVN",
          dataIndex: "dentityCard",
          key: "dentityCard",
        },
        {
          title: "loan amount",
          dataIndex: "balance",
          key: "balance",
          render: (value) => {
            var num = value / 100;
            num = num + "";
            var str = "";
            for (var i = num.length - 1, j = 1; i >= 0; i--, j++) {
              if (j % 3 == 0 && i != 0) {
                //每隔三位加逗号，过滤正好在第一个数字的情况
                str += num[i] + ","; //加千分位逗号
                continue;
              }
              str += num[i]; //倒着累加数字
            }
            return str.split("").reverse().join("");
          },
        },
        {
          title: 'loan term',
          dataIndex: 'cycle',
          key: 'cycle',
  
        },
        {
          title: 'order created time',
          dataIndex: 'createdTime',
          key: 'createdTime',
          render: (value) => {
            let timeaudit= new Date(value).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
            let auditimes=moment(new Date(timeaudit)).format("YYYY-MM-DD HH:mm:ss");
            return auditimes
        }
        },
        {
          title: 'Success Repayment Count',
          dataIndex: 'repaymentCount',
          key: 'repaymentCount',
  
        },
        // {
        //   title: 'Loan status',
        //   dataIndex: 'statusDescription',
        //   key: 'statusDescription',
        //   render:(value,record)=> {
        //     if (record.status == 3 &&(record.auditUserTime == ""||record.auditUserTime==null)) {
        //         return "auto approved";
        //     }else {
        //         return value;
        //     }
        // }
        // },
        // {
        //   title: 'Risk Rules Result',
        //   dataIndex: 'auditRiskStatus',
        //   key: 'auditRiskStatus',
        //   render:(value)=> {
        //     if (value==15){
        //         return <font color='red'>reject</font>;
        //     }
        //     if (value==-1){
        //         return <font color='red'>reject</font>;
        //     }
        //     if (value==-2){
        //         return <font color='red'>reject</font>;
        //     }
        //     if (value==1){
        //         return <font color='blue'>pass</font>;
        //     }
        //     if (value==2){
        //         return <font color='blue'>pass</font>;
        //     }
        //     if (value==3){
        //         return <font color='orange'>warning</font>;
        //     }else {
        //         return "";
        //     }
        // }
        // },
        // {
        //   title: 'Reviewer',
        //   dataIndex: 'userName',
        //   key: 'userName',
  
        // },
        // {
        //   title: 'Review time',
        //   dataIndex: 'auditUserTime',
        //   key: 'auditUserTime',
        //   render: (value)=> {
        //     let timeaudit= new Date(value).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
        //     let auditimes=moment(new Date(timeaudit)).format("YYYY-MM-DD HH:mm:ss");
        //     if (value == null) {
        //         return "";
        //     }
        //     return auditimes;
        // }
  
        // },
        // {
        //   title: 'Review details',
        //   dataIndex: 'auditDetail',
        //   key: 'auditDetail',
        //   render: (record) => {
        //     return (
        //       <Tooltip title={record}>
        //         <div className="twoEllipsis">
        //           {record}
        //         </div>
        //       </Tooltip>
        //     )
        // }
        // },
  
        {
          title: "Operation",
          dataIndex: "operating",
          key: "operating",
          width: 70,
          id: "id",
          uid: "uid",
          // fixed:'right',
          render: (text, record, index) => (
            <span style={{ display: "flex", flexDirection: "row" }}>
              <Button
                type="primary"
                className="detailsbutton common_color"
                size="small"
                onClick={() => this.text(record)}
              >
                Details
              </Button>
              <Button
                type="primary"
                className="detailsbutton common_color"
                size="small"
                onClick={() => this.loan(record)}
              >
                Loan
              </Button>
              <Button
                type="primary"
                className="detailsbutton common_color"
                size="small"
                onClick={() => this.refuse(record)}
              >
                Refuse
              </Button>
            </span>
          ),
        },
      ];
      return (
        <div className="credit_order">
          <div className="myorder_content">



  <div className="flutter">

        <div className="pre_content_top">
        <div className="pre_content_fst">
                {/* <div className='pre_content_flex' >
                    <p></p>
                    <img src={fstimg} onClick={this.refresh}/>
                </div> */}

                <p className="pre_content_p">{this.state.amountfst} ₦</p>
                <p className="pre_content_botp">PayStack Balance</p>
              </div>
              <span className="pre_content_span"></span>
              <div className="pre_content_scd">
                {/* <div className='pre_content_flex' >
                    <p></p>
                    <img src={scdimg}/>
                </div> */}
                <p className="pre_content_p">{this.state.amountscd} ₦</p>
                <p className="pre_content_botp">Flutterwave Balance</p>
              </div>
              <span className="pre_content_span"></span>
              <div className="pre_content_thd">
                {/* <div className='pre_content_flex' >
                    <p></p>
                    <img src={thdimg}/>
                </div> */}
                <p className="pre_content_p">{this.state.updatatime}</p>
                <p className="pre_content_botp">Last update time</p>
              </div>
              <span className="pre_content_span"></span>
              <div className="pre_content_fou">
                {/* <div className='pre_content_flex' >
                    <p></p>
                    <img src={fouimg}/>
                </div> */}
                <p className="pre_content_p">{this.state.singloan}</p>
                <p className="pre_content_botp">
                  Singular number of pending loans
                </p>
              </div>
              <span className="pre_content_span"></span>
              <div className="pre_content_fiv">
                {/* <div className='pre_content_flex' >
                    <p></p>
                    <img src={fivimg}/>
                </div> */}
                <p className="pre_content_p">{this.state.amounthd} ₦</p>
                <p className="pre_content_botp">Amount to be lent</p>
              </div>
        </div>

        <div className="collectio_order_mid">
              <div className="top collection_order_top">
                {/* <div className="top_left" style={{width:'30%',flexShrink:1}}>
                <div className="search">
                  <span className="description left">Order search:</span>
                  <Search
                    placeholder="BVN/Phone/OrderNo/Name"
                    onSearch={value => console.log(value)}
                    style={{width: 290}}
                    value={this.state.searchVal}
                    onChange={this.searchIpt.bind(this)}
                  />
                 </div>
              </div> */}
                <div
                  className="top_left"
                  style={{ width: "30%", flexShrink: 1 }}
                >
                  <div className="search">
                    <span className="description left">Order No:</span>
                    <Search
                      placeholder="Order No"
                      onSearch={(value) => console.log(value)}
                      style={{ width: 290 }}
                      value={this.state.orderNo}
                      onChange={(e) => {
                        this.setState({ orderNo: e.target.value });
                      }}
                    />
                  </div>
                </div>

                <div
                  className="top_mid"
                  style={{
                    width: "30%",
                    flexShrink: 2,
                    marginLeft: "5%",
                    marginRight: "5%",
                  }}
                >
                  <div className="rangepick">
                    <span className="description mid_span">Created time:</span>
                    <RangePicker
                      style={{ width: 290 }}
                      ranges={{
                        Today: [moment(), moment()],
                        "This Month": [
                          moment().startOf("month"),
                          moment().endOf("month"),
                        ],
                      }}
                      showTime
                      format={dateFormat}
                      onChange={this.rangeOnChange}
                      value={
                        this.state.from === undefined ||
                        this.state.to === undefined ||
                        this.state.from === "" ||
                        this.state.to === ""
                          ? null
                          : [
                              moment(this.state.from, dateFormat),
                              moment(this.state.to, dateFormat),
                            ]
                      }
                    />
                  </div>
                </div>
                <div
                  className="top_right"
                  style={{ width: "30%", flexShrink: 1 }}
                >
                  <div className="search">
                    <span className="description left">Phone:</span>
                    <Search
                      placeholder="Phone"
                      onSearch={(value) => console.log(value)}
                      style={{ width: 290 }}
                      value={this.state.mobile}
                      onChange={(e) => {
                        this.setState({ mobile: e.target.value });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                className="bottom"
                style={{
                  //   justifyContent: "space-between",
                  paddingLeft: 20,
                  paddingRight: 20,
                  alignItems: "center",
                }}
              >
                <div className="mid_right" style={{ width: "30%" }}>
                  <span className="description right" style={{ width: 120 }}>
                    OldOrNew:
                  </span>
                  <select
                    className="form_select"
                    value={this.state.oldOrNew}
                    onChange={this.oldOrderNew.bind(this)}
                  >
                    <option value="">all</option>
                    <option value="1">new User</option>
                    <option value="2">old User</option>
                  </select>
                </div>
                <div
                  className="top_right"
                  style={{
                    width: "30%",
                    flexShrink: 2,
                    marginLeft: "5%",
                    marginRight: "5%",
                  }}
                >
                  
                </div>
                <div
                  style={{
                    width: "30%",
                  }}
                >
                  <Button type="primary" style={{width:170,marginRight:20,height:40,border:'none'}}  className="common_color" onClick={this.searchClick.bind(this)}>Search</Button>
                <Button type="primary" style={{width:170,height:40,border:'none'}} className="common_color_scd" onClick={this.reloadClick.bind(this)}>Refresh</Button>
                </div>
              </div>
            </div>
          </div>
          </div>
          <div className='table_content'>
            <div className='button_group'>
                <Button type="primary" style={{width:170,marginRight:20,height:40,border:'none'}}  className="common_color" onClick={this.selected.bind(this)}>Batch lending</Button>
                <Button type="primary" style={{width:170,height:40,border:'none'}} className="common_color_scd" onClick={this.payall.bind(this)}>Pay All</Button>
            </div>
             
              <Table columns={columns}  rowKey={record=>record.id} rowSelection={rowSelection}  dataSource={this.state.tableScdData} scroll={{x:'100%'}} loading={this.state.boolean} onChange={this.handleChange} pagination={false} />
          </div>
          
        <Modal
            visible={this.state.fstvisible}
            onOk={this.fsthandleOk}
            onCancel={this.handleCancel}
        >
          <p>Are you sure to pay all the records?</p>
            
        </Modal>   

        <Modal
          visible={this.state.scdvisible}
          onOk={this.scdhandleOk}
          onCancel={this.handleCancel}
        >
        <p>Are you sure to pay all the pending payment orders?</p>
        </Modal>
         
        <Modal
          visible={this.state.thdvisible}
          onOk={this.thdhandleOk}
          onCancel={this.handleCancel}
        >
        <input value={this.state.disinpt} style={{display:'none'}}/>
        <p>Make sure you want to pass loan?</p>
        </Modal>

        <Modal
          title='Disapproval reason'
          visible={this.state.fouvisible}
          onOk={this.fouhandleOk}
          onCancel={this.handleCancel}
        >
        <input value={this.state.scddisinpt} style={{display:'none'}}/>
        <div className="model_last">
        <span  className="long">Reasons for rejection:</span>
        <textarea className='modal_textarea' style={{width:'60%',marginLeft:'4%'}} value={this.state.fsttextarea} onChange={this.fsttxarea}/>
        </div>
        </Modal>
        <Pagination
          showQuickJumper
          defaultCurrent={1} 
          current={this.state.current}
          total={this.state.total} 
          onChange={this.pageChange} 
          showTotal={showTotal}
          style={{textAlign:'right',marginTop:20}}
          pageSize={this.state.pageSize}
          showSizeChanger={true}
          onShowSizeChange={this.onShowSizeChange} //pagesize改变时回调函数
          pageSizeOptions={["10","50","100"]}   //设置pagesize区间
          />
        </div>
      );
    }
  }
  export default withRouter(PendingLoan)