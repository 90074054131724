import React from "react";
import {
  Input,
  Button,
  Table,
  Modal,
  Pagination,
  DatePicker,
  message,
  Row,
  Col,
} from "antd";
import "./index.css";
import axios from "axios";
import fstimg from "../../../../public/img/myorder_fst.png";
import scdimg from "../../../../public/img/myorder_scd.png";
import thdimg from "../../../../public/img/myorder_thd.png";
import fouimg from "../../../../public/img/myorder_fou.png";
import { withRouter } from "react-router-dom";
import EventEmitter from "../../../utils/events";
import {
  thousandSignNumber,
  formatT0Time,
  getCollectionResult,
} from "../../../utils/index";

import moment from "moment";
const { RangePicker } = DatePicker;
const { Search } = Input;
const dateFormat = "YYYY-MM-DD" || undefined;

function showTotal(total) {
  return `Total ${total} items`;
}

class collectionOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fstSelect: "",
      scdSelect: "",
      thdSelect: "1",
      fouSelect: "",
      searchVal: "",
      mobileNum: "",
      current: 1,
      fstinpt: "",
      from: "",
      ColSelect: "",
      scdafter: false,
      to: "",
      debit_money: "",
      order_statues: "please select one",
      modalfstSelect: 0,
      fstInput: "",
      fsttextarea: "",
      pageSize: 10,
      boolean: true,
      reviewbutton: { display: "block" },
      detailsbutton: { display: "block" },
      reviewreason: "",
      left_fst: "",
    };
  }
  //第个modal提交按钮
  fsthandleOk = (e) => {
    // console.log(e);//
    if (this.state.debit_money < 100) {
      alert("Debit amount must exceed 100");
      return false;
    }
    var data = {};
    if (this.state.debit_money == this.state.mostvalue) {
      data = {
        id: this.state.fstinpt,
        uid: this.state.scdinpt,
      };
    } else {
      data = {
        money: this.state.debit_money,
        id: this.state.fstinpt,
        uid: this.state.scdinpt,
      };
    }
    axios.post(`/api/loanUrge/withhold`, data).then((res) => {
      if (res.data.code === 200) {
        this.searchClick();
        alert(res.data.msg);
      } else if (res.data.code === 800) {
        this.props.history.push({ pathname: "/" });
      } else {
        alert(res.data.msg);
      }
    });
    this.setState({
      fstvisible: false,
    });
  };

  scdhandleOk = () => {
    this.setState({
      scdafter: true,
    });
    axios
      .post(`/api//userBank/blacklistAdd`, {
        mobile: this.state.thdinpt,
        remark: this.state.fsttextarea,
        reason: this.state.modalscdSelect,
        status: "1",
        uid: this.state.scdinpt,
      })
      .then((res) => {
        if (res.data.code === 200) {
          message.success(res.data.msg);
          this.setState({
            scdvisible: false,
            boolean: true,
            modalscdSelect: "",
            fsttextarea: "",
            reason: "",
            status: "",
            uid: "",
            scdafter: false,
          });
          this.searchClick();
        } else if (res.data.code === 800) {
          window.location.href = "/";
        } else {
          this.setState({
            scdvisible: false,
            boolean: true,
            modalscdSelect: "",
            fsttextarea: "",
            reason: "",
            status: "",
            scdafter: false,
            uid: "",
          });
        }
      });
  };
  fstInputChange = (e) => {
    var value = e.target.value;
    this.setState({
      fstInput: value.replace(/^(0+)|[^\d]+/g, ""),
    });
  };
  colortime = (record) => {
    let date11 = new Date(
      moment(new Date()).format("YYYY-MM-DD").replace(/-/g, "/")
    );
    let date22 = new Date(
      moment(new Date(record.collectionTime))
        .format("YYYY-MM-DD")
        .replace(/-/g, "/")
    );
    var date33 = date22.getTime() - date11.getTime();
    if (date33 == 0) {
      return <span style={{ color: "green" }}>{record.collectionTime}</span>;
    } else {
      return <span style={{ color: "red" }}>{record.collectionTime}</span>;
    }
  };
  //页码改变时触发
  pageChange = (page) => {
    this.setState(
      {
        current: page,
      },
      () => {
        this.searchClick();
      }
    );
  };
  rangeOnChange = (dates, dateStrings) => {
    this.setState({ from: dateStrings[0], to: dateStrings[1] });
  };
  //选择table触发
  handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    this.setState({});
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  handleCancel = () => {
    this.setState({
      fstvisible: false,
      scdvisible: false,
      modalfstSelect: 0,
      fstSelect: 0,
      mobile: "",
      remark: "",
      reason: "",
      status: "",
      uid: "",
      order_statues: "please select one",
      scdafter: false,
    });
  };
  searchClick = () => {
    this.setState({
      boolean: true,
    });
    axios
      .post(`/api/loanUrge/getMyCollectionList`, {
        pageSize: this.state.pageSize,
        current: this.state.current,
        indentNo: this.state.searchVal,
        mobile: this.state.mobileNum,
        appCode: this.state.fouSelect,
        beginTime: this.state.from,
        isCollection: this.state.ColSelect,
        endTime: this.state.to,
        remainBalance: this.state.fstInput,
        urgeResult: this.state.result,
      })
      .then((res) => {
        if (res.data.code === 200) {
          let data = res.data.data.resultDate;
          if (data.length < 1) {
            this.setState({
              boolean: false,
            });
          }
          let differCount = 0;
          let differAmount = 0;
          let different = 0;
          let a1 = res.data.data.collectionManage.differCount;
          let b1 = res.data.data.collectionManage.differAmount;

          if (a1 >= 0) {
            differCount = res.data.data.collectionManage.differCount;
          } else {
            differCount = 0;
          }
          if (b1 >= 0) {
            differAmount = res.data.data.collectionManage.differAmount / 100;
          } else {
            differAmount = 0;
          }
          if (a1 <= 0) {
            different = 15000;
          } else {
            different = 0;
          }
          this.setState({
            tableData: data,
            length: res.data.data.page.total,
            boolean: false,
            left_fst: res.data.data.collectionManage.totalCount,
            left_scd: res.data.data.collectionManage.curTotalCount,
            left_thd: res.data.data.collectionManage.turnoverVolumeCount,
            left_fou: res.data.data.collectionManage.totalBillCollection,
            left_second_fst: res.data.data.collectionManage.totalAmount / 100,
            left_second_scd:
              res.data.data.collectionManage.curTotalAmount / 100,
            left_second_thd:
              res.data.data.collectionManage.turnoverAmount / 100,
            left_second_fou:
              res.data.data.collectionManage.totalAmountDue / 100,
            left_thd_fst: res.data.data.collectionManage.kpiRate,
            left_thd_scd: res.data.data.collectionManage.paymentRecoveryRate,
            left_thd_thd:
              res.data.data.collectionManage.orderNumberRecoveryRate,
            left_fou_fst: differCount,
            left_fou_scd: differAmount,
            left_fou_thd: different,
          });
        } else if (res.data.code === 800) {
          this.props.history.push({ pathname: "/" });
        } else {
        }
      });
  };
  //重置按钮
  reloadClick(e) {
    this.setState(
      {
        fstSelect: 1,
        scdSelect: 0,
        thdSelect: 1,
        fouSelect: "",
        searchVal: "",
        mobileNum: "",
        ColSelect: "",
        from: undefined,
        to: undefined,
        boolean: true,
        fstInput: "",
      },
      () => {
        this.searchClick();
      }
    );
  }
  onShowSizeChange = (current, pageSize) => {
    this.setState(
      {
        pageSize: pageSize,
      },
      () => {
        this.searchClick();
      }
    );
  };
  debitMoney = (e) => {
    var value = e.target.value.replace(/[^0-9]/g, "");
    if (value > this.state.mostvalue) {
      value = this.state.mostvalue;
    }
    this.setState({
      debit_money: value,
    });
  };
  //控制审核按钮model
  showFstModal = (record) => {
    var id = record.id;
    var uid = record.uid;
    this.setState({
      fstinpt: id,
      scdinpt: uid,
      fstvisible: true,
      debit_money: record.alsoNeedBackp,
      mostvalue: record.alsoNeedBackp,
    });
    // this.gradeChange()
  };

  showScdModal = (record) => {
    axios
      .post(`/api/common/getCommonListByCode`, {
        code: "USER_BLACK_REASON",
      })
      .then((res) => {
        let getselect = [];
        if (res.data.code === 200) {
          for (let i = 0; i < res.data.data.length; i++) {
            if (i === 0) {
              getselect.push(<option value=""></option>);
            }
            getselect.push(
              <option value={res.data.data[i].name}>
                {res.data.data[i].name}
              </option>
            );

            this.setState({
              getselect: getselect,
            });
          }
        } else if (res.data.code === 800) {
          this.props.history.push({ pathname: "/" });
        } else {
        }
      });
    var mobile = record.mobile;
    var uid = record.uid;
    this.setState({
      scdinpt: uid,
      thdinpt: mobile,
      scdvisible: true,
    });
  };

  componentDidMount() {
    this.searchClick();
  }
  text(record) {
    window.location = "/#/details/index?id=" + record.id + "&uid=" + record.uid;
    let test = [
      {
        path: "/details/index",
        key: `/details/index/${record.id}/${record.uid}`,
        number: `${record.id}/${record.uid}`,
      },
    ];
    EventEmitter.trigger("btn", test[0]);
    EventEmitter.trigger("idanduid", test[0].number);
  }
  //输入框
  searchIpt(e) {
    var value = e.target.value;
    this.setState({ searchVal: value.replace(/\s+/g, "") });
  }
  //手机号输入框
  searchIpt1(e) {
    var value = e.target.value;
    this.setState({ mobileNum: value.replace(/\s+/g, "") });
  }
  //选择订单状态
  selectFst(e) {
    var value = e.target.value;
    this.setState({
      fstSelect: value,
    });
  }
  //选择审核时效
  selectThd(e) {
    var value = e.target.value;
    this.setState({
      thdSelect: value,
    });
  }
  //选择apptype
  selectFou(e) {
    var value = e.target.value;
    this.setState({
      fouSelect: value,
    });
  }

  //modal第二个select
  modelselectTwo = (e) => {
    var value = e.target.value;
    this.setState({
      modalscdSelect: value,
    });
  };
  modelselectthree = (e) => {
    var value = e.target.value;
    this.setState({
      modalthdSelect: value,
    });
  };
  //选择collectiontype
  selectCol(e) {
    var value = e.target.value;
    this.setState({
      ColSelect: value,
    });
  }
  //第一个textarea
  fsttxarea = (e) => {
    let value = e.target.value;
    this.setState({ fsttextarea: value });
  };

  render() {
    const columns = [
      {
        title: "Order No",
        dataIndex: "indent_no",
        key: "indent_no",
        width: 50,
      },
      {
        title: "Customer name",
        dataIndex: "first_name",
        key: "first_name",
      },
      {
        title: "phone",
        dataIndex: "mobile",
        key: "mobile",
      },
      {
        title: "loan amount",
        dataIndex: "balance",
        key: "balance",
        render: (text, record) => thousandSignNumber(text / 100),
      },
      {
        title: "loan term",
        dataIndex: "cycle",
        key: "cycle",
      },
      {
        title: "should repayment amount",
        dataIndex: "shouldback_fee",
        key: "shouldback_fee",
        render: (text, record) => thousandSignNumber(text / 100),
      },
      {
        title: "should repayment time",
        dataIndex: "shouldback_time",
        key: "shouldback_time",
        render: (text, record) => formatT0Time(text),
      },
      {
        title: "remaining balance",
        dataIndex: "remainingbalance",
        key: "remainingbalance",
        render: (text, record) =>
          thousandSignNumber(
            (record.shouldback_fee -
              record.actualback_amt -
              record.reduce_amt) /
              100
          ),
      },
      {
        title: "Overdue days",
        dataIndex: "overduedays",
        key: "overduedays",
      },
      {
        title: "Successful repayment count",
        dataIndex: "repayment_count",
        key: "repayment_count",
      },
      {
        title: "Allocate time",
        dataIndex: "allocate_time",
        key: "allocate_time",
      },
      {
        title: "Last collection time",
        dataIndex: "collectionTime",
        key: "collectionTime",
        render: (text, record) => this.colortime(record),
      },
      {
        title: "Collection Result",
        dataIndex: "CollectionResult",
        key: "CollectionResult",
        render: (text, record) => getCollectionResult(record.collectionResult),
      },
      {
        title: "collection detail",
        dataIndex: "collectionDetails",
        key: "collectionDetails",
      },
      {
        title: "Operation",
        dataIndex: "operating",
        key: "operating",
        width: 30,
        id: "id",
        inBlackList: "inBlackList",
        uid: "uid",
        fixed: "right",
        render: (text, record, index) => (
          <span style={{ display: "flex", flexDirection: "row" }}>
            {/* <Button
              type="primary"
              className="reviewbutton"
              style={this.state.reviewbutton}
              size="small"
              onClick={() => this.showFstModal(record)}
            >
              debit
            </Button> */}
            <Button
              type="primary"
              style={this.state.detailsbutton}
              className="detailsbutton"
              size="small"
              onClick={() => this.text(record)}
            >
              {" "}
              details
            </Button>
            {/* <Button type="primary"  className="reviewbutton" style={{display: record.inBlackList === 1 ? 'none' : 'block'}}  size="small" onClick={() => this.showScdModal(record)}>blacklist</Button> */}
          </span>
        ),
      },
    ];
    return (
      <div className="credit_order">
        <div className="myorder_content">
          <div className="flutter">
            <div className="order_content_top">
              <div className="top_fst">
                <div className="top_fst_top">
                  <div className="top_fst_top_left">
                    <span className="kpi">KPI</span>
                    <span className="order">/Order</span>
                  </div>
                  <div className="top_fst_top_right">
                    <img src={fstimg} />
                  </div>
                </div>
                <div className="top_fst_bot">
                  <div className="top_fst_bot_div">
                    <div className="top_fst_bot_div_left">
                      {" "}
                      <span className="top_circle"></span>
                      <span>Total allocate order</span>
                    </div>
                    <span>{this.state.left_fst}</span>
                  </div>
                  <div className="top_fst_bot_div">
                    <div className="top_fst_bot_div_left">
                      {" "}
                      <span className="top_circle"></span>
                      <span>Current order total</span>
                    </div>
                    <span>{this.state.left_scd}</span>
                  </div>
                  <div className="top_fst_bot_div">
                    <div className="top_fst_bot_div_left">
                      {" "}
                      <span className="top_circle"></span>
                      <span>Total transfer order</span>
                    </div>
                    <span>{this.state.left_thd}</span>
                  </div>
                  <div className="top_fst_bot_div">
                    <div className="top_fst_bot_div_left">
                      {" "}
                      <span className="top_circle"></span>
                      <span>Total repayment quantity</span>
                    </div>
                    <span>{this.state.left_fou}</span>
                  </div>
                </div>
              </div>
              <div className="top_scd">
                <div className="top_fst_top">
                  <div className="top_fst_top_left">
                    <span className="kpi">KPI</span>
                    <span className="order">/Amount</span>
                  </div>
                  <div className="top_fst_top_right">
                    <img src={scdimg} />
                  </div>
                </div>
                <div className="top_fst_bot">
                  <div className="top_fst_bot_div">
                    <div className="top_fst_bot_div_left">
                      {" "}
                      <span className="top_circle"></span>
                      <span>Total allocate amount</span>
                    </div>
                    <span>{this.state.left_second_fst}</span>
                  </div>
                  <div className="top_fst_bot_div">
                    <div className="top_fst_bot_div_left">
                      {" "}
                      <span className="top_circle"></span>
                      <span>Current amount total</span>
                    </div>
                    <span>{this.state.left_second_scd}</span>
                  </div>
                  <div className="top_fst_bot_div">
                    <div className="top_fst_bot_div_left">
                      {" "}
                      <span className="top_circle"></span>
                      <span>Total transfer amount</span>
                    </div>
                    <span>{this.state.left_second_thd}</span>
                  </div>
                  <div className="top_fst_bot_div">
                    <div className="top_fst_bot_div_left">
                      {" "}
                      <span className="top_circle"></span>
                      <span>Total repayment amount</span>
                    </div>
                    <span>{this.state.left_second_fou}</span>
                  </div>
                </div>
              </div>
              <div className="top_thd">
                <div className="top_fst_top">
                  <div className="top_fst_top_left">
                    <span className="kpi">KPI</span>
                    <span className="order">/Rate</span>
                  </div>
                  <div className="top_fst_top_right">
                    <img src={thdimg} />
                  </div>
                </div>
                <div className="top_fst_bot">
                  <div className="top_fst_bot_div">
                    <div className="top_fst_bot_div_left">
                      <span className="top_circle"></span>
                      <span>Target collection rate</span>
                    </div>
                    <span>{this.state.left_thd_fst}</span>
                  </div>
                  <div className="top_fst_bot_div">
                    <div className="top_fst_bot_div_left">
                      {" "}
                      <span className="top_circle"></span>
                      <span>Amount recovery rate</span>
                    </div>
                    <span>{this.state.left_thd_scd}</span>
                  </div>
                  <div className="top_fst_bot_div">
                    <div className="top_fst_bot_div_left">
                      {" "}
                      <span className="top_circle"></span>
                      <span>Order recovery rate</span>
                    </div>
                    <span>{this.state.left_thd_thd}</span>
                  </div>
                </div>
              </div>
              <div className="top_fou">
                <div className="top_fst_top">
                  <div className="top_fst_top_left">
                    <span className="kpi">KPI</span>
                    <span className="order">/Goals</span>
                  </div>
                  <div className="top_fst_top_right">
                    <img src={fouimg} />
                  </div>
                </div>
                <div className="top_fst_bot">
                  <div className="top_fst_bot_div">
                    <div className="top_fst_bot_div_left">
                      <span className="top_circle"></span>
                      <span>Order gap</span>
                    </div>
                    <span>{this.state.left_fou_fst}</span>
                  </div>
                  <div className="top_fst_bot_div">
                    <div className="top_fst_bot_div_left">
                      {" "}
                      <span className="top_circle"></span>
                      <span>Amount gap</span>
                    </div>
                    <span>{this.state.left_fou_scd}</span>
                  </div>
                  {/* <div className="top_fst_bot_div">
                    <div className="top_fst_bot_div_left"> <span className="top_circle"></span>
                        <span>Prospective bonus</span></div>
                        <span>{this.state.left_fou_thd}</span>
                    </div> */}
                </div>
              </div>
            </div>
            <div className="collectio_order_mid">
              <Row className="search_form">
                <Col span={8}>
                  <div className="search">
                    <p className="form_label">Order No:</p>
                    <Search
                      placeholder="Order No"
                      onSearch={(value) => console.log(value)}
                      style={{ width: 290 }}
                      value={this.state.searchVal}
                      onChange={this.searchIpt.bind(this)}
                    />
                  </div>
                </Col>
                <Col span={8}>
                  <div className="top_mid">
                    <p className="form_label">Phone:</p>
                    <Search
                      placeholder="Phone"
                      onSearch={(value) => console.log(value)}
                      style={{ width: 290 }}
                      value={this.state.mobileNum}
                      onChange={this.searchIpt1.bind(this)}
                    />
                  </div>
                </Col>
                <Col span={8}>
                  <div className="rangepick">
                    <p className="form_label">should repay time:</p>
                    <RangePicker
                      style={{ width: 290 }}
                      ranges={{
                        Today: [moment(), moment()],
                        "This Month": [
                          moment().startOf("month"),
                          moment().endOf("month"),
                        ],
                      }}
                      showTime
                      format={dateFormat}
                      onChange={this.rangeOnChange}
                      value={
                        this.state.from === undefined ||
                        this.state.to === undefined ||
                        this.state.from === "" ||
                        this.state.to === ""
                          ? null
                          : [
                              moment(this.state.from, dateFormat),
                              moment(this.state.to, dateFormat),
                            ]
                      }
                    />
                  </div>
                </Col>
                <Col span={8}>
                  <div className="top_right">
                    <p className="form_label">Collection Type:</p>
                    <select
                      className="form_select"
                      value={this.state.ColSelect}
                      onChange={this.selectCol.bind(this)}
                    >
                      <option value=" ">ALL</option>
                      <option value="1">Had collection</option>
                      <option value="0">Not collection</option>
                    </select>
                  </div>
                </Col>
              </Row>
              <div className="bottom">
                <Button
                  type="primary"
                  className="common_color"
                  style={{
                    width: 170,
                    marginRight: 20,
                    height: 40,
                    border: "none",
                  }}
                  onClick={this.searchClick.bind(this)}
                >
                  Search
                </Button>
                {/* <Button
                  type="primary"
                  style={{
                    width: 170,
                    height: 40,
                    backgroundColor: "#1CA9BA",
                    border: "none",
                  }}
                  onClick={this.reloadClick.bind(this)}
                >
                  Refresh
                </Button> */}
              </div>
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={this.state.tableData}
          scroll={{ x: "100%" }}
          loading={this.state.boolean}
          onChange={this.handleChange}
          pagination={false}
        />

        <Modal
          title="Add To BlackList"
          width={700}
          onCancel={this.handleCancel}
          visible={this.state.scdvisible}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={this.state.scdafter}
              onClick={this.scdhandleOk}
            >
              ok
            </Button>,
          ]}
        >
          <input
            type="text"
            value={this.state.scdinpt}
            style={{ display: "none" }}
          />
          <input
            type="text"
            value={this.state.thdinpt}
            style={{ display: "none" }}
          />
          <div style={this.state.inptdis}>
            <span style={{ fontSize: 15, fontWeight: "bold" }}>Reason</span>
            <select
              className="model_fst"
              value={this.state.modalscdSelect}
              onChange={this.modelselectTwo.bind(this)}
            >
              {this.state.getselect}
            </select>
          </div>

          <br />
          <div className="model_last">
            <label style={{ fontSize: 15, fontWeight: "bold" }}>Remark</label>
            <textarea
              style={{ width: "68%", marginLeft: "3%", height: 80 }}
              value={this.state.fsttextarea}
              onChange={this.fsttxarea}
            />
          </div>
        </Modal>

        <Modal
          title="debet"
          visible={this.state.fstvisible}
          onOk={this.fsthandleOk}
          onCancel={this.handleCancel}
        >
          <input
            type="text"
            value={this.state.fstinpt}
            style={{ display: "none" }}
          />
          <input
            type="text"
            value={this.state.scdinpt}
            style={{ display: "none" }}
          />
          <div className="both_div">
            <span>debit amount:</span>
            <input
              value={this.state.debit_money}
              onChange={this.debitMoney}
              className="reduce_inpt"
            />
          </div>
        </Modal>

        <Pagination
          showSizeChanger //展示改变pagesize样式
          onShowSizeChange={this.onShowSizeChange} //pagesize改变时回调函数
          pageSizeOptions={["10", "50", "100"]} //设置pagesize区间
          defaultCurrent={1}
          current={this.state.current}
          total={this.state.length}
          onChange={this.pageChange}
          showTotal={showTotal}
          style={{ textAlign: "right", marginTop: 20 }}
          pageSize={this.state.pageSize}
          // showSizeChanger={true}
        />
      </div>
    );
  }
}
export default withRouter(collectionOrder);
