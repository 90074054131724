/*eslint-disable*/
import React from 'react';
import { Button,Table,DatePicker,Pagination,message } from 'antd';
import './index.css';
import axios from 'axios'
import moment from 'moment';
import {withRouter} from 'react-router-dom'

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD'||undefined;

function showTotal(total) {
  return `Total ${total} items`;
}

class dayArrive extends React.Component{
    constructor(props){
        super(props);
        this.state={
          from:'',
          to:'',
          tableData:[], 
          current:1,
          length:1,
          pageSize:10,
          boolean:true

        }
      }
      componentDidMount(){
        this.secondTable();
      }
      //设置时间
      rangeOnChange=(dates, dateStrings)=>{
        this.setState({ from: dateStrings[0],to:dateStrings[1] })
      }
    
    reloadClick(e){
      this.setState({
        from:undefined,
        to:undefined,
        boolean:true
      },()=>{
        this.secondTable()
      });
     
      }
    showmodal=()=>{
        alert(this.index)
    }
    pageChange=(page)=>{
      this.setState({
        current: page
        },()=>{
          this.searchClick()
        })
    }

//页面初始化加载的table 
  secondTable=()=>{
    // if(this.props.location.state == undefined){
    //   this.props.history.push({ pathname: "/home/index"});
    //   return
    // }
    let urlid = ''
    var url = window.location.hash
    var params = url.substr(1);
    var aPos = params.indexOf("=")
     urlid = params.substr(aPos + 1);
    axios
      .post(`/api/loan/credit/getCreditDaysReviewerStatisticsSearchByArriveTime`,{
      pageSize: this.state.pageSize,
      current: this.state.current,
      userId:urlid,
    })
    .then(res=>{
      if(res.data.code === 200){
        const data1 = [];
        const data = res.data.data.resultDate
        if(data.length<1){
          this.setState({
            boolean:false,
          })
        }else{
          for (let i = 0; i < data.length; i++) {
            data1.push({
              key: i,
              reviewer:data[i].reviewer,
              date:data[i].date,
              loanCount:data[i].loanCount,
              shouldbackCount:data[i].shouldbackCount,
              backCount:data[i].backCount,
              overdueCount:data[i].overdueCount,
              overdueRate:data[i].overdueRate,
              overduebackCount:data[i].overduebackCount,
              overduenotbackCount:data[i].overduenotbackCount,
            });
           
          }
        }
        
        this.setState({
          tableScdData:data1,
          length:res.data.data.page.total,
          boolean:false

        })
      }else if(res.data.code ==800){
        this.props.history.push({ pathname: "/"});
      }else{
      }
    })
    
  }

          //搜索
          searchClick=()=>{ 
            this.setState({
              boolean:true
            })
            let urlid = ''
            var url = window.location.hash
            var params = url.substr(1);
            var aPos = params.indexOf("=")
             urlid = params.substr(aPos + 1);                
              axios
                    .post(`/api/loan/credit/getCreditDaysReviewerStatisticsSearchByArriveTime`,{

                    beginTime:this.state.from,
                    endTime:this.state.to,
                    pageSize: this.state.pageSize,
                    userId:urlid,
                    current: this.state.current,
                  })
                  .then(res=>{
                    if(res.data.code === 200){
                      const data1 = [];
                      const data = res.data.data.resultDate
                      if(data.length<1){
                        this.setState({
                          boolean:false
                        })
                      }else{
                        for (let i = 0; i < data.length; i++) {
                          data1.push({
                            key: i,
                            reviewer:data[i].reviewer,
                            date:data[i].date,
                            loanCount:data[i].loanCount,
                            shouldbackCount:data[i].shouldbackCount,
                            backCount:data[i].backCount,
                            overdueCount:data[i].overdueCount,
                            overdueRate:data[i].overdueRate,
                            overduebackCount:data[i].overduebackCount,
                            overduenotbackCount:data[i].overduenotbackCount,
                          });                         
                        } 
                      }
                      this.setState({
                        tableScdData:data1,
                        boolean:false
                      })
                    }else if(res.data.code ==800){
                      this.props.history.push({ pathname: "/"});
                    }else{
                    }
                  })
                  
                }
    render(){
        

        const columns = [
           {
              title: 'Date',
              dataIndex: 'date',
              key: 'date'
            }, {
              title: 'Reviewer',
              dataIndex: 'reviewer',
              key: 'reviewer'
            }, {
              title: 'Lending order number',
              dataIndex: 'loanCount',
              key: 'loanCount'
            }, {
              title: 'Due order number',
              dataIndex: 'shouldbackCount',
              key: 'shouldbackCount'
            }, {
              title: 'Normal repayment number',
              dataIndex: 'backCount',
              key: 'backCount'
            }, {
              title: 'Overdue number',
              dataIndex: 'overdueCount',
              key: 'overdueCount'
            },
            {
                title: 'Overdue rate after due date',
                dataIndex: 'overdueRate',
                key: 'overdueRate'
              }, {
                title: 'Repayment number of overdue customer',
                dataIndex: 'overduebackCount',
                key: 'overduebackCount'
              }, {
                title: 'Currently remaining overdue number',
                dataIndex: 'overduenotbackCount',
                key: 'overduenotbackCount'
              },
          ];
        return(
            <div className="audit_content">
                 <div className="commom_top" style={{alignItems:'center'}}>
                 <RangePicker
                ranges={{
                Today: [moment(), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                }}
                showTime
                format={dateFormat}
                onChange={this.rangeOnChange}
                value={this.state.from===undefined||this.state.to===undefined||this.state.from===""||this.state.to===""?null:[moment(this.state.from, dateFormat), moment(this.state.to, dateFormat)]}
                />
                <Button type="primary" style={{width:170,marginLeft:'15%',marginRight:50,height:40,border:'none'}}  className="common_color" onClick={this.searchClick.bind(this)}>Search</Button>
                <Button type="primary" style={{width:170,height:40,border:'none',}} className="common_color_scd" onClick={this.reloadClick.bind(this)}>Refresh</Button>
                </div>
                <div className="table">
                <Table columns={columns} loading={this.state.boolean} dataSource={this.state.tableScdData} pagination={false} scroll={{ x: '100%' }}/>
                </div>

                <Pagination
                  defaultCurrent={1} 
                  current={this.state.current}
                  total={this.state.length} 
                  onChange={this.pageChange} 
                  showTotal={showTotal}
                  pageSize={this.state.pageSize}
                  style={{textAlign:'right',marginTop:20}}
                  showSizeChanger={true}
                  />
            </div>
        )
    }
}
export default withRouter(dayArrive)