/*eslint-disable*/
import React from "react";
import {
  Input,
  Button,
  Switch,
  Table,
  Modal,
  Radio,
  Pagination,
  DatePicker,
  Tooltip,
  message,
} from "antd";
import "./index.css";
import axios from "axios";
import moment from "moment";
import { withRouter } from "react-router-dom";
import EventEmitter from "../../utils/events";
var getSelect = [];
var getselect = [];
function showTotal(total) {
  return `Total ${total} items`;
}

const { Search } = Input;
const key = [];
class AccountManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 1,
      data: [],
      tableData: [],
      searchVal: "",
      current: 1,
      total: 1,
      pageSize: 50,
      boolean: true,
      ishide: false,
      isshow: true,
      ids: "",
      mobilephone: "",
      opened: false,
      arr: [],
      showhide: false,
      overdugrade: null,
      editoverdugrade: null,
      modalthdSelect: 0,
      selectoverdugrade: "",
      fstAddVal: "",
      scdAddVal: "",
      thdAddVal: "",
      fivAddVal: "",
      sixAddVal: "",
      reset_password_button:'',
      kill_service_button:'',
      kill_nginx_button:'',
      spePassShow:"",
      spePassType:"1",
      spePassText:"",
      bach_reset_password_button:''
    };
  }
  //页码改变时触发
  pageChange = (page) => {
    this.setState(
      {
        current: page,
      },
      () => {
        this.searchClick();
      }
    );
  };
  radioOnChange = (e) => {
    console.log("radio checked", e.target.value);
    this.setState({
      radvalue: e.target.value,
    });
  };

  edit = (record) => {
    if (
      record.grade == 1 ||
      record.grade == 2 ||
      record.grade == 3 ||
      record.grade == 4 ||
      record.grade == 5 ||
      record.grade == 6
    ) {
      this.setState({
        hideshow: true,
        editoverdugrade: record.grade,
      });
    } else {
      this.setState({
        hideshow: false,
      });
    }
    axios
      .post(`/api/system/managerGet`, {
        id: record.id,
      })
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            fstval: record.gender,
            scdval: record.firstName,
            thdval: record.surName,
            radvalue: record.status,
            sevenAddVal: res.data.data.remark,
          });
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
          message.error(res.data.msg);
        }
      });
    this.setState({
      fstvisible: true,
      id: record.id,
    });
  };
  edithandleOk = (record, e) => {
    axios
      .post(`/api/system/managerEdit`, {
        id: this.state.id,
        userWorkNumber: this.state.fstval,
        overdueGrade: this.state.editoverdugrade,
        accountStatus: this.state.radvalue,
        remark: this.state.sevenAddVal,
      })
      .then((res) => {
        if (res.data.code === 200) {
          message.success(res.data.msg);

          this.setState({
            fstval: "",
            scdval: "",
            value4: "",
            sevenAddVal: "",
          });
          this.secondTable();
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
          message.error(res.data.msg);
          this.setState({
            fstval: "",
            scdval: "",
            value4: "",
          });
        }
      });
    this.setState({
      fstvisible: false,
    });
  };
  Add = () => {
    this.setState({
      scdvisible: true,
      showhide: false,
      showhide2: false,
      showhide3: false,
      sevenAddVal: "",
    });
  };


  ResetPassword = () =>{
    this.setState({
      closePsd:true
    })
  };




  closedTube = () =>{
    this.setState({
      closedTube:true
    })
  }

  closedNginx =() =>{
    this.setState({
      closedNginx:true
    })
  }


  ResetSpePassword = () =>{
    this.setState({
      spePassShow:true,
      spePassText:"",
      spePassType:"1"
    })
  };

  spePassTextonChange = (e) => {
    let value = e.target.value;
    this.setState({
      spePassText: value,
    });
  };

  handelSpePassType = e => {
    this.setState({
      spePassType: e.target.value
    });
  };

  /*添加modal点击ok*/
  addhandleOk = () => {
    if (this.state.fstAddVal == "") {
      alert("login name can not empty");
      return false;
    } else if (this.state.scdAddVal == "") {
      alert("job number can not empty");
      return false;
    }
    axios
      .post(`/api/system/managerAdd`, {
        userRoleld: this.state.modalthdSelect,
        overdueGrade: this.state.overdugrade,
        loginName: this.state.fstAddVal,
        userWorkNumber: this.state.scdAddVal,
        remark: this.state.sevenAddVal,
      })
      .then((res) => {
        if (res.data.code === 200) {
          message.success(res.data.msg);
          this.secondTable();
          this.setState({
            modalthdSelect: 0,
            overdugrade: null,
            fstAddVal: "",
            scdvisible: false,
            scdAddVal: "",
            thdAddVal: "",
            fivAddVal: "",
            sixAddVal: "",
            fouAddVal: "",
            sevenAddVal: "",
          });
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
          message.error(res.data.msg);
          this.setState({
            modalthdSelect: 0,
            overdugrade: null,
            fstAddVal: "",
            scdvisible: false,
            scdAddVal: "",
            thdAddVal: "",
            fivAddVal: "",
            fouAddVal: "",
            sixAddVal: "",
            sevenAddVal: "",
          });
          this.secondTable();
        }
      });
  };

  handleResetPass = () => {
    if (this.state.spePassText == "") {
      alert("Please enter login name or job number");
      return false;
    }
    axios
        .post(`/api/system/batchpassword`, {
          name: this.state.spePassText,
          type: this.state.spePassType
        })
        .then((res) => {
          if (res.data.code === 200) {
            message.success(res.data.msg);
           // this.secondTable();
            this.setState({
              spePassText: "",
              spePassShow: false
            });
          } else if (res.data.code == 800) {
            this.props.history.push({ pathname: "/" });
          } else {
            message.error(res.data.msg);
            this.setState({
              spePassText: "",
              spePassShow: false
            });
          //  this.secondTable();
          }
        });
  };

  delhandleOk = () => {
    axios
      .post(`/api/system/managerDel`, {
        id: this.state.delval,
      })
      .then((res) => {
        if (res.data.code === 200) {
          message.success(res.data.msg);
          this.secondTable();
          this.setState({
            thdvisible: false,
          });
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
          message.error(res.data.msg);
          this.secondTable();
          this.setState({
            thdvisible: false,
          });
        }
      });
  };
  modelselectThree = (e) => {
    var value = e.target.value;
    this.setState({
      modalthdSelect: value,
      showhide: false,
      showhide2: false,
      showhide3: false,
    });
    if (value == 6) {
      this.setState({
        showhide: true,
      });
    } else if (value == 15) {
      this.setState({
        showhide2: true,
      });
    } else if (value == 10) {
      this.setState({
        showhide3: true,
      });
    }
  };
  fstselect = (e) => {
    var value = e.target.value;
    this.setState({
      fstSelect: value,
    });
  };
  searchemailIpt = (e) => {
    var value = e.target.value;
    this.setState({
      searchemail: value.replace(/\s+/g, ""),
    });
  };
  searchnameIpt = (e) => {
    var value = e.target.value;
    this.setState({
      searchname: value,
    });
  };

  overdueselect = (e) => {
    var value = e.target.value;
    this.setState({
      overdugrade: value,
    });
  };
  selectstatus = (e) => {
    var value = e.target.value;
    this.setState({
      statusSelect: value,
    });
  };

  selectoverdueselect = (e) => {
    var value = e.target.value;
    this.setState({
      selectoverdugrade: value,
    });
  };
  editoverdueselect = (e) => {
    var value = e.target.value;
    this.setState({
      editoverdugrade: value,
    });
  };
  //获取select
  select = () => {
    axios.post(`/api/system/managerRoleList`, {}).then((res) => {
      if (res.data.code === 200) {
        getSelect = [];
        let data = res.data.data;
        for (let i = 0; i < res.data.data.length; i++) {
          if (i == 0) {
            getSelect.push(
              <option value="" key={"all"}>
                all
              </option>
            );
          }
          getSelect.push(
            <option id={data} value={data[i].roleName} key={data[i].roleName}>
              {data[i].roleName}
            </option>
          );
          // console.log('select',getSelect)
          this.setState({
            getSelect: getSelect,
          });
        }
      } else if (res.data.code == 800) {
        // this.props.history.push({ pathname: "/"});
      }
    });
  };

  //获取select
  scdselect = () => {
    axios.post(`/api/system/managerRoleList`, {}).then((res) => {
      if (res.data.code === 200) {
        getselect = [];
        let data = res.data.data;
        for (let i = 0; i < res.data.data.length; i++) {
          if (i == 0) {
            getselect.push(<option value="" key="1"></option>);
          }
          getselect.push(
            <option id={data} value={data[i].id} key={data[i].roleName}>
              {data[i].roleName}
            </option>
          );
          // console.log('select',getSelect)
          this.setState({
            getselect: getselect,
          });
        }
      } else if (res.data.code == 800) {
        // this.props.history.push({ pathname: "/"});
      }
    });
  };
  handleCancel = () => {
    this.setState({
      fstvisible: false,
      scdvisible: false,
      thdvisible: false,
      fouvisible: false,
      closePsd:false,
      closedTube:false,
      closedNginx:false,
      modalthdSelect: 0,
      overdugrade: 0,
      fstAddVal: "",
      scdAddVal: "",
      thdAddVal: "",
      fivAddVal: "",
      sixAddVal: "",
      fouAddVal: "",
      sevenAddVal: "",
      spePassShow:false,
      spePassType:"1",
      spePassText:""
    });
  };

  //页面初始化加载下面的table
  secondTable = () => {
    this.searchClick();
    this.select();
    this.searchRole()
  };
  searchRole = () =>{
    axios.post(`/api/system/isPermission`,{
      buttonType:['reset_password_button','kill_service_button','kill_nginx_button','bach_reset_password_button']
    }).then(res=>{
      console.log(res)
      if(res.data.code == 200){
        this.setState({
          reset_password_button:res.data.data.reset_password_button,
          kill_service_button:res.data.data.kill_service_button,
          kill_nginx_button:res.data.data.kill_nginx_button,
          bach_reset_password_button:res.data.data.bach_reset_password_button
        })
      }
    })
  }

  //搜索
  searchClick = () => {
    this.setState({
      boolean: true,
    });
    axios
      .post(`/api/system/managerList`, {
        pageNum: this.state.current,
        pageSize: this.state.pageSize,
        roleName: this.state.fstSelect,
        userWorkNumber: this.state.searchemail,
        loginName: this.state.searchname,
        accountStatus: this.state.statusSelect,
        overdueGrade: this.state.selectoverdugrade,
      })
      .then((res) => {
        if (res.data.code === 200) {
          const data1 = [];
          const data = res.data.data.resultData;

          for (let i = 0; i < data.length; i++) {
            data[i].key = i;
            let status = data.map((item) => {
              return item.resourceStatus;
            });
            this.setState({
              switchStatus: status,
            });

            data1.push({
              key: i,
              firstName: data[i].loginName,
              middleName: data[i].roleName,
              surName: data[i].userPhone,
              gender: data[i].userWorkNumber,
              bvnNo: data[i].lastLoginTime,
              status: data[i].accountStatus,
              grade: data[i].overdueGrade,
              id: data[i].id,
              remark: data[i].remark,
              operator: data[i].operatorName,
              operatorTime: data[i].operationTime,
              createTime: data[i].createTime,
            });
          }
          this.setState({
            tableScdData: data1,
            boolean: false,
            length: res.data.data.page.total,
          });
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
        }
      });
  };
  handClick = (item) => {
    console.log("1111111122222233333", item);
  };
  /*重置密码按钮开始*/
  reset = (record) => {
    let Num = "";
    for (let i = 0; i < 6; i++) {
      Num += Math.floor(Math.random() * 10);
    }
    this.setState({
      fouvisible: true,
      num: Num,
      resval: record.id,
    });
  };
  /*重置按钮结束*/

  reshandleOk = () => {
    axios
      .post(`/api/system/managerReset`, {
        id: this.state.resval,
        password: this.state.num,
      })
      .then((res) => {
        if (res.data.code === 200) {
          message.success(res.data.msg);
          this.searchClick();
          this.setState({
            fouvisible: false,
          });
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
          message.error(res.data.msg);
          this.searchClick();
          this.setState({
            fouvisible: false,
          });
        }
      });
  };
  // 重置所有用户密码
  resetOK = () =>{
    axios.get(`/api/system/setRandPassword`).then((res) =>{
      if(res.data.code == 200){
        message.success(res.data.msg)
        this.searchClick();
        this.handleCancel()
      }else{
        message.error(res.data.msg)
        this.searchClick();
        this.handleCancel()
      }
    })
  }

  //是否关闭后管服务
  closedTubeOK = () =>{
    axios.get(`/api/system/killservice`).then((res) =>{
      if(res.data.code == 200){
        message.success(res.data.msg)
        this.searchClick();
        this.handleCancel()
      }else{
        message.error(res.data.msg)
        this.searchClick();
        this.handleCancel()
      }
    })
  }

  //是否关闭nginx服务
  closedNginxOK = () =>{
    axios.get(`/api/system/killsnginx`).then((res) =>{
      console.log(res)
      if(res.data.code == 200){
        message.success(res.data.msg)
        this.searchClick();
        this.handleCancel()
      }else{
        message.error(res.data.msg)
        this.searchClick();
        this.handleCancel()
      }
    })
  }

  /*add弹框内容开始*/
  fstonChange = (e) => {
    let value = e.target.value;
    this.setState({
      fstval: value,
    });
  };
  fstaddonChange = (e) => {
    let value = e.target.value;
    this.setState({
      fstAddVal: value,
    });
  };
  scdonChange = (e) => {
    let value = e.target.value;
    this.setState({
      scdval: value,
    });
  };
  scdaddonChange = (e) => {
    let value = e.target.value;
    this.setState({
      scdAddVal: value,
    });
  };
  thdonChange = (e) => {
    let value = e.target.value;
    this.setState({
      thdval: value,
    });
  };
  thdaddonChange = (e) => {
    let value = e.target.value;
    this.setState({
      thdAddVal: value,
    });
  };
  fouonChange = (e) => {
    let value = e.target.value;
    this.setState({
      fouval: value,
    });
  };
  fouaddonChange = (e) => {
    let value = e.target.value;
    this.setState({
      fouAddVal: value,
    });
  };
  fivaddonChange = (e) => {
    let value = e.target.value;
    this.setState({
      fivAddVal: value,
    });
  };
  sixaddonChange = (e) => {
    let value = e.target.value;
    this.setState({
      sixAddVal: value,
    });
  };
  sevenaddonChange = (e) => {
    let value = e.target.value;
    this.setState({
      sevenAddVal: value,
    });
  };



  /*add弹框内容结束*/
  componentDidMount() {
    this.secondTable();
    this.scdselect();
  }

  render() {
    const columns = [
      {
        title: "Login name",
        dataIndex: "firstName",
        key: "firstName",
        render: (value) => {
          return value || "";
        },
      },
      {
        title: "User role",
        dataIndex: "middleName",
        key: "middleName",
        render: (value) => {
          return value || "";
        },
      },
      {
        title: "Job NO.",
        dataIndex: "gender",
        key: "gender",
      },
      {
        title: "create time",
        dataIndex: "createTime",
        key: "createTime",
        render: (value) => value,
      },
      {
        title: "Last login time",
        dataIndex: "bvnNo",
        key: "bvnNo",
        render: (value) => {
          let timeaudit =
            new Date(value).getTime() +
            new Date().getTimezoneOffset() * 60 * 1000 -
            0 * 60 * 60 * 1000;
          let auditimes = "";
          if (value == null) {
            return (auditimes = "");
          } else {
            return (auditimes = moment(new Date(timeaudit)).format(
              "YYYY-MM-DD HH:mm:ss"
            ));
          }
        },
      },
      {
        title: "Grade",
        dataIndex: "grade",
        key: "grade",
        render: (value, record) => {
          if (value == 21) {
            return "S0-Old";
          } else if (value == 1) {
            return "S1-Old";
          } else if (value == 2) {
            return "S2-Old";
          } else if (value == 3) {
            return "M1-Old";
          } else if (value == 4) {
            return "M2";
          } else if (value == 5) {
            return "M3";
          } else if (value == 6) {
            return "M4";
          } else if (value == 22) {
            return "S0-New";
          } else if (value == 11) {
            return "S1-New";
          } else if (value == 12) {
            return "S2-New";
          } else if (value == 13) {
            return "M1-New";
          } else if (value == 31) {
            return "New customer";
          } else if (value == 32) {
            return "Old customer";
          } else {
            return "";
          }
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (value) => {
          if (value == 0) {
            return "normal";
          } else if (value == -2) {
            return "disable";
          } else if (value == 1) {
            return 'suspend'
          }
        },
      },
      {
        title: "operator",
        dataIndex: "operator",
        key: "operator",
      },
      {
        title: "Operation time",
        dataIndex: "operatorTime",
        key: "operatorTime",
        render: (value) => {
          if (!value) {
            return "";
          }
          let timeaudit =
            new Date(value).getTime() +
            new Date().getTimezoneOffset() * 60 * 1000 -
            0 * 60 * 60 * 1000;
          let auditimes = "";
          if (value == null) {
            return (auditimes = "");
          } else {
            return (auditimes = moment(new Date(timeaudit)).format(
              "YYYY-MM-DD HH:mm:ss"
            ));
          }
        },
      },
      {
        title: "remark",
        dataIndex: "remark",
        key: "remark",
      },
      {
        title: "Operation",
        dataIndex: "",
        hidename: "hidename",
        key: "operation",
        render: (text, record) => (
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              margin: 0,
            }}
          >
            <a
              className="open_button common_color"
              href="#!"
              onClick={() => this.edit(record)}
            >
              Edit
            </a>
            <a
              className="edit_button"
              href="#!"
              onClick={(e) => this.reset(record)}
            >
              Reset
            </a>
          </span>
        ),
      },
    ];
    return (
      <div className="menu_content">
        <div
          style={{
            width: "98%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <p className="details_tab1_top_p"></p>
          <Button
            className="tab2_button"
            type="primary"
            style={{ marginTop: 15 }}
            onClick={this.Add}
          >
            Add
          </Button>

          <Button
              className="tab2_button"
              type="primary"
              style={{ marginTop: 15,
                marginLeft:10,
                marginRight:10,display: this.state.bach_reset_password_button ? "inline-block" : "none", }}
              onClick={this.ResetSpePassword}
          >
           reset  specified password
          </Button>
          <Button
            className="tab2_button"
            type="primary"
            style={{
              marginTop: 15,
              marginLeft:10,
              marginRight:10,
              display: this.state.reset_password_button ? "inline-block" : "none",
            }}
            onClick={this.ResetPassword}
          >
            ResetPassword
          </Button>
          <Button
            className="tab2_button"
            type="primary"
            style={{
              marginTop: 15,
              marginLeft:10,
              marginRight:10,
              display: this.state.kill_service_button ? "inline-block" : "none",
            }}
            onClick={this.closedTube}
          >
            closedTube
          </Button>
          <Button
            className="tab2_button"
            type="primary"
            style={{
              marginTop: 15,
              display: this.state.kill_nginx_button ? "inline-block" : "none",
            }}
            onClick={this.closedNginx}
          >
            closedNginx
          </Button>
        </div>
        <div
          className="commom_top"
          style={{ flexDirection: "column", marginTop: 20 }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div className="mid_left" style={{ float: "left", marginLeft: 20 }}>
              <span className="description_new" style={{ width: 88 }}>
                Login name:
              </span>
              <Search
                placeholder="Login name"
                onSearch={(value) => console.log(value)}
                style={{ width: 193 }}
                value={this.state.searchname}
                onChange={this.searchnameIpt}
              />
            </div>
            <div className="mid_mid" style={{ float: "left" }}>
              <span className="description_new">Job NO.:</span>
              <Search
                placeholder="Job NO"
                onSearch={(value) => console.log(value)}
                style={{ width: 194 }}
                value={this.state.searchemail}
                onChange={this.searchemailIpt}
              />
            </div>
            <div className="mid_mid" style={{ float: "left" }}>
              <span className="description_new">Role:</span>
              <select
                className="menu_form_select_new"
                style={{ width: 195 }}
                placeholder="please select one"
                id="modal_sel ect"
                value={this.state.fstSelect}
                onChange={this.fstselect}
              >
                {getSelect}
              </select>
            </div>
            <div className="mid_mid" style={{ float: "left" }}>
              <span className="description_new">Status:</span>
              <select
                className="menu_form_select_new"
                style={{ width: 195 }}
                value={this.state.statusSelect}
                onChange={this.selectstatus}
              >
                <option value="">all</option>
                <option value="0">normal</option>
                <option value="-2">disable</option>
                <option value="1">suspend</option>
              </select>
            </div>
            <div className="mid_mid" style={{ float: "left" }}>
              <span className="description_new">Grade:</span>
              <select
                className="menu_form_select_new"
                style={{ width: 195 }}
                value={this.state.selectoverdugrade}
                onChange={this.selectoverdueselect}
              >
                <option value="">all</option>
                <option value="21">S0-Old</option>
                <option value="1">S1-Old</option>
                <option value="2">S2-Old</option>
                <option value="3">M1-Old</option>
                <option value="4">M2</option>
                <option value="5">M3</option>
                <option value="6">M4</option>
                <option value="22">S0-New</option>
                <option value="11">S1-New</option>
                <option value="12">S2-New</option>
                <option value="13">M1-New</option>
              </select>
            </div>
          </div>

          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
          >
            <Button
              type="primary"
              className="button_style common_color"
              style={{ width: 170, marginRight: 20, height: 40 }}
              onClick={this.searchClick.bind(this)}
            >
              Search
            </Button>
          </div>
        </div>
        <div className="table">
          <Table
            columns={columns}
            className="old_table"
            expandedRowKeys={this.state.arr}
            dataSource={this.state.tableScdData}
            loading={this.state.boolean}
            pagination={false}
          />
        </div>
        <Pagination
          defaultCurrent={1}
          current={this.state.current}
          total={this.state.length}
          onChange={this.pageChange}
          pageSize={this.state.pageSize}
          showTotal={showTotal}
          style={{ textAlign: "right", marginTop: 20 }}
        />
        {/* 编辑 */}
        <Modal
          title="Edit account"
          visible={this.state.fstvisible}
          onOk={this.edithandleOk}
          onCancel={this.handleCancel}
        >
          <input value={this.state.id} style={{ display: "none" }} readOnly />
          <br />
          <div>
            <span>Login name</span>
            <Input
              value={this.state.scdval}
              disabled
              style={{ marginTop: 10 }}
              onChange={this.scdonChange}
              readOnly
            />
          </div>
          <br />
          <div>
            <span>Job number</span>
            <Input
              value={this.state.fstval}
              allowClear
              style={{ marginTop: 10 }}
              onChange={this.fstonChange}
            />
          </div>
          <br />
          <div>
            <span>Remark</span>
            <Input
              value={this.state.sevenAddVal}
              allowClear
              style={{ marginTop: 10 }}
              onChange={this.sevenaddonChange}
            />
          </div>
          <br />
          <Radio.Group
            onChange={this.radioOnChange}
            value={this.state.radvalue}
          >
            <Radio value={0}>Normal</Radio>
            <Radio value={-2}>Disable</Radio>
            <Radio value={1}>suspend</Radio>
          </Radio.Group>
        </Modal>
        {/* 新增 */}
        <Modal
          title="Add"
          visible={this.state.scdvisible}
          onOk={this.addhandleOk}
          onCancel={this.handleCancel}
        >
          <select
            className="menu_model_fst"
            placeholder="please select one"
            id="modal_select"
            value={this.state.modalthdSelect}
            onChange={this.modelselectThree}
          >
            {getselect}
          </select>

          {this.state.showhide && (
            <select
              className="menu_model_fst"
              value={this.state.overdugrade}
              onChange={this.overdueselect}
            >
              <option value="">overdue grade</option>
              <option value="1">S1-Old</option>
              <option value="2">S2-Old</option>
              <option value="3">M1-Old</option>
              <option value="4">M2</option>
              <option value="5">M3</option>
              <option value="6">M4</option>
              <option value="11">S1-New</option>
              <option value="12">S2-New</option>
              <option value="13">M1-New</option>
            </select>
          )}

          {this.state.showhide2 && (
            <select
              className="menu_model_fst"
              value={this.state.overdugrade}
              onChange={this.overdueselect}
            >
              <option value="">overdue grade</option>
              <option value="21">S0-Old</option>
              <option value="22">S0-New</option>
            </select>
          )}
          {this.state.showhide3 && (
            <select
              className="menu_model_fst"
              value={this.state.overdugrade}
              onChange={this.overdueselect}
            >
              <option value="">overdue grade</option>
              <option value="31">Telemarketer-New</option>
              <option value="32">Telemarketer-Old</option>
            </select>
          )}
          <Input
            value={this.state.fstAddVal}
            placeholder="login name"
            allowClear
            style={{ marginTop: 10 }}
            onChange={this.fstaddonChange}
          />
          <br />
          <Input
            value={this.state.scdAddVal}
            placeholder="job number"
            allowClear
            style={{ marginTop: 10 }}
            onChange={this.scdaddonChange}
          />
          <br />
          <Input
            value={this.state.sevenAddVal}
            placeholder="remark"
            allowClear
            style={{ marginTop: 10 }}
            onChange={this.sevenaddonChange}
          />
        </Modal>


        <Modal
            title="reset password"
            visible={this.state.spePassShow}
            onOk={this.handleResetPass}
            onCancel={this.handleCancel}
        >
          <textarea  placeholder="工号或登录名" value={this.state.spePassText}   style={{ marginTop: 10,width:450,height:300 }}
                     onChange={this.spePassTextonChange}/>
          <br />
          <input type="radio" name="radioGroup"  value="1" checked={this.state.spePassType == 1} onChange={this.handelSpePassType} /> 工号
          <input type="radio" name="radioGroup" value="2" checked={this.state.spePassType == 2}    onChange={this.handelSpePassType}  style={{ marginLeft: 20 }}/> 登录名
        </Modal>

        <Modal
          visible={this.state.fouvisible}
          onOk={this.reshandleOk}
          onCancel={this.handleCancel}
        >
          {" "}
          <input
            value={this.state.resval}
            style={{ display: "none" }}
            readOnly
          />
          <p>
            Are you sure you want to reset the user's password? After reset, the
            user's password is: {"123456"}
          </p>
        </Modal>
        {/* 是否重置密码 */}
        <Modal
          visible={this.state.closePsd}
          onOk={this.resetOK}
          onCancel={this.handleCancel}
        >
          {" "}
          <input
            value={this.state.resval}
            style={{ display: "none" }}
            readOnly
          />
          <p>
            Are you sure you want to reset all users' passwords
          </p>
        </Modal>
        {/* 是否关闭后管服务 */}
        <Modal
          visible={this.state.closedTube}
          onOk={this.closedTubeOK}
          onCancel={this.handleCancel}
        >
          {" "}
          <input
            value={this.state.resval}
            style={{ display: "none" }}
            readOnly
          />
          <p>
            Are you sure you want to shut down all back-end services
          </p>
        </Modal>
        {/* 是否关闭nginx服务 */}
        <Modal
          visible={this.state.closedNginx}
          onOk={this.closedNginxOK}
          onCancel={this.handleCancel}
        >
          {" "}
          <input
            value={this.state.resval}
            style={{ display: "none" }}
            readOnly
          />
          <p>
            Are you sure you want to close all nginx
          </p>
        </Modal>
      </div>
    );
  }
}
export default withRouter(AccountManagement);
