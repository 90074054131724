import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import "./index.css";
import logo from "../../../public/img/logo.png";
import MenuConfig from "./../../config/menuConfig";
import EventEmitter from "./../../utils/events";
const SubMenu = Menu.SubMenu;
var res = [];
class NavLeft extends React.Component {
  constructor(props) {
    super(props);
    this.newTabIndex = 0;
    const panes = [
      { title: "Tab 1", content: "Content of Tab Pane 1", key: "1" },
      { title: "Tab 2", content: "Content of Tab Pane 2", key: "2" },
    ];
    this.state = {
      currentKey: "",
      activeKey: panes[0].key,
      panes,
      menu: [],
    };
  }
  componentWillMount() {
    var read = JSON.parse(localStorage.getItem("key"));
    // console.log('navleftsession',read)

    let _this = this;
    EventEmitter.on("menu", function (data) {
      const menulist = _this.state.menu;
      menulist.push(data);
      _this.setState({
        menu: menulist,
      });
      res = _this.state.menu[0];
      return res;
    });
    const menuTreeNode = _this.renderMenu(read);
    // const menuTreeNode= _this.renderMenu(MenuConfig);

    let currentKey = window.location.hash.replace("#", "");
    //获取对象之后，对象的改变通过setstate方法进而会走render渲染步骤
    _this.setState({
      currentKey,
      menuTreeNode,
    });
  }
  //菜单渲染 递归 有children的放在SubMenu中，没有的放在Menu.Item 中
  renderMenu = (data) => {
    // console.log(data);
    return (
      data &&
      data.map((item) => {
        if (item.children) {
          return (
            <SubMenu title={item.title} key={item.key}>
              {this.renderMenu(item.children)}
            </SubMenu>
          );
        }
        return (
          <Menu.Item title={item.title} key={item.key}>
            <Link to={item.key}>{item.title}</Link>
          </Menu.Item>
        );
      })
    );
  };
  // tohome=()=>{
  //   window.location='/#/home'
  //   let test = [{path: '/home',key: `/home`}]
  //   EventEmitter.trigger('btn', test[0])
  //   EventEmitter.trigger('idanduid', test[0].number)
  // }
  handleClick = ({ item, key }) => {
    this.setState({
      currentKey: key,
    });
    EventEmitter.trigger("nav", item);
    // EventEmitter.trigger('tabList',item.props)
  };
  add = () => {
    const { panes } = this.state;
    const activeKey = `newTab${this.newTabIndex++}`;
    panes.push({ title: "New Tab", content: "New Tab Pane", key: activeKey });
    this.setState({ panes, activeKey });
  };
  render() {
    return (
      <div style={{ height: "100%" }}>
        <div className="logo">
          <img
            style={{ cursor: "pointer" }}
            src={logo}
            alt=""
            className="logoimg"
          />
          {/* <h1>Lucky Loan</h1> */}
        </div>
        <Menu
          mode="inline"
          theme="dark"
          selectedKeys={[this.state.currentKey]}
          onClick={this.handleClick}
        >
          {this.state.menuTreeNode}
        </Menu>
      </div>
    );
  }
}
export default NavLeft;
