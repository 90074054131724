/*eslint-disable*/
import React from 'react';
import { Button,Table,DatePicker,Pagination,message } from 'antd';
import './index.css';
import {Link} from 'react-router-dom'
import axios from 'axios'
import moment from 'moment';
import {withRouter} from 'react-router-dom'
import EventEmitter from '../../../utils/events'

const { RangePicker } = DatePicker
const dateFormat = 'YYYY-MM-DD'||undefined;

function showTotal(total) {
  return `Total ${total} items`;
}

 class creditArrivetime extends React.Component{
    constructor(props){
        super(props);
        this.state={
          filteredInfo: null,
          sortedInfo: null,
          from:'',
          to:'',
          tableScdData:[],
          tableData:[],
          current:1,
          length:1,
          pageSize:10,
          ishide:false,
          isshow:true,
          boolean:true,
          botboolean:true
        }
      }
       //设置时间
       rangeOnChange=(dates, dateStrings)=>{
        console.log('From: ', dates[0], ', to: ', dates[1]);
        console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
        this.setState({ from: dateStrings[0],to:dateStrings[1] })
      }
      reloadClick(e){
        this.setState({
          from:undefined,
          to:undefined,
          boolean:true,
          botboolean:true
        },()=>{
          this.secondTable()
          this.showtable()
        });
       
      }
      pageChange=(page)=>{
        this.setState({
          current: page
          },()=>{
            this.searchClick()
          })
      }
      showtable=()=>{
        this.setState({
          isshow:false,
          ishide:true
        })
        axios
          .post(`/api/loan/credit/getCreditDataByArriveTime`,{

          // beginTime:this.state.from,
          // endTime:this.state.to,
          pageSize:this.state.pageSize,
          current: this.state.current,
        })
        .then(res=>{
          if(res.data.code === 200){
            const data1 = [];
            const data = res.data.data.resultDate
            if(data.length<1){
              this.setState({
                boolean:false,
              })
            }else{
              for (let i = 0; i < data.length; i++) {
                data1.push({
                  key: i,
                  loanCount:data[i].loanCount,
                  shouldbackCount:data[i].shouldbackCount,
                  backCount:data[i].backCount,
                  overdueCount:data[i].overdueCount,
                  overdueRate:data[i].overdueRate,
                  overduebackCount:data[i].overduebackCount,
                  overduenotbackCount:data[i].overduenotbackCount,
                  loanCountNew:data[i].loanCountNew,
                  shouldbackCountNew:data[i].shouldbackCountNew,
                  backCountNew:data[i].backCountNew,
                  overdueCountNew:data[i].overdueCountNew,
                  overdueRateNew:data[i].overdueRateNew,
                  overduebackCountNew:data[i].overduebackCountNew,
                  overduenotbackCountNew:data[i].overduenotbackCountNew,
                  loanCountOld:data[i].loanCountOld,
                  shouldbackCountOld:data[i].shouldbackCountOld,
                  backCountOld:data[i].backCountOld,
                  overdueCountOld:data[i].overdueCountOld,
                  overdueRateOld:data[i].overdueRateOld,
                  overduebackCountOld:data[i].overduebackCountOld,
                  overduenotbackCountOld:data[i].overduenotbackCountOld,
                });
                console.log('aaaaa',data1)
               
                
              }
            }
          
            this.setState({
              tableData:data1,
              boolean:false,
            })
          }else if(res.data.code ==800){
            this.props.history.push({ pathname: "/"});
          }else{
          }
        })
      }
     
//页面初始化加载下面的table
      secondTable=()=>{
        axios
          .post(`/api/loan/credit/getCreditReviewerDataByArriveTime`,{

          // beginTime:this.state.from,
          // endTime:this.state.to,
          pageSize: this.state.pageSize,
          current: this.state.current,
        })
        .then(res=>{
          if(res.data.code === 200){
            const data1 = [];
            const data = res.data.data.resultDate
            if(data.length<1){
              this.setState({
                botboolean:false,
              })
            }
            for (let i = 0; i < data.length; i++) {
              data1.push({
                key: i,
                reviewer:data[i].reviewer,
                loanCount:data[i].loanCount,
                shouldbackCount:data[i].shouldbackCount,
                backCount:data[i].backCount,
                overdueCount:data[i].overdueCount,
                overdueRate:data[i].overdueRate,
                overduebackCount:data[i].overduebackCount,
                overduenotbackCount:data[i].overduenotbackCount,
                loanCountNew:data[i].loanCountNew,
                shouldbackCountNew:data[i].shouldbackCountNew,
                backCountNew:data[i].backCountNew,
                overdueCountNew:data[i].overdueCountNew,
                overdueRateNew:data[i].overdueRateNew,
                overduebackCountNew:data[i].overduebackCountNew,
                overduenotbackCountNew:data[i].overduenotbackCountNew,
                loanCountOld:data[i].loanCountOld,
                shouldbackCountOld:data[i].shouldbackCountOld,
                backCountOld:data[i].backCountOld,
                overdueCountOld:data[i].overdueCountOld,
                overdueRateOld:data[i].overdueRateOld,
                overduebackCountOld:data[i].overduebackCountOld,

                overduenotbackCountOld:data[i].overduenotbackCountOld,
                reviewerId:data[i].reviewerId,
              });
              this.setState({
                tableScdData:data1,
                length:res.data.data.page.total,
                botboolean:false
              })
              // localStorage.removeItem('userId')
              // localStorage.userId = data[i].reviewerId        
              // console.log('session',localStorage.userId)
            } 
          }else if(res.data.code ==800){
            this.props.history.push({ pathname: "/"});
          }else{
          }
        })
      }
      onShowSizeChange=(current, pageSize)=>{

        this.setState({
          boolean:true,
          pageSize: pageSize
        },()=>{
          this.secondTable()
        })
    }
      componentDidMount() {
        this.setState({
          from:moment().startOf('month'),
          to: moment()
        },()=>{
          this.secondTable()
        })
      }
          //搜索
    searchClick=()=>{ 
//第一个table

      axios
        .post(`/api/loan/credit/getCreditDataByArriveTime`,{

        beginTime:this.state.from,
        endTime:this.state.to,
        pageSize: this.state.pageSize,
        current: this.state.current,
      })
      .then(res=>{
        if(res.data.code === 200){
          const data1 = [];
          const data = res.data.data.resultDate
          if(data.length<1){
            this.setState({
              boolean:false,
            })
          }else{
            for (let i = 0; i < data.length; i++) {
              data1.push({
                key: i,
                loanCount:data[i].loanCount,
                shouldbackCount:data[i].shouldbackCount,
                backCount:data[i].backCount,
                overdueCount:data[i].overdueCount,
                overdueRate:data[i].overdueRate,
                overduebackCount:data[i].overduebackCount,
                overduenotbackCount:data[i].overduenotbackCount,
                loanCountNew:data[i].loanCountNew,
                shouldbackCountNew:data[i].shouldbackCountNew,
                backCountNew:data[i].backCountNew,
                overdueCountNew:data[i].overdueCountNew,
                overdueRateNew:data[i].overdueRateNew,
                overduebackCountNew:data[i].overduebackCountNew,
                overduenotbackCountNew:data[i].overduenotbackCountNew,
                loanCountOld:data[i].loanCountOld,
                shouldbackCountOld:data[i].shouldbackCountOld,
                backCountOld:data[i].backCountOld,
                overdueCountOld:data[i].overdueCountOld,
                overdueRateOld:data[i].overdueRateOld,
                overduebackCountOld:data[i].overduebackCountOld,
                overduenotbackCountOld:data[i].overduenotbackCountOld,
              });
              console.log('aaaaa',data1)
              
            } 
          }
          this.setState({
            tableData:data1,
            boolean:false
          })
        }else if(res.data.code ==800){
          this.props.history.push({ pathname: "/"});
        }else{
        }
      })


      //第二个table
      axios
        .post(`/api/loan/credit/getCreditReviewerDataByArriveTime`,{

        beginTime:this.state.from,
        endTime:this.state.to,
        pageSize:this.state.pageSize,
        current: this.state.current,
      })
      .then(res=>{
        if(res.data.code === 200){
          const data1 = [];
          const data = res.data.data.resultDate
          if(data.length<1){
            this.setState({
              botboolean:false,
            })
          }else{
            for (let i = 0; i < data.length; i++) {
              data1.push({
                key: i,
                reviewer:data[i].reviewer,
                loanCount:data[i].loanCount,
                shouldbackCount:data[i].shouldbackCount,
                backCount:data[i].backCount,
                overdueCount:data[i].overdueCount,
                overdueRate:data[i].overdueRate,
                overduebackCount:data[i].overduebackCount,
                overduenotbackCount:data[i].overduenotbackCount,
                loanCountNew:data[i].loanCountNew,
                shouldbackCountNew:data[i].shouldbackCountNew,
                backCountNew:data[i].backCountNew,
                overdueCountNew:data[i].overdueCountNew,
                overdueRateNew:data[i].overdueRateNew,
                overduebackCountNew:data[i].overduebackCountNew,
                overduenotbackCountNew:data[i].overduenotbackCountNew,
                loanCountOld:data[i].loanCountOld,
                shouldbackCountOld:data[i].shouldbackCountOld,
                backCountOld:data[i].backCountOld,
                overdueCountOld:data[i].overdueCountOld,
                overdueRateOld:data[i].overdueRateOld,
                overduebackCountOld:data[i].overduebackCountOld,
  
                overduenotbackCountOld:data[i].overduenotbackCountOld,
                reviewerId:data[i].reviewerId,
              });
              console.log('aaaaa',data1)
              
            } 
          }
          
          this.setState({
            tableScdData:data1,
            botboolean:false,
            length:res.data.data.page.total,
          })
        }else if(res.data.code ==800){
          this.props.history.push({ pathname: "/"});
        }else{
        }
      })
    }

    toShopDetails=(record)=>{
      window.location='/#/credit_system/credit_arrivetime/day_arrive?id='+record.reviewerId
      let test = [{path: '/credit_system/credit_arrivetime/day_arrive',key: `/credit_system/credit_arrivetime/day_arrive${record.reviewerId}/${record.uid}`,number: `${record.reviewerId}/${record.uid}`}]
      EventEmitter.trigger('btn', test[0])
      EventEmitter.trigger('idanduid', test[0].number)  
    }
    render(){
        const columns1 = [
            {
              title: 'Total Lending',
              width: 55,
              dataIndex: 'loanCount',
              key: 'loanCount',
            },
            {
              title: 'Total Due',
              width: 55,
              dataIndex: 'shouldbackCount',
              key: 'shouldbackCount',
            },
            {
              title: 'Total Normal Repayment',
              width: 60,
              dataIndex: 'backCount',
              key: 'backCount'
            }, {
              title: 'Total Overdue',
              width: 55,
              dataIndex: 'overdueCount',
              key: 'overdueCount'
            }, {
              title: 'Overdue Rate',
              width: 50,
              dataIndex: 'overdueRate',
              key: 'overdueRate'
            }, {
              title: 'Total Overdue Repayment',
              width: 60,
              dataIndex: 'overduebackCount',
              key: 'overduebackCount'
            }, {
              title: 'Still Overdue',
              width: 65,
              dataIndex: 'overduenotbackCount',
              key: 'overduenotbackCount'
            }, {
              title: 'New client Lending',
              width: 55,
              dataIndex: 'loanCountNew',
              key: 'loanCountNew'
            }, {
              title: 'New client Due',
              width: 55,
              dataIndex: 'shouldbackCountNew',
              key: 'shouldbackCountNew'
            }, {
              title: 'New client Normal Repayment',
              width: 70,
              dataIndex: 'backCountNew',
              key: 'backCountNew'
            }, {
              title: 'New client Overdue',
              width: 55,
              dataIndex: 'overdueCountNew',
              key: 'overdueCountNew'
            }, {
              title: 'New client Overdue Rate',
              width: 55,
              dataIndex: 'overdueRateNew',
              key: 'overdueRateNew'
            }, {
              title: 'New client Overdue Repayment',
              width: 70,
              dataIndex: 'overduebackCountNew',
              key: 'overduebackCountNew'
            }, {
              title: 'New client Still Overdue',
              width: 75,
              dataIndex: 'overduenotbackCountNew',
              key: 'overduenotbackCountNew'
            }, {
              title: 'Old client Lending',
              width: 55,
              dataIndex: 'loanCountOld',
              key: 'loanCountOld'
            }, {
              title: 'Old client Due',
              width: 55,
              dataIndex: 'shouldbackCountOld',
              key: 'shouldbackCountOld'
            }, {
              title: 'Old client Normal Repayment',
              width: 65,
              dataIndex: 'backCountOld',
              key: 'backCountOld'
            }, {
              title: 'Old client Overdue',
              width: 55,
              dataIndex: 'overdueCountOld',
              key: 'overdueCountOld'
            }, {
              title: 'Old client Overdue Rate',
              width: 45,
              dataIndex: 'overdueRateOld',
              key: 'overdueRateOld'
            }, {
              title: 'Old client Overdue Repayment',
              width: 60,
              dataIndex: 'overduebackCountOld',
              key: 'overduebackCountOld'
            }, {
              title: 'Old client Still Overdue',
              width: 65,
              dataIndex: 'overduenotbackCountOld',
              key: 'overduenotbackCountOld'
            },
          ];






        const columns = [
            {
              title: 'Reviewer',  
              textWrap: 'ellipsis',
              dataIndex: 'reviewer',
              key: 'reviewer',
              fixed:'left',
              width: 100,
            },
            {
              title: 'Total Lending',
              width: 100,
              textWrap: 'ellipsis',
              dataIndex: 'loanCount',
              key: 'loanCount',
            },
            {
              title: 'Total Due',
              width: 100,
              dataIndex: 'shouldbackCount',
              key: 'shouldbackCount'
            }, {
              title: 'Total Normal Repayment',
              dataIndex: 'backCount',
              key: 'backCount',
              width: 100,
            }, {
              title: 'Total Overdue',
              dataIndex: 'overdueCount',
              width: 100,
              key: 'overdueCount'
            }, {
              title: 'Overdue Rate',
              dataIndex: 'overdueRate',
              width: 100,
              key: 'overdueRate'
            }, {
              title: 'Total  Overdue Repayment',
              width: 100,
              dataIndex: 'overduebackCount',
              key: 'overduebackCount'
            }, {
              title: 'Still Overdue',
              dataIndex: 'overduenotbackCount',
              width: 100,
              key: 'overduenotbackCount'
            }, {
              title: 'New client Lending',
              dataIndex: 'loanCountNew',
              width: 100,
              key: 'loanCountNew'
            }, {
              title: 'New client Due',
              dataIndex: 'shouldbackCountNew',
              width: 100,
              key: 'shouldbackCountNew'
            }, {
              title: 'New client Normal Repayment',
              dataIndex: 'backCountNew',
              width: 100,
              key: 'backCountNew'
            }, {
              title: 'New client Overdue',
              width: 100,
              dataIndex: 'overdueCountNew',
              key: 'overdueCountNew'
            }, {
              title: 'New client Overdue Rate',
              width: 100,
              dataIndex: 'overdueRateNew',
              key: 'overdueRateNew'
            }, {
              title: 'New client Overdue Repayment',
              dataIndex: 'overduebackCountNew',
              width: 100,
              key: 'overduebackCountNew'
            }, {
              title: 'New client Still Overdue',
              dataIndex: 'overduenotbackCountNew',
              width: 100,
              key: 'overduenotbackCountNew'
            }, {
              title: 'Old client Lending',
              dataIndex: 'loanCountOld',
              width: 100,
              key: 'loanCountOld'
            }, {
              title: 'Old client Due',
              width: 100,
              dataIndex: 'shouldbackCountOld',
              key: 'shouldbackCountOld'
            }, {
              title: 'Old client Normal Repayment',
              dataIndex: 'backCountOld',
              width: 100,
              key: 'backCountOld'
            }, {
              title: 'Old client Overdue',
              dataIndex: 'overdueCountOld',
              width: 100,
              key: 'overdueCountOld'
            }, {
              title: 'Old client Overdue Rate',
              dataIndex: 'overdueRateOld',
              width: 100,
              key: 'overdueRateOld'
            }, {
              title: 'Old client Overdue Repayment',
              dataIndex: 'overduebackCountOld',
              width: 100,
              key: 'overduebackCountOld'
            }, {
              title: 'Old client Still Overdue',
              dataIndex: 'overduenotbackCountOld',
              width: 100,
              key: 'overduenotbackCountOld'
            },
            {
              title: 'Operation',
              key: 'operation',
              fixed: 'right',
              reviewerId:'reviewerId',
              width: 100,
              render: (record) => <a onClick={() => this.toShopDetails(record)} style={{display:'block',margin:10,fontSize:12}} >details</a>,
            },
          ];
        return(
            <div className="audit_content">
                <div className="commom_top" style={{alignItems:'center'}}>
                <RangePicker
                ranges={{
                Today: [moment(), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                }}
                showTime
                format={dateFormat}
                onChange={this.rangeOnChange}
                value={this.state.from===undefined||this.state.to===undefined||this.state.from===""||this.state.to===""?null:[moment(this.state.from, dateFormat), moment(this.state.to, dateFormat)]}
                />
                 <Button type="primary" style={{width:170,marginLeft:'15%',marginRight:50,height:40,border:'none'}}  className="common_color" onClick={this.searchClick.bind(this)}>Search</Button>
                <Button type="primary" style={{width:170,height:40,border:'none',}} className="common_color_scd" onClick={this.reloadClick.bind(this)}>Refresh</Button>
                </div>
                <div className="table">
                <div className="content_bot" style={{textAlign:'center',marginBottom:40}}>
                <p className="content_bot_p">Total</p>
                {this.state.isshow&&
                 <Button type="primary" style={{width:170,height:40,border:'none'}} className="common_color_scd" onClick={this.showtable}>Details</Button>
                 }
                {this.state.ishide&&
                   <Table columns={columns1} loading={this.state.boolean} dataSource={this.state.tableData} pagination={false} scroll={{ x: 1300 }}/>
                }
               
                </div>
               
                <Table columns={columns} scroll={{ x:2300,y:700 }} loading={this.state.botboolean} dataSource={this.state.tableScdData} pagination={false} />
                </div>
                <Pagination
                  defaultCurrent={1} 
                  onShowSizeChange={this.onShowSizeChange} //pagesize改变时回调函数
                  pageSizeOptions={["10","50","100"]}   //设置pagesize区间
                  current={this.state.current}
                  total={this.state.length} 
                  onChange={this.pageChange} 
                  showTotal={showTotal}
                  pageSize={this.state.pageSize}
                  style={{textAlign:'right',marginTop:20}}
                  // showSizeChanger={true}
                  />
            </div>
        )
    }
}
export default withRouter(creditArrivetime)