/*eslint-disable*/
import React from "react"
import './index.css';
import home_img from '../../../public/img/home_img.png';

export default class Welcome extends React.Component{
  render(){
      return(
          <div className="welcome">
             <img src={home_img}/>
             <p className="welcome_p">Welcome  Back !</p>
             <span className="welcome_span">欢迎进入9ja Cash信贷管理系统</span>
          </div>
      )
  }  
}
