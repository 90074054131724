/*eslint-disable*/
import React from 'react';
import { Button,Table,DatePicker,Pagination } from 'antd';
import './index.css';
import axios from 'axios'
import moment from 'moment';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router-dom'

const { RangePicker } = DatePicker;
function showTotal(total) {
  return `Total ${total} items`;
}

 class hourAudit extends React.Component{
    constructor(props){
        super(props);
        this.state={
          from:'',
          to:'',
          tableData:[], 
          current:1,
          length:1,
          pageSize:10,
          boolean:true
        }
      }
      componentDidMount(){
        this.secondTable();
      }
      //设置时间
      rangeOnChange=(dates, dateStrings)=>{
        this.setState({ from: dateStrings[0],to:dateStrings[1] })
      }
    
    reloadClick(e){
      this.setState({
        from:undefined,
        to:undefined,
        boolean:true
      },()=>{
        this.secondTable()
      });
      
      }
    showmodal=()=>{
        alert(this.index)
    }
    pageChange=(page)=>{
      this.setState({
        current: page
        },()=>{
          this.searchClick()
        })
    }


    onShowSizeChange=(current, pageSize)=>{

      this.setState({
        pageSize: pageSize
      },()=>{
        this.secondTable()
      })
  }

//页面初始化加载的table
  secondTable=()=>{
    
    // if(this.props.location.state == undefined){
    //   this.props.history.push({ pathname: "/home/index"});
    //   return
    // }   
  
    let urlid = ''
    var url = window.location.hash
    var params = url.substr(1);
    var aPos = params.indexOf("=")
     urlid = params.substr(aPos + 1);
    axios
      .post(`/api/loan/credit/getCreditHoursReviewerStatisticsSearchByAuditTime`,{

      pageSize: this.state.pageSize,
      current: this.state.current,
      userId:urlid,
    })
    .then(res=>{
      if(res.data.code === 200){
        const data1 = [];
        const data = res.data.data.resultDate
        if(data.length<1){
          this.setState({
            boolean:false
          })
        }else{
          for (let i = 0; i < data.length; i++) {
            data1.push({
              key: i,
              reviewer:data[i].reviewer,
              date:data[i].date,
              reviewedCount:data[i].reviewedCount,
              passCount:data[i].passCount,
              rejectCount:data[i].rejectCount,
              passRate:data[i].passRate,
              reviewerId:data[i].reviewerId,
            });
           
          }
        }
        
        this.setState({
          tableScdData:data1,
          length:res.data.data.page.total,
          boolean:false
        })
      }else if(res.data.code ==800){
        this.props.history.push({ pathname: "/"});
      }else{
      }
    })
    
  }



          //搜索
          searchClick=()=>{ 
            
            // if(this.props.location.state == undefined){
            //   this.props.history.push({ pathname: "/home/index"});
            // }   
            this.setState({
              boolean:true
            })
            let urlid = ''
            var url = window.location.hash
            var params = url.substr(1);
            var aPos = params.indexOf("=")
             urlid = params.substr(aPos + 1);
                  axios
                    .post(`/api/loan/credit/getCreditHoursReviewerStatisticsSearchByAuditTime`,{

                    beginTime:this.state.from,
                    endTime:this.state.to,
                    pageSize: this.state.pageSize,
                    userId:urlid,
                    current: this.state.current,
                  })
                  .then(res=>{
                    if(res.data.code === 200){
                      const data1 = [];
                      const data = res.data.data.resultDate
                      if(data.length<1){
                        this.setState({
                          boolean:false
                        })
                      }else{
                        for (let i = 0; i < data.length; i++) {
                          data1.push({
                            key: i,
                            reviewer:data[i].reviewer,
                            date:data[i].date,
                            reviewedCount:data[i].reviewedCount,
                            passCount:data[i].passCount,
                            rejectCount:data[i].rejectCount,
                            passRate:data[i].passRate,
                            reviewerId:data[i].reviewerId,
                          });
                         
                        }
                      }
                      
                      this.setState({
                        tableScdData:data1,
                        boolean:false
                      })
                    }else if(res.data.code ==800){
                      this.props.history.push({ pathname: "/"});
                    }else{
                    }
                  })
                  // localStorage.removeItem('userId')
                  
                }
    render(){
        

        const columns = [
           {
              title: 'Date',
              dataIndex: 'date',
              key: 'date'
            }, {
              title: 'Reviewer',
              dataIndex: 'reviewer',
              key: 'reviewer'
            }, {
              title: 'Reviewed number',
              dataIndex: 'reviewedCount',
              key: 'reviewedCount'
            }, {
              title: 'Approval number',
              dataIndex: 'passCount',
              key: 'passCount'
            }, {
              title: 'Rejected number',
              width: 120,
              dataIndex: 'rejectCount',
              key: 'rejectCount'
            }, {
              title: 'Review passing rate',
              dataIndex: 'passRate',
              key: 'passRate'
            },

          ];
        return(
            <div className="audit_content">
                  <div className="commom_top" style={{alignItems:'center'}}>
                <RangePicker
                ranges={{
                Today: [moment(), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                }}
                showTime
                format="YYYY-MM-DD"
                onChange={this.rangeOnChange}
                from={this.state.from}
                to={this.state.to}
                />
                <Button type="primary" style={{width:170,marginLeft:'15%',marginRight:50,height:40,border:'none'}}  className="common_color" onClick={this.searchClick.bind(this)}>Search</Button>
                <Button type="primary" style={{width:170,height:40,border:'none',}} className="common_color_scd" onClick={this.reloadClick.bind(this)}>Refresh</Button>
                </div>
                <div className="table">
                <Table columns={columns} loading={this.state.boolean} dataSource={this.state.tableScdData} pagination={false} scroll={{ x: '100%' }}/>
                </div>

                <Pagination
                  // showQuickJumper
                  showSizeChanger                          //展示改变pagesize样式
                  onShowSizeChange={this.onShowSizeChange} //pagesize改变时回调函数
                  pageSizeOptions={["10","50","100"]}   //设置pagesize区间
                  defaultCurrent={1} 
                  current={this.state.current}
                  total={this.state.length} 
                  onChange={this.pageChange} 
                  pageSize={this.state.pageSize}
                  showTotal={showTotal}
                  style={{textAlign:'right',marginTop:20}}
                  />
            </div>
        )
    }
}
export default withRouter(hourAudit)