/*eslint-disable*/
import React from 'react';
import { Input,Button,Table,Modal,Pagination,DatePicker,Tooltip,message,Icon,Upload } from 'antd';
import './index.css';
import axios from 'axios'
import {withRouter} from 'react-router-dom'
import EventEmitter from '../../utils/events'
import rule from '../../../public/img/bank_rule.png'
import location_red from '../../../public/img/rule.png';
import location_red_today from '../../../public/img/rule.png';
import { exportMethod } from '../../utils/axios'
import {Link} from 'react-router-dom'
import moment from 'moment';
  const { RangePicker } = DatePicker;
  const { Search } = Input;
  const dateFormat = 'YYYY-MM-DD'||undefined;
  var whitchshow = []

  function showTotal(total) {
    return `Total ${total} items`;
  }

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  
  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
      message.error("Image must smaller than 1MB!");
    }
    return isJpgOrPng && isLt1M;
  }
 class Bank extends React.Component {
    constructor(props){
      super(props);
      this.state={
        current:1,
        pageSize:10,
        tableData:[],
        mobile:'',
        bvnNo:'',
        bankCardNo:'',
        bankAccNo:'',
        status:'',
        bankCardSvisible:false,
        clearCardSvisible:false,
        isDisabled:false,
        btnWord:'export',
        dates:[],
      }
    }

    onShowSizeChange=(current, pageSize)=>{
      this.setState({
        pageSize: pageSize
      },()=>{
        this.searchClick()
      })
    }
    //页码改变时触发
    pageChange=(page)=>{
      this.setState({
        current: page
        },()=>{
          this.searchClick()
        })
    }



    //重置按钮
    reloadClick(e){
      this.setState({
        current:1,
        pageSize:10,
        tableData:[],
        mobile:'',
        bvnNo:'',
        bankCardNo:'',
        bankAccNo:'',
        status:'',
        operate: undefined,
        operateto: undefined,
        dates:[]
      },()=>{
          this.searchClick();
      });
    
    }

    onload=()=>{
      this.searchClick()
    }
    componentDidMount(){
     this.onload()
    }

    //输入框
    searchIpt(e){
      var value = e.target.value;
      this.setState({ searchVal: value.replace(/\s+/g,"") })
    }
    selectfst= (e) =>{
      var value = e.target.value;
      this.setState({
        fstSelect:value,
      });
    }
    selectClaim = (e) =>{
      let value = e.target.value
      this.setState({
        claimed:value
      })
    }
    handleReduceCancel =() =>{
      this.setState({
        thdvisible:false
      })
    }
    handleImageChange = (info) => {
      if (info.file.status === "uploading") {
        this.setState({ loading: true });
        return;
      }
      if (info.file.status === "done") {
        getBase64(info.file.originFileObj, (imgUrl) => {        
          let temp = this.state
          temp.imgUrl = info.file.response.data
          this.setState({
            data: temp,
            loading: false,
          })
        });
      }
    };

    //重置密码
    ResetInformation =(row) =>{
      console.log(row)
      this.state.rows = row
      //调用接口，根据接口返回状态判断是否可以重置银行卡信息
      axios({
        url:'/api/userBank/selectUserOrderInfo?userId=' + row.uid,
        method:'get',
      }).then(res=>{
        if(res.data.data.status == 1){
          message.error('Cannot reset card')
        }else if(res.data.data.status == 2){
          this.setState({
            bankCardSvisible:true
          })
        }else if(res.data.data.status == 3){
          this.setState({
            clearCardSvisible:true
          })
        }
      })
    }

    handleMethods = () =>{
      axios({
        url:'/api/userBank/resetUserBankCardInfo',
        method:'post',
        data:{
          userId:this.state.rows.uid,
          bankId:this.state.rows.bankId
        }
      })
      .then(res=>{
        if(res.data.code == 200){
          this.setState({
            bankCardSvisible:false,
            clearCardSvisible:false
          })
          message.success(res.data.data.msg)
          this.searchClick()
        }else if(res.data.code == -1){
          message.error(res.data.msg)
          this.setState({
            bankCardSvisible:false,
            clearCardSvisible:false
          })
        }
      })
    }

    handleRest = () =>{
      this.handleMethods()
    }

    handleCancelRest =() =>{
      this.setState({
        bankCardSvisible:false
      })
    }

    handleModalCancel = () =>{
      this.setState({
        clearCardSvisible:false
      })
    }

    handleModalOk = () =>{
      this.handleMethods()
    }

   
    uploadhandleOkToday=()=>{
      if(this.state.fileList==''){
        alert("Upload content cannot be empty")
        return false
      }
      this.setState({
        scdafter:true
    })
   
    const { fileList} = this.state;
    const formData = new FormData();
    formData.append('file', fileList[0]);  
    axios({
        url: '/api/bankPayment/uploadExcelToday',
        method: 'post',
        data: formData
    })
    .then(res => {
        if (res.data.code === 200) {        
              message.success(res.data.msg)
              this.searchClick()
              this.setState({ 
                  uploadvisible:false,
                  uploadvisibletoday:false,
                  fileList:'',
                  scdafter:false,
                  isdisabled:false
              })
          } else if (res.data.code == 800) {
              this.props.history.push({pathname: "/"});
          } else {
              message.error(res.data.msg)
              this.setState({
                uploadvisible:false,
                uploadvisibletoday:false,
                scdafter:false,
                fileList:'',
                isdisabled:false
              })
          }
      })
    }
    showrule=()=>{
      this.setState({
        
      })
    }
    todetail=(record)=>{
      this.props.history.push({ pathname: "../../details/index",state:{record}});
    }

    getTable =  () =>{
      this.searchClick(1)
    }

    searchClick=(current)=>{
      this.setState({
        boolean: true
      })
      axios
      .post(`/api/userBank/selectUserBankCardInfo`,{
    
          pageSize: this.state.pageSize,
          current:  current || this.state.current,
          mobile:this.state.mobile,
          bvnNo:this.state.bvnNo,
          bankCardNo:this.state.bankCardNo,
          bankAccNo:this.state.bankAccNo,
          status:this.state.status,
          startTime:this.state.operate,
          endTime:this.state.operateto
    })
    .then(res=>{
      console.log(res)
      if (res.data.code === 200) {
        const data1 = [];
        const data = res.data.data.resultDate
        if(data.length<1){
          this.setState({
          boolean: false  
          })
        }else{
          for (let i = 0; i < data.length; i++) {
            data[i].key = i;
            data1.push(data[i]);
          }
        }
        this.setState({
          tableData: data1,
          total: res.data.data.page.total,
          boolean: false
        })
    } else if(res.data.code ==800){
        this.props.history.push({ pathname: "/"});
      }else{
        // message.error(res.data.msg);
      }
    })
  }

  downExcel=()=>{
    console.log(this.state.operate)
    if(this.state.operate == undefined){
      message.error('Select the export time first')
    }else{
      this.state.isDisabled = true;
      this.export();
      let count = 60;
      let timer = setInterval(()=>{
        if(count <=0){
          this.setState({
            btnWord:'export',
            isDisabled:false
          })
          clearInterval(timer);
        }else{
          count --;
          this.setState({
            btnWord:count +'s'
          })
        }
      },1000)
    }
  }
  export=()=>{
    let myObj = {
      method: 'post',
      url:`/api/userBank/outExcelUserBankCardInfo`,
      data:{
        mobile:this.state.mobile,
        bvnNo:this.state.bvnNo,
        bankCardNo:this.state.bankCardNo,
        bankAccNo:this.state.bankAccNo,
        status:this.state.status,
        startTime:this.state.operate,
        endTime:this.state.operateto
      },
      fileName: 'Bank Statements.xls',
      }
      exportMethod(myObj)
  }


    //是否隐藏
    disabledDate=(current)=>{
      // console.log("日期列表 单个",current)
      let {dates}=this.state
      if (!dates || dates.length === 0) {
          return false;
      }
      let startTime=dates[0]
      const start=startTime && startTime.format("YYYYMMDD") > current.format("YYYYMMDD")
      const end=startTime && moment(startTime).add(30,'days').format("YYYYMMDD") < current.format("YYYYMMDD")
      return start || end;
    }
    //选中时间时
    CalendarChange=(value)=>{
        this.setState({dates:value})
    }
    change=(value,dateStrings)=>{
        if(value.length==0){
          this.setState({dates:[],operate:'',operateto:''})
        }else{
          this.setState({ operate: dateStrings[0],operateto:dateStrings[1] })
        }
        console.log(dateStrings,1)
    }
      

    render() {
      const phoneColumns = [
        {
          title: 'Check No',
          dataIndex: 'claimed',
          key: 'claimed',
      },
        {
            title: 'Effective Date',
            dataIndex: 'effectiveDate',
            key: 'effectiveDate',
            render: (value) => {
              let timeaudit= new Date(value).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
              let auditimes=moment(new Date(timeaudit)).format("YYYY-MM-DD");
              return auditimes
          }
        },
        {
            title: 'Credit',
            dataIndex: 'credit',
            key: 'credit',
        },
        {
          title: 'Claim Order No',
          dataIndex: 'claimOrderNo',
          key: 'claimOrderNo',
      },
      {
          title: 'Claimant',
          dataIndex: 'claimant',
          key: 'claimant',

      },
      {
        title: 'Claim time',
        dataIndex: 'createdTime',
        key: 'createdTime',
        render: (value) => {
          let timeaudit= new Date(value).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
          let auditimes=moment(new Date(timeaudit)).format("YYYY-MM-DD hh:mm:ss");
          return auditimes
        },
    },
    {
      title: 'remark',
      dataIndex: 'remark',
      key: 'remark',
      },
    {
      title: 'Claim voucher',
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      render: (value) => {
          if(value==''||value==undefined){
              return ''
          }
          else {
           return (<a href={value} target="_blank">图片链接</a>)
          }
        }
      },
    ];

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const uploadButton = (
      <div style={formItemLayout}>
        <Icon type={this.state.loading ? "loading" : "plus"} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    

    const columns = [
        {
          title: "name",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "mobile",
          dataIndex: "mobile",
          key: "mobile",
        },
        {
          title: "email",
          dataIndex: "email",
          key: "email",
        },
        {
          title: "bvnNo",
          dataIndex: "bvnNo",
          key: "bvnNo",
        },
        {
          title: "bankAccNo",
          dataIndex: "bankAccNo",
          key: "bankAccNo",
        },
        {
          title: "bankCardNo",
          dataIndex: "bankCardNo",
          key: "bankCardNo",
        },
  
        {
          title: "createdTime",
          dataIndex: "createdTime",
          key: "createdTime",
          render: (value) => {
            let timeaudit =
              new Date(value).getTime() +
              new Date().getTimezoneOffset() * 60 * 1000 -
              0 * 60 * 60 * 1000;
            let auditimes = moment(new Date(timeaudit)).format(
              "YYYY-MM-DD HH:mm:ss"
            );
            if (value == null) {
              return (auditimes = "");
            } else {
              return (auditimes = auditimes);
            }
          },
        },
        {
          title: "status",
          dataIndex: "status",
          key: "status",
          render: (value) => {
            if (value == 1) {
              return (value = "certified");
            } else if (value == 2 ) {
              return "unauthorized";
            } else if (value == 3){
              return "failure";
            }
          },
        },
        {
          title: "Operate",
          dataIndex: "operating",
          key: "operating",
          width: 70,
          id: "id",
          uid: "uid",
          fixed: "right",
          render: (text, record, index) => (
            <span style={{ display: "flex", flexDirection: "row" }}>
            <Button 
            type="primary"    
            className="detailsbutton common_color" 
            size="small"  
            onClick={()=>this.ResetInformation(record)}
            style={{display:record.status !== 1 ? "block":"none"}}>
               ResetInformation
            </Button>
            </span>
          ),
        },
      ];
      const { uploading, fileList } = this.state;
      const props = {
        onRemove: (file) => {
          this.setState((state) => {
            const index = state.fileList.indexOf(file);
            const newFileList = state.fileList.slice();
            newFileList.splice(index, 1);
            return {
              fileList: newFileList,
            };
          });
        },
        beforeUpload: (file) => {
          this.setState(state => ({
            fileList: [...state.fileList, file],
            isdisabled:true
          }));
         
          return false;
        },
        fileList,
      };
      return (
        <div className="credit_order">
          <div className="myorder_content">
          <div
            className="flutter"
            style={{
              backgroundColor: "#fff",
              padding: 20,
              paddingTop: 30,
              paddingBottom: 30,
            }}
          >
            <div className="top">
              <div className="top_left" style={{ width: "30%", flexShrink: 1 }}>
                <div className="search">
                  <span className="description left" style={{ width: 90 }}>
                    Mobile:
                  </span>
                  <input
                    type="text"
                    placeholder="mobile"
                    value={this.state.mobile}
                    onChange={(e) => {
                      this.setState({ mobile: e.target.value });
                    }}
                    className="modal_input"
                    style={{ width: 290, marginLeft: 0 }}
                  />
                </div>
              </div>

              <div
                className="top_mid"
                style={{
                  width: "30%",
                  flexShrink: 2,
                  marginLeft: "5%",
                  marginRight: "5%",
                }}
              >
                <span className="description left" style={{ width: 85 }}>
                  BvnNo:
                </span>
                <input
                  type="text"
                  placeholder="BvnNo"
                  value={this.state.bvnNo}
                  onChange={(e) => {
                    this.setState({ bvnNo: e.target.value });
                  }}
                  className="modal_input"
                  style={{ width: 290, marginLeft: 0 }}
                />
              </div>
              <div
                className="top_right"
                style={{ width: "30%", flexShrink: 1 }}
              >
                <div className="rangepick">
                  <span className="description mid_span" style={{ width: 105 }}>
                  CardNumber:
                  </span>
                  <input
                    type="text"
                    placeholder="CardNumber"
                    value={this.state.bankCardNo}
                    onChange={(e) => {
                      this.setState({ bankCardNo: e.target.value });
                    }}
                    className="modal_input"
                    style={{ width: 290, marginLeft: 0 }}
                  />
                </div>
              </div>
            </div>

            <div className="order_mid">
              <div className="mid_left" style={{ width: "30%" }}>
                <span className="description mid_span" style={{ width: 90 }}>
                  Account No:
                </span>
                <input
                  type="text"
                  placeholder="Account No"
                  value={this.state.bankAccNo}
                  onChange={(e) => {
                    this.setState({ bankAccNo: e.target.value });
                  }}
                  className="modal_input"
                  style={{ width: 290, marginLeft: 0 }}
                />
              </div>

              <div
                className="mid_right"
                style={{
                  width: "30%",
                  flexShrink: 2,
                  marginLeft: "5%",
                  marginRight: "5%",
                }}
              >
                <span className="description right" style={{ width: 85 }}>
                  Status:
                </span>
                <select
                  className="form_select"
                  style={{ marginRight: 0, width: 292 }}
                  value={this.state.status}
                  onChange={(e) => {
                    this.setState({ status: e.target.value });
                  }}
                >
                  <option value="">All</option>
                  <option value="1">certifi</option>
                  <option value="2">unauthoriz</option>
                  <option value="3">failure</option>
                </select>
              </div>

              <div className="top_mid" style={{ width: "30%" }}>
                <div className="rangepick">
                  <span className="description mid_span" style={{ width: 105 }}>
                  authentication time:
                  </span>
                  <RangePicker value={this.state.dates} ref="time" disabledDate={this.disabledDate} onCalendarChange={this.CalendarChange} onChange={this.change} />
                </div>
              </div>
            </div>
            
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                marginTop: 20,
              }}
            >
              <div
                className="top_mid inside_top"
                style={{
                  width: "15%",
                  marginRight: 0,
                  justifyContent: "flex-start",
                }}
                onClick={this.demo}
              >
                
              </div>

              <div
                className="top_mid inside_top"
                style={{
                  width: "15%",
                  marginRight: 0,
                  justifyContent: "flex-start",
                }}
                onClick={this.today}
              >
                
              </div>
              <div
                className="mid_right"
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  width: "30%",
                }}
              >
                 <Button type="primary" style={{width:170,marginRight:20,height:40,border:'none'}}  className="common_color" onClick={this.getTable.bind(this)}>Search</Button>
                <Button type="primary" style={{width:170,height:40,border:'none'}} className="common_color_scd" onClick={this.reloadClick.bind(this)}>Refresh</Button>
                <Button type="primary"  style={{width:150,marginLeft:20,height:40,backgroundColor:'#009688',border:'none'}} onClick={this.downExcel} disabled={this.state.isDisabled}>{this.state.btnWord}</Button>
              </div>
            </div>
          </div>
          </div>
          <div style={{backgroundColor:'#fff'}}>
            <div style={{textAlign:'right',paddingTop:20,paddingBottom:20,display:'flex',flexDirection:'row',justifyContent:'flex-end'}}></div>
            <Table 
              columns={columns} 
              dataSource={this.state.tableData} 
              scroll={{x:1500}} 
              loading={this.state.boolean} 
              onChange={this.handleChange} 
              pagination={false} 
            />
            <Pagination
              showQuickJumper
              defaultCurrent={1} 
              current={this.state.current}
              total={this.state.total} 
              onChange={this.pageChange} 
              showTotal={showTotal}
              style={{textAlign:'right',marginTop:20}}
              pageSize={this.state.pageSize}
              showSizeChanger={true}
              onShowSizeChange={this.onShowSizeChange} //pagesize改变时回调函数
              pageSizeOptions={["10","50","100"]}   //设置pagesize区间
            />
          </div>



          {/* 有进行中的订单是否重置 */}
          <Modal
            title="Tip"
            visible={this.state.bankCardSvisible}
            onCancel={this.handleCancelRest}
            footer={[
              <Button key="back" onClick={this.handleCancelRest}>
                Cancel
              </Button>,
              <Button key="submit" type="primary"  onClick={this.handleRest}>
              OK
            </Button>,
            ]}
            >
            <p>This user has an order in progress. Are you sure you want to reset their bank card information?</p>
          </Modal>

          {/* 重置银行卡信息 */}
          <Modal
            title="Tip"
            visible={this.state.clearCardSvisible}
            onCancel={this.handleModalCancel}
            footer={[
              <Button key="back" onClick={this.handleModalCancel}>
                Cancel
              </Button>,
              <Button key="submit" type="primary"  onClick={this.handleModalOk}>
              OK
            </Button>,
            ]}
            >
            <p>Are you sure you want to reset this bank card information?</p>
          </Modal>
          
        </div>
      );
    }
  }
  export default withRouter(Bank)