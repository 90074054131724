/*eslint-disable*/
import React from 'react';
import {Input, Button, Table, Modal, Pagination, DatePicker, Tooltip, message} from 'antd';
import './index.css';
import axios from 'axios'
import moment from 'moment';
import {withRouter} from 'react-router-dom'
import EventEmitter from '../../utils/events'
const dateFormat = 'YYYY-MM-DD'||undefined;
function showTotal(total) {
    return `Total ${total} items`;
}
var getselect = []

const {Search} = Input;

 class UserInformation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filteredInfo: null,
            sortedInfo: null,
            data: [],
            tableData: [],
            searchVal: '',
            current: 1,
            total: 1,
            pageSize: 10,
            boolean: false,
            ishide: false,
            isshow: false,
            ids:'',
            mobilephone:''
        }
    }

    logout=(record)=>{
        this.setState({
            scdvisible:true,
            ids:record.uid,
        })
    }
    thdhandleOk=()=>{
        axios
            .post(`/api/userInfo/logoutUserInfo`, {
                uid:this.state.ids,
            })
            .then(res => {
                if (res.data.code === 200) {
                    message.success(res.data.msg)
                    this.setState({
                        scdvisible:false,                    
                    })
                } else if (res.data.code == 800) {
                    this.props.history.push({pathname: "/"});
                } else {
                    message.error(res.data.msg)
                }
    
            })
        this.setState({
            scdvisible:false,
            boolean:true,
            
        })
        this.searchClick()
    }
  ReasonSelect=(record)=>{
    axios
      .post(`/api/common/getCommonListByCode`,{
        code:'USER_BLACK_REASON'
    })
    .then(res=>{
      getselect=[]
      if(res.data.code === 200){
        for(let i=0;i<res.data.data.length;i++ ){
            if(i==0){
                getselect.push(<option value=''>please select one</option> )
              }
            getselect.push(       
              <option value={res.data.data[i].name}>{res.data.data[i].name}</option>
          )
          
        this.setState({
            getselect:getselect
        })
        }
      }else if(res.data.code ==800){
        this.props.history.push({ pathname: "/"});
      }else{
      }
    })

  }



    reloadClick=(record) =>{
        this.setState({
            from: undefined,
            to: undefined,
            searchBvnNo:'',
            searchMobile:'',
            searchEmail:'',
            searchName:'',
            current:1,
        });
      
    }
    ModalFst=(e)=>{
        var value = e.target.value;
        this.setState({
            modalfst:value
        })
    }
    ModalScd=(e)=>{
        var value = e.target.value;
        this.setState({
            modalscd:value
        })
    }
    ModalThd=(e)=>{
        var value = e.target.value;
        this.setState({
            modalthd:value
        })
    }
    ModalFou=(e)=>{
        var value = e.target.value;
        this.setState({
            modalfou:value
        })
    }
    //输入框
    searchBvnNoIpt(e) {
        var value = e.target.value;
        this.setState({searchBvnNo: value.replace(/\s+/g,"")})
    }
    dateonChange = (date, dateString) =>{
        this.setState({ datepiker:dateString})
    }
    //手机号输入框
    searchMobileIpt(e) {
        var value = e.target.value;
        
        this.setState({searchMobile: value.replace(/\s+/g,"")})
    }

    //订单号输入框
    searchEmailIpt(e) {
        var value = e.target.value;
        this.setState({searchEmail: value.replace(/\s+/g,"")})
    }

    //名字输入框
    searchNameIpt(e) {
        var value = e.target.value;
        this.setState({searchName: value.replace(/\s+/g,"")})
    }

    showmodal = () => {
        alert(this.index)
    }
     //拉黑按钮
     black = (record) => {
         console.log('123123',record)
        this.setState({
            fstvisible:true,
            ids:record.uid,
            mobilephone:record.mobile
        })
    }
    pageChange = (page) => {
        this.setState({
            current: page
        },() => {
            this.searchClick()
        })
    }
    reset=(record)=>{
        this.setState({
            thdvisible:true,
            ids:record.uid,
        })
    }
    modify=(record)=>{
        console.log(record)
        this.setState({
            fouvisible:true,
            ids:record.uid,
            modalfst:record.firstName,
            modalscd:record.middleName,
            modalthd:record.surName,
            modalfou:record.bvnPhone,
            datepiker:record.birthday
        })

    }
    fsthandleOk=()=>{
        axios
            .post(`/api/userInfo/resetUserInfo`, {
                uid:this.state.ids,
            })
            .then(res => {
                if (res.data.code === 200) {
                    message.success(res.data.msg)
                    this.setState({
                        thdvisible:false,                    
                    })
                } else if (res.data.code == 800) {
                    this.props.history.push({pathname: "/"});
                } else {
                    message.error(res.data.msg)
                }
    
            })
        this.setState({
            thdvisible:false,
            boolean:true,
            
        })
        this.searchClick()
    }
    
    
    scdhandleOk=()=>{
        axios
            .post(`/api/userInfo/modifyUserInfo`, {
                uid:this.state.ids,
                firstName:this.state.modalfst,
                surName:this.state.modalthd,
                middleName:this.state.modalscd,
                bvnPhone:this.state.modalfou,
                birthday:this.state.datepiker,
            })
            .then(res => {
                if (res.data.code === 200) {
                    message.success(res.data.msg)
                    this.setState({
                        fouvisible:false,                    
                    })
                } else if (res.data.code == 800) {
                    this.props.history.push({pathname: "/"});
                } else {
                    message.error(res.data.msg)
                }
    
            })
        this.setState({
            modalfst:'',
            modalthd:'',
            modalscd:'',
            modalfou:'',
            datepiker:'',
            fouvisible:false,
            boolean:true,
            
        })
        this.searchClick()
    }
//页面初始化加载下面的table
    secondTable = () => {
        // this.searchClick()
    }
    //搜索
    searchClick = () => {
        this.setState({
            boolean: true
        })
        axios
            .post(`/api/userInfo/userInfoList`, {
                bvnNo: this.state.searchBvnNo,
                mobile: this.state.searchMobile,
                email: this.state.searchEmail,
                name: this.state.searchName,
                pageSize: this.state.pageSize,
                current: this.state.current,
            })
            .then(res => {
                if (res.data.code === 200) {
                 
                    const data1 = [];
                    const data = res.data.data.resultDate

                    for (let i = 0; i < data.length; i++) {
                        if(res.data.data.role==1||res.data.data.role==9||res.data.data.role==17||res.data.data.role==18){
                            this.setState({
                                isshow:true
                            })
                        }
                        data[i].key = i;
                        data1.push({
                            key: i,
                            firstName:data[i].firstName,
                            middleName: data[i].middleName,
                            surName:data[i].surName,
                            gender:data[i].gender,
                            bvnNo:data[i].bvnNo,
                            mobile:data[i].mobile,
                            email:data[i].email,
                            repaymentCount:data[i].repaymentCount,
                            createdTime:data[i].createdTime,
                            id:0,
                            uid:data[i].uid,
                            blacklist:data[i].isBlackList,
                            bvnPhone:data[i].bvnPhone,
                            birthday:data[i].birthday,
                          });
                    }
                    this.setState({
                        tableScdData: data1,
                        total: res.data.data.page.total,
                        boolean: false
                    })
                } else if (res.data.code == 800) {
                    this.props.history.push({pathname: "/"});
                } else {
                }
            })
    }
    handClick = (item) => {
        console.log('1111111122222233333', item)
    }
    toShopDetails = (record) => {
        console.log(record,'记录')
        window.location='/#/details/index?id='+record.id+'&uid='+record.uid
        let test = [{path: '/details/index',key: `/details/index/${record.id}/${record.uid}`,number: `${record.id}/${record.uid}`}]
        EventEmitter.trigger('btn', test[0])
        EventEmitter.trigger('idanduid', test[0].number)
    }

    componentDidMount() {
        this.secondTable()
        this.ReasonSelect()

    }
 //点击Modal确定按钮
 handleOk=()=>{
    axios
        .post(`/api/blacklist/blacklistAdd`, {

            status:0,
            uid:this.state.ids,
            remark:this.state.fsttextarea,
            reason:this.state.modalthdSelect,
            mobile:this.state.mobilephone
        })
        .then(res => {
            if (res.data.code === 200) {
                message.success(res.data.msg)
            } else if (res.data.code == 800) {
                this.props.history.push({pathname: "/"});
            } else {
                message.error(res.data.msg)
            }

        })
    this.setState({
        modalinput:'',
        modalthdSelect:'',
        fsttextarea:'',
        fstvisible:false,
        boolean:true,
        
    })
    this.searchClick()
}

   //modal内reason下拉框
   modalselectThd=(e)=>{
    var value = e.target.value;
    this.setState({
        modalthdSelect:value
    })
}
    //第一个textarea
    fsttxarea=(e)=>{
        let value = e.target.value;
        this.setState({ fsttextarea: value })
      }
    //点击Modal取消按钮
    handleCancel=()=>{
        this.setState({
            fstvisible:false,
            scdvisible:false,
            thdvisible:false,
            fouvisible:false,
            modalinput:'',
            modalthdSelect:'',
            fsttextarea:'',
            selectedRowKeys:'',
            scdmodalSelect:'',
            scdmodalSelect:0,
            modalfst:'',
            modalscd:'',
            modalthd:'',
            modalfou:'',
            datepiker:''
        })
    }
    render() {
        const columns = [
            {
                title: 'First Name',
                dataIndex: 'firstName',
                key: 'firstName',
                render: (value) => {
                    return value || '——'
                }

            },
            {
                title: 'Middle Name',
                dataIndex: 'middleName',
                key: 'middleName',
                render: (value) => {
                    return value || '——'
                }
            },
            {
                title: 'Surname',
                dataIndex: 'surName',
                key: 'surName',
                render: (value) => {
                    return value || '——'
                }

            },
            {
                title: 'Gender',
                dataIndex: 'gender',
                key: 'gender',
                render: (value) => {
                    if (value == 1) {
                        return 'male'
                    } else if (value == 2) {
                        return 'female'
                    } else {
                        return '——'
                    }
                }
            },
            {
                title: 'BvnNo',
                dataIndex: 'bvnNo',
                key: 'bvnNo',
               
                render: (value) => {
                    return value || '——'
                }
            },
            {
                title: 'mobile',
                dataIndex: 'mobile',
                key: 'mobile',
                render: (value) => {
                    return value
                }
            },
            {
                title: 'email address',
                dataIndex: 'email',
                key: 'email',
                render: (value) => {
                    return value || '——'
                }
            },
            {
                title: 'Success Repayment Count',
                dataIndex: 'repaymentCount',
                key: 'repaymentCount',
                render: (value) => {
                    return value
                }
            },
            {
                title: 'Register time',
                dataIndex: 'createdTime',
                key: 'createdTime',
                render: (value) => {
                    let timeaudit= new Date(value).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
                    let auditimes=moment(new Date(timeaudit)).format("YYYY-MM-DD HH:mm:ss");
                    return auditimes
                }
            },
            {
                title: 'Operation',
                key: 'operation',
                id: '',
                uid: 'uid',
                birthday: 'birthday',
                bvnPhone: 'bvnPhone',
                width:200,
                blacklist:'blacklist',
                render: (record) => (<span style={{ display: 'flex' , flexDirection: 'column', justifyContent:'flex-start',margin:0}}>
                <div style={{display:'flex'}}>
                <Button type="primary" style={{
                    width: 100,
                    marginRight: 20,
                    height: 30,
                    backgroundColor: '#775bee',
                    border: 'none'
                }} onClick={()=>this.toShopDetails(record)}>detail</Button>
                <Button type="primary" style={{
                    width: 150,
                    height: 30,
                    display: record.blacklist==-1? "block" : 'none',
                    backgroundColor: '#775bee',
                    border: 'none'
                }}  onClick={()=>this.black(record)}>to blacklist</Button>
                </div>
                {this.state.isshow&&
                <div style={{display:'flex',marginTop:10,flexDirection:'row'}}>
                <Button type="primary" style={{
                    width: 100,
                    marginRight: 20,
                    height: 30,
                    backgroundColor: '#775bee',
                    border: 'none'
                }} onClick={()=>this.logout(record)}>logout</Button>
                  <Button type="primary" style={{
                    width: 100,
                    marginRight: 20,
                    height: 30,
                    backgroundColor: '#775bee',
                    border: 'none'
                }} onClick={()=>this.reset(record)}>reset</Button>

                  <Button type="primary" style={{
                    width: 100,
                    height: 30,
                    backgroundColor: '#775bee',
                    border: 'none'
                }} onClick={()=>this.modify(record)}>modify</Button>
               
                </div>
                 }
              </span>)

            },
        ];
        return (
            <div className="audit_content">
                <div className="commom_top" style={{alignItems: 'center'}}>
                    <Search
                        placeholder="BVN"
                        onSearch={value => console.log(value)}
                        style={{width: 290}}
                        value={this.state.searchBvnNo}
                        onChange={this.searchBvnNoIpt.bind(this)}
                    />
                    <Search
                        placeholder="Phone"
                        onSearch={value => console.log(value)}
                        style={{width: 290, marginLeft: 20}}
                        value={this.state.searchMobile}
                        onChange={this.searchMobileIpt.bind(this)}
                    />
                    <Search
                        placeholder="Email"
                        onSearch={value => console.log(value)}
                        style={{width: 290, marginLeft: 20}}
                        value={this.state.searchEmail}
                        onChange={this.searchEmailIpt.bind(this)}
                    />
                    <Search
                        placeholder="Name"
                        onSearch={value => console.log(value)}
                        style={{width: 290, marginLeft: 20}}
                        value={this.state.searchName}
                        onChange={this.searchNameIpt.bind(this)}
                    />
                    <Button type="primary" style={{
                        width: 170,
                        marginLeft: '4%',
                        height: 40,
                        backgroundColor: '#775bee',
                        border: 'none'
                    }} onClick={this.searchClick.bind(this)}>Search</Button>
                      <Button type="primary" style={{
                        width: 170,
                        marginLeft: '4%',
                        height: 40,
                        backgroundColor: '#775bee',
                        border: 'none'
                    }} onClick={this.reloadClick}>Clear</Button>
              
                </div>
                <div className="table">

                    <Table columns={columns} dataSource={this.state.tableScdData} loading={this.state.boolean}
                           pagination={false}/>

                </div>
        <Modal
          title="Add To BlackList"
          visible={this.state.fstvisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >

        <input value={this.state.mobilephone} style={{display:'none'}}/>
        <div className="model_last">
        <span className="long">reason:</span>
            <select className="modal_getselect" style={{marginLeft:'14.7%',width:310}} value={this.state.modalthdSelect} onChange={this.modalselectThd}>
                {getselect}
            </select>
        </div>
        <div className="model_last">
        <span  className="long">remark:</span>
        <textarea className='modal_textarea' value={this.state.fsttextarea} onChange={this.fsttxarea}/>
        </div>
        </Modal>
        <Modal
          title="Logout"
          visible={this.state.scdvisible}
          onOk={this.thdhandleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" onClick={this.thdhandleOk}>
              Confirm
            </Button>,
          ]}
        >
            <p>After clicking the Confirm button to log out,customers will no longer be able to log in to our app.</p>
        </Modal>
        
        <Modal
          title="Reset login password"
          visible={this.state.thdvisible}
          onOk={this.fsthandleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" onClick={this.fsthandleOk}>
              Confirm
            </Button>,
          ]}
        >

            <p>After clicking the Confirm button to reset,the customer login password is reset to 1234.</p>

        </Modal>

        <Modal
          title="Modify"
          visible={this.state.fouvisible}
          onOk={this.scdhandleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" onClick={this.scdhandleOk}>
              Confirm
            </Button>,
          ]}
        >

        <input value={this.state.mobilephone} style={{display:'none'}}/>
        <div className="model_last">
        <span className="long" style={{width:120}}>First name:</span>
        <input className="modal_input" style={{marginLeft:'10%',width:290}} type='text' value={this.state.modalfst} onChange={this.ModalFst}/>
        </div>
        <div className="model_last">
        <span className="long" style={{width:120}}>Middle name:</span>
        <input className="modal_input" style={{marginLeft:'10%',width:290}} type='text' value={this.state.modalscd} onChange={this.ModalScd}/>
        </div>
        <div className="model_last">
        <span className="long" style={{width:120}}>Surname:</span>
        <input className="modal_input" style={{marginLeft:'10%',width:290}} type='text' value={this.state.modalthd} onChange={this.ModalThd}/>
        </div>
        <div className="model_last">
        <span className="long" style={{width:120}}>Bvn Resevrved phone:</span>
        <input className="modal_input" style={{marginLeft:'10%',width:290}} type='text' value={this.state.modalfou} onChange={this.ModalFou}/>
        </div>
        <div className="model_last">
        <span className="long" style={{width:120}}>Birhday:</span>
        <DatePicker  style={{marginLeft:'10%',width:290}}    value={this.state.datepiker===undefined||this.state.datepiker===""?null:moment(this.state.datepiker, dateFormat) }onChange={this.dateonChange} />
        </div>
        </Modal>
                <Pagination
                    // showQuickJumper
                    defaultCurrent={1}
                    current={this.state.current}
                    total={this.state.total}
                    onChange={this.pageChange}
                    showTotal={showTotal}
                    style={{textAlign: 'right', marginTop: 20}}
                    showSizeChanger={true}
                    pageSize={this.state.pageSize}
                />
            </div>
        )
    }
}
export default withRouter(UserInformation)