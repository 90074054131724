import React from "react";
import {
  Input,
  Button,
  Table,
  Modal,
  Pagination,
  DatePicker,
  message,
  Select,
  Row,
  Col,
} from "antd";
import "./index.css";
import axios from "axios";
import moment from "moment";
import $ from "jquery";
import { withRouter } from "react-router-dom";
import EventEmitter from "../../../utils/events";
import { thousandSignNumber, formatT0Time, getCollectionResult } from "../../../utils/index";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Search } = Input;
const dateFormat = "YYYY-MM-DD" || undefined;
var collector = [];
function showTotal(total) {
  return `Total ${total} items`;
}
class collectionormanagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fstSelect: "",
      searchDistributionState: '2',

      scdSelectt: "",
      thdSelect: "1",
      fouSelect: "",
      fstinpt: "",
      scdinpt: "",
      thdinpt: "",
      searchVal: "",
      mobileNum: "",
      current: 1,
      length: 0,
      boolean: true,
      ColSelect: "",
      selectedRowKeys: [],
      tableData: [],
      fstvisible: false,
      scdvisible: false,
      allocateVisible: false,
      order_statues: "please select one",
      from: "",
      to: "",
      rangvalue: "",
      fsttextarea: "",
      reduceRson: "",
      changeAmt: "",
      modalfstSelect: 0,
      modalscdSelect: "",
      inptdis: { display: "none" },
      reviewbutton: { display: "block" },
      reassignbutton: { display: "none" },
      detailsbutton: { display: "block" },
      reviewreason: "",
      pageSize: 10,
      show: true,
      hide: true,
      debit_money: "",
      fstInput: "",
      oldOrNew: "",
      selectedOverdue: "",
      collectorList: [],
      reduceVisible:false,
      selectedCollector:'',
      remark:"",
      isShow:false
    };
  }
  colortime = (record) => {
    let date11 = new Date(
      moment(new Date()).format("YYYY-MM-DD").replace(/-/g, "/")
    );
    let date22 = new Date(
      moment(new Date(record.collectionTime))
        .format("YYYY-MM-DD")
        .replace(/-/g, "/")
    );
    var date33 = date22.getTime() - date11.getTime();
    if (date33 == 0) {
      return <span style={{ color: "green" }}>{record.collectionTime}</span>;
    } else {
      return <span style={{ color: "red" }}>{record.collectionTime}</span>;
    }
  };
  //选择table触发
  handleChange = (pagination, filters, sorter) => {
    this.setState({});
  };

  rangeOnChange = (dates, dateStrings) => {
    this.setState({ from: dateStrings[0], to: dateStrings[1] });
  };

  handleAllocatedTimeOnChange = (dates, dateStrings) => {
    this.setState({
      allocatedBeginTime: dateStrings[0],
      allocatedEndTime: dateStrings[1],
    });
  };

  //获取select
  select = () => {
    axios.post(`/api/remindData/getCollectionUsers`, {}).then((res) => {
      if (res.data.code === 200) {
        collector = [];
        let data = res.data.data.resultDate;
        for (let i = 0; i < data.length; i++) {
          collector.push(
            <option data-userId={data[i].id} value={data[i].userName}></option>
          );
          // console.log('select',collector)
          this.setState({
            collector: collector,
          });
        }
      } else if (res.data.code == 800) {
        this.props.history.push({ pathname: "/" });
      } else {
      }
    });
  };
  //页码改变时触发
  pageChange = (page) => {
    this.setState(
      {
        current: page,
      },
      () => {
        this.searchClick();
      }
    );
  };
  //选择collector
  selectscdd(e) {
    var value = e.target.value;
    var userInfo = $("#iptt").val();
    var getUserId = $("option[value='" + userInfo + "']").data("userid");
    $("input[name='userId']").val(getUserId);
    this.setState({
      scdSelectt: value,
      idd: getUserId,
    });
  }
  oldOrderNew(e) {
    var value = e.target.value;
    this.setState({
      oldOrNew: value,
    });
  }
  debitMoney = (e) => {
    var value = e.target.value.replace(/[^0-9]/g, "");
    if (value > this.state.mostvalue) {
      value = this.state.mostvalue;
    }
    this.setState({
      debit_money: value,
    });
  };

  //选择collector
  handleSearchDistributionState(e) {
    var value = e.target.value;
    this.setState({
      searchDistributionState: value,
    });
  }

  //重置按钮
  reloadClick(e) {
    this.setState(
      {
        fstSelect: "",
        searchDistributionState: "2",
        scdSelectt: "",
        thdSelect: 1,
        fouSelect: "",
        searchVal: "",
        mobileNum: "",
        from: undefined,
        to: undefined,
        id: "",
        reduceAmt_val: "",
        reduceRson: "",
        ColSelect: "",
        fsttextarea: "",
        boolean: true,
        oldOrNew: "",
        btnloading: false,
      },
      () => {
        this.searchClick();
      }
    );
  }

  changeTime = (params) => {
    let time1 = params.time;
    let time2 = params.when;
    let date1 = new Date(time1);
    let date2 = new Date(time2);
    var date3 = date2.getTime() - date1.getTime();
    let days = Math.floor(date3 / (24 * 3600 * 1000));
    var leave1 = date3 % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
    let hours = Math.floor(leave1 / (3600 * 1000));
    var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
    let minutes = Math.floor(leave2 / (60 * 1000));
    if (days == 0 && hours < 4) {
      return <span style={{ color: "black" }}>{params.sure}</span>;
    } else if (days == 0 && hours >= 4 && hours < 8) {
      return <span style={{ color: "purple" }}>{params.sure}</span>;
    } else if (days == 0 && hours >= 8) {
      return <span style={{ color: "red" }}>{params.sure}</span>;
    } else {
      return <span style={{ color: "red" }}>{params.sure}</span>;
    }
  };
  changeRisk = (params) => {
    if (params.risk == 15) {
      return <span style={{ color: "red" }}>reject</span>;
    } else if (params.risk == -1) {
      return <span style={{ color: "red" }}>reject</span>;
    } else if (params.risk == -2) {
      return <span style={{ color: "red" }}>reject</span>;
    } else if (params.risk == 1) {
      return <span style={{ color: "blue" }}>pass</span>;
    } else if (params.risk == 2) {
      return <span style={{ color: "blue" }}>pass</span>;
    } else if (params.risk == 3) {
      return <span style={{ color: "orange" }}>warning</span>;
    } else {
      return;
    }
  };

  componentDidMount() {
    this.select();
    this.searchClick();
  }

  fstInputChange = (e) => {
    var value = e.target.value;
    this.setState({
      fstInput: value.replace(/^(0+)|[^\d]+/g, ""),
    });
  };
  //输入框
  searchIpt(e) {
    var value = e.target.value;
    this.setState({ searchVal: value.replace(/\s+/g, "") });
  }
  //输入框
  searchIpt1(e) {
    var value = e.target.value;
    this.setState({ mobileNum: value.replace(/\s+/g, "") });
  }
  //输入框bvn
  handleSearchBvn(e) {
    var value = e.target.value;
    this.setState({ searchBvn: value.replace(/\s+/g, "") });
  }

  //选择订单状态
  selectFst(e) {
    var value = e.target.value;
    this.setState({
      fstSelect: value,
    });
  }
  //选择collectiontype
  selectCol(e) {
    var value = e.target.value;
    this.setState({
      ColSelect: value,
    });
  }
  redecu_reson(e) {
    var value = e.target.value;
    this.setState({
      reduceRson: value,
    });
  }

  changeAmt = (e) => {
    var value = e.target.value;
    this.setState({
      reduceAmt_val: value,
    });
  };

  //选择apptype
  selectFou(e) {
    var value = e.target.value;
    this.setState({
      fouSelect: value,
    });
  }

  //搜索
  searchClick = () => {
    this.setState({
      boolean: true,
    });
    axios
      .post(`/api/loanUrge/getCollectionList`, {
        pageSize: this.state.pageSize,
        current: this.state.current,
        indentNo: this.state.searchVal,
        mobile: this.state.mobileNum,
        bvn: this.state.searchBvn,
        isDistributed: this.state.searchDistributionState,
        expireDay: this.state.fstSelect,
        collectionUserId: this.state.idd,
        appCode: this.state.fouSelect,
        beginTime: this.state.from,
        endTime: this.state.to,
        allocatedBeginTime: this.state.allocatedBeginTime,
        allocatedEndTime: this.state.allocatedEndTime,
        isCollection: this.state.ColSelect,
        remainBalance: this.state.fstInput,
        newOrOld: this.state.oldOrNew,
      })
      .then((res) => {
        if (res.data.code === 200) {
          message.success(res.data.msg);
          if (res.data.data.resultDate.length < 1) {
            this.setState({
              boolean: false,
            });
          } else {
            for (let i = 0; i < res.data.data.resultDate.length; i++) {
              let reviewers = res.data.data.resultDate[i].reviewers;
              if (reviewers == null) {
                reviewers = res.data.data.resultDate[i].finalReceiver;
              }
              let collectionTimes = res.data.data.resultDate[i].collectionTime;
              if (collectionTimes != null) {
                collectionTimes = moment(
                  new Date(new Date(collectionTimes))
                ).format("YYYY-MM-DD HH:mm:ss");
              }
            }
          }
          this.setState({
            tableData: res.data.data.resultDate,
            length: res.data.data.page.total,
            boolean: false,
            isShow:res.data.data.isshow
          });
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
        }
      });
  };

  //控制审核按钮model
  showFstModal = (record) => {
    var id = record.id;
    var uid = record.uid;
    this.setState({
      fstinpt: id,
      scdinpt: uid,
      fstvisible: true,
      debit_money: record.alsoNeedBackp,
      mostvalue: record.alsoNeedBackp,
    });
    // this.gradeChange()
  };

  //第一个textarea
  fsttxarea = (e) => {
    let value = e.target.value;
    this.setState({ fsttextarea: value });
  };

  //控制重新分配按钮model
  showScdModal = (record) => {
    let a = record.id;
    let b = record.alsoNeedBackp;
    let c = record.number;
    axios
      .post(`/api/reduce/selectReduce`, {
        loanId: a,
      })
      .then((res) => {
        if (res.data.code == 200) {
          this.setState({
            hide: true,
            show: false,
            getVal: b,
            scdvisible: true,
            hideid: a,
            hideidno: c,
          });
        } else {
          this.setState({
            hide: false,
            show: true,
            scdvisible: true,
          });
        }
      });
  };
  //第个modal提交按钮
  fsthandleOk = (e) => {
    // console.log(e);//
    if (this.state.debit_money < 100) {
      alert("Debit amount must exceed 100");
      return false;
    }
    var data = {};
    if (this.state.debit_money == this.state.mostvalue) {
      data = {
        id: this.state.fstinpt,
        uid: this.state.scdinpt,
      };
    } else {
      data = {
        money: this.state.debit_money,
        id: this.state.fstinpt,
        uid: this.state.scdinpt,
      };
    }
    axios.post(`/api/loanUrge/withhold`, data).then((res) => {
      if (res.data.code == 200) {
        this.searchClick();
        alert(res.data.msg);
      } else if (res.data.code == 800) {
        this.props.history.push({ pathname: "/" });
      } else {
        alert(res.data.msg);
      }
    });
    this.setState({
      fstvisible: false,
    });
  };
  scdhandleOk = (e) => {
    if (this.state.getVal < this.state.reduceAmt_val) {
      message.error(
        "The amount of the reduction cannot be greater than the amount of repayment."
      );
      this.setState({
        reduceAmt_val: "",
        reduceRson: "",
        fsttextarea: "",
      });
      return false;
    } else {
      this.setState({
        btnloading: true,
      });
      axios
        .post(`/api/reduce/saveReduce`, {
          loanId: this.state.hideid,
          loanIndentNo: this.state.hideidno,
          reduceAmt: this.state.reduceAmt_val,
          reduceReason: this.state.reduceRson,
          remark: this.state.fsttextarea,
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.setState({
              boolean: true,
              btnloading: false,
            });
            this.reloadClick();
          } else if (res.data.code == 800) {
            this.props.history.push({ pathname: "/" });
          } else if (res.data.code == 400) {
            message.error(res.data.msg);
            this.setState({
              btnloading: false,
            });
          } else {
            this.reloadClick();
          }
        });
      this.setState({
        scdvisible: false,
      });
    }
  };
  outOnChange = (value, record, ifCheck) => {
    axios
      .post(`/api/loanUrge/updateExtensionStatus`, {
        loanRecordId: record.id,
      })
      .then((res) => {
        if (res.data.code === 200) {
          message.success(res.data.msg);
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
          message.error(res.data.msg);
        }
      });
  };
  onShowSizeChange = (current, pageSize) => {
    this.setState(
      {
        pageSize: pageSize,
      },
      () => {
        this.searchClick();
      }
    );
  };
  thdhandleOk = (e) => {
    let arr = this.state.selectedRowKeys;
    let lastarr = arr.join(",");

    axios
      .post(`/api/loanUrge/saveCollection`, {
        id: lastarr,
        overdueGrade: this.state.selectedRows[0].overdueGrade,
        uid: this.state.selectedCollector,
      })
      .then((res) => {
        if (res.data.code == 200) {
          this.setState({
            boolean: true,
          });
          this.searchClick();
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
          message.error(res.data.msg);
        }
      });
    this.setState({
      allocateVisible: false,
      selectedRowKeys: "",
      selectedCollector: "",
    });
  };
  handleCancel = (e) => {
    console.log(e);
    this.setState({
      allocateVisible: false,
      fstvisible: false,
      scdvisible: false,
      selectedRowKeys: "",
      selectedCollector: "",
      allocateVisible:false,
      reduceVisible:false
    });
  };

  todetail(record, item) {
    window.location = "/#/details/index?id=" + record.id + "&uid=" + record.uid;
    let test = [
      {
        path: "/details/index",
        key: `/details/index/${record.id}/${record.uid}`,
        number: `${record.id}/${record.uid}`,
      },
    ];
    EventEmitter.trigger("btn", test[0]);
    EventEmitter.trigger("idanduid", test[0].number);
  }

  //modal第三个select
  modelselectThree = (e) => {
    var value = e.target.value;
    this.setState({
      modalthdSelect: value,
    });
  };

  handleTipsModalOk = () => {
    this.setState({
      tipsvisible: false,
    });
  };
  handleTipsModalCancel = () => {
    this.setState({
      tipsvisible: false,
    });
  };

  handleCollectorChange = (e) => {
    const { value } = e.target;
    this.setState({
      remark: value.replace(/(^\s*)|(\s*$)/g, ""),
    });
  };

  handleCollectorChanges = (e) => {
    const { value } = e.target;
    this.setState({
      selectedCollector: value.replace(/(^\s*)|(\s*$)/g, ""),
    });
  };

  massReduction = () =>{
    if(this.state.fstInput){
      if(this.state.selectedRows !== undefined){
        this.setState({
          reduceVisible:true
        })
      }else{
        message.error('Please select one');
      }
      // return false;
    }else{
      message.error('Please enter the amount of exemption for batch application first');

    }
  }

  handleReduceOk= ()=>{
    console.log(this.state.selectedRowKeys)
    let loanIndentNo = []
    this.state.selectedRows.map(item =>{
      loanIndentNo.push(item.indent_no)
    })
    axios.post(`/api/reduce/batchReductionApplication`,{
      loanId:this.state.selectedRowKeys,
      loanIndentNo:loanIndentNo,
      reduceAmt:this.state.fstInput,
      remark:this.state.remark
    }).then(res=>{
      if(res.data.code == 200){
        message.success(res.data.msg)
        this.setState({
          reduceVisible:false,
          remark:'',
          fstInput:'',
        })
        this.handleCancel()
        this.searchClick();
      }else{
        message.error(res.data.msg)
      }
    })
  }

  handleReduceCancel = () =>{
    this.state.reduceVisible = false,
    this.state.fstInput= ''
    this.state.remark = ''
  }


  redistribution = () => {
    if (this.state.selectedRowKeys.length < 1) {
      message.error("please select one");
      return false;
    }
    for (let i = 0; i < this.state.selectedRows.length - 1; i++) {
      for (let j = i + 1; j < this.state.selectedRows.length; j++) {
        if (
          this.state.selectedRows[i].overdueGrade !==
          this.state.selectedRows[j].overdueGrade
        ) {
          this.setState({
            tipsvisible: true,
          });
          return;
        }
      }
    }
    if (!this.state.selectedRows[0].overdueGrade) {
      message.error("Abnormal orders!");
      return false;
    }
    axios
      .post(`/api/system/listCollector`, {
        overdueGrade: this.state.selectedRows[0].overdueGrade,
      })
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            collectorList: res.data.data,
            selectedOverdue: this.state.selectedRows[0].overdue,
            allocateVisible: true,
          });
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        }
      });
    // console.log(this.state.id);
  };


  render() {
    const styleOne = {
      style:{
        marginLeft: 20,
        width: 170,
        height: 40,
        border: "none",
        display:this.state.isShow == false ? 'none' : 'block'
      }
    }
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: (selectedRowkeys, selectedRows) => {
        this.setState({
          selectedRowKeys: selectedRowkeys,
          selectedRows: selectedRows,
        });
      },
      columnWidth: 40,
    };
    const columns = [
      {
        title: "Order No",
        dataIndex: "indent_no",
        key: "indent_no",
      },
      {
        title: "Customer name",
        dataIndex: "first_name",
        key: "first_name",
      },
      {
        title: "phone",
        dataIndex: "mobile",
        key: "mobile",
      },
      {
        title: "Bvn",
        dataIndex: "dentity_card",
        key: "dentity_card",
      },
      {
        title: "Loan amount",
        dataIndex: "balance",
        render: (text, record) => thousandSignNumber(text / 100),
      },
      {
        title: "loan term",
        dataIndex: "cycle",
        key: "cycle",
      },
      {
        title: "Should repayment amount",
        dataIndex: "shouldback_fee",
        render: (text, record) => thousandSignNumber(text / 100),
      },
      {
        title: "Should repayment date",
        dataIndex: "shouldback_time",
        key: "shouldback_time",
        render: (text, record) => formatT0Time(text),
      },
      {
        title: "Repayment amount",
        dataIndex: "actualback_amt",
        render: (text, record) => thousandSignNumber(text / 100),
      },
      {
        title: "Remaining balance",
        dataIndex: "remainingbalance",
        render: (text, record) =>
          thousandSignNumber(
            (record.shouldback_fee - record.actualback_amt) / 100
          ),
      },
      {
        title: "Overdue days",
        dataIndex: "overduedays",
        key: "overduedays",
      },
      {
        title: "Overdue Grade",
        dataIndex: "overdue",
        key: "overdue",
      },
      {
        title: "Should extend",
        dataIndex: "extensionFees",
        render: (text, record) => thousandSignNumber(text / 100),
      },
      {
        title: "Successful repayment count",
        dataIndex: "repayment_count",
        key: "repayment_count",
      },
      {
        title: "Distribution state",
        dataIndex: "distributionstate",
        key: "distributionstate",
        render: (text, record) => (
          <span>{record.allocate_time ? "distributed" : "undistributed"}</span>
        ),
      },
      {
        title: "Allocate time",
        dataIndex: "allocate_time",
        key: "allocate_time",
        width: 100,
      },

      {
        title: "Collector",
        dataIndex: "reviewers",
        key: "reviewers",
        width: 100,
      },
      {
        title: "Last collection time",
        dataIndex: "collectionTime",
        key: "collectionTime",
        render: (text, record) => this.colortime(record),
      },
      {
        title: "Collection Result",
        dataIndex: "CollectionResult",
        key: "CollectionResult",
        render: (text, record) => getCollectionResult(record.collectionResult),
      },
      {
        title: "collection detail",
        dataIndex: "collectionDetails",
        key: "collectionDetails",
      },
      {
        title: "operating",
        dataIndex: "operating",
        key: "operating",
        status: "status",
        id: "id",
        uid: "uid",
        fixed: "right",
        render: (text, record, index) => (
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            {/* <Button
              type="primary"
              style={this.state.reviewbutton}
              className="reviewbutton"
              size="small"
              onClick={() => this.showFstModal(record)}
            >
              debit
            </Button> */}

            <Button
              type="primary"
              style={this.state.detailsbutton}
              className="detailsbutton"
              size="small"
              onClick={() => this.todetail(record)}
            >
              details
            </Button>
            {/* <Button
              type="primary"
              style={{ display: record.reduceAmt === 0 ? "block" : "none" }}
              className="reassignbutton"
              size="small"
              onClick={() => this.showScdModal(record)}
            >
              reduce
            </Button> */}
          </span>
        ),
      },
    ];
    return (
      <div className="credit_order">
        <div className="credit_content">
          <div className="flutter">
            <Row className="search_form">
              <Col span={8}>
                <div className="search">
                  <p className="form_label">Order No:</p>
                  <Search
                    placeholder="Order No"
                    onSearch={(value) => console.log(value)}
                    style={{ width: 290 }}
                    value={this.state.searchVal}
                    onChange={this.searchIpt.bind(this)}
                  />
                </div>
              </Col>
              <Col span={8}>
                <div className="top_mid">
                  <p className="form_label">Phone:</p>
                  <Search
                    placeholder="Phone"
                    onSearch={(value) => console.log(value)}
                    style={{ width: 290 }}
                    value={this.state.mobileNum}
                    onChange={this.searchIpt1.bind(this)}
                  />
                </div>
              </Col>
              <Col span={8}>
                <div className="top_right">
                  <p className="form_label">Bvn:</p>
                  <Search
                    placeholder="Bvn"
                    onSearch={(value) => console.log(value)}
                    style={{ width: 290 }}
                    value={this.state.searchBvn}
                    onChange={this.handleSearchBvn.bind(this)}
                  />
                </div>
              </Col>
              <Col span={8}>
                <div className="mid_mid">
                  <p className="form_label">Collector:</p>

                  <input
                    list="browsers"
                    style={{ width: 290 }}
                    placeholder="all"
                    className="form_select_mohu"
                    id={this.state.idd}
                    id="iptt"
                    value={this.state.scdSelectt}
                    onChange={this.selectscdd.bind(this)}
                  />
                  <datalist id="browsers">{collector}</datalist>
                </div>
              </Col>
              <Col span={8}>
                <div className="rangepick">
                  <p className="form_label">should repayment time:</p>
                  <RangePicker
                    style={{ width: 290 }}
                    ranges={{
                      Today: [moment(), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                    }}
                    showTime
                    format={dateFormat}
                    onChange={this.rangeOnChange}
                    value={
                      this.state.from === undefined ||
                      this.state.to === undefined ||
                      this.state.from === "" ||
                      this.state.to === ""
                        ? null
                        : [
                            moment(this.state.from, dateFormat),
                            moment(this.state.to, dateFormat),
                          ]
                    }
                  />
                </div>
              </Col>
              <Col span={8}>
                <div className="rangepick">
                  <p className="form_label">Allocated time:</p>
                  <RangePicker
                    style={{ width: 290 }}
                    ranges={{
                      Today: [moment(), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                    }}
                    showTime
                    format={dateFormat}
                    onChange={this.handleAllocatedTimeOnChange}
                    value={
                      this.state.allocatedBeginTime === undefined ||
                      this.state.allocatedEndTime === undefined ||
                      this.state.allocatedBeginTime === "" ||
                      this.state.allocatedEndTime === ""
                        ? null
                        : [
                            moment(this.state.allocatedBeginTime, dateFormat),
                            moment(this.state.allocatedEndTime, dateFormat),
                          ]
                    }
                  />
                </div>
              </Col>
              <Col span={8}>
                <div className="mid_mid">
                  <p className="form_label">Distribution status:</p>
                  <select
                    className="form_select"
                    id="getselect"
                    value={this.state.searchDistributionState}
                    onChange={this.handleSearchDistributionState.bind(this)}
                  >
                    <option value="">all</option>
                    <option value="1">allocated</option>
                    <option value="2">undistributed</option>
                  </select>
                </div>
              </Col>
              <Col span={8}>
                <div className="mid_right">
                  <p className="form_label">Overdue Grade:</p>
                  <select
                    className="form_select"
                    value={this.state.fstSelect}
                    onChange={this.selectFst.bind(this)}
                  >
                    <option value="0">all</option>
                    <option value="1">S1</option>
                    <option value="2">S2</option>
                    <option value="3">M1</option>
                    <option value="4">M2</option>
                    <option value="5">M3</option>
                    <option value="6">M4</option>
                  </select>
                </div>
              </Col>
              <Col span={8}>
                <div className="top_mid">
                  <p className="form_label">Remaining balance:</p>
                  <input
                    type="text"
                    placeholder="all"
                    value={this.state.fstInput}
                    onChange={this.fstInputChange}
                    className="modal_input"
                    style={{ width: 290, marginLeft: 0 }}
                  />
                </div>
              </Col>
              <Col span={8}>
                <div className="mid_left">
                  <p className="form_label">oldOrNew:</p>
                  <select
                    className="form_select"
                    value={this.state.oldOrNew}
                    onChange={this.oldOrderNew.bind(this)}
                  >
                    <option value="">all</option>
                    <option value="1">new User</option>
                    <option value="2">old User</option>
                  </select>
                </div>
              </Col>
            </Row>

            <div className="bottom">
              <Button
                type="primary"
                className="common_color"
                style={{
                  width: 170,
                  marginRight: 20,
                  height: 40,
                  border: "none",
                }}
                onClick={this.searchClick.bind(this)}
              >
                Search
              </Button>
              {/* <Button
                type="primary"
                style={{
                  width: 170,
                  height: 40,
                  backgroundColor: "#1CA9BA",
                  border: "none",
                }}
                onClick={this.reloadClick.bind(this)}
              >
                Refresh
              </Button> */}
              <Button
                className="common_color_scd"
                style={{
                  marginLeft: 20,
                  width: 170,
                  height: 40,
                  border: "none",
                }}
                onClick={this.redistribution}
                type="primary"
              >
                Batch re-allocate
              </Button>
              <Button
                type="primary"
                className="common_color_bacth"
                // style={{
                //   marginLeft: 20,
                //   width: 170,
                //   height: 40,
                //   border: "none",
                // }}
                {...styleOne}
                onClick={this.massReduction}
              >
                Bacth reduce
              </Button>
            </div>
          </div>
        </div>

        <Pagination
          defaultCurrent={1}
          current={this.state.current}
          total={this.state.length}
          onChange={this.pageChange}
          pageSize={this.state.pageSize}
          showTotal={showTotal}
          style={{ textAlign: "right", marginTop: 20 }}
        />
        <Table
          columns={columns}
          dataSource={this.state.tableData}
          rowKey={(record) => record.id}
          scroll={{ x: "110%" }}
          loading={this.state.boolean}
          onChange={this.handleChange}
          rowSelection={rowSelection}
          pagination={false}
        />

        <Modal
          title="debit"
          visible={this.state.fstvisible}
          onOk={this.fsthandleOk}
          onCancel={this.handleCancel}
        >
          <input
            type="text"
            value={this.state.fstinpt}
            style={{ display: "none" }}
          />
          <input
            type="text"
            value={this.state.scdinpt}
            style={{ display: "none" }}
          />
          <div className="both_div">
            <span>debit amount:</span>
            <input
              value={this.state.debit_money}
              onChange={this.debitMoney}
              className="reduce_inpt"
            />
          </div>
        </Modal>

        <Modal
          title="reduce apply"
          visible={this.state.scdvisible}
          onOk={this.scdhandleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              cancel
            </Button>,

            <Button
              key="submit"
              type="primary"
              loading={this.state.btnloading}
              onClick={this.scdhandleOk}
            >
              ok
            </Button>,
          ]}
        >
          <input value={this.state.hideid} style={{ display: "none" }} />
          <input value={this.state.hideidno} style={{ display: "none" }} />
          {this.state.show && (
            <p>
              The current order reduction application is under review, please be
              patient!
            </p>
          )}
          {this.state.hide && (
            <div>
              <div className="both_div">
                <span>remaining balance:</span>
                <input
                  value={this.state.getVal}
                  disabled
                  className="reduce_inpt"
                />
              </div>
              <div className="both_div">
                <span>reduce amount:</span>
                <input
                  value={this.state.reduceAmt_val}
                  onChange={this.changeAmt}
                  className="reduce_inpt"
                />
              </div>
              <div className="both_div">
                <span>reduce reason:</span>
                <select
                  id="reduceReason"
                  name="reduceReason"
                  value={this.state.reduceRson}
                  className="reduce_inpt"
                  onChange={this.redecu_reson.bind(this)}
                >
                  <option value="" selected>
                    Please select
                  </option>
                  <option value="Repayment delay">Repayment delay</option>
                  <option value="Not cleared in time">
                    Not cleared in time
                  </option>
                  <option value="Third party payment">
                    Third party payment
                  </option>
                  <option value="Customer funding difficulties">
                    Customer funding difficulties
                  </option>
                  <option value="Customer refuses payment">
                    Customer refuses payment
                  </option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="both_div">
                <span>remark:</span>
                <textarea
                  style={{ width: "65%", marginLeft: "3%", height: 80, }}
                  value={this.state.fsttextarea}
                  onChange={this.fsttxarea}
                />
              </div>
            </div>
          )}
        </Modal>

        <Modal
          title="Batch re-allocate"
          visible={this.state.allocateVisible}
          onOk={this.thdhandleOk}
          onCancel={this.handleCancel}
        >
          <div>
            <lable style={{ fontSize: 15, fontWeight: "bold" }}>
              Collection level:{" "}
            </lable>
            <span>{this.state.selectedOverdue}</span>
            <br></br>
          </div>
          <div>
            <lable style={{ fontSize: 15, fontWeight: "bold" }}>
              Collector:{" "}
            </lable>
            <Input
              style={{ width: 200 }}
              value={this.state.selectedCollector}
              onChange={this.handleCollectorChanges.bind(this)}
            ></Input>
          </div>
        </Modal>

        {/* bacth reduce弹框 */}
        <Modal
          title="Batch re-allocate"
          visible={this.state.reduceVisible}
          onOk={this.handleReduceOk}
          onCancel={this.handleCancel}
        >
          <div>
            <lable style={{ fontSize: 15, fontWeight: "bold" }}>
            remaining balance:
            </lable>
            ≤<Input
              style={{ width: '50%',marginLeft:'9%' }}
              value={this.state.fstInput}
              onChange={this.fstInputChange.bind(this)}
            ></Input>
            {/* <span>{this.state.selectedOverdue}</span> */}
            <br></br>
          </div>
          <div style={{marginTop:20}}>
            <lable style={{ fontSize: 15, fontWeight: "bold",verticalAlign: 'top'}}>
            reduce reason:{" "}
            </lable>
            <textarea style={{border:"1px solid #d9d9d9",width:'50%',marginLeft:'17%',height:80}} value={this.state.remark} onChange={this.handleCollectorChange.bind(this)}/>
          </div>
        </Modal>


        <Pagination
          showSizeChanger //展示改变pagesize样式
          onShowSizeChange={this.onShowSizeChange} //pagesize改变时回调函数
          pageSizeOptions={["10", "50", "100"]} //设置pagesize区间
          defaultCurrent={1}
          current={this.state.current}
          total={this.state.length}
          onChange={this.pageChange}
          pageSize={this.state.pageSize}
          showTotal={showTotal}
          style={{ textAlign: "right", marginTop: 20 }}
        />

        {/* 您批量选择的订单类型等级不一致，请重新选择 */}
        <Modal
          title="Tips"
          visible={this.state.tipsvisible}
          onOk={this.handleTipsModalOk}
          onCancel={this.handleTipsModalCancel}
        >
          <p>
            The order type level you selected in bulk is not consistent, please
            re-select it
          </p>
        </Modal>
      </div>
    );
  }
}
export default withRouter(collectionormanagement);
