/*eslint-disable*/
import React from 'react';
import { Input,Button,Table,Modal,Radio,Pagination,Tooltip,message,Tabs,DatePicker } from 'antd';
import './index.css';
import axios from 'axios'
import {Link} from 'react-router-dom'
import moment from 'moment';
import $ from 'jquery';
import location_red from '../../../../public/img/collection_imgleft.png';
import right from '../../../../public/img/collection_imgright.png';
import {withRouter} from 'react-router-dom'
import EventEmitter from '../../../utils/events'

const dateFormat = 'YYYY-MM-DD'||undefined;
const { RangePicker } = DatePicker;
var collector = [];
const { TabPane } = Tabs;
var getSelect = [];
var selector = [];
const DateFormat = 'YYYY-MM-DD'||undefined;

var whitchshow = []
function callback(key) {
    console.log(key);
  }

  function showTotal(total) {
      return `Total ${total} items`;
    }
 class Coupon extends React.Component {
    constructor(props){
      super(props);
      this.state={
        number:'',
        searchVal:'',
        current:1,
        length:0,
        boolean:true,
        topboolean:true,
        from:'',
        to:'',
        pageSize:20,
        isshow:true,
        ishide:false,
        scdSelect:'',
        id:'',
        fstSelect:'',
        modaltitle:'新增',
        showhide:false,
        hideshow:false,
        fstModalInput:'',
        fstAddVal:'',
        scdModalInput:'',
        thdModalInput:'',
        fouModalInput:'',
        radvalue:1,
        datepiker:'',
        datepiker2:'',
        fivModalInput:'',
        scdafter:false
      }
     
    }
    //选择table触发
    handleChange = (pagination, filters, sorter) => {
      this.setState({
      });
    };
    rangeOnChange=(dates, dateStrings)=>{
      this.setState({ from: dateStrings[0],to:dateStrings[1] })
    }
    //页码改变时触发
    pageChange=(page)=> { 
      
    this.setState({
      current: page
      },()=>{
        this.searchClick()
      })
    
    } 

       //搜索
       searchClick = () => {
        axios
            .post(`/api/coupon/getCouponList`, {

                indentNo: this.state.fstInput,
                name: this.state.scdInput,
                pageSize: this.state.pageSize,
                current: this.state.current,
                status: this.state.scdSelect,
                type:this.state.fstSelect,
            })
            .then(res => {
                if (res.data.code === 200) {

                    const data1 = [];
                    const data = res.data.data.resultDate

                    for (let i = 0; i < data.length; i++) {
                        data[i].key = i;
                        data1.push(data[i]);
                    }
                    this.setState({
                      tableData: data1,
                        total: res.data.data.page.total,
                        boolean: false
                    })
                } else if (res.data.code == 800) {
                    this.props.history.push({pathname: "/"});
                } else {
                }
            })
    }


    add=(e)=>{
      this.setState({
        scdvisible:true,
        modaltitle:'新增',
      });

    }
    dateonChange = (date, dateString) =>{
        this.setState({ datepiker:dateString})
      }
      dateonChange2 = (date, dateString) =>{
        this.setState({ datepiker2:dateString})
      }
  componentDidMount(){
    this.searchClick()
  }


 



    handleCancel = e => {
      console.log(e);
      this.setState({
        scdvisible: false,
        modaltitle:'',
        fstModalInput:'',
        fstAddVal:'',
        thdModalInput:'',
        fouModalInput:'',
        scdModalInput:'',
        fivModalInput:'',
        datepiker:'',
        datepiker2:'',
        showhide:false,
        hideshow:false,
        scdafter:false
      });
    };

    edit=(record)=>{
      if(record.type==1){
        this.setState({
          showhide:true,
          hideshow:false
        })
      }else if(record.type == 2 || record.type == 3 || record.type == 4|| record.type == 5){
       this.setState({
         showhide:false,
         hideshow:true
       })
      }else{
       this.setState({
         showhide:false,
         hideshow:false
       })
      }
      if(!record.effectiveDays){
        this.setState({
          radvalue:1
        })
      }else{
        this.setState({
          radvalue:-1
        })
      }
      console.log(record)
        this.setState({
            modaltitle:'编辑',
            scdvisible:true,
            id:record.id,
            fstModalInput:record.name,
            fstAddVal:record.type,
            thdModalInput:record.discount,
            fouModalInput:record.maxDiscountAmount/100,
            scdModalInput:record.amount/100,
            fivModalInput:record.effectiveDays,
            datepiker:record.enabledDate,
            datepiker2:record.disabledDate
        })
    }
    selectFst=(e)=>{
      var value = e.target.value;
      this.setState({
        fstSelect:value,
      });
    }
    selectScd=(e)=>{
      var value = e.target.value;
      this.setState({
        scdSelect:value,
      });
    }
    fstInputChange=(e)=>{
      var value = e.target.value;
      this.setState({
        fstInput:value,
      });
    }
    scdInputChange=(e)=>{
      var value = e.target.value;
      this.setState({
        scdInput:value,
      });
    }
    fstOnchange=(e)=>{
     
      var value = e.target.value;
      this.setState({
        fstAddVal:value,
      },()=>{
        if(this.state.fstAddVal==1){
         this.setState({
           showhide:true,
           hideshow:false,
           thdModalInput:'',
           fouModalInput:''
         })
       }else if(this.state.fstAddVal == 2 || this.state.fstAddVal == 3 || this.state.fstAddVal == 4|| this.state.fstAddVal == 5){
        this.setState({
          showhide:false,
          hideshow:true,
          scdModalInput:'',
        })
       }else{
        this.setState({
          showhide:false,
          hideshow:false,
          scdModalInput:'',
          thdModalInput:'',
           fouModalInput:''
        })
       }
      });
      
    }
    reload=()=>{
      this.setState({
        fstInput:'',
        scdInput:'',
        fstSelect:'',
        scdSelect:'',
      },()=>{
        this.searchClick()
      })
    }
    handleOk=()=>{  
      this.setState({
        scdafter:true
      })
      if(this.state.fstModalInput==''){
        alert('优惠券名称不能为空')
        return false
      } if(this.state.fstAddVal==''){
        alert('优惠类型不能为空')
        return false
      } if(this.state.fstAddVal==1&&this.state.scdModalInput==''){
        alert('立减券面额不能为空')
        return false
      } if(this.state.fstAddVal!=1&&this.state.fstAddVal!=''){
        if(this.state.thdModalInput==''){
          alert('折扣不能为空')
          return false
        }
        else if(this.state.fouModalInput==''){
          alert('最高优惠不能为空')
          return false
        }
      }
      if(this.state.radvalue==1){
        if(this.state.datepiker==''||this.state.datepiker2==''){
          alert('固定有效期限不能为空')
          return false
        }
      }else if(this.state.radvalue==-1){
        if(this.state.fivModalInput==''){
          alert('相对可用时间不能为空')
          return false
        }
      }
      this.setState({
        scdvisible: false,
      });
      axios
        .post(`/api/coupon/saveCouponInfo`,{
          id :this.state.id,
          name : this.state.fstModalInput,
          type : this.state.fstAddVal,
          discount: this.state.thdModalInput,
          maxDiscountAmount : this.state.fouModalInput*100,
          amount  : this.state.scdModalInput*100,
          effectiveDays  : this.state.fivModalInput,
          enabledDate   : this.state.datepiker,
          disabledDate  : this.state.datepiker2,
      })
      .then(res=>{
        if(res.data.code === 200){
          this.setState({
            boolean:true,
            scdafter:false
          })
          this.searchClick()
        }
        else if(res.data.code ==800){
          this.props.history.push({ pathname: "/"});
        }else{
  
        }
      })
      this.setState({
        fstvisible: false,
        fstModalInput:'',
        fstAddVal:'',
        thdModalInput:'',
        fouModalInput:'',
        scdModalInput:'',
        fivModalInput:'',
        datepiker:'',
        datepiker2:'',
        id:''
      });
    }
    radioOnChange = e => {
      console.log('radio checked', e.target.value);
      this.setState({
          radvalue: e.target.value,
      });
      if(e.target.value==1){
        this.setState({
          fivModalInput:'',
          botdis:true,
          topdis:false,
        })
      }else{
        this.setState({
          datepiker:'',
          datepiker2:'',
          botdis:false,
          topdis:true,
        })
      }
    };
    scdInputOnchange=(e)=>{
      var value = e.target.value;
      this.setState({
        scdModalInput:value,
      });
    }
    thdInputOnchange=(e)=>{
      var value = e.target.value;
      this.setState({
        thdModalInput:value,
      });
    }
    fouInputOnchange=(e)=>{
      var value = e.target.value;
      this.setState({
        fouModalInput:value,
      });
    }
    fstInputOnchange=(e)=>{
      var value = e.target.value;
      this.setState({
        fstModalInput:value,
      });
    }
    fivInputOnchange=(e)=>{
      var value = e.target.value;
      this.setState({
        fivModalInput:value,
      });
    }
    render() {
      const columns = [
        {
          title: '优惠券ID',
          dataIndex: 'indentNo',
          key: 'indentNo',
          width:100,
        },
        {
          title: '优惠券名称',
          dataIndex: 'name',
          key: 'name',
          width:100,
        },
        {
          title: '优惠类型',
          dataIndex: 'type',
          key: 'type',
          width:100,
          render: (value) => {
            if(value==1){
              return '立减券'
            }else if(value==2){
              return '折扣券（总还款金额）'
            }else if(value==3){
              return '折扣券（总息费）'
            }else if(value==4){
              return '折扣券（利息）'
            }else if(value==5){
              return '折扣券（服务费）'
            }
        }
        },
        {
          title: '详细',
          dataIndex: 'info',
          key: 'info',
          width:100,
          render: (value,record) => {
            if(record.type==1){
              return '立减' + record.amount/100
            }else{
              return '折扣' + record.discount + '%,最高' + record.maxDiscountAmount/100 
            }
        }
        },

        {
          title: '优惠券期限',
          dataIndex: 'effectiveDays',
          key: 'effectiveDays',
          width:100,
          render: (value,record) => {
            if(value != '' && value!= null){
              return value
            }else{
              let timeaudit= new Date(record.enabledDate).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
              let beginTime=moment(new Date(timeaudit)).format("YYYY-MM-DD HH:mm:ss").substring(0,10);;
              let timeaudit1= new Date(record.disabledDate).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
              let endTime=moment(new Date(timeaudit1)).format("YYYY-MM-DD HH:mm:ss").substring(0,10);

              return beginTime + ' ~ ' +  endTime
            }
        }

        },
        {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
          width:100,
          render: (value) => {
            if(value==1){
              return '未关联'
            }else{
              return '已关联'
            }
        }
        },
      
       
        {
          title: 'operating',
          dataIndex: 'operating',
          key: 'operating',
          width:104,
          id:'id',
          // uid:'uid',
          render: (text, record, index) => (<span style={{  display: record.status==2? "none" : 'inline-block',}} className='coupon_button'> 
            <a onClick={() => this.edit(record)}  className='coupon_button common_color'>编辑</a>
            </span>)
        },
      ];
      return (
        <div className="credit_order" style={{overflow:'hidden'}}>
          

          <div className="content_mid">
            <div className="mid_bot">
            <div className="mid_left" style={{width:'25%'}}>
                <span style={{width: 70,}} className="description left">优惠券ID</span>
               <input type="text" placeholder='输入ID' value={this.state.fstInput} onChange={this.fstInputChange} className="modal_input width"  style={{width: 200,}}/>
            </div>
              <div className="mid_mid" style={{width:'25%',flexShrink:2,marginLeft:'1%',marginRight:'1%'}}>
                <div className="rangepick">
                    <span style={{width: 40,}}className="description mid_span">名称</span>
                    <input type="text" placeholder='输入名称' value={this.state.scdInput} onChange={this.scdInputChange} className="modal_input width"  style={{width: 200,}}/>
                </div>
              </div>

              <div className="mid_right" style={{width:'25%'}}>
                  <span  style={{width:80,}} className="description right">优惠类型</span>
                  <select  style={{width: 200,}} className="coupon_form_select" value={this.state.fstSelect} onChange={this.selectFst}>
                  <option value="">请选择</option>
                  <option value="1">立减券</option>
                  <option value="2">折扣券(总还款金额)</option>
                  <option value="3">折扣券(总息费)</option>
                  <option value="4">折扣券(利息)</option>
                  <option value="5">折扣券(服务费)</option>

                </select>
              </div>
              <div className="mid_right" style={{width:'25%'}}>
                  <span  style={{width: 40,}} className="description right">状态</span>
                  <select  style={{width: 200,}} className="coupon_form_select" value={this.state.scdSelect} onChange={this.selectScd}>
                  <option value="">全部</option>
                  <option value="2">已关联</option>
                  <option value="1">未关联</option>
                </select>
              </div>
            </div>
              <div className="bottom">
                <Button type="primary" style={{width:170,marginRight:20,height:40,border:'none'}}  className="common_color" onClick={this.searchClick}>搜索</Button>
                <Button type="primary" style={{width:170,height:40,marginRight:20,border:'none'}} className="common_color_scd" onClick={this.add}>新增</Button>
                <Button type="primary" style={{width:170,height:40,marginRight:20,border:'none'}} className="common_color_thd"onClick={this.reload}>重置</Button>
              </div>    
            </div>

          <Table columns={columns}  dataSource={this.state.tableData}  loading={this.state.boolean} onChange={this.handleChange} pagination={false} />


        <Pagination
         defaultCurrent={1} 
         current={this.state.current}
         total={this.state.total} 
         onChange={this.pageChange} 
         pageSize={this.state.pageSize}
         showTotal={showTotal}
         style={{textAlign:'right',marginTop:20}}
          />
           <Modal
                title={this.state.modaltitle }
                visible={this.state.scdvisible} 
                width={600}
                onCancel={this.handleCancel}
                footer={[
                  <Button key="back" onClick={this.handleCancel}>
                    cancel
                  </Button>,
                  <Button key="submit" type="primary" loading={this.state.scdafter} onClick={this.handleOk}>
                    ok
                  </Button>,
                ]}
                >
                 {/* <input value={this.state.addpid} style={{display:'none'}}/> */}  
               
                <div className='banner_out_div'>
                    <div className='banner_left_div'>
                        <p className='banner_left_p'>优惠券名称:</p>
                        <p className='banner_left_p'>优惠类型:</p>
                        {this.state.showhide&&
                        <div>
                            <p className='banner_left_p'>立减券:</p>
                        </div>
                        }
                        {this.state.hideshow&&
                        <div>
                             <p className='banner_left_p'>折扣券:</p>
                        </div>
                        }
                      
                        <p className='banner_left_p' style={{visibility:'hidden'}}>优惠券期限</p>
                        <p className='banner_left_p'>优惠券期限:</p>
                    </div> 
                    <div className='coupon_right_div'>
                    <input type='text' className='product_select' value={this.state.fstModalInput} onChange={this.fstInputOnchange}/>
                    <select  value = {this.state.fstAddVal}  className='product_select' onChange={this.fstOnchange}>
                      <option value="">请选择</option>
                      <option value="1">立减券</option>
                      <option value="2">折扣券(总还款金额)</option>
                      <option value="3">折扣券(总息费)</option>
                      <option value="4">折扣券(利息)</option>
                      <option value="5">折扣券(服务费)</option>
                    </select>
                    {this.state.showhide&&
                     <div className='coupon_div'>
                        <span className='coupon_span'>面额</span>
                        <input type='text' style={{textAlign:'center',width:'50%'}} className='product_select' value={this.state.scdModalInput} onChange={this.scdInputOnchange}/>
                        <span className='coupon_span_two'>奈拉</span>
                    </div>
                    }
                      {this.state.hideshow&&
                      <div>
                        <div className='coupon_div'>
                            <span className='coupon_span'>折扣</span>
                            <input type='text' style={{textAlign:'center',width:'50%'}} className='product_select' value={this.state.thdModalInput} onChange={this.thdInputOnchange}/>
                            <span className='coupon_span_two'>%</span>
                        </div>
                        <div className='coupon_div'>
                            <span className='coupon_span'>最高优惠</span>
                            <input type='text' style={{textAlign:'center',width:'50%'}} className='product_select' value={this.state.fouModalInput} onChange={this.fouInputOnchange}/>
                            <span className='coupon_span_two'>奈拉</span>
                        </div>
                    </div>
                    }
                    <div className='coupon_out_div'>
                    <div className='coupon_modal_left_div'>
                        <Radio.Group style={{display:'flex',flexDirection:'column',width:'30%',lineHeight:50}} onChange={this.radioOnChange} value={this.state.radvalue}>
                            <Radio value={1}>固定有效期限</Radio>
                            <Radio value={-1}>相对可用时间</Radio>
                        </Radio.Group> 
                    </div> 
                    <div className='coupon_modal_right_div'>
                        <div className='coupon_div'>
                            <DatePicker style={{marginRight:'2%',width:125}} disabled={this.state.topdis} value={this.state.datepiker===undefined||this.state.datepiker===""?null:moment(this.state.datepiker, DateFormat) }onChange={this.dateonChange} /> 
                            <span>到</span>
                            <DatePicker style={{marginLeft:'2%',width:125}} disabled={this.state.topdis} value={this.state.datepiker2===undefined||this.state.datepiker2===""?null:moment(this.state.datepiker2, DateFormat) }onChange={this.dateonChange2} /> 
                        </div>
                    <div className='coupon_div'>
                        <span className='coupon_span_sp'>领取后</span>
                        <input type='text' style={{textAlign:'center',width:'20%'}} disabled={this.state.botdis} className='product_select' value={this.state.fivModalInput} onChange={this.fivInputOnchange}/>
                        <span className='coupon_span_sp'style={{marginLeft:10}}>天有效</span>
                    </div>
                   </div>
               
                   </div>
                    </div>
                </div>

                </Modal>
        </div>
      );
    }
  }
  export default withRouter(Coupon)