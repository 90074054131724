import React from 'react';
import { Input,Button,Table } from 'antd';
import '../settle_order/index.css';
import {Link} from 'react-router-dom'
import RangePick from '../../../utils/RangePicker';
  const { Search } = Input;

//例子
  // const data = [];
  // for (let i = 0; i < 46; i++) {
  //   data.push({
  //     key: i,
  //     name: `Edward King ${i}`,
  //     age: 32,
  //     address: `London, Park Lane no. ${i}`,
  //   });
  // }


  const data = [
    {
      key: '1',
      name: 'John Brown',
      age: 32,
      address: 'New York No. 1 Lake Park',
    },
    {
      key: '2',
      name: 'Jim Green',
      age: 42,
      address: 'London No. 1 Lake Park',
    },
    {
      key: '3',
      name: 'Joe Black',
      age: 32,
      address: 'Sidney No. 1 Lake Park',
    },
    {
      key: '4',
      name: 'Jim Red',
      age: 32,
      address: 'London No. 2 Lake Park',
    },
  ];
  export default class settleOrder extends React.Component {
    constructor(props){
      super(props);
      this.state={
        fstSelect:'1',
        // scdSelect:'1',
        thdSelect:'1',
        // fouSelect:'1',
        searchVal:'',
      }
    }
    //选择table触发
    handleChange = (pagination, filters, sorter) => {
      console.log('Various parameters', pagination, filters, sorter);
      this.setState({
      });
    };
    clearFilters = () => {
      this.setState({ filteredInfo: null });
    };
  
    //搜索按钮
    searchClick(){

    }
    //重置按钮
    reloadClick(e){
      this.setState({
        fstSelect:1,
        // scdSelect:1,
        thdSelect:1,
        // fouSelect:1,
        searchVal:''
      });
    }
    //输入框
    searchIpt(e){
      var value = e.target.value;
      this.setState({ searchVal: value })
    }
    //选择订单状态
    selectFst(e){
      var value = e.target.value;
      this.setState({
        fstSelect:value,
      });
    }
    //选择审核时效
    selectThd(e){
      var value = e.target.value;
      this.setState({
        thdSelect:value,
      });
    }
    //下载按钮
    downloadClick(e){

    }

    render() {

      const columns = [
        {
          title: '订单号',
          dataIndex: 'number',
          key: 'number',
          width:40,
        },
        {
          title: '借款人姓名',
          dataIndex: 'name',
          key: 'name',
          width:50,
        },
        {
          title: '借款人电话 bvn 号',
          dataIndex: 'phone',
          key: 'phone',
          width:50,
        },
        {
          title: '借款金额',
          dataIndex: 'amount',
          key: 'amount',
          width:40,
        },
        {
          title: '应还款日期',
          dataIndex: 'days',
          key: 'days',
          width:40,
        },
        {
          title: '应还金额',
          dataIndex: 'time',
          key: 'time',
          width:40,
        },
        {
          title: '实际还款时间',
          dataIndex: 'times',
          key: 'times',
          width:45,
        },
        {
          title: '实际还款金额',
          dataIndex: 'state',
          key: 'state',
          width:45,
        },
        {
          title: '剩余应还款金额',
          dataIndex: 'risk',
          key: 'risk',
          width:50,
        },
        {
          title: '逾期金额',
          dataIndex: 'people',
          key: 'people',
          width:35,
        },
        {
          title: '逾期天数',
          dataIndex: 'when',
          key: 'when',
          width:35,
        },
        {
          title: '催收人',
          dataIndex: 'detail',
          key: 'detail',
          width:30,
        },
        {
          title: '最后催收人',
          dataIndex: 'reson',
          key: 'reson',
          width:40,
        },
        {
          title: '最后一次催收时间',
          dataIndex: 'sure',
          key: 'sure',
          width:55,
        },
        {
            title: '催收详情',
            dataIndex: 'reson',
            key: '1',
            width:40,
          },
          {
            title: '催收结果',
            dataIndex: 'sure',
            key: '2',
            width:40,
          },
        {
          title: '操作',
          dataIndex: 'operating',
          key: 'operating',
          width:40,
          render: () => (<span> 
            <Link to="../credit_auditime/index">详情</Link>
            </span>)
        },
      ];
      return (
        <div className="credit_order">
          <div className="settle_content">
          <div className="search">
            <Search
              placeholder="input search text"
              onSearch={value => console.log(value)}
              style={{ width: 200,marginTop:20 }}
              value={this.state.searchVal}
              onChange={this.searchIpt.bind(this)}
            />
          </div>
        <div className="rangepick">
            <RangePick
            
            />
        </div>
          <select className="form_select" value={this.state.fstSelect} onChange={this.selectFst.bind(this)}>
            <option value="1">逾期等级</option>
            <option value="2">S1</option>
            <option value="3">S2</option>
            <option value="4">M1</option>
            <option value="5">M2</option>
            <option value="6">M3</option>
            <option value="7">M4</option>
          </select>
          <select className="form_select" value={this.state.thdSelect} onChange={this.selectThd.bind(this)}>
            <option value="1">催收人员</option>
            <option value="3">account03</option>
            <option value="4">liuji</option>
            <option value="5">account02</option>
            <option value="6">account01</option>
            <option value="7">alen</option>
            <option value="8">jude</option>
          </select>
          <div className="btn_search">
          <Button type="primary" onClick={this.searchClick.bind(this)} shape="circle" icon="search"/>
          <Button type="primary" style={{marginLeft:15,marginRight:15}} onClick={this.reloadClick.bind(this)} shape="circle" icon="reload"/>
          <Button type="primary" style={{marginRight:15}} onClick={this.downloadClick.bind(this)} shape="circle" icon="download"/>
          </div>
          </div>
          <Table columns={columns} dataSource={data} onChange={this.handleChange} pagination={false} />
        </div>
      );
    }
  }