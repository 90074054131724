/*eslint-disable*/
import React from 'react';
import {Input, Button,Switch ,Tree , Table, Modal,Radio, Pagination, DatePicker, Tooltip, message} from 'antd';
import './index.css';
import axios from 'axios'
import moment from 'moment';
import {withRouter} from 'react-router-dom'
import EventEmitter from '../../utils/events'
var getSelect = []
var treeData = []
let test = []
function showTotal(total) {
    return `Total ${total} items`;
}

var getselect = []
const {Search} = Input;
const key = [];
 class HotIssues extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 1,
            data: [],
            tableData: [],
            searchVal: '',
            current: 1,
            total: 1,
            pageSize: 10,
            boolean: true,
            fstSelect:1,
            thdval:'',
            scdval:''
        }
    }
        //页码改变时触发
        pageChange=(page)=> { 
      
            this.setState({
              current: page
              },()=>{
                this.searchClick()
              })
            }





    /*添加modal点击ok*/ 
    addhandleOk=()=>{
       
        axios
        .post(`/api/problem/problemAdd`, {
            type:this.state.fstSelect,
            problem:this.state.thdval,
            answer:this.state.scdval,
        })
        .then(res => {
            if (res.data.code === 200) {        
                message.success(res.data.msg)
                this.setState({
                    scdval:'',
                    thdval:'',
                    scdvisible:false,
                    fstSelect:1
                })
                this.searchClick()
            } else if (res.data.code == 800) {
                this.props.history.push({pathname: "/"});
            } else {
                message.error(res.data.msg)
                this.setState({
                    scdval:'',
                    thdval:'',
                    scdvisible:false,
                    fstselect:1
                })
                this.searchClick()
            }
        })
    }

     /*删除modal点击ok*/ 
    deletehandleOk=()=>{
        axios
        .post(`/api/problem/problemDel`, {
            id:this.state.delval,
        })
        .then(res => {
            if (res.data.code === 200) {        
                message.success(res.data.msg)
                this.searchClick()
                this.setState({
                    thdvisible:false
                })
            } else if (res.data.code == 800) {
                this.props.history.push({pathname: "/"});
            } else {
                message.error(res.data.msg)
                this.searchClick()
                this.setState({
                    thdvisible:false
                })
            }
        })
    }
   
    Add=()=>{
        this.setState({
            scdvisible:true,
        })
    }


   
        modelselectThree=(e)=>{
            var value = e.target.value;
            this.setState({
              modalthdSelect:value
            })
           
          }

    handleCancel=()=>{
        this.setState({
            fstvisible:false,
            scdvisible:false,
            thdvisible:false,
            fouvisible:false,
        })
    }
    delete=(record)=>{
        this.setState({
            named : record.name,
            thdvisible:true,
            delval:record.id
        })
    }

//页面初始化加载下面的table
    secondTable = () => {
        this.searchClick()
    }
    //搜索
    searchClick = () => {
        this.setState({
            boolean: true
        })
        axios
            .post(`/api/problem/problemList`, {
                pageNum:this.state.current,
                pageSize:this.state.pageSize,
                searchParam:this.state.searchVal
            })
            .then(res => {
                if (res.data.code === 200) {

                    const data1 = [];
                    const data = res.data.data.resultData

                    for (let i = 0; i < data.length; i++) {
                        data[i].key = i;
                        data1.push(data[i]);
                    }
                    this.setState({
                        tableScdData: data1,
                        boolean: false,
                        length:res.data.data.page.total
                    })
                } else if (res.data.code == 800) {
                    this.props.history.push({pathname: "/"});
                } else {
                    
                }
            })
    }
    handClick = (item) => {
        console.log('1111111122222233333', item)
    }




        //输入框
        searchIpt(e){
            var value = e.target.value;
            this.setState({ searchVal: value.replace(/\s+/g,"") })
          }
    scdonChange=(e)=>{
        let value=e.target.value;
        this.setState({
            scdval:value
        })
    }
    thdonChange=(e)=>{
        let value=e.target.value;
        this.setState({
            thdval:value
        })
    }
    fstselect=(e)=>{
        var value = e.target.value;
            this.setState({
                fstSelect:value
            })
      }
    componentDidMount() {
        this.secondTable()
    }

    render() {
       
      const { selectedRowKeys } = this.state;
      const rowSelection = {
        selectedRowKeys,
        onChange: this.onSelectChange,
         columnWidth:10
      };
        const columns = [

            {
                title: 'type',
                dataIndex: 'type',
                key: 'type',
                render: (value) => {
                    if(value==1){
                        return <span>热门问题</span>
                    } else if(value==2){
                        return <span>贷款</span>
                    }  else if(value==3){
                        return <span>卡设置</span>
                    } else if(value==4){
                        return <span>偿还借款</span>
                    } else if(value==5){
                        return <span>安全和隐私</span>
                    } else if(value==6){
                        return <span>推荐和优惠</span>
                    } else if(value==7){
                        return <span>Google Play Store</span>
                    } 
                }
            },
            {
                title: 'question',
                dataIndex: 'problem',
                key: 'problem',
                width:400,
                render: (value) => {
                    return value || '——'
                }
                

            },
            {
                title: 'question answer',
                dataIndex: 'answer',
                key: 'answer',
                width:300,
                render: (record) => {
                    return (
                      <Tooltip title={record}>
                        <div className="twoEllipsis">
                          {record}
                        </div>
                      </Tooltip>
                    )
                }
            },
            {
                title: 'status',
                dataIndex: 'status',
                key: 'status',
                id:'id',
                render: (value) => {
                    if(value==1){
                        return <span>normal</span>
                    } else{
                        return <span style={{color:'red'}}>unknown</span>
                    } 
                }
            },
            {
                title: 'creation time',
                dataIndex: 'createdTime',
                key: 'createdTime',
                render: (value) => {
                    let timeaudit= new Date(value).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
                    let auditimes=moment(new Date(timeaudit)).format("YYYY-MM-DD HH:mm:ss");
                    return auditimes
                }
            },
           
           
            { title: 'Operation', dataIndex: '', key: 'operation', render: (text, record) => 
            <span style={{ display: 'flex' , flexDirection: 'row', justifyContent:'center',margin:0}}>
                 <a className='edit_button' href="javascript:;" onClick={() => this.delete(record)}>Delete</a>
              </span>
           
            },
        ];
        return (
            <div className="menu_content">
             <div style={{width:'98%',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                <div>
                    <Search
                        placeholder="question/answer"
                        onSearch={value => console.log(value)}
                        style={{width: 290,marginRight:10,marginLeft:10}}
                        value={this.state.searchVal}
                        onChange={this.searchIpt.bind(this)}
                    />
                    <Button type="primary" style={{width:170,marginRight:20,height:40,border:'none'}}  className="common_color" onClick={this.searchClick}>Search</Button>
                </div>
                
                    <Button className='tab2_button' type="primary" style={{ marginTop: 15 }} onClick={this.Add}>Add</Button></div>
                <div className="table">
                    <Table columns={columns} className='old_table'  dataSource={this.state.tableScdData} loading={this.state.boolean}  pagination={false}/>
                </div>
                <Pagination
                defaultCurrent={1} 
                current={this.state.current}
                total={this.state.length} 
                onChange={this.pageChange}  
                pageSize={this.state.pageSize}
                showTotal={showTotal}
                style={{textAlign:'right',marginTop:20}}
                />


                <Modal
                title="add new problem" 
                visible={this.state.scdvisible} 
                onOk={this.addhandleOk}
                onCancel={this.handleCancel}
                >
                <div >
                    <span className='product_span'>type:</span>
                    <select style={{marginTop:5}} className='product_select' value={this.state.fstSelect} onChange={this.fstselect}>
                        <option value='1'>Hot</option>
                        <option value='2'>Loan</option>
                        <option value='3'>CARD SETUP</option>
                        <option value='4'>LOAN REPAYMENT</option>
                        <option value='5'>SECURITY AND PRIVACY</option>
                        <option value='6'>REFERRALS AND LOAN DISCOUNTS</option>
                        <option value='7'>Google Play Store</option>
                    </select>
                </div>
                <div style={{marginTop:10}}>
                        <span className='product_span'>Question:</span>
                        <Input style={{marginTop:5}} value = {this.state.thdval} allowClear  onChange={this.thdonChange} />
                    </div>
                <div style={{marginTop:10}}>
                        <span className='product_span'>answer:</span>
                        <Input style={{marginTop:5}} value = {this.state.scdval} allowClear  onChange={this.scdonChange} />
                    </div>
                <br />

                
                </Modal>

                <Modal
                visible={this.state.thdvisible} 
                onOk={this.deletehandleOk}
                onCancel={this.handleCancel}
                >
                <input value={this.state.delval} style={{display:'none'}}/>
               <p>Make sure you want to delete {this.state.named}?</p>
                
                </Modal>
            </div>

        )
    }
}
export default withRouter(HotIssues)