/*eslint-disable*/
import { Button, DatePicker, Input, Pagination, Table, Tooltip } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React from 'react';
import './index.css';
import {withRouter} from 'react-router-dom'
import EventEmitter from '../../utils/events'

const { RangePicker } = DatePicker;
  const { Search } = Input;
  const dateFormat = 'YYYY-MM-DD'||undefined;
var whitchshow = []
var Select = []

   
  function showTotal(total) {
    return `Total ${total} items`;
  }
 class LoanFail extends React.Component {
    constructor(props){
      super(props);
      this.state={
        fstSelect:'',
        scdSelect:'',
        fouSelect:'',
        searchVal:'',
        current:1,
        fstinpt:'',
        order_statues:'please select one',
        modalfstSelect:0,
        inptdis: {
          display:"none"
      },
      fsttextarea:'',
      pageSize:10,
      boolean:true,
      reviewbutton: {display: "block"},
      detailsbutton: {display: "block"},
      reviewreason:'',
      oldOrNew:''
      }
    }
        //页码改变时触发
        pageChange=(page)=>{
          this.setState({
            current: page
            },()=>{
              this.searchClick()
            })
        }
        rangeOnChange=(dates, dateStrings)=>{
          this.setState({ from: dateStrings[0],to:dateStrings[1] })
        }
    //选择table触发
    handleChange = (pagination, filters, sorter) => {
      console.log('Various parameters', pagination, filters, sorter);
      this.setState({
      });
    };
    clearFilters = () => {
      this.setState({ filteredInfo: null });
    };
  


    getselect=()=>{
      axios
      .post(`/api/appshadow/findAppShadowList`,{
  
    })
    .then(res=>{
       Select=[]
      if(res.data.code === 200){
        for(let i=0;i<res.data.data.list.length;i++ ){
          
            if(i==0){
              Select.push(<option value=''>please select one</option> )
              }
              Select.push(       
              <option value={res.data.data.list[i].appCode}>{res.data.data.list[i].appCode}</option>
          )
          
        this.setState({
          Select:Select
        })
        }
      }else if(res.data.code ==800){
        this.props.history.push({ pathname: "/"});
      }else{
      }
    })
    }

    handleCancel=()=>{
      this.setState({
        fstvisible: false,
        scdvisible: false,
        modalfstSelect:0,
        fstSelect:'',
        order_statues:'please select one',
        inptdis: {display: "none"},
      });
    }
    //重置按钮
    reloadClick(e){
      this.setState({
        fstSelect:'',
        scdSelect:0,
        fouSelect:'',
        searchVal:'',
        from:undefined,
        to:undefined,
        oldOrNew:'',
        boolean:true
      },()=>{
        this.onload()

      });
    }
    showFstModal = (record) => {
      var id = record.id
      this.setState({
        fstinpt:id,
        fstvisible: true,
      });
      // this.gradeChange()
    };
    //modal第一个select
    gradeChange=(e)=>{
      var value = e.target.value;
      var code1 = '';
      this.setState({
        modalfstSelect:value
      })
      var objS = document.getElementById("modal_fstid");
      var grade = objS.options[objS.selectedIndex].value;
      if(grade==0){
        code1='please select one'
        this.setState({
          inptdis: {
            display:"none",
             
        }, order_statues:'please select one'}) 
       
      }else if(grade==1){
        code1='Manual Approved'
        this.setState({
          inptdis: {
            display:"none",
            
        }, order_statues:'Manual Approved'}) 
       
      }
      else{
        this.setState({
          inptdis: {
            display:"inline-block"
        }, }) }
      if(grade == 2){
        code1='NO_PASS_RETURN'
        this.setState({
          order_statues:'Return by Manual Review',
          reviewreason:'returnReason'

        })
      }else if(grade == 4){
        code1='NO_PASS_REJECT_IMMEDIATELY'
        this.setState({
          order_statues:'Reject by Manual Review',
          reviewreason:'rejectImmReason'

        })
      }
      else if(grade == 3){
        code1 = 'NO_PASS_REJECT'
        this.setState({
          order_statues:'Reject by Manual Review',
          reviewreason:'rejectReason'

        })
      }
      else if(grade == 5){
        code1 = 'NO_PASS_BLACK_LIST'
        this.setState({
          order_statues:'Reject by Manual Review',
          reviewreason:'blackListReason'

        })
      }
      axios
        .post(`/api/common/getCommonListByCode`,{

          code:code1
      })
      .then(res=>{
        whitchshow=[]
        if(res.data.code === 200){
          for(let i=0;i<res.data.data.length;i++ ){
            whitchshow.push(       
              <option value={res.data.data[i].number}>{res.data.data[i].name}</option>
          )
          this.setState({
            whitchshow:whitchshow
          })
          }
        }else if(res.data.code ==800){
          this.props.history.push({ pathname: "/"});
        }else{
        }
      })
      
    }
    oldOrderNew(e){
      var value = e.target.value;
      this.setState({
        oldOrNew:value,
      });
    }
    onload=()=>{
      axios
        .post(`/api/loanOrder/getLoanFailureList`,{

          pageSize: 10,
          current: this.state.current,
      })
      .then(res=>{
        if(res.data.code === 200){
          const data = [];
          if(res.data.data.resultDate.length<1){
            this.setState({
              boolean:false
            })
          }else{
            for (let i = 0; i < res.data.data.resultDate.length; i++) {
              let newtime = res.data.data.resultDate[i].audit_user_time;
              //new Date().getTimezoneOffset() 获取当前时间时间戳   （0 * 60 * 60 * 1000） 0 即为时区
              let timeaudit= new Date(newtime).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
              let auditimes=moment(new Date(timeaudit)).format("YYYY-MM-DD HH:mm:ss");
              if(res.data.data.resultDate[i].audit_user_time == null){
                auditimes = ''
              }else{
                auditimes = auditimes
              }
              let time=res.data.data.resultDate[i].created_time;
              //new Date().getTimezoneOffset() 获取当前时间时间戳   （0 * 60 * 60 * 1000） 0 即为时区
              let east0time = new Date(time).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
              let times=moment(new Date(east0time)).format("YYYY-MM-DD HH:mm:ss");
              let auditRiskStatus = res.data.data.resultDate[i].audit_risk_status;
              if(auditRiskStatus == 15){
                 auditRiskStatus = 'reject';
              }else if (auditRiskStatus == -1){
                  auditRiskStatus = 'reject';
              }else if (auditRiskStatus == -2){
                auditRiskStatus = 'reject';
              }else if (auditRiskStatus == 1){
                auditRiskStatus = 'pass';
              }else if (auditRiskStatus == 2){
                auditRiskStatus = 'pass';
              }else if (auditRiskStatus == 3){
                auditRiskStatus = 'warning';
              }else{
                auditRiskStatus = '';
            }
              data.push({
                  key: i,
                  type:res.data.data.resultDate[i].app_code,
                  number: res.data.data.resultDate[i].indent_no,
                  name:res.data.data.resultDate[i].first_name,
                  phone: res.data.data.resultDate[i].mobile,
                  bvn: res.data.data.resultDate[i].dentity_card,
                  amount: res.data.data.resultDate[i].balance/100,
                  days: res.data.data.resultDate[i].cycle,
                  time: times,
                  times:res.data.data.resultDate[i].repayment_count,
                  state:res.data.data.resultDate[i].statusDescription,
                  risk:auditRiskStatus,
                  people:res.data.data.resultDate[i].auditName,
                  auditimes:auditimes,
                  detail:res.data.data.resultDate[i].audit_detail,
                  auditRiskReson:res.data.data.resultDate[i].audit_risk_reson,
                  id:res.data.data.resultDate[i].id,
                  uid:res.data.data.resultDate[i].uid,
  
                });
                
              }
          }
          
            this.setState({
              tableData:data,
              length:res.data.data.page.total,
              boolean:false
            })
            // message.success(res.data.msg);
        }else if(res.data.code ==800){
          this.props.history.push({ pathname: "/"});
        }else{
          // message.error(res.data.msg);
        }
      })
    }
    componentDidMount(){
     this.onload()
     this.getselect()

    }
    text(record){
      window.location='/#/details/index?id='+record.id+'&uid='+record.uid
      let test = [{path: '/details/index',key: `/details/index/${record.id}/${record.uid}`,number: `${record.id}/${record.uid}`}]
      EventEmitter.trigger('btn', test[0])
      EventEmitter.trigger('idanduid', test[0].number)
    }
    //输入框
    searchIpt(e){
      var value = e.target.value;
      this.setState({ searchVal: value.replace(/\s+/g,"") })
    }
    //选择订单状态
    selectFst(e){
      var value = e.target.value;
      this.setState({
        fstSelect:value,
      });
    }

    //选择apptype
    selectFou(e){
      var value = e.target.value;
      this.setState({
        fouSelect:value,
      });
    }


          searchClick=()=>{
            this.setState({
              boolean:true
            })
            axios
            .post(`/api/loanOrder/getLoanFailureList`,{

            pageSize: this.state.pageSize,
            current: this.state.current,
            searchParam:this.state.searchVal,
            status:this.state.fstSelect,
            appCode:this.state.fouSelect,
            beginTime:this.state.from,
            endTime:this.state.to,
            newOrOld:this.state.oldOrNew

          })
          .then(res=>{
            if(res.data.code === 200){
              const data = [];
              if(res.data.data.resultDate.length > 0){
                for (let i = 0; i < res.data.data.resultDate.length; i++) {
                  let newtime = res.data.data.resultDate[i].audit_user_time;
                  //new Date().getTimezoneOffset() 获取当前时间时间戳   （0 * 60 * 60 * 1000） 0 即为时区
                  let timeaudit= new Date(newtime).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
                  let auditimes=moment(new Date(timeaudit)).format("YYYY-MM-DD HH:mm:ss");
                  if(res.data.data.resultDate[i].audit_user_time == null){
                    auditimes = ''
                  }else{
                    auditimes = auditimes
                  }
                  let time=res.data.data.resultDate[i].created_time;
                  //new Date().getTimezoneOffset() 获取当前时间时间戳   （0 * 60 * 60 * 1000） 0 即为时区
                  let east0time = new Date(time).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
                  let times=moment(new Date(east0time)).format("YYYY-MM-DD HH:mm:ss");
                  let auditRiskStatus = res.data.data.resultDate[i].audit_risk_status;
                  if(auditRiskStatus == 15){
                     auditRiskStatus = 'reject';
                  }else if (auditRiskStatus == -1){
                      auditRiskStatus = 'reject';
                  }else if (auditRiskStatus == -2){
                    auditRiskStatus = 'reject';
                  }else if (auditRiskStatus == 1){
                    auditRiskStatus = 'pass';
                  }else if (auditRiskStatus == 2){
                    auditRiskStatus = 'pass';
                  }else if (auditRiskStatus == 3){
                    auditRiskStatus = 'warning';
                  }else{
                    auditRiskStatus = '';
                }
                  data.push({
                      key: i,
                      type:res.data.data.resultDate[i].app_code,
                      number: res.data.data.resultDate[i].indent_no,
                      name:res.data.data.resultDate[i].first_name,
                      phone: res.data.data.resultDate[i].mobile,
                      bvn: res.data.data.resultDate[i].dentity_card,
                      amount: res.data.data.resultDate[i].balance/100,
                      days: res.data.data.resultDate[i].cycle,
                      time: times,
                      times:res.data.data.resultDate[i].repayment_count,
                      state:res.data.data.resultDate[i].statusDescription,
                      risk:auditRiskStatus,
                      people:res.data.data.resultDate[i].auditName,
                      auditimes:auditimes,
                      detail:res.data.data.resultDate[i].audit_detail,
                      auditRiskReson:res.data.data.resultDate[i].audit_risk_reson,
                      id:res.data.data.resultDate[i].id,
                      uid:res.data.data.resultDate[i].uid,
      
                    });
              }
          
                 
                } else{
                  this.setState({
                    boolean:false
                  })
                }
                this.setState({
                  tableData:data,
                  length:res.data.data.page.total,
                  boolean:false
                })
                // message.success(res.data.msg);
            }else if(res.data.code ==800){
              this.props.history.push({ pathname: "/"});
            }else{
              // message.error(res.data.msg);
            }
          })
          }
    render() {

      const columns = [
        {
          title: 'app type',
          dataIndex: 'type',
          key: 'type',
    
        },
        {
          title: 'Order No',
          dataIndex: 'number',
          key: 'number',
          width:50
        },
        {
          title: 'Customer name',
          dataIndex: 'name',
          key: 'name',
  
        },
        {
          title: 'mobile phone',
          dataIndex: 'phone',
          key: 'phone',
     
        },
        {
          title: 'BVN',
          dataIndex: 'bvn',
          key: 'bvn',
     
        },
        {
          title: 'loan amount',
          dataIndex: 'amount',
          key: 'amount',
  
        },
        {
          title: 'loan term',
          dataIndex: 'days',
          key: 'days',

        },
        {
          title: 'order created time',
          dataIndex: 'time',
          key: 'time',

        },
        {
          title: 'Success Repayment Count',
          dataIndex: 'times',
          key: 'times',

        },
        {
          title: 'Loan status',
          dataIndex: 'state',
          key: 'state',
 
        },
        {
          title: 'Risk Rules Result',
          dataIndex: 'risk',
          key: 'risk',
 
        },
        {
          title: 'Reviewer',
          dataIndex: 'people',
          key: 'people',

        },
        {
          title: 'Review time',
          dataIndex: 'auditimes',
          key: 'auditimes',

        },
        {
          title: 'Review details',
          dataIndex: 'detail',
          key: 'detail',
          render: (record) => {
            return (
              <Tooltip title={record}>
                <div className="twoEllipsis">
                  {record}
                </div>
              </Tooltip>
            )
        }
        },
        {
          title: 'Disapproval reason',
          dataIndex: 'auditRiskReson',
          key: 'auditRiskReson',
          render: (record) => {
            return (
              <Tooltip title={record}>
                <div className="twoEllipsis">
                  {record}
                </div>
              </Tooltip>
            )
        }

        },

        {
          title: 'Operation',
          dataIndex: 'operating',
          key: 'operating',
          width:70,
          id:'id',
          uid:'uid',
          // fixed:'right',
          render: (text, record, index) => (<span style={{ display: 'flex' , flexDirection: 'row'}}> 
          <Button type="primary"   style={this.state.detailsbutton} className="detailsbutton common_color" size="small"  onClick={()=>this.text(record)}>
               details
            </Button>
            </span>)
        },
      ];
      return (
        <div className="credit_order">
          <div className="myorder_content">



  <div className="flutter">
            <div className="top">
              <div className="top_left" style={{width:'30%',flexShrink:1}}>
                <div className="search">
                  <span className="description left">Order search:</span>
                  <Search
                    placeholder="BVN/Phone/OrderNo/Name"
                    onSearch={value => console.log(value)}
                    style={{width: 290}}
                    value={this.state.searchVal}
                    onChange={this.searchIpt.bind(this)}
                  />
                 </div>
              </div>

              <div className="top_mid" style={{width:'30%',flexShrink:2,marginLeft:'5%',marginRight:'5%'}}>
                <div className="rangepick">
                    <span className="description mid_span">Created time:</span>
                    <RangePicker
                       style={{width: 290,}}
                      ranges={{
                      Today: [moment(), moment()],
                      'This Month': [moment().startOf('month'), moment().endOf('month')],
                      }}
                      showTime
                      format={dateFormat}
                      onChange={this.rangeOnChange}
                      value={this.state.from===undefined||this.state.to===undefined||this.state.from===""||this.state.to===""?null:[moment(this.state.from, dateFormat), moment(this.state.to, dateFormat)]}
                    />
                </div>
              </div>
              <div className="top_right" style={{width:'30%',flexShrink:1}}>
                <span className="description right">App type:</span>
                <select className="form_select"  value={this.state.fouSelect} onChange={this.selectFou.bind(this)}>
                 {Select}

                </select>
              </div>
            </div>
      
            <div className="order_mid" style={{justifyContent:'flex-start'}}>
              <div className="mid_left" style={{width:'30%',marginRight:'5%'}}>
                <span className="description left">Loan status:</span>
                <select className="form_select" value={this.state.fstSelect} onChange={this.selectFst.bind(this)}>
                  <option value="">all</option>
                  <option value="41">Fail Payment</option>
                  <option value="42">Reject Payment</option>
                  <option value="32">Reject by Manual Review</option>
                  <option value="31">Reject by Risk Rules</option>
                </select>
              </div>
              <div className="mid_right" style={{width:'30%'}}>
                    <span className="description right" style={{width:132}}>oldOrNew:</span>
                    <select className="form_select" value={this.state.oldOrNew} onChange={this.oldOrderNew.bind(this)}>
                      <option value="">all</option>
                      <option value="1">new User</option>
                      <option value="2">old User</option>
                    </select>
                </div>
            </div>    

            <div className="bottom">
              <Button type="primary" style={{width:170,marginRight:20,height:40,border:'none'}}  className="common_color" onClick={this.searchClick.bind(this)}>Search</Button>
              <Button type="primary" style={{width:170,height:40,border:'none'}} className="common_color_scd" onClick={this.reloadClick.bind(this)}>Refresh</Button>
            </div>      
          </div>
          </div>
          <Table columns={columns} dataSource={this.state.tableData} loading={this.state.boolean} onChange={this.handleChange} pagination={false} />
            
        
        <Pagination
          // showQuickJumper
          defaultCurrent={1} 
          current={this.state.current}
          total={this.state.length} 
          onChange={this.pageChange} 
          showTotal={showTotal}
          style={{textAlign:'right',marginTop:20}}
          pageSize={this.state.pageSize}
          showSizeChanger={true}
          />
        </div>
      );
    }
  }
  export default withRouter(LoanFail)