/*eslint-disable*/
import React from 'react';
import { Input,Button,Table,Modal,Radio,Pagination,message,Tabs,DatePicker,Tooltip, Progress } from 'antd';
import './index.css';
import axios from 'axios'
import {Link} from 'react-router-dom'
import moment from 'moment';
import location_red from '../../../../public/img/collection_imgleft.png';
import location_question from '../../../../public/img/rule.png';
import right from '../../../../public/img/collection_imgright.png';
import {withRouter} from 'react-router-dom'
import EventEmitter from '../../../utils/events'

const dateFormat = 'YYYY-MM-DD'||undefined;
const { RangePicker } = DatePicker;
var Select = [];
const { TabPane } = Tabs;
const DateFormat = 'YYYY-MM-DD'||undefined;

var whitchshow = []
function callback(key) {
    console.log(key);
  }

  function showTotal(total) {
      return `Total ${total} items`;
    }
 class Data extends React.Component {
    constructor(props){
      super(props);
      this.state={
        current:1,
        boolean:true,
        from:'',
        to:'',
        useStart:'',
        useEnd:'',
        pageSize:10,
        id:'',
        fstSelect:'',
        modify:false,
        fstpercent:0,
        scdpercent:0,
        thdpercent:0,
        radvalue3:''
      }
     
    }

    rangeOnChange=(dates, dateStrings)=>{
      this.setState({ from: dateStrings[0],to:dateStrings[1] })
    }
    rangeOnChange1=(dates, dateStrings)=>{
      this.setState({ useStart: dateStrings[0],useEnd:dateStrings[1] })
    }
    //页码改变时触发
    pageChange=(page)=> { 
    this.setState({
      current: page
      },()=>{
        this.searchClick()
      })
    } 

  componentDidMount(){
     
      var url = window.location.hash
      var params = url.substr(1);
      var aPos = params.indexOf("=")
      var bPos = params.indexOf('&');
      var urlid = params.substr(aPos + 1, bPos - aPos - 1);
       this.setState({
        urlid:urlid,
      })
      axios
      .post(`/api/userCoupon/statistics`, {
        activityId:urlid,
      })
      .then(res => {
          if (res.data.code === 200) {

              const data1 = [];
              const data = res.data.data
            let start = data.enabledDate;
            let end = data.disabledDate
            let startTime=moment(new Date(start)).format("YYYY-MM-DD");
            let endTime=moment(new Date(end)).format("YYYY-MM-DD");
            console.log(startTime)
            this.setState({
              indentNo:data.indentNo,
              activityName:data.activityName,
              activityTime:startTime+'-'+endTime,
              couponIndentNo:data.couponIndentNo,
              couponName:data.couponName,
              appName:data.appName,
              fstpercent:data.getRate|0,  
              scdpercent:data.usedRate|0,
              thdpercent:data.newUserRate|0,
              useAmount:data.usedAmount/100,
              total_rate:data.totalCount,
              getNum:data.getCount,
              newNum:data.newUserCount,
              useNum:data.usedCount
             })
          } else if (res.data.code == 800) {
              this.props.history.push({pathname: "/"});
          } else {
          }
      })
      this.searchClick()
  }

  reload=()=>{
    this.setState({
      fstInput:'',
      scdInput:'',
      fstSelect:'',
      thdSelect:''
    },()=>{
      this.searchClick()
    })
  }
  edit=(record)=>{
    this.setState({
      fstvisible:true,
      modifyId:record.id
    })
  }
   //搜索
   searchClick = () => {
    var url = window.location.hash
    var params = url.substr(1);
    var aPos = params.indexOf("=")
    var bPos = params.indexOf('&');
    var urlid = params.substr(aPos + 1, bPos - aPos - 1);
     this.setState({
      urlid:urlid,
    })
    axios
        .post(`/api/userCoupon/getUserCouponList`, {

            status: this.state.radvalue3,
            phone: this.state.fstInput,
            pageSize: this.state.pageSize,
            current: this.state.current,
            getDateStart: this.state.from,
            getDateEnd:this.state.to,
            usedDateStart:this.state.useStart,
            usedDateEnd:this.state.useEnd,
            id:this.state.scdInput,
            activityId:urlid,
            
        })
        .then(res => {
            if (res.data.code === 200) {

                const data1 = [];
                const data = res.data.data.resultDate

                for (let i = 0; i < data.length; i++) {
                    data[i].key = i;
                    data1.push(data[i]);
                }
                this.setState({
                  tableData: data1,
                  total: res.data.data.page.total,
                  boolean: false
                })
            } else if (res.data.code == 800) {
                this.props.history.push({pathname: "/"});
            } else {
            }
        })
}
radioOnChange3 = e => {
    console.log('radio checked', e.target.value);
    this.setState({
        radvalue3: e.target.value,
    });
  };



    handleCancel = e => {
      console.log(e);
      this.setState({
        fstvisible: false,
        modify:false,
        scdvisible:false,
      });
    };

    modifyhandleOk=()=>{
      this.setState({
        modify:true
      })
      axios
      .post(`/api/userCoupon/disable`,{
        id:this.state.modifyId,
    })
    .then(res=>{
      if(res.data.code === 200){
        this.setState({
          fstvisible: false,
          modify:false
        })
        this.searchClick()
      }
      else if(res.data.code ==800){
        this.props.history.push({ pathname: "/"});
      }else{
        this.setState({
          fstvisible: false,
          modify:false
        })
      }
    })
    this.setState({
      fstvisible: false,
      fstSelect:'',
      fouSelect:'',
      from:undefined,
      to:undefined,
      id:''
    });
    }

    fstOnchangeInput=(e)=>{
      var value = e.target.value;
      this.setState({
        fstInput:value,
      });
    }
    scdOnchangeInput=(e)=>{
      var value = e.target.value;
      this.setState({
        scdInput:value,
      });
    }
    openDescribe=()=>{
      console.log(1234)
      this.setState({
          scdvisible:true
      })
  }
    render() {

      const columns = [
        {
          title: '用户手机号',
          dataIndex: 'phone',
          key: 'phone',

        },
        {
          title: '优惠券编号',
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: '领取时间',
          dataIndex: 'createdTime',
          key: 'createdTime',
        },

      
          {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            render: (value) => {
              if(value==0){
                return '未领取'
              }else if(value==1){
                return '未使用'
              }else if(value==2){
                return '已使用'
              }else if(value==3){
                return '已过期'
              }else if(value==4){
                return '已失效'
              }else if(value==5){
                return '已绑定'
              }
          }
          },
       
        {
          title: '操作',
          dataIndex: 'operating',
          key: 'operating',
          id:'id',
          render: (text, record, index) => (<span style={{ display: 'flex' , flexDirection: 'row', justifyContent:'center',}}> 
            <a onClick={() => this.edit(record)} disabled={record.status==1?false:true}  style={{backgroundColor:record.status==1?'#009688':'#999999'}}className='activity_edit'>禁用</a>
            </span>)
        },
      ];
      return (
        <div className="data" style={{overflow:'hidden'}}>
           <div className="content_mid">
                <div className="mid_bot" style={{marginBottom:80}}>
                    <div className="data_top_div">
                        <div className="mid_left" style={{width:'30%'}}>
                            <span style={{width: 70,}} className="description left">活动ID:</span>
                            <p className="data_content_p">{this.state.indentNo}</p>
                        </div>
                        <div className="mid_mid" style={{width:'30%',flexShrink:2,marginLeft:'2%',marginRight:'2%'}}>
                            <span style={{width: 85,}}className="description mid_span">活动名称:</span>
                            <p className="data_content_p">{this.state.activityName}</p>
                        </div>
                        <div className="mid_right" style={{width:'36%'}}>
                            <span  style={{width:85,}} className="description right">活动有效期:</span>
                            <p className="data_content_p">{this.state.activityTime}</p>              
                        </div>
                    </div>
                    <div className="data_top_div">
                        <div className="mid_right" style={{width:'30%'}}>
                            <span  style={{width:70,}} className="description right">优惠券ID:</span>
                            <p className="data_content_p">{this.state.couponIndentNo}</p>              
                        </div>
                        <div className="mid_mid" style={{width:'30%',flexShrink:2,marginLeft:'2%',marginRight:'2%'}}>
                            <span style={{width: 85,}} className="description mid_span">优惠券名称:</span>
                            <p className="data_content_p">{this.state.couponName}</p>
                        </div>
                        <div className="mid_right" style={{width:'36%'}}>
                            <span  style={{width: 85,}} className="description right">关联产品:</span>
                            <p className="data_content_p">{this.state.appName}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{
                    width: 240,
                    marginLeft: '4%',
                    height: 40,
                    float: 'right',
                    marginTop: '-60px',
                    marginBottom: '20px',
                    overflow:'hidden',
                    display:'flex'
                }}>
                <div
                style={{width:170,display:'flex',alignItems:'center',justifyContent:'center'}}>
                     <Button type="link" block style={{color:'#169DB2'}} onClick={this.openDescribe}>
                    <img src={location_question} />
                    <span >字段解释</span>
                    </Button>
                </div>
                </div>
            <div className="data_mid">
                <div className="data_mid_left">
                    <div className="data_left_top">
                        <div className="fst_progress">
                            <Progress strokeLinecap="square"width="153px"strokeWidth="12"strokeColor="#009688"type="circle" percent={this.state.fstpercent}  format={percent => `${percent}% `}  />
                            <div className="progress_right_div">
                                <h2>领取率</h2>
                                <div className="progress_insidediv">
                                    <span className="progress_span"></span>
                                    <span>{this.state.total_rate}</span>
                                </div>
                                <div className="progress_insidediv">
                                    <span className="progress_span fst_color"></span>
                                    <span>{this.state.getNum}</span>
                                </div>
                            </div>
                        </div>
                        <div className="fst_progress">
                            <Progress strokeLinecap="square"width="153px"strokeWidth="12"strokeColor="#FFBF51"type="circle" percent={this.state.scdpercent}  format={percent => `${percent}% `}  />
                            <div className="progress_right_div">
                                <h2>使用率</h2>
                                <div className="progress_insidediv">
                                    <span className="progress_span"></span>
                                    <span>{this.state.total_rate}</span>
                                </div>
                                <div className="progress_insidediv">
                                    <span className="progress_span scd_color"></span>
                                    <span>{this.state.useNum}</span>
                                </div>
                            </div>
                        </div>
                        <div className="fst_progress">
                            <Progress strokeLinecap="square"width="153px"strokeWidth="12"strokeColor="#3AA1F0"type="circle" percent={this.state.thdpercent}  format={percent => `${percent}% `}  />
                            <div className="progress_right_div">
                                <h2>拉新率</h2>
                                <div className="progress_insidediv">
                                    <span className="progress_span"></span>
                                    <span>{this.state.total_rate}</span>
                                </div>
                                <div className="progress_insidediv">
                                    <span className="progress_span thd_color"></span>
                                    <span>{this.state.newNum}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="data_mid_right">
                    <h1>{this.state.useAmount}</h1>
                    <span>总优惠金额</span>
                </div>
            </div>
            
            <div className='activity_out_div'>
                <div style={{display:'flex',justifyContent:'space-between',paddingTop:30,marginBottom:30,paddingLeft:20,alignItems:'center'}}>
                    <div className="table_top">
                        <div className="mid_left" style={{}}>
                            <span style={{width: 85,}} className="description left">用户手机号:</span>
                            <input type="text" placeholder='用户手机号' value={this.state.fstInput} onChange={this.fstOnchangeInput} className="form_select"  style={{width: 200,}}/>
                        </div>
                        <div className="mid_left" style={{}}>
                            <span style={{width: 85,}} className="description left">优惠券编号:</span>
                            <input type="text" placeholder='优惠券编号' value={this.state.scdInput} onChange={this.scdOnchangeInput} className="form_select"  style={{width: 200,}}/>
                        </div>
                        <div className="mid_left" style={{}}>
                            <span style={{width: 85,}} className="description left">优惠券状态:</span>
                            <Radio.Group onChange={this.radioOnChange3}  value={this.state.radvalue3}>
                                <Radio value=''>全部</Radio>
                                <Radio value='1'>未使用</Radio>
                                <Radio value='2'>已使用</Radio>
                                <Radio value='3'>已过期</Radio>
                                <Radio value='4'>已失效</Radio>
                            </Radio.Group>             
                        </div>
                    </div>
                    <Button type="primary" style={{width:170,height:40,marginRight:20,backgroundColor:'#009688',border:'none'}} onClick={this.searchClick}>查询</Button>
                </div>
                <div className="table_top" style={{paddingLeft:20,marginBottom:30}}>
                        <div className="mid_left" style={{}}>
                            <span style={{width: 85,}} className="description left">领取时间:</span>
                            <RangePicker
                              style={{width: 290,}}
                              ranges={{
                              Today: [moment(), moment()],
                              'This Month': [moment().startOf('month'), moment().endOf('month')],
                              }}
                              showTime
                              format={dateFormat}
                              onChange={this.rangeOnChange}
                              value={this.state.from===undefined||this.state.to===undefined||this.state.from===""||this.state.to===""?null:[moment(this.state.from, dateFormat), moment(this.state.to, dateFormat)]}
                            />
                        </div>
                        <div className="mid_left" style={{marginLeft:"5%"}}>
                            <span style={{width: 85,}} className="description left">使用时间:</span>
                            <RangePicker
                              style={{width: 290,}}
                              ranges={{
                              Today: [moment(), moment()],
                              'This Month': [moment().startOf('month'), moment().endOf('month')],
                              }}
                              showTime
                              format={dateFormat}
                              onChange={this.rangeOnChange1}
                              value={this.state.from===undefined||this.state.to===undefined||this.state.useStart===""||this.state.useEnd===""?null:[moment(this.state.useStart, dateFormat), moment(this.state.useEnd, dateFormat)]}
                            />                        </div>
                    </div>
                <Table columns={columns}  dataSource={this.state.tableData}  loading={this.state.boolean} onChange={this.handleChange} pagination={false} />
            </div>
          


        <Pagination
         onShowSizeChange={this.onShowSizeChange} //pagesize改变时回调函数
         defaultCurrent={1} 
         current={this.state.current}
         total={this.state.total} 
         onChange={this.pageChange} 
         pageSize={this.state.pageSize}
         showTotal={showTotal}
         style={{textAlign:'right',marginTop:20}}
          />
            <Modal
          title="Modify"
          visible={this.state.fstvisible}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              取消
            </Button>,
             <Button key="submit" type="primary" loading={this.state.modify} onClick={this.modifyhandleOk}>
             确定
           </Button>,
          ]}
          >
          <input style={{display:'none'}} value={this.state.modifyId}/>
          <p>确定禁用吗？</p>
          </Modal>
        <Modal
                title={this.state.modaltitle }
                visible={this.state.scdvisible} 
                onOk={this.handleCancel}
                width={600}
                onCancel={this.handleCancel}
                >
                 {/* <input value={this.state.addpid} style={{display:'none'}}/> */}  
               
                 <div className='banner_out_div'>
                    <div className='inside_modal_left_div'>
                        <p className='banner_left_p' style={{lineHeight:'30px',color:'#333333',fontWeight:'bold'}}>领取率：</p>
                        <p className='banner_left_p' style={{lineHeight:'30px',color:'#333333',fontWeight:'bold',marginTop:'30px'}}>使用率：</p>
                        <p className='banner_left_p' style={{lineHeight:2,color:'#333333',fontWeight:'bold'}}>拉新率：</p>
                        <p className='banner_left_p' style={{lineHeight:'91px',color:'#333333',fontWeight:'bold'}}>总优惠金额：</p>
                    </div> 
                    <div className='inside_modal_right_div'>
                    <p className='banner_left_p' style={{lineHeight:'30px'}}>优惠券领取总量/优惠券发放总量,设置自动发放时，该字段为100%；</p>
                        <p className='banner_left_p' style={{lineHeight:'30px'}}>优惠券已使用总量/优惠券已领取总量；</p>
                        <p className='banner_left_p' style={{lineHeight:'30px'}}>活动期间注册且领取优惠券的新用户数/活动期间领取该优惠券的总用户数；</p>
                        <p className='banner_left_p' style={{lineHeight:'30px'}}>该活动所产生的优惠券总使用产生的优惠金额；</p>
                    </div>
                </div>
                <p className='modal_last_p'>注：以上均是以该活动为前提下的数据</p>
        </Modal>
        </div>
      );
    }
  }
  export default withRouter(Data)