import React, { Component } from 'react'
import { Table, Pagination, Modal,Button, Input, message,Radio } from 'antd';
import {validateNumber,hundredNumber} from '../../../utils/validate'
import {getTargetData,collectTargetForm} from '../../../api/systemApi'
import Schema from 'async-validator'
import moment from 'moment';
import axios from 'axios'
const { confirm } = Modal;
const rules={
    keyValue:[//阀值
        {required: true, message: 'please enter threshold '},
        {validator:validateNumber,message:'threshold should be positive integer'},
        {validator:hundredNumber,message: 'The value cannot be greater than 100'}
    ]
}
class Tab2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 1,
            total: 1,
            pageSize: 10,
            tableData: []
        }
    }
    showConfirm=(record)=> { //编辑按钮前确认
        var that = this;
     confirm({
       title: 'Are you sure to perform the operation?',
       // icon: <ExclamationCircleOutlined />,
       // content: 'Some descriptions',
       onOk() {
           that.firsthandleOk();
       },
       onCancel() {
         console.log('Cancel');
       },
     });
   }
    edit = (record) => { //编辑表单
        this.setState({
            firstVisible: true,
            status:record.status,
            threshold: record.threshold,
           remark:record.remark,
           keyName:record.keyName,
           keyValue:record.keyValue,
           overDueDays:record.overDueDays,
            id: record.id,
            modelTitle: '编辑'
        })
    }
    getTableData = () => { //获取表格数据
        this.setState({
            boolean: true
        })
        getTargetData().then(res => {
            if (res.data.code === 200) {
                this.setState({
                    tableData: res.data.data,
                    boolean: false
                })
            } else if (res.data.code == 800) {
                this.props.history.push({ pathname: "/" });
            } else {
                message.error(res.data.msg)
            }
        })
    }

        //表单值双向绑定
        fstaddonChange = (e) => {
            let InputName = e.target.name;
            let InputValue = e.target.value;
            this.setState({
                [InputName]: InputValue.replace(/\s+/g, ""),
            })
        }
        //单选框绑定
        radioChange=(e)=>{
            let InputName = e.target.name;
            let InputValue = e.target.value;
            this.setState({
                [InputName]: InputValue,
            })
        }
    handleCancel = () => { //关闭弹窗
        this.setState({
            firstVisible: false,
            threshold: '',
            theme: '',
            copywriting: '',
            keyName:'',
            overDueDays:'',
            keyValue:'',
            id:'',
            btnloading:false,
        })
    }
    firsthandleOk=()=>{//表单提交
        const validator = new Schema(rules);
        validator.validate({ keyValue:this.state.keyValue}, (errors, fields) => {
            if (!errors) {
                //新增编辑同一接口
                this.setState({
                    btnloading: true
                })
                collectTargetForm({
                    id:this.state.id,
                    keyValue:this.state.keyValue,
                }).then(res=>{
                    if (res.data.code == 200) {
                        this.setState({
                            btnloading: false,
                        })
                        message.success(res.data.msg);
                        this.getTableData();
                        this.handleCancel()
                    } else {
                        message.error(res.data.msg)
                        this.setState({
                            btnloading: false
                        })
                    }
                })
            } else {
             message.error(errors[0].message)
            }
        })
    }
    componentDidMount() { //生命周期
        this.getTableData();
    }
    
    render() {
        const columns = [
            {
                title: '序号',
                width: '10%',
                render:(text,record,index)=> `${index+1}`,
            },  
            {
                title: '催收等级',
                dataIndex: 'keyName',
                key: 'keyName',
            },      
            {
                title: '逾期天数',
                dataIndex: 'overDueDays',
                key: 'overDueDays',
            },
            {
                title: '目标阀值',
                dataIndex: 'keyValue',
                key: 'keyValue',
            },
            {
                title: '最近更新时间',
                dataIndex: 'updatedTime',
                key: 'updatedTime',
                render: (value) => {
                    return value?moment(value).format('YYYY-MM-DD HH:mm:ss'): '——'
                }
            },
            {
                title: '最近操作人',
                dataIndex: 'operator',
                key: 'operator',
            },
            {
                title: '操作', dataIndex: '', pid: 'pid', key: 'z',
                render: (text, record) =>
                    <div>
                        <span>
                            <a className='edit_button' href="javascript:;" onClick={() => this.edit(record)}>编辑</a>
                        </span>
                    </div>

            },
        ];

        return (<div>
            <Table columns={columns} dataSource={this.state.tableData} loading={this.state.boolean} pagination={false} rowKey={(text,record)=>text.id}/>
            <Modal
                // destroyOnClose={true}
                title={this.state.modelTitle}
                visible={this.state.firstVisible}
                onOk={this.showConfirm}
                onCancel={this.handleCancel}
                footer={[
                    <Button key="back" onClick={this.handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" loading={this.state.btnloading} onClick={this.showConfirm}>
                        Confirm
                    </Button>,
                ]}
            >
                <div className={'formItem'}>
                    <label>催收等级:</label>
                    <Input value={this.state.id} name="id" disabled placeholder='正整数'  style={{ marginTop: 10 }} onChange={this.fstaddonChange} value={this.state.keyName}/>
                </div>
                <div className={'formItem'}>
                    <label>逾期天数:</label>
                    <Input value={this.state.remark} disabled style={{ marginTop: 10 }} value={this.state.overDueDays}/>
                </div>
                <div className={'formItem'}>
                    <label>目标阀值:</label>
                    <Input suffix="%" style={{ display: 'flex', alignItems: 'center' }} name="keyValue" onChange={this.fstaddonChange} placeholder='正整数' value={this.state.keyValue} maxLength={3} style={{ marginTop: 10 }}/>
                </div>
            </Modal>
        </div>);
    }
}

export default Tab2;