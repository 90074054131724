/*eslint-disable*/
import { Button, DatePicker, Input, Pagination, Table ,Modal,message} from 'antd';
import axios from 'axios';
import moment from 'moment';
import React from 'react';
import './index.css';
import {withRouter} from 'react-router-dom'
import EventEmitter from '../../utils/events'

const { RangePicker } = DatePicker;
const { Search } = Input;
const dateFormat = 'YYYY-MM-DD'||undefined;
var whitchshow = []


function showTotal(total) {
    return `Total ${total} items`;
}
class extendOrder extends React.Component {
    constructor(props){
        super(props);
        this.state={
            fstSelect:'',
            sixSelect:1,
            thdSelect:'',
            current:1,
            fstinpt:'',
            modalfstSelect:0,
            fsttextarea:'',
            pageSize:10,
            boolean:true,
            reviewreason:'',
            oldOrNew:'1',
        }
    }
    ModalInput=(e)=>{
        let value = e.target.value;
        this.setState({
            modalinput:value.replace(/\s+/g,""),
        });
    }
    ModalScdInput=(e)=>{
        let value = e.target.value;
        this.setState({
            modalscdinput:value.replace(/\s+/g,""),
        });
    }
    ModalTarget=(e)=>{
        let value = e.target.value;
        this.setState({
            modaltarget:value.replace(/\s+/g,""),
        });
    }
    //页码改变时触发
    pageChange=(page)=>{
        this.setState({
            current: page
        },()=>{
            this.searchClick()
        })
    }
    rangeOnChange=(dates, dateStrings)=>{
        this.setState({ from: dateStrings[0],to:dateStrings[1] })
    }

    handleCancel=()=>{
        this.setState({
            reviewvisible: false,
            modalfstSelect:0,
            fstSelect:0,
            fstafter:false
        });
    }
    //重置按钮
    reloadClick(e){
        this.setState({
          sixSelect:1,
          fstInput:'',
          scdInput:'',
          name1:'',
          name2:'',
          thdSelect:'',
          oldOrNew:'1',
            from:undefined,
            to:undefined,
            boolean:true 
        },()=>{
            this.searchClick()
        });

    }
    fstInputChange=(e)=>{
        var value = e.target.value;
        this.setState({
            fstInput:value,
        });
    }
    scdInputChange=(e)=>{
        var value = e.target.value;
        this.setState({
            scdInput:value,
        });
    }
    Name1=(e)=>{
        var value = e.target.value;
        this.setState({
            name1:value,
        });
    }
    Name2=(e)=>{
        var value = e.target.value;
        this.setState({
            name2:value,
        });
    }
    oldOrderNew(e){
        var value = e.target.value;
        this.setState({
            oldOrNew:value,
        });
    }


    componentDidMount(){
        this.searchClick()
    }
    text(record){
        window.location='/#/details/index?id='+record.loanId+'&uid='+record.uid
        let test = [{path: '/details/index',key: `/details/index/${record.loanId}/${record.uid}`,number: `${record.loanId}/${record.uid}`}]
        EventEmitter.trigger('btn', test[0])
        EventEmitter.trigger('idanduid', test[0].number)
    }


    //选择还款类型
    selectThd(e){
        var value = e.target.value;
        this.setState({
            thdSelect:value,
        });
    }

    onShowSizeChange=(current, pageSize)=>{

        this.setState({
            pageSize: pageSize
        },()=>{
            this.searchClick()
        })
    }
    review=(record)=>{
        console.log(record)

        this.setState({
            reviewvisible:true,
            disval:record.id,
            modalinput:record.extensionFees/100,
            modalscdinput:record.actualExtensionFees/100,
            modaltarget:(record.extensionFees-record.actualExtensionFees)/100,
            modalscdSelect:record.initialrepaymentTime,
            modal_textarea:record.extensionTime
        })
    }
    //选择查询类型（其他/放款/还款）
    selectsix=(e)=>{
        var value = e.target.value;
        this.setState({
            sixSelect:value,
        });
    }
    searchClick=()=>{
        this.setState({
            boolean:true
        })
        axios
            .post(`/api/extension/getExtensionList`,{

                pageSize: this.state.pageSize,
                current: this.state.current,
                indentNo:this.state.fstInput,
                mobile:this.state.scdInput,
                timeType:this.state.sixSelect,
                beginTime:this.state.from,
                endTime:this.state.to,
                name1:this.state.name1,
                name2:this.state.name2,
                status:this.state.thdSelect,
                extendStatus:this.state.oldOrNew,

            })
            .then(res=>{
                if (res.data.code === 200) {
                    const data1 = [];
                    const data = res.data.data.resultData
                    if(data.length<1){
                        this.setState({
                            boolean: false
                        })
                    }else{
                        for (let i = 0; i < data.length; i++) {
                            data[i].key = i;
                            data1.push(data[i]);
                        }
                    }
                    this.setState({
                        tableData: data1,
                        total: res.data.data.page.total,
                        boolean:false
                    })
                } else if(res.data.code ==800){
                    this.props.history.push({ pathname: "/"});
                }else{
                    // message.error(res.data.msg);
                }
            })
    }
    modal_review=()=>{
        axios
            .post(`/api/extension/auditExtension`,{

                id: this.state.disval,
                status:2
            })
            .then(res=>{
                if (res.data.code === 200) {
                    message.success(res.data.msg);
                    this.setState({
                        reviewvisible:false,
                        fstafter:false
                    })

                } else if(res.data.code ==800){
                    this.props.history.push({ pathname: "/"});
                }else{
                    message.error(res.data.msg);
                    this.setState({
                        reviewvisible:false,
                        fstafter:false
                    })
                }
            })
        this.searchClick()
    }
    render() {

        const columns = [
            {
                title: 'Extend ID',
                dataIndex: 'id',
                key: 'id',

            },
            {
                title: 'App type',
                dataIndex: 'appCode',
                key: 'appCode',

            },
            {
                title: 'Order No',
                dataIndex: 'indentNo',
                key: 'indentNo',
                width:50
            },
            {
                title: 'Customer type',
                dataIndex: 'tag',
                key: 'tag',

            },
            {
                title: 'Customer name',
                dataIndex: 'firstName',
                key: 'firstName',

            },
            {
                title: 'Phone',
                dataIndex: 'mobile',
                key: 'mobile',

            },
            {
                title: 'Loan amount',
                dataIndex: 'balance',
                key: 'balance',
                render: (value)=> {
                    var num = value/100;
                    num = num + '';
                    if(num.indexOf(".")!=-1){
                        return num;
                    }else{
                        var str = "";
                        for(var i=num.length- 1,j=1;i>=0;i--,j++){
                            if(j%3==0 && i!=0){//每隔三位加逗号，过滤正好在第一个数字的情况
                                str+=num[i]+",";//加千分位逗号
                                continue;
                            }
                            str+=num[i];//倒着累加数字
                        }
                        return str.split('').reverse().join("");
                    }


                }
            },
            {
                title: 'Loan term',
                dataIndex: 'cycle',
                key: 'cycle',

            },
            {
                title: 'Due amount',
                dataIndex: 'shouldbackFee',
                key: 'shouldbackFee',
                render: (value)=> {
                    var num = value/100;
                    num = num + '';
                    if(num.indexOf(".")!=-1){
                        return num;
                    }else{
                        var str = "";
                        for(var i=num.length- 1,j=1;i>=0;i--,j++){
                            if(j%3==0 && i!=0){//每隔三位加逗号，过滤正好在第一个数字的情况
                                str+=num[i]+",";//加千分位逗号
                                continue;
                            }
                            str+=num[i];//倒着累加数字
                        }
                        return str.split('').reverse().join("");
                    }


                }
            },
            {
                title: 'Original due date',
                dataIndex: 'shouldbackTime',
                key: 'shouldbackTime',
                render: (value,record) => {
                  if(value == record.extensionTime) {
                      return record.initialrepaymentTime;
                  }else {
                      return value;
                  }
                }
            },
            {
                title: 'Now due date',
                dataIndex: 'extensionTime',
                key: 'extensionTime',
                // render: (value) => {
                //   if(value){
                //     let timeaudit= new Date(value).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
                //     let auditimes=moment(new Date(timeaudit)).format("YYYY-MM-DD HH:mm:ss");
                //     return auditimes
                //   }else{
                //     return ''
                //   }

                // }
            },
            {
                title: 'Loan status',
                dataIndex: 'loanStatusDescription',
                key: 'loanStatusDescription',

            },
            {
                title: 'Extend status',
                dataIndex: 'statusDescription',
                key: 'statusDescription',
            },
            {
                title: 'Actural extend fee',
                dataIndex: 'actualExtensionFees',
                key: 'actualExtensionFees',
                render: (value)=> {
                    var num = value/100;
                    num = num + '';
                    if(num.indexOf(".")!=-1){
                        return num;
                    }else{
                        var str = "";
                        for(var i=num.length- 1,j=1;i>=0;i--,j++){
                            if(j%3==0 && i!=0){//每隔三位加逗号，过滤正好在第一个数字的情况
                                str+=num[i]+",";//加千分位逗号
                                continue;
                            }
                            str+=num[i];//倒着累加数字
                        }
                        return str.split('').reverse().join("");
                    }


                }
            },
            {
                title: 'Apply extend fee',
                dataIndex: 'extensionFees',
                key: 'extensionFees',
                render: (value)=> {
                    var num = value/100;
                    num = num + '';
                    if(num.indexOf(".")!=-1){
                        return num;
                    }else{
                        var str = "";
                        for(var i=num.length- 1,j=1;i>=0;i--,j++){
                            if(j%3==0 && i!=0){//每隔三位加逗号，过滤正好在第一个数字的情况
                                str+=num[i]+",";//加千分位逗号
                                continue;
                            }
                            str+=num[i];//倒着累加数字
                        }
                        return str.split('').reverse().join("");
                    }


                }
            },
            {
                title: 'Extend difference',
                dataIndex: '(extensionFees-actualExtensionFees)',
                key: '(extensionFees-actualExtensionFees)',
                render:(value,record)=> {
                    var num1 = record.extensionFees;
                    var num2 = record.actualExtensionFees
                    var num3 = (num1 - num2)/100
                    num3 = num3 + '';
                    if(num3.indexOf(".")!=-1){
                        return num3;
                    }else if(num3.indexOf("-")!=-1){
                      return num3
                    }else{
                        var str = "";
                        for(var i=num3.length- 1,j=1;i>=0;i--,j++){
                            if(j%3==0 && i!=0){//每隔三位加逗号，过滤正好在第一个数字的情况
                                str+=num3[i]+",";//加千分位逗号
                                continue;
                            }
                            str+=num3[i];//倒着累加数字
                        }
                        return str.split('').reverse().join("");
                    }
                }
            },
            {
                title: 'Extend Apply date',
                dataIndex: 'paymentTime',
                key: 'paymentTime',
                // render: (value) => {
                //   if(value){
                //     let timeaudit= new Date(value).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
                //     let auditimes=moment(new Date(timeaudit)).format("YYYY-MM-DD HH:mm:ss");
                //     return auditimes
                //   }else{
                //     return ''
                //   }

                // }
            },
            {
                title: 'Operation',
                dataIndex: 'operating',
                key: 'operating',
                width:70,
                id:'id',
                uid:'uid',
                loanId:'loanId',
                status:'status',
                fixed:'right',
                render: (text, record, index) => (<span style={{ display: 'flex' , flexDirection: 'row'}}>
                  <Button type="primary"  style={{display:record.status=='1'?'block':'none'}}  className="detailsbutton common_color" size="small"  onClick={()=>this.review(record)}>
                      review
                  </Button>
                
                  <Button type="primary"    className="detailsbutton common_color" size="small"  onClick={()=>this.text(record)}>
                    details
                  </Button>
                  </span>)
                  },
            ];
        return (
            <div className="credit_order">
                <div className="myorder_content">



                    <div className="flutter">
                        <div className="top">
                            <div className="top_left" style={{width:'30%',flexShrink:1}}>
                                <div className="rangepick">
                                    <div className="search">
                                        <span className="description left">Order No:</span>
                                        <input type="text" placeholder='order No' value={this.state.fstInput} onChange={this.fstInputChange} className="modal_input"  style={{width: 290,marginLeft:0}}/>
                                    </div>
                                </div>
                            </div>

                            <div className="top_mid" style={{width:'30%',flexShrink:2,marginLeft:'5%',marginRight:'5%'}}>
                                <span className="description left">Phone:</span>
                                <input type="text" placeholder='phone' value={this.state.scdInput} onChange={this.scdInputChange} className="modal_input"  style={{width: 290,marginLeft:0}}/>
                            </div>
                            <div className="mid_right"style={{width:'30%'}}>
                                <span className="description right">Extend status:</span>
                                <select className="form_select" value={this.state.oldOrNew} onChange={this.oldOrderNew.bind(this)}>
                                    <option value="">All</option>
                                    <option value="2">In extend</option>
                                    <option value="1">In review</option>
                                    <option value="3">Finished</option>
                                    <option value="4">Rejected</option>
                                </select>
                            </div>
                           
                        </div>

                        <div className="order_mid">
                            <div className="top_left" style={{width:'30%',flexShrink:1}}>
                                <div className="rangepick">
                                    <div className="search">
                                        <span className="description left">Name1:</span>
                                        <input type="text" placeholder='name1' value={this.state.name1} onChange={this.Name1} className="modal_input"  style={{width: 290,marginLeft:0}}/>
                                    </div>
                                </div>
                            </div>

                            <div className="top_mid" style={{width:'30%',flexShrink:2,marginLeft:'5%',marginRight:'5%'}}>
                                <span className="description left">Name2:</span>
                                <input type="text" placeholder='name2' value={this.state.name2} onChange={this.Name2} className="modal_input"  style={{width: 290,marginLeft:0}}/>
                            </div>


                            <div className="mid_right" style={{width:'30%'}}>
                                <span className="description right">Loan Status:</span>
                                <select className="form_select" value={this.state.thdSelect} onChange={this.selectThd.bind(this)}>
                                    <option value="">all</option>
                                    <option value="6">In loan</option>
                                    <option value="8">Overdue</option>
                                    <option value="22">Bad Debit</option>
                                </select>
                            </div>
                        </div>

                        <div className="bottom"  style={{justifyContent:'flex-start',marginTop:20}}>
                            <div className="top_right" style={{width:'30%',flexShrink:1}}>
                                <span className="description left" >Time Type:</span>
                                <select className="form_select" style={{marginRight:0,width:292}} value={this.state.sixSelect} onChange={this.selectsix}>
                                    <option value="1" >should back time</option>
                                    <option value="2">last repay time</option>
                                    <option value="3">payment time</option>
                                    <option value="4">created time</option>
                                </select>
                            </div>
                            <div className="mid_right" style={{width:'30%',marginLeft:'5%',marginRight:'5%'}}>
                                <span className="description left">Selected time:</span>
                                <RangePicker
                                    style={{width: 290,}}
                                    ranges={{
                                        Today: [moment(), moment()],
                                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                                    }}
                                    showTime
                                    format={dateFormat}
                                    onChange={this.rangeOnChange}
                                    value={this.state.from===undefined||this.state.to===undefined||this.state.from===""||this.state.to===""?null:[moment(this.state.from, dateFormat), moment(this.state.to, dateFormat)]}
                                />
                            </div>
                            <Button type="primary" style={{width:170,marginRight:20,height:40,border:'none'}} className="common_color" onClick={this.searchClick.bind(this)}>Search</Button>
                            <Button type="primary" style={{width:170,height:40,border:'none'}} className="common_color_scd" onClick={this.reloadClick.bind(this)}>Refresh</Button>
                        </div>
                    </div>
                </div>
                <Table columns={columns} dataSource={this.state.tableData} loading={this.state.boolean} onChange={this.handleChange} pagination={false} />


                <Pagination
                 
              
                    showQuickJumper
                    defaultCurrent={1}
                    current={this.state.current}
                    total={this.state.total}
                    onChange={this.pageChange}
                    showTotal={showTotal}
                    style={{textAlign:'right',marginTop:20}}
                    pageSize={this.state.pageSize}
                />
                <Modal
                    title="Extend repay"
                    onCancel={this.handleCancel}
                    visible={this.state.reviewvisible}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            cancel
                        </Button>,
                        <Button key="submit" type="primary" loading={this.state.fstafter} onClick={this.modal_review}>
                            ok
                        </Button>,
                    ]}>
                    <input value={this.state.disval} style={{display:'none'}}/>
                    <div className="model_last">
                        <span  className="long" style={{width:140}}>Should Extend fee:</span>
                        <input className="modal_input" disabled placeholder="Should Extend fee" style={{marginLeft:'7%',width:290}}  value={this.state.modalinput} onChange={this.ModalInput}/>
                    </div>
                    <div className="model_last">
                        <span className="long" style={{width:140}}>Actural Extend fee:</span>
                        <input className="modal_input" disabled placeholder="Actural Extend fee" style={{marginLeft:'7%',width:290}}  value={this.state.modalscdinput} onChange={this.ModalScdInput}/>
                    </div>
                    <div className="model_last">
                        <span className="long" style={{width:140}}>Reduce Amount:</span>
                        <input className="modal_input" placeholder="Reduce Amount" disabled style={{marginLeft:'7%',width:290}}  value={this.state.modaltarget} onChange={this.ModalTarget}/>
                    </div>
                    <div className="model_last">
                        <span className="long" style={{width:140}}>Original due date:</span>
                        <input className="modal_input" placeholder="Original due date" disabled style={{marginLeft:'7%',width:290}}  value={this.state.modalscdSelect} onChange={this.whitch_selectshow}/>
                    </div>
                    <div className="model_last">
                        <span className="long" style={{width:140}}>Now due date:</span>
                        <input className="modal_input" placeholder="Now due date" disabled style={{marginLeft:'7%',width:290}}  value={this.state.modal_textarea} onChange={this.modaltextarea}/>
                    </div>
                </Modal>
            </div>
        );
    }
}
export default withRouter(extendOrder)