/*eslint-disable*/
import React from 'react';
import {Input, Button,Switch , Table, Modal,Radio, Pagination, DatePicker, Tooltip, message,Upload,  Icon} from 'antd';
import './index.css';
import axios from 'axios'
import moment from 'moment';
import {withRouter} from 'react-router-dom';
import Zmage from 'react-zmage'
function showTotal(total) {
    return `Total ${total} items`;
}

var getselect = []
const {Search} = Input;
const key = [];
var Select = []
 class Banner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            tableData: [],
            searchVal: '',
            current: 1,
            total: 1,
            pageSize: 10,
            boolean: true,
            fileList: [],
            uploading: false,
            radvalue:1,
            id:0,
            isUpload:0,
            modaltitle:'新增',
            fstAddVal:1,
            scdAddVal:'',
            fstInput:'',
            scdInput:'',
            thdInput:'',
            // isfalse:true,
            scdafter:false,
            productStatus:1 //搜索框默认状态为上线

        }
    }
    getselect=()=>{
        axios
        .post(`/api/appshadow/findAppShadowList`,{
    
      })
      .then(res=>{
         Select=[]
        if(res.data.code === 200){
          for(let i=0;i<res.data.data.list.length;i++ ){
            
              if(i==0){
                Select.push(<option value=''>please select one</option> )
                }
                Select.push(       
                <option value={res.data.data.list[i].appCode}>{res.data.data.list[i].appName}</option>
            )
            
          this.setState({
            Select:Select
          })
          }
        }else if(res.data.code ==800){
          this.props.history.push({ pathname: "/"});
        }else{
        }
      })
      }
    
    edit=(record)=>{
        this.setState({
            modaltitle:'编辑',
          
            id:record.id
        })
        axios({
            url: '/api/sysBanner/selectBanner',
            method: 'post',
            data: {id:record.id}
        })
        .then(res => {
            if (res.data.code === 200) {        
                // this.searchClick()
                this.setState({ 
                    scdvisible:false,
                    fstAddVal:res.data.data.resultDate.location,
                    scdAddVal:res.data.data.resultDate.product,
                    fstInput:res.data.data.resultDate.title,
                    scdInput:res.data.data.resultDate.sort,
                    thdInput:res.data.data.resultDate.jumpUrl,
                    radvalue:res.data.data.resultDate.status,
                    id:res.data.data.resultDate.id,
                    scdvisible:true,
                })
            } else if (res.data.code == 800) {
                this.props.history.push({pathname: "/"});
            } else {
                // this.setState({
                //     scdvisible:false,
                // })
            }
        })
    }
    radioOnChange = e => {
        console.log('radio checked', e.target.value);
        this.setState({
            radvalue: e.target.value,
        });
      };
    Add=()=>{
        this.setState({
            scdvisible:true,
        })
    }

    edithandleOk=(record,e)=>{
    
    }


    handleOk=(record)=>{
        this.setState({
            scdafter:true
        })
        const { fileList,fstAddVal,scdAddVal,fstInput,scdInput,thdInput,radvalue,id,isUpload } = this.state;
        const formData = new FormData();
        formData.append('file', fileList[0]);    
        formData.append('location', fstAddVal);    
        formData.append('product', scdAddVal);    
        formData.append('title', fstInput);    
        formData.append('sort', scdInput);    
        formData.append('jumpUrl', thdInput);    
        formData.append('isUpload', isUpload);   
        formData.append('id', id); 
        formData.append('status', radvalue); 
          
        console.log('fileList',fileList)
        axios({
            url: '/api/sysBanner/bannerAddOrEdit',
            method: 'post',
            data: formData
        })
        .then(res => {
            if (res.data.code === 200) {        
                message.success(res.data.msg)
                this.searchClick()
                this.setState({ 
                    scdvisible:false,
                    fstAddVal:1,
                    scdAddVal:'',
                    fstInput:'',
                    scdInput:'',
                    thdInput:'',
                    isUpload:0,
                    radvalue:1,
                    id:0,
                    fileList:'',
                    scdafter:false
                })
            } else if (res.data.code == 800) {
                this.props.history.push({pathname: "/"});
            } else {
                message.error(res.data.msg)
                this.setState({
                    scdvisible:false,
                    scdafter:false
                })
            }
        })

    }

    handleCancel=()=>{
        this.setState({
            fstvisible:false,
            scdvisible:false,
            fstAddVal:1,
            scdAddVal:'',
            fstInput:'',
            scdInput:'',
            thdInput:'',
            isUpload:0,
            radvalue:1,
            id:0,
            fileList:'',
            scdafter:false
        })

    }


//页面初始化加载
    secondTable = () => {
        this.searchClick()
    }
    //搜索
    searchClick = () => {
        this.setState({
            boolean: true
        })
        axios
            .post(`/api/sysBanner/getBannerList`, {
                current:this.state.current,
                pageSize:this.state.pageSize,
                status:this.state.productStatus,
            })
            .then(res => {
                if (res.data.code === 200) {

                    const data1 = [];
                    const data = res.data.data.resultDate

                    for (let i = 0; i < data.length; i++) {
                        data[i].key = i;
                        data1.push(data[i]);
                    }
                    this.setState({
                        tableScdData: data1,
                        boolean: false,
                        length:res.data.data.page.total
                    })
                } else if (res.data.code == 800) {
                    this.props.history.push({pathname: "/"});
                } else {
                    
                }
            })
    }
    refresh=()=>{
        this.setState({
            productStatus:1,
            productcategory:''
        },()=>{
            this.searchClick();
        })
    }
    changeupload=()=>{
        this.setState({
            isUpload:1
        })
    }
    fstOnchange=(e)=>{
        let value = e.target.value
        this.setState({
            fstAddVal:value
        })
    }
    scdOnchange=(e)=>{
        let value = e.target.value
        this.setState({
            scdAddVal:value
        })
    }
    fstInputOnchange=(e)=>{
        let value = e.target.value
        this.setState({
            fstInput:value
        })
    }
    scdInputOnchange=(e)=>{
        let value = e.target.value
        this.setState({
            scdInput:value
        })
    }
    thdInputOnchange=(e)=>{
        let value = e.target.value
        this.setState({
            thdInput:value
        })
    }
    statusOnchange = (e) => {
        let value = e.target.value
        this.setState({
            productStatus: value
        })
        }
    componentDidMount() {
        this.secondTable()
        this.getselect()
    }
        //页码改变时触发
        pageChange=(page)=> { 
      
            this.setState({
              current: page
              },()=>{
                this.searchClick()
              })
            }
            
    render() {
        
        const columns = [
            {
                title: '位置',
                dataIndex: 'location',
                key: 'location',
                render: (value) => {
                    if(value==1){
                        return '首页Banner'
                    }else if(value==2){
                        return '邀请好友'
                    }else if(value==3){
                        return '胶囊Banner'
                    }else if(value==4){
                        return '产品页Banner'
                    }else if(value==5){
                        return 'APP启动页图片'
                    }else if(value==6){
                        return '绑卡'
                    }
                }

            },
            {
                title: '产品',
                dataIndex: 'product',
                key: 'product',
                render: (value) => {
                    return value || '——'
                }
            },
            {
                title: '滚动图标题',
                dataIndex: 'title',
                key: 'title',
                render: (value) => {
                    return value || '——'
                }

            },
            {
                title: '序号',
                dataIndex: 'sort',
                key: 'sort',
                render: (value) => {
                    return value || '——'
                }

            },
            {
                title: '轮播图',
                dataIndex: 'bannerUrl',
                key: 'bvnNo',
                render: (value) => {
                    return <Zmage src={value} style={{width:220,height:110}}/>
                }
            },

            {
                title: '跳转地址',
                dataIndex: 'jumpUrl',
                key: 'jumpUrl',
               
                render: (value) => {
                    return <a href={value} target="_blank" className='text_direction'>{value}</a>
                }
            },
            {
                title: '今日点击量',
                dataIndex: 'clickToday',
                key: 'clickToday',
                render: (value) => {
                    return value 
                }

            },
            {
                title: '创建时间',
                dataIndex: 'updatedTime',
                key: 'updatedTime',
                render: (value) => {
                    let timeaudit= new Date(value).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
                    let auditimes = ''
                    if(value==null){
                        return auditimes=''
                    }else{
                        return auditimes=moment(new Date(timeaudit)).format("YYYY-MM-DD HH:mm:ss");
                    }
                    
                }

            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: (value) => {
                   if(value==1){
                       return '上线'
                   }else{
                    return '下线'
                   }
                }
            },
            { title: 'Operation', dataIndex: '', key: 'operation',id:'id', render: (text, record) => 
            <span style={{ display: 'flex' , flexDirection: 'row', justifyContent:'center',margin:0}}>
                 <a className='edit_button' href="javascript:;" onClick={() => this.edit(record)}>编辑</a>
              
              </span>
           
            },
        ];
        const { uploading, fileList } = this.state;
        const props = {
          onRemove: (file) => {
            this.setState((state) => {
              const index = state.fileList.indexOf(file);
              const newFileList = state.fileList.slice();
              newFileList.splice(index, 1);
              return {
                fileList: newFileList,
              };
            });
          },
          beforeUpload: (file) => {
            this.setState(state => ({
              fileList: [...state.fileList, file],
            }));
            return false;
          },
          fileList,
        };
       
        return (
            <div className="menu_content">
                       <div style={{ width: '98%', display: 'flex', justifyContent: 'flex-start',alignItems:'center' }}>
                        <div className="mid_mid" style={{ width: '21%', flexShrink: 2, marginLeft: '1%', marginRight: '1%' }}>
                            <div className="rangepick">
                                <span style={{ width: 65, }} className="description mid_span">状态</span>
                                <select style={{ width: 200, }} className="coupon_form_select" value={this.state.productStatus} onChange={this.statusOnchange}>
                                    <option value="1">上线</option>
                                    <option value="">所有</option>
                                    <option value="-1">下线</option>
                                </select>
                            </div>
                        </div>
                <div className="bottom" style={{marginTop:'10px'}}>
                        <Button type="primary" style={{width:170,marginRight:20,height:40,border:'none' }} className="common_color" onClick={this.searchClick.bind(this)}>搜索</Button>
                        <Button type="primary" style={{ width:170,marginRight:20,height:40,border:'none' }}  className="common_color_thd"  onClick={this.refresh}>重置</Button>
                    </div>
                </div>
             <div style={{width:'98%',display:'flex',justifyContent:'space-between'}}><p  className='details_tab1_top_p'>Banner管理</p>
                    <Button className='tab2_button' type="primary" style={{ marginTop: 15 }} onClick={this.Add}>添加</Button></div>
                <div className="table">
                    <Table columns={columns} className='old_table' dataSource={this.state.tableScdData} loading={this.state.boolean}  pagination={false}/>
                </div>
                <Pagination
                defaultCurrent={1} 
                current={this.state.current}
                total={this.state.length} 
                onChange={this.pageChange}  
                pageSize={this.state.pageSize}
                showTotal={showTotal}
                style={{textAlign:'right',marginTop:20}}
                />


                <Modal
                title={this.state.modaltitle }
                visible={this.state.scdvisible} 
                onCancel={this.handleCancel}
                footer={[
                    <Button key="back" onClick={this.handleCancel}>
                      cancel
                    </Button>,
                    <Button key="submit" type="primary" loading={this.state.scdafter} onClick={this.handleOk}>
                      ok
                    </Button>,
                  ]}
                >
                
                 {/* <input value={this.state.addpid} style={{display:'none'}}/> */}  
               
                <div className='banner_out_div'>
                    <div className='banner_left_div'>
                        <p className='banner_left_p'>位置:</p>
                        <p className='banner_left_p'>关联产品:</p>
                        <p className='banner_left_p'>轮播图标题:</p>
                        <p className='banner_left_p'>上传图片:</p>
                        <p className='banner_left_p'>序号:</p>
                        <p className='banner_left_p'>跳转地址:</p>
                        <p className='banner_left_p'>状态:</p>
                    </div> 
                    <div className='banner_right_div'>
                    <select  value = {this.state.fstAddVal} allowClear className='product_select' onChange={this.fstOnchange}>
                        <option value='1'>首页Banner（460*200）</option>
                        <option value='2'>邀请好友</option>
                        <option value='3'>胶囊Banner（660*202）</option>
                        <option value='4'>产品页Banner</option>
                        <option value='5'>APP启动页图片</option>
                        <option value='6'>绑卡</option>
                    </select>
                    <select  value = {this.state.scdAddVal} allowClear className='product_select' onChange={this.scdOnchange}>
                       {Select}
                    </select>
                    <input type='text' className='product_select' value={this.state.fstInput} onChange={this.fstInputOnchange}/>
                    <div className="uploadBtn">
                        <Upload {...props}>
                        <Button onClick={this.changeupload}>
                            <Icon type="upload" /> 选择图片
                        </Button>
                        </Upload>
                    </div>
                    <input type='text' className='product_select' type='number' value={this.state.scdInput} onChange={this.scdInputOnchange}/>
                    <input type='text' className='product_select' value={this.state.thdInput} onChange={this.thdInputOnchange}/>
                    <Radio.Group onChange={this.radioOnChange} value={this.state.radvalue}>
                    <Radio value={1}>上线</Radio>
                    <Radio value={-1}>下线</Radio>

                </Radio.Group>
                    </div>
                </div>

                </Modal>
            </div>

        )
    }
}
export default withRouter(Banner)