/*eslint-disable*/
import React from "react";
import {
  Input,
  Button,
  Switch,
  Tree,
  Table,
  Modal,
  Pagination,
  message,
  Select,
} from "antd";
import "./index.css";
import axios from "axios";
import moment from "moment";
import { withRouter } from "react-router-dom";
import PageSearch from "./pageSearch";
import PageDialog from "./pageDialog";

function showTotal(total) {
  return `Total ${total} items`;
}
class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 1,
      data: [],
      tableData: [],
      //
      fee: "",
      cycle: "",
      status: 1,
      //
      current: 1,
      total: 1,
      pageSize: 10,
      boolean: true, // loading
      //
      newRole: {
        id: "",
        fee: "",
        cycle: "",
        profit: "",
        feePercent: "",
        serviceFee: "",
        overduePercent: "",
        status: 1,
      },
      showAddDialog: false,
      showEditDialog: false,
    };
  }
  //页码改变时触发
  pageChange = (page) => {
    this.setState(
      {
        current: page,
      },
      () => {
        this.searchClick();
      }
    );
  };
  resetNewRole = () => {
    this.setState({
      newRole: {
        id: "",
        fee: "",
        cycle: "",
        profit: "",
        feePercent: "",
        serviceFee: "",
        overduePercent: "",
        status: 1,
      },
      showAddDialog: false,
      showEditDialog: false,
    });
  };
  /*添加modal点击ok*/
  add = (newRole) => {
    delete newRole.id;
    this.setState({ newRole }, () => {
      axios
        .post(`/api/product/productAdd`, {
          ...this.state.newRole,
        })
        .then((res) => {
          if (res.data.code === 200) {
            message.success(res.data.msg);
            this.resetNewRole();
            this.searchClick();
          } else if (res.data.code == 800) {
            this.props.history.push({ pathname: "/" });
          } else {
            message.error(res.data.msg);
            this.resetNewRole();
            this.searchClick();
          }
        });
    });
  };
  addModal = () => {
    this.setState({
      newRole: {
        id: "",
        fee: "",
        cycle: "",
        profit: "",
        feePercent: "",
        serviceFee: "",
        overduePercent: "",
        status: 1,
      },
      showAddDialog: true,
    });
  };
  editModal = (record) => {
    const product = record;
    this.setState({
      newRole: {
        id: product.id,
        fee: product.fee / 100,
        cycle: product.cycle,
        profit: product.profit / 100,
        feePercent: product.feePercent / 100,
        serviceFee: product.suffixFee / 100,
        overduePercent: product.overduePercent / 100,
        status: product.status,
      },
      showEditDialog: true,
    });
  };
  edit = (newRole) => {
    this.setState({ newRole }, () => {
      axios
        .post(`/api/product/productEdit`, { ...this.state.newRole })
        .then((res) => {
          if (res.data.code === 200) {
            message.success(res.data.msg);

            this.resetNewRole();
            this.searchClick();
          } else if (res.data.code == 800) {
            this.props.history.push({ pathname: "/" });
          } else {
            message.error(res.data.msg);
            this.resetNewRole();
          }
        });
    });
  };
  //页面初始化加载下面的table
  secondTable = () => {
    this.searchClick();
  };
  //搜索
  searchClick = () => {
    this.setState({
      boolean: true,
    });
    axios
      .post(`/api/product/productList`, {
        pageNum: this.state.current,
        pageSize: this.state.pageSize,
        fee: this.state.fee,
        cycle: this.state.cycle,
        status: this.state.status,
      })
      .then((res) => {
        if (res.data.code === 200) {
          const data1 = [];
          const data = res.data.data.resultData;

          for (let i = 0; i < data.length; i++) {
            data[i].key = i;
            data1.push(data[i]);
          }
          this.setState({
            tableScdData: data1,
            boolean: false,
            length: res.data.data.page.total,
          });
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
        }
      });
  };
  componentDidMount() {
    this.secondTable();
  }
  render() {
    const columns = [
      {
        title: "loan amount",
        dataIndex: "fee",
        key: "fee",
        render: (value) => {
          return value / 100;
        },
      },
      {
        title: "Loan term",
        dataIndex: "cycle",
        key: "cycle",
        render: (value) => {
          return value;
        },
      },
      {
        title: "Interest （%）",
        dataIndex: "feePercent",
        key: "feePercent",
        render: (value) => {
          return value / 100;
        },
      },
      // 利息费
      {
        title: "Interest fee",
        dataIndex: "profit",
        key: "profit",
        id: "id",
        render: (value) => {
          return value / 100;
        },
      },
      // 服务费
      {
        title: "Service charge",
        dataIndex: "suffixFee",
        key: "suffixFee",
        render: (value) => {
          return value / 100;
        },
      },
      // 到期应还金额
      {
        title: "Amount due",
        dataIndex: "Amount due",
        key: "Amount due",
        render: (value, item) => {
          return (item.totalFee + item.fee) / 100;
        },
      },
      {
        title: "Overdue fee rate （%）",
        dataIndex: "overduePercent",
        key: "overduePercent",
        render: (value) => {
          return value / 100;
        },
      },
      {
        title: "status",
        dataIndex: "status",
        key: "status",
        id: "id",
        render: (value) => {
          if (value == 1) {
            return "online";
          } else {
            return "offline";
          }
        },
      },
      {
        title: "Operator",
        dataIndex: "operator",
        key: "operator",
        render: (value) => {
          return value;
        },
      },
      {
        title: "Operating time",
        dataIndex: "updatedTime",
        key: "updatedTime",
        render: (value) => {
          return moment(new Date(value)).format("YYYY-MM-DD HH:mm:ss");
        },
      },
      {
        title: "operate",
        dataIndex: "",
        key: "operation",
        render: (text, record) => (
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              margin: 0,
            }}
          >
            <a
              className="edit_button"
              href="#!"
              onClick={() => this.editModal(record)}
            >
              edit
            </a>
          </span>
        ),
      },
    ];
    return (
      <div className="menu_content">
        <div
          style={{
            width: "98%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <PageSearch
            fee={this.state.fee}
            cycle={this.state.cycle}
            status={this.state.status}
            search={(searchParams) => {
              this.setState({ ...searchParams, current: 1 }, () => {
                this.searchClick();
              });
            }}
          ></PageSearch>

          <Button
            className="tab2_button"
            type="primary"
            style={{ marginTop: 15 }}
            onClick={this.addModal}
          >
            Add
          </Button>
        </div>
        <div className="table">
          <Table
            columns={columns}
            rowKey={(record) => record.id}
            className="old_table"
            dataSource={this.state.tableScdData}
            loading={this.state.boolean}
            pagination={false}
          />
          ,
        </div>
        <Pagination
          defaultCurrent={1}
          current={this.state.current}
          total={this.state.length}
          onChange={this.pageChange}
          pageSize={this.state.pageSize}
          showTotal={showTotal}
          style={{ textAlign: "right", marginTop: 20 }}
        />

        <PageDialog
          key={this.state.showEditDialog + "edit"}
          isShow={this.state.showEditDialog}
          {...this.state.newRole}
          commit={this.edit}
          handleCancel={() => this.setState({ showEditDialog: false })}
        ></PageDialog>

        <PageDialog
          key={this.state.showAddDialog + "add"}
          isShow={this.state.showAddDialog}
          {...this.state.newRole}
          commit={this.add}
          handleCancel={() => this.setState({ showAddDialog: false })}
        ></PageDialog>
      </div>
    );
  }
}
export default withRouter(Product);
