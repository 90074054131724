/*eslint-disable*/
import React from "react";
import {
  Input,
  Button,
  Switch,
  Tree,
  Table,
  Modal,
  Radio,
  Pagination,
  DatePicker,
  Tooltip,
  message,
} from "antd";
import "./index.css";
import axios from "axios";
import moment from "moment";
import { withRouter } from "react-router-dom";
import EventEmitter from "../../utils/events";
var getSelect = [];
var treeData = [];
let test = [];
function showTotal(total) {
  return `Total ${total} items`;
}

const options = [
  { label: "Normal", value: 0 },
  { label: "Disable", value: -1 },
];
const optionsWithDisabled = [
  { label: "General user", value: 0 },
  { label: "system administrator", value: 1 },
];
var getselect = [];
const { TreeNode } = Tree;
const { Search } = Input;
const key = [];
class RoleManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 1,
      data: [],
      tableData: [],
      selectedRowKeys: [],
      searchVal: "",
      current: 1,
      total: 1,
      pageSize: 10,
      boolean: true,
      ishide: false,
      isshow: true,
      ids: "",
      mobilephone: "",
      opened: false,
      arr: [],
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
      value1: 0,
      value2: 0,
      treeInfo: [], //用来存放数组的数据
      childrenIds: [], //存放所有子节点的数组
    };
  }

  onExpand = (expandedKeys) => {
    console.log("onExpand", expandedKeys);
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    });
  };

  onCheck = (checkedKeys) => {
    console.log("onCheck", checkedKeys);
    this.setState({ checkedKeys });
  };

  onSelect = (selectedKeys, info) => {
    console.log("onSelect", info);
    this.setState({ selectedKeys });
  };
  renderTreeNodes = (data) =>
    data &&
    data.map((item) => {
      if (item.childResource) {
        return (
          <TreeNode title={item.resourceName} key={item.id}>
            {this.renderTreeNodes(item.childResource)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.id} title={item.resourceName} />;
    });
  //获取所有的子节点

  requestList = (data) => {
    data &&
      data.map((item) => {
        if (item.childResource && item.childResource.length > 0) {
          this.requestList(item.childResource);
        } else {
          test.push(item.id);
        }
        return null;
      });
    console.log("test---", test);
    return test;
  };
  // 去除父节点的方法
  uniqueTree = (uniqueArr, Arr) => {
    let uniqueChild = [];
    for (var i in Arr) {
      for (var k in uniqueArr) {
        if (uniqueArr[k] === Arr[i]) {
          uniqueChild.push(uniqueArr[k]);
        }
      }
    }
    return uniqueChild;
  };

  onSelectChange = (selectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    this.setState({ selectedRowKeys });
  };
  //页码改变时触发
  pageChange = (page) => {
    this.setState(
      {
        current: page,
      },
      () => {
        this.searchClick();
      }
    );
  };
  outOnChange = (value, record, ifCheck) => {
    console.log("record", record);
    console.log("ifcheck", ifCheck);
    axios
      .post(`/api/system//updateRoleStatus`, {
        id: record.id,
      })
      .then((res) => {
        if (res.data.code === 200) {
          message.success(res.data.msg);
          this.searchClick();
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
          message.error(res.data.msg);
        }
      });
  };
  onChange1 = (e) => {
    console.log("radio checked", e.target.value);
    this.setState({
      value1: e.target.value,
    });
  };
  onChange2 = (e) => {
    console.log("radio checked", e.target.value);
    this.setState({
      value2: e.target.value,
    });
  };

  onChange4 = (e) => {
    console.log("radio checked", e.target.value);
    this.setState({
      value4: e.target.value,
    });
  };
  /*添加modal点击ok*/
  addhandleOk = () => {
    axios
      .post(`/api/system/roleAdd`, {
        roleType: this.state.value2,
        roleName: this.state.fstAddVal,
        roleRemark: this.state.scdAddVal,
        roleStatus: this.state.value1,
      })
      .then((res) => {
        if (res.data.code === 200) {
          message.success(res.data.msg);
          this.searchClick();
          this.setState({
            value2: 0,
            value1: 0,
            fstAddVal: "",
            scdvisible: false,
            scdAddVal: "",
          });
          this.searchClick();
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
          message.error(res.data.msg);
          this.setState({
            value2: 0,
            value1: 0,
            fstAddVal: "",
            scdvisible: false,
            scdAddVal: "",
          });
          this.searchClick();
        }
      });
  };
  /*删除modal点击ok*/
  deletehandleOk = () => {
    axios
      .post(`/api/system/roleDel`, {
        id: this.state.delval,
      })
      .then((res) => {
        if (res.data.code === 200) {
          message.success(res.data.msg);
          this.searchClick();
          this.setState({
            thdvisible: false,
          });
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
          message.error(res.data.msg);
          this.searchClick();
          this.setState({
            thdvisible: false,
          });
        }
      });
  };
  edit = (record) => {
    console.log(record);
    axios
      .post(`/api/system/roleGet`, {
        id: record.id,
      })
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            fstval: res.data.data.roleName,
            scdval: res.data.data.roleRemark,
            value4: res.data.data.roleType,
          });
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
          message.error(res.data.msg);
        }
      });
    this.setState({
      fstvisible: true,
      pid: record.pid,
      id: record.id,
    });
  };
  edithandleOk = (record, e) => {
    axios
      .post(`/api/system/roleEdit`, {
        id: this.state.id,
        roleName: this.state.fstval,
        roleRemark: this.state.scdval,
        roleType: this.state.value4,
      })
      .then((res) => {
        if (res.data.code === 200) {
          message.success(res.data.msg);

          this.setState({
            fstval: "",
            scdval: "",
            value4: "",
          });
          this.searchClick();
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
          message.error(res.data.msg);
          this.setState({
            fstval: "",
            scdval: "",
            value4: "",
          });
        }
      });
    this.setState({
      fstvisible: false,
    });
  };
  menuhandleOk = () => {
    if (this.state.checkedKeys.length < 1) {
      alert("Please select one menu at least ");
      return false;
    }
    axios
      .post(`/api/system/roleResourceUpdate`, {
        id: this.state.menuid,
        resourceIds: this.state.checkedKeys,
      })
      .then((res) => {
        if (res.data.code === 200) {
          message.success(res.data.msg);

          this.setState({
            fouvisible: false,
          });
          this.searchClick();
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
          message.error(res.data.msg);
          this.setState({
            fouvisible: false,
          });
        }
      });
    this.setState({
      fstvisible: false,
    });
  };
  Add = () => {
    this.setState({
      scdvisible: true,
    });
  };
  //调接口获取树菜单
  Permisions = (record) => {
    this.setState({
      fouvisible: true,
      menuid: record.id,
    });
    axios
      .post(`/api/system/roleResourceEdit`, {
        roleId: record.id,
      })
      .then((res) => {
        if (res.data.code === 200) {
          // let home = {}
          // let child = {}

          // console.log(res.data.data[0])
          this.requestList(res.data.data[0]); //将后台返回的数组中的子节点存放在一个数组中
          let uniqueChild = this.uniqueTree(res.data.data[1], test); //调用去除父节点的方法
          console.log("uniqueChild", uniqueChild);
          // console.log("test",this.requestList(res.data.data[0]))
          this.setState({
            treeInfo: res.data.data[0],
            checkedKeys: uniqueChild,
          });
        }
      });
    this.setState({
      fstvisible: false,
    });
  };

  modelselectThree = (e) => {
    var value = e.target.value;
    this.setState({
      modalthdSelect: value,
    });
  };

  handleCancel = () => {
    this.setState({
      fstvisible: false,
      scdvisible: false,
      thdvisible: false,
      fouvisible: false,
    });
  };
  delete = (record) => {
    this.setState({
      named: record.middleName,
      thdvisible: true,
      delval: record.id,
    });
  };

  //页面初始化加载下面的table
  secondTable = () => {
    this.searchClick();
  };
  //搜索
  searchClick = () => {
    this.setState({
      boolean: true,
    });
    axios
      .post(`/api/system/roleList`, {
        pageNum: this.state.current,
        pageSize: this.state.pageSize,
        roleName: this.state.searchVal,
      })
      .then((res) => {
        if (res.data.code === 200) {
          const data1 = [];
          const data = res.data.data.resultData;

          for (let i = 0; i < data.length; i++) {
            data[i].key = i;
            let status = data.map((item) => {
              return item.resourceStatus;
            });
            this.setState({
              switchStatus: status,
            });

            data1.push({
              key: i,
              middleName: data[i].roleName,
              surName: data[i].roleRemark,
              gender: data[i].roleStatus,
              bvnNo: data[i].roleType,
              id: data[i].id,
            });
          }
          this.setState({
            tableScdData: data1,
            boolean: false,
            length: res.data.data.page.total,
          });
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
        }
      });
  };
  handClick = (item) => {
    console.log("1111111122222233333", item);
  };
  fstonChange = (e) => {
    let value = e.target.value;
    this.setState({
      fstval: value,
    });
  };

  /*添加modal的input开始 */
  fstAddOnChange = (e) => {
    let value = e.target.value;
    this.setState({
      fstAddVal: value,
    });
  };
  scdAddOnChange = (e) => {
    let value = e.target.value;
    this.setState({
      scdAddVal: value,
    });
  };
  /*添加modal的input结束 */

  //输入框
  searchIpt(e) {
    var value = e.target.value;
    this.setState({ searchVal: value });
  }
  scdonChange = (e) => {
    let value = e.target.value;
    this.setState({
      scdval: value.replace(/\s+/g, ""),
    });
  };
  thdonChange = (e) => {
    let value = e.target.value;
    this.setState({
      thdval: value,
    });
  };
  componentDidMount() {
    this.secondTable();
  }

  render() {
    const columns = [
      {
        title: "character name",
        dataIndex: "middleName",
        key: "middleName",
        render: (value) => {
          return value || "——";
        },
      },
      {
        title: "remark",
        dataIndex: "surName",
        key: "surName",
        render: (value) => {
          return value || "——";
        },
      },
      {
        title: "status",
        dataIndex: "gender",
        key: "gender",
        render: (value, record, ifCheck) => (
          <span>
            <Switch
              onChange={(ifCheck) => {
                this.outOnChange(value, record, ifCheck);
              }}
              checked={record.gender == -1 ? false : true}
            />
          </span>
        ),
      },
      {
        title: "type",
        dataIndex: "bvnNo",
        description:
          "My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.",
        key: "bvnNo",
        id: "id",
        render: (value) => {
          if (value == 0) {
            return (value = "user");
          } else {
            return (value = "system administrator");
          }
        },
      },
      {
        title: "Operation",
        dataIndex: "",
        key: "operation",
        render: (text, record) => (
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              margin: 0,
            }}
          >
            <a
              className="open_button common_color"
              href="javascript:;"
              onClick={(e) => this.Permisions(record)}
            >
              Permisions
            </a>
            <a
              className="edit_button"
              href="javascript:;"
              onClick={() => this.edit(record)}
            >
              Edit
            </a>
            <a
              className="edit_button"
              href="javascript:;"
              onClick={() => this.delete(record)}
            >
              Delete
            </a>
          </span>
        ),
      },
    ];
    return (
      <div className="menu_content">
        <div
          style={{
            width: "98%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <Search
              placeholder="character name"
              onSearch={(value) => console.log(value)}
              style={{ width: 290, marginRight: 10, marginLeft: 10 }}
              value={this.state.searchVal}
              onChange={this.searchIpt.bind(this)}
            />
            <Button
              type="primary"
              style={{
                width: 170,
                marginRight: 20,
                height: 40,
                border: "none",
              }}
              className="common_color"
              onClick={this.searchClick}
            >
              Search
            </Button>
          </div>

          <Button
            className="tab2_button"
            type="primary"
            style={{ marginTop: 15 }}
            onClick={this.Add}
          >
            Add
          </Button>
        </div>
        <div className="table">
          <Table
            columns={columns}
            className="old_table"
            dataSource={this.state.tableScdData}
            loading={this.state.boolean}
            pagination={false}
          />
          ,
        </div>
        <Pagination
          defaultCurrent={1}
          current={this.state.current}
          total={this.state.length}
          onChange={this.pageChange}
          pageSize={this.state.pageSize}
          showTotal={showTotal}
          style={{ textAlign: "right", marginTop: 20 }}
        />
        <Modal
          title="Edit Menu"
          visible={this.state.fstvisible}
          onOk={this.edithandleOk}
          onCancel={this.handleCancel}
        >
          <input value={this.state.id} style={{ display: "none" }} />
          <Input
            value={this.state.fstval}
            allowClear
            placeholder="character name"
            style={{ marginTop: 10 }}
            onChange={this.fstonChange}
          />
          <br />
          <Input
            value={this.state.scdval}
            allowClear
            placeholder="remark"
            style={{ marginTop: 10 }}
            onChange={this.scdonChange}
          />
          <br />
          <Radio.Group onChange={this.onChange4} value={this.state.value4}>
            <Radio value={0}>General user</Radio>
            <Radio value={1}>System administrator</Radio>
          </Radio.Group>
        </Modal>

        <Modal
          title="New Role"
          visible={this.state.scdvisible}
          onOk={this.addhandleOk}
          onCancel={this.handleCancel}
        >
          <Input
            value={this.state.fstAddVal}
            placeholder="character name"
            allowClear
            style={{ marginTop: 10 }}
            onChange={this.fstAddOnChange}
          />
          <br />
          <Input
            value={this.state.scdAddVal}
            allowClear
            placeholder="remark"
            style={{ marginTop: 10 }}
            onChange={this.scdAddOnChange}
          />
          <br />
          <Radio.Group
            style={{ marginTop: 10, marginBottom: 5 }}
            options={options}
            onChange={this.onChange1}
            value={this.state.value1}
          />

          <br />
          <Radio.Group
            onChange={this.onChange2}
            options={optionsWithDisabled}
            value={this.state.value2}
          />
        </Modal>

        <Modal
          visible={this.state.thdvisible}
          onOk={this.deletehandleOk}
          onCancel={this.handleCancel}
        >
          <input value={this.state.delval} style={{ display: "none" }} />
          <p>Make sure you want to delete {this.state.named}?</p>
        </Modal>

        <Modal
          title="Menu Permissions"
          visible={this.state.fouvisible}
          onOk={this.menuhandleOk}
          onCancel={this.handleCancel}
        >
          <input value={this.state.menuid} style={{ display: "none" }} />
          <Tree
            checkable
            onExpand={this.onExpand}
            expandedKeys={this.state.expandedKeys}
            autoExpandParent={this.state.autoExpandParent}
            onCheck={this.onCheck}
            checkedKeys={this.state.checkedKeys}
            onSelect={this.onSelect}
            selectedKeys={this.state.selectedKeys}
          >
            {this.renderTreeNodes(this.state.treeInfo)}
          </Tree>
        </Modal>
      </div>
    );
  }
}
export default withRouter(RoleManagement);
