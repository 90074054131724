import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import Schema from 'async-validator'
import moment from 'moment';
import { Table, Switch, Pagination, Button, Modal, Input, message, DatePicker } from 'antd';
import { validateSymbol, validateNumber,validateNumbers } from '../../../utils/validate'
import {getCollectSettingData,collectTargetForm} from '../../../api/systemApi'
const { TextArea } = Input;
const { confirm } = Modal;
const rules = {
    keyValue: [//阀值
        { required: true, message: 'The input field cannot be empty ' },
        { validator: validateNumbers, message: 'Please enter a positive integer' }
    ]
}
const rules1 = {
    keyValue: [//阀值
        { required: true, message: 'The input field cannot be empty ' },
        { validator: validateNumbers, message: 'Please enter a positive integer' }
    ]
}
const rules2 = {
    dateTime: [//阀值
        { required: true, message: 'The input field cannot be empty ' },
    ]
}
const dateFormat = 'YYYY-MM-DD' || undefined;
let flag = 1; //控制底下3个按钮是否禁用
class Tab1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 1,
            total: 1,
            pageSize: 10,
            tableData: [],
            boolean: true,
            btnLoading: false,
            controlBtn: 1
        }
    }

    showConfirm=(record)=> { //编辑按钮前确认
        var that = this;
     confirm({
       title: 'Are you sure to perform the operation?',
       // icon: <ExclamationCircleOutlined />,
       // content: 'Some descriptions',
       onOk() {
           that.secondhandleOk();
       },
       onCancel() {
         console.log('Cancel');
       },
     });
   }

    statusComfirm = (value, record, ifCheck) => { //切换状态按钮前确认
        var that = this;
        confirm({
            title: 'Are you sure to perform the operation?',
            // icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            onOk() {
                that.outOnChange(value, record, ifCheck)
            },
            onCancel() {
                console.log('cancel')
            },
        });
    }
    //状态切换
    outOnChange = (value, record, ifCheck) => {
        collectTargetForm({
                id: record.id,
                // status: ifCheck == true ? 1 : 2,
                keyName: record.keyName,
                keyValue: ifCheck == true ? 1 : 2,
            })
            .then(res => {
                if (res.data.code === 200) {
                    message.success(res.data.msg)
                    this.getTableData();
                } else if (res.data.code == 800) {
                    this.props.history.push({ pathname: "/" });
                } else {
                    message.error(res.data.msg)
                }
            })
    }
    componentDidMount() { //生命周期
        this.getTableData();
    }
    showDialog() { //打开新增弹窗
        this.setState({
            modelTitle: 'Add SMS',
            firstVisible: true
        })
    }
    handleCancel = () => { //关闭弹窗
        this.setState({
            firstVisible: false,
            secondVisible: false,
            oldSecondVisible:false,
            thirdVisible: false,
            id: '',
            btnLoading: false,
            dateTime: ''
        })

    }
    getTableData = () => { //获取表格数据
        this.setState({
            boolean: true
        })
        getCollectSettingData({keyType:'PAYMENT'}).then(res => {
            if (res.data.code === 200) {
                const data1 = [];
                const data = res.data.data
                for (let i = 0; i < data.length; i++) {
                    data[i].key = i;
                    data1.push({
                        key: i + 1,
                        createTime: data[i].createTime,
                        id: data[i].id,
                        remark: data[i].remark,
                        status: data[i].status,
                        keyValue: data[i].keyValue,
                        type: data[i].type,
                        operator:data[i].operator,
                        updateTime: data[i].updatedTime,
                        keyName: data[i].keyName
                    })
                }
                this.setState({
                    tableData: data1,
                    boolean: false,
                })
            } else if (res.data.code == 800) {
                this.props.history.push({ pathname: "/" });
            }
        })
    }
    //表单值双向绑定
    fstaddonChange = (e) => {
        let InputName = e.target.name;
        let InputValue = e.target.value;
        this.setState({
            [InputName]: InputValue.replace(/\s+/g, ""),
        })
    }
    //获取日期
    getDataTime = (date, dateString) => {
        this.setState({
            dateTime: date
        })
    }
    //表单回显
    edit = (record) => {
        if (record.keyName == 'PAYMENT_LIMIT_AMOUNT') { //控制打开哪个弹窗
            this.setState({
                firstVisible: true,
            })
        } else if (record.keyName == 'PAYMENT_NEW_LOAN_LIMIT_COUNT') {
            this.setState({
                secondVisible: true,
                dateTime: record.keyValue
            })
        } else if(record.keyName == 'PAYMENT_OLD_LOAN_LIMIT_COUNT'){
            this.setState({
                oldSecondVisible: true,
                dateTime: record.keyValue
            })
        }
        this.setState({
            keyValue: record.keyValue,
            id: record.id,
            modelTitle: '编辑'
        })
    }
    //表单提交
    firsthandleOk = () => {
        const validator = new Schema(rules);
        validator.validate({ keyValue: this.state.keyValue }, (errors, fields) => {
            if (!errors) {
                //新增编辑同一接口
                this.setState({
                    btnloading: true
                })
                collectTargetForm( { id: this.state.id, keyValue: this.state.keyValue }).then(res => {
                    if (res.data.code == 200) {
                        this.setState({
                            btnloading: false
                        })
                        message.success(res.data.msg);
                        this.getTableData();
                        this.handleCancel()
                    } else {
                        message.error(res.data.msg)
                        this.setState({
                            btnloading: false
                        })
                    }
                })
            } else {
                message.error(errors[0].message)
            }
        })

    }
    //表单提交
    secondhandleOk = () => {
        const validator = new Schema(rules1);
        validator.validate({ keyValue: this.state.keyValue }, (errors, fields) => {
            if (!errors) {
                //新增编辑同一接口
                this.setState({
                    btnloading: true
                })
                collectTargetForm( { id: this.state.id, keyValue: this.state.keyValue }).then(res => {
                    if (res.data.code == 200) {
                        this.setState({
                            btnloading: false
                        })
                        message.success(res.data.msg);
                        this.getTableData();
                        this.handleCancel()
                    } else {
                        message.error(res.data.msg)
                        this.setState({
                            btnloading: false
                        })
                    }
                })
            } else {
                message.error(errors[0].message)
            }
        })

    }
    render() {
        const columns = [
            {
                title: '编号',
                dataIndex: 'key',
                key: 'key',
            },
            {
                title: '类别',
                dataIndex: 'remark',
                key: 'remark',
            },
            {
                title: '阀值',
                dataIndex: 'keyValue',
                key: 'keyValue',
                render: (value, record, ifCheck) => {
                    if (record.keyName == 'AUTO_PAY_SWITCH') {
                        return <Switch onChange={(ifCheck) => { this.statusComfirm(value, record, ifCheck) }} checked={record.keyValue == 1 ? true : false} />

                    }
                    else {
                        return value.replace(/(?=(\B)(\d{3})+$)/g, ',') || '——';
                    }

                }
            },
            {
                title: '最近更新时间',
                dataIndex: 'updateTime',
                key: 'updateTime',
                render: (value) => {
                    return value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '——'
                }
            },
            {
                title: '最近操作人',
                dataIndex: 'operator',
                key: 'operator',
            },
            {
                title: '操作', width: '16.5%', dataIndex: '', pid: 'pid', key: 'z',
                render: (text, record) =>
                    record.keyName !== 'AUTO_PAY_SWITCH' && <div>
                        <span>
                            <a className='edit_button' href="javascript:;" onClick={() => this.edit(record)}>编辑</a>
                        </span>
                    </div>

            },
        ];
        return (
            <div>
                {/* <Button type="primary" style={{
                    width: 170,
                    marginLeft: '4%',
                    height: 40,
                    float: 'right',
                    marginTop: '-60px',
                    marginBottom: '20px',
                }} onClick={this.showDialog.bind(this)} className="common_color">新增</Button> */}
                <Table columns={columns} dataSource={this.state.tableData} loading={this.state.boolean} pagination={false} />
                {/* 弹窗 */}
                <Modal
                    // destroyOnClose={true}
                    title={this.state.modelTitle}
                    visible={this.state.firstVisible}
                    onOk={this.showConfirm}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            Cancel
                        </Button>,
                        <Button key="submit" type="primary" loading={this.state.btnloading} onClick={this.showConfirm}>
                            Confirm
                        </Button>,
                    ]}
                >

                    <div className={'centerForm'}>
                        <label> {'每日放款额度'}</label>
                        <Input prefix="₦" suffix="分" style={{ display: 'flex', alignItems: 'center' }} name="keyValue" onChange={this.fstaddonChange} value={this.state.keyValue} maxLength={10} />
                    </div>
                </Modal>
                {/* 弹窗 */}
                <Modal
                    // destroyOnClose={true}
                    title={this.state.modelTitle}
                    visible={this.state.secondVisible}
                    onOk={this.showConfirm}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            Cancel
                        </Button>,
                        <Button key="submit" type="primary" loading={this.state.btnloading} onClick={this.showConfirm}>
                            Confirm
                        </Button>,
                    ]}
                >

                    <div className={'centerForm'}>
                        <label>新客自动放款单量:</label>
                        <Input suffix="单" style={{ display: 'flex', alignItems: 'center' }} name="keyValue" onChange={this.fstaddonChange} value={this.state.keyValue} maxLength={10} />
                    </div>
                </Modal>
                {/* 弹窗 */}
                {/* 弹窗 */}
                <Modal
                    // destroyOnClose={true}
                    title={this.state.modelTitle}
                    visible={this.state.oldSecondVisible}
                    onOk={this.showConfirm}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            Cancel
                        </Button>,
                        <Button key="submit" type="primary" loading={this.state.btnloading} onClick={this.showConfirm}>
                            Confirm
                        </Button>,
                    ]}
                >

                    <div className={'centerForm'}>
                        <label>老客自动放款单量:</label>
                        <Input suffix="单" style={{ display: 'flex', alignItems: 'center' }} name="keyValue" onChange={this.fstaddonChange} value={this.state.keyValue} maxLength={10} />
                    </div>
                </Modal>
                {/* 弹窗 */}
            </div>
        );
    }
}

export default withRouter(Tab1);