/*eslint-disable*/
import React from 'react';
import { Input,Button,Table,Modal,Pagination,Tooltip,message,Tabs,DatePicker } from 'antd';
import './index.css';
import axios from 'axios'
import {Link} from 'react-router-dom'
import moment from 'moment';
import $ from 'jquery';
import location_red from '../../../../public/img/collection_imgleft.png';
import right from '../../../../public/img/collection_imgright.png';
import {withRouter} from 'react-router-dom'
import EventEmitter from '../../../utils/events'
import { exportMethod } from '../../../utils/axios'

const dateFormat = 'YYYY-MM-DD'||undefined;
const { RangePicker } = DatePicker;
var collector = [];
const { TabPane } = Tabs;
var getSelect = [];
var selector = [];
var Collector = [];
var whitchshow = []
function callback(key) {
    console.log(key);
  }
  function formatMoney(s, type){
    if (/[^0-9\.]/.test(s))
      return "0";
    if (s == null || s == "null" || s == "")
      return "0";
    s = s.toString().replace(/^(\d*)$/, "$1.");
    s = (s + "00").replace(/(\d*\.\d\d)\d*/, "$1");
    s = s.replace(".", ",");
    var re = /(\d)(\d{3},)/;
    while (re.test(s))
      s = s.replace(re, "$1,$2");
    s = s.replace(/,(\d\d)$/, ".$1");
    if (type == 0) {
      var a = s.split(".");
      if (a[1] == "00") {
        s = a[0];
      }
    }
    s = s.replace(".", ",");
    return s;
  }
  function showTotal(total) {
      return `Total ${total} items`;
    }
 class collectionOrderManagement extends React.Component {
    constructor(props){
      super(props);
      this.state={
        number:'',
        searchVal:'',
        current:1,
        length:0,
        boolean:true,
        topboolean:true,
        from:'',
        to:'',
        pageSize:20,
        isshow:true,
        ishide:false,
        scdSelect:'',
        id:'',
        isToggleOn: true,
        isToggleOff:false,
        S1:'',
        S2:'',
        M1:'',
        M2:'',
        M3:'',
        M4:'',
        s1total:'',
        s2total:'',
        m1total:'',
        m2total:'',
        m3total:'',
        m4total:'',
        fstSelect:'',
        totalnum:'',
        todaynum:'',
        total_today:'total',
        isDisabled:false,
        btnWord:'export',
        mode: ['month', 'month'],
        value: [],
      }
      this.whichActive = this.whichActive.bind(this);
      this.whichotherActive = this.whichotherActive.bind(this);

    }
    //选择table触发
    handleChange = (pagination, filters, sorter) => {
      this.setState({
      });
    };
    whichActive() {
      this.setState({
        isToggleOn: true,
        isToggleOff:false,
        total_today:'total',
        s1total:this.state.s1total1,
        s2total:this.state.s2total1,
        m1total:this.state.m1total1,
        m2total:this.state.m2total1,
        m3total:this.state.m3total1,
        m4total:this.state.m4total1,
      });
      console.log(this.state.m2total)
    }
    whichotherActive() {
      this.setState({
        isToggleOff: true,
        isToggleOn:false,
        total_today:'today',
        s1total:this.state.s1today,
        s2total:this.state.s2today,
        m1total:this.state.m1today,
        m2total:this.state.m2today,
        m3total:this.state.m3today,
        m4total:this.state.m4today,
      });
      console.log(this.state.m2today)

    }
    rangeOnChange=(dates, dateStrings)=>{
      this.setState({ from: dateStrings[0],to:dateStrings[1] })
    }
    //页码改变时触发
    pageChange=(page)=> { 
      
    this.setState({
      current: page
      },()=>{
        this.searchClick()
      })
    
    } 
    handlePanelChange = (value, mode) => {
      this.setState({
        from:value[0],
        to:value[1],
        mode: [mode[0] === 'date' ? 'month' : mode[0], mode[1] === 'date' ? 'month' : mode[1]],
      });
    };
    showtable=()=>{
      let starttime = moment(this.state.from).format("YYYY-MM-DD")
      let endtime = moment(this.state.to).format("YYYY-MM-DD")

      this.setState({
        isshow:false,
        ishide:true
      })
      axios
        .post(`/api/collectionData/getCollectionDataTotal`,{
          overdueGrade:this.state.fstSelect,
          collectionUserid:this.state.id,
          beginTime: starttime,
          endTime: endtime,
          type:1
      })
      .then(res=>{
        if(res.data.code === 200){
        const  data1 = []
          let data = res.data.data.resultDate
          if(data.length<1){
            this.setState({
              topboolean:false
            })
          }else{
            for(let i=0; i<data.length;i++){
              data1.push({
                key: i,
                order:data[i].totalCountSum,
                amount: data[i].totalAmountSum/100,
                currentorder:data[i].curTotalCountSum,
                currentamount: data[i].curTotalAmountSum/100,
                transferorder: data[i].turnoverVolumeCountSum,
                transferamount: data[i].turnoverAmountSum/100,
                repaymentquantity: data[i].totalBillCollectionSum,
                repaymentamount:data[i].totalAmountDueSum/100,
                amountrate:data[i].paymentRecoveryRate,
                orderrate:data[i].orderNumberRecoveryRate,
                todayorder:data[i].todayCountSum,
                todayamount:data[i].todayAmountSum/100,
                todaytransfer:data[i].turnoverTodayCountSum,
                todaytransamount:data[i].turnoverTodayAmountSum/100,
                repaymentorder:data[i].totalCollectionSum,
                todayrepaymentamount:data[i].totalAmountsSum/100,
                id:data[i].id,
                totalExtendOrder:data[i].totalExtendOrder,
                totalExtendAmount:data[i].totalExtendAmount/100,
                todayExtendOrder:data[i].todayExtendOrder,
                todayExtendAmount:data[i]. todayExtendAmount/100,
              });
            }
          }
            this.setState({
            totalData:data1,
            topboolean:false
          })  
  
        }
        else if(res.data.code ==800){
          this.props.history.push({ pathname: "/"});
        }else{
  
        }
      })
    }
     //获取select
     select=()=>{

      axios
      .post(`/api/remindData/getCollectionUsers`,{
    })
    .then(res=>{
      if(res.data.code === 200){
        Collector=[]
      let data = res.data.data.resultDate
      for(let i=0;i<data.length;i++ ){
        if(i==0){Collector.push(<option value='0'></option> )
      }
        Collector.push(       
          <option value={data[i].id}>{data[i].userName}</option>
      )
      // console.log('select',collector)
      this.setState({
        Collector:Collector
      })
      }
      }else if(res.data.code ==800){
        this.props.history.push({ pathname: "/"});
      }
      else{
      }
    })
    }
    //搜索
    searchClick=()=>{
      this.setState({
        boolean:true,
        topboolean:true
      })
      let starttime = moment(this.state.from).format("YYYY-MM-DD")
      let endtime = moment(this.state.to).format("YYYY-MM-DD")
      // console.log(starttime)
      axios
        .post(`/api/collectionData/getCollectionData`,{
          pageSize: this.state.pageSize,
          current: this.state.current,
          beginTime: starttime,
          endTime: endtime,
          overdueGrade:this.state.fstSelect,
          collectionUserid:this.state.scdselect,
          type:2,
      })
      .then(res=>{
        if(res.data.code === 200){
        const  data1 = []
          let data = res.data.data.resultDate
          if(data.length < 1){
            this.setState({
              boolean:false
            })
          }else{
            for(let i=0; i<data.length;i++){
              data1.push({
                key: i,
                Collector:data[i].userName,
                grade: data[i].overdueGrade,
                Order:data[i].totalCount,
                Amount: data[i].totalAmount/100,
                Currentorder: data[i].curTotalCount,
                Currentamount: data[i].curTotalAmount/100,
                Transferorder: data[i].turnoverVolumeCount,
                Transferamount:data[i].turnoverAmount/100,
                Repaymentorder:data[i].totalBillCollection,
                Repaymentamount:data[i].totalAmountDue/100,
                Amountreco:data[i].paymentRecoveryRate,
                Orderreco:data[i].orderNumberRecoveryRate,
                Allocateorder:data[i].todayCount,
                Allocateamount:data[i].todayAmount/100,
                Todaytran:data[i].turnoverTodayCount,
                Todayamt:data[i].turnoverTodayAmount/100,
                Todayor:data[i].totalCollection,
                Todayam:data[i].totalAmounts/100,
                id:data[i].id,
                uid:data[i].uid,
                totalExtendOrder:data[i].totalExtendOrder,
                totalExtendAmount:data[i].totalExtendAmount/100,
                todayExtendOrder:data[i].todayExtendOrder,
                todayExtendAmount:data[i]. todayExtendAmount/100,
              });
            }
          }
          
          console.log(data1)
          this.setState({
            tableData:data1,
            total:res.data.data.page.total,
            boolean:false
          })  
  
        }
        else if(res.data.code ==800){
          this.props.history.push({ pathname: "/"});
        }else{
  
        }
      })

      axios
        .post(`/api/collectionData/getCollectionDataTotal`,{
          overdueGrade:this.state.fstSelect,
          collectionUserid:this.state.id,
          beginTime: starttime,
          endTime: endtime,
          type:1
      })
      .then(res=>{
        if(res.data.code === 200){
        const  data1 = []
          let data = res.data.data.resultDate
          if(data.length < 1){
            this.setState({
              topboolean:false
            })
          }else{
            for(let i=0; i<data.length;i++){
              data1.push({
                key: i,
                order:data[i].totalCountSum,
                amount: data[i].totalAmountSum/100,
                currentorder:data[i].curTotalCountSum,
                currentamount: data[i].curTotalAmountSum/100,
                transferorder: data[i].turnoverVolumeCountSum,
                transferamount: data[i].turnoverAmountSum/100,
                repaymentquantity: data[i].totalBillCollectionSum,
                repaymentamount:data[i].totalAmountDueSum/100,
                amountrate:data[i].paymentRecoveryRate,
                orderrate:data[i].orderNumberRecoveryRate,
                todayorder:data[i].todayCountSum,
                todayamount:data[i].todayAmountSum/100,
                todaytransfer:data[i].turnoverTodayCountSum,
                todaytransamount:data[i].turnoverTodayAmountSum/100,
                repaymentorder:data[i].totalCollectionSum,
                todayrepaymentamount:data[i].totalAmountsSum/100,
                id:data[i].id,
                todayExtendOrder:data[i].todayExtendOrder,
                todayExtendAmount:data[i]. todayExtendAmount/100,
                totalExtendOrder:data[i].totalExtendOrder,
                totalExtendAmount:data[i].totalExtendAmount/100,
              });
            }
          }
            this.setState({
            totalData:data1,
            topboolean:false
          })  
  
        }
        else if(res.data.code ==800){
          this.props.history.push({ pathname: "/"});
        }else{
  
        }
      })
    }
    downExcel=()=>{
      this.state.isDisabled = true;
      this.export();
      let count = 60;
      let timer = setInterval(()=>{
        if(count <=0){
          this.setState({
            btnWord:'export',
            isDisabled:false
          })
          clearInterval(timer);
        }else{
          count --;
          this.setState({
            btnWord:count +'s'
          })
        }
      },1000)
    }
    export=()=>{
      let starttime = moment(this.state.from).format("YYYY-MM-DD")
      let endtime = moment(this.state.to).format("YYYY-MM-DD")
      let myObj = {
        method: 'post',
        url:`/api/collectionData/outExcelCollectionData`,
        data:{
          beginTime: starttime,
          endTime: endtime,
          overdueGrade:this.state.fstSelect,
          collectionUserid:this.state.id,
          type:1,
        },
        }
        exportMethod(myObj)
    }

    //重置按钮
    reloadClick(e){
      this.setState({
        fstSelect:'',
        scdSelect:'',
        fouSelect:'',
        modalscdSelect:'',
        from:undefined,
        to:undefined,
        topboolean:true,
        boolean:true,
        id:''
      },()=>{
        this.onloadTable()
        this.showtable()
      });
     
    }

    modelShow=()=>{

      this.setState({
        fstvisible:true,
      })
      axios
        .post(`/api/remindData/getCollectionUsers`,{
      })
      .then(res=>{
        if(res.data.code === 200){
          collector=[]
        let data = res.data.data.resultDate
        for(let i=0;i<data.length;i++ ){
          collector.push(       
            <option data-userId={data[i].id} value={data[i].userName}></option>
        )
        // console.log('select',collector)
        this.setState({
          collector:collector
        })
        }
        }else if(res.data.code ==800){
          this.props.history.push({ pathname: "/"});
        }
        else{
        }
      })
    }

  componentDidMount(){
    this.setState({
      from:moment().startOf('month'),
      to: moment().endOf('month'),
    },()=>{
      this.onloadTable()
    })
     
      this.select()
  }
  modelselectOne(e){
    var value = e.target.value;
    this.setState({
      modalscdSelect:value,
    });
  }

 
  //table初始化
  onloadTable=()=>{
    console.log('aaaaaaa',this.state.from)

    let starttime = moment(this.state.from).format("YYYY-MM-DD")
    let endtime = moment(this.state.to).format("YYYY-MM-DD")
    // console.log(starttime)
    axios
      .post(`/api/collectionData/getCollectionData`,{
        pageSize: this.state.pageSize,
        current: this.state.current,
        beginTime: starttime,
        endTime: endtime,
        type:1,
    })
    .then(res=>{
      if(res.data.code === 200){
      const  data1 = []
        let data = res.data.data.resultDate
        for(let i=0; i<data.length;i++){
          data1.push({
            key: i,
            Collector:data[i].userName,
            grade: data[i].overdueGrade,
            Order:data[i].totalCount,
            Amount: data[i].totalAmount/100,
            Currentorder: data[i].curTotalCount,
            Currentamount: data[i].curTotalAmount/100,
            Transferorder: data[i].turnoverVolumeCount,
            Transferamount:data[i].turnoverAmount/100,
            Repaymentorder:data[i].totalBillCollection,
            Repaymentamount:data[i].totalAmountDue/100,
            Amountreco:data[i].paymentRecoveryRate,
            Orderreco:data[i].orderNumberRecoveryRate,
            Allocateorder:data[i].todayCount,
            Allocateamount:data[i].todayAmount/100,
            Todaytran:data[i].turnoverTodayCount,
            Todayamt:data[i].turnoverTodayAmount/100,
            Todayor:data[i].totalCollection,
            Todayam:data[i].totalAmounts/100,
            id:data[i].id,
            uid:data[i].uid,
            todayExtendOrder:data[i].todayExtendOrder,
            todayExtendAmount:data[i]. todayExtendAmount/100,
            totalExtendOrder:data[i].totalExtendOrder,
            totalExtendAmount:data[i].totalExtendAmount/100,
          });
        }
        console.log(data1)
        this.setState({
         
          tableData:data1,
          total:res.data.data.page.total,
          boolean:false
        })  

      }
      else if(res.data.code ==800){
        this.props.history.push({ pathname: "/"});
      }else{

      }
    })
  }
  fsthandleOk=()=>{
    console.log(this.state.id)
    axios
      .post(`/api/remindData/changeOverdueGrade`,{
        id:this.state.id,
        overdueGrade: this.state.fstSelect,
    })
    .then(res=>{
      if(res.data.code === 200){
        this.setState({
          boolean:true
        })
        this.onloadTable()
      }
      else if(res.data.code ==800){
        this.props.history.push({ pathname: "/"});
      }else{

      }
    })
    this.setState({
      fstvisible: false,
      fstSelect:'',
      scdSelect:'',
      fouSelect:'',
      modalscdSelect:'',
      from:undefined,
      to:undefined,
      id:''
    });
  }

    handleCancel = e => {
      console.log(e);
      this.setState({
        fstvisible: false,
       
      });
    };
    todetail=(record)=>{
      window.location='/#/collection/getCollectionOrderManagement/month?id='+record.id
      let test = [{path: '/collection/getCollectionOrderManagement/month',key: `/collection/getCollectionOrderManagement/month${record.id}`,number: `${record.id}`}]
      EventEmitter.trigger('btn', test[0])
      EventEmitter.trigger('idanduid', test[0].number)  
      localStorage.id = record.id
      // window.location.href='/#/collection/getCollectionOrderManagement/month?id='+record.id+'&uid='+record.uid
    }
    collector =(params)=>{
      console.log('aaaaaaaaaaaaaa',params)
      var va = params.grade
        if (va==1){
            return <span style={{color:'black'}}>S1-Old</span>
        }else if (va==2){
            return <span style={{color:'black'}}>S2-Old</span>
        }else if (va==3){
            return <span style={{color:'black'}}>M1-Old</span>
        }else if (va==4){
            return <span style={{color:'black'}}>M2</span>
        }else if (va==5){
            return <span style={{color:'black'}}>M3</span>
        }else if (va==6){
            return <span style={{color:'black'}}>M4</span>
        }else if (va==11){
            return <span style={{color:'black'}}>S1-New</span>
        }else if (va==12){
            return <span style={{color:'black'}}>S2-New</span>
        }else if (va==13){
            return <span style={{color:'black'}}>M1-New</span>
        }else {
            return <span style={{color:'black'}}></span>
        }
      }
      amount =(params)=>{
        var num = params.Amount;
        num = num + '';
        if(num.indexOf(".")!=-1){
          return num;
          }else{
            var str = "";
            for(var i=num.length- 1,j=1;i>=0;i--,j++){
                if(j%3==0 && i!=0){//每隔三位加逗号，过滤正好在第一个数字的情况
                    str+=num[i]+",";//加千分位逗号
                    continue;
                }
                str+=num[i];//倒着累加数字
            }
            return str.split('').reverse().join("");
          }
      }
      amount1 =(params)=>{
        var num = params.Currentamount;
        num = num + '';
        if(num.indexOf(".")!=-1){
          return num;
          }else{
            var str = "";
            for(var i=num.length- 1,j=1;i>=0;i--,j++){
                if(j%3==0 && i!=0){//每隔三位加逗号，过滤正好在第一个数字的情况
                    str+=num[i]+",";//加千分位逗号
                    continue;
                }
                str+=num[i];//倒着累加数字
            }
            return str.split('').reverse().join("");
          }
      }
      amount2 =(params)=>{
        var num = params.Transferamount;
        num = num + '';
        if(num.indexOf(".")!=-1){
          return num;
          }else{
            var str = "";
            for(var i=num.length- 1,j=1;i>=0;i--,j++){
                if(j%3==0 && i!=0){//每隔三位加逗号，过滤正好在第一个数字的情况
                    str+=num[i]+",";//加千分位逗号
                    continue;
                }
                str+=num[i];//倒着累加数字
            }
            return str.split('').reverse().join("");
          }
      }
      amount3 =(params)=>{
        var num = params.Repaymentamount;
        num = num + '';
        if(num.indexOf(".")!=-1){
          return num;
          }else{
            var str = "";
            for(var i=num.length- 1,j=1;i>=0;i--,j++){
                if(j%3==0 && i!=0){//每隔三位加逗号，过滤正好在第一个数字的情况
                    str+=num[i]+",";//加千分位逗号
                    continue;
                }
                str+=num[i];//倒着累加数字
            }
            return str.split('').reverse().join("");
          }
      }
      amount4 =(params)=>{
        var num = params.Allocateamount;
        num = num + '';
        if(num.indexOf(".")!=-1){
          return num;
          }else{
            var str = "";
            for(var i=num.length- 1,j=1;i>=0;i--,j++){
                if(j%3==0 && i!=0){//每隔三位加逗号，过滤正好在第一个数字的情况
                    str+=num[i]+",";//加千分位逗号
                    continue;
                }
                str+=num[i];//倒着累加数字
            }
            return str.split('').reverse().join("");
          }
      }
      amount5 =(params)=>{
        var num = params.Todayamt;
        num = num + '';
        if(num.indexOf(".")!=-1){
          return num;
          }else{
            var str = "";
            for(var i=num.length- 1,j=1;i>=0;i--,j++){
                if(j%3==0 && i!=0){//每隔三位加逗号，过滤正好在第一个数字的情况
                    str+=num[i]+",";//加千分位逗号
                    continue;
                }
                str+=num[i];//倒着累加数字
            }
            return str.split('').reverse().join("");
          }
      }
      amount6 =(params)=>{
        var num = params.Todayam;
        num = num + '';
        if(num.indexOf(".")!=-1){
          return num;
          }else{
            var str = "";
            for(var i=num.length- 1,j=1;i>=0;i--,j++){
                if(j%3==0 && i!=0){//每隔三位加逗号，过滤正好在第一个数字的情况
                    str+=num[i]+",";//加千分位逗号
                    continue;
                }
                str+=num[i];//倒着累加数字
            }
            return str.split('').reverse().join("");
          }
      }
      
      
    redistribution=()=>{
      this.setState({
        thdvisible: true,
        });
    }

    selectFst=(e)=>{
      var value = e.target.value;
      this.setState({
        fstSelect:value,
      });
    }
    selectThd=()=>{

    }



        //选择collector
        selectScd(e){
          var value = e.target.value;
          this.setState({
            scdselect:value,
          });
        }
      onShowSizeChange=(current, pageSize)=>{

        this.setState({
          pageSize: pageSize,
          boolean:true
        },()=>{
          this.onloadTable()
        })
    }
        //选择collector
        selectscdd(e){
          var value = e.target.value;
          var userInfo = $("#iptt").val();
          var getUserId = $("option[value='" + userInfo + "']").data("userid");
          $("input[name='userId']").val(getUserId);
          this.setState({
            scdSelect:value,
            id:getUserId
          });
        }
    render() {
      const { selectedRowKeys } = this.state;
      const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowkeys,selectedRows) =>{
          console.log(selectedRowkeys,selectedRows)
          this.setState({ selectedRowKeys:selectedRowkeys, });
        },
        columnWidth:40
        
      };

      
      const totalcolumns = [
        {
          title: 'Total Allocate Order',
          dataIndex: 'order',
          key: 'order',
         
        },
        {
          title: 'Total Allocate Amount',
          dataIndex: 'amount',
          key: 'amount',
          
        },
        {
          title: 'Current Order Total',
          dataIndex: 'currentorder',
          key: 'currentorder',
     
        },
        {
          title: 'Current Amount Total',
          dataIndex: 'currentamount',
          key: 'currentamount',
   
        },
        // {
        //   title: 'Total Transfer Order',
        //   dataIndex: 'transferorder',
        //   key: 'transferorder',
 
        // },
        // {
        //   title: 'Total Transfer Amount',
        //   dataIndex: 'transferamount',
        //   key: 'transferamount',
  
        // },
        {
          title: 'Total repayment quantity',
          dataIndex: 'repaymentquantity',
          key: 'repaymentquantity',
        
        },
        {
          title: 'Total repayment amount',
          dataIndex: 'repaymentamount',
          key: 'repaymentamount',
        
        },
        {
          title: 'Amount Recovery Rate',
          dataIndex: 'amountrate',
          key: 'amountrate',
      
        },
        {
          title: 'Order Recovery Rate',
          dataIndex: 'orderrate',
          key: 'orderrate',

        },
        {
          title: 'Total extend order',
          dataIndex: 'totalExtendOrder',
          key: 'totalExtendOrder',

        },
        {
          title: 'Total extend fee',
          dataIndex: 'totalExtendAmount',
          key: 'totalExtendAmount',

        },
        {
          title: 'Today Allocate Order',
          dataIndex: 'todayorder',
          key: 'todayorder',
     
        },
        {
          title: 'Today Allocate Amount',
          dataIndex: 'todayamount',
          key: 'todayamount',
     
        },
      //   {
      //     title: 'Today Transfer Order',
      //     dataIndex: 'todaytransfer',
      //     key: 'todaytransfer',
      // },
      //   {
      //     title: 'Today Transfer Amount',
      //     dataIndex: 'todaytransamount',
      //     key: 'todaytransamount',
      //   },
        {
          title: 'Today Repayment Order',
          dataIndex: 'repaymentorder',
          key: 'repaymentorder',
        },
        {
          title: 'Today Repayment Amount',
          dataIndex: 'todayrepaymentamount',
          key: 'todayrepaymentamount',
        },
        {
          title: 'Today extend order',
          dataIndex: 'todayExtendOrder',
          key: 'todayExtendOrder',
        },
        {
          title: 'Today extend fee',
          dataIndex: 'todayExtendAmount',
          key: 'todayExtendAmount',
        },
      ];



      const columns = [
        {
          title: 'Collector',
          dataIndex: 'Collector',
          key: 'Collector',
          fixed: 'left',
          width:120,
        },
        {
          title: 'Overdue grade',
          dataIndex: 'grade',
          key: 'grade',
          width:100,
          render:(text,record) =>  this.collector(record)
        },
        {
          title: 'Total Allocate Order',
          dataIndex: 'Order',
          key: 'Order',
          width:110,
        },
        {
          title: 'Total Allocate Amount',
          dataIndex: 'Amount',
          key: 'Amount',
          width:130,
          render:(text,record) =>  this.amount(record)
        },
        {
          title: 'Current Order Total',
          dataIndex: 'Currentorder',
          key: 'Currentorder',
          width:100,
        },
        {
          title: 'Current Amount Total',
          dataIndex: 'Currentamount',
          key: 'Currentamount',
          width:130,
          render:(text,record) =>  this.amount1(record)

        },
        // {
        //   title: 'Total Transfer Order',
        //   dataIndex: 'Transferorder',
        //   key: 'Transferorder',
        //   width:100,
        // },
        // {
        //   title: 'Total Transfer Amount',
        //   dataIndex: 'Transferamount',
        //   key: 'Transferamount',
        //   render:(text,record) =>  this.amount2(record),
        //   width:100,
        // },
        {
          title: 'Total Repayment Order',
          dataIndex: 'Repaymentorder',
          key: 'Repaymentorder',
          width:120,
        },
        {
          title: 'Total Repayment Amount',
          dataIndex: 'Repaymentamount',
          key: 'Repaymentamount',
          width:120,
          render:(text,record) =>  this.amount3(record)

        },
        {
          title: 'Amount Recovery Rate',
          dataIndex: 'Amountreco',
          key: 'Amountreco',
          width:120,
        },
        {
          title: 'Order Recovery Rate',
          dataIndex: 'Orderreco',
          key: 'Orderreco',
          width:120,
        },
        {
          title: 'Today Allocate Order',
          dataIndex: 'Allocateorder',
          key: 'Allocateorder',
          width:120,
      },
        {
          title: 'Today Allocate Amount',
          dataIndex: 'Allocateamount',
          key: 'Allocateamount',
          width:100,
          render:(text,record) =>  this.amount4(record)

        },
        {
          title: 'Total extend order',
          dataIndex: 'totalExtendOrder',
          key: 'totalExtendOrder',
          width:100,
        },
        {
          title: 'Total extend fee',
          dataIndex: 'totalExtendAmount',
          key: 'totalExtendAmount',
          width:100,
        },
        // {
        //   title: 'Today Transfer Order',
        //   dataIndex: 'Todaytran',
        //   key: 'Todaytran',
        //   width:100,
        // },
        // {
        //   title: 'Today Transfer Amount',
        //   dataIndex: 'Todayamt',
        //   key: 'Todayamt',
        //   width:100,
        //   render:(text,record) =>  this.amount5(record)

        // },
        {
          title: 'Today Repayment Order',
          dataIndex: 'Todayor',
          key: 'Todayor',
          width:120,
        },
        {
          title: 'Today Repayment Amount',
          dataIndex: 'Todayam',
          key: 'Todayam',
          width:120,
          render:(text,record) =>  this.amount6(record)
        },
        {
          title: 'Today extend order',
          dataIndex: 'todayExtendOrder',
          key: 'todayExtendOrder',
          width:100,
        },
        {
          title: 'Today extend fee',
          dataIndex: 'todayExtendAmount',
          key: 'todayExtendAmount',
          width:100,
        },
        {
          title: 'operating',
          dataIndex: 'operating',
          key: 'operating',
          width:104,
          id:'id',
          // uid:'uid',
          fixed: 'right',
          render: (text, record, index) => (<span style={{ display: 'flex' , flexDirection: 'column', justifyContent:'center',}}> 
            {/* <Button   className="no_margin" onClick={() => this.todetail(record)}>details</Button> */}
            <a onClick={() => this.todetail(record)} style={this.state.detailsbutton} >detail</a>
            </span>)
        },
      ];
      return (
        <div className="credit_order" style={{overflow:'hidden'}}>
            <div className="collection_top" style={{display:'none'}}>
                <div className="collection_top_left">
                    <div className="collection_top_left_top">
                        <p>Overdue Quantity</p>
                    </div>
                    <div className="collection_top_left_bot">
                         <div className="collection_top_left_bot_left">
                            <div style={{marginLeft:40,marginRight:44}}><img src={location_red}/></div>
                            <div>
                                <p className="p_number">{this.state.totalnum}</p>
                                {/* <p>{this.state.number}</p> */}
                                <span className="span_number">Total overdue quantity</span>
                            </div>
                        </div>
                        <p style={{width:2,height:104,background:'rgba(243,247,253,1)',marginTop:52,marginBottom:52}}></p>
                        <div className="collection_top_left_bot_right">
                        <div style={{marginLeft:40,marginRight:44}}><img src={location_red}/></div>
                            <div>
                                <p className="p_number">{this.state.todaynum}</p>
                                {/* <p>{this.state.number1}</p> */}
                                <span className="span_number">Today overdue quantity</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="collection_top_right">
                    <div className="collection_top_right_top">
                        <p>Overdue Grade</p>
                    </div>
                    <div className="collection_top_right_bottom">
                        <div className="collection_top_right_bot_left out_div">
                            <div className="collection_top_right_bot_left_one">
                                <span className="circle"></span>
                                <span className="right_span collection_adjust">S1</span>
                                <span className="span_number">{this.state.S1} Days</span>
                            </div>
                            <div className="collection_top_right_bot_left_two">
                                <span className="circle" style={{border:'4px solid rgba(69,186,163,1)'}}></span>
                                <span className="right_span collection_adjust" >S2</span>
                                <span className="span_number">{this.state.S2} Days</span>
                            </div>
                            <div className="collection_top_right_bot_left_three">
                                <span className="circle"  style={{border:'4px solid rgba(114,211,193,1)'}}></span>
                                <span className="right_span collection_adjust" >M1</span>
                                <span className="span_number">{this.state.M1} Days</span>
                            </div>
                        </div>
                        <div className="collection_top_right_bot_left out_div" >
                            <div className="collection_top_right_bot_left_one">
                                <span className="circle"></span>
                                <span className="right_span collection_adjust" >M2</span>
                                <span className="span_number">{this.state.M2} Days</span>
                            </div>
                            <div className="collection_top_right_bot_left_two">
                                <span className="circle" style={{border:'4px solid rgba(69,186,163,1)'}}></span>
                                <span className="right_span collection_adjust" >M3</span>
                                <span className="span_number">{this.state.M3} Days</span>
                            </div>
                            <div className="collection_top_right_bot_left_three">
                                <span className="circle"  style={{border:'4px solid rgba(114,211,193,1)'}}></span>
                                <span className="right_span collection_adjust" >M4</span>
                                <span className="span_number">{this.state.M4}+ Days</span>
                            </div>
                        </div>
                        <div className="collection_top_right_bot_right">
                        <div className='circlr_img' ><img src={right}/></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="collection_mid" style={{display:'none'}}>
                <div className="collection_mid_top_c" >
                    <div className="collection_mid_top_left">
                        <p >Statistics by Overdue Grade</p>
                    </div>
                    <div className="collection_mid_top_right">
                        <div className="right_btn">
                            <span className={`both_button ${this.state.isToggleOn ? 'active' : ''}`} onClick={this.whichActive}>Total</span>
                            <span className={`both_button ${this.state.isToggleOff ? 'active' : ''}`} onClick={this.whichotherActive}>Data of today</span>
                        </div>
                    </div>
                </div>
                <div className="collection_mid_bot">
                    <div className="collection_mid_bot_div">
                        <p className="p_white">{this.state.s1total}</p>
                        <span style={{color:'#fff'}}>S1 {this.state.total_today}</span>
                    </div>
                    <div className="collection_mid_bot_div" style={{background:'rgba(69,186,163,1)'}}>
                        <p className="p_white">{this.state.s2total}</p>
                        <span style={{color:'#fff'}}>S2 {this.state.total_today}</span>
                    </div>
                    <div className="collection_mid_bot_div" style={{background:'rgba(114,211,193,1)'}}>
                        <p className="p_white">{this.state.m1total}</p>
                        <span style={{color:'#fff'}}>M1 {this.state.total_today}</span>
                    </div>
                    <div className="collection_mid_bot_div" style={{background:'rgba(149,239,222,1)'}}>
                        <p className="p_number">{this.state.m2total}</p>
                        <span>M2 {this.state.total_today}</span>
                    </div>
                    <div className="collection_mid_bot_div" style={{background:'rgba(201,242,234,1)'}}>
                        <p className="p_number">{this.state.m3total}</p>
                        <span>M3 {this.state.total_today}</span>
                    </div>
                    <div className="collection_mid_bot_div" style={{background:'rgba(225,249,244,1)'}}>
                        <p className="p_number">{this.state.m4total}</p>
                        <span>M4 {this.state.total_today}</span>
                    </div>
                </div>
            </div>

          <div className="content_mid">
            <div className="mid_bot">
            <div className="mid_left" style={{width:'30%'}}>
                <span className="description left">Overdue Grade:</span>
                <select className="form_select" value={this.state.fstSelect} onChange={this.selectFst.bind(this)}>
                  <option value="">all</option>
                    <option value='1'>S1-Old</option>
                    <option value='2'>S2-Old</option>
                    <option value='3'>M1-Old</option>
                    <option value='4'>M2</option>
                    <option value='5'>M3</option>
                    <option value='6'>M4</option>
                    <option value='11'>S1-New</option>
                    <option value='12'>S2-New</option>
                    <option value='13'>M1-New</option>
                </select>
              </div>
              <div className="mid_mid" style={{width:'30%',flexShrink:2,marginLeft:'5%',marginRight:'5%'}}>
                <div className="rangepick">
                    <span className="description mid_span">Created time:</span>
                    {/* <RangePicker
                       style={{width: 290,}}
                      ranges={{
                      Today: [moment(), moment()],
                      'This Month': [moment().startOf('month'), moment().endOf('month')],}}
                      
                      showTime
                      format={dateFormat}
                      onChange={this.rangeOnChange}
                      value={this.state.from===undefined||this.state.to===undefined||this.state.from===""||this.state.to===""?null:[moment(this.state.from, dateFormat), moment(this.state.to, dateFormat)]}
                    /> */}
                        <RangePicker
                      placeholder={['Start month', 'End month']}
                      format="YYYY-MM"
                      value={this.state.from===undefined||this.state.to===undefined||this.state.from===""||this.state.to===""?null:[moment(this.state.from, dateFormat), moment(this.state.to, dateFormat)]}
                      mode={this.state.mode}
                      onPanelChange={this.handlePanelChange}
      />
                </div>
              </div>

              <div className="mid_right" style={{width:'30%'}}>
                  <span className="description right">Collector:</span>
                  <select className="form_select" style={{width:290}} value={this.state.scdselect} onChange={this.selectScd.bind(this)}>
                    {Collector}
                  </select>
                  {/* <input list='browsers' placeholder='all' className="form_select_mohu" id={this.state.id} id="ipt" value={this.state.scdSelect} onChange={this.selectscd.bind(this)}/>
                  <datalist id="browsers">
                    {collector}
                  </datalist> */}
              </div>
              
            </div>
              <div className="bottom">
                <Button type="primary" style={{width:170,marginRight:20,height:40,border:'none'}} className="common_color" onClick={this.searchClick.bind(this)}>Search</Button>
                <Button type="primary" style={{width:170,height:40,marginRight:20,border:'none'}}className="common_color_scd" onClick={this.reloadClick.bind(this)}>Refresh</Button>
                {/*<Button type="primary" style={{width:170,height:40,marginRight:20,border:'none'}} className="common_color_thd"onClick={this.modelShow.bind(this)}>Reset Grade</Button>*/}
                <Button type="primary" style={{width:170,height:40,marginRight:20,border:'none',backgroundColor:this.state.isDisabled?'':'#009688'}}  onClick={this.downExcel} disabled={this.state.isDisabled}>{this.state.btnWord}</Button>
              </div>    
            </div>
            <div className="content_bot" style={{textAlign:'center',marginBottom:40}}>
                <p className="content_bot_p">Total Collection Data</p>
                {this.state.isshow&&
                 <Button type="primary" style={{width:170,height:40,border:'none'}}className="common_color_scd" onClick={this.showtable}>Total</Button>
                 }
               {this.state.ishide&&
               <Table columns={totalcolumns} dataSource={this.state.totalData} loading={this.state.topboolean} onChange={this.handleChange}  pagination={false} scroll={{x:'100%'}}/>
              }
               
            </div>

         
          <Modal
          title="Reset"
          visible={this.state.fstvisible}
          onOk={this.fsthandleOk}
          // width={700}
          onCancel={this.handleCancel}
        >

        <span style={this.state.inptdis}  className="long">Collector</span>
        <input list='browsers' style={{marginLeft:56,width:279}} placeholder='all' className="form_select_mohu" id="iptt" value={this.state.scdSelect} onChange={this.selectscdd.bind(this)}/>
        <datalist id="browsers">
          {collector}
        </datalist>

        <br/>
        <div className="model_last">
        <span className="description left"  className="long">Overdue Grade:</span>
                <select className="form_select" style={{width:276,marginLeft:10}}  value={this.state.fstSelect} onChange={this.selectFst.bind(this)}>
                  <option value="0">S0</option>
                  <option value="1">S1</option>
                  <option value="2">S2</option>
                  <option value="3">M1</option>
                  <option value="4">M2</option>
                  <option value="5">M3</option>
                  <option value="6">M4</option>
                </select>
        </div>
        </Modal>
          <Table columns={columns} scroll={{x:2350,y:700}} dataSource={this.state.tableData} rowKey={record=>record.id} loading={this.state.boolean} onChange={this.handleChange} rowSelection={rowSelection} pagination={false} />


        <Pagination
         showSizeChanger                          //展示改变pagesize样式
         onShowSizeChange={this.onShowSizeChange} //pagesize改变时回调函数
         pageSizeOptions={["10","50","100"]}   //设置pagesize区间
         defaultCurrent={1} 
         current={this.state.current}
         total={this.state.total} 
         onChange={this.pageChange} 
         pageSize={this.state.pageSize}
         showTotal={showTotal}
         style={{textAlign:'right',marginTop:20}}
          />
        </div>
      );
    }
  }
  export default withRouter(collectionOrderManagement)