// 使用自己封装的api
import request from './request'
export function getsmsMarketData(data) { //smsManage 页获取数据
    return request({
      url: '/sms/marketing/list',
      method: 'post',
      data
    })
}
export function submitCsv(data) { //smsManage 页上传csv表单
    return request({
      url: '/sms/marketing/upload/csv',
      method: 'post',
      data
    })
}
export function getSmsData(data) { //smsdata 页获取数据
    return request({
      url: '/sms/marketing/total/list',
      method: 'post',
      data
    })
}
export function getSmsTotal(data) { //smsdata 页获取数据
    return request({
      url: '/sms/marketing/total',
      method: 'post',
      data
    })
}



















