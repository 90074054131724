/*eslint-disable*/
import React from 'react';
import { Input,Button,Table,Modal,Pagination,DatePicker,Tooltip,message,Radio,Upload,Icon } from 'antd';
import './index.css';
import axios from 'axios'
import moment from 'moment';
import {withRouter} from 'react-router-dom'

function showTotal(total) {
  return `Total ${total} items`;
}
const { RangePicker } = DatePicker;
var Select = []
var getselect = []
const { Search } = Input;
const dateFormat = 'YYYY-MM-DD'||undefined;
const DateFormat = 'YYYY-MM-DD HH:mm:ss'||undefined;
class SystemMessage extends React.Component{
    constructor(props){
        super(props);
        this.state={
          filteredInfo: null,
          sortedInfo: null,
          data:[],
          tableData:[],
          searchVal:'',
          radioValue:1,
          current:1,
          total:1,
          pageSize:10,
          boolean:true,
          ishide:false,
          isshow:true,
          modalscdinput:'',
          fsttextarea:'',
          modalscdSelect:0,
          modalthdSelect:0,
          fileList: [],
          Import:false
        }
        
      }
 //获取select
 
 getselect=()=>{
  axios
  .post(`/api/appshadow/findAppShadowList`,{

})
.then(res=>{
   Select=[]
  if(res.data.code === 200){
    for(let i=0;i<res.data.data.list.length;i++ ){
      
        if(i==0){
          Select.push(<option value=''>please select one</option> )
          }
          Select.push(       
          <option value={res.data.data.list[i].appCode}>{res.data.data.list[i].appCode}</option>
      )
      
    this.setState({
      Select:Select
    })
    }
  }else if(res.data.code ==800){
    this.props.history.push({ pathname: "/"});
  }else{
  }
})
}
    //设置时间
      rangeOnChange=(dates, dateStrings)=>{
      this.setState({ from: dateStrings[0],to:dateStrings[1] })
    }
    onShowSizeChange=(current, pageSize)=>{
      this.setState({
        pageSize: pageSize
      },()=>{
        this.secondTable()
      })
  }
    dateonChange = (date, dateString) =>{

      this.setState({ datepiker:dateString})
    }
      reloadClick(e){
        this.setState({
          from:undefined,
          to:undefined,
          searchVal:'',
          fstSelect:'',
          scdSelect:'',
          thdSelect:'',
          boolean:true
        },()=>{
          this.secondTable()
        });
        
      }
      radioOnChange = e => {
        this.setState({
          radioValue: e.target.value,
        });
      };
          //输入框
    searchIpt(e){
        var value = e.target.value;
        this.setState({ searchVal: value.replace(/\s+/g,"") })
      }

      //delete按钮
      delete=(record)=>{
        this.setState({
          thdvisible:true,
          thdmodalinpt:record.id
        })
      }
      //status下拉框
      selectFst=(e)=>{
        var value = e.target.value;
        this.setState({ fstSelect: value })
      }
      //apptype
      selectScd=(e)=>{
        var value = e.target.value;
        this.setState({ scdSelect: value })
      }
      //pushUser
      selectThd=(e)=>{
        var value = e.target.value;
        this.setState({ thdSelect: value })
      }
      //modal内pushUser
      modalselectThd=(e)=>{
        var value = e.target.value;
        if(value=='9'){
          this.setState({
            Import:true
          })
        }else{
          this.setState({
            Import:false
          })
        }
        this.setState({ modalthdSelect: value })
      }
        //modal内apptype
        modalselectScd=(e)=>{
          var value = e.target.value;
          this.setState({ modalscdSelect: value })
        }
    showmodal=()=>{
        alert(this.index)
    }
    black=()=>{

    }
    pageChange=(page)=>{
      this.setState({
        current: page
        },()=>{
          this.searchClick()
        })
    }


//modal取消按钮
handleCancel=()=>{
  this.setState({
    thdvisible:false,
    fstvisible:false,
    modalscdSelect:0,
    modalthdSelect:0,
    modalfstinput:'',
    modalscdinput:'',
    fsttextarea:'',
    radioValue:'',
    datepiker:'',
    Import:false,
    fileList:''
  })
}
    //content
    fsttxarea=(e)=>{
      let value = e.target.value;
      this.setState({ fsttextarea: value })
    }
    //Channel number
    ScdModalInput=(e)=>{
      let value = e.target.value;
      this.setState({ modalscdinput: value })
    }
    //topic name
    FstModalInput=(e)=>{
      let value = e.target.value;
      this.setState({ modalfstinput: value })
    }

    //modal确定按钮
    thdhandleOk=()=>{
      axios
      .post(`/api/sysTopic/topicDelete`,{
        id:this.state.thdmodalinpt,
    })
    .then(res=>{
      if(res.data.code === 200){
        message.success(res.data.msg)
        this.searchClick()
        
      }else if(res.data.code ==800){
        this.props.history.push({ pathname: "/"});
      }else{
        message.error(res.data.msg)
      }
    })
    this.setState({
      boolean:true,
      thdvisible:false,
    }) 
    }
    //modal确定按钮
    handleOk=()=>{
      this.setState({
        fstvisible:false,
      }) 
      if(this.state.modalscdinput==''){
        alert("The Topic name can't  be empty.")
        this.setState({
          Import:false,
          fileList:'',
          modalthdSelect:0
        }) 
        return false
      }else if(this.state.fsttextarea==''){
        alert("The content can't  be empty.")
        this.setState({
          Import:false,
          fileList:'',
          modalthdSelect:0
        }) 
        return false
      }
      const { fileList,modalscdSelect,modalthdSelect,modalfstinput,modalscdinput,fsttextarea,radioValue,datepiker} = this.state;
      const formData = new FormData();
      formData.append('file', fileList[0]);  
      const json = {
        'appCode':modalscdSelect,
        'pushUser':modalthdSelect,
        'channelNumber':modalfstinput,
        'title':modalscdinput,
        'content':fsttextarea,
        'publishMethod':radioValue,
        'publishTime':new Date(this.state.datepiker),
    }
    const objToStr = JSON.stringify(json)
    formData.append('json',objToStr)
      axios({
      url: '/api/sysTopic/topicAddNew',
      method: 'post',
      data: formData
    })
    .then(res=>{
      if(res.data.code === 200){
        message.success(res.data.msg)
        this.setState({
          boolean:true, 
          fstvisible:false,
          modalscdSelect:0,
          modalthdSelect:0,
          modalfstinput:'',
          modalscdinput:'',
          fsttextarea:'',
          radioValue:'',
          datepiker:'',
          Import:false,
          fileList:''
        }) 
      }else if(res.data.code ==800){
        this.props.history.push({ pathname: "/"});
      }else{
        message.error(res.data.msg)
        this.setState({
          boolean:true, 
          fstvisible:false,
          modalscdSelect:0,
          modalthdSelect:0,
          modalfstinput:'',
          modalscdinput:'',
          fsttextarea:'',
          radioValue:'',
          datepiker:'',
          Import:false,
          fileList:''
        }) 
      }

      this.searchClick()
    })


    }

    //add按钮
    add=()=>{
      this.setState({
        fstvisible:true,
      })

      axios
      .post(`/api/appshadow/findAppShadowList`,{
    
    })
    .then(res=>{
       getselect=[]
      if(res.data.code === 200){
        for(let i=0;i<res.data.data.list.length;i++ ){
          
            if(i==0){
              getselect.push(<option value=''>please select one</option> )
              }
              getselect.push(       
              <option value={res.data.data.list[i].appCode}>{res.data.data.list[i].appCode}</option>
          )
          
        this.setState({
          getselect:getselect
        })
        }
      }else if(res.data.code ==800){
        this.props.history.push({ pathname: "/"});
      }else{
      }
    })
    }

    //页面初始化加载下面的table
    secondTable=()=>{
      this.searchClick()
    }
    //搜索
    searchClick=()=>{ 
    this.setState({
      boolean:true
    })
    axios
      .post(`/api/sysTopic/getSysTopicList`,{
      title:this.state.searchVal,
      beginTime:this.state.from,
      endTime:this.state.to,
      pageSize: this.state.pageSize,
      current: this.state.current,
      status:this.state.fstSelect,
      appCode:this.state.scdSelect,
      pushUser:this.state.thdSelect,
    })
    .then(res=>{
      if(res.data.code === 200){
        
        const data1 = [];
        const data = res.data.data.resultDate
        if(data.length<1){
          this.setState({
            boolean:false
          })
        }else{
          for (let i = 0; i < data.length; i++) {
            data[i].key = i;
            data1.push(data[i]);
          }
        }
        
        this.setState({
          tableScdData:data1,
          boolean:false,
          total:res.data.data.page.total
        }) 
      }else if(res.data.code ==800){
        this.props.history.push({ pathname: "/"});
      }else{
      }
    })
  }
      componentDidMount() {
        this.secondTable()
        this.getselect()
      }
    render(){
      const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      };
        const columns = [
            {
              title: 'serial number',
              dataIndex: 'id',
              key: 'id',
            },
            {
              title: 'topic name',
              dataIndex: 'title',
              key: 'title',
            },
            
             
            {
              title: 'content',
              dataIndex: 'content',
              key: 'content',
              width:200,
              render: (record) => {
                return (
                  <Tooltip title={record}>
                    <div className="twoEllipsis">
                      {record}
                    </div>
                  </Tooltip>
                )
            }
            }, 
            {
              title: 'App name',
              dataIndex: 'appName',
              key: 'appName'
            },
            {
              title: 'Push crowd',
              dataIndex: 'pushUser',
              key: 'pushUser',
              render : (value,record)=>{
                if(value == 1){
                    return  'newUser';
                }
                if(value==0){
                    return 'All';
                }
                if(value==2){
                    return 'oldUser';
                }
                if(value==3){
                    return 'newUser(step 0)';
                }
                if(value==4){
                    return 'newUser(step 1)';
                }
                if(value==5){
                    return 'newUser(step 2)';
                }
                if(value==6){
                    return 'newUser(step 3)';
                }
                if(value==7){
                    return 'newUser(step 4)';
                }
                if(value==8){
                    return 'newUser(step 5,6)';
                } 
                if(value==9){
                    return 'Import users in bulk';
                }
                if(value==10){
                  return 'Pending Bind Card';
              }
            }
            },
            {
                title: 'Channel number',
                dataIndex: 'channelNumber',
                key: 'channelNumber'
              }, 
            {
                title: 'Push number',
                dataIndex: 'pushCount',
                key: 'pushCount',
  
              }, 
            {
                title: 'Number of readers',
                dataIndex: 'readCount',
                key: 'readCount'
              },
            {
              title: 'publish time',
              dataIndex: 'publishTime',
              key: 'publishTime',
              render: (value) => {
                let timeaudit= new Date(value).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
                let auditimes=moment(new Date(timeaudit)).format("YYYY-MM-DD HH:mm:ss");
                return auditimes
            }
            },
             {
              title: 'status',
              dataIndex: 'status',
              key: 'status',
              render : (value,record)=>{
                if(value == 1){
                    return  'topicPublished';
                }else if(value==2){
                    return 'topicNoPublished';
                }
            }
            }, 
            {
              title: 'Operation',
              key: 'operation',
              reviewerId:'reviewerId',
              render: (record) =>(<span style={{ display: 'flex' , flexDirection: 'row', justifyContent:'center',margin:0}}> 
                <Button type="primary" style={{width:100,height:30,backgroundColor:'#009688',border:'none'}} onClick={()=>this.delete(record)}>delete</Button>
              </span>)
            
            },
          ];
          const { uploading, fileList } = this.state;
      const props = {
        onRemove: (file) => {
          this.setState((state) => {
            const index = state.fileList.indexOf(file);
            const newFileList = state.fileList.slice();
            newFileList.splice(index, 1);
            return {
              fileList: newFileList,
            };
          });
        },
        beforeUpload: (file) => {
          this.setState(state => ({
            fileList: [...state.fileList, file],
          }));
          return false;
        },
        fileList,
      };
        return(
            <div className="audit_content">
                <div className="commom_top" style={{flexDirection:'column'}}>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <div className="mid_left" style={{width:'25%'}}>
                      <span className="description_new_scd">Search:</span>
                      <Search
                          placeholder="enter topic name"
                          onSearch={value => console.log(value)}
                          style={{width: 220}}
                          value={this.state.searchVal}
                          onChange={this.searchIpt.bind(this)}
                      />

                  </div>
                  <div className="mid_mid" style={{width:'25%',marginLeft:'2%'}}>
                      <span className="description_new_scd">App Type:</span>
                      <select  className="form_select_new" value={this.state.scdSelect} onChange={this.selectScd}>
                      {Select}
                      </select>
                  </div>

                  <div className="mid_right" style={{width:'25%',marginLeft:'2%'}}>
                      <span className="description_new_scd">Push crowd:</span>
                      <select className="form_select_new"  value={this.state.thdSelect} onChange={this.selectThd}>
                          <option value="0">all</option>
                          <option value="1">New user</option>
                          <option value="2">Old user</option>
                          <option value="3">New user(step0)</option>
                          <option value="4">New user(step1)</option>
                          <option value="5">New user(step2)</option>
                          <option value="6">New user(step3)</option>
                          <option value="7">New user(step4)</option>
                          <option value="8">New user(step5,6)</option>
                      </select>
                  </div>
                  <div className="rangepick"style={{width:'25%',marginLeft:'2%'}}>
                      <span className="description_new_scd">Published time:</span>
                      <RangePicker
                      style={{width: 220,}}
                      ranges={{
                      Today: [moment(), moment()],
                      'This Month': [moment().startOf('month'), moment().endOf('month')],
                      }}
                      showTime
                      format={dateFormat}
                      onChange={this.rangeOnChange}
                      value={this.state.from===undefined||this.state.to===undefined||this.state.from===""||this.state.to===""?null:[moment(this.state.from, dateFormat), moment(this.state.to, dateFormat)]}
                      />
                  </div>
          
              
                </div>
               
                <div style={{display:'flex',justifyContent:'row',marginTop:20}}>
                    <div className="mid_left" style={{width:'25%'}}>
                        <span className="description_new_scd">Status:</span>
                        <select className="form_select_new" value={this.state.fstSelect} onChange={this.selectFst}>
                            <option value="0">all</option>
                            <option value="1">Published</option>
                            <option value="2">Unpublish</option>
                        </select>
                    </div>
                    <Button type="primary" className='button_style' style={{width:170,marginRight:20,height:40,backgroundColor:'#009688'}} onClick={this.searchClick}>Search</Button>
                    <Button type="primary" className='button_style' style={{width:170,marginRight:20,height:40,backgroundColor:'#009688'}} onClick={this.reloadClick.bind(this)}>Refresh</Button>
                    <Button type="primary" className='button_style' style={{width:170,marginRight:20,height:40,backgroundColor:'#009688',}} onClick={this.add}>Add</Button>

               
                </div>

                </div>
                <div className="table">
                    <Table columns={columns} dataSource={this.state.tableScdData} loading={this.state.boolean}  pagination={false}  />,
                </div>
                <Modal
                  title="Are you sure you want to delete it ?"
                  visible={this.state.thdvisible}
                  onOk={this.thdhandleOk}
                  onCancel={this.handleCancel}
                  okText="CONFIRM"
                  cancelText="CANCEL"
                >
                <input style={{display:'none'}} value={this.state.thdmodalinpt}/>
                </Modal>
                <Modal
                  title="add message"
                  visible={this.state.fstvisible}
                  onOk={this.handleOk}
                  onCancel={this.handleCancel}
                >
                <div className="model_last">
                <span  className="long">App name:</span>
                <select className="form_select_modal_new" style={{marginLeft:'13%'}} value={this.state.modalscdSelect} onChange={this.modalselectScd}>
                   {getselect}
                </select>
                </div>
                <div className="model_last">
                <span  className="long">Push crowd:</span>
                <select className="form_select_modal_new"   value={this.state.modalthdSelect} onChange={this.modalselectThd}>
                  <option value="0" selected='selected'>all</option>
                  <option value="1">New user</option>
                  <option value="2">Old user</option>
                  <option value="3">New user(step0)</option>
                  <option value="4">New user(step1)</option>
                  <option value="5">New user(step2)</option>
                  <option value="6">New user(step3)</option>
                  <option value="7">New user(step4)</option>
                  <option value="8">New user(step5,6)</option>
                  <option value="9">Import users in bulk</option>
                  <option value="10">Pending Bind Card</option>

                </select>
                </div>
                {this.state.Import&&
                    <div className="model_last">
                    <span  className="long">upload file:</span>
                      <div  style={{marginLeft:'12%',alignItems:'center'}} className='message_file'>
                      <Upload  {...props}>
                      <Button disabled={this.state.fstdis} onClick={this.changeupload}>
                          <Icon type="upload" /> 选择文件
                      </Button>
                      </Upload>
                      </div>
                      
                     </div>
                    }
                <div className="model_last">
                <span  className="long">Channel number:</span>
                    <input className="modal_input" type='text' style={{marginLeft:'3.5%',width:290}}value={this.state.modalfstinput} onChange={this.FstModalInput}/>
                </div>
               
                <div className="model_last">
                <span className="long">Topic name:</span>
                <input className="modal_input" type='text' style={{marginLeft:'10.3%',width:290}} value={this.state.modalscdinput} onChange={this.ScdModalInput}/>
                </div>
                <div className="model_last">
                <span  className="long">Content:</span>
                <textarea className='modal_textarea' style={{marginLeft:'16%',width:290}} value={this.state.fsttextarea} onChange={this.fsttxarea}/>
                </div>
                <div className="model_last">
                <span  className="long">Publish method:</span>
                <Radio.Group onChange={this.radioOnChange} value={this.state.radioValue} style={{display:'flex',flexDirection:'row',marginLeft:20}}>
                  <Radio style={radioStyle} value={1}>
                    Publish now
                  </Radio>
                  <Radio style={radioStyle} value={2}>
                    Timed publish
                  </Radio>
                </Radio.Group>
                </div>
                <div className="model_last" style={{display:this.state.radioValue==2?'block':'none'}}>
                <span className="long">publish time:</span>
                <DatePicker style={{marginLeft:'4%',width:290}}  showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }} value={this.state.datepiker===undefined||this.state.datepiker===""?null:moment(this.state.datepiker, DateFormat) }onChange={this.dateonChange} /> 
                {/* <DatePicker style={{marginLeft:'4%',width:290}} value={this.state.datepiker===undefined||this.state.datepiker===""?null:[moment(this.state.datepiker, dateFormat)]}   onChange={this.dateonChange} /> */}
                </div>
                </Modal>
                <Pagination
                  showQuickJumper
                  defaultCurrent={1} 
                  current={this.state.current}
                  total={this.state.total} 
                  onChange={this.pageChange} 
                  showTotal={showTotal}
                  style={{textAlign:'right',marginTop:20}}
                  showSizeChanger                          //展示改变pagesize样式
                  onShowSizeChange={this.onShowSizeChange} //pagesize改变时回调函数
                  pageSizeOptions={["10","50","100"]}   //设置pagesize区间
                  pageSize={this.state.pageSize}
                  />
            </div>
        )
    }
}
export default withRouter(SystemMessage)