/*eslint-disable*/
import React from 'react';
import { Input,Button,Table,Modal,Pagination,DatePicker,Tooltip,message } from 'antd';
import moment from 'moment';
import axios from 'axios'
import './index.css';
import $ from 'jquery';
import {withRouter} from 'react-router-dom'
import EventEmitter from '../../../utils/events'
import { exportMethod } from '../../../utils/axios'

function showTotal(total) {
  return `Total ${total} items`;
}
var collector = []
const { RangePicker } = DatePicker;
  const { Search } = Input;
  const dateFormat = 'YYYY-MM-DD'||undefined;
 class CollectionWorkMonitor extends  React.Component {
    constructor(props) {
        super(props);
        this.state = {
            boolean:true,
            topboolean:true,
            current:1,
            pageSize:10,
            isDisabled:false,
            btnWord:'export',
          };
    }
    componentDidMount() {
      this.setState({
      },()=>{
        this.secondTable()
        this.table()
        this.select()
      })
    }
    text(record){
      window.location='/#/collection/getCollectionDataDay/collectionDayAudit?id='+record.id
      let test = [{path: '/collection/getCollectionDataDay/collectionDayAudit',key: `/collection/getCollectionDataDay/collectionDayAudit/${record.id}`,number: `${record.id}`}]
      EventEmitter.trigger('btn', test[0])
      EventEmitter.trigger('idanduid', test[0].number)  
  }
    //重置按钮
    reloadClick(e){
      this.setState({
      fstSelect:'',
      scdSelect:'',
      fouSelect:'',
      modalscdSelect:'',
      id: '',
      boolean:true,
      topboolean:true
      },()=>{
        this.secondTable()
        this.table()
      });
      
    }
          //选择collector
          selectscd(e){
            var value = e.target.value;
            var userInfo = $("#ipt1").val();
            var getUserId = $("option[value='" + userInfo + "']").data("userid");
            $("input[name='userId']").val(getUserId);
            console.log(getUserId)
            this.setState({
              scdSelect:value,
              id:getUserId
            });
          }
     //页码改变时触发
     pageChange=(page)=>{
      this.setState({
        current: page
        },()=>{
          this.searchClick()
        })
    }
    onShowSizeChange=(current, pageSize)=>{
    
      this.setState({
        pageSize: pageSize,
        boolean:true
      },()=>{
        this.secondTable()
        this.table()
      })
    }
//获取select
select=()=>{
  axios
    .post(`/api/remindData/getCollectionUsers`,{
  })
  .then(res=>{
    if(res.data.code === 200){
      collector=[]
    let data = res.data.data.resultDate
    for(let i=0;i<data.length;i++ ){
      collector.push(       
        <option data-userId={data[i].id} value={data[i].userName}></option>
    )
    // console.log('select',collector)
    this.setState({
      collector:collector
    })
    }
    }else if(res.data.code ==800){
      this.props.history.push({ pathname: "/"});
    }
    else{
    }
  })
  
}
    secondTable=()=>{
      axios
        .post(`/api/collectionData/getCollectionDataDay`,{
          pageSize: this.state.pageSize,
          current: this.state.current,
      })
      .then(res=>{
        if(res.data.code === 200){
          const data1 = [];
          const data = res.data.data.resultDate

          if(data == null){
            this.setState({
              tabletableScdData:[],
              total:0,
              boolean:false
            })
          }else{
            for (let i = 0; i < data.length; i++) {
              if(data.length<1){
                this.setState({
                  boolean:false
                })
              }else{
                data1.push({
                  key: i,
                  overdueGrade:data[i].overdueGrade,
                  userName:data[i].userName,  
                  underUrgeToday:data[i].underUrgeToday,
                  urgedToday:data[i].urgedToday,
                  unUrgeToday:data[i].unUrgeToday,
                  urgeCountToday:data[i].urgeCountToday,
                  urgeAverageCountToday:data[i].urgeAverageCountToday,
                  urgeRepayToday:data[i].urgeRepayToday,
                  id:data[i].id,
                  todayExtendOrder:data[i].todayExtendOrder,
                });
              }
             
              this.setState({
                tableScdData:data1,
                total:res.data.data.page.total,
                boolean:false
              })
            } 

          }

          
        }else if(res.data.code ==800){
          this.props.history.push({ pathname: "/"});
        }else{
    
        }
      })
      
    }
    
    table=()=>{
      let id = localStorage.id;
      axios
        .post(`/api/collectionData/getCollectionDataDayTotal`,{
          pageSize: this.state.pageSize,
          current: this.state.current,
      })
      .then(res=>{
        if(res.data.code === 200){
          const data1 = [];
          const data = res.data.data.resultDate
          if(data.length<1){
            this.setState({
              topboolean:false
            })
          }else{
            if(data == null){
              this.setState({
                totalData:[],
                topboolean:false
              }) 
            }else{
              if(data == null){
                this.setState({
                  tabletableScdData:[],
                  total:0,
                  boolean:false
                })
              }else{
              for (let i = 0; i < data.length; i++) {
                data1.push({
                  key: i,
                  overdueGrade:'--',
                  userName:'--',
                  underUrgeToday:data[i].underUrgeToday,
                  urgedToday:data[i].urgedToday,
                  unUrgeToday:data[i].unUrgeToday,
                  urgeCountToday:data[i].urgeCountToday,
                  urgeAverageCountToday:data[i].urgeAverageCountToday,
                  urgeRepayToday:data[i].urgeRepayToday,
                  todayExtendOrder:data[i].todayExtendOrder,
                });
              } 
            }
              this.setState({
                totalData:data1,
                topboolean:false
              }) 
            }
            }
        }else if(res.data.code ==800){
          this.props.history.push({ pathname: "/"});
        }else{
        }
      })
    }


    downExcel=()=>{
      this.state.isDisabled = true;
      this.export();
      let count = 60;
      let timer = setInterval(()=>{
        if(count <=0){
          this.setState({
            btnWord:'export',
            isDisabled:false
          })
          clearInterval(timer);
        }else{
          count --;
          this.setState({
            btnWord:count +'s'
          })
        }
      },1000)
    }

    export=()=>{
      let myObj = {
        method: 'post',
        url:`/api/collectionData/outExcelCollectionDataDay`,
        data:{
          pageSize: this.state.pageSize,
            current: this.state.current,
          overdueGrade:this.state.fstSelect,
          collectionUserid:this.state.id,
        },
        }
        exportMethod(myObj)
    }


      //搜索按钮
      searchClick=()=>{
        this.setState({
          boolean:true,
          topboolean:true
        })
        axios
          .post(`/api/collectionData/getCollectionDataDay`,{
            pageSize: this.state.pageSize,
            current: this.state.current,
            overdueGrade:this.state.fstSelect,
            collectionUserid:this.state.id,
        })
        .then(res=>{
          if(res.data.code === 200){
            const data1 = [];
            const data = res.data.data.resultDate
            if(data==null||data.length <1 ){
              this.setState({
                boolean:false
              })
            }else{
              if(data ==null){
                this.setState({
                  tableScdData:[],
                  total:0,
                  boolean:false
                })
              }else {
              for (let i = 0; i < data.length; i++) {
                data1.push({
                  key: i,
                  overdueGrade:data[i].overdueGrade,
                  userName:data[i].userName,
                  underUrgeToday:data[i].underUrgeToday,
                  urgedToday:data[i].urgedToday,
                  unUrgeToday:data[i].unUrgeToday,
                  urgeCountToday:data[i].urgeCountToday,
                  urgeAverageCountToday:data[i].urgeAverageCountToday,
                  urgeRepayToday:data[i].urgeRepayToday,
                  id:data[i].id,
                  todayExtendOrder:data[i].todayExtendOrder,
                });
               
              } 
            }
            this.setState({
              tableScdData:data1,
              total:res.data.data.page.total,
              boolean:false
            })
          }
          }else if(res.data.code ==800){
            this.props.history.push({ pathname: "/"});
          }else{
      
          }
        })
        axios
          .post(`/api/collectionData/getCollectionDataDayTotal`,{
            pageSize: this.state.pageSize,
            current: this.state.current,
            overdueGrade:this.state.fstSelect,
            collectionUserid:this.state.id,
        })
        .then(res=>{
          if(res.data.code === 200){
            const data1 = [];
            const data = res.data.data.resultDate
            if(data==null||data.length <1){
                  this.setState({
                    topboolean:false
                  })
                }else{
                  for (let i = 0; i < data.length; i++) {
                    data1.push({
                      key: i,
                      overdueGrade:'--',
                      userName:'--',
                      underUrgeToday:data[i].underUrgeToday,
                      urgedToday:data[i].urgedToday,
                      unUrgeToday:data[i].unUrgeToday,
                      urgeCountToday:data[i].urgeCountToday,
                      urgeAverageCountToday:data[i].urgeAverageCountToday,
                      urgeRepayToday:data[i].urgeRepayToday,
                      todayExtendOrder:data[i].todayExtendOrder,
                    });
                    this.setState({
                      totalData:data1,
                      topboolean:false
                    }) 
                  } 
                }
          
          }else if(res.data.code ==800){
            this.props.history.push({ pathname: "/"});
          }else{
          }
        })
      }
      collector =(params)=>{
        var va = params.overdueGrade
          if (va==1){
              return <span style={{color:'black'}}>S1-Old</span>
          }else if (va==2){
              return <span style={{color:'black'}}>S2-Old</span>
          }else if (va==3){
              return <span style={{color:'black'}}>M1-Old</span>
          }else if (va==4){
              return <span style={{color:'black'}}>M2</span>
          }else if (va==5){
              return <span style={{color:'black'}}>M3</span>
          }else if (va==6){
              return <span style={{color:'black'}}>M4</span>
          }else if (va==11){
              return <span style={{color:'black'}}>S1-New</span>
          }else if (va==12){
              return <span style={{color:'black'}}>S2-New</span>
          }else if (va==13){
              return <span style={{color:'black'}}>M1-New</span>
          }else {
              return <span style={{color:'black'}}></span>
          }
        }
    selectFst(e){
      var value = e.target.value;
      this.setState({
        fstSelect:value,
      });
    }

    selectFou(e){
        var value = e.target.value;
        this.setState({
          fouSelect:value,
        });
      }
      selectThd=()=>{

     }
     
 
    render() {
        const botcolumns = [
            {
              title: 'Overdue grade',
              dataIndex: 'overdueGrade',
              key: 'overdueGrade',
            },
            {
              title: 'Collector',
              dataIndex: 'userName',
              key: 'userName',
            },
            {
              title: 'Current Order Total',
              dataIndex: 'underUrgeToday',
              key: 'underUrgeToday',
      
            },
            {
              title: 'Collected Order',
              dataIndex: 'urgedToday',
              key: 'urgedToday',
         
            },
            {
              title: 'Not Collection Order',
              dataIndex: 'unUrgeToday',
              key: 'unUrgeToday',
         
            },
            {
              title: 'Total Collection Times',
              dataIndex: 'urgeCountToday',
              key: 'urgeCountToday',
      
            },
            {
              title: 'Average Collection Times',
              dataIndex: 'urgeAverageCountToday',
              key: 'urgeAverageCountToday',
    
            },
            {
              title: 'Today Repayment Order',
              dataIndex: 'urgeRepayToday',
              key: 'urgeRepayToday',
    
            },
            {
              title: 'Today extend order',
              dataIndex: 'todayExtendOrder',
              key: 'todayExtendOrder',
              width:100,
            },
          
          ];
        const columns = [
          {
            title: 'Overdue grade',
            dataIndex: 'overdueGrade',
            key: 'overdueGrade',
            render:(text,record) =>  this.collector(record)

          },
          {
            title: 'Collector',
            dataIndex: 'userName',
            key: 'userName',
          },
          {
            title: 'Current Order Total',
            dataIndex: 'underUrgeToday',
            key: 'underUrgeToday',
    
          },
          {
            title: 'Collected Order',
            dataIndex: 'urgedToday',
            key: 'urgedToday',
       
          },
          {
            title: 'Not Collection Order',
            dataIndex: 'unUrgeToday',
            key: 'unUrgeToday',
       
          },
          {
            title: 'Total Collection Times',
            dataIndex: 'urgeCountToday',
            key: 'urgeCountToday',
    
          },
          {
            title: 'Average Collection Times',
            dataIndex: 'urgeAverageCountToday',
            key: 'urgeAverageCountToday',
  
          },
          {
            title: 'Today Repayment Order',
            dataIndex: 'urgeRepayToday',
            key: 'urgeRepayToday',
  
          },
          {
            title: 'Today extend order',
            dataIndex: 'todayExtendOrder',
            key: 'todayExtendOrder',
            width:100,
          },
            {
              title: 'Operation',
              dataIndex: 'operating',
              key: 'operating',
              width:70,
              id:'id',
              uid:'uid',
              // fixed:'right',
              render: (text, record, index) => (<span style={{ display: 'flex' , flexDirection: 'column'}}> 
              <Button type="primary"   style={this.state.detailsbutton} className="detailsbutton common_color" size="small"  onClick={()=>this.text(record)}>details</Button>
                </span>)
            },
          ];
        return (
            <div className="overduepaid">
                    <div className="overduepaid_top" style={{backgroundColor:'#fff',paddingTop:20,paddingBottom:20}}>
                    <div className="overduepaid_mid">
                        <div className="top collection_order_top">
                            <div className="top_left" style={{width:'30%',flexShrink:1}}>
                                <span className="description left">Overdue Grade:</span>
                                <select className="form_select" value={this.state.fstSelect} onChange={this.selectFst.bind(this)}>
                                    <option value="">all</option>
                                    <option value='1'>S1-Old</option>
                                    <option value='2'>S2-Old</option>
                                    <option value='3'>M1-Old</option>
                                    <option value='4'>M2</option>
                                    <option value='5'>M3</option>
                                    <option value='6'>M4</option>
                                    <option value='11'>S1-New</option>
                                    <option value='12'>S2-New</option>
                                    <option value='13'>M1-New</option>
                                </select>
                            </div>

                            
                            <div className="mid_right" style={{width:'30%'}}>
                            <span className="description right">Collector:</span>
                            <input list='browsers' placeholder='all' className="form_select_mohu" id={this.state.id} id="ipt1" value={this.state.scdSelect} onChange={this.selectscd.bind(this)}/>
                            <datalist id="browsers">
                              {collector}
                            </datalist>
                        </div>
                        </div>
                        
                        <div className="bottom">
                            <Button type="primary" style={{width:170,marginRight:20,height:40,border:'none'}} className="common_color" onClick={this.searchClick.bind(this)}>Search</Button>
                            <Button type="primary" style={{width:170,height:40,border:'none'}} className="common_color_scd" onClick={this.reloadClick.bind(this)}>Refresh</Button>
                            <Button type="primary" style={{width:170,height:40,marginRight:20,marginLeft: 20,border:'none',backgroundColor:this.state.isDisabled?'':'#009688'}}  onClick={this.downExcel} disabled={this.state.isDisabled}>{this.state.btnWord}</Button>
                        </div>  
                    </div>
                </div>
                <div className="overdue_table">
                <Table columns={botcolumns} dataSource={this.state.totalData} loading={this.state.topboolean} onChange={this.handleChange} pagination={false}/>
                  
                </div>
                <Table columns={columns} dataSource={this.state.tableScdData} loading={this.state.boolean} onChange={this.handleChange} pagination={false}/>
                <Pagination
                      showSizeChanger                          //展示改变pagesize样式
                      onShowSizeChange={this.onShowSizeChange} //pagesize改变时回调函数
                      pageSizeOptions={["10","50","100"]}   //设置pagesize区间
                      defaultCurrent={1} 
                      current={this.state.current}
                      total={this.state.total} 
                      onChange={this.pageChange} 
                      pageSize={this.state.pageSize}
                      showTotal={showTotal}
                      style={{textAlign:'right',marginTop:20}}
                      />
            </div>
        );
    }
}
export default withRouter(CollectionWorkMonitor)