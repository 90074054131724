/*eslint-disable*/
import React from 'react';
import { Input,Button,Table,Modal,Radio,Pagination,Tooltip,message,Tabs,DatePicker,Icon,Upload } from 'antd';
import './index.css';
import axios from 'axios'
import moment from 'moment';
import location_red from '../../../../public/img/rule.png';
import {withRouter} from 'react-router-dom'
import EventEmitter from '../../../utils/events'
var Select = [];
const dateFormat = 'YYYY-MM-DD'||undefined;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
const DateFormat = 'YYYY-MM-DD'||undefined;
var whitchshow = []
var closeId = '';
 class Edit extends React.Component {
    constructor(props){
      super(props);
      this.state={
        number:'',
        searchVal:'',
        current:1,
        length:0,
        pageSize:10,
        isshow:true,
        ishide:false,
        scdSelect:'',
        id:'',
        fstSelect:'',
        showhide:true,
        hideshow:false,
        fileList: [],
        fstdis:false,
        scddis:false,
        buttonshow:true,
        scdInput:'',
        couponId :'',
        couponIndentNo:''
      }
     
    }

    rangeOnChange=(dates, dateStrings)=>{
      this.setState({ from: dateStrings[0],to:dateStrings[1] })
    }
    //页码改变时触发
    pageChange=(page)=> { 
      
    this.setState({
      current: page
      },()=>{
        this.searchClick()
      })
    
    } 

    getselect=()=>{
        axios
        .post(`/api/appshadow/findAppShadowList`,{
  
      })
      .then(res=>{
         Select=[]
        if(res.data.code === 200){
          for(let i=0;i<res.data.data.list.length;i++ ){
            
              if(i==0){
                Select.push(<option value=''>请选择</option> )
                }
                Select.push(       
                <option value={res.data.data.list[i].appCode}>{res.data.data.list[i].appCode}</option>
            )
            
          this.setState({
            Select:Select
          })
          }
        }else if(res.data.code ==800){
          this.props.history.push({ pathname: "/"});
        }else{
        }
      })
      }

    //重置按钮
    add=(e)=>{
      this.setState({
        scdvisible:true,
      },()=>{

      });
     
    }
    dateonChange = (date, dateString) =>{
        this.setState({ datepiker:dateString})
      }
      dateonChange2 = (date, dateString) =>{
        this.setState({ datepiker2:dateString})
      }
  componentDidMount(){
        // this.loadTable()
        let urluid
        this.getselect()
        let url = window.location.hash
        let params = url.substr(1);
        let aPos = params.indexOf("=")
        let bPos = params.indexOf("&")
        let urlid = params.substr(aPos + 1, bPos - aPos - 1);
        let ishave = params.indexOf("type")
        closeId = urlid;
        if(ishave==-1){
            urluid = ''
        }else{
            urluid = params.substr(params.indexOf("type")+5,params.length-5);        
        }
        if(urlid!=0){
            this.setState({
                fstdis:true,
                scddis:false,
                type:4,
                ids:urlid
            })
            axios
            .post(`/api/sysactivity/findSysActivity`,{
                id:urlid
          })
          .then(res=>{
              let data = res.data.data.list[0]
            if(res.data.code === 200){
                if(data.offerEvens==1){
                    this.setState({
                        attribute:true,
                        behavior:false,
                        special:false,
                        Import:false,
                    })
                }
                else if(data.offerEvens==2){
                    this.setState({
                        attribute:false,
                        behavior:true,
                        special:false,
                        Import:false,
                    })
                }else if(data.offerEvens==3){
                    this.setState({
                        attribute:false,
                        behavior:false,
                        special:true,
                        Import:false,
                    })
                }else if(data.offerEvens==4){
                    this.setState({
                        attribute:false,
                        behavior:false,
                        special:false,
                        Import:true,
                    })
                }
                if(data.offerType==1){
                    this.setState({
                        showhide: true,
                        hideshow:false
                    });
                }else{
                    this.setState({
                        showhide: false,
                        hideshow:true
                    });
                }
                let time = data.enabledDate;
                let d = new Date(time);
                let times=d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
                let startTime = times
                let time1 = data.disabledDate;
                let d1 = new Date(time1);
                let times1=d1.getFullYear() + '-' + (d1.getMonth() + 1) + '-' + d1.getDate() ;
                let endTime = times1.substring(0,10)
              this.setState({
                fstInput:data.activityName,
                fstSelect:data.appshadowId,
                radvalue:data.offerType,
                datepiker:startTime,
                datepiker2:endTime,
                radvalue2:data.status,
                scdSelectVal:data.offerEvens,
                fstText:data.text,
                thdInput:data.avtivityOfferLimit,
                fouInput:data.userOfferMax,
                fivInput:data.avtivityOfferUrl,
                radvalue3:data.offerAttributes,
                radvalue4:data.offerConduct,
                scdText:data.phone,
                couponId:data.couponId,
                couponIndentNo:data.couponIndentNo
              })
            }else if(res.data.code ==800){
              this.props.history.push({ pathname: "/"});
            }else{
            }
          })
            if(urluid!=''){
                this.setState({
                    fstdis:true,
                    scddis:true,
                    buttonshow:false
                })
            }
        }else{
            this.setState({
                ids:0
            })
        }
  }
  modelselectOne(e){
    var value = e.target.value;
    this.setState({
      modalscdSelect:value,
    });
  }

  radioOnChange = e => {
    console.log('radio checked', e.target.value);
    if(e.target.value==1){
        this.setState({
            showhide: true,
            hideshow:false
        });
    }else{
        this.setState({
            showhide: false,
            hideshow:true
        });
    }
    this.setState({
        radvalue: e.target.value,
    });
  };
  radioOnChange2 = e => {
    console.log('radio checked', e.target.value);
    this.setState({
        radvalue2: e.target.value,
    });
  };
  radioOnChange3 = e => {
    console.log('radio checked', e.target.value);
    this.setState({
        radvalue3: e.target.value,
    });
  };
  radioOnChange4 = e => {
    console.log('radio checked', e.target.value);
    this.setState({
        radvalue4: e.target.value,
    });
  };



    handleCancel = e => {
      console.log(e);
      this.setState({
        scdvisible: false,
      });
    };

    handleOk=()=>{
        this.setState({
            scdvisible: false,
          });
    }
    handclick=()=>{
        this.setState({
            scdvisible:true
        })
    }
      

    sure=()=>{

        // window.location='/#/marketing_management/activity/activity'
        // let test = [{path: '/marketing_management/activity/activity',key: `/marketing_management/activity/activity`}]
        // EventEmitter.trigger('btn', test[0])
        // EventEmitter.trigger('idanduid', test[0].number)
        const { fileList,fstInput,thdInput,radvalue,fstSelect,datepiker,datepiker2,radvalue2,scdSelectVal,fstText,fouInput,fivInput,radvalue3,radvalue4,scdText,type,ids } = this.state;
        const formData = new FormData();
        formData.append('file', fileList[0]);    
        const json = {
            'activityName':fstInput,
            'appshadowId':fstSelect,
            'offerType':radvalue,
            'enabledDate':datepiker + ' 12:00:00',
            'disabledDate':datepiker2+ ' 12:00:00',
            'status':radvalue2,
            'offerEvens':scdSelectVal,
            'text':fstText,
            'avtivityOfferLimit':thdInput,
            'userOfferMax':fouInput,
            'avtivityOfferUrl':fivInput,
            'offerAttributes':radvalue3,
            'offerConduct':radvalue4,
            'phone':scdText,
            'type':type,
            'id':ids,
            'couponId':this.state.couponId,
            'couponIndentNo':this.state.couponIndentNo
        }
        const objToStr = JSON.stringify(json)
        formData.append('json',objToStr)
        console.log(objToStr)
        axios({
            url: '/api/sysactivity/saveSysActivity',
            method: 'post',
            data: formData
        })
        .then(res => {
            if (res.data.code === 200) {        
                message.success(res.data.msg)
                let path = `/marketing_management/activity/edit/${closeId}`
                EventEmitter.trigger('handleOk', path);
                EventEmitter.trigger('getList', '')
                this.setState({ 
                    fstInput:'',
                    fstSelect:'',
                    radvalue:'',
                    datepiker:'',
                    datepiker2:'',
                    radvalue2:'',
                    scdSelectVal:'',
                    fstText:'',
                    ids:0,
                    couponId:'',
                    couponIndentNo:'',
                    type:'',
                    scdText:'',
                    radvalue4:'',
                    radvalue3:'',
                    fivInput:'',
                    fouInput:'',
                    thdInput:'', 
                    scdInput:''
                })
            } else if (res.data.code == 800) {
                this.props.history.push({pathname: "/"});
            } else {
                message.error(res.data.msg)
                this.setState({ 
                    scdvisible:false,
                })
            }
        })
      }
      cancel=()=>{
        this.setState({ 
            fstInput:'',
            fstSelect:'',
            radvalue:'',
            datepiker:'',
            datepiker2:'',
            radvalue2:'',
            scdSelectVal:'',
            fstText:'',
            ids:0,
            couponId:'',
            couponIndentNo:'',
            type:'',
            scdText:'',
            radvalue4:'',
            radvalue3:'',
            fivInput:'',
            fouInput:'',
            thdInput:'', 
            scdInput:''
        },()=>{
            let path = `/marketing_management/activity/edit/${closeId}`
            EventEmitter.trigger('handleOk', path);
        })
      }
    selectFst=(e)=>{
      var value = e.target.value;
      this.setState({
        fstSelect:value,
      });
    }
    fstInputOnchange=(e)=>{
        let value = e.target.value;
        this.setState({
            fstInput:value,
        });
    }
    scdInputOnchange=(e)=>{
        let value = e.target.value;   
        this.setState({
            scdInput:value,
        });
    }
    thdInputOnchange=(e)=>{
        let value = e.target.value;
        this.setState({
            thdInput:value,
        });
    }
    fouInputOnchange=(e)=>{
        let value = e.target.value;
        this.setState({
            fouInput:value,
        });
    }
    fivInputOnchange=(e)=>{
        let value = e.target.value;
        this.setState({
            fivInput:value,
        });
    }
    fstSelectOnchange=(e)=>{
        let value = e.target.value;
        this.setState({
            fstSelect:value,
        });
    }
    scdSelectOnchange=(e)=>{
        let value = e.target.value;
        this.setState({
            scdSelectVal:value,
        });
        if(this.state.ids!=0){
            this.setState({
                type:4
            })
        }else{
            if(value==1){
                this.setState({
                    attribute:true,
                    behavior:false,
                    special:false,
                    Import:false,
                    type:3
                })
            }else if(value==2){
                this.setState({
                    attribute:false,
                    behavior:true,
                    special:false,
                    Import:false,
                    type:3
                })
            }else if(value==3){
                this.setState({
                    attribute:false,
                    behavior:false,
                    special:true,
                    Import:false,
                    type:2
                })
            }else if(value==4){
                this.setState({
                    attribute:false,
                    behavior:false,
                    special:false,
                    Import:true,
                    type:1
                })
            }else{
                this.setState({
                    attribute:false,
                    behavior:false,
                    special:false,
                    Import:false
                })
            }
        }
       

    }
    fstTextOnchange=(e)=>{
        let value = e.target.value;
        this.setState({
            fstText:value,
        });
    }
    scdTextOnchange=(e)=>{
        let value = e.target.value;
        this.setState({
            scdText:value,
        });
    }
    addCoupon=()=>{
        if(this.state.scdInput==''){
            return false
        }
        axios
        .post(`/api/coupon/getCouponList`, {

            indentNo: this.state.scdInput,
            pageSize: 1,
            current: 1,
        })
        .then(res => {
            if (res.data.code === 200) {

                const data1 = [];
                const data = res.data.data.resultDate

                for (let i = 0; i < data.length; i++) {
                    data[i].key = i;
                    data1.push(data[i]);
                    this.setState({
                        couponId:res.data.data.resultDate[0].id,
                        couponIndentNo:res.data.data.resultDate[0].indentNo
                    })
                }
                this.setState({
                  tableData: data1,
                    boolean: false,
                    
                })
            } else if (res.data.code == 800) {
                this.props.history.push({pathname: "/"});
            } else {
            }
        })
    }
    // loadTable=()=>{
    //     axios
    //     .post(`/api/coupon/getCouponList`, {
    //         pageSize: this.state.pageSize,
    //         current: this.state.current,
    //     })
    //     .then(res => {
    //         if (res.data.code === 200) {

    //             const data1 = [];
    //             const data = res.data.data.resultDate

    //             for (let i = 0; i < data.length; i++) {
    //                 data[i].key = i;
    //                 data1.push(data[i]);
    //             }
    //             this.setState({
    //               tableData: data1,
    //                 total: res.data.data.page.total,
    //                 boolean: false
    //             })
    //         } else if (res.data.code == 800) {
    //             this.props.history.push({pathname: "/"});
    //         } else {
    //         }
    //     })
    // }
    delete=()=>{
        this.setState({
            tableData:[],
            couponId:'',
            couponIndentNo:''
        })
    }
    render() {

      const columns = [
        {
          title: '优惠券ID',
          dataIndex: 'indentNo',
          key: 'indentNo',
          width:100,
        },
        {
          title: '优惠券名称',
          dataIndex: 'name',
          key: 'name',
          width:100,
        },
        {
          title: '优惠券有效期',
          dataIndex: 'effectiveDays',
          key: 'effectiveDays',
          width:100,
          render: (value,record) => {
            if(value != '' && value!= null){
              return value
            }else{
              let timeaudit= new Date(record.enabledDate).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
              let beginTime=moment(new Date(timeaudit)).format("YYYY-MM-DD HH:mm:ss").substring(0,10);;
              let timeaudit1= new Date(record.disabledDate).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
              let endTime=moment(new Date(timeaudit1)).format("YYYY-MM-DD HH:mm:ss").substring(0,10);

              return beginTime + ' ~ ' +  endTime
            }
        }
        },
        {
          title: '操作',
          dataIndex: 'operating',
          key: 'operating',
          width:104,
          id:'id',
          // uid:'uid',
          render: (text, record, index) => (<span style={{ display: 'flex' ,  justifyContent:'center',}}> 
            <a onClick={() => this.delete(record)} className='inside_button' disabled={this.state.fstdis} >删除</a>
            </span>)
        },
      ];
      const { uploading, fileList } = this.state;
      const props = {
        onRemove: (file) => {
          this.setState((state) => {
            const index = state.fileList.indexOf(file);
            const newFileList = state.fileList.slice();
            newFileList.splice(index, 1);
            return {
              fileList: newFileList,
            };
          });
        },
        beforeUpload: (file) => {
          this.setState(state => ({
            fileList: [...state.fileList, file],
          }));
          return false;
        },
        fileList,
      };
      return (
        <div className='inside'>
            <div className='inside_top'>
                <img src={location_red}/>
                <span onClick={this.handclick}>使用说明</span>
            </div>
            <div className='inside_out'>
                <div className='banner_out_div' style={{width: '40%'}}>
                    <div className='banner_left_div'>
                        <p className='banner_left_p'>活动名称:</p>
                        <p className='banner_left_p'>关联产品:</p>
                        <p className='banner_left_p'>发放形式:</p>
                        {this.state.showhide&&
                        <div>
                            <p className='banner_left_p' style={{height:120}}>发放通知文案:</p>      
                        </div>
                        }
                          {this.state.hideshow&&
                        <div>
                            <p className='banner_left_p'>发放数量:</p>
                            <p className='banner_left_p'>用户领取限制:</p>
                            <p className='banner_left_p'>活动链接:</p>
                        </div>
                        }
                         <p className='banner_left_p'>添加优惠券:</p>
                    </div> 
                    <div className='banner_right_div'>
                    <input type='text' className='product_select' disabled={this.state.fstdis} style={{backgroundColor:this.state.fstdis==false?"#fff":"#f5f5f5"}} value={this.state.fstInput} onChange={this.fstInputOnchange}/>
                    <select  value = {this.state.fstSelect} disabled={this.state.fstdis} style={{backgroundColor:this.state.fstdis==false?"#fff":"#f5f5f5"}} className='product_select' onChange={this.fstSelectOnchange}>
                    {Select}
                    </select>
                    <Radio.Group onChange={this.radioOnChange} disabled={this.state.fstdis} value={this.state.radvalue}>
                    <Radio value={1}>自动发放</Radio>
                    <Radio value={2}>手动领取</Radio>

                    </Radio.Group>
                    {this.state.showhide&&
                       <div><textarea className='product_select' disabled={this.state.scddis} value={this.state.fstText} onChange={this.fstTextOnchange} style={{height:100}}/></div>
                    }
                    {this.state.hideshow&&
                         <div>
                         <input className='product_select' style={{backgroundColor:this.state.fstdis==false?"#fff":"#f5f5f5"}} disabled={this.state.fstdis} value={this.state.thdInput} onChange={this.thdInputOnchange}/>
                         限 <input className='product_select' disabled={this.state.fstdis} style={{width:50,backgroundColor:this.state.fstdis==false?"#fff":"#f5f5f5"}} value={this.state.fouInput} onChange={this.fouInputOnchange}/> 次
                         <input className='product_select' disabled={this.state.scddis} value={this.state.fivInput} onChange={this.fivInputOnchange}/>
                     </div>
                    }
                         <div style={{width:'140%'}}>
                            <input className='product_select' disabled={this.state.fstdis} value={this.state.scdInput} onChange={this.scdInputOnchange} style={{width:'71%',backgroundColor:this.state.fstdis==false?"#fff":"#f5f5f5"}}/>
                            <button className='inside_button' disabled={this.state.fstdis} onClick={this.addCoupon}>添加</button>
                        </div>
                    </div>
             
                </div>
     

                <div className='banner_out_div' style={{width: '40%'}}>
                    <div className='banner_left_div'>
                        <p className='banner_left_p'>活动时间:</p>
                        <p className='banner_left_p'>上线状态:</p>
                        <p className='banner_left_p'>发放对象:</p>
                        {this.state.attribute&&
                            <div>
                                <p className='banner_left_p' style={{height:120}}>用户属性:</p>      
                            </div>
                        }
                        {this.state.behavior&&
                            <div>
                                <p className='banner_left_p' style={{height:120}}>用户行为:</p>      
                            </div>
                        } 
                        {this.state.special&&
                            <div>
                                <p className='banner_left_p' style={{height:120}}>特定用户:</p>      
                            </div>
                        }
                        {this.state.Import&&
                            <div>
                                <p className='banner_left_p' style={{height:120}}>批量导入用户:</p>      
                            </div>
                        } 
                    </div> 
                    <div className='banner_right_div'>
                    <div className='coupon_div'>
                        <DatePicker style={{marginRight:'2%',width:150}} disabled={this.state.fstdis} value={this.state.datepiker===undefined||this.state.datepiker===""?null:moment(this.state.datepiker, DateFormat) }onChange={this.dateonChange} /> 
                        <span>到</span>
                        <DatePicker style={{marginLeft:'2%',width:150}} disabled={this.state.fstdis} value={this.state.datepiker2===undefined||this.state.datepiker2===""?null:moment(this.state.datepiker2, DateFormat) }onChange={this.dateonChange2} /> 
                    </div>
                    <Radio.Group onChange={this.radioOnChange2} disabled={this.state.scddis} value={this.state.radvalue2}>
                        <Radio value={1}>上线</Radio>
                        <Radio value={0}>下线</Radio>
                    </Radio.Group>  
                        <select  value = {this.state.scdSelectVal} style={{backgroundColor:this.state.fstdis==false?"#fff":"#f5f5f5"}} disabled={this.state.fstdis} className='product_select' onChange={this.scdSelectOnchange}>
                            <option value=''>请选择</option>
                            <option value='1'>用户属性</option>
                            <option value='2'>用户行为</option>
                            <option value='3'>特定用户</option>
                            <option value='4'>批量导入用户</option>
                        </select>
                    {this.state.attribute&&
                        <div>
                          <Radio.Group onChange={this.radioOnChange3} disabled={this.state.fstdis} value={this.state.radvalue3}>
                            <Radio value={1}>全部用户</Radio>
                            <Radio value={2}>新用户</Radio>
                            <Radio value={3}>老用户</Radio>
                        </Radio.Group>  
                        </div>
                    }
                    {this.state.behavior&&
                        <div>
                            <Radio.Group onChange={this.radioOnChange4} disabled={this.state.fstdis} value={this.state.radvalue4}>
                                <Radio value={1}>放款成功</Radio>
                                <Radio value={2}>线上全额还款</Radio>
                                <Radio value={3}>邀请的朋友首次放款成功</Radio>
                                <Radio value={4}>邀请的朋友首次结清</Radio>
                                <Radio value={5}>卡认证成功</Radio>
                            </Radio.Group>  
                        </div>
                    }
                     {this.state.special&&
                         <div><textarea className='product_select' disabled={this.state.fstdis} value={this.state.scdText} onChange={this.scdTextOnchange} style={{height:100,backgroundColor:this.state.fstdis==false?"#fff":"#f5f5f5"}}/></div>
                    }
                     {this.state.Import&&
                         <div>
                         <Upload  {...props}>
                         <Button disabled={this.state.fstdis} onClick={this.changeupload}>
                             <Icon type="upload" /> 选择文件
                         </Button>
                         </Upload>
                     </div>
                    }
                    </div>
                </div>
            </div>
            <div className='inside_table'><Table columns={columns}  dataSource={this.state.tableData} loading={this.state.boolean}  pagination={false}/></div>
            {this.state.buttonshow&&
            <div>
                <button className='inside_sure common_color' onClick={this.sure}>确认</button>
                <button className='inside_cancel' onClick={this.cancel}>取消</button>
            </div>
            }
            <Modal
                title={this.state.modaltitle }
                visible={this.state.scdvisible} 
                onOk={this.handleOk}
                width={600}
                onCancel={this.handleCancel}
                >
                 {/* <input value={this.state.addpid} style={{display:'none'}}/> */}  
               
                <div className='banner_out_div'>
                    <div className='inside_modal_left_div'>
                        <p className='banner_left_p' style={{lineHeight:'30px',color:'#333333',fontWeight:'bold'}}>全部用户:</p>
                        <p className='banner_left_p' style={{lineHeight:'30px',color:'#333333',fontWeight:'bold'}}>新用户:</p>
                        <p className='banner_left_p' style={{lineHeight:2,color:'#333333',fontWeight:'bold'}}>老用户:</p>
                        <p className='banner_left_p' style={{height:'33px',color:'#333333',fontWeight:'bold'}}></p>
                        <p className='banner_left_p' style={{lineHeight:2,color:'#333333',fontWeight:'bold'}}>放款成功:</p>
                        <p className='banner_left_p' style={{lineHeight:1.7,color:'#333333',fontWeight:'bold'}}>结清还款:</p>
                        <p className='banner_left_p' style={{lineHeight:'57px',color:'#333333',fontWeight:'bold'}}>邀请的朋友首次放款成功:</p>
                        <p className='banner_left_p' style={{lineHeight:'23px',color:'#333333',fontWeight:'bold'}}>邀请的朋友首次结清:</p>
                        <p className='banner_left_p' style={{lineHeight:'40px',color:'#333333',fontWeight:'bold',marginTop:'32px'}}>特定用户书写规范:</p>
                        <p className='banner_left_p' style={{lineHeight:'12px',color:'#333333',fontWeight:'bold'}}>批量用户上传规范:</p>
                    </div> 
                    <div className='inside_modal_right_div'>
                    <p className='banner_left_p' style={{lineHeight:'30px'}}>指活动有效期有过登陆的用户（当前活动仅发放一次）</p>
                        <p className='banner_left_p' style={{lineHeight:'30px'}}> 指活动有效期内注册的用户</p>
                        <p className='banner_left_p' style={{lineHeight:'30px'}}>指活动有效期内正常和提前结清的客户， 在活动之前有过成功贷款记录（活动期间一个客户仅发送一次优惠券）</p>
                        <p className='banner_left_p' style={{lineHeight:'30px'}}>指活动有效期内注册的客户，首次放款成功</p>
                        <p className='banner_left_p' style={{lineHeight:'20px'}}>指活动有效期内，线上全额结清还款成功后（如是使用优惠券后的结清也算全额结清）</p>
                        <p className='banner_left_p' style={{lineHeight:'20px'}}>指活动有效期内，邀请方发出邀请，且被邀请的朋友在活动有效期内首次放款成功，那么将发放给邀请方优惠券</p>
                        <p className='banner_left_p' style={{lineHeight:'20px'}}>指活动有效期内，邀请方发出邀请， 且被邀请的朋友在活动有效期内首次结清（含提前，正常，逾期结清），那么将发放给邀请方优惠券</p>
                        <p className='banner_left_p' style={{lineHeight:'30px'}}>输入框内直接写手机号，两手机号间用英文逗号字符隔开;</p>
                        <p className='banner_left_p' style={{lineHeight:'20px'}}>上传文件为CSV格式，无需列名;</p>
                    </div>
                </div>
                <p className='modal_last_p'>所有的额度优惠券不能提现，均只有线上还款时才能使用，线下还款不能使用。 提前还款/正常还款可用，逾期还款不可用。一次只能用一张特定用户和批量导入必须是自动发放形式，且一个客户只能发送一次</p>

                </Modal>
        </div>
      );
    }
  }
  export default (Edit)