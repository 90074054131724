import React, { Component } from 'react'
import { Table, Pagination, Modal,Button, Input, message,Radio } from 'antd';
import {validateNumber} from '../../../utils/validate'
import {getsmsData,editsmsData} from '../../../api/systemApi'
import Schema from 'async-validator'
import axios from 'axios'
const rules={
    threshold:[//阀值
        {required: true, message: 'please enter threshold '},
       {validator:validateNumber}
    ]
}
const { confirm } = Modal;
function showTotal(total) {
    return `Total ${total} items`;
}
class Tab2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 1,
            total: 1,
            pageSize: 10,
            tableData: []
        }
    }
    showConfirm=(record)=> { //编辑按钮前确认
        var that = this;
     confirm({
       title: 'Are you sure to perform the operation?',
       // icon: <ExclamationCircleOutlined />,
       // content: 'Some descriptions',
       onOk() {
           that.firsthandleOk();
       },
       onCancel() {
         console.log('Cancel');
       },
     });
   }
    edit = (record) => { //编辑表单
        this.setState({
            firstVisible: true,
            status:record.status,
            threshold: record.threshold,
           remark:record.remark,
            id: record.id,
            modelTitle: 'Edit SMS'
        })
    }
    getTableData = () => { //获取表格数据
        this.setState({
            boolean: true
        })
        getsmsData( {
            current: this.state.current,
            pageSize: this.state.pageSize,
            type: '2'
        }).then(res => {
            if (res.data.code === 200) {
                this.setState({
                    tableData: res.data.data.resultDate,
                    boolean: false,
                    length: res.data.data.page.total,
                    boolean: false
                })
            } else if (res.data.code == 800) {
                this.props.history.push({ pathname: "/" });
            } else {
                message.error(res.data.msg)
            }
        })
    }
    pageChange = (page) => { //分页变化
        this.setState({
            current: page
        }, () => {
            this.getTableData()
        })
    }
        //表单值双向绑定
        fstaddonChange = (e) => {
            let InputName = e.target.name;
            let InputValue = e.target.value;
            this.setState({
                [InputName]: InputValue.replace(/\s+/g, ""),
            })
        }
        //单选框绑定
        radioChange=(e)=>{
            let InputName = e.target.name;
            let InputValue = e.target.value;
            this.setState({
                [InputName]: InputValue,
            })
        }
    handleCancel = () => { //关闭弹窗
        this.setState({
            firstVisible: false,
            threshold: '',
            theme: '',
            copywriting: '',
            id:'',
            btnloading:false,
            modelTitle:"Add SMS",
        })
    }
    firsthandleOk=()=>{//表单提交
        const validator = new Schema(rules);
        validator.validate({ threshold:this.state.threshold}, (errors, fields) => {
            if (!errors) {
                //新增编辑同一接口
                this.setState({
                    btnloading: true
                })
                editsmsData({
                    id:this.state.id,
                    threshold:this.state.threshold,
                    status:this.state.status,
                    type:'2'
                }).then(res=>{
                    if (res.data.code == 200) {
                        this.setState({
                            btnloading: false,
                        })
                        message.success(res.data.msg);
                        this.getTableData();
                        this.handleCancel()
                    } else {
                        message.error(res.data.msg)
                        this.setState({
                            btnloading: false
                        })
                    }
                })
            } else {
             message.error(errors[0].message)
            }
        })
    }
    componentDidMount() { //生命周期
        this.getTableData();
    }
    
    render() {
        const columns = [
            {
                title: '规则Id',
                dataIndex: 'id',
                width: '6%',
                key: 'id',
            },
            {
                title: '规则说明',
                dataIndex: 'remark',
                key: 'remark',
            },
            {
                title: '阀值',
                dataIndex: 'threshold',
                key: 'threshold',
            },
            {
                title: '创建时间',
                dataIndex: 'createTime',
                key: 'createTime',
            },
            {
                title: '更新时间',
                dataIndex: 'updateTime',
                key: 'updateTime',
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                width: '6%',
                render(status) {
                    let config = {
                        '1': '上线',
                        '2': '下线'
                    }
                    return config[status]
                }
            },
            {
                title: '操作人',
                dataIndex: 'operatorName',
                key: 'operatorName',
                render: (value) => {
                    return value?value: '——'
                }
            },
            {
                title: '操作', dataIndex: '', pid: 'pid', key: 'z',
                render: (text, record) =>
                    <div>
                        <span>
                            <a className='edit_button' href="javascript:;" onClick={() => this.edit(record)}>编辑文案</a>
                        </span>
                    </div>

            },
        ];

        return (<div>
            <Table columns={columns} dataSource={this.state.tableData} loading={this.state.boolean} pagination={false} />
            <Pagination
                defaultCurrent={1}
                current={this.state.current}
                total={this.state.length}
                onChange={this.pageChange}
                showTotal={showTotal}
                pageSize={this.state.pageSize}
                style={{ textAlign: 'right', marginTop: 20 }}
            >
            </Pagination>
            <Modal
                // destroyOnClose={true}
                title={this.state.modelTitle}
                visible={this.state.firstVisible}
                onOk={this.showConfirm}
                onCancel={this.handleCancel}
                footer={[
                    <Button key="back" onClick={this.handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" loading={this.state.btnloading} onClick={this.showConfirm}>
                        Confirm
                    </Button>,
                ]}
            >
                <div className={'formItem'}>
                    <label>规则ID:</label>
                    <Input value={this.state.id} name="id" disabled placeholder='正整数'  style={{ marginTop: 10 }} onChange={this.fstaddonChange} />
                </div>
                <div className={'formItem'}>
                    <label>规则说明:</label>
                    <Input value={this.state.remark} disabled style={{ marginTop: 10 }} />
                </div>
                <div className={'formItem'}>
                    <label>阀值:</label>
                    <Input value={this.state.threshold} name="threshold" placeholder='正整数' allowClear style={{ marginTop: 10 }} onChange={this.fstaddonChange} maxLength={10}/>
                </div>
                <div className={'formItem'}>
                    <label>状态:</label>
                    <Radio.Group name="status" onChange={this.radioChange} value={this.state.status}>
                        <Radio value={1}>上线</Radio>
                        <Radio value={2}>下线</Radio>
                    </Radio.Group>
                </div>
            </Modal>
        </div>);
    }
}

export default Tab2;