/*eslint-disable*/
import React from "react";
import {
  Input,
  Button,
  Table,
  Modal,
  Pagination,
  DatePicker,
  Select,
  message,
  Row,
  Col,
} from "antd";
import moment from "moment";
import axios from "axios";
import "./index.css";
import { withRouter } from "react-router-dom";
import EventEmitter from "../../../utils/events";
import { thousandSignNumber, formatT0Time, getCollectionResult } from "../../../utils/index";

function showTotal(total) {
  return `Total ${total} items`;
}

var collector = [];
var getSelect = [];
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Search } = Input;
const dateFormat = "YYYY-MM-DD" || undefined;

class RemindOrderManagement extends React.Component {
  constructor(props) {
    super(props);
    (this.stateSelectedOverdue = ""),
      (this.stateSelectedOverdueGrade = ""),
      (this.state = {
        current: 1,
        pageSize: 10,
        timeSelect: "",
        selectedRowKeys: [],
        boolean: true,
        debit_money: "",
        fstInput: "",
        reviewbutton: { display: "block" },
        detailsbutton: { display: "block" },
        oldOrNew: "",
        searchDistributionState:'',
        selectedOverdue: "",
        selectedOverdueGrade: "",
        tipsvisible: false,
        collectorList: [],
        selectedCollector: "",
      });
  }

  componentDidMount() {
    this.searchClick();
    this.select();
  }

  fstInputChange = (e) => {
    var value = e.target.value;
    this.setState({
      fstInput: value.replace(/^(0+)|[^\d]+/g, ""),
    });
  };
  //获取select
  select = () => {
    axios.post(`/api/remindData/getRemindUsers`, {}).then((res) => {
      if (res.data.code === 200) {
        getSelect = [];
        let data = res.data.data.resultDate;
        for (let i = 0; i < data.length; i++) {
          if (i == 0) {
            getSelect.push(<option value="">please select one</option>);
          }
          getSelect.push(
            <option value={data[i].id}>{data[i].userName}</option>
          );
          // console.log('select',collector)
          this.setState({
            getSelect: getSelect,
          });
        }
      } else if (res.data.code == 800) {
        this.props.history.push({ pathname: "/" });
      } else {
      }
    });
  };
  thdhandleOk = (e) => {
    let arr = this.state.selectedRowKeys;
    let lastarr = arr.join(",");

    axios
      .post(`/api/remindData/saveRemindDistribution`, {
        id: lastarr,
        overdueGrade: this.stateSelectedOverdue,
        uid: this.state.selectedCollector,
      })
      .then((res) => {
        if (res.data.code == 200) {
          this.setState({
            boolean: true,
            selectedCollector: "",
          });
          this.reloadClick();
          message.success(res.data.msg);
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
          message.error(res.data.msg);
        }
      });
    this.setState({
      allocateVisible: false,
      scdSelect: "",
      id: "",
      selectedRowKeys: "",
      selectedCollector: "",
    });
  };
  handleCancel = (e) => {
    console.log(e);
    this.setState({
      allocateVisible: false,
      fstvisible: false,
      scdSelect: "",
      id: "",
      selectedRowKeys: "",
      selectedCollector: "",
    });
  };

  selectScd(e) {
    var value = e.target.value;
    this.setState({
      scdSelectt: value,
    });
  }

  //订单输入框
  searchIpt(e) {
    var value = e.target.value;
    this.setState({ searchVal: value.replace(/\s+/g, "") });
  }
  //号码输入框
  searchIpt1(e) {
    var value = e.target.value;
    this.setState({ mobileNum: value.replace(/\s+/g, "") });
  }
  //输入框bvn
  handleSearchBvn(e) {
    var value = e.target.value;
    this.setState({ searchBvn: value.replace(/\s+/g, "") });
  }
  oldOrderNew(e) {
    var value = e.target.value;
    this.setState({
      oldOrNew: value,
    });
  }

  //选择collector
  handleSearchDistributionState(e) {
    var value = e.target.value;
    this.setState({
      searchDistributionState: value,
    });
  }

  handleAllocatedTimeOnChange = (dates, dateStrings) => {
    this.setState({
      allocatedBeginTime: dateStrings[0],
      allocatedEndTime: dateStrings[1],
    });
  };

  //页码改变时触发
  pageChange = (page) => {
    this.setState(
      {
        current: page,
      },
      () => {
        this.searchClick();
      }
    );
  };

  //重置按钮
  reloadClick(e) {
    this.setState(
      {
        scdSelect: "",
        scdSelectt: "",
        fouSelect: "",
        searchVal: "",
        mobileNum: "",
        from: undefined,
        to: undefined,
        id: "",
        reduceAmt_val: "",
        reduceRson: "",
        fsttextarea: "",
        boolean: true,
        oldOrNew: "",
        fstInput: "",
        timeSelect: "",
      },
      () => {
        this.searchClick();
      }
    );
  }

  //选择collector
  selectscd(e) {
    var value = e.target.value;
    this.setState({
      scdSelect: value,
    });
  }

  //控制审核按钮model
  showFstModal = (record) => {
    var id = record.id;
    var uid = record.uid;
    this.setState({
      fstinpt: id,
      scdinpt: uid,
      fstvisible: true,
      debit_money: record.fee,
      mostvalue: record.fee,
    });
    // this.gradeChange()
  };
  onShowSizeChange = (current, pageSize) => {
    this.setState(
      {
        pageSize: pageSize,
      },
      () => {
        this.searchClick();
      }
    );
  };

  handleCollectorChange = (e) => {
    const { value } = e.target;
    this.setState({
      selectedCollector: value.replace(/(^\s*)|(\s*$)/g, ""),
    });
  };

  redistribution = () => {
    if (this.state.selectedRowKeys.length < 1) {
      message.error("please select one");
      return false;
    } else {
      this.stateSelectedOverdue = "";
      this.stateSelectedOverdueGrade = "";
    }

    if (this.state.selectedRows.length == 1) {
      if (this.state.selectedRows[0].repayment_count == 0) {
        this.stateSelectedOverdue = 22;
        this.stateSelectedOverdueGrade = "S0-new";
      } else {
        this.stateSelectedOverdue = 21;
        this.stateSelectedOverdueGrade = "S0-old";
      }
    } else {
      for (let i = 0; i < this.state.selectedRows.length - 1; i++) {
        if (this.state.selectedRows[0].repayment_count == 0) {
          if (this.state.selectedRows[i + 1].repayment_count !== 0) {
            this.setState({
              tipsvisible: true,
            });
            return;
          } else {
            this.stateSelectedOverdue = 22;
            this.stateSelectedOverdueGrade = "S0-new";
          }
        } else {
          if (this.state.selectedRows[i + 1].repayment_count == 0) {
            this.setState({
              tipsvisible: true,
            });
            return;
          } else {
            this.stateSelectedOverdue = 21;
            this.stateSelectedOverdueGrade = "S0-old";
          }
        }
      }
    }

    if (this.stateSelectedOverdue) {
      axios
        .post(`/api/system/listCollector`, {
          overdueGrade: this.stateSelectedOverdue,
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.setState({
              collectorList: res.data.data,
              selectedOverdue: this.state.selectedRows[0].overdue,
              allocateVisible: true,
            });
          } else if (res.data.code == 800) {
            this.props.history.push({ pathname: "/" });
          }
        });
    } else {
      message.error("The operation is too frequent, please try again later");
    }

    // this.setState({
    //   allocateVisible: true,
    // });
    // axios.post(`/api/remindData/getRemindUsers`, {}).then((res) => {
    //   if (res.data.code === 200) {
    //     collector = [];
    //     let data = res.data.data.resultDate;
    //     for (let i = 0; i < data.length; i++) {
    //       if (i == 0) {
    //         collector.push(<option value=""></option>);
    //       }
    //       collector.push(
    //         <option value={data[i].id}>{data[i].userName}</option>
    //       );
    //       // console.log('select',collector)
    //       this.setState({
    //         collector: collector,
    //       });
    //     }
    //   } else if (res.data.code == 800) {
    //     this.props.history.push({ pathname: "/" });
    //   }
    // });
    // console.log(this.state.id);
  };

  //第个modal提交按钮
  fsthandleOk = (e) => {
    if (this.state.debit_money < 100) {
      alert("Debit amount must exceed 100");
      return false;
    }
    var data = {};
    if (this.state.debit_money == this.state.mostvalue) {
      data = {
        id: this.state.fstinpt,
        uid: this.state.scdinpt,
      };
    } else {
      data = {
        money: this.state.debit_money,
        id: this.state.fstinpt,
        uid: this.state.scdinpt,
      };
    }
    // console.log(e);//
    axios.post(`/api/loanUrge/withhold`, data).then((res) => {
      if (res.data.code == 200) {
        this.searchClick();
        alert(res.data.msg);
      } else if (res.data.code == 800) {
        this.props.history.push({ pathname: "/" });
      } else {
        alert(res.data.msg);
      }
    });
    this.setState({
      fstvisible: false,
    });
  };
  //搜索按钮
  searchClick = () => {
    this.setState({
      boolean: true,
    });
    axios
      .post(`/api/remindData/getRemindOrderManage`, {
        pageSize: this.state.pageSize,
        current: this.state.current,
        indentNo: this.state.searchVal,
        mobile: this.state.mobileNum,
        bvn: this.state.searchBvn,
        allocatedBeginTime: this.state.allocatedBeginTime,
        allocatedEndTime: this.state.allocatedEndTime,
        customerUserid: this.state.scdSelectt,
        appCode: this.state.fouSelect,
        remainBalance: this.state.fstInput,
        searchTime: this.state.timeSelect,
        newOrOld: this.state.oldOrNew,
        isDistributed: this.state.searchDistributionState,
      })
      .then((res) => {
        if (res.data.code === 200) {
          const data1 = [];
          message.success(res.data.msg);
          let data = res.data.data.resultDate;
          if (data.length < 1) {
            this.setState({
              boolean: false,
            });
          }

          this.setState({
            tableData: res.data.data.resultDate,
            length: res.data.data.page.total,
            boolean: false,
          });
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
        }
      });
  };
  debitMoney = (e) => {
    var value = e.target.value.replace(/[^0-9]/g, "");
    if (value > this.state.mostvalue) {
      value = this.state.mostvalue;
    }
    this.setState({
      debit_money: value,
    });
  };

  text(record, item) {
    window.location =
      "/#/details/index?id=" +
      record.id +
      "&remind=" +
      true +
      "&uid=" +
      record.uid;
    let test = [
      {
        path: "/details/index",
        key: `/details/index/${record.id}/${record.uid}`,
        number: `${record.id}/${record.uid}`,
      },
    ];
    EventEmitter.trigger("btn", test[0]);
    EventEmitter.trigger("idanduid", test[0].number);
  }

  selectFou(e) {
    var value = e.target.value;
    this.setState({
      fouSelect: value,
    });
  }

  selectTime = (e) => {
    var value = e.target.value;
    this.setState({
      timeSelect: value,
    });
  };
  selectThd = () => {};
  special = (params) => {
    var value = params.remind_object;
    if (value == 1) {
      return <span>client</span>;
    }
    if (value == 2) {
      return <span>first relationship</span>;
    }
    if (value == 3) {
      return <span>secound reletionship</span>;
    }
    if (value == 4) {
      return <span>company phone</span>;
    }
    if (value == 5) {
      return <span>friends of client</span>;
    }
    if (value == 6) {
      return <span>colleagues of client</span>;
    }
    if (value == 7) {
      return <span>family of client</span>;
    }
    if (value == 8) {
      return <span>others</span>;
    } else {
      return "";
    }
  };

  handleTipsModalOk = () => {
    this.setState({
      tipsvisible: false,
    });
  };
  handleTipsModalCancel = () => {
    this.setState({
      tipsvisible: false,
    });
  };

  render() {
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: (selectedRowkeys, selectedRows) => {
        this.setState({
          selectedRowKeys: selectedRowkeys,
          selectedRows: selectedRows,
        });
      },
      columnWidth: 40,
    };
    const columns = [
      {
        title: "Order No",
        dataIndex: "indent_no",
        key: "indent_no",
      },
      {
        title: "Customer name",
        dataIndex: "first_name",
        key: "first_name",
      },
      {
        title: "phone",
        dataIndex: "mobile",
        key: "mobile",
      },
      {
        title: "BVN",
        dataIndex: "dentity_card",
        key: "dentity_card",
      },
      {
        title: "loan amount",
        dataIndex: "balance",
        key: "balance",
        render: (text, record) => thousandSignNumber(text / 100),
      },
      {
        title: "loan terms",
        dataIndex: "cycle",
        key: "cycle",
      },
      {
        title: "should repayment amount",
        dataIndex: "amountDue",
        key: "amountDue",
        render: (text, record) => thousandSignNumber(text / 100),
      },

      {
        title: "should repayment time",
        dataIndex: "shouldback_time",
        key: "shouldback_time",
        render: (text, record) => formatT0Time(text),
      },

      {
        title: "repayment amount",
        dataIndex: "actualback_amt",
        key: "actualback_amt",
        render: (text, record) => thousandSignNumber(text / 100),
      },

      {
        title: "remaining balance",
        dataIndex: "fee",
        key: "fee",
        render: (text, record) => thousandSignNumber(text / 100),
      },
      {
        title: "Should extend",
        dataIndex: "extensionFees",
        key: "extensionFees",
        render: (text, record) => thousandSignNumber(text / 100),
      },

      {
        title: "Success Repayment Count",
        dataIndex: "repayment_count",
        key: "repayment_count",
      },

      {
        title: "Distribution state",
        dataIndex: "distributionstate",
        key: "distributionstate",
        render: (text, record) => (
          <span>{record.allocate_time ? "distributed" : "undistributed"}</span>
        ),
      },
      {
        title: "Allocate time",
        dataIndex: "allocate_time",
        key: "allocate_time",
        render: (text, record) => formatT0Time(text),
      },
      {
        title: "S0 Collector",
        dataIndex: "customer",
        key: "customer",
      },
      {
        title: 'Last Remind time',
        dataIndex: 'remindedDate',
        key: 'remindedDate',
        render: (text, record) => formatT0Time(text),
      },
      {
        title: 'Reminded result',
        dataIndex: 'urgeResut',
        key: 'urgeResut',
        render: (text, record) => getCollectionResult(text),
      },
      {
        title: 'Reminded details',
        dataIndex: 'remind_details',
        key: 'remind_details',
      },
      {
        title: "Operation",
        dataIndex: "operating",
        key: "operating",
        width: 70,
        id: "id",
        uid: "uid",
        fixed: "right",
        render: (text, record, index) => (
          <span style={{ display: "flex", flexDirection: "row" }}>
            {/* <Button
              type="primary"
              className="reviewbutton"
              style={this.state.reviewbutton}
              size="small"
              onClick={() => this.showFstModal(record)}
            >
              debit
            </Button> */}
            <Button
              type="primary"
              style={this.state.detailsbutton}
              className="detailsbutton"
              size="small"
              onClick={() => this.text(record)}
            >
              details
            </Button>
          </span>
        ),
      },
    ];
    return (
      <div className="overduepaid">
        <div className="overduepaid_top">
          <div className="overduepaid_mid">
            <Row className="search_form">
              <Col span={8}>
                <div className="search">
                  <p className="form_label">Order No:</p>
                  <Search
                    placeholder="Order No"
                    onSearch={(value) => console.log(value)}
                    style={{ width: 290 }}
                    value={this.state.searchVal}
                    onChange={this.searchIpt.bind(this)}
                  />
                </div>
              </Col>
              <Col span={8}>
                <div className="search">
                  <p className="form_label">Phone:</p>
                  <Search
                    placeholder="Phone"
                    onSearch={(value) => console.log(value)}
                    style={{ width: 290 }}
                    value={this.state.mobileNum}
                    onChange={this.searchIpt1.bind(this)}
                  />
                </div>
              </Col>
              <Col span={8}>
                <div className="search">
                  <p className="form_label">Bvn:</p>
                  <Search
                    placeholder="Bvn"
                    onSearch={(value) => console.log(value)}
                    style={{ width: 290 }}
                    value={this.state.searchBvn}
                    onChange={this.handleSearchBvn.bind(this)}
                  />
                </div>
              </Col>
              <Col span={8}>
                <div className="top_left">
                  <p className="form_label">S0collector:</p>
                  <select
                    className="form_select"
                    id="getselect"
                    value={this.state.scdSelectt}
                    onChange={this.selectScd.bind(this)}
                  >
                    {getSelect}
                  </select>
                </div>
              </Col>
              <Col span={8}>
                <div className="top_right">
                  <p className="form_label">Should back time:</p>
                  <select
                    className="form_select"
                    value={this.state.timeSelect}
                    onChange={this.selectTime.bind(this)}
                  >
                    <option value="">Today</option>
                    <option value="1">Tomorrow</option>
                    <option value="2">Day after tomorrow</option>
                  </select>
                </div>
              </Col>

              <Col span={8}>
                <div className="top_right">
                  <p className="form_label" style={{ width: 120 }}>
                    oldOrNew:
                  </p>
                  <select
                    className="form_select"
                    value={this.state.oldOrNew}
                    onChange={this.oldOrderNew.bind(this)}
                  >
                    <option value="">all</option>
                    <option value="1">new User</option>
                    <option value="2">old User</option>
                  </select>
                </div>
              </Col>
              <Col span={8}>
                <div className="top_right">
                  <p className="form_label">Distribution status:</p>
                  <select
                    className="form_select"
                    id="getselect"
                    value={this.state.searchDistributionState}
                    onChange={this.handleSearchDistributionState.bind(this)}
                  >
                    <option value="">all</option>
                    <option value="1">allocated</option>
                    <option value="2">undistributed</option>
                  </select>
                </div>
              </Col>
              <Col span={8}>
                <div className="rangepick">
                  <p className="form_label">Allocated time:</p>
                  <RangePicker
                    style={{ width: 290 }}
                    ranges={{
                      Today: [moment(), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                    }}
                    showTime
                    format={dateFormat}
                    onChange={this.handleAllocatedTimeOnChange}
                    value={
                      this.state.allocatedBeginTime === undefined ||
                      this.state.allocatedEndTime === undefined ||
                      this.state.allocatedBeginTime === "" ||
                      this.state.allocatedEndTime === ""
                        ? null
                        : [
                            moment(this.state.allocatedBeginTime, dateFormat),
                            moment(this.state.allocatedEndTime, dateFormat),
                          ]
                    }
                  />
                </div>
              </Col>
            </Row>
            <div className="bottom">
              <Button
                type="primary"
                className="common_color"
                style={{
                  width: 170,
                  marginRight: 20,
                  height: 40,
                  border: "none",
                }}
                onClick={this.searchClick.bind(this)}
              >
                Search
              </Button>
              {/* <Button
                type="primary"
                style={{
                  width: 170,
                  height: 40,
                  backgroundColor: "#1CA9BA",
                  border: "none",
                }}
                onClick={this.reloadClick.bind(this)}
              >
                Refresh
              </Button> */}
              <Button
                className="common_color_scd"
                style={{
                  marginLeft: 20,
                  width: 170,
                  height: 40,
                  border: "none",
                }}
                onClick={this.redistribution}
                type="primary"
              >
                Batch re-allocate
              </Button>
            </div>
          </div>
        </div>
        <div className="overdue_table">
          <Table
            columns={columns}
            dataSource={this.state.tableData}
            loading={this.state.boolean}
            scroll={{ x: "100%" }}
            rowKey={(record) => record.id}
            rowSelection={rowSelection}
            onChange={this.handleChange}
            pagination={false}
          />
        </div>
        <Pagination
          showSizeChanger //展示改变pagesize样式
          onShowSizeChange={this.onShowSizeChange} //pagesize改变时回调函数
          pageSizeOptions={["10", "50", "100"]} //设置pagesize区间
          defaultCurrent={1}
          current={this.state.current}
          total={this.state.length}
          onChange={this.pageChange}
          pageSize={this.state.pageSize}
          showTotal={showTotal}
          style={{ textAlign: "right", marginTop: 20 }}
        />
        <Modal
          title="add record"
          visible={this.state.allocateVisible}
          onOk={this.thdhandleOk}
          onCancel={this.handleCancel}
        >
          <div>
            <lable style={{ fontSize: 15, fontWeight: "bold" }}>
              Collection level:{" "}
            </lable>
            <span>{this.stateSelectedOverdueGrade}</span>
            <br></br>
          </div>
          <div>
            <lable style={{ fontSize: 15, fontWeight: "bold" }}>
              Collector:{" "}
            </lable>
            {/* <Select
              style={{ width: 120 }}
              value={this.state.selectedCollector}
              onChange={this.handleCollectorChange}
            >
              {this.state.collectorList.map((item, i) => (
                <Option key={i} value={item.login_name}>
                  {item.login_name}
                </Option>
              ))}
            </Select> */}
            <Input
              style={{ width: 200 }}
              value={this.state.selectedCollector}
              onChange={this.handleCollectorChange.bind(this)}
            ></Input>
          </div>
        </Modal>
        <Modal
          title="debet"
          visible={this.state.fstvisible}
          onOk={this.fsthandleOk}
          onCancel={this.handleCancel}
        >
          <input
            type="text"
            value={this.state.fstinpt}
            style={{ display: "none" }}
          />
          <input
            type="text"
            value={this.state.scdinpt}
            style={{ display: "none" }}
          />
          <div className="both_div">
            <span>debit amount:</span>
            <input
              value={this.state.debit_money}
              onChange={this.debitMoney}
              className="reduce_inpt"
            />
          </div>
        </Modal>

        {/* 您批量选择的订单类型等级不一致，请重新选择 */}
        <Modal
          title="Tips"
          visible={this.state.tipsvisible}
          onOk={this.handleTipsModalOk}
          onCancel={this.handleTipsModalCancel}
        >
          <p>
            The order type level you selected in bulk is not consistent, please
            re-select it
          </p>
        </Modal>
      </div>
    );
  }
}

export default withRouter(RemindOrderManagement);
