import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import Schema from 'async-validator'
import moment from 'moment';
import { Table, Switch, Pagination, Button, Modal, Input, message, DatePicker, } from 'antd';
import { collectSmsData, editSmsData } from '../../../api/systemApi'
import location_red from '../../../../public/img/rule.png';
import { validateSymbol, validateNumber } from '../../../utils/validate'
const { TextArea } = Input;
const { confirm } = Modal;
const { Search } = Input;
const dateFormat = 'YYYY-MM-DD' || undefined;
const rules = {
    threshold: [//阀值
        { required: true, message: 'please enter threshold ' },
        // { validator: validateNumber }
    ],
    copywriting: [//文案
        { required: true, message: 'please enter copywriting ' },
        // {validator:validateSymbol}
    ],
}
class Tab4 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 1,
            total: 1,
            pageSize: 10,
            tableData: [],
            boolean: true,
            btnLoading: false,
            wordLength: 0
        }
    }

    showConfirm = (record) => { //编辑按钮前确认
        var that = this;
        confirm({
            title: 'Are you sure to perform the operation?',
            // icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            onOk() {
                that.firsthandleOk();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }
    statusComfirm = (value, record, ifCheck, statusKey) => { //切换状态按钮前确认
        var that = this;
        confirm({
            title: 'Are you sure to perform the operation?',
            // icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            onOk() {
                that.outOnChange(value, record, ifCheck);
            },
            onCancel() {
                console.log('cancel')
            },
        });
    }

    componentDidMount() { //生命周期
        this.getTableData();
    }
    showDialog() { //打开新增弹窗
        this.setState({
            modelTitle: '新增',
            firstVisible: true
        })
    }
    handleCancel = () => { //关闭弹窗
        this.setState({
            firstVisible: false,
            threshold: '',
            theme: '',
            copywriting: '',
            id: '',
            scdvisible: false,
            btnLoading: false,
            modelTitle: "Add SMS",
            wordLength: 0
        })

    }
    openDescribe = () => {
        console.log(1234)
        this.setState({
            scdvisible: true
        })
    }
    getTableData = () => { //获取表格数据
        this.setState({
            boolean: true
        })
        collectSmsData({
            theme: this.state.searchVal1,
            status: this.state.searchVal2,
            threshold: this.state.searchVal3,
        }).then(res => {
            if (res.data.code === 200) {
                const data1 = [];
                const data = res.data.data
                for (let i = 0; i < data.length; i++) {
                    data[i].key = i;
                    data1.push({
                        key: i + 1,
                        id: data[i].id,
                        copywriting: data[i].copywriting,
                        theme: data[i].theme,
                        remark: data[i].remark,
                        threshold: data[i].threshold,
                        status: data[i].status,
                        type: data[i].type,
                        opratorId: data[i].opratorId,
                        operatorName: data[i].operatorName,
                        createTime: data[i].createTime,
                        updateTime: data[i].updateTime
                    })
                }
                this.setState({
                    tableData: data1,
                    boolean: false,
                })
            } else if (res.data.code == 800) {
                this.props.history.push({ pathname: "/" });
            }
        })
    }
    //表单值双向绑定
    fstaddonChange = (e) => {
        let InputName = e.target.name;
        let InputValue = e.target.value;
        this.setState({
            [InputName]: InputValue.replace(/\s+/g, ""),
        })
    }
    fstaddonChange1 = (e) => { //可以输入空格
        let InputName = e.target.name;
        let InputValue = e.target.value;
        let wordLength = e.target.value.length;
        this.setState({
            [InputName]: InputValue,
            wordLength: wordLength
        })
    }
    //表单回显
    edit = (record) => {
        this.setState({
            firstVisible: true,
            threshold: record.threshold,
            copywriting: record.copywriting,
            id: record.id,
            modelTitle: '编辑',
            wordLength: record.copywriting.length
        })
    }
    //状态切换
    outOnChange = (value, record, ifCheck) => {
        editSmsData({
            id: record.id,
            status: ifCheck == true ? 1 : 2
        })
            .then(res => {
                if (res.data.code === 200) {
                    message.success(res.data.msg)
                    this.getTableData();
                } else if (res.data.code == 800) {
                    this.props.history.push({ pathname: "/" });
                } else {
                    message.error(res.data.msg)
                }
            })
    }
    //重置搜索项
    refreshList = () => {
        this.setState({
            searchVal1: '',
            searchVal2: '',
            searchVal3: '',
        }, () => {
            this.getTableData()
        })
    }
    //表单提交
    firsthandleOk = () => {
        let params = {
            threshold: this.state.threshold,
            copywriting: this.state.copywriting,
        }
        if (this.state.id != '') { //如果是编辑的话，添加id
            params['id'] = this.state.id
        }
        const validator = new Schema(rules);
        validator.validate({ threshold: this.state.threshold, copywriting: this.state.copywriting }, (errors, fields) => {
            if (!errors) {
                //新增编辑同一接口
                this.setState({
                    btnloading: true
                })
                editSmsData({ ...params }).then(res => {
                    if (res.data.code == 200) {
                        this.setState({
                            btnloading: false
                        })
                        message.success(res.data.msg);
                        this.getTableData();
                        this.handleCancel()
                    } else {
                        message.error(res.data.msg)
                        this.setState({
                            btnloading: false
                        })
                    }
                })
            } else {
                message.error(errors[0].message)
            }
        })

    }


    render() {
        const columns = [
            {
                title: '序号',
                dataIndex: 'key',
                key: 'key',
            },
            {
                title: '编号',
                dataIndex: 'theme',
                key: 'theme',
            },
            {
                title: '催收等级',
                dataIndex: 'threshold',
                key: 'threshold',
                render(value) {
                    let config = {
                        '0': 'S0',
                        '1': 'S1',
                        '2': 'S2',
                        '3': 'M1',
                        '4': 'M2',
                    }
                    return config[value]
                }
            },
            {
                title: '短信内容',
                dataIndex: 'copywriting',
                key: 'copywriting',
                width: '36%'
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                width: '6%',
                render: (value, record, ifCheck) => {
                    return <span>
                        <Switch onChange={(ifCheck) => { this.statusComfirm(value, record, ifCheck) }} checked={record.status == 1 ? true : false} />
                    </span>
                }
            },
            {
                title: '最近更新时间',
                dataIndex: 'updateTime',
                key: 'updateTime',
                render: (value) => {
                    return value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '——'
                }
            },
            {
                title: '最近操作人',
                dataIndex: 'operatorName',
                key: 'operatorName',
            },
            {
                title: '操作', width: '16.5%', dataIndex: '', pid: 'pid', key: 'z',
                render: (text, record) =>
                    <div>
                        <span>
                            <a className='edit_button' href="javascript:;" onClick={() => this.edit(record)}>编辑</a>
                        </span>
                    </div>

            },
        ];
        return (
            <div>

                <div className="collectio_order_mid">
                    <div className="top collection_order_top">
                        <div className="top_left" style={{ width: '30%', flexShrink: 1 }}>
                            <div className="search">
                                <span className="description left">编号:</span>
                                <Search
                                    placeholder="编号"
                                    onSearch={value => console.log(value)}
                                    style={{ width: 290 }}
                                    value={this.state.searchVal1}
                                    onChange={(e) => { this.setState({ searchVal1: e.target.value.replace(/\s+/g, "") }) }}
                                />
                            </div>
                        </div>

                        <div className="top_mid" style={{ width: '30%', flexShrink: 2, marginLeft: '5%', marginRight: '5%' }}>
                            <div className="rangepick">
                                <span className="description mid_span">状态:</span>
                                <select className="form_select" value={this.state.searchVal2}
                                    onChange={(e) => { this.setState({ searchVal2: e.target.value }) }}
                                >
                                    <option value="">全部</option>
                                    <option value="1">上线</option>
                                    <option value="2">下线</option>
                                </select>
                            </div>
                        </div>
                        <div className="top_right" style={{ width: '30%', flexShrink: 1 }}>
                            <span className="description mid_span">催收等级:</span>
                            <select className="form_select" value={this.state.searchVal3}
                                onChange={(e) => { this.setState({ searchVal3: e.target.value }) }}
                            >
                                <option value="">请选择</option>
                                <option value="0">S0</option>
                                <option value="1">S1</option>
                                <option value="2">S2</option>
                                <option value="3">M1</option>
                                <option value="4">M2</option>
                            </select>
                        </div>
                    </div>


                    <div className="bottom" style={{ justifyContent: 'flex-end', paddingLeft: '20px', paddingRight: '20px', alignItems: 'center' }}>
                        <Button type="primary" style={{ width: 170, marginRight: 20, height: 40,  border: 'none' }} onClick={this.showDialog.bind(this)} className="common_color">新增</Button>
                        <Button type="primary" style={{ width: 170, marginRight: 20, height: 40,  border: 'none' }} onClick={this.getTableData}>搜索</Button>
                        <Button type="primary" style={{ width: 170, height: 40, border: 'none' }} className="common_color_thd" onClick={this.refreshList}>刷新</Button>

                        <Button type="link" block style={{ color: '#169DB2', width: 170, height: 40, }} onClick={this.openDescribe}>
                            <img src={location_red} />
                            <span >参数说明</span>
                        </Button>

                    </div>
                </div>
                <div className="smsTable">
                <Table columns={columns} dataSource={this.state.tableData} loading={this.state.boolean} pagination={false} />
                </div>
                {/* 弹窗 */}
                <Modal
                    // destroyOnClose={true}
                    title={this.state.modelTitle}
                    visible={this.state.firstVisible}
                    onOk={this.showConfirm}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            Cancel
                        </Button>,
                        <Button key="submit" type="primary" loading={this.state.btnloading} onClick={this.showConfirm}>
                            Confirm
                        </Button>,
                    ]}
                >

                    <div className={'formItem'}>
                        <label>催收等级:</label>
                        <select value={this.state.threshold} allowClear className='product_select' name="threshold" onChange={this.fstaddonChange} disabled={this.state.id ? true : false}>
                            <option value=''>请选择</option>
                            <option value='0'>S0</option>
                            <option value='1'>S1</option>
                            <option value='2'>S2</option>
                            <option value='3'>M1</option>
                            <option value='4'>M2</option>
                        </select>
                    </div>
                    <div className={'formItem'} style={{ position: 'relative' }}>
                        <label>短信内容:</label>
                        <TextArea rows={4} value={this.state.copywriting} name="copywriting" onChange={this.fstaddonChange1} maxLength={310} />
                        <span style={{ position: 'absolute', right: 10, bottom: 0 }}>{this.state.wordLength}/310</span>
                    </div>
                </Modal>
                <Modal
                    title={this.state.modaltitle}
                    visible={this.state.scdvisible}
                    onOk={this.handleCancel}
                    width={600}
                    onCancel={this.handleCancel}
                >
                    {/* <input value={this.state.addpid} style={{display:'none'}}/> */}

                    <div className='banner_out_div'>
                        <div className='inside_modal_left_div'>
                            <p className='banner_left_p' style={{ lineHeight: '30px', color: '#333333', fontWeight: 'bold' }}>客户名字：</p>
                            <p className='banner_left_p' style={{ lineHeight: '30px', color: '#333333', fontWeight: 'bold' }}>应还日期：</p>
                            <p className='banner_left_p' style={{ lineHeight: 2, color: '#333333', fontWeight: 'bold' }}>应还总金额：</p>
                            <p className='banner_left_p' style={{ lineHeight: 2, color: '#333333', fontWeight: 'bold' }}>剩余应还金额：</p>
                            <p className='banner_left_p' style={{ lineHeight: 2, color: '#333333', fontWeight: 'bold' }}>逾期天数：</p>
                        </div>
                        <div className='inside_modal_right_div'>
                            <p className='banner_left_p' style={{ lineHeight: '30px' }}>firstName</p>
                            <p className='banner_left_p' style={{ lineHeight: '30px' }}>shouldbackTime</p>
                            <p className='banner_left_p' style={{ lineHeight: '30px' }}>shouldbackFee</p>
                            <p className='banner_left_p' style={{ lineHeight: '30px' }}>remainingBalance</p>
                            <p className='banner_left_p' style={{ lineHeight: '30px' }}>overdueDays</p>
                        </div>
                    </div>
                    <p className='modal_last_p'>注：在使用参数时，将参数使用英文大括号括来，如{'{firstname}'}</p>

                </Modal>
            </div>
        );
    }
}

export default withRouter(Tab4);