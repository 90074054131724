/*eslint-disable*/
import React from "react";
import {
  Input,
  Button,
  Table,
  Modal,
  Pagination,
  DatePicker,
  Tooltip,
  message,
} from "antd";
import "./index.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import EventEmitter from "../../utils/events";
import moment from "moment";
const { RangePicker } = DatePicker;
const { Search } = Input;
const dateFormat = "YYYY-MM-DD" || undefined;
function showTotal(total) {
  return `Total ${total} items`;
}

class RepaymentRecord extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fstSelect: "",
      modalinput: "",
      scdSelect: "",
      testSelect: "",
      sixSelect: "",
      fouSelect: "",
      searchVal: "",
      current: 1,
      modalscdinput: "",
      modalthdinput: "",
      datepiker: "",
      scddatepiker: "",
      fstinpt: "",
      disval: "",
      adjustdisval: "",
      adddisval: "",
      order_statues: "please select one",
      fsttextarea:
        "原订单还款超出应还，需抵扣客户其他订单(Use excess amount to deduct current order)",
      pageSize: 10,
      boolean: false,
      scdtextarea:
        "第三方反馈错(Third-party feedback error, the status was modified, and Amount was added)",
      thdtextarea:
        "流水状态正常，未更新订单状态(Active repayment amount added to order manually)",
      reviewreason: "",
    };
  }
  onShowSizeChange = (current, pageSize) => {
    this.setState(
      {
        pageSize: pageSize,
      },
      () => {
        this.searchClick();
      }
    );
  };
  //页码改变时触发
  pageChange = (page) => {
    this.setState(
      {
        current: page,
      },
      () => {
        this.searchClick();
      }
    );
  };
  rangeOnChange = (dates, dateStrings) => {
    this.setState({ from: dateStrings[0], to: dateStrings[1] });
  };
  dateonChange = (date, dateString) => {
    this.setState({ datepiker: dateString });
  };
  scddateonChange = (date, dateString) => {
    this.setState({ scddatepiker: dateString });
  };
  handleCancel = () => {
    this.setState({
      fstvisible: false,
      scdvisible: false,
      thdvisible: false,
      disval: "",
      adjustdisval: "",
      adddisval: "",
      datepiker: "",
      scddatepiker: "",
      modalinput: "",
      modalscdinput: "",
      modalthdinput: "",
      fsttextarea:
        "原订单还款超出应还，需抵扣客户其他订单(Use excess amount to deduct current order)",
      scdtextarea:
        "第三方反馈错(Third-party feedback error, the status was modified, and Amount was added)",
      thdtextarea:
        "流水状态正常，未更新订单状态(Active repayment amount added to order manually)",
      modalfst: "",
      modalscd: "",
      btn3loading: false,
      btnloading: false,
      btn2loading: false,
    });
  };
  //重置按钮
  reloadClick(e) {
    this.setState({
      fstSelect: "",
      scdSelect: "",
      testSelect: "",
      fouSelect: "",
      searchVal: "",
      from: undefined,
      to: undefined,
      boolean: false,
      sixSelect: "",
    },()=>{
        this.searchClick()
    });
  }
  adjust = () => {
    this.setState({
      fstvisible: true,
    });
  };
  onload = () => {
    // this.searchClick()
  };
  componentDidMount() {
    this.searchClick();
  }
  text(record) {
    this.setState({
      scdvisible: true,
      adjustdisval: record.id,
    });
  }
  //输入框
  searchIpt(e) {
    var value = e.target.value;
    this.setState({ searchVal: value.replace(/\s+/g, "") });
  }
  //选择订单状态
  selectFst = (e) => {
    var value = e.target.value;
    this.setState({
      fstSelect: value,
    });
  };
  selectScd = (e) => {
    var value = e.target.value;
    this.setState({
      scdSelect: value,
    });
  };
  selectTest(e) {
    var value = e.target.value;
    this.setState({
      testSelect: value,
    });
  }
  selectsix = (e) => {
    var value = e.target.value;
    this.setState({
      sixSelect: value,
    });
  };

  //选择apptype
  selectFou = (e) => {
    var value = e.target.value;
    this.setState({
      fouSelect: value,
    });
  };

  handleData(time) {
    if (!time) {
      return false;
    } else {
      // 大于当前日期不能选 time > moment()
      // 小于当前日期不能选 time < moment().subtract(1, "days")
      // 只能选前7后7 time < moment().subtract(7, "days") || time > moment().add(7, 'd')
      return time > moment();
    }
  }

  todetail = (record) => {
    this.props.history.push({
      pathname: "../../details/index",
      state: { record },
    });
  };

  searchClick = () => {
    this.setState({
      boolean: true,
    });
    axios
      .post(`/api/userRepay/getRepayFlowList`, {
        pageSize: this.state.pageSize,
        current: this.state.current,
        mobile: this.state.searchVal,
        bankNo: this.state.sixSelect,
        indentNo: this.state.fouSelect,
        repayFlowNo: this.state.fstSelect,
        status: this.state.testSelect,
        beginTime: this.state.from,
        endTime: this.state.to,
        remark: this.state.scdSelect,
      })
      .then((res) => {
        if (res.data.code === 200) {
          const data1 = [];
          const data = res.data.data.resultDate;
          if (data.length < 1) {
            this.setState({
              boolean: false,
            });
          } else {
            for (let i = 0; i < data.length; i++) {
              data[i].key = i;
              data1.push(data[i]);
            }
          }
          this.setState({
            tableData: data1,
            total: res.data.data.page.total,
            boolean: false,
          });
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
          // message.error(res.data.msg);
        }
      });
  };
  ModalInput = (e) => {
    let value = e.target.value;
    this.setState({
      modalinput: value,
    });
  };
  oldOrderBlur() {
    axios
      .get(`/api/loanFront/findByIndentNo/${this.state.modalinput}`)
      .then((res) => {
        if (res.data.code === 200) {
          const { actualbackAmt = 0, shouldbackFee = 0 } = res.data.data;
          this.setState({
            modalscdinput: (actualbackAmt - shouldbackFee) / 100,
          });
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
        }
      });
  }
  FstModal = (e) => {
    let value = e.target.value;
    this.setState({
      modalfst: value,
    });
  };
  ModalScd = (e) => {
    let value = e.target.value;
    this.setState({
      modalscd: value,
    });
  };
  ModalScdInput = (e) => {
    let value = e.target.value;
    this.setState({
      modalscdinput: value,
    });
  };
  ModalThdInput = (e) => {
    let value = e.target.value;
    this.setState({
      modalthdinput: value,
    });
  };
  newOrderBlur() {
    axios
      .get(`/api/loanFront/findByIndentNo/${this.state.modalthdinput}`)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            datepiker: res.data.data.shouldbackTime,
          });
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
        }
      });
  }
  fsttxarea = (e) => {
    let value = e.target.value;
    this.setState({
      fsttextarea: value,
    });
  };
  scdtxarea = (e) => {
    let value = e.target.value;
    this.setState({
      scdtextarea: value,
    });
  };
  thdtxarea = (e) => {
    let value = e.target.value;
    this.setState({
      thdtextarea: value,
    });
  };
  //offine按钮
  offline = (record) => {
    this.setState({
      thdvisible: true,
      adddisval: record.id,
      // modalinput:record.indentNo
    });
  };
  addOk = () => {
    if (this.state.modalscd == "") {
      message.error("原订单号不能为空");
      return;
    } else if (this.state.thdtextarea == "") {
      message.error("备注不能为空");
      return;
    }
    this.setState({
      btn3loading: true,
    });
    axios
      .post(`/api/userRepay/addLoanRecord`, {
        id: this.state.adddisval,
        indentNo: this.state.modalscd,
        remark: this.state.thdtextarea,
      })
      .then((res) => {
        if (res.data.code === 200) {
          message.success(res.data.msg);
          this.setState({
            thdvisible: false,
            thdtextarea:
              "流水状态正常，未更新订单状态(Active repayment amount added to order manually)",
            modalscd: "",
            btn3loading: false,
          });
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
          message.error(res.data.msg);
          this.setState({
            thdvisible: false,
            thdtextarea:
              "流水状态正常，未更新订单状态(Active repayment amount added to order manually)",
            modalscd: "",
            btn3loading: false,
          });
        }
      });
    this.searchClick();
  };
  adjustOk = () => {
    if (this.state.modalfst == "") {
      message.error("原订单号不能为空");
      return;
    } else if (this.state.scddatepiker == "") {
      message.error("还款时间不能为空");
      return;
    } else if (this.state.scdtextarea == "") {
      message.error("备注不能为空");
      return;
    }
    this.setState({
      btn2loading: true,
    });
    axios
      .post(`/api/userRepay/updateRepayFlowStatus`, {
        id: this.state.adjustdisval,
        repayTime: this.state.scddatepiker,
        indentNo: this.state.modalfst,
        remark: this.state.scdtextarea,
      })
      .then((res) => {
        if (res.data.code === 200) {
          message.success(res.data.msg);
          this.setState({
            scdvisible: false,
            modalfst: "",
            scddatepiker: "",
            scdtextarea: "",
            btn2loading: false,
          });
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
          message.error(res.data.msg);
          this.setState({
            scdvisible: false,
            modalfst: "",
            scddatepiker: "",
            scdtextarea: "",
            btn2loading: false,
          });
        }
      });
    this.searchClick();
  };
  handleOk = () => {
    if (this.state.modalscdinput == "") {
      message.error("原订单号不能为空");
      return;
    } else if (this.state.modalthdinput == "") {
      message.error("现订单号不能为空");
      return;
    } else if (this.state.datepiker == "") {
      message.error("还款时间不能为空");
      return;
    } else if (this.state.fsttextarea == "") {
      message.error("备注不能为空");
      return;
    } else if (Number(this.state.modalscdinput) <= 0) {
      message.error("金额不能小于零");
      return;
    }
    this.setState({
      btnloading: true,
    });
    axios
      .post(`/api/userRepay/updateRepayFlow`, {
        // loanId: this.state.disval,
        repayTime: this.state.datepiker,
        indentNo: this.state.modalinput,
        indentNoNew: this.state.modalthdinput,
        money: this.state.modalscdinput,
        remark: this.state.fsttextarea,
      })
      .then((res) => {
        if (res.data.code === 200) {
          message.success(res.data.msg);
          this.setState({
            fstvisible: false,
            datepiker: "",
            modalinput: "",
            modalscdinput: "",
            modalthdinput: "",
            fsttextarea:
              "原订单还款超出应还，需抵扣客户其他订单(Use excess amount to deduct current order)",
            btnloading: false,
          });
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
          message.error(res.data.msg);
          this.setState({
            fstvisible: false,
            datepiker: "",
            modalinput: "",
            modalscdinput: "",
            modalthdinput: "",
            fsttextarea:
              "原订单还款超出应还，需抵扣客户其他订单(Use excess amount to deduct current order)",
            btnloading: false,
          });
        }
      });
    this.searchClick();
  };
  render() {
    const columns = [
      {
        title: "流水号",
        dataIndex: "transaction_no",
        key: "transaction_no",
      },
      {
        title: "还款金额",
        dataIndex: "transaction_amount",
        key: "transaction_amount",
        render: (value) => {
          var num = value / 100;
          num = num + "";
          if (num.indexOf(".") != -1) {
            return num;
          } else {
            var str = "";
            for (var i = num.length - 1, j = 1; i >= 0; i--, j++) {
              if (j % 3 == 0 && i != 0) {
                //每隔三位加逗号，过滤正好在第一个数字的情况
                str += num[i] + ","; //加千分位逗号
                continue;
              }
              str += num[i]; //倒着累加数字
            }
            return str.split("").reverse().join("");
          }
        },
      },
      {
        title: "订单号",
        dataIndex: "indentNo",
        key: "indentNo",
      },
      {
        title: "手机号",
        dataIndex: "mobile",
        key: "mobile",
      },
      //   {
      //     title: "银行账号",
      //     dataIndex: "bankNo",
      //     key: "bankNo",
      //   },
      {
        title: "还款方式",
        dataIndex: "type",
        key: "type",
      },
      {
        title: "状态",
        dataIndex: "status",
        key: "status",
      },
      {
        title: "备注/描述",
        dataIndex: "remark",
        key: "remark",
      },
      {
        title: "交易时间",
        dataIndex: "created_time",
        key: "created_time",
      },
      {
        title: "操作",
        dataIndex: "operating",
        key: "operating",
        width: 70,
        id: "id",
        uid: "uid",
        fixed: "right",
        render: (text, record, index) => (
          <span style={{ display: "flex", flexDirection: "row" }}>
            <Button
              type="primary"
              style={{
                display: record.status == "支付成功 " ? "block" : "none",
              }}
              className="detailsbutton common_color"
              size="small"
              onClick={() => this.offline(record)}
            >
              添加
            </Button>
            <Button
              type="primary"
              style={{
                display: record.status == "支付成功 " ? "none" : "block",
              }}
              className="detailsbutton common_color"
              size="small"
              onClick={() => this.text(record)}
            >
              修改
            </Button>
          </span>
        ),
      },
    ];
    return (
      <div className="credit_order">
        <div className="myorder_content">
          <div className="flutter">
            <div className="top">
              <div className="top_left" style={{ width: "19%", flexShrink: 1 }}>
                <div className="search">
                  <Search
                    placeholder="手机号"
                    onSearch={(value) => console.log(value)}
                    style={{ width: 220 }}
                    value={this.state.searchVal}
                    onChange={this.searchIpt.bind(this)}
                  />
                </div>
              </div>

              <div
                className="top_right"
                style={{ width: "19%", flexShrink: 2 }}
              >
                {/* <input type="text" placeholder='银行账号' value={this.state.sixSelect} onChange={this.selectsix} className="modal_input"  style={{width: 220,marginLeft:0}}/> */}
                <input
                  type="text"
                  placeholder="流水号"
                  value={this.state.fstSelect}
                  onChange={this.selectFst}
                  className="modal_input"
                  style={{ width: 220, marginLeft: 0 }}
                />
              </div>

              <div className="top_mid" style={{ width: "24%", flexShrink: 1 }}>
                <div className="rangepick">
                  <span className="description right" style={{ width: 85 }}>
                    交易时间:
                  </span>
                  <RangePicker
                    style={{ width: 220 }}
                    ranges={{
                      Today: [moment(), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                    }}
                    showTime
                    format={dateFormat}
                    onChange={this.rangeOnChange}
                    value={
                      this.state.from === undefined ||
                      this.state.to === undefined ||
                      this.state.from === "" ||
                      this.state.to === ""
                        ? null
                        : [
                            moment(this.state.from, dateFormat),
                            moment(this.state.to, dateFormat),
                          ]
                    }
                  />
                </div>
              </div>
              <div className="mid_left" style={{ width: "19%" }}>
                {/* <select
                  className="form_select"
                  style={{ marginRight: 0, width: 220 }}
                  value={this.state.testSelect}
                  onChange={this.selectTest.bind(this)}
                >
                  <option value="">状态(all)</option>
                  <option value="3">成功</option>
                  <option value="4">失败</option>
                </select> */}
                <input
                  type="text"
                  placeholder="订单号"
                  value={this.state.fouSelect}
                  onChange={this.selectFou}
                  className="modal_input"
                  style={{ width: 220, marginLeft: 0 }}
                />
              </div>
              <div className="mid_right" style={{ width: "19%" }}></div>
            </div>

            <div className="order_mid" style={{ justifyContent: "flex-start" }}>
              <div className="mid_left" style={{ width: "19%" }}></div>
              <div className="mid_left" style={{ width: "20%" }}>
                {/* <input
                  type="text"
                  placeholder="备注/描述"
                  value={this.state.scdSelect}
                  onChange={this.selectScd}
                  className="modal_input"
                  style={{ width: 220, marginLeft: 0 }}
                /> */}
              </div>
              <div className="bottom" style={{ marginTop: 0, width: "40%" }}>
              <Button type="primary common_color" style={{width:170,marginRight:20,height:40,border:'none'}} onClick={this.searchClick.bind(this)}>搜索</Button>
              <Button type="primary common_color_scd" style={{width:170,marginRight:20,height:40,border:'none'}} onClick={this.reloadClick.bind(this)}>刷新</Button>
              <Button type="primary common_color_thd" style={{width:170,height:40,border:'none'}} onClick={this.adjust.bind(this)}>调整</Button>
              </div>
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={this.state.tableData}
          loading={this.state.boolean}
          onChange={this.handleChange}
          pagination={false}
        />

        <Modal
          title="调整"
          visible={this.state.fstvisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={this.state.btnloading}
              onClick={this.handleOk}
            >
              Confirm
            </Button>,
          ]}
        >
          <input value={this.state.disval} style={{ display: "none" }} />
          <div className="model_last">
            <span className="long record_span">原订单号（减实际还款）:</span>
            <input
              className="modal_input"
              style={{ marginLeft: "2.5%", width: 290 }}
              type="text"
              value={this.state.modalinput}
              onChange={this.ModalInput}
              onBlur={this.oldOrderBlur.bind(this)}
            />
          </div>

          <div className="model_last">
            <span className="long record_span">金额:</span>
            <input
              className="modal_input"
              style={{ marginLeft: "2.5%", width: 290 }}
              value={this.state.modalscdinput}
              disabled
              //   onChange={this.ModalScdInput}
            />
          </div>
          <div className="model_last">
            <span className="long record_span">现订单号（增实际还款）:</span>
            <input
              className="modal_input"
              style={{ marginLeft: "2.5%", width: 290 }}
              value={this.state.modalthdinput}
              onChange={this.ModalThdInput}
              onBlur={this.newOrderBlur.bind(this)}
            />
          </div>
          <div className="model_last">
            <span className="long record_span">还款时间:</span>
            <DatePicker
              style={{ marginLeft: "2.5%", width: 290 }}
              disabledDate={this.handleData}
              value={
                this.state.datepiker === undefined ||
                this.state.datepiker === ""
                  ? null
                  : moment(this.state.datepiker, dateFormat)
              }
              disabled
              //   onChange={this.dateonChange}
            />
          </div>
          <div className="model_last">
            <span className="long record_span">备注:</span>
            <textarea
              className="modal_textarea_new"
              style={{ marginLeft: "2.5%" }}
              placeholder="原订单还款超出应还，需抵扣客户其他订单(Use excess amount to deduct current order)"
              value={this.state.fsttextarea}
              onChange={this.fsttxarea}
            />
          </div>
        </Modal>

        <Modal
          title="修改"
          visible={this.state.scdvisible}
          onOk={this.adjustOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={this.state.btn2loading}
              onClick={this.adjustOk}
            >
              Confirm
            </Button>,
          ]}
        >
          <input value={this.state.adjustdisval} style={{ display: "none" }} />
          <div className="model_last">
            <span className="long record_span">还到的订单号</span>
            <input
              className="modal_input"
              style={{ marginLeft: "10%", width: 290 }}
              type="text"
              value={this.state.modalfst}
              onChange={this.FstModal}
            />
          </div>
          <div className="model_last">
            <span className="long record_span">还款时间:</span>
            <DatePicker
              style={{ marginLeft: "10%", width: 290 }}
              disabledDate={this.handleData}
              value={
                this.state.scddatepiker === undefined ||
                this.state.scddatepiker === ""
                  ? null
                  : moment(this.state.scddatepiker, dateFormat)
              }
              onChange={this.scddateonChange}
            />
          </div>
          <div className="model_last">
            <span className="long record_span">备注:</span>
            <textarea
              style={{ marginLeft: "10%" }}
              className="modal_textarea_new"
              placeholder="第三方反馈错(Third-party feedback error, the status was modified, and Amount was added)"
              value={this.state.scdtextarea}
              onChange={this.scdtxarea}
            />
          </div>
        </Modal>

        <Modal
          title="添加"
          visible={this.state.thdvisible}
          onOk={this.addOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={this.state.btn3loading}
              onClick={this.addOk}
            >
              Confirm
            </Button>,
          ]}
        >
          <input value={this.state.adddisval} style={{ display: "none" }} />
          <div className="model_last">
            <span className="long record_span">还到的订单号:</span>
            <input
              className="modal_input"
              style={{ marginLeft: "10%", width: 290 }}
              type="text"
              value={this.state.modalscd}
              onChange={this.ModalScd}
            />
          </div>
          <div className="model_last">
            <span className="long record_span">备注:</span>
            <textarea
              style={{ marginLeft: "10%" }}
              className="modal_textarea_new"
              placeholder="流水状态正常，未更新订单状态(Active repayment amount added to order manually)"
              value={this.state.thdtextarea}
              onChange={this.thdtxarea}
            />
          </div>
        </Modal>
        <Pagination
          showQuickJumper
          defaultCurrent={1}
          current={this.state.current}
          total={this.state.total}
          onChange={this.pageChange}
          showTotal={showTotal}
          style={{ textAlign: "right", marginTop: 20 }}
          pageSize={this.state.pageSize}
          showSizeChanger={true}
          onShowSizeChange={this.onShowSizeChange} //pagesize改变时回调函数
          pageSizeOptions={["10", "50", "100"]} //设置pagesize区间
        />
      </div>
    );
  }
}
export default withRouter(RepaymentRecord);
