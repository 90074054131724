/*eslint-disable*/
import React from "react";
import { Tabs } from "antd";
import "./index.css";
import axios from "axios";
import Tab1 from "./Tab1";
import Tab2 from "./Tab2";
import Tab3 from "./Tab5";
import Tab4 from "./repayment";
import Tab5 from "./extend";
import EventEmitter from "../../utils/events";
import { withRouter } from "react-router-dom";

const { TabPane } = Tabs;
var urlid = "";
var urluid = "";
class Details extends React.Component {
  //初始化
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      uid: "",
    };
  }

  geturlid = () => {
    console.log("test", window.location);
    var url = window.location.hash;
    var params = url.substr(1);
    var aPos = params.indexOf("=");
    var bPos = params.indexOf("&");
    urlid = params.substr(aPos + 1, bPos - aPos - 1);
    this.setState({
      id: urlid,
    });
    return urlid;
  };
  geturluid = () => {
    // console.log('search',this) //这两段  你不能通过this.props.location 除非你包了一个withrouter
    var url = window.location.hash;
    var params = url.substr(1);
    urluid = params.substr(params.indexOf("uid") + 4, params.length - 4);
    this.setState({
      uid: urluid,
    });
    return urluid;
  };
  getIdAndUid = () => {};
  componentDidMount() {
    EventEmitter.on("idanduid", function (data) {
      // let datas = data.splice()
      if (data) {
        // console.log('-------------------',this.props.location.state)
      }
    });
  }
  componentDidUpdate() {
    //  console.log('test',window.location)
  }
  componentWillMount() {
    // console.log('this',this.props)
    // console.log(window.localStorage.id)
    // if(window.localStorage.id == undefined){
    //   this.props.history.push({ pathname: "/home/index"});
    this.geturluid();
    this.geturlid();

    // }
    // this.getIdAndUid()
  }
  remove = (targetKey) => {
    let { activeKey } = this.state;
    let lastIndex;
    this.state.panes.forEach((pane, i) => {
      if (pane.key === targetKey) {
        lastIndex = i - 1;
      }
    });
  };

  render() {
    return (
      <div className="details">
        <Tabs defaultActiveKey="1" onEdit={this.onEdit} hideAdd>
          <TabPane tab="User Information" key="1">
            <Tab1 id={this.state.id} uid={this.state.uid} />
          </TabPane>
          <TabPane tab="Historical Loan Order" key="2">
            <Tab2 id={this.state.id} uid={this.state.uid} />
          </TabPane>
          <TabPane tab="Collection Information" key="3">
            <Tab3 id={this.state.id} uid={this.state.uid} />
          </TabPane>
          <TabPane tab="Repayment Records" key="4">
            <Tab4 id={this.state.id} uid={this.state.uid} />
          </TabPane>
          <TabPane tab="Extend Information" key="5">
            <Tab5 id={this.state.id} uid={this.state.uid} />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}
export default withRouter(Details);
