import React from "react";
import { withRouter } from "react-router-dom";
import EventEmitter from "../../utils/events";
import "./monnify.css";
import {
  input,
  Button,
  Table,
  Modal,
  Pagination,
  DatePicker,
  Tooltip,
  message,
  Icon,
  Upload,
  Select,
} from "antd";
import axios from "axios";

class Monnify extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      claimed: "",
      amount: "",
      uploadvisible: false,
      scdafter: false,
      optionsData: [],
      type: "",
      destinationBankCode: "",
      destinationAccountNumber: "",
      remark: "",
    };
  }

  componentDidMount() {
    this.onload();
  }

  onload = () => {
    axios.get(`/api/loan/credit/getMonnifyBank`).then((res) => {
      if (res.data.code == 200) {
        this.setState({
          optionsData: res.data.data.resultDate.banklist,
        });
      } else if (res.data.code == 800) {
        this.props.history.push({ pathname: "/" });
      } else {
        message.error(res.data.msg);
      }

      console.log(res);
    });
  };

  handleClick = () => {
    this.setState({
      uploadvisible: true,
    });
  };

  handleOk = (record) => {
    this.setState({
      scdafter: true,
    });
    axios
      .post(`/api/loan/credit/monnifyPay`, {
        type: this.state.type,
        amount: this.state.amount,
        destinationBankCode: this.state.destinationBankCode,
        destinationAccountNumber: this.state.destinationAccountNumber,
        remark: this.state.remark,
      })
      .then((res) => {
        if (res.data.code === 200) {
          message.success(res.data.msg);
        } else {
          message.error(res.data.msg);
        }
        this.setState({
          uploadvisible: false,
          scdafter: false,
        });
        console.log(res);
      });
  };

  handleCancel = () => {
    this.setState({
      uploadvisible: false,
      scdafter: false,
    });
  };

  render() {
    return (
      <div className="monnify">
        <div className="monnify_content">
          <h2>monnify转账</h2>
          <div className="monnify_form">
            <div className="monnify_form_item">
              <span className="description mid_span" style={{ width: 120 }}>
                转账账户:
              </span>
              <select
                className="form_select"
                style={{ marginRight: 0, width: 290 }}
                value={this.state.type}
                onChange={(e) => {
                  this.setState({ type: e.target.value });
                }}
              >
                <option value="">请选择</option>
                <option value="brimon">brimon</option>
                <option value="finance">finance</option>
              </select>
            </div>
            <div className="monnify_form_item">
              <span className="description mid_span" style={{ width: 120 }}>
                转账金额:
              </span>
              <input
                type="text"
                value={this.state.amount}
                onChange={(e) => {
                  this.setState({ amount: e.target.value });
                }}
                className="modal_input"
                style={{ width: 290, marginLeft: 0 }}
              />
            </div>
            <div className="monnify_form_item">
              <span className="description mid_span" style={{ width: 120 }}>
                收款银行名称:
              </span>
              <Select
                style={{ width: 290 }}
                value={this.state.destinationBankCode}
                onChange={(e) => {
                  this.setState({ destinationBankCode: e });
                }}
                getPopupContainer={(e) => e.parentNode}
              >
                {this.state.optionsData.map((item, i) => (
                  <Select.Option key={item} value={item.code}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className="monnify_form_item">
              <span className="description mid_span" style={{ width: 120 }}>
                收款银行账号:
              </span>
              <input
                type="text"
                value={this.state.destinationAccountNumber}
                onChange={(e) => {
                  this.setState({ destinationAccountNumber: e.target.value });
                }}
                className="modal_input"
                style={{ width: 290, marginLeft: 0 }}
              />
            </div>
            <div className="monnify_form_item">
              <span className="description mid_span" style={{ width: 120 }}>
                备注:
              </span>
              <textarea
                style={{
                  width: 290,
                  verticalAlign: "text-top",
                  height: 80,
                  border: "none",
                }}
                value={this.state.remark}
                onChange={(e) => {
                  this.setState({ remark: e.target.value });
                }}
              />
            </div>
            <div className="monnify_form_button">
              <Button
                type="primary"
                style={{
                  width: 170,
                  marginRight: 20,
                  height: 40,
                  border: "none",
                }}
                className="common_color"
                onClick={this.handleClick}
              >
                确定
              </Button>
            </div>
          </div>
        </div>
        <Modal
          title="二次确认"
          onCancel={this.handleCancel}
          visible={this.state.uploadvisible}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={this.state.scdafter}
              onClick={this.handleOk}
            >
              ok
            </Button>,
          ]}
        >
          <div className="monnify_form">
            <div className="monnify_form_item">
              <span className="description mid_span" style={{ width: 120 }}>
                转账账户:
              </span>
              <select
                disabled
                className="form_select"
                style={{ marginRight: 0, width: 290 }}
                value={this.state.type}
                onChange={(e) => {
                  this.setState({ type: e.target.value });
                }}
              >
                <option value="">请选择</option>
                <option value="brimon">brimon</option>
                <option value="finance">finance</option>
              </select>
            </div>
            <div className="monnify_form_item">
              <span className="description mid_span" style={{ width: 120 }}>
                转账金额:
              </span>
              <input
                disabled
                type="text"
                value={this.state.amount}
                onChange={(e) => {
                  this.setState({ amount: e.target.value });
                }}
                className="modal_input"
                style={{ width: 290, marginLeft: 0 }}
              />
            </div>
            <div className="monnify_form_item">
              <span className="description mid_span" style={{ width: 120 }}>
                收款银行名称:
              </span>
              <Select
                disabled
                style={{ width: 290 }}
                value={this.state.destinationBankCode}
                onChange={(e) => {
                  this.setState({ destinationBankCode: e });
                }}
                getPopupContainer={(e) => e.parentNode}
                placeholder="收款银行名称"
              >
                {this.state.optionsData.map((item, i) => (
                  <Select.Option key={item} value={item.code}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className="monnify_form_item">
              <span className="description mid_span" style={{ width: 120 }}>
                收款银行账号:
              </span>
              <input
                disabled
                type="text"
                value={this.state.destinationAccountNumber}
                onChange={(e) => {
                  this.setState({ destinationAccountNumber: e.target.value });
                }}
                className="modal_input"
                style={{ width: 290, marginLeft: 0 }}
              />
            </div>
            <div className="monnify_form_item">
              <span className="description mid_span" style={{ width: 120 }}>
                备注:
              </span>
              <textarea
                disabled
                style={{
                  width: 290,
                  verticalAlign: "text-top",
                  height: 80,
                  border: "none",
                }}
                value={this.state.remark}
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withRouter(Monnify);
