import React from "react";
import { Input, Select, Button } from "antd";

export default class PageSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fee: props.fee,
      cycle: props.cycle,
      status: props.status,
    };
  }
  render() {
    return (
      <div>
        <Input
          placeholder="金额"
          type="text"
          style={{ width: 240, marginRight: 10, marginLeft: 10 }}
          value={this.state.fee}
          onChange={(e) => {
            this.setState({ fee: e.target.value });
          }}
        />
        <Input
          placeholder="贷款期限"
          type="text"
          style={{ width: 240, marginRight: 10, marginLeft: 10 }}
          value={this.state.cycle}
          onChange={(e) => {
            this.setState({ cycle: e.target.value });
          }}
        />
        <Select
          defaultValue={1}
          style={{ width: 120, marginRight: 10 }}
          onChange={(value) => {
            this.setState({ status: value });
          }}
        >
          <Select.Option value={1}>上线</Select.Option>
          <Select.Option value={0}>下线</Select.Option>
        </Select>
        <Button
          type="primary"
          style={{
            width: 170,
            marginRight: 20,
            height: 40,
            border: "none",
          }}
          className="common_color"
          onClick={() => this.props.search(this.state)}
        >
          Search
        </Button>
      </div>
    );
  }
}
