/*eslint-disable*/
import React from "react";
import { Row, Col, Tag, Modal, Input, Button, message } from "antd";
import { Link, withRouter } from "react-router-dom";

import axios from "axios";

class ModifyPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fstvisible: true,
    };
  }
  logout = () => {
    localStorage.removeItem("loginUser");
    localStorage.removeItem("authToken");
    localStorage.removeItem("key");
    axios.post(`/api/system/loginout`, {}).then((res) => {
      if (res.data.code === 200) {
        this.props.history.push({ pathname: "/" });
      } else if (res.data.code == 800) {
        this.props.history.push({ pathname: "/" });
      }
    });
  };
  fsthandleOk = () => {
    if (this.state.oldpassword == this.state.password) {
      message.error("The new password cannot be the same as the old password");
      return;
    }
    if (this.state.password != this.state.newpassword) {
      message.error("Two password entries are inconsistent");
      return;
    }
    if (this.state.password === "123456") {
      message.error("The new password cannot be 123456");
      return;
    }
    axios
      .post(`/api/system/resetPassword`, {
        oldPassword: this.state.oldpassword,
        newPassword: this.state.password,
      })
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            fstvisible: false,
          });
          message.success(res.data.msg);
          this.logout();
        } else {
          message.error(res.data.msg);
        }
        this.setState({
          oldpassword: "",
          password: "",
          newpassword: "",
        });
      });
  };
  render() {
    return (
      <div className="header">
        <Modal
          title="Please change login password"
          visible={this.state.fstvisible}
          closable={false}
          footer={[1].map(() => {
            return (
              <Button type="primary" onClick={this.fsthandleOk}>
                OK
              </Button>
            );
          })}
        >
          <Input
            placeholder="old password"
            value={this.state.oldpassword}
            onChange={(e) => {
              this.setState({
                oldpassword: e.target.value,
              });
            }}
          />
          <br />
          <Input
            placeholder="new password"
            style={{ marginTop: 35 }}
            type="password"
            value={this.state.password}
            onChange={(e) => {
              this.setState({
                password: e.target.value,
              });
            }}
          />
          <br />
          <Input
            placeholder="confirm password"
            style={{ marginTop: 35 }}
            type="password"
            value={this.state.newpassword}
            onChange={(e) => {
              this.setState({
                newpassword: e.target.value,
              });
            }}
          />
          <br />
        </Modal>
      </div>
    );
  }
}
export default withRouter(ModifyPassword);
