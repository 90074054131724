/*eslint-disable*/
import React from 'react';
import { Input,Button,Table,Modal,Pagination,DatePicker,Tooltip,message,Icon,Upload } from 'antd';
import './index.css';
import axios from 'axios'
import {withRouter} from 'react-router-dom'
import EventEmitter from '../../utils/events'
import Zmage from 'react-zmage'

import {Link} from 'react-router-dom'
import moment from 'moment';
const { RangePicker } = DatePicker;
  const { Search } = Input;
  const dateFormat = 'YYYY-MM-DD'||undefined;
var whitchshow = []
var Select = []
  function showTotal(total) {
    return `Total ${total} items`;
  }

 class RepaymentPlan extends React.Component {
    constructor(props){
      super(props);
      this.state={
        fstSelect:'',
        scdSelect:'',
        testSelect:'',
        sixSelect:1,
        fouSelect:'',
        searchVal:'',
        current:1,
        modalscdinput:'',
        modalthdinput:'',
        datepiker:'',
        fstinpt:'',
        disval:'',
        fileList: [],
        order_statues:'please select one',
        fsttextarea:'',
        pageSize:10,
        boolean:false,
        reviewreason:'',
        fstInput1:'',
        AbnormalData:[],
        cancelPromptId:'',
        ishow:''
      }
    }
    onShowSizeChange=(current, pageSize)=>{
      this.setState({
        pageSize: pageSize
      },()=>{
        this.searchClick()
      })
  }
        //页码改变时触发
        pageChange=(page)=>{
          this.setState({
            current: page
            },()=>{
              this.searchClick()
            })
        }
        rangeOnChange=(dates, dateStrings)=>{
          this.setState({ from: dateStrings[0],to:dateStrings[1] })
        }
        dateonChange = (date, dateString) =>{
          this.setState({ datepiker:dateString})
        }
        getselect=()=>{
          axios
          .post(`/api/appshadow/findAppShadowList`,{

        })
        .then(res=>{
           Select=[]
          if(res.data.code === 200){
            for(let i=0;i<res.data.data.list.length;i++ ){

                if(i==0){
                  Select.push(<option value=''>please select one</option> )
                  }
                  Select.push(
                  <option value={res.data.data.list[i].appCode}>{res.data.data.list[i].appCode}</option>
              )

            this.setState({
              Select:Select
            })
            }
          }else if(res.data.code ==800){
            this.props.history.push({ pathname: "/"});
          }else{
          }
        })
        }
        fstInput1Change=(e)=>{
          var value = e.target.value;
          this.setState({
            fstInput1:value,
          });
        }
        fstInput2Change=(e)=>{
          var value = e.target.value;
          this.setState({
            fstInput2:value,
          });
        }
        fstInput3Change=(e)=>{
          var value = e.target.value;
          this.setState({
            fstInput3:value,
          });
        }
    handleCancel=()=>{
      this.setState({
        fstvisible: false,
        scdvisible: false,
        disval:'',
        datepiker:'',
        modalinput:'',
        modalscdinput:'',
        modalthdinput:'',
        fsttextarea:'',
        fileList:'',
        btnloading:false,
        AbnormalDialog:false,
        cancelPrompt:false
      });
    }
    //重置按钮
    reloadClick(e) {
        this.setState(
        {
            fstSelect: "",
            scdSelect: "",
            testSelect: "",
            fouSelect: "",
            searchVal: "",
            mobileNum: "",
            from: undefined,
            to: undefined,
            boolean: false,
            sixSelect: 1,
            fstInput1: "",
            fstInput2: "",
            fstInput3: "",
        },
        () => {
            this.searchClick();
        }
        );
    }

    onload=()=>{
      // this.searchClick()
    }
    componentDidMount(){
     this.onload()
     this.getselect()
    }
    text(record){
      window.location='/#/details/index?id='+record.id+'&uid='+record.uid
      let test = [{path: '/details/index',key: `/details/index/${record.id}/${record.uid}`,number: `${record.id}/${record.uid}`}]
      EventEmitter.trigger('btn', test[0])
      EventEmitter.trigger('idanduid', test[0].number)
    }



    handleCancelPrompt = () =>{
      this.setState({
        cancelPrompt:false
      })
    }


    
    //异常销账
    Abnormal(record){
      this.setState({
        AbnormalDialog:true
      })
      axios.post(`/api/loanFront/getRepayFlowByIndentNo`,{
        indentNo:record.indentNo
      }).then(res =>{
        this.setState({
          AbnormalData:res.data.data.resultDate
        })
      })
    }




    //输入框
    searchIpt(e){
      var value = e.target.value;
      this.setState({ searchVal: value.replace(/\s+/g,"") })
    }
     //号码输入框
    searchIpt1(e){
      var value = e.target.value;
      this.setState({ mobileNum: value.replace(/\s+/g,"") })
    }
    //选择订单状态
    selectFst(e){
      var value = e.target.value;
      this.setState({
        fstSelect:value,
      });
    }
    selectTest(e){
      var value = e.target.value;
      this.setState({
        testSelect:value,
      });
    }
    selectsix= (e) =>{
      var value = e.target.value;
      this.setState({
        sixSelect:value,
      });
    }


    //选择apptype
    selectFou(e){
      var value = e.target.value;
      this.setState({
        fouSelect:value,
      });
    }



    //撤销订单
    undo(record){
      this.setState({
        cancelPrompt:true,
        cancelPromptId:record.id
      })
    }

    handleOkcancelPrompt = () =>{
        axios.post(`/api/loanFront/writeOffCancellation`,{id:this.state.cancelPromptId}).then(res=>{
            console.log(res)
            if(res.data.code == 200 ){
              this.handleCancel()
              // this.Abnormal()
              message.success(res.data.msg)
            }else {
              this.handleCancel()
              // this.handleCancelPrompt()
              message.error(res.data.msg)
            }
        })
    }

    handleData(time){
      if(!time){
          return false
      }else{
      // 大于当前日期不能选 time > moment()
      // 小于当前日期不能选 time < moment().subtract(1, "days")
      // 只能选前7后7 time < moment().subtract(7, "days") || time > moment().add(7, 'd')
          return time > moment()
      }
    }

    todetail=(record)=>{
      this.props.history.push({ pathname: "../../details/index",state:{record}});
    }

    searchClick=()=>{
      this.setState({
        boolean: true
      })
      axios
      .post(`/api/loanFront/getLoaningList`,{

          pageSize: this.state.pageSize,
          current: this.state.current,
          schParam: this.state.searchVal,
          indentNo: this.state.searchVal,
          mobile: this.state.mobileNum,
          status: this.state.fstSelect,
          timeType: this.state.sixSelect,
          tag: this.state.testSelect,
          appCode: this.state.fouSelect,
          beginTime: this.state.from,
          endTime: this.state.to,
          firstName: this.state.fstInput1,
          middleName: this.state.fstInput2,
          lastName: this.state.fstInput3,
    })
    .then(res=>{
      if (res.data.code === 200) {
        const data1 = [];
        const data = res.data.data.resultDate
        if(data.length<1){
          this.setState({
          boolean: false
          })
        }else{
          for (let i = 0; i < data.length; i++) {
            data[i].key = i;
            data1.push(data[i]);
          }
        }
        this.setState({
          tableData: data1,
          total: res.data.data.page.total,
          ishow:res.data.data.ishow,
          boolean: false
        })
    } else if(res.data.code ==800){
        this.props.history.push({ pathname: "/"});
      }else{
        // message.error(res.data.msg);
      }
    })
    }
    ModalInput=(e)=>{
      let value = e.target.value;
      this.setState({
        modalinput:value,
      });
    }
    ModalScdInput=(e)=>{
      let value = e.target.value;
      this.setState({
        modalscdinput:value,
      });
    }
    ModalThdInput=(e)=>{
      let value = e.target.value;
      if(value<0||value>100000){
        alert('Please confirm the amount twice')
        value = ""
        this.setState({
          modalthdinput:value,
        });
        return false
      }
      this.setState({
        modalthdinput:value,
      });
    }
    fsttxarea=(e)=>{
      let value = e.target.value;
      this.setState({
        fsttextarea:value,
      });
    }
    //offine按钮
    offline=(record)=>{
      this.setState({
        fstvisible:true,
        disval:record.id,
        modalinput:record.indentNo

      })
    }
    handleOk=()=>{
      if(this.state.modalscdinput==''){
        message.error("The transaction number can't  be empty.")
        return
      }
      else if(this.state.modalthdinput==''){
        message.error("The repayment amount can't  be empty.")
        return
      }
      else if(this.state.datepiker==''){
        message.error("The repayment time can't  be empty.")
        return
      }
      else if(this.state.fsttextarea==''){
        message.error("The remark can't  be empty.")
        return
      }
      this.setState({
        btnloading:true
      })
      const formData = new FormData();
      formData.append('file', this.state.fileList[0]);
      const json = {
        'loanId':this.state.disval,
        'repayTime':this.state.datepiker,
        'repayNo':this.state.modalinput,
        'repayFlowNo':this.state.modalscdinput,
        'repayFee':this.state.modalthdinput,
        'remark':this.state.fsttextarea,
    }
    const objToStr = JSON.stringify(json)
    formData.append('json',objToStr)
    axios({
      url: '/api/loanFront/offlineInstallment',
      method: 'post',
      data: formData,
    })

    .then(res=>{
      if (res.data.code === 200) {
        message.success(res.data.msg);
        this.setState({
          fstvisible:false,
          datepiker:'',
          modalinput:'',
          modalscdinput:'',
          modalthdinput:'',
          fsttextarea:'',
          btnloading:false,
          fileList:''
        })

    } else if(res.data.code ==800){
        this.props.history.push({ pathname: "/"});
      }else{
        message.error(res.data.msg);
        this.setState({
          fstvisible:false,
          datepiker:'',
          modalinput:'',
          modalscdinput:'',
          modalthdinput:'',
          fsttextarea:'',
          btnloading:false,
          fileList:''
        })
      }
    })
    this.searchClick()
    }
    render() {


        const AbnormalColumns = [

          {
            title:'流水号',
            dataIndex: "transaction_no",
            key: "transaction_no",
          },
          {
            title:'交易时间',
            dataIndex: "created_time",
            key: "created_time",
            render: (value) => {
              let timeaudit =
                new Date(value).getTime() +
                new Date().getTimezoneOffset() * 60 * 1000 -
                0 * 60 * 60 * 1000;
              let auditimes = moment(new Date(timeaudit)).format(
                "YYYY-MM-DD HH:mm:ss"
              );
              return auditimes;
            },
          },
          {
            title:'金额',
            dataIndex: "amount",
            key: "amount",
            render: (value) => {
              var num = value / 100;
              num = num + "";
              if (num.indexOf(".") != -1) {
                return num;
              } else {
                var str = "";
                for (var i = num.length - 1, j = 1; i >= 0; i--, j++) {
                  if (j % 3 == 0 && i != 0) {
                    //每隔三位加逗号，过滤正好在第一个数字的情况
                    str += num[i] + ","; //加千分位逗号
                    continue;
                  }
                  str += num[i]; //倒着累加数字
                }
                return str.split("").reverse().join("");
              }
            },
          },
          {
            title:'关联订单号',
            dataIndex: "loan_record_no",
            key: "loan_record_no",
          },
          {
            title:'操作人',
            dataIndex: "operator_name",
            key: "operator_name",
          },
          {
            title:'操作时间',
            dataIndex: "completion_time",
            key: "completion_time",
            render: (value) => {
              let timeaudit =
                new Date(value).getTime() +
                new Date().getTimezoneOffset() * 60 * 1000 -
                0 * 60 * 60 * 1000;
              let auditimes = moment(new Date(timeaudit)).format(
                "YYYY-MM-DD HH:mm:ss"
              );
              return auditimes;
            },
          },
          // {
          //   title:'相关操作',
          //   dataIndex: "status",
          //   key: "status",
          //   render: (value) => {
          //     if (value == 0) {
          //       return (value = "撤销");
          //     } else  if (value == 3){
          //       return (value = "销账");
  
          //     }
          //   },
          // },
          {
            title: "操作",
            dataIndex: "operating",
            key: "operating",
            width: 70,
            id: "id",
            uid: "uid",
            fixed: "right",
            render: (text, record, index) => (
              <span style={{ display: "flex", flexDirection: "row" }}>
                  <Button type="primary"    className="detailsbutton common_color" size="small" disabled={record.status==0}  onClick={()=>this.undo(record)}>
                    操作
                  </Button>
              </span>
            ),
          },
        ]

        const columns = [
            {
              title: "app type",
              dataIndex: "appCode",
              key: "appCode",
            },
            {
              title: "Order No",
              dataIndex: "indentNo",
              key: "indentNo",
            },
            // {
            //   title: 'Customer type',
            //   dataIndex: 'tag',
            //   key: 'tag',

            // },
            {
              title: "Customer name",
              dataIndex: "firstName",
              key: "firstName",
            },
            {
              title: "mobile phone",
              dataIndex: "mobile",
              key: "mobile",
            },
            // {
            //   title: 'BVN',
            //   dataIndex: 'dentityCard',
            //   key: 'dentityCard',

            // },
            {
              title: "loan amount",
              dataIndex: "balance",
              key: "balance",
              render: (value) => {
                var num = value / 100;
                num = num + "";
                if (num.indexOf(".") != -1) {
                  return num;
                } else {
                  var str = "";
                  for (var i = num.length - 1, j = 1; i >= 0; i--, j++) {
                    if (j % 3 == 0 && i != 0) {
                      //每隔三位加逗号，过滤正好在第一个数字的情况
                      str += num[i] + ","; //加千分位逗号
                      continue;
                    }
                    str += num[i]; //倒着累加数字
                  }
                  return str.split("").reverse().join("");
                }
              },
            },
            // {
            //   title: 'loan term',
            //   dataIndex: 'cycle',
            //   key: 'cycle',

            // },
            //   {
            //     title: "Success Repayment Count",
            //     dataIndex: "repaymentCount",
            //     key: "repaymentCount",
            //   },
            {
              title: "Loan status",
              dataIndex: "statusDescription",
              key: "statusDescription",
            },

            //   {
            //     title: "repayment amount",
            //     dataIndex: "actualbackAmt",
            //     key: "actualbackAmt",
            //     render: (value) => {
            //       var num = value / 100;
            //       num = num + "";
            //       if (num.indexOf(".") != -1) {
            //         return num;
            //       } else {
            //         var str = "";
            //         for (var i = num.length - 1, j = 1; i >= 0; i--, j++) {
            //           if (j % 3 == 0 && i != 0) {
            //             //每隔三位加逗号，过滤正好在第一个数字的情况
            //             str += num[i] + ","; //加千分位逗号
            //             continue;
            //           }
            //           str += num[i]; //倒着累加数字
            //         }
            //         return str.split("").reverse().join("");
            //       }
            //     },
            //   },

            //   {
            //     title: "actual back time",
            //     dataIndex: "lastbackTime",
            //     key: "lastbackTime",
            //     render: (value) => {
            //       if (value == null) {
            //         return "";
            //       } else {
            //         let timeaudit =
            //           new Date(value).getTime() +
            //           new Date().getTimezoneOffset() * 60 * 1000 -
            //           0 * 60 * 60 * 1000;
            //         let auditimes = moment(new Date(timeaudit)).format(
            //           "YYYY-MM-DD HH:mm:ss"
            //         );
            //         return auditimes;
            //       }
            //     },
            //   },
            //   {
            //     title: "picture",
            //     dataIndex: "picture",
            //     key: "picture",
            //     render: (value) => {
            //       return <Zmage src={value} style={{ width: 70, height: 65 }} />;
            //     },
            //   },
            {
              title: "due amount",
              dataIndex: "shouldbackFee",
              key: "shouldbackFee",
              render: (value) => {
                var num = value / 100;
                num = num + "";
                if (num.indexOf(".") != -1) {
                  return num;
                } else {
                  var str = "";
                  for (var i = num.length - 1, j = 1; i >= 0; i--, j++) {
                    if (j % 3 == 0 && i != 0) {
                      //每隔三位加逗号，过滤正好在第一个数字的情况
                      str += num[i] + ","; //加千分位逗号
                      continue;
                    }
                    str += num[i]; //倒着累加数字
                  }
                  return str.split("").reverse().join("");
                }
              },
            },
            {
              title: "remainng balance",
              dataIndex: "alsoNeedBack",
              key: "alsoNeedBack",
              render: (value, record) => {
                var num =
                  (record.shouldbackFee -
                    record.actualbackAmt -
                    record.reduceAmt -
                    record.couponsFee) /
                  100;
                num = num + "";
                return num;
              },
            },
            //   {
            //     title: "reduce amount",
            //     dataIndex: "reduceAmt",
            //     key: "reduceAmt",
            //     render: (value) => {
            //       var num = value / 100;
            //       num = num + "";
            //       if (num.indexOf(".") != -1) {
            //         return num;
            //       } else {
            //         var str = "";
            //         for (var i = num.length - 1, j = 1; i >= 0; i--, j++) {
            //           if (j % 3 == 0 && i != 0) {
            //             //每隔三位加逗号，过滤正好在第一个数字的情况
            //             str += num[i] + ","; //加千分位逗号
            //             continue;
            //           }
            //           str += num[i]; //倒着累加数字
            //         }
            //         return str.split("").reverse().join("");
            //       }
            //     },
            //   },

            //   {
            //     title: "coupon amount",
            //     dataIndex: "couponsFee",
            //     key: "couponsFee",
            //     render: (value) => {
            //       var num = value / 100;
            //       num = num + "";
            //       var str = "";
            //       for (var i = num.length - 1, j = 1; i >= 0; i--, j++) {
            //         if (j % 3 == 0 && i != 0) {
            //           //每隔三位加逗号，过滤正好在第一个数字的情况
            //           str += num[i] + ","; //加千分位逗号
            //           continue;
            //         }
            //         str += num[i]; //倒着累加数字
            //       }
            //       return str.split("").reverse().join("");
            //     },
            //   },

            //   {
            //     title: "arrival time",
            //     dataIndex: "arriveTime",
            //     key: "arriveTime",
            //     render: (value) => {
            //       let timeaudit =
            //         new Date(value).getTime() +
            //         new Date().getTimezoneOffset() * 60 * 1000 -
            //         0 * 60 * 60 * 1000;
            //       let auditimes = moment(new Date(timeaudit)).format(
            //         "YYYY-MM-DD HH:mm:ss"
            //       );
            //       return auditimes;
            //     },
            //   },
            {
              title: "should repayment date",
              dataIndex: "shouldbackTime",
              key: "shouldbackTime",
              render: (value) => {
                let timeaudit =
                  new Date(value).getTime() +
                  new Date().getTimezoneOffset() * 60 * 1000 -
                  0 * 60 * 60 * 1000;
                let auditimes = moment(new Date(timeaudit)).format(
                  "YYYY-MM-DD HH:mm:ss"
                );
                return auditimes;
              },
            },

            //   {
            //     title: "coupon ID",
            //     dataIndex: "couponIndentNo",
            //     key: "couponIndentNo",
            //   },

            //   {
            //     title: "coupon name",
            //     dataIndex: "couponName",
            //     key: "couponName",
            //   },

            {
              title: "Operation",
              dataIndex: "operating",
              key: "operating",
              width: 70,
              id: "id",
              uid: "uid",
              fixed: "right",
              render: (text, record, index) => (
                <span style={{ display: "flex", flexDirection: "row" }}>
                    <Button type="primary"  style={{display:this.state.ishow == false ?'none':'block'}}   className="detailsbutton common_color" size="small"  onClick={()=>this.Abnormal(record)}>
                    Abnormal
                    </Button>
                    <Button type="primary"  style={{display:record.statusDescription=='Normal Repayment'||record.statusDescription=='Early Repayment'||record.statusDescription=='Overdue Repayment'?'none':'block'}}  className="detailsbutton common_color" size="small"  onClick={()=>this.offline(record)}>
                    offline
                    </Button>
                    <Button type="primary"    className="detailsbutton common_color" size="small"  onClick={()=>this.text(record)}>
                    details
                    </Button>
                </span>
              ),
            },
          ];
      const { uploading, fileList } = this.state;
      const props = {
        onRemove: (file) => {
          this.setState((state) => {
            const index = state.fileList.indexOf(file);
            const newFileList = state.fileList.slice();
            newFileList.splice(index, 1);
            return {
              fileList: newFileList,
            };
          });
        },
        beforeUpload: (file) => {
          this.setState(state => ({
            fileList: [...state.fileList, file],
          }));
          return false;
        },
        fileList: this.state.fileList ,
      };
      return (
        <div className="credit_order">
          <div className="myorder_content">
          <div className="flutter">
            <div className="top">
              <div className="top_left" style={{ width: "30%", flexShrink: 1 }}>
                <div className="search">
                  <span className="description left">Order No:</span>
                  <Search
                    placeholder="Order No"
                    onSearch={(value) => console.log(value)}
                    style={{ width: 290 }}
                    value={this.state.searchVal}
                    onChange={this.searchIpt.bind(this)}
                  />
                </div>
              </div>

              <div
                className="top_right"
                style={{
                  width: "30%",
                  flexShrink: 2,
                  marginLeft: "5%",
                  marginRight: "5%",
                }}
              >
                {/* <span className="description mid_span" >Time type:</span>
                  <select className="form_select" style={{marginRight:0,width:292}} value={this.state.sixSelect} onChange={this.selectsix}>
                    <option value="1">should back time</option>
                    <option value="2" >last repay time</option>
                    <option value="3" >payment time</option>
                    <option value="4" >created time</option>
                  </select> */}
                <span className="description left">Phone:</span>
                <Search
                  placeholder="Phone"
                  onSearch={(value) => console.log(value)}
                  style={{ width: 290 }}
                  value={this.state.mobileNum}
                  onChange={this.searchIpt1.bind(this)}
                />
              </div>

              <div className="top_mid" style={{ width: "30%", flexShrink: 1 }}>
                <div className="rangepick">
                  {/* <span className="description right">Selected time:</span>
                    <RangePicker
                       style={{width: 290,}}
                      ranges={{
                      Today: [moment(), moment()],
                      'This Month': [moment().startOf('month'), moment().endOf('month')],
                      }}
                      showTime
                      format={dateFormat}
                      onChange={this.rangeOnChange}
                      value={this.state.from===undefined||this.state.to===undefined||this.state.from===""||this.state.to===""?null:[moment(this.state.from, dateFormat), moment(this.state.to, dateFormat)]}
                    /> */}
                  <span className="description right">Loan status:</span>
                  <select
                    className="form_select"
                    value={this.state.fstSelect}
                    onChange={this.selectFst.bind(this)}
                  >
                    <option value="">all</option>
                    {/* <option value="4">In Loan</option> */}
                    <option value="5">In Loan</option>
                    <option value="6">Normal Repayment</option>
                    <option value="8">Early Repayment</option>
                    <option value="21">Overdue Status</option>
                    <option value="22">Overdue Repayment</option>
                    <option value="51">Bad Debt</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="order_mid">
              <div className="mid_left" style={{ width: "30%" }}>
                {/* <span className="description left">Phone:</span>
                  <Search
                    placeholder="Phone"
                    onSearch={value => console.log(value)}
                    style={{width: 290}}
                    value={this.state.mobileNum}
                    onChange={this.searchIpt1.bind(this)}
                  /> */}
                <span className="description left" style={{ width: 120 }}>
                  First Name:
                </span>
                <input
                  type="text"
                  placeholder="first name"
                  value={this.state.fstInput1}
                  onChange={this.fstInput1Change}
                  className="modal_input"
                  style={{ width: 290, marginLeft: 0 }}
                />
              </div>

              <div className="mid_right" style={{ width: "30%" }}>
                <span className="description mid_span">Middle Name:</span>
                <input
                  type="text"
                  placeholder="Middle Name"
                  value={this.state.fstInput2}
                  onChange={this.fstInput2Change}
                  className="modal_input"
                  style={{ width: 290, marginLeft: 0 }}
                />
              </div>

              <div className="mid_left" style={{ width: "30%" }}>
                <span className="description right">Last Name:</span>
                <input
                  type="text"
                  placeholder="Last Name"
                  value={this.state.fstInput3}
                  onChange={this.fstInput3Change}
                  className="modal_input"
                  style={{ width: 290, marginLeft: 0 }}
                />
              </div>
            </div>
            <div className="order_mid">
              <div className="top_mid" style={{ width: "30%", flexShrink: 2 }}>
                <div className="rangepick">
                  <span className="description right">
                    should repayment time:
                  </span>
                  <RangePicker
                    style={{ width: 290 }}
                    ranges={{
                      Today: [moment(), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                    }}
                    showTime
                    format={dateFormat}
                    onChange={this.rangeOnChange}
                    value={
                      this.state.from === undefined ||
                      this.state.to === undefined ||
                      this.state.from === "" ||
                      this.state.to === ""
                        ? null
                        : [
                            moment(this.state.from, dateFormat),
                            moment(this.state.to, dateFormat),
                          ]
                    }
                  />
                </div>
              </div>

              <div className="mid_right" style={{ width: "30%" }}>
              <Button type="primary" style={{width:170,marginRight:20,height:40,border:'none'}}  className="common_color" onClick={this.searchClick.bind(this)}>Search</Button>
              <Button type="primary" style={{width:170,height:40,border:'none'}} className="common_color_scd" onClick={this.reloadClick.bind(this)}>Refresh</Button>
              </div>
              <div className="mid_left" style={{ width: "30%" }}>

              </div>
            </div>
            <div className="bottom" style={{ justifyContent: "space-between" }}>
              <div
                className="top_mid"
                style={{ width: "30%", flexShrink: 1 }}
              ></div>
              <div
                className="mid_right"
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "30%",
                  justifyContent: "center",
                }}
              ></div>
            </div>
          </div>
          </div>
          <Table columns={columns} dataSource={this.state.tableData} scroll={{x:1500}} loading={this.state.boolean} onChange={this.handleChange} pagination={false} />

        <Modal
          title="Offline stage"
          visible={this.state.fstvisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={this.state.btnloading} onClick={this.handleOk}>
              Confirm
            </Button>,
          ]}
        >
        <input value={this.state.disval} style={{display:'none'}}/>
        <div className="model_last">
            <span  className="long">order number:</span>
            <input className="modal_input" style={{marginLeft:'10%',width:290}} type='text' value={this.state.modalinput} onChange={this.ModalInput}/>
        </div>
        <div className="model_last">
          <span  className="long">upload file:</span>
            <div  style={{marginLeft:'15%',alignItems:'center'}} className='message_file'>
            <Upload  {...props}>
            <Button   disabled={fileList.length === 1} onClick={this.changeupload}>
                <Icon type="upload" /> 选择文件
            </Button>
            </Upload>
            </div>
        </div>
        <div className="model_last">
            <span className="long">transaction number:</span>
            <input className="modal_input" style={{marginLeft:'2%',width:290}} value={this.state.modalscdinput} onChange={this.ModalScdInput}/>
        </div>
        <div className="model_last">
            <span className="long">repayment amount:</span>
            <input className="modal_input" style={{marginLeft:'2.5%',width:290}} value={this.state.modalthdinput} onChange={this.ModalThdInput}/>
        </div>
        <div className="model_last">
            <span className="long">repayment time:</span>
            <DatePicker  style={{marginLeft:'7%',width:290}}  disabledDate={this.handleData}  value={this.state.datepiker===undefined||this.state.datepiker===""?null:moment(this.state.datepiker, dateFormat) }onChange={this.dateonChange} />
        </div>
        <div className="model_last">
            <span  className="long">remark:</span>
            <textarea className='modal_textarea_new' value={this.state.fsttextarea} onChange={this.fsttxarea}/>
        </div>
        </Modal>

        <Modal
          title="处理记录"
          visible={this.state.AbnormalDialog}
          width={1100}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Cancel
            </Button>,
          ]}
        >
          <Table columns={AbnormalColumns} dataSource={this.state.AbnormalData} pagination={false} />
        </Modal>
        <Modal
          title="撤销"
          visible={this.state.cancelPrompt}
          onCancel={this.handleCancelPrompt}
          footer={[
            <Button key="back" onClick={this.handleCancelPrompt}>
              Cancel
            </Button>,
             <Button key="submit" type="primary"  onClick={this.handleOkcancelPrompt}>
             Confirm
           </Button>,
          ]}
        >
          <p>您确定要撤销此笔操作？</p>
        </Modal>
        <Pagination
          showQuickJumper
          defaultCurrent={1}
          current={this.state.current}
          total={this.state.total}
          onChange={this.pageChange}
          showTotal={showTotal}
          style={{textAlign:'right',marginTop:20}}
          pageSize={this.state.pageSize}
          showSizeChanger={true}
          onShowSizeChange={this.onShowSizeChange} //pagesize改变时回调函数
          pageSizeOptions={["10","50","100"]}   //设置pagesize区间
          />
        </div>
      );
    }
  }
  export default withRouter(RepaymentPlan)
