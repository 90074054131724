/*eslint-disable*/
import React from 'react';
import { Input,Button,Table,Modal,Pagination,DatePicker,Tooltip,message } from 'antd';
import moment from 'moment';
import axios from 'axios'
import './index.css';
import $ from 'jquery';
import {withRouter} from 'react-router-dom'
import EventEmitter from '../../../utils/events'

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD"||undefined;
var collectorSelect = [];
function showTotal(total) {
    return `Total ${total} items`;
}
class getHistoryRemindDataList extends  React.Component {
    constructor(props) {
        super(props);
        this.state = {
            from:'',
            to:'',
            current:1,
            pageSize:10,
            isshow:true,
            ishide:false,
            boolean:true,
            topboolean:true,
            total:0,
            id:'',
            role:'',
            collectionId:'',
            queryType:'',
            collectorSelect:''
        };
    }
    componentDidMount() {
        this.setState({
        },()=>{
            this.searchClick()
        })
        this.select()
    }
    rangeOnChange=(dates, dateStrings)=>{
        this.setState({ from: dateStrings[0],to:dateStrings[1] })
    }
    searchIpt(e){
        var value = e.target.value;
        this.setState({ searchVal: value })
    }
    pageChange=(page)=>{
        this.setState({
            current: page
        },()=>{
            this.searchClick()
        })
    }
    onShowSizeChange=(current, pageSize)=>{

        this.setState({
            pageSize: pageSize
        },()=>{
            this.searchClick()
        })
    }

todetail=(record)=>{
    window.location='/#/remind/getHistoryRemindDataList/month?collectionId='+record.collectionId
    let test = [{path: '/remind/getHistoryRemindDataList/month',key: `/remind/getHistoryRemindDataList/month/${record.collectionId}`,number: `${record.collectionId}`}]
    EventEmitter.trigger('btn', test[0])
    EventEmitter.trigger('idanduid', test[0].number)
    localStorage.id = record.collectionId
    console.log(record.collectionId);
    // window.location.href='/#/collection/getCollectionOrderManagement/month?id='+record.id+'&uid='+record.uid
}

//重置按钮
reloadClick = (e) => {
    this.setState({
        fstSelect: '',
        scdSelect: '',
        fouSelect: '',
        searchVal: '',
        from: undefined,
        to: undefined,
        id: '',
        boolean: true,
        collectionId:'',
        role: '',
        collectorSelect:'',
    }, () => {
        this.searchClick()
    });
}

rolesSelect(e) {
var value = e.target.value;
this.setState({
    role: value,
});
}

//选择collector
selectscdd(e){
    var value = e.target.value;
    var userInfo = $("#ipt2").val();
    var getUserId = $("option[value='" + userInfo + "']").data("userid");
    $("input[name='userId']").val(getUserId);
    this.setState({
        scdSelect:value,
        collectionId:getUserId
    });
}

//获取select
select=()=>{
    axios.post(`/api/remindData/getRemindUsers`).then(res=>{
        if(res.data.code === 200){
            collectorSelect=[]
            let data = res.data.data.resultDate
            for(let i=0;i<data.length;i++ ){
                collectorSelect.push(
                    <option data-userid={data[i].id} value={data[i].userName}></option>
                )
                this.setState({
                    collectorSelect:collectorSelect
                })
            }
        }else if(res.data.code ==800){
            this.props.history.push({ pathname: "/"});
        }
        else{
        }
    })
}

    searchClick=()=>{
        this.setState({
            topboolean:true,
            boolean:true
        })
        axios
            .post(`/api/remindData/getHistoryRemindDataList`,{

                beginTime:this.state.from,
                endTime:this.state.to,
                pageSize: 10,
                current: 1,
                role:this.state.role,
                collectionId:this.state.collectionId,
                queryType:1,
                queryDataType:1
            })
            .then(res=>{
                if(res.data.code === 200){
                    const data1 = [];
                    const data = res.data.data.resultDate
                    if(data.length<1){
                        this.setState({
                            topboolean:false
                        })
                    }else{
                        for (let i = 0; i < data.length; i++) {
                            data1.push({
                                key: i,
                                totalAllocate:data[i].totalAllocate,
                                repaidAfterAllocate:data[i].repaidAfterAllocate,
                                remindRepaymentRate:data[i].remindRepaymentRate <= 0 ? "0%" : data[i].remindRepaymentRate+"%",
                                todayExtendOrder:data[i].todayExtendOrder,
                                totalOverdue:data[i].totalOverdue,
                                overdueRate:data[i].overdueRate <= 0 ? "0%" : data[i].overdueRate+"%",
                                reminded:data[i].reminded,
                                notRemind:data[i].notRemind
                            });

                        }
                    }

                    this.setState({
                        totalData:data1,
                        topboolean:false
                    })
                }else if(res.data.code ==800){
                    this.props.history.push({ pathname: "/"});
                }else{

                }
            })
        axios
            .post(`/api/remindData/getHistoryRemindDataList`,{
                beginTime:this.state.from,
                endTime:this.state.to,
                pageSize: this.state.pageSize,
                current: this.state.current,
                role:this.state.role,
                collectionId:this.state.collectionId,
                queryType:1,
                queryDataType:2
            })
            .then(res=>{
                if(res.data.code === 200){
                    const data1 = [];
                    const data = res.data.data.resultDate
                    if(data.length<1){
                        this.setState({
                            boolean:false
                        })
                    }else{
                        for (let i = 0; i < data.length; i++) {
                            data1.push({
                                key: i,
                                collectionId:data[i].collectionId,
                                role:data[i].role,
                                collector:data[i].collector,
                                totalAllocate:data[i].totalAllocate,
                                repaidAfterAllocate:data[i].repaidAfterAllocate,
                                remindRepaymentRate:data[i].remindRepaymentRate <= 0 ? "0%" : data[i].remindRepaymentRate+"%",
                                todayExtendOrder:data[i].todayExtendOrder,
                                totalOverdue:data[i].totalOverdue,
                                overdueRate:data[i].overdueRate <= 0 ? "0%" : data[i].overdueRate+"%",
                                reminded:data[i].reminded,
                                notRemind:data[i].notRemind
                            });
                        }
                    }

                    this.setState({
                        tableScdData:data1,
                        total:res.data.data.page.total,
                        boolean:false
                    })
                }else if(res.data.code ==800){
                    this.props.history.push({ pathname: "/"});
                }else{
                }
            })

    }

    render() {
        const botcolumns = [
            {
                title: 'Total allocate',
                dataIndex: 'totalAllocate',
                key: 'totalAllocate',

            },
            {
                title: 'Repaid after allocate',
                dataIndex: 'repaidAfterAllocate',
                key: 'repaidAfterAllocate',

            },
            {
                title: 'Remind repayment rate',
                dataIndex: 'remindRepaymentRate',
                key: 'remindRepaymentRate',

            },
            {
                title: 'Total extend order',
                dataIndex: 'todayExtendOrder',
                key: 'todayExtendOrder',
            },
            {
                title: 'Total overdue',
                dataIndex: 'totalOverdue',
                key: 'totalOverdue',

            },
            {
                title: 'Overdue rate',
                dataIndex: 'overdueRate',
                key: 'overdueRate',

            },
            {
                title: 'Reminded',
                dataIndex: 'reminded',
                key: 'reminded',
                width:100,
            },
            {
                title: 'Not remind',
                dataIndex: 'notRemind',
                key: 'notRemind',

            }
        ];

        const columns = [
            {
                title: 'Role',
                dataIndex: 'role',
                key: 'role',
                render(text, record, index){
                    if(text===21){
                        return "S0-Old";
                    }else if (text===22) {
                        return "S0-New";
                    }else {
                        return "--";
                    }
                }
            },
            {
                title: 'Collector',
                dataIndex: 'collector',
                key: 'collector',

            },
            {
                title: 'Total allocate',
                dataIndex: 'totalAllocate',
                key: 'totalAllocate',

            },
            {
                title: 'Repaid after allocate',
                dataIndex: 'repaidAfterAllocate',
                key: 'repaidAfterAllocate',

            },
            {
                title: 'Remind repayment rate',
                dataIndex: 'remindRepaymentRate',
                key: 'remindRepaymentRate',

            },
            {
                title: 'Total extend order',
                dataIndex: 'todayExtendOrder',
                key: 'todayExtendOrder',
            },
            {
                title: 'Total overdue',
                dataIndex: 'totalOverdue',
                key: 'totalOverdue',

            },
            {
                title: 'Overdue rate',
                dataIndex: 'overdueRate',
                key: 'overdueRate',

            },
            {
                title: 'Reminded',
                dataIndex: 'reminded',
                key: 'reminded',
                width:100,
            },
            {
                title: 'Not remind',
                dataIndex: 'notRemind',
                key: 'notRemind',

            },
            {
                title: 'operating',
                dataIndex: 'operating',
                key: 'operating',
                id:'collectionId',
                // width:20,
                // fixed: 'right',
                render: (text, record, index) => (<span style={{ display: 'flex' , flexDirection: 'column', justifyContent:'center',}}>
            <Button type="primary" style={this.state.detailsbutton} className="detailsbutton" size="small" onClick={() => this.todetail(record)}>details</Button>

                </span>)
            },
        ];
        return (
            <div className="overduepaid">
                <div className="overduepaid_top">
                    <div className="overduepaid_mid">
                        <div className="bottom" style={{justifyContent:'left',alignItems:'center',marginTop:0}}>
                            <div className="mid_mid" style={{width:'80%'}}>
                                <div className="rangepick" style={{float: "left"}}>
                                    <span className="description mid_span" style={{width: 100,textAlign:'right'}}>Created time:</span>
                                    <RangePicker
                                        style={{width: 290,}}
                                        ranges={{
                                            Today: [moment(), moment()],
                                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                                        }}
                                        showTime
                                        format={dateFormat}
                                        onChange={this.rangeOnChange}
                                        value={this.state.from===undefined||this.state.to===undefined||this.state.from===""||this.state.to===""?null:[moment(this.state.from, dateFormat), moment(this.state.to, dateFormat)]}
                                    />
                                </div>
                                <div style={{float: "left"}}>
                                    <span className="description right" style={{width: 70,textAlign:'right'}}>Role:</span>
                                    <select className="form_select" value={this.state.role} onChange={this.rolesSelect.bind(this)}>
                                        <option value="">all</option>
                                        <option value="22">S0-New</option>
                                        <option value="21">S0-Old</option>
                                    </select>
                                </div>
                                <div style={{float: "left"}}>
                                    <span className="description right" style={{width: 70,textAlign:'right'}}>Collector:</span>
                                    <input list='browsers' placeholder='all' className="form_select_mohu" id={this.state.id} id="ipt2" value={this.state.scdSelect} onChange={this.selectscdd.bind(this)}/>
                                    <datalist id="browsers">
                                        {collectorSelect}
                                    </datalist>
                                </div>
                            </div>
                            <div className="bottom" style={{marginTop:"0%"}}>
                                <Button type="primary" style={{width:170,marginRight:20,height:40,backgroundColor:'#775bee',border:'none'}} onClick={this.searchClick.bind(this)}>Search</Button>
                                <Button type="primary" style={{width: 170, height: 40, backgroundColor: '#07AEE6', border: 'none'}} onClick={this.reloadClick}>Refresh</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overdue_table">
                    <Table columns={botcolumns} dataSource={this.state.totalData} onChange={this.handleChange} pagination={false}/>
                </div>
                <div className="overdue_table">
                    <Table columns={columns} dataSource={this.state.tableScdData} loading={this.state.boolean} scroll={{x:'100%'}}onChange={this.handleChange} pagination={false}/>
                    <Pagination

                        showSizeChanger
                        onShowSizeChange={this.onShowSizeChange}
                        pageSizeOptions={["10","50","100"]}
                        defaultCurrent={1}
                        current={this.state.current}
                        total={this.state.total}
                        onChange={this.pageChange}
                        pageSize={this.state.pageSize}
                        showTotal={showTotal}

                        style={{textAlign:'right',marginTop:20}}
                    />
                </div>
            </div>
        );
    }
}
export default withRouter(getHistoryRemindDataList)