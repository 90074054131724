/*eslint-disable*/
import React from 'react';
import { Button,Table,DatePicker,Pagination,message } from 'antd';
import './index.css';
import axios from 'axios'
import moment from 'moment';
import {withRouter} from 'react-router-dom'

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD'||undefined;

function showTotal(total) {
  return `Total ${total} items`;
}

class dayPerson extends React.Component{
    constructor(props){
        super(props);
        this.state={
          from:'',
          to:'',
          tableData:[], 
          current:1,
          length:1,
          pageSize:10,
          boolean:true,
          botboolean:true,
          total:''
        }
      }
      componentDidMount(){
        this.searchClick();

      }
      collector =(params)=>{
        var va = params.grade
          if (va==1){
              return <span style={{color:'black'}}>S1-Old</span>
          }else if (va==2){
              return <span style={{color:'black'}}>S2-Old</span>
          }else if (va==3){
              return <span style={{color:'black'}}>M1-Old</span>
          }else if (va==4){
              return <span style={{color:'black'}}>M2</span>
          }else if (va==11){
              return <span style={{color:'black'}}>S1-New</span>
          }else if (va==12){
              return <span style={{color:'black'}}>S2-New</span>
          }else if (va==13){
              return <span style={{color:'black'}}>M1-New</span>
          }else {
              return <span style={{color:'black'}}></span>
          }
        }
      //设置时间
      rangeOnChange=(dates, dateStrings)=>{
        this.setState({ from: dateStrings[0],to:dateStrings[1] })
      }
    showmodal=()=>{
        // alert(this.index)
    }
    pageChange=(page)=>{
      this.setState({
        current: page
        },()=>{
          this.searchClick()
        })
    }
    onShowSizeChange=(current, pageSize)=>{

      this.setState({
        pageSize: pageSize
      },()=>{
        this.searchClick()
      })
  }
// //页面初始化加载的上面的table
// Table=()=>{

//   let urlid = ''
//   var url = window.location.hash
//   var params = url.substr(1);
//   var aPos = params.indexOf("=")
//    urlid = params.substr(aPos + 1);
//        axios
//       .post(`/api/collectionData/getCollectionDataDayDetails`,{
//       pageSize: this.state.pageSize,
//       current: this.state.current,
//       id:urlid,
//     })
//     .then(res=>{
//       if(res.data.code === 200){
//         const data1 = [];
//         const data = res.data.data.resultDate
//         if(data.length<1){
//           this.setState({
//             boolean:false
//           })
//         }else{
//           for (let i = 0; i < data.length; i++) {
//           data1.push({
//           key: i,
//           date:data[i].date,
//           grade:data[i].overdueGrade,
//           collector:data[i].collectionName,
//           currtotal:data[i].underUrgeToday,
//           collectionOrder:data[i].urgedToday,
//           notcollection:data[i].unUrgeToday,
//           totalcollection:data[i].urgeCountToday,
//           Average:data[i].urgeAverageCountToday,
//           Todayrepay:data[i].urgeRepayToday,
//         });
//       }
          
//           this.setState({
//             tableData:data1,
//             total:res.data.data.page.total,
//             boolean:false
//           })
//         } 
//       }else if(res.data.code ==800){
//         this.props.history.push({ pathname: "/"});
//       }else{
  
//       }
//     })


  
// }


// //页面初始化加载的table
//   secondTable=()=>{
//     let urlid = ''
//     var url = window.location.hash
//     var params = url.substr(1);
//     var aPos = params.indexOf("=")
//      urlid = params.substr(aPos + 1);
//       axios
//         .post(`/api/collectionData/getCollectionDataDayDetailsTotal`,{
  
//         pageSize: this.state.pageSize,
//         current: this.state.current,
//         id:urlid,
//       })
//       .then(res=>{
//         if(res.data.code === 200){
          
//           const data1 = [];
//           const data = res.data.data.resultDate
//           if(data.length<1){
//             this.setState({
//               botboolean:false
//             })
//           }else{
//             for (let i = 0; i < data.length; i++) {
//               data1.push({
//               key: i,
//               Date:'--',
//               Collector:'--',
//               Overduegrd:'--',
//               Currentorder:data[i].underUrgeToday,
//               Collected:data[i].urgedToday,
//               Not:data[i].unUrgeToday,
//               TotalTimes:data[i].urgeCountToday,
//               AverageTimes:data[i].urgeAverageCountToday,
//               TodayOrder:data[i].urgeRepayToday,
//             });
//           }
           
//             this.setState({
//               tableScdData:data1,
//               botboolean:false
//             })
//           } 
//         }else if(res.data.code ==800){
//           this.props.history.push({ pathname: "/"});
//         }else{
  
//         }
//       })

   
    
//   }


          //搜索
          searchClick=()=>{ 
            let urlid = ''
            var url = window.location.hash
            var params = url.substr(1);
            var aPos = params.indexOf("=")
             urlid = params.substr(aPos + 1);
             this.setState({
              botboolean:true,
              boolean:true
             })
            axios
              .post(`/api/collectionData/getCollectionDataDayDetailsTotal`,{
        
              pageSize: this.state.pageSize,
              current: this.state.current,
              id:urlid,
              beginTime:this.state.from,
              endTime:this.state.to
            })
            .then(res=>{
              if(res.data.code === 200){
                
                const data1 = [];
                const data = res.data.data.resultDate
                if(data.length <1){
                  this.setState({
                    botboolean:false
                  })
                }else{
                  for (let i = 0; i < data.length; i++) {
                    data1.push({
                    key: i,
                    Date:'--',
                    Collector:'--',
                    Overduegrd:'--',
                    Currentorder:data[i].underUrgeToday,
                    Collected:data[i].urgedToday,
                    Not:data[i].unUrgeToday,
                    TotalTimes:data[i].urgeCountToday,
                    AverageTimes:data[i].urgeAverageCountToday,
                    TodayOrder:data[i].urgeRepayToday,
                    extendOrder:data[i].extendOrder,
                  });
                }
                 
                
                } 
                this.setState({
                  tableScdData:data1,
                  botboolean:false
                })
              }else if(res.data.code ==800){
                this.props.history.push({ pathname: "/"});
              }else{
        
              }
            })
            axios
              .post(`/api/collectionData/getCollectionDataDayDetails`,{
              pageSize: this.state.pageSize,
              current: this.state.current,
              id:urlid,
              beginTime:this.state.from,
              endTime:this.state.to
            })
            .then(res=>{
              if(res.data.code === 200){
                const data1 = [];
                const data = res.data.data.resultDate
                if(data.length<1){
                  this.setState({
                    boolean:false
                  })
                }else{
                  for (let i = 0; i < data.length; i++) {
                  data1.push({
                  key: i,
                  date:data[i].date,
                  grade:data[i].overdueGrade,
                  collector:data[i].collectionName,
                  currtotal:data[i].underUrgeToday,
                  collectionOrder:data[i].urgedToday,
                  notcollection:data[i].unUrgeToday,
                  totalcollection:data[i].urgeCountToday,
                  Average:data[i].urgeAverageCountToday,
                  Todayrepay:data[i].urgeRepayToday,
                  extendOrder:data[i].extendOrder,
                });
              }
                  
                  this.setState({
                    tableData:data1,
                    total:res.data.data.page.total,
                    boolean:false
                  })
                } 
              }else if(res.data.code ==800){
                this.props.history.push({ pathname: "/"});
              }else{
          
              }
            })
                  
  }
    render(){
        

        const columns = [
           {
              title: 'date',
              dataIndex: 'date',
              key: 'date'
            }, 
            {
              title: 'Collector',
              dataIndex: 'collector',
              key: 'collector',

            }, 
            {
              title: 'Overdue grade',
              dataIndex: 'grade',
              key: 'grade',
              render:(text,record) =>  this.collector(record)

            }, 
            {
              title: 'Current Order Total',
              dataIndex: 'currtotal',
              key: 'currtotal'
            }, 
            {
              title: 'Collected Order',
              dataIndex: 'collectionOrder',
              key: 'collectionOrder'
            },
            {
              title: 'Not Collection Order',
              dataIndex: 'notcollection',
              key: 'notcollection'
            },
            {
                title: 'Total Collection Times',
                dataIndex: 'totalcollection',
                key: 'totalcollection'
            }, 
            {
                title: 'Average Collection Times',
                dataIndex: 'Average',
                key: 'Average'
            },
            {
                title: 'Today Repayment Order',
                dataIndex: 'Todayrepay',
                key: 'Todayrepay'
            },
            {
              title: 'Today extend order',
              dataIndex: 'extendOrder',
              key: 'extendOrder',
              width:100,
            },
          ];


          const botcolumns = [
            {
               title: 'date',
               dataIndex: 'Date',
               key: 'Date'
             }, 
             {
               title: 'Collector',
               dataIndex: 'Collector',
               key: 'Collector'
             }, 
             {
               title: 'Overdue grade',
               dataIndex: 'Overduegrd',
               key: 'Overduegrd',

             }, 
             {
               title: 'Current Order Total',
               dataIndex: 'Currentorder',
               key: 'Currentorder'
             }, 
             {
               title: 'Collected Order',
               dataIndex: 'Collected',
               key: 'Collected'
             },
             {
               title: 'Not Collection Order',
               dataIndex: 'Not',
               key: 'Not'
             },
             {
                 title: 'Total Collection Times',
                 dataIndex: 'TotalTimes',
                 key: 'TotalTimes'
             }, 
             {
                 title: 'Average Collection Times',
                 dataIndex: 'AverageTimes',
                 key: 'AverageTimes'
             },
             {
                 title: 'Today Repayment Order',
                 dataIndex: 'TodayOrder',
                 key: 'TodayOrder'
             },
             {
              title: 'Today extend order',
              dataIndex: 'extendOrder',
              key: 'extendOrder',
              width:100,
            },
           ];
        return(
            <div className="audit_content">
                <div className="commom_top" style={{alignItems:'center',backgroundColor:'#fff',paddingTop:20,paddingBottom:20,paddingLeft:20}}>
                <div className="rangepick">
                    <span className="description mid_span">Created time:</span>
                    <RangePicker
                        style={{width: 290,marginRight:20}}
                        ranges={{
                        Today: [moment(), moment()],
                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                        }}
                        showTime
                        format={dateFormat}
                        onChange={this.rangeOnChange}
                        value={this.state.from===undefined||this.state.to===undefined||this.state.from===""||this.state.to===""?null:[moment(this.state.from, dateFormat), moment(this.state.to, dateFormat)]}
                            />
                        </div>
                <Button type="primary" style={{width:170,marginRight:20,height:40,border:'none'}} className="common_color" onClick={this.searchClick.bind(this)}>Search</Button>
                </div>
                <div className="table">
                <Table columns={columns} dataSource={this.state.tableData} loading={this.state.boolean} pagination={false} scroll={{ x: '100%' }}/>
                </div>

                  <Pagination
                  showSizeChanger                          //展示改变pagesize样式
                  onShowSizeChange={this.onShowSizeChange} //pagesize改变时回调函数
                  pageSizeOptions={["10","50","100"]}   //设置pagesize区间
                  defaultCurrent={1} 
                  current={this.state.current}
                  total={this.state.total} 
                  onChange={this.pageChange} 
                  pageSize={this.state.pageSize}
                  showTotal={showTotal}
                  style={{textAlign:'right',marginTop:20}}
                  />
                  <Table columns={botcolumns} dataSource={this.state.tableScdData} loading={this.state.botboolean} pagination={false} scroll={{ x: '100%' }}/>

            </div>
        )
    }
}
export default withRouter(dayPerson)