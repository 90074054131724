/*eslint-disable*/
import React from 'react';
import {Input, Button, Table, Modal, Pagination, DatePicker, Tooltip, message,Icon} from 'antd';
import './index.css';
import axios from 'axios'
import moment from 'moment';
import {withRouter} from 'react-router-dom'
import EventEmitter from '../../utils/events'

function showTotal(total) {
    return `Total ${total} items`;
}

const {Search} = Input;
var getselect = []
var getSelect = []

class Blacklist extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filteredInfo: null,
            sortedInfo: null,
            data: [],
            tableData: [],
            searchVal: '',
            searchphone: '',
            searchemail: '',
            searchname: '',
            fstSelect:'',
            scdSelect:'',
            thdSelect:'',
            fouSelect:'',
            current: 1,
            total: 1,
            pageSize: 10,
            boolean: true,
            ishide: false,
            isshow: true,
            scdmodalSelect:0,
            selectedRowKeys:[],
            ids:[]
        }
    }

    //调整按钮
    batch = () => {
        this.setState({
            scdvisible:true,
            ids:this.state.selectedRowKeys
        })
    }
    //拉黑按钮
    blacklist = () => {
        this.setState({
            fstvisible:true
        })
    }
    //点击Modal取消按钮
    handleCancel=()=>{
        this.setState({
            fstvisible:false,
            scdvisible:false,
            thdvisible:false,
            modalinput:'',
            modalthdSelect:'',
            fsttextarea:'',
            selectedRowKeys:'',
            scdmodalSelect:'',
            scdmodalSelect:0,
            scdafter:false,

        })
    }
     //点击Modal确定按钮
     handleOk=()=>{
        this.setState({
            scdafter:true,
          })
        axios
            .post(`/api/blacklist/blacklistAdd`, {

                status:0,
                mobile:this.state.modalinput,
                remark:this.state.fsttextarea,
                reason:this.state.modalthdSelect,
            })
            .then(res => {
                if (res.data.code === 200) {
                    message.success(res.data.msg)
                    this.setState({
                        scdafter:false,
                    })
                } else if (res.data.code == 800) {
                    this.props.history.push({pathname: "/"});
                } else {
                    message.error(res.data.msg)
                }

            })
        this.setState({
            modalinput:'',
            modalthdSelect:'',
            fsttextarea:'',
            fstvisible:false,
            boolean:true,
            scdafter:false,
            
        })
        this.searchClick()

    }

     //点击review的Modal确定按钮
     scdhandleOk=(e)=>{
        if(this.state.ids.length<1){
            message.error('please select one');
            return false
        }
        let arr = this.state.ids;
       
            let lastarr = arr;
            axios
            .post(`/api/blacklist/batchAudit`, {
                ids:lastarr,
                status:this.state.scdmodalSelect,
            })
            .then(res => {
                if (res.data.code === 200) {
                    message.success(res.data.msg)
                    this.setState({
                        scdvisible:false,
            
                    })
                } else if (res.data.code == 800) {
                    this.props.history.push({pathname: "/"});
                } else {
                    message.error(res.data.msg)
                    this.setState({
                        scdvisible:false,
            
                    })
                }

            })
        this.setState({
            modalthdSelect:'',
            boolean:true,
            selectedRowKeys:'',
            scdmodalSelect:'',
            scdmodalSelect:0

        })
        this.searchClick()
    }
    reloadClick=(e)=> {
        this.setState({
            fstSelect:'',
            scdSelect:'',
            thdSelect:'',
            fouSelect:'',
            searchVal: '',
            searchphone: '',
            searchemail: '',
            searchname: '',
            boolean: true

        },()=>{
            this.secondTable()
        })
        
    }

    //输入框
    searchIpt(e) {
        var value = e.target.value;
        this.setState({searchVal: value.replace(/\s+/g,"")})
    }

    //手机号输入框
    searchphoneIpt(e) {
        var value = e.target.value;
        this.setState({searchphone: value.replace(/\s+/g,"")})
    }

    //email输入框
    searchemailIpt(e) {
        var value = e.target.value;
        this.setState({searchemail: value.replace(/\s+/g,"")})
    }

    //名字输入框
    searchnameIpt(e) {
        var value = e.target.value;
        this.setState({searchname: value.replace(/\s+/g,"")})
    }

    showmodal = () => {
        alert(this.index)
    }
    //审核按钮
    black = (record) => {
        this.setState({
            ids:[record.id],
            scdvisible:true
        })
    }
    
    //删除按钮
    delete=(record)=>{
        this.setState({
            thdvisible:true,
            thdmodalinpt:record.id,
        })
    }
    //第三个modal提交按钮
    thdhandleOk = () => {

            axios
            .post(`/api/blacklist/batchAudit`, {
                ids:[this.state.thdmodalinpt],
                status:'-1',
            })
            .then(res => {
                if (res.data.code === 200) {
                    message.success(res.data.msg)
                } else if (res.data.code == 800) {
                    this.props.history.push({pathname: "/"});
                } else {
                    message.error(res.data.msg)
                }

            })
        this.setState({
            thdvisible:false,
            boolean:true,
        })

        this.searchClick()

    }
    pageChange = (page) => {
        this.setState({
            current: page
        }, () => {
            this.searchClick()
        })
    }
    
    //status下拉框
    selectFst=(e)=>{
        var value = e.target.value;
        this.setState({
            fstSelect:value
        })
    }
    //role下拉框
    selectScd=(e)=>{
        var value = e.target.value;
        this.setState({
            scdSelect:value
        })
    }
    selectScd
    //reason下拉框
    selectThd=(e)=>{
        var value = e.target.value;
        this.setState({
            thdSelect:value
        })
    }
    //操作员下拉框
    selectFou=(e)=>{
        var value = e.target.value;
        this.setState({
            fouSelect:value
        })
    }
      
   //modal内reason下拉框
   modalselectThd=(e)=>{
    var value = e.target.value;
    this.setState({
        modalthdSelect:value
    })
}
   //modal内review的下拉框
   scdmodalselect=(e)=>{
    var value = e.target.value;
    this.setState({
        scdmodalSelect:value
    })
}

//页面初始化加载下面的table
    secondTable = () => {

        axios
            .post(`/api/blacklist/blacklistList`, {

                pageSize: this.state.pageSize,
                current: this.state.current,
            })
            .then(res => {
                if (res.data.code === 200) {
                    const data1 = [];
                    const data = res.data.data.resultDate
                    if(data.length<1){
                        this.setState({
                            boolean: false
                        })
                    }else{
                        for (let i = 0; i < data.length; i++) {
                            data[i].key = i;
                            data1.push(data[i]);
                            localStorage.userId = data[i].reviewerId
                        }
                    }
                    this.setState({
                        tableScdData: data1,
                        total: res.data.data.page.total,
                        boolean: false
                    })
                } else if (res.data.code == 800) {
                    this.props.history.push({pathname: "/"});
                } else {
                }

            })
    }

    //搜索
    searchClick = () => {

        axios
            .post(`/api/blacklist/blacklistList`, {

                beginTime: this.state.from,
                endTime: this.state.to,
                pageSize: this.state.pageSize,
                current: this.state.current,
                status: this.state.fstSelect,
                roleId:this.state.scdSelect,
                bvnNo: this.state.searchVal,
                mobile: this.state.searchphone,
                email: this.state.searchemail,
                reason:this.state.thdSelect,
                operatorId:this.state.fouSelect,
                name: this.state.searchname,
            })
            .then(res => {
                if (res.data.code === 200) {

                    const data1 = [];
                    const data = res.data.data.resultDate

                    for (let i = 0; i < data.length; i++) {
                        data[i].key = i;
                        data1.push(data[i]);
                    }
                    this.setState({
                        tableScdData: data1,
                        total: res.data.data.page.total,
                        boolean: false
                    })
                } else if (res.data.code == 800) {
                    this.props.history.push({pathname: "/"});
                } else {
                }
            })
    }
    //Modal内的input
    ModalInput=(e)=>{
        var value = e.target.value;
        this.setState({
            modalinput:value
        })
    }
    //获取reason下拉框
    ReasonSelect=()=>{
        axios
          .post(`/api/blacklist/searchList`,{
          })
        .then(res=>{
          getselect=[]
          getSelect=[]
          if(res.data.code === 200){

            for(let i=0;i<res.data.data.reasonList.length;i++ ){
              if(i==0){
                getselect.push(<option value=''>All</option> )
              }
                getselect.push(       
                  <option value={res.data.data.reasonList[i]}>{res.data.data.reasonList[i]}</option>
              )
            this.setState({
              getselect:getselect
            })
            }
            for(let i=0;i<res.data.data.operatorList.length;i++ ){
                  if(i==0){
                    getSelect.push(<option value=''>All</option> )
                  }
                    getSelect.push(       
                      <option value={res.data.data.operatorList[i].id}>{res.data.data.operatorList[i].userName}</option>
                  )
                this.setState({
                  getSelect:getSelect
                })
                }
          }else if(res.data.code ==800){
            this.props.history.push({ pathname: "/"});
          }else{
          }
        })
        this.setState({

        });
      }
    //第一个textarea
    fsttxarea=(e)=>{
        let value = e.target.value;
        this.setState({ fsttextarea: value })
      }

    toShopDetails = (record) => {
        let id = 0
        window.location='/#/details/index?id='+id+'&uid='+record.uid
        let test = [{path: '/details/index',key: `/details/index/${id}/${record.uid}`,number: `${id}/${record.uid}`}]
        EventEmitter.trigger('btn', test[0])
        EventEmitter.trigger('idanduid', test[0].number)
    }
    onShowSizeChange=(current, pageSize)=>{

        this.setState({
          pageSize: pageSize
        },()=>{
          this.secondTable()
        })
    }
    componentDidMount() {
        this.secondTable()
        this.ReasonSelect()
    }
    onSelectChange = selectedRowKeys => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
      };
    render() {
        const { selectedRowKeys } = this.state;
        const rowSelection = {
          selectedRowKeys,
          onChange: this.onSelectChange,
           columnWidth:20,
           getCheckboxProps: record => ({
            disabled: record.status === 0, // Column configuration not to be checked
            name: record.name,
          }),
        };

        const columns = [
            {
                title: 'First Name',
                dataIndex: 'firstName',
                key: 'firstName',
            },
            {
                title: 'status',
                dataIndex: 'status',
                key: 'status',
                render: (value) => {
                    if (value == 0) {
                        return 'blacklist'
                    } else if (value == 1) {
                        return 'under review'
                    } else {
                        return '——'
                    }
                }
            },


            {
                title: 'BvnNo',
                dataIndex: 'bvnNo',
                key: 'bvnNo'
            },
            {
                title: 'Success Repayment Count',
                dataIndex: 'user.repaymentCount',
                key: 'repaymentCount'
            },
            {
                title: 'mobile',
                dataIndex: 'mobile',
                key: 'mobile'
            },
            {
                title: 'email',
                dataIndex: 'email',
                key: 'email'
            },
            {
                title: 'IMEI',
                dataIndex: 'imei',
                key: 'imei'
            },
            {
                title: 'Add black reason',
                dataIndex: 'reason',
                key: 'reason',
                render: (record) => {
                    return (
                      <Tooltip title={record}>
                        <div className="twoEllipsis">
                          {record}
                        </div>
                      </Tooltip>
                    )
                }
            },
            {
                title: 'remark',
                dataIndex: 'remark',
                key: 'remark',
                render: (record) => {
                    return (
                      <Tooltip title={record}>
                        <div className="twoEllipsis">
                          {record}
                        </div>
                      </Tooltip>
                    )
                }
            },
            {
                title: 'blacken time',
                dataIndex: 'createdTime',
                key: 'createdTime',
                render: (value) => {
                    let timeaudit= new Date(value).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
                    let auditimes=moment(new Date(timeaudit)).format("YYYY-MM-DD HH:mm:ss");
                    return auditimes
                }
            },
            {
                title: 'reviewer',
                dataIndex: 'manage.userName',
                key: 'operatorId',
               
            },
            {
                title: 'Operation',
                key: 'operation',
                id: 0,
                uid:'uid',
                render: (record) => (<span style={{ display: 'flex' , flexDirection: 'row', justifyContent:'center',margin:0}}>
                <Button type="primary" style={{
                    width: 80,
                    height: 30,
                    backgroundColor: '#775bee',
                    border: 'none'
                }} onClick={() =>this.toShopDetails(record)}>Detail</Button>
                <Button type="primary" style={{
                    width: 80,
                    height: 30,
                    marginLeft: 10,
                    display: record.status==0? "none" : 'block',
                    backgroundColor: '#775bee',
                    border: 'none'
                }} onClick={() =>this.black(record)}>Review</Button>
                <Button type="primary"  style={{   width: 80,
                    height: 30,marginLeft: 10,backgroundColor: '#f00',border: 'none',  backgroundColor: '#775bee',}}onClick={() => this.delete(record)}>Delete</Button>

              </span>)

            },
        ];
        return (
            <div className="audit_content">
                <div className="commom_top" style={{flexDirection: 'column'}}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <div className="mid_left" style={{width: '25%'}}>
                            <span className="description_new">BVN:</span>
                            <Search
                                placeholder="BVN"
                                onSearch={value => console.log(value)}
                                style={{width: 220}}
                                value={this.state.searchVal}
                                onChange={this.searchIpt.bind(this)}
                            />
                        </div>
                        <div className="mid_left" style={{width: '25%', marginLeft: '2%'}}>
                            <span className="description_new">Phone:</span>
                            <Search
                                placeholder="Phone"
                                onSearch={value => console.log(value)}
                                style={{width: 220}}
                                value={this.state.searchphone}
                                onChange={this.searchphoneIpt.bind(this)}
                            />
                        </div>
                        <div className="mid_left" style={{width: '25%', marginLeft: '2%'}}>
                            <span className="description_new">Email:</span>
                            <Search
                                placeholder="Email"
                                onSearch={value => console.log(value)}
                                style={{width: 220}}
                                value={this.state.searchemail}
                                onChange={this.searchemailIpt.bind(this)}
                            />
                        </div>
                        <div className="mid_left" style={{width: '25%', marginLeft: '2%'}}>
                            <span className="description_new">Name:</span>
                            <Search
                                placeholder="Name"
                                onSearch={value => console.log(value)}
                                style={{width: 220}}
                                value={this.state.searchname}
                                onChange={this.searchnameIpt.bind(this)}
                            />
                        </div>


                    </div>

                    <div style={{display: 'flex', justifyContent: 'row', marginTop: 20}}>
                        <div className="mid_left" style={{width: '25%'}}>
                            <span className="description_new">Status:</span>
                            <select className="form_select_new" value={this.state.fstSelect} onChange={this.selectFst}>
                                <option value="">All</option>
                                <option value="0">BlackList</option>
                                <option value="1">Under Review</option>
                            </select>
                        </div>
                        <div className="mid_mid" style={{width: '25%', marginLeft: '2%'}}>
                            <span className="description_new">Role:</span>
                            <select className="form_select_new"  value={this.state.scdSelect}
                                    onChange={this.selectScd}>
                                <option value="">All</option>
                                <option value="2,12">Review</option>
                                <option value="6,11">Collection</option>
                            </select>
                        </div>

                        <div className="mid_right" style={{width: '25%', marginLeft: '2%'}}>
                            <span className="description_new">Reason:</span>
                            <select className="form_select_new" value={this.state.thdSelect} onChange={this.selectThd}>
                                {getselect}
                            </select>
                        </div>
                        <div className="mid_right" style={{width: '25%', marginLeft: '2%'}}>
                            <span className="description_new">Operator:</span>
                            <select className="form_select_new" value={this.state.fouSelect} onChange={this.selectFou}>
                                {getSelect}
                            </select>
                        </div>

                    </div>
                    <div style={{display: 'flex', justifyContent: 'flex-start', marginTop: 20}}>
                        <Button type="primary" className='button_style'
                                style={{width: 170, marginRight: 20, height: 40, backgroundColor: '#775bee'}}
                                onClick={this.searchClick.bind(this)}>Search</Button>
                        <Button type="primary" className='button_style'
                                style={{width: 170, marginRight: 20, height: 40, backgroundColor: '#775bee',}}
                                onClick={this.blacklist}>Add To Blacklist</Button>
                        <Button type="primary" className='button_style'
                                style={{width: 170, marginRight: 20, height: 40, backgroundColor: '#775bee'}}
                                onClick={this.batch}>Batch Pass Audit</Button>
                        <Button type="primary" className='button_style'
                                style={{width: 170, marginRight: 20, height: 40, backgroundColor: '#775bee'}}
                                onClick={this.reloadClick}>Refresh</Button>


                    </div>
                </div>
                <div className="table">
                    <Table columns={columns}  rowKey={record=>record.id} rowSelection={rowSelection} dataSource={this.state.tableScdData}  loading={this.state.boolean}
                           pagination={false}/>,
                </div>
        <Modal
          title="Add To BlackList"
          visible={this.state.fstvisible}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              cancel
            </Button>,
            <Button key="submit" type="primary" loading={this.state.scdafter} onClick={this.handleOk}>
              ok
            </Button>,
          ]}
        >
        <div className="model_last">
        <span  className="long">mobile number:</span>
            <input className="modal_input" type='text' value={this.state.modalinput} onChange={this.ModalInput}/>
        </div>

        <div className="model_last">
        <span className="long">reason:</span>
            <select className="modal_getselect" style={{marginLeft:'14.7%',width:310}} value={this.state.modalthdSelect} onChange={this.modalselectThd}>
                {getselect}
            </select>
        </div>
        <div className="model_last">
        <span  className="long">remark:</span>
        <textarea className='modal_textarea' value={this.state.fsttextarea} onChange={this.fsttxarea}/>
        </div>
        </Modal>
        <Modal
          title="Audit"
          visible={this.state.scdvisible}
          onOk={this.scdhandleOk}
          onCancel={this.handleCancel}
        >
        <input style={{display:'none'}} value={this.state.scdmodalinpt}/>
        <div className="model_last">
        <span className="long">review result:</span>
            <select className="modal_getselect" style={{marginLeft:'4.7%',width:310}} value={this.state.scdmodalSelect} onChange={this.scdmodalselect}>
                <option value="0" selected>approved</option>
                <option value="-1">reject</option>
            </select>
        </div>

        </Modal>
        <Modal
          title="Make sure you want to delete?"
          visible={this.state.thdvisible}
          onOk={this.thdhandleOk}
          onCancel={this.handleCancel}
          okText="CONFIRM"
          cancelText="CANCEL"
        >
        <input style={{display:'none'}} value={this.state.thdmodalinpt}/>
        </Modal>
                <Pagination
                    // showQuickJumper
                    onShowSizeChange={this.onShowSizeChange} //pagesize改变时回调函数
                    pageSizeOptions={["10","50","100"]}   //设置pagesize区间
                    defaultCurrent={1}
                    current={this.state.current}
                    total={this.state.total}
                    onChange={this.pageChange}
                    showTotal={showTotal}
                    style={{textAlign: 'right', marginTop: 20}}
                    showSizeChanger={true}
                    pageSize={this.state.pageSize}
                />
            </div>
        )
    }
}
export default withRouter(Blacklist)