/*eslint-disable*/
import React from 'react';
import { Input, Button, Table, Modal, Icon, Pagination, DatePicker, Upload, Tooltip, message } from 'antd';
import axios from 'axios'
import './index.scss'
import moment from 'moment';
import EventEmitter from '../../utils/events'
import { withRouter } from 'react-router-dom'
import { exportMethod } from '../../utils/axios'
import Schema from 'async-validator'
import { getTotalTelemarketData, getTotalTelemarketSelect, TotalTelemarketFormInput } from '../../api/telemarket'
import { validateSymbol } from '../../utils/validate'
const { TextArea } = Input;

function showTotal(total) {
    return `Total ${total} items`;
}
var getselect = []
const dateFormat = 'YYYY-MM-DD' || undefined;
const { RangePicker } = DatePicker;
const { Search } = Input;
var Select = [] //催收员下拉框数据
const firstRule = { //校验规则
    resultStatus: [
        { required: true, message: 'please chose resultStatus ' },
    ],
    // remark:[
    //     {validator:validateSymbol}
    // ]
}
const secondRule = {
    operatorName: [
        {
            required: true, message: 'Please enter operatorName '
        },
        { validator: validateSymbol }
    ]
}
class SmsManage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 1,
            total: 1,
            from: '',
            to: '',
            selectedRowKeys: [],
            pageSize: 10,
            fileList: [],
            tableData: [],
            boolean: true,
            theme: '',
            indate: '',
            orderID: '',
        }
    }
    componentDidMount() {
        // this.setState({
        //     from: moment().startOf('month'),
        //     to: moment().endOf('month'),
        // }, () => {
        //     this.getTableData()
        // })
        this.getTableData()
        this.getselect();

    }
    //改变页面条数时的回调
    onShowSizeChange = (current, pageSize) => {

        this.setState({
            pageSize: pageSize
        }, () => {
            this.doSearch()
        })
    }
    //请求下拉框数据
    getselect = () => {
        getTotalTelemarketSelect()
            .then(res => {
                Select = []
                if (res.data.code === 200) {
                    for (let i = 0; i < res.data.data.resultDate.length; i++) {

                        if (i == 0) {
                            Select.push(<option value=''>please select one</option>)
                        }
                        Select.push(
                            <option value={res.data.data.resultDate[i].id}>{res.data.data.resultDate[i].loginName}</option>
                        )
                        this.setState({
                            Select: Select
                        })
                    }
                } else if (res.data.code == 800) {
                    this.props.history.push({ pathname: "/" });
                } else {
                }
            })
    }
    //搜索数据
    doSearch = () => {
        let params = {
            mobile: this.state.mobile,
            resultStatus: this.state.resultStatus,
            operatorId: this.state.operatorId,
            type: this.state.type,
            userStatus: this.state.userStatus,
            beginTime2:this.state.from1,
            endTime2:this.state.to1,
        }
        this.getTableData(params)
    }
    //重置搜索框
    clearSearch = () => {
        this.setState({
            // from: moment().startOf('month'),
            // to: moment().endOf('month'),
            from: '',
            to: '',
            from1: '',
            to1: '',
            mobile: '',
            resultStatus: '',
            operatorId: '',
            type: '',
            userStatus: ''
        }, () => {
            this.getTableData()
        })
    }
    //打开弹窗
    openModal = () => {
        this.setState({
            uploadvisible: true,
        })
    }
    //表单提交
    thdhandleOk = (e) => {
        let arr = this.state.selectedRowKeys;
        let lastarr = arr.join(',');

        const validator = new Schema(secondRule);
        validator.validate({ operatorName: this.state.operatorName }, (errors, fields) => {
            if (!errors) {
                this.setState({ //表单按钮禁用
                    scdafter: true
                })
                TotalTelemarketFormInput({
                    ids: lastarr,
                    operatorName: this.state.operatorName,
                })
                    .then(res => {
                        if (res.data.code == 200) {
                            this.setState({
                                boolean: true,
                                scdafter: false
                            })
                            this.doSearch()
                            message.success(res.data.msg);
                        } else if (res.data.code == 800) {
                            this.props.history.push({ pathname: "/" });
                        } else {
                            message.error(res.data.msg);
                            this.setState({
                                scdafter: false
                            })
                        }
                    })
                this.setState({
                    thdvisible: false,
                    selectedRowKeys: '',
                    operatorName: ''
                });
            } else {
                message.error(errors[0].message)
            }
        })
    }
    //手动分配弹窗
    redistribution = () => {
        if (this.state.selectedRowKeys.length < 1) {
            message.error('please select one');
            return false
        }
        this.doSearch();//刷新表格
        this.setState({
            thdvisible: true,
        });
    }

    //下载excel

    export = () => {
        // let starttime = moment(this.state.from).format("YYYY-MM-DD")
        // let endtime = moment(this.state.to).format("YYYY-MM-DD")
        // let params = {
        //     mobile: this.state.mobile,
        //     resultStatus: this.state.resultStatus,
        //     operatorId: this.state.operatorId,
        //     type: this.state.type,
        //     userStatus: this.state.userStatus
        // }
        let myObj = {
            method: 'post',
            url: `/api/promotion/downloadOrderExcel`,
            data: {
                // beginTime: starttime,
                // endTime: endtime,
                // ...params
            },
        }
        exportMethod(myObj)
    }
    //请求表格数据
    getTableData(params) {
        this.setState({
            boolean: true
        })
        let starttime = this.state.from
        let endtime = this.state.to
        getTotalTelemarketData({
            ...params,
            beginTime: starttime,
            endTime: endtime,
            pageNum: this.state.current,
            pageSize: this.state.pageSize,
        }).then(res => {
            if (res.data.code === 200) {
                this.setState({
                    boolean: false,
                    tableData: res.data.data.resultData,
                    length: res.data.data.page.total
                })
            } else if (res.data.code == 800) {
                this.props.history.push({ pathname: "/" });
            }
        })
    }
    //跳转到详情页

    todetail(record, item) {
        console.log(record, '记录')
        record['id'] = 0; //后端接口要求
        window.location = '/#/details/index?id=' + record.id + '&uid=' + record.uid
        let test = [{ path: '/details/index', key: `/details/index/${record.id}/${record.uid}`, number: `${record.id}/${record.uid}` }]
        EventEmitter.trigger('btn', test[0])
        EventEmitter.trigger('idanduid', test[0].number)
    }
    //表单备注提交
    firsthandleOk = () => {
        const validator = new Schema(firstRule);
        validator.validate({ resultStatus: this.state.resultStatus1, remark: this.state.remark }, (errors, fields) => {
            if (!errors) {
                this.setState({
                    btnloading: true
                })
                axios.post('/api/promotion/updateTelemarketingOrder', {
                    id: this.state.orderID,
                    resultStatus: this.state.resultStatus1,
                    remark: this.state.remark
                }).then(res => {
                    if (res.data.code === 200) {
                        this.setState({
                            firstVisible: false,
                            remark: '',
                            orderID: '',
                            resultStatus1: '',
                            btnloading: false
                        })
                        message.success(res.data.msg)
                        this.doSearch();//刷新表格
                    } else if (res.data.code == 800) {
                        this.props.history.push({ pathname: "/" });
                    } else {
                        message.error(res.data.msg);
                        this.setState({
                            btnloading: false
                        })
                    }
                })
            } else {
                message.error(errors[0].message)
            }
        })


    }
    //关闭表单
    handleCancel = e => {
        console.log(e);
        this.setState({
            thdvisible: false,
            firstVisible: false,
            remark: '',
            orderID: '',
            resultStatus: '',
            scdvisible: false,
            ScdSelect: '',
            btnloading: false,
            scdafter: false,
            selectedRowKeys: ''
        });
    };
    //表单值双向绑定
    fstaddonChange = (e) => {
        let InputName = e.target.name;
        let InputValue = e.target.value;
        this.setState({
            [InputName]: InputValue.replace(/\s+/g, ""),
        })
    }
    fstaddonChange1 = (e) => { //可以输入空格
        let InputName = e.target.name;
        let InputValue = e.target.value;
        this.setState({
            [InputName]: InputValue,
        })
    }
    pageChange = (page) => {
        this.setState({
            current: page
        }, () => {
            this.doSearch()
        })
    }
    //时间选择器范围
    rangeOnChange = (dates, dateStrings) => {
        console.log(dateStrings, '时间选择器')
        this.setState({ from: dateStrings[0], to: dateStrings[1] })
    }
    //时间选择器范围
    rangeOnChange1 = (dates, dateStrings) => {
        console.log(dateStrings, '时间选择器')
        this.setState({ from1: dateStrings[0], to1: dateStrings[1] })
    }
    //订单备注弹窗
    remarkOrder = (record) => {
        this.setState({
            orderID: record.id,
            firstVisible: true
        })

    }
    render() {
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowkeys, selectedRows) => {
                console.log(selectedRowkeys, selectedRows)
                // this.setState({
                //   selectedRowIds : selectedRowkeys.map(Number),
                //   selectedRowKeys
                // })
                this.setState({ selectedRowKeys: selectedRowkeys, });
            },
            columnWidth: 40

        };
        const columns = [
            {
                title: 'Phone',
                dataIndex: 'mobile',
                key: 'mobile',
                render: (value) => {
                    return value || '——'
                }

            },
            {
                title: 'First name',
                dataIndex: 'firstName',
                key: 'firstName',
                render: (value) => {
                    return value || '——'
                }
            },
            {
                title: 'Current status',
                dataIndex: 'userStatus',
                key: 'userStatus',
                render: (value) => {
                    switch (value) {
                        case 0:
                            return 'No application'
                            break;
                        case 1:
                            return 'Application'
                            break;
                        case 2:
                            return 'Pending card'
                            break;
                        case 3:
                            return 'Returned'
                            break;
                        default:
                            return '——'
                            break;
                    }
                }

            },
            {
                title: 'Loan counts',
                dataIndex: 'repaymentCount',
                key: 'repaymentCount',
                render: (value) => {
                    return value || '——'
                }

            },
            {
                title: 'Allocate time',
                dataIndex: 'createTime',
                key: 'createTime',
                render: (value) => {
                    return value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '——'
                }

            },
            {
                title: 'Telemarketer',
                dataIndex: 'operatorName',
                key: 'operatorName',
                render: (value) => {
                    return value || '——'
                }

            },
            {
                title: 'Last Telemarketer',
                dataIndex: 'secondOperatorName',
                key: 'secondOperatorName',
                render: (value) => {
                    return value || '——'
                }

            },
            {
                title: 'Last communicate result',
                dataIndex: 'resultStatus',
                key: 'resultStatus',
                render: (value) => {
                    switch (value) {
                        case 1:
                            return 'Customer has a loan intention '
                            break;
                        case 2:
                            return 'Customer has no loan intention'
                            break;
                        case 3:
                            return 'Phone is not connected'
                            break;
                        default:
                            return '——'
                            break;
                    }
                }

            },
            {
                title: 'Last Remark',
                dataIndex: 'remark',
                key: 'remark',
                render: (value) => {
                    return value || '——'
                }

            },
            {
                title: 'Last communicate time',
                dataIndex: 'updateTime',
                key: 'updateTime',
                render: (value) => {
                    return value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '——'
                }

            },
            {
                title: 'First communicate time',
                dataIndex: 'firstEffectiveTime',
                key: 'firstEffectiveTime',
                render: (value) => {
                    return value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '——'
                }

            },
            {
                title: 'Operate', width: '16.5%',
                render: (text, record) =>
                    <div>
                        <span>
                            <a className='edit_button' href="javascript:;" onClick={() => this.remarkOrder(record)}>Remark</a>
                        </span>
                        <span>
                            <a className='edit_button' href="javascript:;" onClick={() => this.todetail(record)}>Detail</a>
                        </span>
                    </div>

            },
        ];
        const { uploading, fileList } = this.state;
        const props = {
            onRemove: (file) => {
                this.setState((state) => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                    };
                });
            },
            beforeUpload: (file) => {
                this.setState(state => ({
                    fileList: [...state.fileList, file],
                    isdisabled: true
                }));
                return false;
            },
            fileList,
        };
        return (
            <div className="audit_content">
                <div className="commom_top" style={{ alignItems: 'center', marginBottom: 30 }}>
                    <label>Phone：</label>
                    <Search
                        placeholder="Phone"
                        onSearch={value => console.log(value)}
                        style={{ width: 290 }}
                        name="mobile"
                        value={this.state.mobile}
                        onChange={this.fstaddonChange}
                    />
                    <label>Allocate time:：</label>
                    <RangePicker
                        style={{ width: 290, }}
                        ranges={{
                            Today: [moment(), moment()],
                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                        }}
                        showTime
                        onChange={this.rangeOnChange}
                        format={dateFormat}
                        value={this.state.from === undefined || this.state.to === undefined || this.state.from === "" || this.state.to === "" ? null : [moment(this.state.from, dateFormat), moment(this.state.to, dateFormat)]}
                    />
                    <label>Communicate Result：</label>
                    <select className="menu_form_select_new" name="resultStatus" value={this.state.resultStatus} onChange={this.fstaddonChange}>
                        <option value=''>all</option>
                        <option value='1'>Customer has a loan intention</option>
                        <option value='2'>Customer has no loan intention</option>
                        <option value='3'>Phone is not connected</option>
                    </select>
                    <label>Telemarketer:</label>
                    <select className="menu_form_select_new" name="operatorId" value={this.state.operatorId} onChange={this.fstaddonChange}>
                        {Select}
                    </select>
                </div>
                <div className="commom_top" style={{ alignItems: 'center', marginBottom: 30 }}>
                    <label>NewOrOld：</label>
                    <select className="menu_form_select_new" name="type" value={this.state.type} onChange={this.fstaddonChange}>
                        <option value=''>all</option>
                        <option value='1'>Telemarketing-New</option>
                        <option value='2'>Telemarketing-Old</option>
                    </select>


                    <label>Current status:</label>
                    <select className="menu_form_select_new" name="userStatus" value={this.state.userStatus} onChange={this.fstaddonChange}>
                        <option value=''>all</option>
                        <option value='0'>No application</option>
                        <option value='3'>Returned</option>
                        <option value='2'>Pending card</option>
                    </select>
                    <label>Last communicate time:</label>
                    <RangePicker
                        style={{ width: 290, }}
                        ranges={{
                            Today: [moment(), moment()],
                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                        }}
                        showTime
                        onChange={this.rangeOnChange1}
                        format={dateFormat}
                        value={this.state.from1 === undefined || this.state.to1 === undefined || this.state.from1 === "" || this.state.to1 === "" ? null : [moment(this.state.from1, dateFormat), moment(this.state.to1, dateFormat)]}
                    />
                    <div className="upLoadBtn">
                        <Button type="primary" style={{
                            width: 170,
                            marginLeft: '4%',
                            height: 40,
                        }}
                            className="common_color"
                            onClick={this.doSearch}
                        >Search</Button>
                        <Button type="primary" style={{
                            width: 170,
                            marginLeft: '4%',
                            height: 40,
                            border: 'none'
                        }}
                            className="common_color_scd"
                            onClick={this.clearSearch}
                        >Refresh</Button>
                    </div>

                </div>
                <div className="upLoadBtn">
                    <Button type="primary" style={{
                        width: 170,
                        height: 40,
                        border: 'none',
                    }}
                        className="common_color"
                        onClick={this.redistribution}
                    >Batch re-allocate</Button>
                    {/* <Button type="primary" style={{
                        width: 170,
                        height: 40,
                        marginLeft: 20,
                        border: 'none'
                    }}
                        className="common_color_scd"
                        onClick={this.export}
                    >Download</Button> */}
                </div>
                <div className="table">
                    <Table columns={columns} dataSource={this.state.tableData} loading={this.state.boolean}
                        rowKey={record => record.id}
                        rowSelection={rowSelection}
                        pagination={false} />
                </div>

                <Pagination
                    // showQuickJumper
                    onShowSizeChange={this.onShowSizeChange}//pagesize改变时回调函数
                    pageSizeOptions={["10", "50", "100"]}   //设置pagesize区间
                    defaultCurrent={1}
                    current={this.state.current}
                    total={this.state.length}
                    onChange={this.pageChange}
                    showTotal={showTotal}
                    style={{ textAlign: 'right', marginTop: 20 }}
                    showSizeChanger={true}
                    pageSize={this.state.pageSize}
                />
                {/* 订单备注弹窗 */}
                <Modal
                    // destroyOnClose={true}
                    title="Telemarketing remarks"
                    visible={this.state.firstVisible}
                    onOk={this.firsthandleOk}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            cancel
            </Button>,
                        <Button key="submit" type="primary" loading={this.state.btnloading} onClick={this.firsthandleOk}>
                            ok
            </Button>,
                    ]}
                >

                    <div className={'formItem'}>
                        <label>Communicate result:</label>
                        <select className="menu_form_select_new" name="resultStatus1" value={this.state.resultStatus1} onChange={this.fstaddonChange}>
                            <option value=''>all</option>
                            <option value='1'>Customer has a loan intention</option>
                            <option value='2'>Customer has no loan intention</option>
                            <option value='3'>Phone is not connected</option>
                        </select>
                    </div>
                    <div className={'formItem'}>
                        <label>Remark:</label>
                        <TextArea rows={3} value={this.state.remark} name="remark" onChange={this.fstaddonChange1} maxLength={120} />
                    </div>
                </Modal>
                {/* //手动分配弹窗 */}
                <Modal
                    title="Batch re-allocate"
                    visible={this.state.thdvisible}
                    onOk={this.thdhandleOk}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            cancel
                        </Button>,
                        <Button key="submit" type="primary" loading={this.state.scdafter} onClick={this.thdhandleOk}>
                            ok
            </Button>,
                    ]}
                >
                    <span style={{ fontSize: 15, fontWeight: 'bold', marginRight: 5 }}>Telemarketer:</span>
                    <input type="text" placeholder='all' value={this.state.operatorName || ''} name="operatorName" onChange={this.fstaddonChange} className="form_input"
                        style={{ width: 290, marginLeft: 0 }} maxLength={20} />
                </Modal>
            </div>
        )
    }
}
export default withRouter(SmsManage)