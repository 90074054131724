/*eslint-disable*/
import React from "react";
import {
  Input,
  Button,
  Table,
  Modal,
  Pagination,
  DatePicker,
  Tooltip,
  message,
} from "antd";
import "./index.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import EventEmitter from "../../utils/events";

import { Link } from "react-router-dom";
import moment from "moment";
const { RangePicker } = DatePicker;
const { Search } = Input;
const dateFormat = "YYYY-MM-DD" || undefined;
var whitchshow = [];

function showTotal(total) {
  return `Total ${total} items`;
}

class NewRepaymentPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fstSelect: "",
      scdSelect: "",
      testSelect: "",
      sixSelect: 1,
      fouSelect: "",
      searchVal: "",
      mobileNum: "",
      current: 1,
      modalscdinput: "",
      modalthdinput: "",
      datepiker: "",
      fstinpt: "",
      disval: "",
      order_statues: "please select one",
      fsttextarea: "",
      pageSize: 10,
      boolean: false,
      isShow: false,
      reviewreason: "",
      fstInput1: "",
    };
  }
  onShowSizeChange = (current, pageSize) => {
    this.setState(
      {
        pageSize: pageSize,
      },
      () => {
        this.searchClick();
      }
    );
  };
  //页码改变时触发
  pageChange = (page) => {
    this.setState(
      {
        current: page,
      },
      () => {
        this.searchClick();
      }
    );
  };
  rangeOnChange = (dates, dateStrings) => {
    this.setState({ from: dateStrings[0], to: dateStrings[1] });
  };

  dateonChange = (date, dateString) => {
    this.setState({ datepiker: dateString });
  };

  handleCancel = () => {
    this.setState({
      fstvisible: false,
      scdvisible: false,
      disval: "",
      datepiker: "",
      modalinput: "",
      modalscdinput: "",
      modalthdinput: "",
      fsttextarea: "",
      btnloading: false,
    });
  };
  //重置按钮
  reloadClick(e) {
    this.setState(
      {
        fstSelect: "",
        scdSelect: "",
        testSelect: "",
        fouSelect: "",
        searchVal: "",
        mobileNum: "",
        from: undefined,
        to: undefined,
        boolean: false,
        sixSelect: 1,
        fstInput1: "",
        fstInput2: "",
        fstInput3: "",
      },
      () => {
        this.searchClick();
      }
    );
  }

  onload = () => {
    // this.searchClick()
  };
  componentDidMount() {
    this.onload();
    console.log(localStorage.roleId);
    if (
      localStorage.roleId == 1 ||
      localStorage.roleId == 5 ||
      localStorage.roleId == 9 ||
      localStorage.roleId == 14 ||
      localStorage.roleId == 17 ||
      localStorage.roleId == 19
    ) {
      this.setState({
        isShow: true,
      });
    }
  }
  fstInput1Change = (e) => {
    var value = e.target.value;
    this.setState({
      fstInput1: value,
    });
  };
  fstInput2Change = (e) => {
    var value = e.target.value;
    this.setState({
      fstInput2: value,
    });
  };
  fstInput3Change = (e) => {
    var value = e.target.value;
    this.setState({
      fstInput3: value,
    });
  };
  text(record) {
    window.location = "/#/details/index?id=" + record.id + "&uid=" + record.uid;
    let test = [
      {
        path: "/details/index",
        key: `/details/index/${record.id}/${record.uid}`,
        number: `${record.id}/${record.uid}`,
      },
    ];
    EventEmitter.trigger("btn", test[0]);
    EventEmitter.trigger("idanduid", test[0].number);
  }
  //输入框
  searchIpt(e) {
    var value = e.target.value;
    this.setState({ searchVal: value.replace(/\s+/g, "") });
  }
  //号码输入框
  searchIpt1(e) {
    var value = e.target.value;
    this.setState({ mobileNum: value.replace(/\s+/g, "") });
  }
  //选择订单状态
  selectFst(e) {
    var value = e.target.value;
    this.setState({
      fstSelect: value,
    });
  }
  selectTest(e) {
    var value = e.target.value;
    this.setState({
      testSelect: value,
    });
  }
  selectsix = (e) => {
    var value = e.target.value;
    this.setState({
      sixSelect: value,
    });
  };

  //选择apptype
  selectFou(e) {
    var value = e.target.value;
    this.setState({
      fouSelect: value,
    });
  }

  handleData(time) {
    if (!time) {
      return false;
    } else {
      // 大于当前日期不能选 time > moment()
      // 小于当前日期不能选 time < moment().subtract(1, "days")
      // 只能选前7后7 time < moment().subtract(7, "days") || time > moment().add(7, 'd')
      return time > moment();
    }
  }

  todetail = (record) => {
    this.props.history.push({
      pathname: "../../details/index",
      state: { record },
    });
  };

  searchClick = () => {
    this.setState({
      boolean: true,
    });
    axios
      .post(`/api/loanFront/getLoaningList`, {
        pageSize: this.state.pageSize,
        current: this.state.current,
        indentNo: this.state.searchVal,
        mobile: this.state.mobileNum,
        schParam: this.state.searchVal,
        status: this.state.fstSelect,
        timeType: this.state.sixSelect,
        tag: this.state.testSelect,
        appCode: this.state.fouSelect,
        beginTime: this.state.from,
        endTime: this.state.to,
        firstName: this.state.fstInput1,
        middleName: this.state.fstInput2,
        lastName: this.state.fstInput3,
      })
      .then((res) => {
        if (res.data.code === 200) {
          const data1 = [];
          const data = res.data.data.resultDate;
          if (data.length < 1) {
            this.setState({
              boolean: false,
            });
          } else {
            for (let i = 0; i < data.length; i++) {
              data[i].key = i;
              data1.push(data[i]);
            }
          }
          this.setState({
            tableData: data1,
            total: res.data.data.page.total,
            boolean: false,
          });
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
          // message.error(res.data.msg);
        }
      });
  };
  ModalInput = (e) => {
    let value = e.target.value;
    this.setState({
      modalinput: value.replace(/\s+/g, ""),
    });
  };
  ModalScdInput = (e) => {
    let value = e.target.value;
    this.setState({
      modalscdinput: value.replace(/\s+/g, ""),
    });
  };
  ModalThdInput = (e) => {
    let value = e.target.value;
    this.setState({
      modalthdinput: value.replace(/\s+/g, ""),
    });
  };
  fsttxarea = (e) => {
    let value = e.target.value;
    this.setState({
      fsttextarea: value,
    });
  };
  //offine按钮
  offline = (record) => {
    this.setState({
      fstvisible: true,
      disval: record.id,
      modalinput: record.indentNo,
    });
  };
  search = () => {
    axios
      .post(`/api/loanFront/findBankFlow`, {
        indentNo: this.state.modalscdinput,
      })
      .then((res) => {
        if (res.data.code === 200) {
          // let timeaudit= new Date(res.data.data.effectiveDate).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
          let auditimes = moment(new Date(res.data.data.effectiveDate)).format(
            "YYYY-MM-DD"
          );
          message.success(res.data.msg);
          this.setState({
            modalthdinput: res.data.data.credit / 100,
            datepiker: auditimes,
            fsttextarea: res.data.data.description,
          });
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
          message.error(res.data.msg);
          this.setState({
            modalscdinput: "",
            modalthdinput: "",
            datepiker: "",
            fsttextarea: "",
          });
        }
      });
  };
  handleOk = () => {
    if (this.state.modalscdinput == "") {
      message.error("The Check No can't  be empty.");
      return;
    } else if (this.state.modalthdinput == "") {
      message.error("The repayment amount can't  be empty.");
      return;
    } else if (this.state.datepiker == "") {
      message.error("The repayment time can't  be empty.");
      return;
    } else if (this.state.fsttextarea == "") {
      message.error("The remark can't  be empty.");
      return;
    }
    this.setState({
      btnloading: true,
    });
    axios
      .post(`/api/loanFront/offlineInstallmentNew`, {
        loanId: this.state.disval,
        repayTime: this.state.datepiker,
        repayNo: this.state.modalinput,
        repayFlowNo: this.state.modalscdinput,
        repayFee: this.state.modalthdinput,
        remark: this.state.fsttextarea,
      })
      .then((res) => {
        if (res.data.code === 200) {
          message.success(res.data.msg);
          this.setState({
            fstvisible: false,
            datepiker: "",
            modalinput: "",
            modalscdinput: "",
            modalthdinput: "",
            fsttextarea: "",
            btnloading: false,
          });
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
          message.error(res.data.msg);
          this.setState({
            fstvisible: false,
            datepiker: "",
            modalinput: "",
            modalscdinput: "",
            modalthdinput: "",
            fsttextarea: "",
            btnloading: false,
          });
        }
      });
    this.searchClick();
  };
  render() {
    const columns = [
      {
        title: "app type",
        dataIndex: "appCode",
        key: "appCode",
      },
      {
        title: "Order No",
        dataIndex: "indentNo",
        key: "indentNo",
      },
      // {
      //   title: 'Customer type',
      //   dataIndex: 'tag',
      //   key: 'tag',

      // },
      {
        title: "Customer name",
        dataIndex: "firstName",
        key: "firstName",
      },
      {
        title: "mobile phone",
        dataIndex: "mobile",
        key: "mobile",
      },
      // {
      //   title: 'BVN',
      //   dataIndex: 'dentityCard',
      //   key: 'dentityCard',

      // },
      {
        title: "loan amount",
        dataIndex: "balance",
        key: "balance",
        render: (value) => {
          var num = value / 100;
          num = num + "";
          var str = "";
          for (var i = num.length - 1, j = 1; i >= 0; i--, j++) {
            if (j % 3 == 0 && i != 0) {
              //每隔三位加逗号，过滤正好在第一个数字的情况
              str += num[i] + ","; //加千分位逗号
              continue;
            }
            str += num[i]; //倒着累加数字
          }
          return str.split("").reverse().join("");
        },
      },
      // {
      //   title: 'loan term',
      //   dataIndex: 'cycle',
      //   key: 'cycle',

      // },
      // {
      //   title: 'Success Repayment Count',
      //   dataIndex: 'repaymentCount',
      //   key: 'repaymentCount',
      // },
      {
        title: "Loan status",
        dataIndex: "statusDescription",
        key: "statusDescription",
      },
      // {
      //   title: 'repayment amount',
      //   dataIndex: 'actualbackAmt',
      //   key: 'actualbackAmt',
      //   render: (value)=> {
      //     var num = value/100;
      //     num = num + '';
      //     if(num.indexOf(".")!=-1){
      //       return num;
      //       }else{
      //         var str = "";
      //         for(var i=num.length- 1,j=1;i>=0;i--,j++){
      //             if(j%3==0 && i!=0){//每隔三位加逗号，过滤正好在第一个数字的情况
      //                 str+=num[i]+",";//加千分位逗号
      //                 continue;
      //             }
      //             str+=num[i];//倒着累加数字
      //         }
      //         return str.split('').reverse().join("");
      //       }
      //    }
      // },
      // {
      //   title: 'already_back_amt',
      //   dataIndex: 'actualbackAmt',
      //   key: 'actualbackAmt',
      //   render: (value)=> {
      //     var num = value/100;
      //     num = num + '';
      //     var str = "";
      //     for(var i=num.length- 1,j=1;i>=0;i--,j++){
      //         if(j%3==0 && i!=0){//每隔三位加逗号，过滤正好在第一个数字的情况
      //             str+=num[i]+",";//加千分位逗号
      //             continue;
      //         }
      //         str+=num[i];//倒着累加数字
      //     }
      //     return str.split('').reverse().join("");
      // }
      // },
      // {
      //   title: 'actual back time',
      //   dataIndex: 'lastbackTime',
      //   key: 'lastbackTime',
      //   render: (value) => {
      //     if(value==null){
      //       return ''
      //     }else{
      //       let timeaudit= new Date(value).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
      //       let auditimes=moment(new Date(timeaudit)).format("YYYY-MM-DD HH:mm:ss");
      //       return auditimes
      //     }

      //   }
      // },
      {
        title: "due amount",
        dataIndex: "shouldbackFee",
        key: "shouldbackFee",
        render: (value) => {
          var num = value / 100;
          num = num + "";
          if (num.indexOf(".") != -1) {
            return num;
          } else {
            var str = "";
            for (var i = num.length - 1, j = 1; i >= 0; i--, j++) {
              if (j % 3 == 0 && i != 0) {
                //每隔三位加逗号，过滤正好在第一个数字的情况
                str += num[i] + ","; //加千分位逗号
                continue;
              }
              str += num[i]; //倒着累加数字
            }
            return str.split("").reverse().join("");
          }
        },
      },
      {
        title: "remainng balance",
        dataIndex: "alsoNeedBack",
        key: "alsoNeedBack",
        render: (value, record) => {
          var num =
            (record.shouldbackFee -
              record.actualbackAmt -
              record.reduceAmt -
              record.couponsFee) /
            100;
          num = num + "";
          return num;
        },
      },
      // {
      //   title: 'reduce amount',
      //   dataIndex: 'reduceAmt',
      //   key: 'reduceAmt',
      //   render: (value)=> {
      //     var num = value/100;
      //     num = num + '';
      //     if(num.indexOf(".")!=-1){
      //       return num;
      //       }else{
      //         var str = "";
      //         for(var i=num.length- 1,j=1;i>=0;i--,j++){
      //             if(j%3==0 && i!=0){//每隔三位加逗号，过滤正好在第一个数字的情况
      //                 str+=num[i]+",";//加千分位逗号
      //                 continue;
      //             }
      //             str+=num[i];//倒着累加数字
      //         }
      //         return str.split('').reverse().join("");
      //       }
      //  }
      // },
      // {
      //   title: 'coupon amount',
      //   dataIndex: 'couponsFee',
      //   key: 'couponsFee',
      //   render: (value)=> {
      //     var num = value/100;
      //     num = num + '';
      //     if(num.indexOf(".")!=-1){
      //       return num;
      //       }else{
      //         var str = "";
      //         for(var i=num.length- 1,j=1;i>=0;i--,j++){
      //             if(j%3==0 && i!=0){//每隔三位加逗号，过滤正好在第一个数字的情况
      //                 str+=num[i]+",";//加千分位逗号
      //                 continue;
      //             }
      //             str+=num[i];//倒着累加数字
      //         }
      //         return str.split('').reverse().join("");
      //       }
      //   }
      // },
      // {
      //   title: 'actual amount received',
      //   dataIndex: 'actualBalance',
      //   key: 'actualBalance',
      //   render: (value)=> {
      //     var num = value/100;
      //     num = num + '';
      //     var str = "";
      //     for(var i=num.length- 1,j=1;i>=0;i--,j++){
      //         if(j%3==0 && i!=0){//每隔三位加逗号，过滤正好在第一个数字的情况
      //             str+=num[i]+",";//加千分位逗号
      //             continue;
      //         }
      //         str+=num[i];//倒着累加数字
      //     }
      //     return str.split('').reverse().join("");
      // }
      // },
      // {
      //   title: 'arrival time',
      //   dataIndex: 'arriveTime',
      //   key: 'arriveTime',
      //   render: (value) => {
      //     let timeaudit= new Date(value).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
      //             let auditimes=moment(new Date(timeaudit)).format("YYYY-MM-DD HH:mm:ss");
      //             return auditimes
      //   }
      // },
      {
        title: "should repayment date",
        dataIndex: "shouldbackTime",
        key: "shouldbackTime",
        render: (value) => {
          let timeaudit =
            new Date(value).getTime() +
            new Date().getTimezoneOffset() * 60 * 1000 -
            0 * 60 * 60 * 1000;
          let auditimes = moment(new Date(timeaudit)).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          return auditimes;
        },
      },

      // {
      //   title: 'coupon ID',
      //   dataIndex: 'couponIndentNo',
      //   key: 'couponIndentNo',

      // },
      // {
      //   title: 'coupon name',
      //   dataIndex: 'couponName',
      //   key: 'couponName',
      // },

      {
        title: "Operation",
        dataIndex: "operating",
        key: "operating",
        width: 70,
        id: "id",
        uid: "uid",
        fixed: "right",
        render: (text, record, index) => (
          <span style={{ display: "flex", flexDirection: "row" }}>
            {this.state.isShow && (
              <Button
                type="primary"
                style={{
                  display:
                    record.statusDescription == "Normal Repayment" ||
                    record.statusDescription == "Early Repayment" ||
                    record.statusDescription == "Overdue Repayment"
                      ? "none"
                      : "block",
                }}
                className="detailsbutton common_color"
                size="small"
                onClick={() => this.offline(record)}
              >
                offline
              </Button>
            )}
            <Button
              type="primary"
              className="detailsbutton common_color"
              size="small"
              onClick={() => this.text(record)}
            >
              details
            </Button>
          </span>
        ),
      },
    ];
    return (
      <div className="credit_order">
        <div className="myorder_content">
          <div className="flutter">
            <div className="top">
              <div className="top_left" style={{ width: "30%", flexShrink: 1 }}>
                <div className="search">
                  <span className="description left">Order No:</span>
                  <Search
                    placeholder="Order No"
                    onSearch={(value) => console.log(value)}
                    style={{ width: 290 }}
                    value={this.state.searchVal}
                    onChange={this.searchIpt.bind(this)}
                  />
                </div>
              </div>

              <div
                className="top_right"
                style={{
                  width: "30%",
                  flexShrink: 2,
                  marginLeft: "5%",
                  marginRight: "5%",
                }}
              >
                {/* <span className="description mid_span" >Time type:</span>
                  <select className="form_select" style={{marginRight:0,width:292}} value={this.state.sixSelect} onChange={this.selectsix}>
                    <option value="1">should back time</option>
                    <option value="2" >last repay time</option>
                    <option value="3" >payment time</option>
                    <option value="4" >created time</option>
                  </select> */}
                <span className="description left">Phone:</span>
                <Search
                  placeholder="Phone"
                  onSearch={(value) => console.log(value)}
                  style={{ width: 290 }}
                  value={this.state.mobileNum}
                  onChange={this.searchIpt1.bind(this)}
                />
              </div>

              <div className="top_mid" style={{ width: "30%", flexShrink: 1 }}>
                <div className="rangepick">
                  {/* <span className="description right">Selected time:</span>
                    <RangePicker
                       style={{width: 290,}}
                      ranges={{
                      Today: [moment(), moment()],
                      'This Month': [moment().startOf('month'), moment().endOf('month')],
                      }}
                      showTime
                      format={dateFormat}
                      onChange={this.rangeOnChange}
                      value={this.state.from===undefined||this.state.to===undefined||this.state.from===""||this.state.to===""?null:[moment(this.state.from, dateFormat), moment(this.state.to, dateFormat)]}
                    /> */}
                  <span className="description right">Loan status:</span>
                  <select
                    className="form_select"
                    value={this.state.fstSelect}
                    onChange={this.selectFst.bind(this)}
                  >
                    <option value="">all</option>
                    {/* <option value="4">In Loan</option> */}
                    <option value="5">In Loan</option>
                    {/* <option value="6">Normal Repayment</option> */}
                    {/* <option value="8">Early Repayment</option> */}
                    <option value="21">Overdue Status</option>
                    {/* <option value="22">Overdue Repayment</option> */}
                    <option value="51">Bad Debt</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="order_mid">
              <div className="mid_left" style={{ width: "30%" }}>
                {/* <span className="description left">Phone:</span>
                  <Search
                    placeholder="Phone"
                    onSearch={value => console.log(value)}
                    style={{width: 290}}
                    value={this.state.mobileNum}
                    onChange={this.searchIpt1.bind(this)}
                  /> */}
                <span className="description left" style={{ width: 120 }}>
                  First Name:
                </span>
                <input
                  type="text"
                  placeholder="first name"
                  value={this.state.fstInput1}
                  onChange={this.fstInput1Change}
                  className="modal_input"
                  style={{ width: 290, marginLeft: 0 }}
                />
              </div>

              <div className="mid_right" style={{ width: "30%" }}>
                <span className="description mid_span">Middle Name:</span>
                <input
                  type="text"
                  placeholder="Middle Name"
                  value={this.state.fstInput2}
                  onChange={this.fstInput2Change}
                  className="modal_input"
                  style={{ width: 290, marginLeft: 0 }}
                />
              </div>

              <div className="mid_left" style={{ width: "30%" }}>
                <span className="description right">Last Name:</span>
                <input
                  type="text"
                  placeholder="Last Name"
                  value={this.state.fstInput3}
                  onChange={this.fstInput3Change}
                  className="modal_input"
                  style={{ width: 290, marginLeft: 0 }}
                />
              </div>
            </div>
            <div className="order_mid">
              <div className="top_mid" style={{ width: "30%", flexShrink: 2 }}>
                <div className="rangepick">
                  <span className="description right">
                    should repayment time:
                  </span>
                  <RangePicker
                    style={{ width: 290 }}
                    ranges={{
                      Today: [moment(), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                    }}
                    showTime
                    format={dateFormat}
                    onChange={this.rangeOnChange}
                    value={
                      this.state.from === undefined ||
                      this.state.to === undefined ||
                      this.state.from === "" ||
                      this.state.to === ""
                        ? null
                        : [
                            moment(this.state.from, dateFormat),
                            moment(this.state.to, dateFormat),
                          ]
                    }
                  />
                </div>
              </div>

              <div className="mid_right" style={{ width: "30%" }}></div>
              <div className="mid_left" style={{ width: "30%" }}>
                <Button
                  type="primary"
                  style={{
                    width: 170,
                    marginRight: 20,
                    height: 40,
                    border: "none",
                  }}
                  className="common_color"
                  onClick={this.searchClick.bind(this)}
                >
                  Search
                </Button>
                <Button
                  type="primary"
                  style={{
                    width: 170,
                    height: 40,
                    border: "none",
                  }}
                  className="common_color_scd"
                  onClick={this.reloadClick.bind(this)}
                >
                  Refresh
                </Button>
              </div>
            </div>
            <div className="bottom" style={{ justifyContent: "space-between" }}>
              <div
                className="top_mid"
                style={{ width: "30%", flexShrink: 1 }}
              ></div>
              <div
                className="mid_right"
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "30%",
                  justifyContent: "center",
                }}
              ></div>
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={this.state.tableData}
          scroll={{ x: 1500 }}
          loading={this.state.boolean}
          onChange={this.handleChange}
          pagination={false}
        />

        <Modal
          title="Offline stage"
          visible={this.state.fstvisible}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={this.state.btnloading}
              onClick={this.handleOk}
            >
              Confirm
            </Button>,
          ]}
        >
          <input value={this.state.disval} style={{ display: "none" }} />
          <div className="model_last">
            <span className="long">order number:</span>
            <input
              className="modal_input"
              style={{ marginLeft: "10%", width: 290 }}
              disabled
              type="text"
              value={this.state.modalinput}
              onChange={this.ModalInput}
            />
          </div>

          <div className="model_last">
            <span className="long" style={{ width: 140 }}>
              Check No:
            </span>
            <Search
              placeholder="Check No"
              onSearch={this.search}
              enterButton="Search"
              size="middle"
              style={{ width: 290, marginLeft: "1.6%" }}
              value={this.state.modalscdinput}
              onChange={this.ModalScdInput.bind(this)}
            />
          </div>
          <div className="model_last">
            <span className="long">repayment amount:</span>
            <input
              className="modal_input"
              style={{ marginLeft: "2.5%", width: 290 }}
              disabled
              value={this.state.modalthdinput}
              onChange={this.ModalThdInput}
            />
          </div>
          <div className="model_last">
            <span className="long">repayment time:</span>
            <DatePicker
              style={{ marginLeft: "7%", width: 290 }}
              disabledDate={this.handleData}
              disabled
              value={
                this.state.datepiker === undefined ||
                this.state.datepiker === ""
                  ? null
                  : moment(this.state.datepiker, dateFormat)
              }
              onChange={this.dateonChange}
            />
          </div>
          <div className="model_last">
            <span className="long">remark:</span>
            <textarea
              className="modal_textarea_new"
              value={this.state.fsttextarea}
              onChange={this.fsttxarea}
            />
          </div>
        </Modal>
        <Pagination
          showQuickJumper
          defaultCurrent={1}
          current={this.state.current}
          total={this.state.total}
          onChange={this.pageChange}
          showTotal={showTotal}
          style={{ textAlign: "right", marginTop: 20 }}
          pageSize={this.state.pageSize}
          showSizeChanger={true}
          onShowSizeChange={this.onShowSizeChange} //pagesize改变时回调函数
          pageSizeOptions={["10", "50", "100"]} //设置pagesize区间
        />
      </div>
    );
  }
}
export default withRouter(NewRepaymentPlan);
