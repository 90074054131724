/*eslint-disable*/
import React from "react";
import {
  Input,
  Button,
  Table,
  Modal,
  Pagination,
  Divider,
  message,
} from "antd";
import "./index.css";
import axios from "axios";
import moment from "moment";

// import RangePick from '../../../utils/RangePicker';
const { Search } = Input;
var whitchshow = [];

function showTotal(total) {
  return `Total ${total} items`;
}

export default class Tab1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order_no: "",
      Status: "",
      time: "",
      appCode: "",
      loan_amount: "",
      interest: "",
      loan_term: "",
      Due_date: "",
      Due_amount: "",
      Overdue_day: "",
      Penalty: "",
      Total: "",
      Overdue: "",
      Repayment: "",
      Remaining: "",
      Disbursement: "",
      reson: "",
      tableData: [],
      count: 0,
      modelvisible: false,
      modalfstSelect: 0,
      modalscdSelect: "",
      fstinpt: "",
      fsttextarea: "",
      order_statues: "please select one",
      current: 1,
      total: 1,
      inptdis: {
        display: "none",
      },
      pageSize: 10,
      boolean: true,
      reviewreason: "",
      isshow: false,
      modify: false,
      //person info
      name: "",
      idnum: "",
      email: "",
      phone: "",
      register: "",
      gender: "",
      birthday: "",
      age: "",
      status: "",
      relation: "",
      family: "",
      family_phone: "",
      other_relation: "",
      other_name: "",
      other_phone: "",
      bvn_no: "",
      bvn_number: "",
      area: "",
      education: "",
      office_tel: "",
      employer: "",
      start_time: "",
      company_address: "",
      income: "",
      bankname: "",
      bankAccNo: "",
      bankCardNo: "",
      expirydata: "",
      authCode: "",
      default: "",
      bamkEmail: "",
      credittime: "",
      AuditRecordvisible: false,
      addressBtnLoading:false,
      bookBoolean:false,
      phoneCurrent: 1,
      BankName:'',
      AccountNumber:'',
      btnWord:'Send Msg',
      isshowSend:false,
      extensionModify:false,
      isExtension: false,
      extensionvisible:false,
      emount:"",
    };
  }
  //选择table触发
  handleChange = (pagination, filters, sorter) => {
    // console.log('Various parameters', pagination, filters, sorter);
    this.setState({});
  };

  //页码改变时触发
  pageChange = (page) => {
    this.setState(
      {
        current: page,
      },
      () => {
        this.auditRecordTable();
      }
    );
  };
  //点击manual按钮
  display = () => {};

  showModel = () => {
    this.setState({
      fstvisible: true,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      fstvisible: false,
      modify: false,
      extensionModify:false,
      extensionvisible:false,
      fsttextarea: "",
      modalfstSelect: 0,
      fstSelect: 0,
      order_statues: "please select one",
      inptdis: {
        display: "none",
      },
    });
  };



  showExtensionModel = () => {
    this.setState({
      extensionvisible: true,
    });
  };


  //Audit Record 弹窗
  showAuditRecord = () => {
    this.auditRecordTable();
    this.setState({
      AuditRecordvisible: true,
    });
  };

  handleCancelAudit = () => {
    this.setState({
      AuditRecordvisible: false,
    });
  };
  //modal第二个select
  modelselectTwo = (e) => {
    var value = e.target.value;
    this.setState({
      modalscdSelect: value,
    });
  };
  //页面初始化
  onload = () => {
    let id = this.props.id;
    let uid = this.props.uid;
    axios
      .post(`/api/loan/credit/getLoanOrderInformation`, {
        id: id,
        uid: uid,
      })

      .then((res) => {
        if (res.data.code === 200) {
          if (JSON.stringify(res.data.data) == "{}") {
            return false;
          } else {
            let data = res.data.data.record;
            let time = data.shouldbackTime;
            //new Date().getTimezoneOffset() 获取当前时间时间戳   （0 * 60 * 60 * 1000） 0 即为时区
            let east0time =
              new Date(time).getTime() +
              new Date().getTimezoneOffset() * 60 * 1000 -
              0 * 60 * 60 * 1000;
            let times = moment(new Date(east0time)).format(
              "YYYY-MM-DD HH:mm:ss"
            );

            let time1 = data.lastbackTime;
            //new Date().getTimezoneOffset() 获取当前时间时间戳   （0 * 60 * 60 * 1000） 0 即为时区
            let east0time1 =
              new Date(time1).getTime() +
              new Date().getTimezoneOffset() * 60 * 1000 -
              0 * 60 * 60 * 1000;
            let times1 = moment(new Date(east0time1)).format(
              "YYYY-MM-DD HH:mm:ss"
            );
            let date2 = new Date(
              moment(new Date()).format("YYYY-MM-DD").replace(/-/g, "/")
            );
            if (data.status == 6 || data.status == 22 || data.status == 8) {
              date2 = new Date(
                moment(new Date(times1)).format("YYYY-MM-DD").replace(/-/g, "/")
              );
            } else {
              date2 = new Date(
                moment(new Date()).format("YYYY-MM-DD").replace(/-/g, "/")
              );
            }

            let date1 = new Date(
              moment(new Date(times)).format("YYYY-MM-DD").replace(/-/g, "/")
            );

            var date3 = date2.getTime() - date1.getTime();
            let days = Math.floor(date3 / (24 * 3600 * 1000));
            if (!data) {
              this.setState({
                appCode: res.data.data.record.appCode,
                count: res.data.data.user.repaymentCount,
              });
            } else {
              let newdata = res.data.data;
              let whitchstatus = data.status;
              console.log("aaaaaaa", data);
              if (whitchstatus == 1) {
                this.setState({
                  Status: "Pending Risk Review",
                });
              } else if (whitchstatus == 2) {
                this.setState({
                  Status: "Pending Manual Review",
                });
              } else if (whitchstatus == 3) {
                this.setState({
                  Status: "Manual Approved",
                });
              } else if (whitchstatus == 4) {
                this.setState({
                  Status: "Paying",
                });
              } else if (whitchstatus == 5) {
                this.setState({
                  Status: "In Loan",
                });
              } else if (whitchstatus == 6) {
                this.setState({
                  Status: "Normal Repayment",
                });
              } else if (whitchstatus == 8) {
                this.setState({
                  Status: "Early Repayment",
                });
              } else if (whitchstatus == 11) {
                this.setState({
                  Status: "Repaying",
                });
              } else if (whitchstatus == 21) {
                this.setState({
                  Status: "Overdue Status",
                });
              } else if (whitchstatus == 22) {
                this.setState({
                  Status: "Overdue Repayment",
                });
              } else if (whitchstatus == 31) {
                this.setState({
                  Status: "Reject by Risk Rules",
                });
              } else if (whitchstatus == 32) {
                this.setState({
                  Status: "Reject by Manual Review",
                });
              } else if (whitchstatus == 33) {
                this.setState({
                  Status: "Return by Manual Review",
                });
              } else if (whitchstatus == 41) {
                this.setState({
                  Status: "Failed Payment",
                });
              } else if (whitchstatus == 42) {
                this.setState({
                  Status: "Reject payment",
                });
              } else if (whitchstatus == 51) {
                this.setState({
                  Status: "Bad Debt",
                });
              }
              if (
                whitchstatus == 21 ||
                whitchstatus == 51 ||
                whitchstatus == 22
              ) {
                this.setState({
                  Overdue_day: days,
                });
              } else {
                this.setState({
                  Overdue_day: 0,
                });
              }

              if (data.loanReason == 1) {
                this.setState({
                  reson: "Education",
                });
              } else if (data.loanReason == 2) {
                this.setState({
                  reson: "Medical Emergency",
                });
              } else if (data.loanReason == 3) {
                this.setState({
                  reson: "Rent",
                });
              } else if (data.loanReason == 4) {
                this.setState({
                  reson: "Travel",
                });
              } else if (data.loanReason == 5) {
                this.setState({
                  reson: "Business",
                });
              } else if (data.loanReason == 6) {
                this.setState({
                  reson: "Goods",
                });
              } else if (data.loanReason == 7) {
                this.setState({
                  reson: "Events",
                });
              } else if (data.loanReason == 8) {
                this.setState({
                  reson: "Household",
                });
              } else if (data.loanReason == 9) {
                this.setState({
                  reson: "Debt Consolidation",
                });
              } else if (data.loanReason == 10) {
                this.setState({
                  reson: "Personal Consumption",
                });
              } else if (data.loanReason == 11) {
                this.setState({
                  reson: "Funeral",
                });
              } else if (data.loanReason == 99) {
                this.setState({
                  reson: "Other",
                });
              }

              let time1 = data.createdTime;
              let time2 = data.shouldbackTime;
              let time3 = data.arriveTime;
              let times4 = "";
              let times5 = "";
              let a =
                new Date(time1).getTime() +
                new Date().getTimezoneOffset() * 60 * 1000 -
                0 * 60 * 60 * 1000;
              let b =
                new Date(time2).getTime() +
                new Date().getTimezoneOffset() * 60 * 1000 -
                0 * 60 * 60 * 1000;
              let c =
                new Date(time3).getTime() +
                new Date().getTimezoneOffset() * 60 * 1000 -
                0 * 60 * 60 * 1000;
              //new Date().getTimezoneOffset() 获取当前时间时间戳   （0 * 60 * 60 * 1000） 0 即为时区
              let times = moment(new Date(a)).format("YYYY-MM-DD HH:mm:ss");
              let times2 = moment(new Date(b)).format("YYYY-MM-DD HH:mm:ss");
              let times3 = moment(new Date(c)).format("YYYY-MM-DD HH:mm:ss");
              if (data.arriveTime == null) {
                times4 = "";
              } else {
                times4 = times3;
              }
              if (data.shouldbackTime == null) {
                times5 = "";
              } else {
                times5 = times2;
              }
              let show = res.data.data.record.status;
              if (show == 2) {
                this.setState({
                  isshow: true,
                });
              } else {
                this.setState({
                  isshow: false,
                });
              }
              this.setState({
                order_no: data.indentNo,
                time: times,
                appCode: res.data.data.record.appCode,
                loan_amount: data.balanceStr,
                interest: data.feePercent / 100,
                loan_term: data.cycle,
                Due_date: times5,
                Due_amount: data.shouFee / 100,
                Penalty: data.overdueFee / 100,
                Total: data.shouldbackFee / 100,
                Overdue: data.overduePercent / 100,
                Repayment: data.actualbackAmt / 100,
                Remaining: (data.shouldbackFee - data.actualbackAmt) / 100,
                Disbursement: times4,
                front: newdata.frontImgPath,
                back: newdata.backImgPath,
                hand: newdata.headImgPath,
                first: newdata.successLoanImgPath,
                thistime: newdata.recordLoanImgPath,
                count: res.data.data.user.repaymentCount,
                emount: data.extensionRateFee,

                // tableData: data,
              });
            }
          }
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
          // alert(456)
        }
      });
    axios
      .post(`/api/loan/credit/getPersonalInformation`, {
        id: id,
        uid: uid,
      })
      .then((res) => {
        let data = res.data.data.userInfo;
        let data1 = res.data.data.userCredit;
        let data2 = res.data.data.user;
        let data3 = res.data.data.userBvn;
        let code1 = data.companyProvinceCode;
        let code2 = data.companyCityCode;
        let code3 = data.cityCode;
        let code4 = data.provinceCode;
        if (res.data.code === 200) {
          if (data3 == undefined) {
            this.setState({
              bvn_no: "",
            });
          } else {
            let finalname = data3.firstName + data3.lastName;
            this.setState({
              bvn_no: data3.bvnNo,
            });
          }
          if (data1 == undefined) {
            return;
          } else {
            if (data.gender == 1) {
              this.setState({
                gender: "Male",
              });
            } else if (data.gender == 2) {
              this.setState({
                gender: "Female",
              });
            }
            if (data.maritalStatus == 1) {
              this.setState({
                status: "Single",
              });
            } else if (data.maritalStatus == 2) {
              this.setState({
                status: "Married",
              });
            } else if (data.maritalStatus == 3) {
              this.setState({
                status: "Windowed",
              });
            } else if (data.maritalStatus == 4) {
              this.setState({
                status: "Separated",
              });
            }
            if (
              data.familyMember == 1 ||
              data.familyMember == 2 ||
              data.familyMember == 9
            ) {
              this.setState({
                relation: "father or mother",
              });
            } else if (
              data.familyMember == 3 ||
              data.familyMember == 4 ||
              data.familyMember == 10
            ) {
              this.setState({
                relation: "brother or sister",
              });
            } else if (
              data.familyMember == 5 ||
              data.familyMember == 6 ||
              data.familyMember == 11
            ) {
              this.setState({
                relation: "husband or wife",
              });
            } else if (
              data.familyMember == 7 ||
              data.familyMember == 8 ||
              data.familyMember == 12
            ) {
              this.setState({
                relation: "son or daughter",
              });
            }
            if (data.otherContact == 1) {
              this.setState({
                other_relation: "Friends",
              });
            } else if (data.otherContact == 2) {
              this.setState({
                other_relation: "Colleague",
              });
            } else if (data.otherContact == 3) {
              this.setState({
                other_relation: "Schoolmate",
              });
            }
            let areaname = "";
            let areaname1 = "";
            let areaname2 = "";
            let areaname3 = "";
            for (let i = 0; i < res.data.data.areaList.length; i++) {
              if (code1 == res.data.data.areaList[i].code) {
                areaname = res.data.data.areaList[i].name;
              }
              if (code2 == res.data.data.areaList[i].code) {
                areaname1 = res.data.data.areaList[i].name;
              }
              if (code3 == res.data.data.areaList[i].code) {
                areaname2 = res.data.data.areaList[i].name;
              }
              if (code4 == res.data.data.areaList[i].code) {
                areaname3 = res.data.data.areaList[i].name;
              }
              this.setState({
                area: areaname2,
                state: areaname3,
              });
            }

            if (data.income == 1 || data.income == 6) {
              this.setState({
                income: "Within ₦ 30,000",
              });
            } else if (data.income == 2 || data.income == 7) {
              this.setState({
                income: "30,000-50,000",
              });
            } else if (
              data.income == 3 ||
              data.income == 4 ||
              data.income == 5 ||
              data.income == 8
            ) {
              this.setState({
                income: "More than ₦ 50,000",
              });
            }
            let time = data2.createdTime;
            let d =
              new Date(time).getTime() +
              new Date().getTimezoneOffset() * 60 * 1000 -
              0 * 60 * 60 * 1000;
            let times = moment(new Date(time)).format("YYYY-MM-DD HH:mm:ss");
            if (data2 == undefined) {
              return;
            } else {
              this.setState({
                fstname: data.firstName,
                midname: data.middleName,
                lstname: data.surName,
                idnum: data.idnumber,
                register: times,
                email: data.email,
                phone: data2.mobile,
                birthday: data.birthday,
                age: data.age,
                family: data.familyMemberName,
                family_phone: data.familyMemberPhone,
                other_name: data.otherContactName,
                other_phone: data.otherContactPhone,
                bvn_number: data.bvnPhone,
                live_year: data.liveYear,
                live_month: data.liveMonth,
                office_tel: data.officialNumber,
                employer: data.emplayer,
                start_time: data.startWorkingTime,
                company_address: data.companyAddress,
                recipient: data.recipientCode,
                isExtension: (data.extension==1?true:false),
              });
            }
          }
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        }
      });
    axios
      .post(`/api/userBank/getUserBankListByUid`, {
        uid: uid,
        orderId: id,
      })
      .then((res) => {
        if (res.data.code == 200) {
          if(res.data.data.isShowAddressBook == true){
            this.setState({
              isShowAddressBook:true
            })
          }
          let data = res.data.data.bankList;
          if (data.length < 1) {
            return;
          }
          let bankdata = data[0];
          let value1 = "";

          if (bankdata.isDefault == 1) {
            value1 = "yes";
          }
          if (bankdata.isDefault == 0) {
            value1 = "no";
          }
          let recipi = "";

          if (!(res.data.data.recipientList[0].recipientCode != null)) {
            recipi = "";
          } else {
            recipi = res.data.data.recipientList[0].recipientCode;
          }
          let times = moment(new Date(bankdata.createdTime)).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          this.setState({
            bankname: bankdata.bankName,
            bankAccNo: bankdata.bankAccNo,
            bankCardNo: bankdata.bankCardNo,
            expirydata: bankdata.validTill,
            authCode: bankdata.authCode,
            bankEmail: bankdata.email,
            default: value1,
            credittime: times,
            boolean: false,
          });
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        }
      });

      axios
      .post(`/api/loan/credit/getUserMonnifyAccount`,{
        uid:uid,
      }).then(res=>{
        if(res.data.code == 200){
          this.setState({
            BankName:res.data.data.resultDate.ReservedBankName,
            AccountNumber:res.data.data.resultDate.ReservedAccountNumber,
            isshowSend:res.data.data.isshow

          })
        }else{
          message.error(res.data.msg)
        }
      });
  };
  componentDidMount() {
    // this.searchClick()
    this.onload();
  }

  //modal第一个select
  newgradeChange = (e) => {
    var value = e.target.value;
    var code1 = "";
    this.setState({
      modalfstSelect: value,
    });
    var grade = value;
    if (grade == 0) {
      code1 = "please select one";

      this.setState({
        inptdis: {
          display: "none",
        },
        order_statues: "please select one",
      });
    } else if (grade == 1) {
      code1 = "Manual Approved";
      this.setState({
        inptdis: {
          display: "none",
        },
        order_statues: "Manual Approved",
      });
    } else {
      this.setState({
        inptdis: {
          display: "inline-block",
        },
      });
    }
    if (grade == 2) {
      code1 = "NO_PASS_RETURN";
      this.setState({
        order_statues: "Return by Manual Review",
        reviewreason: "returnReason",
      });
    } else if (grade == 4) {
      code1 = "NO_PASS_REJECT_IMMEDIATELY";
      this.setState({
        order_statues: "Reject by Manual Review",
        reviewreason: "rejectImmReason",
      });
    } else if (grade == 3) {
      code1 = "NO_PASS_REJECT";
      this.setState({
        order_statues: "Reject by Manual Review",
        reviewreason: "rejectReason",
      });
    } else if (grade == 5) {
      code1 = "NO_PASS_BLACK_LIST";
      this.setState({
        order_statues: "Reject by Manual Review",
        reviewreason: "blackListReason",
      });
    }
    axios
      .post(`/api/common/getCommonListByCode`, {
        code: code1,
      })
      .then((res) => {
        whitchshow = [];
        if (res.data.code === 200) {
          let data = res.data;
          for (let i = 0; i < res.data.data.length; i++) {
            if (i == 0) {
              whitchshow.push(<option value="">Please select one</option>);
            }
            whitchshow.push(
              <option value={res.data.data[i].number}>
                {res.data.data[i].name}
              </option>
            );
            this.setState({
              whitchshow: whitchshow,
            });
          }
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
        }
      });
  };

  //第一个textarea
  fsttxarea = (e) => {
    let value = e.target.value;
    this.setState({ fsttextarea: value });
  };
  //第个modal提交按钮
  fsthandleOk = (e) => {
    if (this.state.fsttextarea == "") {
      alert("Review details can't be empty.");
      return false;
    } else if (this.state.modalfstSelect == 0) {
      alert("Review status can't be empty.");
      return false;
    }
    this.setState({
      modify: true,
    });
    var objS = document.getElementById("modal_fstid2");
    var grade = objS.options[objS.selectedIndex].value;
    // console.log(e);//

    if (this.state.modalfstSelect == 1) {
      axios
        .post(`/api/loan/credit/passAudit`, {
          id: this.props.id,
          remark: this.state.fsttextarea,
        })
        .then((res) => {
          let data = res.data;
          if (res.data.code === 200) {
            this.onload();
            //   this.reloadClick()
            this.setState({
              modify: false,
              fsttextarea: "",
              modalfstSelect: "",
              fstSelect: 2,
              fstvisible: false,
              scdvisible: false,
              order_statues: "please select one",
              inptdis: {
                display: "none",
              },
            });
            message.success(res.data.msg);
          } else if (res.data.code == 800) {
            this.props.history.push({ pathname: "/" });
          } else {
            message.error(res.data.msg);
            this.setState({
              modify: false,
              fsttextarea: "",
              modalfstSelect: "",
              fstSelect: 2,
              fstvisible: false,
              scdvisible: false,
              order_statues: "please select one",
              inptdis: {
                display: "none",
              },
            });
          }
        });
    } else {
      if (this.state.modalscdSelect == 0) {
        alert("Reasons can't be empty.");
        return false;
      }
      var objS = document.getElementById("modal_fstid2");
      var seltext = objS.options[objS.selectedIndex].innerText;
      axios
        .post(`/api/loan/credit/passAudit`, {
          id: this.props.id,
          reason: this.state.fsttextarea,
          refuseValue: this.state.reviewreason,
          refuseReason: this.state.modalscdSelect,
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.onload();
            //   this.reloadClick()
            this.setState({
              fsttextarea: "",
              modalfstSelect: 0,
              fstvisible: false,
              scdvisible: false,
              fstSelect: 0,
              order_statues: "please select one",
              inptdis: {
                display: "none",
              },
            });
            whitchshow = [];
            grade = 0;
            message.success(res.data.msg);
          } else if (res.data.code == 800) {
            this.props.history.push({ pathname: "/" });
          } else {
            // alert(123)
            this.setState({
              fsttextarea: "",
              modalfstSelect: 0,
              fstSelect: 0,
              fstvisible: false,
              scdvisible: false,
              order_statues: "please select one",
              inptdis: {
                display: "none",
              },
            });
            whitchshow = [];
            grade = 0;
            message.error(res.data.msg);
          }
        });
    }
  };


  getExtensionFee = (e) => {
    let value = e.target.value;
    this.setState({ emount: value });
  };


  // 展期
  finishExtensionOk = (e) => {
    if (this.state.emount == "") {
      alert("Extension amount can't be empty.");
      return false;
    }
    this.setState({
      extensionModify: true,
    });
    let id = this.props.id;
    let uid = this.props.uid;

    // console.log(e);//
    axios
        .post(`/api/loan/credit/extensionDeal`, {
          id: this.props.id,
          uid:uid,
          orderno: this.state.order_no,
          amount:this.state.emount,
        })
        .then((res) => {
          let data = res.data;
          if (res.data.code === 200) {
            this.onload();
            //   this.reloadClick()
            this.setState({
              extensionModify: false,
              extensionvisible:false,
              emount: "",
            });
            message.success(res.data.msg);
          } else if (res.data.code == 800) {
            this.props.history.push({ pathname: "/" });
          } else {
            message.error(res.data.msg);
            this.setState({
              extensionModify: false,
              extensionvisible:false,
              emount: "",
            });
          }
        });
  };

  //通讯录
  phoneChangePage = (page) => {
      this.setState({
        phoneCurrent: page,
      });
  };

  phonehandleOk = () => {
    this.setState({
      phoneVisible: false,
      addressBtnLoading:false
    });
  };

  downExcel=()=>{
    this.state.isDisabled = true;
    this.sendTextMessage();
    let count = 60;
    let timer = setInterval(()=>{
      if(count <=0){
        this.setState({
          btnWord:'Send Msg',
          isDisabled:false
        })
        clearInterval(timer);
      }else{
        count --;
        this.setState({
          btnWord:count +'s'
        })
      }
    },1000)
  }


  sendTextMessage =() =>{
    let uid = this.props.uid;
    console.log(this.state.phone)
    console.log(this.state.AccountNumber)
    console.log(uid)
    if(uid !== undefined && this.state.phone !== undefined && this.state.AccountNumber !== undefined){
      if(uid !== '' && this.state.phone !== '' && this.state.AccountNumber !== ''){
        axios.
        post(`/api/loan/credit/sendMonnifySms` ,{
          uid:uid,
          mobile:this.state.phone,
          accountnumber:this.state.AccountNumber,
        }).then(res=>{
          if(res.data.code == 200){
            message.success('短信发送成功')
          }else{
            message.error(res.data.msg)
          }
        })
      }else{
        message.error('数据缺失请联系工作人员')
      }
    }else{
      message.error('数据缺失请联系工作人员')
    }
    
  }




  showPhoneModel = () => {
    this.setState({
      phoneVisible: true,
      addressBtnLoading: true,
    });

    let uid = this.props.uid;
    axios
      .post(`/api/userAddressBook/getUserAddressBook`, {
        uid: uid,
      })
      .then((res) => {
        let data = res.data.data;
        if (res.data.code == 200) {
          let data1 = [];
          for (let i = 0; i < data.length; i++) {
            data1.push({
              key: i,
              name: data[i].name,
              mobile: data[i].mobile,
            });
            this.setState({
              phoneTableData: data1,
              bookBoolean: false,
              addressBtnLoading: false,
            });
          }
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        }
      });
  };




  //初始化table

  auditRecordTable = () => {
    axios
      .post(`/api/loan/credit/getAuditRecordList`, {
        uid: this.props.uid,
        pageSize: 10,
        current: this.state.current,
      })
      .then((res) => {
        let tableData = [];
        if (res.data.code === 200) {
          const data = res.data.data.resultDate;
          if (data == []) {
            data1.push({
              key: "",
              indentNo: "",
              statusDescription: "",
              balance: "",
              cycle: "",
              arriveTime: "",
              shouldbackTime: "",
              lastbackTime: "",
              shouldbackFee: "",
              actualbackAmt: "",
              reduceAmt: "",
              coupon_amount: "",
              leftbackAmt: "",
              reviewers: "",
              auditUserTime: "",
              auditDetail: "",
            });
            return;
          }
          this.setState({
            tableData: data,
            total: res.data.data.page.total,
            boolean: false,
          });
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
        }
      });
  };

  render() {
    const phoneColumns = [
      {
        title: "name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "mobile",
        dataIndex: "mobile",
        key: "mobile",
      },
    ];
    const columns = [
      {
        title: "order no",
        dataIndex: "indentNo",
        key: "indentNo",
      },
      {
        title: "Review time",
        dataIndex: "auditUserTime",
        key: "auditUserTime",
      },
      {
        title: "Reviewer",
        dataIndex: "auditUserName",
        key: "auditUserName",
      },
      {
        title: "Review result",
        dataIndex: "auditResult",
        key: "auditResult",
      },
      {
        title: "Review details",
        dataIndex: "auditDetail",
        key: "auditDetail",
      },
    ];
    return (
      <div className="order_infor">
        <div className="details_tab1_top">
          <div className="details_out_div">
            <div className="tab1_top_left">
              <div className="tab1_top_left_bot">
                <p className="details_tab1_top_p">Order Information</p>
                <div className="left_bot_div">
                  <span>Order resource:</span>
                  <span className="details_special_span">
                    {this.state.appCode}
                  </span>
                </div>
                <div className="left_bot_div">
                  <span>Order No:</span>
                  <span className="details_special_span">
                    {this.state.order_no}
                  </span>
                </div>
                <div className="left_bot_div">
                  <span>Loan amount:</span>
                  <span className="details_special_span">
                    ₦{this.state.loan_amount}
                  </span>
                </div>
                <div className="left_bot_div">
                  <span>Loan term:</span>
                  <span className="details_special_span">
                    {this.state.loan_term}
                  </span>
                </div>
                {/* <div className='left_bot_div'>
                                <span>Loan reason:</span>
                                <span className='details_special_span'>{this.state.reson}</span>
                            </div> */}
                <div className="left_bot_div">
                  <span>Order Status:</span>
                  <span className="details_special_span">
                    {this.state.Status}
                  </span>
                </div>
                <div className="left_bot_div">
                  <span>Created time:</span>
                  <span className="details_special_span">
                    {this.state.time}
                  </span>
                </div>
                <div className="left_bot_div">
                  <span>Disbursement time:</span>
                  <span className="details_special_span">
                    {this.state.Disbursement}
                  </span>
                </div>
                <div className="left_bot_div">
                  <span>Due date:</span>
                  <span className="details_special_span">
                    {this.state.Due_date}
                  </span>
                </div>
                {/* <div className='left_bot_div'>
                                <span>Interest rate:</span>
                                <span className='details_special_span'>{this.state.interest}%</span>
                            </div> */}
                {/* <div className='left_bot_div'>
                                <span>Overdue rate:</span>
                                <span className='details_special_span'>{this.state.Overdue}%</span>
                            </div> */}
                <div className="left_bot_div">
                  <span>Overdue days:</span>
                  <span className="details_special_span">
                    {this.state.Overdue_day}
                  </span>
                </div>
                <div className="left_bot_div">
                  <span>Due amount:</span>
                  <span className="details_special_span">
                    ₦{this.state.Due_amount}
                  </span>
                </div>
                <div className="left_bot_div">
                  <span>Penalty fee:</span>
                  <span className="details_special_span">
                    ₦{this.state.Penalty}
                  </span>
                </div>
                <div className="left_bot_div">
                  <span>Total due amount:</span>
                  <span className="details_special_span">
                    ₦{this.state.Total}
                  </span>
                </div>
                <div className="left_bot_div">
                  <span>Repayment amount:</span>
                  <span className="details_special_span">
                    ₦{this.state.Repayment}
                  </span>
                </div>
                <div className="left_bot_div">
                  <span>Remaining balance:</span>
                  <span className="details_special_span">
                    ₦{this.state.Remaining}
                  </span>
                </div>
              </div>
              <div className="tab1_top_left_bot">
                <p className="details_tab1_top_p">Bank Account Information</p>
                <div className="left_bot_div">
                  <span>Bank name:</span>
                  <span className="details_special_span">
                    {this.state.bankname}
                  </span>
                </div>
                <div className="left_bot_div">
                  <span>Bank Account:</span>
                  <span className="details_special_span">
                    {this.state.bankAccNo}
                  </span>
                </div>
                <div className="left_bot_div">
                  <span>Card Number:</span>
                  <span className="details_special_span">
                    {this.state.bankCardNo}
                  </span>
                </div>
                <div className="left_bot_div">
                  <span>Email:</span>
                  <span className="details_special_span">
                    {this.state.bankEmail}
                  </span>
                </div>
                <div className="left_bot_div">
                  <span>Expiry Date:</span>
                  <span className="details_special_span">
                    {this.state.expirydata}
                  </span>
                </div>
                <div className="left_bot_div">
                  <span>Debit Code:</span>
                  <span className="details_special_span">
                    {this.state.authCode}
                  </span>
                </div>
                <div className="left_bot_div">
                  <span>Default:</span>
                  <span className="details_special_span">
                    {this.state.default}
                  </span>
                </div>
                <div className="left_bot_div">
                  <span>created Time:</span>
                  <span className="details_special_span">
                    {this.state.credittime}
                  </span>
                </div>
              </div>
            </div>
            <div className="tab1_top_mid">
              <div className="tab1_top_mid_div">
                <p className="details_tab1_top_p">Personal Information</p>
                <div className="left_bot_div">
                  <span>Name:</span>
                  <div>
                    <span className="details_special_span">
                      {this.state.fstname}&nbsp;
                    </span>
                    <span className="details_special_span">
                      {this.state.midname}&nbsp;
                    </span>
                    <span className="details_special_span">
                      {this.state.lstname}
                    </span>
                  </div>
                </div>
                <div className="left_bot_div">
                  <span>Gender: </span>
                  <span className="details_special_span">
                    {this.state.gender}
                  </span>
                </div>
                <div className="left_bot_div">
                  <span>Birthday:</span>
                  <span className="details_special_span">
                    {this.state.birthday}
                  </span>
                </div>
                <div className="left_bot_div">
                  <span>Age:</span>
                  <span className="details_special_span">{this.state.age}</span>
                </div>
                <div className="left_bot_div">
                  <span>register phone number:</span>
                  <span className="details_special_span">
                    {this.state.phone}
                  </span>
                </div>
                <div className="left_bot_div">
                  <span>Email:</span>
                  <span className="details_special_span">
                    {this.state.email}
                  </span>
                </div>
                <div className="left_bot_div">
                  <span>Register Time:</span>
                  <span className="details_special_span">
                    {this.state.register}
                  </span>
                </div>
                <div className="left_bot_div">
                  <span>BVN NO:</span>
                  <span className="details_special_span">
                    {this.state.bvn_no}
                  </span>
                </div>
                <div className="left_bot_div">
                  <span>BVN phone number:</span>
                  <span className="details_special_span">
                    {this.state.bvn_number}
                  </span>
                </div>
                <div className="left_bot_div">
                  <span>Company name:</span>
                  <span className="details_special_span">
                    {this.state.employer}
                  </span>
                </div>
                <div className="left_bot_div">
                  <span>Company Location:</span>
                  <span className="details_special_span">
                    {this.state.state}
                  </span>
                </div>
                <div className="left_bot_div">
                  <span>Office Tel:</span>
                  <span className="details_special_span">
                    {this.state.office_tel}
                  </span>
                </div>
                <div className="left_bot_div">
                  <span>Monthly Income:</span>
                  <span className="details_special_span">
                    {this.state.income}
                  </span>
                </div>
                <div className="left_bot_div">
                  <span>Current Location:</span>
                  <span className="details_special_span">
                    {this.state.state}
                  </span>
                </div>
                <div className="left_bot_div">
                  <span>Family Member Relation:</span>
                  <span className="details_special_span">
                    {this.state.relation}
                  </span>
                </div>
                <div className="left_bot_div">
                  <span>Family Member Name:</span>
                  <span className="details_special_span">
                    {this.state.family}
                  </span>
                </div>
                <div className="left_bot_div">
                  <span>Family Member Phone:</span>
                  <span className="details_special_span">
                    {this.state.family_phone}
                  </span>
                </div>
                <div className="left_bot_div">
                  <span>Other Contact Relation:</span>
                  <span className="details_special_span">
                    {this.state.other_relation}
                  </span>
                </div>
                <div className="left_bot_div">
                  <span>Other Contact Name:</span>
                  <span className="details_special_span">
                    {this.state.other_name}
                  </span>
                </div>
                <div className="left_bot_div">
                  <span>Other Contact phone:</span>
                  <span className="details_special_span">
                    {this.state.other_phone}
                  </span>
                </div>
              </div>
              <div className="tab1_top_mid_div">
                <p className="details_tab1_top_p">Verification management</p>
                <div className="detail_button_div">
                  {this.state.isshow && (
                    <Button
                      className="details_button"
                      type="primary"
                      onClick={this.showModel}
                      size="large"
                    >
                      Manual audit
                    </Button>
                  )}
                  <Button
                    className="details_button"
                    type="primary"
                    onClick={this.showAuditRecord}
                    size="large"
                  >
                    Audit Record
                  </Button>
                  <Button
                    type="primary"
                    className="details_button"
                    type="primary"
                    size="large"
                    loading={this.state.addressBtnLoading}
                    style={{
                      width: 150,
                      marginRight: 20,
                      float: "right",
                      display: this.state.isShowAddressBook ? "inline-block" : "none",
                    }}
                    onClick={this.showPhoneModel}
                  >
                    Address book
                  </Button>

                  {this.state.isExtension && (
                      <Button
                          className="details_button"
                          type="primary"
                          onClick={this.showExtensionModel}
                          size="large"
                      >
                        Extension
                      </Button>
                  )}
                </div>
                <Modal
                  title="Audit Record"
                  visible={this.state.AuditRecordvisible}
                  onCancel={this.handleCancelAudit}
                  width={800}
                  footer={[]}
                >
                  <Table
                    columns={columns}
                    dataSource={this.state.tableData}
                    loading={this.state.boolean}
                    onChange={this.handleChange}
                    pagination={false}
                  />
                  <Pagination
                    // showQuickJumper
                    defaultCurrent={1}
                    current={this.state.current}
                    total={this.state.total}
                    onChange={this.pageChange}
                    showTotal={showTotal}
                    style={{ textAlign: "right", marginTop: 20 }}
                    pageSize={this.state.pageSize}
                    // showSizeChanger={true}
                  />
                </Modal>
                <Modal
                  title="Review"
                  visible={this.state.fstvisible}
                  onCancel={this.handleCancel}
                  width={700}
                  footer={[
                    <Button key="back" onClick={this.handleCancel}>
                      cancel
                    </Button>,
                    <Button
                      key="submit"
                      type="primary"
                      loading={this.state.modify}
                      onClick={this.fsthandleOk}
                    >
                      ok
                    </Button>,
                  ]}
                >
                  <input
                    type="text"
                    value={this.state.fstinpt}
                    style={{ display: "none" }}
                  />
                  <span style={{ fontSize: 15, fontWeight: "bold" }}>
                    Review status:
                  </span>
                  <select
                    className="model_fst"
                    id="modal_fstid2"
                    value={this.state.modalfstSelect}
                    onChange={this.newgradeChange}
                  >
                    <option value="0">Please select one</option>
                    <option value="1">Approved</option>
                    {/* <option value="2">Return——Incomplete information</option> */}
                    <option value="3">Reject——Reapply 30 days</option>
                    <option value="4">Reject——Reapply immediately</option>
                    {/* <option value="5">Reject——Move to blacklist</option> */}
                  </select>
                  <br />
                  <span style={{ fontSize: 15, fontWeight: "bold" }}>
                    Loan status:
                  </span>
                  <input
                    className="order_model_scd"
                    disabled="disabled"
                    value={this.state.order_statues}
                  />
                  <br />
                  <span style={this.state.inptdis} className="long">
                    Reasons for rejection:
                  </span>
                  <select
                    style={this.state.inptdis}
                    className="model_fst_special"
                    value={this.state.modalscdSelect}
                    onChange={this.modelselectTwo.bind(this)}
                  >
                    {whitchshow}
                  </select>
                  <br />
                  <div className="model_last">
                    <label style={{ fontSize: 15, fontWeight: "bold" }}>
                      Review details:
                    </label>
                    <textarea
                      style={{ width: "65%", marginLeft: "3%", height: 80 }}
                      value={this.state.fsttextarea}
                      onChange={this.fsttxarea}
                    />
                  </div>
                </Modal>



                <Modal
                  title="Address book"
                  visible={this.state.phoneVisible}
                  onOk={this.phonehandleOk}
                  onCancel={this.phonehandleOk}
                >
                  <Table
                    columns={phoneColumns}
                    dataSource={this.state.phoneTableData}
                    loading={this.state.bookBoolean}
                    onChange={this.phoneHandleChange}
                    pagination={{
                      // 分页
                      simple: true,
                      current: this.state.phoneCurrent,
                      pageSize: 20,
                      total: this.state.phoneTotal,
                      onChange: this.phoneChangePage,
                    }}
                  />
                </Modal>


                <Modal
                    title="Extension"
                    visible={this.state.extensionvisible}
                    onCancel={this.handleCancel}
                    width={700}
                    footer={[
                      <Button key="back" onClick={this.handleCancel}>
                        cancel
                      </Button>,
                      <Button
                          key="submit"
                          type="primary"
                          loading={this.state.extensionModify}
                          onClick={this.finishExtensionOk}
                      >
                        ok
                      </Button>,
                    ]}
                >

                  <span style={{ fontSize: 15, fontWeight: "bold" }}>
                    已还金额:
                  </span>
                  <input
                      type="text"
                      disabled
                      value={this.state.Repayment}
                      readOnly
                  />
                  <br />
                  <span style={{ fontSize: 15, fontWeight: "bold" }}>
                    展期费:
                  </span>
                  <Input
                      type="text"
                      value={this.state.emount}
                      style={{ marginTop: 10 }}
                      onChange={this.getExtensionFee}
                  />

                </Modal>



              </div>
            </div>
            <div className="tab1_top_right">
              <p className="details_tab1_top_p">Photo display</p>
              <div className="tab1_top_bot_div">
                <div className="img_content">
                  {/* <div class="img">
                            <img class="bigger" src={this.state.front} alt="Certificates for positive"/>
                            <p className='tab1_img_p'>Certificates for positive</p>
                        </div>
                        <div class="img">
                            <img class="bigger" src={this.state.back} alt="Certificates for opposite"/>
                            <p className='tab1_img_p'>Certificates for opposite</p>
                        </div>

                        <div class="img">
                            <img class="bigger" src={this.state.hand} alt="Certificates for handheld"/>
                            <p className='tab1_img_p'>Certificates for handheld</p>
                        </div> */}
                  <div className="img">
                    <img
                      className="bigger"
                      src={this.state.first}
                      alt="First success loan"
                    />
                    <p className="tab1_img_p">First success loan photo</p>
                  </div>
                  <div className="img">
                    <img
                      className="bigger"
                      src={this.state.thistime}
                      alt="This time loan"
                    />
                    <p className="tab1_img_p">This time loan photo</p>
                  </div>
                </div>
              </div>
              <p className="details_tab1_top_p">offline bank repayment</p>
              <div className="tab1_top_bot_div">
                <div className="left_bot_div">
                    <span>bank name:</span>
                    <span className="details_special_span">
                      {this.state.BankName}
                    </span>
                </div>
                <div className="left_bot_div">
                    <span>A/C NO:</span>
                    <span className="details_special_span">
                      {this.state.AccountNumber}
                    </span>
                </div>
              </div>
              <div className="tab1_top_bot_div">
                {this.state.isshowSend && (
                  <Button type="primary"  style={{width:150,marginRight:20,height:40,backgroundColor:this.state.isDisabled?'':'#009688',border:'none'}} onClick={this.downExcel} disabled={this.state.isDisabled}>{this.state.btnWord}</Button>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
