/*eslint-disable*/
import React from 'react';
import {Input, Button,Switch ,Tree , Table, Modal,Radio, Pagination, DatePicker, Tooltip, message} from 'antd';
import './index.css';
import axios from 'axios'
import moment from 'moment';
import {withRouter} from 'react-router-dom'
import EventEmitter from '../../utils/events'
var getSelect = []
var treeData = []
let test = []
const DateFormat = 'YYYY-MM-DD'||undefined;
function showTotal(total) {
    return `Total ${total} items`;
}



var getselect = []
const { TreeNode } = Tree;
const {Search} = Input;
const key = [];
 class AppVersion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 1,
            data: [],
            tableData: [],
            searchVal: '',
            current: 1,
            total: 1,
            pageSize: 10,
            boolean: true,
            value1:1,
            fstAddVal:'',
            scdAddVal:'',
            thdAddVal:10,
            fivAddVal:'',
            fouAddVal:0,
            sixAddVal:'',
            sevAddVal:'',
            eigAddVal:'',
            ninAddVal:'',
            thdEditVal:10,
            fouEditVal:0
        }
    }
    
     




        //页码改变时触发
        pageChange=(page)=> { 
      
            this.setState({
              current: page
              },()=>{
                this.searchClick()
              })
            }

    onChange1 = e => {
        console.log('radio checked', e.target.value);
        this.setState({
          value1: e.target.value,
        });
      };


    onChange4 = e => {
        console.log('radio checked', e.target.value);
        this.setState({
            value4: e.target.value,
        });
      };
      dateonChange = (date, dateString) =>{

        this.setState({ datepiker:dateString})
      }
    /*添加modal点击ok*/ 
    addhandleOk=()=>{
        axios
        .post(`/api/appVersion/appVersionAdd`, {
            appCode:this.state.fstAddVal,
            appName:this.state.scdAddVal,
            appType:this.state.thdAddVal,
            versionCode:this.state.fivAddVal,
            versionName:this.state.sixAddVal,
            publishTime:this.state.datepiker,
            downUrl:this.state.sevAddVal,
            googleDownUrl:this.state.eigAddVal,
            speadUr:this.state.ninAddVal,
            forceFlag:this.state.fouAddVal
        })
        .then(res => {
            if (res.data.code === 200) {        
                message.success(res.data.msg)
                this.searchClick()
                this.setState({
                    fstAddVal:'',
                    scdvisible:false,
                    scdAddVal:'',
                    thdAddVal:10,
                    fouAddVal:5,
                    sixAddVal:'',
                    sevAddVal:'',
                    eigAddVal:'',
                    ninAddVal:'',
                    fivAddVal:'',
                    datepiker:''

                })
                this.searchClick()
            } else if (res.data.code == 800) {
                this.props.history.push({pathname: "/"});
            } else {
                message.error(res.data.msg)
                this.setState({
                    fstAddVal:'',
                    scdvisible:false,
                    scdAddVal:'',
                    thdAddVal:10,
                    fouAddVal:5,
                    sixAddVal:'',
                    sevAddVal:'',
                    eigAddVal:'',
                    ninAddVal:'',
                    fivAddVal:'',
                    datepiker:''
                       
                })
                this.searchClick()
            }
        })
    }
     /*删除modal点击ok*/ 
    deletehandleOk=()=>{
        axios
        .post(`/api/appVersion/appVersionDel`, {
            id:this.state.delval,
        })
        .then(res => {
            if (res.data.code === 200) {        
                message.success(res.data.msg)
                this.searchClick()
                this.setState({
                    thdvisible:false

                })
            } else if (res.data.code == 800) {
                this.props.history.push({pathname: "/"});
            } else {
                message.error(res.data.msg)
                this.searchClick()
                this.setState({
                    thdvisible:false
                })
            }
        })
    }
    edit=(record)=>{
        let value = record.publishTime
        let timeaudit= new Date(value).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
        let auditimes=moment(new Date(timeaudit)).format("YYYY-MM-DD HH:mm:ss");
        axios
        .post(`/api/appVersion/appVersionGet`, {
            id:record.id,
        })
        .then(res => {
            if (res.data.code === 200) {        
                this.setState({

                })
            } else if (res.data.code == 800) {
                this.props.history.push({pathname: "/"});
            } else {
                message.error(res.data.msg)
            }
        })
        this.setState({
            fstvisible:true,
            pid:record.pid,
            id:record.id,
            value1:record.status,
            fstEditVal:record.appCode,
            scdEditVal:record.appName,
            fivEditVal:record.versionCode,
            sixEditVal:record.versionName,
            sevEditVal:record.downUrl,
            eigEditVal:record.googleDownUrl,
            tenEditVal:auditimes
        })
    }
    edithandleOk=(record,e)=>{
        axios
        .post(`/api/appVersion/appVersionEdit`, {
            appCode:this.state.fstEditVal,
            appName:this.state.scdEditVal,
            appType:this.state.thdEditVal,
            versionCode:this.state.fivEditVal,
            versionName:this.state.sixEditVal,
            publishTime:this.state.tenEditVal,
            downUrl:this.state.sevEditVal,
            googleDownUrl:this.state.eigEditVal,
            speadUr:this.state.ninEditVal,
            id:this.state.id,
            forceFlag:this.state.fouEditVal
        })
        .then(res => {
            if (res.data.code === 200) {        
                message.success(res.data.msg)
              
                this.setState({
                    fstAddVal:'',
                    scdAddVal:'',
                    thdAddVal:10,
                    fouAddVal:5,
                    sixAddVal:'',
                    sevAddVal:'',
                    eigAddVal:'',
                    ninAddVal:'',
                    datepiker:'',
                    fivEditVal:''

                })
                this.searchClick()
            } else if (res.data.code == 800) {
                this.props.history.push({pathname: "/"});
            } else {
                message.error(res.data.msg)
                  this.setState({
                    fstAddVal:'',
                    scdAddVal:'',
                    thdAddVal:10,
                    fouAddVal:5,
                    sixAddVal:'',
                    sevAddVal:'',
                    eigAddVal:'',
                    ninAddVal:'',
                    datepiker:'',
                    fivEditVal:''
                })
            }
        })
        this.setState({
            fstvisible:false,
        })

    }

    Add=()=>{
        this.setState({
            scdvisible:true,
        })
    }
 

   
        modelselectThree=(e)=>{
            var value = e.target.value;
            this.setState({
              modalthdSelect:value
            })
           
          }

    handleCancel=()=>{
        this.setState({
            fstvisible:false,
            scdvisible:false,
            thdvisible:false,
            fouvisible:false,
            datepiker:''

        })
    }
    delete=(record)=>{
        this.setState({
            thdvisible:true,
            delval:record.id,
            appname:record.appName
        })
    }

//页面初始化加载下面的table
    secondTable = () => {
        this.searchClick()
    }
    //搜索
    searchClick = () => {
        this.setState({
            boolean: true
        })
        axios
            .post(`/api/appVersion/appVersionList`, {
                pageNum:this.state.current,
                pageSize:this.state.pageSize,
                name:this.state.searchVal
            })
            .then(res => {
                if (res.data.code === 200) {

                    const data1 = [];
                    const data = res.data.data.resultData

                    for (let i = 0; i < data.length; i++) {
                        data[i].key = i;
                        data1.push(data[i]);
                    }
                    this.setState({
                        tableScdData: data1,
                        boolean: false,
                        length:res.data.data.page.total
                    })
                } else if (res.data.code == 800) {
                    this.props.history.push({pathname: "/"});
                } else {
                    
                }
            })
    }
    handClick = (item) => {
        console.log('1111111122222233333', item)
    }
    fstonChange=(e)=>{
        let value=e.target.value;
        this.setState({
            fstval:value
        })
    }

    /*添加modal的input开始 */
    AddOnChange1=(e)=>{
        let value=e.target.value;
        this.setState({
            fstAddVal:value
        })
    }
    AddOnChange2=(e)=>{
        let value=e.target.value;
        this.setState({
            scdAddVal:value
        })
    }
    AddOnChange3=(e)=>{
        let value=e.target.value;
        this.setState({
            thdAddVal:value
        })
    }
    AddOnChange4=(e)=>{
        let value=e.target.value;
        this.setState({
            fouAddVal:value
        })
    }
    AddOnChange5=(e)=>{
        let value=e.target.value;
        this.setState({
            fivAddVal:value
        })
    }
    AddOnChange6=(e)=>{
        let value=e.target.value;
        this.setState({
            sixAddVal:value
        })
    }
    AddOnChange7=(e)=>{
        let value=e.target.value;
        this.setState({
            sevAddVal:value
        })
    }
    AddOnChange8=(e)=>{
        let value=e.target.value;
        this.setState({
            eigAddVal:value
        })
    }
    AddOnChange9=(e)=>{
        let value=e.target.value;
        this.setState({
            ninAddVal:value
        })
    }
    AddOnChange10=(e)=>{
        let value=e.target.value;
        this.setState({
            datepiker:value
        })
    }
   
    /*添加modal的input结束 */
    EditOnChange1=(e)=>{
        let value=e.target.value;
        this.setState({
            fstEditVal:value
        })
    }
    EditOnChange2=(e)=>{
        let value=e.target.value;
        this.setState({
            scdEditVal:value
        })
    }
    EditOnChange3=(e)=>{
        let value=e.target.value;
        this.setState({
            thdEditVal:value
        })
    }
    EditOnChange4=(e)=>{
        let value=e.target.value;
        this.setState({
            fouEditVal:value
        })
    }
    EditOnChange5=(e)=>{
        let value=e.target.value;
        this.setState({
            fivEditVal:value
        })
    }
    EditOnChange6=(e)=>{
        let value=e.target.value;
        this.setState({
            sixEditVal:value
        })
    }
    EditOnChange7=(e)=>{
        let value=e.target.value;
        this.setState({
            sevEditVal:value
        })
    }
    EditOnChange8=(e)=>{
        let value=e.target.value;
        this.setState({
            eigEditVal:value
        })
    }
    EditOnChange9=(e)=>{
        let value=e.target.value;
        this.setState({
            ninEditVal:value
        })
    }
    EditOnChange10=(e)=>{
        let value=e.target.value;
        this.setState({
            tenEditVal:value
        })
    }
        //输入框
        searchIpt(e){
            var value = e.target.value;
            this.setState({ searchVal: value.replace(/\s+/g,"") })
          }
    scdonChange=(e)=>{
        let value=e.target.value;
        this.setState({
            scdval:value
        })
    }
    thdonChange=(e)=>{
        let value=e.target.value;
        this.setState({
            thdval:value
        })
    }
    componentDidMount() {
        this.secondTable()
    }

    render() {

        const columns = [

            {
                title: 'App code',
                dataIndex: 'appCode',
                key: 'appCode',
                render: (value) => {
                    return value || '——'
                }
            },
            {
                title: 'App name',
                dataIndex: 'appName',
                key: 'appName',
                render: (value) => {
                    return value    
                }

            },
            {
                title: 'App type',
                dataIndex: 'appType',
                key: 'appType',      
                render: (value) => {
                    if (value == 10) {
                        return "Android"
                    }
                    if (value == 11) {
                        return "Android Pad"
                    }
                    if (value == 20) {
                        return "IOS"
                    }
                    if (value == 21) {
                        return "IOS Pad"
                    }
            }
                },         
            
            {
                title: 'Version code',
                dataIndex: 'versionCode',
                key: 'versionCode',
                id:'id',
                render: (value) => {
                    return value    
                }
            },

            {
                title: 'version name',
                dataIndex: 'versionName',
                key: 'versionName',
                render: (value) => {
                    return value    
                }
            },
            {
                title: 'Forced to update',
                dataIndex: 'forceFlag',
                key: 'forceFlag',
                render: (value) => {
                    if (value == 0) {
                        return "No"
                    }
                    if (value == 1) {
                        return "Yes"
                    }
                }

            },
            {
                title: 'Download address',
                dataIndex: 'downUrl',
                key: 'downUrl',
                render: (value) => {
                    return value    
                }
            },
            {
                title: 'Promotion of home page',
                dataIndex: 'spreadUrl',
                key: 'spreadUrl',
                render: (value) => {
                    return value    
            }
            },

            {
                title: 'Google play download address',
                dataIndex: 'googleDownUrl',
                key: 'googleDownUrl',
                render: (value) => {
                    return value    
                }
            },
            {
                title: 'Publish time',
                dataIndex: 'publishTime',
                key: 'publishTime',
                render: (value) => {
                    let timeaudit= new Date(value).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
                    let auditimes=moment(new Date(timeaudit)).format("YYYY-MM-DD HH:mm:ss");
                    return auditimes
                }

            },
            {
                title: 'order created time',
                dataIndex: 'createdTime',
                key: 'createdTime',
                render: (value,record,ifCheck) => {  
                    let timeaudit= new Date(value).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
                    let auditimes=moment(new Date(timeaudit)).format("YYYY-MM-DD HH:mm:ss");
                    return auditimes
                }
            },
            {
                title: 'update Time',
                dataIndex: 'updatedTime',
                key: 'updatedTime',
                id:'id',
                render: (value) => {
                    let timeaudit= new Date(value).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
                    let auditimes=moment(new Date(timeaudit)).format("YYYY-MM-DD HH:mm:ss");
                    return auditimes
                }
            },
            { title: 'Operation', dataIndex: '', key: 'operation', render: (text, record) => 
            <span style={{ display: 'flex' , flexDirection: 'row', justifyContent:'center',margin:0}}>
                 <a className='edit_button' href="javascript:;" onClick={() => this.edit(record)}>edit</a>
                 <a className='edit_button' href="javascript:;" onClick={() => this.delete(record)}>delete</a>
              </span>
           
            }
        ];
        return (
            <div className="menu_content">
             <div style={{width:'98%',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                <div>
                    <Search
                        placeholder="app name"
                        onSearch={value => console.log(value)}
                        style={{width: 290,marginRight:10,marginLeft:10}}
                        value={this.state.searchVal}
                        onChange={this.searchIpt.bind(this)}
                    />
                    <Button type="primary" style={{width:170,marginRight:20,height:40,border:'none'}} className="common_color" onClick={this.searchClick}>Search</Button>
                </div>
                
                    <Button className='tab2_button' type="primary" style={{ marginTop: 15 }} onClick={this.Add}>Add</Button></div>
                <div className="table">
                    <Table columns={columns} rowKey={record=>record.id} className='old_table'  dataSource={this.state.tableScdData} loading={this.state.boolean}  pagination={false}/>  
                </div>
                <Pagination
                defaultCurrent={1} 
                current={this.state.current}
                total={this.state.length} 
                onChange={this.pageChange} 
                pageSize={this.state.pageSize}
                showTotal={showTotal}
                style={{textAlign:'right',marginTop:20}}
                />
                <Modal
                title="Edit" 
                visible={this.state.fstvisible}
                onOk={this.edithandleOk}
                onCancel={this.handleCancel}
                >
                 <div className='product_div'>
                    <div className='common_div'>
                        <span className='product_span'>App code:</span>
                        <Input value = {this.state.fstEditVal}  allowClear  onChange={this.EditOnChange1} />
                    </div>
                    <div className='common_div'>
                        <span className='product_span'>App name:</span>
                        <Input value = {this.state.scdEditVal} allowClear  onChange={this.EditOnChange2} />
                    </div>
                   
                </div>
                <div className='product_div'>
                    <div className='common_div'>
                        <span className='product_span'>App type:</span>
                        <select  value = {this.state.thdEditVal} className='product_select'   allowClear  onChange={this.EditOnChange3}>
                            <option value="10">Android</option>
                            <option value="11">Android Pad</option>
                            <option value="20">IOS</option>
                            <option value="21">IOS Pad</option>
                        </select>
                    </div>
                    <div className='common_div'>
                        <span className='product_span'>Forced to update:</span>
                        <select  value = {this.state.fouEditVal} allowClear className='product_select' onChange={this.EditOnChange4}>
                            <option value="0">no</option>
                            <option value="1">yes</option>
                        </select>
                    </div>
                </div>
                <div className='product_div'>
                    <div className='common_div'>
                        <span className='product_span'>Version code:</span>
                        <Input value = {this.state.fivEditVal}  allowClear  onChange={this.EditOnChange5} />
                    </div>
                    <div className='common_div'>
                        <span className='product_span'>version name:</span>
                        <Input value = {this.state.sixEditVal}  allowClear  onChange={this.EditOnChange6} />
                    </div>
                </div>
                <div className='product_div'>
                    <div className='common_div'>
                        <span className='product_span'>Download address:</span>
                        <Input value = {this.state.sevEditVal}  allowClear  onChange={this.EditOnChange7} />
                    </div>
                    <div className='common_div'>
                        <span className='product_span'>Google play download:</span>
                        <Input value = {this.state.eigEditVal}  allowClear  onChange={this.EditOnChange8} />
                    </div>
                </div>
                <div className='product_div'>
                    <div className='common_div'>
                        <span className='product_span'>Promotion of home page:</span>
                        <Input value = {this.state.ninEditVal}  allowClear  onChange={this.EditOnChange9} />
                    </div>
                    <div className='common_div'>
                        <span className='product_span'>Publish time:</span>
                        <Input value = {this.state.tenEditVal}  allowClear  onChange={this.EditOnChange10} />
                    </div>
                </div>
                <br />
                </Modal>

                <Modal
                title="New" 
                visible={this.state.scdvisible} 
                onOk={this.addhandleOk}
                onCancel={this.handleCancel}
                >
                <div className='product_div'>
                    <div className='common_div'>
                        <span className='product_span'>App code:</span>
                        <Input value = {this.state.fstAddVal}  allowClear  onChange={this.AddOnChange1} />
                    </div>
                    <div className='common_div'>
                        <span className='product_span'>App name:</span>
                        <Input value = {this.state.scdAddVal} allowClear  onChange={this.AddOnChange2} />
                    </div>
                   
                </div>
                <div className='product_div'>
                    <div className='common_div'>
                        <span className='product_span'>App type:</span>
                        <select  value = {this.state.thdAddVal} className='product_select'   allowClear  onChange={this.AddOnChange3}>
                            <option value="10">Android</option>
                            <option value="11">Android Pad</option>
                            <option value="20">IOS</option>
                            <option value="21">IOS Pad</option>
                        </select>
                    </div>
                    <div className='common_div'>
                        <span className='product_span'>Forced to update:</span>
                        <select  value = {this.state.fouAddVal} allowClear className='product_select' onChange={this.AddOnChange4}>
                            <option value="0">no</option>
                            <option value="1">yes</option>
                        </select>
                    </div>
                </div>
                <div className='product_div'>
                    <div className='common_div'>
                        <span className='product_span'>Version code:</span>
                        <Input value = {this.state.fivAddVal}  allowClear  onChange={this.AddOnChange5} />
                    </div>
                    <div className='common_div'>
                        <span className='product_span'>version name:</span>
                        <Input value = {this.state.sixAddVal}  allowClear  onChange={this.AddOnChange6} />
                    </div>
                </div>
                <div className='product_div'>
                    <div className='common_div'>
                        <span className='product_span'>Download address:</span>
                        <Input value = {this.state.sevAddVal}  allowClear  onChange={this.AddOnChange7} />
                    </div>
                    <div className='common_div'>
                        <span className='product_span'>Google play download :</span>
                        <Input value = {this.state.eigAddVal}  allowClear  onChange={this.AddOnChange8} />
                    </div>
                </div>
                <div className='product_div'>
                    <div className='common_div'>
                        <span className='product_span'>Promotion of home page:</span>
                        <Input value = {this.state.ninAddVal}  allowClear  onChange={this.AddOnChange9} />
                    </div>
                    <div className='common_div'>
                        <span className='product_span'>Publish time:</span>
                        <DatePicker style={{width:231}}   value={this.state.datepiker===undefined||this.state.datepiker===""?null:moment(this.state.datepiker, DateFormat) }onChange={this.dateonChange} /> 
                    </div>
                </div>
                <br />
                
                </Modal>

                <Modal
                visible={this.state.thdvisible} 
                onOk={this.deletehandleOk}
                onCancel={this.handleCancel}
                >
                <input value={this.state.delval} style={{display:'none'}}/>
               <p>Are you sure you want to delete {this.state.appname} ?</p>
                
                </Modal>
            </div>

        )
    }
}
export default withRouter(AppVersion)