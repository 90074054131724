/*eslint-disable*/
import React from 'react';
import { Button,Table,DatePicker,Pagination,message } from 'antd';
import './index.css';
import axios from 'axios'
import moment from 'moment';
import {withRouter} from 'react-router-dom'
import EventEmitter from '../../../utils/events'

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD'||undefined;

function showTotal(total) {
  return `Total ${total} items`;
}

class dayAudit extends React.Component{
    constructor(props){
        super(props);
        this.state={
          from:'',
          to:'',
          tableData:[], 
          current:1,
          length:1,
          pageSize:10,
          boolean:true,
          rid:''
        }
      }
      componentDidMount(){
        this.secondTable();
      }
      //设置时间
      rangeOnChange=(dates, dateStrings)=>{
        this.setState({ from: dateStrings[0],to:dateStrings[1] })
      }
    
    reloadClick(e){
      this.setState({
        from:undefined,
        to:undefined,
        boolean:true
      },()=>{
        this.secondTable()
      });
      
      }
    showmodal=()=>{
        alert(this.index)
    }
    pageChange=(page)=>{
      this.setState({
        current: page
        },()=>{
          this.searchClick()
        })
    }
    onShowSizeChange=(current, pageSize)=>{

      this.setState({
        pageSize: pageSize
      },()=>{
        this.secondTable()
      })
  }

//页面初始化加载的table
  secondTable=()=>{
    let urlid = ''
    var url = window.location.hash
    var params = url.substr(1);
    var aPos = params.indexOf("=")
     urlid = params.substr(aPos + 1);
    axios
      .post(`/api/loan/credit/getCreditDaysReviewerStatisticsSearchByAuditTime`,{

      pageSize: this.state.pageSize,
      current: this.state.current,
      userId:urlid,
    })
    .then(res=>{
      if(res.data.code === 200){
        const data1 = [];
        const data = res.data.data.resultDate
        if(data.length<1){
          this.setState({
            boolean:false
          })
        }else{
          for (let i = 0; i < data.length; i++) {
            data1.push({
              key: i,
              reviewer:data[i].reviewer,
              date:data[i].date,
              reviewedCount:data[i].reviewedCount,
              passCount:data[i].passCount,
              rejectCount:data[i].rejectCount,
              passRate:data[i].passRate,
              reviewerId:data[i].reviewerId,
            });
           
          } 
        }
        
        this.setState({
          tableScdData:data1,
          length:res.data.data.page.total,
          boolean:false
        })
      }else if(res.data.code ==800){
        this.props.history.push({ pathname: "/"});
      }else{

      }
    })
    
  }

  toShopDetails=(record)=>{
    window.location='/#/credit_system/credit_auditime/hour_audit?id='+record.reviewerId
    let test = [{path: '/credit_system/credit_auditime/hour_audit',key: `/credit_system/credit_auditime/hour_audit/${record.reviewerId}`,number: `${record.reviewerId}`}]
    EventEmitter.trigger('btn', test[0])
    EventEmitter.trigger('idanduid', test[0].number) 
  }

          //搜索
          searchClick=()=>{ 
            // if(this.props.location.state == undefined){
            //   this.props.history.push({ pathname: "/home"});
            // }   
            let urlid = ''
            var url = window.location.hash
            var params = url.substr(1);
            var aPos = params.indexOf("=")
             urlid = params.substr(aPos + 1);
                  axios
                    .post(`/api/loan/credit/getCreditDaysReviewerStatisticsSearchByAuditTime`,{

                    beginTime:this.state.from,
                    endTime:this.state.to,
                    pageSize: this.state.pageSize,
                    userId:urlid,
                    current: this.state.current,
                  })
                  .then(res=>{
                    if(res.data.code === 200){
                      const data1 = [];
                      const data = res.data.data.resultDate
                      if(data.length<1){
                        this.setState({
                          boolean:false
                        })
                      }else{
                        for (let i = 0; i < data.length; i++) {
                          data1.push({
                            key: i,
                            reviewer:data[i].reviewer,
                            date:data[i].date,
                            reviewedCount:data[i].reviewedCount,
                            passCount:data[i].passCount,
                            rejectCount:data[i].rejectCount,
                            passRate:data[i].passRate,
                            reviewerId:data[i].reviewerId,
                          });
                          console.log('aaaaa',data1)
                         
                        } 
                      }
                      
                      this.setState({
                        tableScdData:data1,
                        boolean:false
                      })
                    }else if(res.data.code ==800){
                      this.props.history.push({ pathname: "/"});
                    }else{
                    }
                  })
                  // localStorage.removeItem('userId')
                  
                }
    render(){
        

        const columns = [
           {
              title: 'Date',
              dataIndex: 'date',
              key: 'date'
            }, {
              title: 'Reviewer',
              dataIndex: 'reviewer',
              key: 'reviewer'
            }, {
              title: 'Reviewed number',
              dataIndex: 'reviewedCount',
              key: 'reviewedCount'
            }, {
              title: 'Approval number',
              dataIndex: 'passCount',
              key: 'passCount'
            }, {
              title: 'Rejected number',
              width: 120,
              dataIndex: 'rejectCount',
              key: 'rejectCount'
            }, {
              title: 'Review passing rate',
              dataIndex: 'passRate',
              key: 'passRate'
            },
            {
              title: 'Operation',
              key: 'operation',
              fixed: 'right',
              reviewerId:'reviewerId',
              width: 80,
              render: (record) =><a style={{margin:10,fontSize:12}}  onClick={() => this.toShopDetails(record)}>details</a>
            },
          ];
        return(
            <div className="audit_content">
                 <div className="commom_top" style={{alignItems:'center'}}>
                 <RangePicker
                ranges={{
                Today: [moment(), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                }}
                showTime
                format={dateFormat}
                onChange={this.rangeOnChange}
                value={this.state.from===undefined||this.state.to===undefined||this.state.from===""||this.state.to===""?null:[moment(this.state.from, dateFormat), moment(this.state.to, dateFormat)]}
                />
                 <Button type="primary" style={{width:170,marginLeft:'15%',marginRight:50,height:40,border:'none'}}  className="common_color" onClick={this.searchClick.bind(this)}>Search</Button>
                <Button type="primary" style={{width:170,height:40,border:'none',}} className="common_color_scd" onClick={this.reloadClick.bind(this)}>Refresh</Button>
                </div>
                <div className="table">
                <Table columns={columns} dataSource={this.state.tableScdData} loading={this.state.boolean} pagination={false} scroll={{ x: '100%' }}/>
                </div>

                  <Pagination
                  // showQuickJumper
                  showSizeChanger                          //展示改变pagesize样式
                  onShowSizeChange={this.onShowSizeChange} //pagesize改变时回调函数
                  pageSizeOptions={["10","50","100"]}   //设置pagesize区间
                  defaultCurrent={1} 
                  current={this.state.current}
                  total={this.state.length} 
                  onChange={this.pageChange} 
                  pageSize={this.state.pageSize}
                  showTotal={showTotal}
                  style={{textAlign:'right',marginTop:20}}
                  />
            </div>
        )
    }
}
export default withRouter(dayAudit)