/*eslint-disable*/
import React from 'react';
import { Input,Button,Table,Modal,Pagination,DatePicker,Tooltip,message } from 'antd';
import './index.css';
import axios from 'axios'
import {withRouter} from 'react-router-dom'
import moment from 'moment';
import { exportMethod } from '../../utils/axios'

const { RangePicker } = DatePicker;
  const { Search } = Input;
  const dateFormat = 'YYYY-MM-DD'||undefined;
  function showTotal(total) {
    return `Total ${total} items`;
  }
  
 class OfflineQC extends React.Component {
    constructor(props){
      super(props); 
      this.state={
        current:1,  
        pageSize:10,
        startDate: '',
        endDate: '',
        dates: []
      }

    }
    export=()=>{
          
      let myObj = {
        method: 'post',
        url:`/api/bankPayment/outExcelOffManageData`,
        data:{
          orderNo:this.state.order,
          name:this.state.name,
          mobile:this.state.phone,
          amount:this.state.amount,
          status:this.state.status,
          operateBeginTime:this.state.from,
          operateEndTime:this.state.to,
          checkNo:this.state.check,
          operator:this.state.operator,
          qc:this.state.qc,
          beginTime:this.state.startDate,
          endTime:this.state.endDate,
          finalStatus:this.state.finalStatus,
          type:this.state.type
        },
        fileName: 'Offline Payment QC',
        }
        exportMethod(myObj)
    }
        onShowSizeChange=(current, pageSize)=>{
          this.setState({
            pageSize: pageSize
          },()=>{
            this.searchClick()
          })
        }
        //页码改变时触发
        pageChange=(page)=>{
          this.setState({
            current: page
            },()=>{
              this.searchClick()
            })
        }
        rangeOnChange=(dates, dateStrings)=>{
          this.setState({ from: dateStrings[0],to:dateStrings[1] })
        }
    handleCancel=()=>{
      this.setState({
        scdvisible:false,
        btn2loading:false,
        btn1loading:false,
        fsttextarea:'',
        scdtextarea:'',
        thdvisible:''
      });
    }
    //重置按钮
    reloadClick=()=>{
      this.setState({
        startDate: '',
        endDate: '',
        from:'',
        to:'',
        order:'',
        name:'',
        phone:'',
        amount:'',
        description:'',
        operator:'',
        check:'',
        qc:'',
        type:'',
        status:'',
        finalStatus:''
      });
    
    }


    componentDidMount(){
      this.searchClick()
    }

    

      Order=(e)=>{
        var value = e.target.value;
        this.setState({
          order:value,
        });
      }
      Name=(e)=>{
        var value = e.target.value;
        this.setState({
          name:value,
        });
      }
      Phone=(e)=>{
        var value = e.target.value;
        this.setState({
          phone:value,
        });
      }
      Amount=(e)=>{
        var value = e.target.value;
        this.setState({
          amount:value,
        });
      }
      Description=(e)=>{
        var value = e.target.value;
        this.setState({
          description:value,
        });
      }
      Check=(e)=>{
        var value = e.target.value;
        this.setState({
          check:value,
        });
      }
      Operator=(e)=>{
        var value = e.target.value;
        this.setState({
          operator:value,
        });
      }
      QC=(e)=>{
        var value = e.target.value;
        this.setState({
          qc:value,
        });
      }
      Type=(e)=>{
        var value = e.target.value;
        this.setState({
          type:value,
        });
      }
      FinalStatus=(e)=>{
        var value = e.target.value;
        this.setState({
          finalStatus:value,
        });
      }
      Status=(e)=>{
        var value = e.target.value;
        this.setState({
          status:value,
        });
      }
      scdtxarea=(e)=>{
        var value = e.target.value;
        this.setState({
          scdtextarea:value,
        });
      }
      fsttxarea=(e)=>{
        var value = e.target.value;
        this.setState({
          fsttextarea:value,
        });
      }
      rangePickerChange = (moment, string) => {
        this.setState({
          startDate: string[0],
          endDate: string[1]
        })
      }
      Abnormal=(record)=>{
        this.setState({
          scdvisible:true,
          disval:record.id
        })
      }
      Cancel=(record)=>{
        this.setState({
          fstvisible:true,
          disval:record.id
        })
      }
      abnormal=()=>{
        if(this.state.scdtextarea==''){
          alert("remark cannot be empty")
          return false
        }
        this.setState({
          btn2loading:true
        })
        axios
        .post(`/api/bankPayment/remarkAbnormal`,{
        id: this.state.disval,
        remark:this.state.scdtextarea
      })
      .then(res=>{
        if (res.data.code === 200) {
          message.success(res.data.msg);
          this.setState({
            scdvisible:false,
            scdtextarea:'',
            btn2loading:false
          })

      } else if(res.data.code ==800){
          this.props.history.push({ pathname: "/"});
        }else{
          message.error(res.data.msg);
          this.setState({
            scdvisible:false,
            scdtextarea:'',
            btn2loading:false
          })
        }
      })
      this.searchClick()
      }
      cancel=()=>{
        if(this.state.fsttextarea==''){
          alert("remark cannot be empty")
          return false
        }
        this.setState({
          btn1loading:true
        })  
        axios
        .post(`/api/bankPayment/cancelAbnormal`,{
        id: this.state.disval,
        remark:this.state.fsttextarea
      })
      .then(res=>{
        if (res.data.code === 200) {
          message.success(res.data.msg);
          this.setState({
            fstvisible:false,
            fsttextarea:'',
            btn1loading:false
          })

      } else if(res.data.code ==800){
          this.props.history.push({ pathname: "/"});
        }else{
          message.error(res.data.msg);
          this.setState({
            fstvisible:false,
            fsttextarea:'',
            btn1loading:false
          })
        }
      })
      this.searchClick()
      }
      searchClick=()=>{
        this.setState({
          boolean: true
        })
        axios
        .post(`/api/bankPayment/offlineManageList`,{

        pageSize: this.state.pageSize,
        current: this.state.current,
        orderNo:this.state.order,
        name:this.state.name,
        mobile:this.state.phone,
        amount:this.state.amount,
        status:this.state.status,
        operateBeginTime:this.state.from,
        operateEndTime:this.state.to,
        checkNo:this.state.check,
        operator:this.state.operator,
        qc:this.state.qc,
        beginTime:this.state.startDate,
        endTime:this.state.endDate,
        finalStatus:this.state.finalStatus,
        type:this.state.type
      })
      .then(res=>{
        if (res.data.code === 200) {
          const data1 = [];
          const data = res.data.data.resultData
          if(data.length<1){
            this.setState({
            boolean: false
            })
          }else{
            for (let i = 0; i < data.length; i++) {
              data[i].key = i;
              data1.push(data[i]);
            }
          }
          this.setState({
            tableData: data1,
            total: res.data.data.page.total,
            boolean: false
          })
      } else if(res.data.code ==800){
          this.props.history.push({ pathname: "/"});
        }else{
          // message.error(res.data.msg);
        }
      })
      }

      record=(record)=>{
        this.setState({
          thdvisible:true
        })
        axios
        .post(`/api/bankPayment/remarkRecord`,{
          id:record.id
      })
      .then(res=>{
        if (res.data.code === 200) {
          const data1 = [];
          const data = res.data.data
            for (let i = 0; i < data.length; i++) {
              data[i].key = i;
              data1.push(data[i]);
            }
          this.setState({
            recordDate: data1,
          })
      } else if(res.data.code ==800){
          this.props.history.push({ pathname: "/"});
        }else{
          // message.error(res.data.msg);
        }
      })
      }
    render() {
        const record = [
        {
            title: 'QC time',
            dataIndex: 'operateTime',
            key: 'operateTime',
            render: (value) => {
              let timeaudit= new Date(value).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
              let auditimes=moment(new Date(timeaudit)).format("YYYY-MM-DD");
              return auditimes
          }
        },
        {
            title: 'QC',
            dataIndex: 'operator',
            key: 'operator',
        },
        {
          title: 'Remark',
          dataIndex: 'remark',
          key: 'remark',
      },
      {
        title: 'Operate',
        dataIndex: 'operate',
        key: 'operate',
        render: (value) => {
          if(value==0){
            return 'Cancel cleared'
          }
           else if(value==1){
            return 'cleared'
           }else if(value==2){
            return 'manual associate'
           }else {
            return 'Refund'
           }
      }
    },
    ];
      const columns = [
        {
          title: 'Check No',
          dataIndex: 'indent_no',
          key: 'indent_no',
    
        },
        {
          title: 'Name',
          dataIndex: 'first_name',
          key: 'first_name',
     
        },
        {
          title: 'Phone',
          dataIndex: 'mobile',
          key: 'mobile',
        },
        {
          title: 'Order No',
          dataIndex: 'loan_record_no',
          key: 'loan_record_no',
  
        },
        {
          title: 'Description',
          dataIndex: 'description',
          key: 'description',
          width:'50',
          render: (record) => {
            return (
              <Tooltip title={record}>
                <div className="twoEllipsis">
                  {record}
                </div>
              </Tooltip>
            )
        }
        },
        {
          title: 'Amount',
          dataIndex: 'credit',
          key: 'credit',
          render: (value)=> {
            var num = value/100;
            num = num + '';
            if(num.indexOf(".")!=-1){
              return num;
              }else{
                var str = "";
                for(var i=num.length- 1,j=1;i>=0;i--,j++){
                    if(j%3==0 && i!=0){//每隔三位加逗号，过滤正好在第一个数字的情况
                        str+=num[i]+",";//加千分位逗号
                        continue;
                    }
                    str+=num[i];//倒着累加数字
                }
                return str.split('').reverse().join("");
              }
             
            
        }
        },
        {
          title: 'Effective date',
          dataIndex: 'effective_date',
          key: 'effective_date',
          render: (value) => {
              if(value){
                let timeaudit= new Date(value).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
                let auditimes=moment(new Date(timeaudit)).format("YYYY-MM-DD HH:mm:ss");
                return auditimes
              }else{
                return ''
              }
            }
        },
        {
          title: 'Operate time',
          dataIndex: 'created_time',
          key: 'created_time',
          render: (value) => {
            if(value){
              let timeaudit= new Date(value).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
              let auditimes=moment(new Date(timeaudit)).format("YYYY-MM-DD HH:mm:ss");
              return auditimes
            }else{
              return ''
            }
          }
        },
        {
          title: 'Operator',
          dataIndex: 'operator',
          key: 'operator',
        },


        {
          title: 'State',
          dataIndex: 'status',
          key: 'status',
          render: (value) => {
            if(value==0){
              return 'Cancel cleared'
            }
             else if(value==1){
              return 'cleared'
             }else if(value==2){
              return 'manual associate'
             }else {
              return 'Refund'
             }
        }
        },
        {
          title: 'Final-State',
          dataIndex: 'finalStatus',
          key: 'finalStatus',
          render: (value) => {
            if(value==0){
              return 'Cancel cleared'
            }
             else if(value==1){
              return 'cleared'
             }else if(value==2){
              return 'manual associate'
             }else {
              return 'Refund'
             }
        }
        },
        {
          title: 'Type',
          dataIndex: 'type',
          key: 'type',
          render: (value) => {
            if(value==0){
              return 'Normal'
            }
             else{
              return 'Abnormal'
             }
        }
        },
        {
          title: 'QC remark',
          dataIndex: 'remark',
          key: 'remark',
          width:'100',
          render: (record) => {
            return (
              <Tooltip title={record}>
                <div className="twoEllipsis">
                  {record}
                </div>
              </Tooltip>
            )
        }
        },
        {
          title: 'Checker',
          dataIndex: 'qc',
          key: 'qc',
        },
        {
          title: 'Operation',
          dataIndex: 'operating',
          key: 'operating',
          width:70,
          id:'id',
          uid:'uid',
          fixed:'right',
          render: (text, record, index) => (<span style={{ display: 'flex' , flexDirection: 'row'}}> 
           <Button type="primary"    className="detailsbutton common_color" size="small" style={{display:record.type==0?"block":"none"}} onClick={()=>this.Abnormal(record)}>
              Abnormal
           </Button>
             <Button type="primary"    className="detailsbutton common_color"style={{display:record.type==1?"block":"none"}} size="small"  onClick={()=>this.Cancel(record)}>
             Cancel
          </Button>
           <Button type="primary"    className="detailsbutton common_color" size="small"  onClick={()=>this.record(record)}>
              QC Record
           </Button>
         
           </span>)
        },
      ];
      return (
        <div className="credit_order">
          <div className="myorder_content">
          <div className="flutter">
            <div className="top">
              <div className="top_left" style={{width:'19%',flexShrink:1}}>
                <input type="text" placeholder='Order No' value={this.state.order} onChange={this.Order} className="modal_input width"/>
              </div>
              <div className="top_right"style={{width:'19%',flexShrink:2}} >
                  <input type="text" placeholder='Name' value={this.state.name} onChange={this.Name} className="modal_input width"/>
              </div>

              <div className="top_mid" style={{width:'19%',flexShrink:1}}>
              <input type="text" placeholder='Phone' value={this.state.phone} onChange={this.Phone} className="modal_input width"/>

              </div>

              <div className="mid_right" style={{width:'19%'}}>
                <input type="text" placeholder='Amount' value={this.state.amount} onChange={this.Amount} className="modal_input width"/>
              </div>

              <div className="mid_left" style={{width:'19%'}}>
                <input type="text" placeholder='Description' value={this.state.description} onChange={this.Description} className="modal_input width"/>
              </div>
            </div>
      
            <div className="order_mid" style={{justifyContent:"center"}}> 
            
              <div className="top_left" style={{width:'19%',flexShrink:1}}>
              <div className="rangepick">
                    <RangePicker
                      className='width'
                      style={{ width: '80%' }}
                      ranges={{
                      Today: [moment(), moment()],
                      'This Month': [moment().startOf('month'), moment().endOf('month')],
                      }}
                      showTime
                      disabledDate={this.handleData}
                      format={dateFormat}
                      onChange={this.rangeOnChange}
                      placeholder={['Operate start','Operate end']}
                      value={this.state.from===undefined||this.state.to===undefined||this.state.from===""||this.state.to===""?null:[moment(this.state.from, dateFormat), moment(this.state.to, dateFormat)]}
                    />
                </div>              </div>
              <div className="top_right"style={{width:'19%',flexShrink:2}} >
                  <input type="text" placeholder='Check No' value={this.state.check} onChange={this.Check} className="modal_input width"/>
              </div>

              <div className="top_mid" style={{width:'19%',flexShrink:1}}>
              <input type="text" placeholder='Operator' value={this.state.operator} onChange={this.Operator} className="modal_input width"/>

              </div>

              <div className="mid_right" style={{width:'19%'}}>
                <input type="text" placeholder='Checker' value={this.state.qc} onChange={this.QC} className="modal_input width"/>
              </div>

              <div className="mid_left" style={{width:'19%'}}>
                <select className="form_select width"  value={this.state.type} onChange={this.Type}>
                  <option value="">Type(all)</option>
                  <option value="1">Abnormal</option>
                  <option value="0">Normal</option>
                </select>
              </div>
             
            
            </div>    
            <div className="order_mid" style={{justifyContent:"center"}}> 
            
              <div className="top_left" style={{width:'19%',flexShrink:1}}>
              <div className="rangepick">

                    <RangePicker
                      onChange={ this.rangePickerChange }
                      disabledDate={ this.disabledDate }
                      style={{ width: '80%' }}
                      placeholder={['Effective date','Effective date']}
                      onCalendarChange={ this.calendarChange }
                      onOpenChange={ this.clearFirst }
                      value={this.state.startDate===undefined||this.state.endDate===undefined||this.state.startDate===""||this.state.endDate===""?null:[moment(this.state.startDate, dateFormat), moment(this.state.endDate, dateFormat)]}

                    />
                </div>              </div>

              <div className="mid_left" style={{width:'19%'}}>
                <select className="form_select width" value={this.state.finalStatus} onChange={this.FinalStatus}>
                  <option value="">final-state(all)</option>
                  <option value="0">Cancel cleared</option>
                  <option value="1">cleared</option>
                  <option value="2">manual associate</option>
                  <option value="3">Refund</option>
                </select>
              </div>
              <div className="mid_left" style={{width:'19%'}}>
                <select className="form_select width" value={this.state.status} onChange={this.Status}>
                  <option value="">state(all)</option>
                  <option value="0">Cancel cleared</option>
                  <option value="1">cleared</option>
                  <option value="2">manual associate</option>
                  <option value="3">Refund</option>
                </select>
              </div>
              <div  style={{marginTop:0,width:"38%"}}>
                <Button type="primary" style={{width:'25%',marginRight:20,height:40,border:'none'}} className="common_color_fst"  onClick={this.searchClick}>Search</Button>
                <Button type="primary" style={{width:'25%',marginRight:20,height:40,border:'none'}} className="common_color_scd"  onClick={this.reloadClick}>Refresh</Button>
                <Button type="primary" style={{width:'25%',marginRight:20,height:40,border:'none'}} className="common_color_thd"  onClick={this.export}>
                    Download
                </Button>
              </div> 
            </div>    
               
          
          </div>
          </div>
          <Table columns={columns} dataSource={this.state.tableData} scroll={{x:1500}} loading={this.state.boolean} onChange={this.handleChange} pagination={false} />
          <Modal
          title="QC Remark"
          visible={this.state.fstvisible}
          onOk={this.cancel}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={this.state.btn1loading} onClick={this.cancel}>
              Confirm
            </Button>,
          ]}
        >
        <input value={this.state.adjustdisval} style={{display:'none'}}/>
        <div className="model_last">
        <span  className="long record_span">remark:</span>
        <textarea style={{margin:0}}  className='modal_textarea' value={this.state.fsttextarea} onChange={this.fsttxarea}/>
        </div>
        </Modal>   
        <Modal
          title="QC Remark"
          visible={this.state.scdvisible}
          onOk={this.abnormal}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={this.state.btn2loading} onClick={this.abnormal}>
              Confirm
            </Button>,
          ]}
        >
        <input value={this.state.adjustdisval} style={{display:'none'}}/>
        <div className="model_last">
        <span  className="long record_span">remark:</span>
        <textarea style={{margin:0}} className='modal_textarea' value={this.state.scdtextarea} onChange={this.scdtxarea}/>
        </div>
        </Modal>
        <Modal
          title="Record"
          visible={this.state.thdvisible}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Cancel
            </Button>,
          ]}
          >
          <Table columns={record} dataSource={this.state.recordDate} onChange={this.phoneHandleChange} pagination={false} />
        </Modal>
        <Pagination
          showQuickJumper
          defaultCurrent={1} 
          current={this.state.current}
          total={this.state.total} 
          onChange={this.pageChange} 
          showTotal={showTotal}
          style={{textAlign:'right',marginTop:20}}
          pageSize={this.state.pageSize}
          showSizeChanger={true}
          onShowSizeChange={this.onShowSizeChange} //pagesize改变时回调函数
          pageSizeOptions={["10","50","100"]}   //设置pagesize区间
          />
        </div>
      );
    }
  }
  export default withRouter(OfflineQC)