/*eslint-disable*/
import React from "react";
import { Table, Pagination } from "antd";
import "./index.css";
import axios from "axios";
import moment from "moment";

function showTotal(total) {
  return `Total ${total} items`;
}

export default class Tab4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      current: 1,
      total: 1,
      repaymentBoolean: true,
    };
  }
  componentDidMount() {
    this.tableonload();
  }

  //页码改变时触发
  pageChange = (page) => {
    this.setState(
      {
        current: page,
      },
      () => {
        this.tableonload();
      }
    );
  };

  //table初始化
  tableonload = () => {
    let uid = this.props.uid;
    //根据用户ID查询所有还款记录
    axios
      .post(`/api/userRepay/getRepayFlowByUid`, {
        uid: uid,
        current: this.state.current,
        pageSize: 10,
      })
      .then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data.resultDate;
          let data2 = [];
          if (data.length < 1) {
            this.setState({
              repaymentBoolean: false,
            });
          }
          for (let i = 0; i < data.length; i++) {
            let time1 = data[i].completionTime;
            let a =
              new Date(time1).getTime() +
              new Date().getTimezoneOffset() * 60 * 1000 -
              0 * 60 * 60 * 1000;
            let times = moment(new Date(a)).format("YYYY-MM-DD HH:mm:ss");
            let time2 = data[i].createdTime;
            let b =
              new Date(time2).getTime() +
              new Date().getTimezoneOffset() * 60 * 1000 -
              0 * 60 * 60 * 1000;
            let times1 = moment(new Date(b)).format("YYYY-MM-DD HH:mm:ss");
            data2.push({
              key: i,
              ordernum: data[i].loanRecordNo,
              tradingnumber: data[i].transactionNo,
              paymentmethod: data[i].wayCode,
              transactionamount: data[i].transactionAmount / 100,
              transactiontime: times,
              remarks: data[i].remark,
              Ordercreation: times1,
            });
            this.setState({
              repaymentData: data2,
              total: res.data.data.page.total,
              repaymentBoolean: false,
            });
          }
        }
      });
  };

  render() {
    //第二个table
    const repaymentColumns = [
      {
        title: "Order No.",
        dataIndex: "ordernum",
        key: "ordernum",
      },
      {
        title: "trading number",
        dataIndex: "tradingnumber",
        key: "tradingnumber",
      },
      {
        title: "payment method",
        dataIndex: "paymentmethod",
        key: "paymentmethod",
      },
      {
        title: "transaction amount",
        dataIndex: "transactionamount",
        key: "transactionamount",
      },
      {
        title: "transaction time",
        dataIndex: "transactiontime",
        key: "transactiontime",
      },
      {
        title: "remarks",
        dataIndex: "remarks",
        key: "remarks",
        render(text) {
          if (text && text.includes("已撤销")) {
            return <span style={{ color: "red" }}>{text}</span>;
          } else {
            return text;
          }
        },
      },
      {
        title: "Order creation time",
        dataIndex: "Ordercreation",
        key: "Ordercreation",
      },
    ];

    return (
      <div className="order_infor">
        <h2>Repayment Information</h2>
        <Table
          columns={repaymentColumns}
          dataSource={this.state.repaymentData}
          loading={this.state.repaymentBoolean}
          pagination={false}
        />
        <Pagination
          // showQuickJumper
          defaultCurrent={2}
          current={this.state.current}
          total={this.state.total}
          onChange={this.pageChange}
          showTotal={showTotal}
          style={{ textAlign: "right", marginTop: 20 }}
          // showSizeChanger={true}
        />
      </div>
    );
  }
}
