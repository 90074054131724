/*eslint-disable*/
import React from 'react';
import { Input,Button,Table,Modal,Radio,Pagination,Tooltip,message,Tabs,DatePicker } from 'antd';
import './index.css';
import axios from 'axios'
import {Link} from 'react-router-dom'
import moment from 'moment';
import location_red from '../../../../public/img/collection_imgleft.png';
import right from '../../../../public/img/collection_imgright.png';
import {withRouter} from 'react-router-dom'
import EventEmitter from '../../../utils/events'

const dateFormat = 'YYYY-MM-DD'||undefined;
const { RangePicker } = DatePicker;
var Select = [];
const { TabPane } = Tabs;
const DateFormat = 'YYYY-MM-DD'||undefined;

var whitchshow = []
function callback(key) {
    console.log(key);
  }

  function showTotal(total) {
      return `Total ${total} items`;
    }
 class Activity extends React.Component {
    constructor(props){
      super(props);
      this.state={
        number:'',
        searchVal:'',
        current:1,
        length:0,
        boolean:true,
        topboolean:true,
        from:'',
        to:'',
        pageSize:10,
        isshow:true,
        ishide:false,
        scdSelect:'',
        id:'',
        fstSelect:'',
        modaltitle:'新增'
      }
     
    }
    //选择table触发
    handleChange = (pagination, filters, sorter) => {
      this.setState({
      });
    };
    rangeOnChange=(dates, dateStrings)=>{
      this.setState({ from: dateStrings[0],to:dateStrings[1] })
    }
    //页码改变时触发
    pageChange=(page)=> { 
      
    this.setState({
      current: page
      },()=>{
        this.searchClick()
      })
    
    } 

    getselect=()=>{
      axios
      .post(`/api/appshadow/findAppShadowList`,{

    })
    .then(res=>{
       Select=[]
      if(res.data.code === 200){
        for(let i=0;i<res.data.data.list.length;i++ ){
          
            if(i==0){
              Select.push(<option value=''>请选择</option> )
              }
              Select.push(       
              <option value={res.data.data.list[i].id}>{res.data.data.list[i].appCode}</option>
          )
          
        this.setState({
          Select:Select
        })
        }
      }else if(res.data.code ==800){
        this.props.history.push({ pathname: "/"});
      }else{
      }
    })
    }
    dateonChange = (date, dateString) =>{
        this.setState({ datepiker:dateString})
      }
      dateonChange2 = (date, dateString) =>{
        this.setState({ datepiker2:dateString})
      }
      componentDidMount(){
        this.searchClick()
        this.getselect();
        EventEmitter.on('getList',this.searchClick)
    }
    componentWillUnmount (){
      EventEmitter.off('getList')
  
    }
  modelselectOne(e){
    var value = e.target.value;
    this.setState({
      modalscdSelect:value,
    });
  }
  reload=()=>{
    this.setState({
      fstInput:'',
      scdInput:'',
      scdSelect:'',
      fstSelect:'',
      thdSelect:''
    },()=>{
      this.searchClick()
    })
  }
 
   //搜索
   searchClick = () => {
    axios
        .post(`/api/sysactivity/findSysActivity`, {

            indentNo: this.state.fstInput,
            activityName: this.state.scdInput,
            pageSize: this.state.pageSize,
            current: this.state.current,
            status: this.state.scdSelect,
            couponIndentNo:this.state.fstSelect,
            appshadowId:this.state.thdSelect,
        })
        .then(res => {
            if (res.data.code === 200) {

                const data1 = [];
                const data = res.data.data.list

                for (let i = 0; i < data.length; i++) {
                    data[i].key = i;
                    data1.push(data[i]);
                }
                this.setState({
                  tableData: data1,
                  total: res.data.data.total,
                  boolean: false
                })
            } else if (res.data.code == 800) {
                this.props.history.push({pathname: "/"});
            } else {
            }
        })
}
  add=()=>{
    let id = 0
    window.location='/#/marketing_management/activity/inside?id='+id+'&'
    let test = [{path: '/marketing_management/activity/inside',key: `/marketing_management/activity/inside`,number: `${id}`}]
    EventEmitter.trigger('btn', test[0])
    EventEmitter.trigger('idanduid', test[0].number)
  }
  fsthandleOk=()=>{
    console.log(this.state.id)
    axios
      .post(`/api/remindData/changeOverdueGrade`,{
        id:this.state.id,
        overdueGrade: this.state.fstSelect,
    })
    .then(res=>{
      if(res.data.code === 200){
        this.setState({
          boolean:true
        })
        this.onloadTable()
      }
      else if(res.data.code ==800){
        this.props.history.push({ pathname: "/"});
      }else{

      }
    })
    this.setState({
      fstvisible: false,
      fstSelect:'',
      scdSelect:'',
      fouSelect:'',
      modalscdSelect:'',
      from:undefined,
      to:undefined,
      id:''
    });
  }

    handleCancel = e => {
      console.log(e);
      this.setState({
        fstvisible: false,
       
      });
    };
    edit=(record)=>{
      let id = record.id
      window.location='/#/marketing_management/activity/edit?id='+id+'&'
      let test = [{path: '/marketing_management/activity/edit',key: `/marketing_management/activity/edit/${id}`,number: `${id}`}]
      EventEmitter.trigger('btn', test[0])
      EventEmitter.trigger('idanduid', test[0].number)
    }

    see=(record)=>{
      let id = record.id
      window.location='/#/marketing_management/activity/see?id='+id+'&type='+1
      let test = [{path: '/marketing_management/activity/see',key: `/marketing_management/activity/inside/${id}`,number: `${id}`}]
      EventEmitter.trigger('btn', test[0])
      EventEmitter.trigger('idanduid', test[0].number)
    }
      
    data=(record)=>{
      let id = record.id
      window.location='/#/marketing_management/data/index?id='+id+'&'
      let test = [{path: '/marketing_management/data/index',key: `/marketing_management/data/index/${id}`,number: `${id}`}]
      EventEmitter.trigger('btn', test[0])
      EventEmitter.trigger('idanduid', test[0].number)
    } 

    redistribution=()=>{
      this.setState({
        thdvisible: true,
        });
    }

    selectFst=(e)=>{
      var value = e.target.value;
      this.setState({
        fstSelect:value,
      });
    }
    fstOnchangeInput=(e)=>{
      var value = e.target.value;
      this.setState({
        fstInput:value,
      });
    }
    scdOnchangeInput=(e)=>{
      var value = e.target.value;
      this.setState({
        scdInput:value,
      });
    }
    selectScd=(e)=>{
      var value = e.target.value;
      this.setState({
        scdSelect:value,
      });
    }
    selectThd=(e)=>{
      var value = e.target.value;
      this.setState({
        thdSelect:value,
      });
    }

      
    render() {

      const columns = [
        {
          title: '关联产品',
          dataIndex: 'appName',
          key: 'appName',

        },
        {
          title: '活动ID',
          dataIndex: 'indentNo',
          key: 'indentNo',
        },
        {
          title: '活动标题',
          dataIndex: 'activityName',
          key: 'activityName',
        },
        {
          title: '优惠券ID',
          dataIndex: 'couponIndentNo',
          key: 'couponIndentNo',
        },
        {
          title: '优惠券名称',
          dataIndex: 'couponName',
          key: 'couponName',
        },
        {
          title: '活动有效期',
          dataIndex: 'enabledDate',
          key: 'enabledDate',
          render: (value,record) => {
          let timeaudit1= new Date(record.disabledDate).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
          let endTime=moment(new Date(timeaudit1)).format("YYYY-MM-DD HH:mm:ss").substring(0,10);
          return value.substring(0,10) + ' ~ ' +  endTime
          }
        },
        {
          title: '发放对象',
          dataIndex: 'offerEvens',
          key: 'offerEvens',
          render: (value) => {
            if(value==1){
              return '用户属性'
            }else if(value==2){
              return '用户行为'
            }else if(value==3){
              return '特定用户'
            }else if(value==4){
              return '批量导入'
            }
        }
        },
        {
            title: '发放形式',
            dataIndex: 'offerType',
            key: 'offerType',
            render: (value) => {
              if(value==1){
                return '自动发放'
              }else if(value==2){
                return '手动领取'
              }
          }
          },
          {
            title: '创建时间',
            dataIndex: 'createdTime',
            key: 'createdTime',
            render: (value,record) => {
              let timeaudit1= new Date(value).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
              let endTime=moment(new Date(timeaudit1)).format("YYYY-MM-DD HH:mm:ss").substring(0,10);
    
              return endTime
              }
          },
          {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            render: (value) => {
              if(value==0){
                return '下线'
              }else if(value==1){
                return '上线'
              }
          }
          },
       
        {
          title: '操作',
          dataIndex: 'operating',
          key: 'operating',
          id:'id',
          render: (text, record, index) => (<span style={{ display: 'flex' , flexDirection: 'row', justifyContent:'center',}}> 
            <a onClick={() => this.edit(record)} className='activity_edit common_color'>编辑</a>
            <a onClick={() => this.see(record)}  className='activity_other common_color'>预览</a>
             <a onClick={() => this.data(record)}  className='activity_other common_color' style={{marginLeft:0}}>统计</a> 
            </span>)
        },
      ];
      return (
        <div className="credit_order" style={{overflow:'hidden'}}>
          

          <div className="content_mid">
            <div className="mid_bot">
            <div className="mid_left" style={{width:'20%'}}>
                <span style={{width: 50,}} className="description left">活动ID</span>
               <input type="text" placeholder='输入ID' value={this.state.fstInput} onChange={this.fstOnchangeInput} className="modal_input width"  style={{width: 200,}}/>
            </div>
              <div className="mid_mid" style={{width:'21%',flexShrink:2,marginLeft:'1%',marginRight:'1%'}}>
                <div className="rangepick">
                    <span style={{width: 65,}}className="description mid_span">活动名称</span>
                    <input type="text" placeholder='输入名称' value={this.state.scdInput} onChange={this.scdOnchangeInput} className="modal_input width"  style={{width: 200,}}/>
                </div>
              </div>

              <div className="mid_right" style={{width:'21%'}}>
                  <span  style={{width:70,}} className="description right">优惠券ID</span>
                  <input type="text" placeholder='输入名称' className="modal_input width"  style={{width: 200,}} value={this.state.fstSelect} onChange={this.selectFst}/>
              </div>
              <div className="mid_mid" style={{width:'21%',flexShrink:2,marginLeft:'1%',marginRight:'1%'}}>
                <div className="rangepick">
                    <span style={{width: 65,}} className="description mid_span">活动状态</span>
                    <select  style={{width: 200,}} className="coupon_form_select" value={this.state.scdSelect} onChange={this.selectScd}>
                  <option value="">请选择</option>
                  <option value="1">上线</option>
                  <option value="0">下线</option>
                  

                </select>
                </div>
              </div>

              <div className="mid_right" style={{width:'20%'}}>
                  <span  style={{width: 65,}} className="description right">关联产品</span>
                  <select  style={{width: 200,}} className="coupon_form_select" value={this.state.thdSelect} onChange={this.selectThd}>
                    {Select}
                </select>
              </div>
            </div>
              <div className="bottom">
                <Button type="primary" style={{width:170,marginRight:20,height:40,border:'none'}} className="common_color" onClick={this.searchClick.bind(this)}>搜索</Button>
                <Button type="primary" style={{width:170,height:40,marginRight:20,border:'none'}}className="common_color_scd" onClick={this.reload}>重置</Button>
              </div>    
            </div>
          <div className='activity_out_div'>
            <div style={{display:'flex',justifyContent:'flex-end',paddingTop:30,marginBottom:30}}><Button type="primary" style={{width:170,height:40,marginRight:20,border:'none'}} className="common_color" onClick={this.add}>添加</Button></div>
            <Table columns={columns}  dataSource={this.state.tableData}  loading={this.state.boolean} onChange={this.handleChange} pagination={false} />
          </div>
          


        <Pagination
         onShowSizeChange={this.onShowSizeChange} //pagesize改变时回调函数
         defaultCurrent={1} 
         current={this.state.current}
         total={this.state.total} 
         onChange={this.pageChange} 
         pageSize={this.state.pageSize}
         showTotal={showTotal}
         style={{textAlign:'right',marginTop:20}}
          />
           
        </div>
      );
    }
  }
  export default withRouter(Activity)