import React from "react";
import { Input, Button, Table, Modal, Pagination, Row, Col } from "antd";
import moment from "moment";
import axios from "axios";
import "./index.css";
import { withRouter } from "react-router-dom";
import EventEmitter from "../../../utils/events";
import { thousandSignNumber, formatT0Time, getCollectionResult } from "../../../utils/index";

function showTotal(total) {
  return `Total ${total} items`;
}

const { Search } = Input;
class MyReminOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      from: "",
      to: "",
      timeSelect: "",
      fstInput: "",
      current: 1,
      debit_money: "",
      pageSize: 10,
      reviewbutton: { display: "block" },
      detailsbutton: { display: "block" },
      todayKpiData: [],
      monthKpiData: [],
    };
  }
  //重置按钮
  reloadClick(e) {
    this.setState(
      {
        scdSelect: "",
        scdSelectt: "",
        fouSelect: "",
        searchVal: "",
        mobileNum: "",
        timeSelect: "",
        id: "",
        reduceAmt_val: "",
        reduceRson: "",
        fsttextarea: "",
        boolean: true,
        fstInput: "",
      },
      () => {
        this.searchClick();
      }
    );
  }
  fstInputChange = (e) => {
    var value = e.target.value;
    this.setState({
      fstInput: value.replace(/^(0+)|[^\d]+/g, ""),
    });
  };
  //第个modal提交按钮
  fsthandleOk = (e) => {
    if (this.state.debit_money < 100) {
      alert("Debit amount must exceed 100");
      return false;
    }
    var data = {};
    if (this.state.debit_money == this.state.mostvalue) {
      data = {
        id: this.state.fstinpt,
        uid: this.state.scdinpt,
      };
    } else {
      data = {
        money: this.state.debit_money,
        id: this.state.fstinpt,
        uid: this.state.scdinpt,
      };
    }
    axios.post(`/api/loanUrge/withhold`, data).then((res) => {
      if (res.data.code == 200) {
        alert(res.data.msg);
        this.searchClick();
      } else if (res.data.code == 800) {
        this.props.history.push({ pathname: "/" });
      } else {
        alert(res.data.msg);
      }
    });
    this.setState({
      fstvisible: false,
    });
  };
  debitMoney = (e) => {
    var value = e.target.value.replace(/[^0-9]/g, "");
    if (value > this.state.mostvalue) {
      value = this.state.mostvalue;
    }
    this.setState({
      debit_money: value,
    });
  };
  //订单输入框
  searchIpt(e) {
    var value = e.target.value;
    this.setState({ searchVal: value.replace(/\s+/g, "") });
  }
  //号码输入框
  searchIpt1(e) {
    var value = e.target.value;
    this.setState({ mobileNum: value.replace(/\s+/g, "") });
  }
  //时间控件
  rangeOnChange = (dates, dateStrings) => {
    this.setState({ from: dateStrings[0], to: dateStrings[1] });
  };
  //页码改变时触发
  pageChange = (page) => {
    this.setState(
      {
        current: page,
      },
      () => {
        this.searchClick();
      }
    );
  };
  onShowSizeChange = (current, pageSize) => {
    this.setState(
      {
        pageSize: pageSize,
      },
      () => {
        this.searchClick();
      }
    );
  };

  //搜索按钮
  searchClick = () => {
    this.setState({
      boolean: true,
    });
    axios
      .post(`/api/remindData/getMyRemindOrderManage`, {
        pageSize: this.state.pageSize,
        current: this.state.current,
        indentNo: this.state.searchVal,
        mobile: this.state.mobileNum,
        appCode: this.state.fouSelect,
        searchTime: this.state.timeSelect,
        remainBalance: this.state.fstInput,
      })
      .then((res) => {
        if (res.data.code === 200) {
          let data = res.data.data.resultDate;
          this.setState({
            tableData: data,
            length: res.data.data.page.total,
            boolean: false,
          });
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        } else {
        }
      });
  };
  selectTime = (e) => {
    var value = e.target.value;
    this.setState({
      timeSelect: value,
    });
  };
  text(record, item) {
    window.location =
      "/#/details/index?id=" +
      record.id +
      "&remind=" +
      true +
      "&uid=" +
      record.uid;
    let test = [
      {
        path: "/details/index",
        key: `/details/index/${record.id}/${record.uid}`,
        number: `${record.id}/${record.uid}`,
      },
    ];
    EventEmitter.trigger("btn", test[0]);
    EventEmitter.trigger("idanduid", test[0].number);
  }
  componentDidMount() {
    this.searchClick();
    axios.get(`/api/remindData/getMyRemindOrderKpi/1`).then((res) => {
      if (res.data.code == 200) {
        this.setState({
          todayKpiData: res.data.data.resultDate,
        });
      } else if (res.data.code == 800) {
        this.props.history.push({ pathname: "/" });
      }
    });

    axios.get(`/api/remindData/getMyRemindOrderKpi/2`).then((res) => {
      if (res.data.code == 200) {
        this.setState({
          monthKpiData: res.data.data.resultDate,
        });
      } else if (res.data.code == 800) {
        this.props.history.push({ pathname: "/" });
      }
    });
  }
  //控制审核按钮model
  showFstModal = (record) => {
    var id = record.id;
    var uid = record.uid;
    this.setState({
      fstinpt: id,
      scdinpt: uid,
      fstvisible: true,
      debit_money: record.fee,
      mostvalue: record.fee,
    });
    // this.gradeChange()
  };

  selectFou(e) {
    var value = e.target.value;
    this.setState({
      fouSelect: value,
    });
  }
  selectThd = () => {};
  handleCancel = (e) => {
    console.log(e);
    this.setState({
      thdvisible: false,
      fstvisible: false,
      scdvisible: false,
      scdSelect: "",
      id: "",
      selectedRowKeys: "",
    });
  };
  render() {
    const columns = [
      {
        title: "Order No",
        dataIndex: "indent_no",
        key: "indent_no",
      },
      {
        title: "Customer name",
        dataIndex: "first_name",
        key: "first_name",
        width: 50,
      },
      {
        title: "phone",
        dataIndex: "mobile",
        key: "mobile",
      },
      {
        title: "BVN",
        dataIndex: "dentity_card",
        key: "dentity_card",
      },
      {
        title: "loan amount",
        dataIndex: "balance",
        key: "balance",
        render: (text, record) => thousandSignNumber(text / 100),
      },
      {
        title: "loan term",
        dataIndex: "cycle",
        key: "cycle",
      },
      {
        title: "should repayment amount",
        dataIndex: "amountDue",
        key: "amountDue",
        render: (text, record) => thousandSignNumber(text / 100),
      },
      {
        title: "should repayment time",
        dataIndex: "shouldback_time",
        key: "shouldback_time",
        render: (text, record) => formatT0Time(text),
      },
      {
        title: "repayment amount",
        dataIndex: "actualback_amt",
        key: "actualback_amt",
        render: (text, record) => thousandSignNumber(text / 100),
      },
      {
        title: "remaining balance",
        dataIndex: "fee",
        key: "fee",
        render: (text, record) => thousandSignNumber(text / 100),
      },
      {
        title: "Successful Repayment Count",
        dataIndex: "repayment_count",
        key: "repayment_count",
      },
      {
        title: "Allocate time",
        dataIndex: "allocate_time",
        render: (text, record) => formatT0Time(text),
      },
      {
        title: 'Last Remind time',
        dataIndex: 'remindedDate',
        key: 'remindedDate',
        render: (text, record) => formatT0Time(text),
      },
      {
        title: 'Reminded result',
        dataIndex: 'urgeResut',
        key: 'urgeResut',
        render: (text, record) => getCollectionResult(text),
      },
      {
        title: 'Reminded details',
        dataIndex: 'remind_details',
        key: 'remind_details',
      },
      {
        title: "Operation",
        dataIndex: "operating",
        key: "operating",
        width: 70,
        id: "id",
        uid: "uid",
        fixed: "right",
        render: (text, record, index) => (
          <span style={{ display: "flex", flexDirection: "row" }}>
            {/* <Button
              type="primary"
              style={this.state.reviewbutton}
              className="reviewbutton"
              size="small"
              onClick={() => this.showFstModal(record)}
            >
              debit
            </Button> */}
            <Button
              type="primary"
              style={this.state.detailsbutton}
              className="detailsbutton"
              size="small"
              onClick={() => this.text(record)}
            >
              details
            </Button>
          </span>
        ),
      },
    ];
    return (
      <div className="overduepaid">
        <div className="overduepaid_top">
          <div
            className="content_bot"
            style={{ textAlign: "left", marginBottom: 40 }}
          >
            <table className="content_bot">
              <thead>
                <tr style={{ height: 35, background: "#fafafa" }}>
                  <td
                    style={{
                      color: "#009688",
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                    KPI
                  </td>
                  <td>Allocate</td>
                  <td>Repaid</td>
                  <td>Repay rate</td>
                  <td>Extend order</td>
                  <td>Reminded</td>
                  <td>Not remind</td>
                  <td>Repay rate target</td>
                  <td>Order difference</td>
                </tr>
              </thead>
              <tbody style={{ color: "#009688", fontSize: 15 }}>
                <tr style={{ height: 35, borderBottom: "1px solid #e6f7ff" }}>
                  <td
                    style={{
                      color: "#FFFFFF",
                      background:
                        "linear-gradient(to right, #009688, #95ded7, #FFFFFF)",
                    }}
                  >
                    Today
                  </td>
                  <td>
                    {this.state.todayKpiData.allocate == null
                      ? "--"
                      : this.state.todayKpiData.allocate}
                  </td>
                  <td>
                    {this.state.todayKpiData.repaid == null
                      ? "--"
                      : this.state.todayKpiData.repaid}
                  </td>
                  <td>
                    {this.state.todayKpiData.repayRate == null
                      ? "--"
                      : this.state.todayKpiData.repayRate}
                  </td>
                  <td>
                    {this.state.todayKpiData.extendOrder == null
                      ? "--"
                      : this.state.todayKpiData.extendOrder}
                  </td>
                  <td>
                    {this.state.todayKpiData.reminded == null
                      ? "--"
                      : this.state.todayKpiData.reminded}
                  </td>
                  <td>
                    {this.state.todayKpiData.notRemind == null
                      ? "--"
                      : this.state.todayKpiData.notRemind}
                  </td>
                  <td>
                    {this.state.todayKpiData.repayRateTarget == null
                      ? "--"
                      : this.state.todayKpiData.repayRateTarget + "%"}
                  </td>
                  <td>
                    {this.state.todayKpiData.orderDifference == null
                      ? "--"
                      : this.state.todayKpiData.orderDifference}
                  </td>
                </tr>
                <tr style={{ height: 35, borderBottom: "1px solid #e6f7ff" }}>
                  <td
                    style={{
                      color: "#FFFFFF",
                      background:
                        "linear-gradient(to right, #1CA9BA, #6CC1CA, #FFFFFF)",
                    }}
                  >
                    This month
                  </td>
                  <td>
                    {this.state.monthKpiData.allocate == null
                      ? "--"
                      : this.state.monthKpiData.allocate}
                  </td>
                  <td>
                    {this.state.monthKpiData.repaid == null
                      ? "--"
                      : this.state.monthKpiData.repaid}
                  </td>
                  <td>
                    {this.state.monthKpiData.repayRate == null
                      ? "--"
                      : this.state.monthKpiData.repayRate}
                  </td>
                  <td>
                    {this.state.monthKpiData.extendOrder == null
                      ? "--"
                      : this.state.monthKpiData.extendOrder}
                  </td>
                  <td>
                    {this.state.monthKpiData.reminded == null
                      ? "--"
                      : this.state.monthKpiData.reminded}
                  </td>
                  <td>
                    {this.state.monthKpiData.notRemind == null
                      ? "--"
                      : this.state.monthKpiData.notRemind}
                  </td>
                  <td>
                    {this.state.monthKpiData.repayRateTarget == null
                      ? "--"
                      : this.state.monthKpiData.repayRateTarget + "%"}
                  </td>
                  <td>
                    {this.state.monthKpiData.orderDifference == null
                      ? "--"
                      : this.state.monthKpiData.orderDifference}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="overduepaid_mid">
            <Row className="search_form">
              <Col span={8}>
                <div className="search">
                  <p className="form_label">Order No:</p>
                  <Search
                    placeholder="Order No"
                    onSearch={(value) => console.log(value)}
                    style={{ width: 290 }}
                    value={this.state.searchVal}
                    onChange={this.searchIpt.bind(this)}
                  />
                </div>
              </Col>
              <Col span={8}>
                <div className="top_right">
                  <p className="form_label">Should back time:</p>
                  <select
                    className="form_select"
                    value={this.state.timeSelect}
                    onChange={this.selectTime.bind(this)}
                  >
                    <option value="">Today</option>
                    <option value="1">Tomorrow</option>
                    <option value="2">Day after tomorrow</option>
                  </select>
                </div>
              </Col>
              <Col span={8}>
                <div className="mid_left">
                  <p className="form_label">Phone:</p>
                  <Search
                    placeholder="Phone"
                    onSearch={(value) => console.log(value)}
                    style={{ width: 290 }}
                    value={this.state.mobileNum}
                    onChange={this.searchIpt1.bind(this)}
                  />
                </div>
              </Col>
            </Row>

            <div className="bottom">
              <Button
                type="primary"                
                className="common_color"
                style={{
                  width: 170,
                  marginRight: 20,
                  height: 40,
                  border: "none",
                }}
                onClick={this.searchClick.bind(this)}
              >
                Search
              </Button>
              {/* <Button
                type="primary"
                style={{
                  width: 170,
                  height: 40,
                  backgroundColor: "#1CA9BA",
                  border: "none",
                }}
                onClick={this.reloadClick.bind(this)}
              >
                Refresh
              </Button> */}
            </div>
          </div>
        </div>
        <div className="overdue_table">
          <Table
            columns={columns}
            dataSource={this.state.tableData}
            scroll={{ x: "100%" }}
            onChange={this.handleChange}
            pagination={false}
          />
          <Pagination
            showSizeChanger //展示改变pagesize样式
            onShowSizeChange={this.onShowSizeChange} //pagesize改变时回调函数
            pageSizeOptions={["10", "50", "100"]} //设置pagesize区间
            defaultCurrent={1}
            current={this.state.current}
            total={this.state.length}
            onChange={this.pageChange}
            pageSize={this.state.pageSize}
            showTotal={showTotal}
            style={{ textAlign: "right", marginTop: 20 }}
          />
        </div>
        <Modal
          title="debet"
          visible={this.state.fstvisible}
          onOk={this.fsthandleOk}
          onCancel={this.handleCancel}
        >
          <input
            type="text"
            value={this.state.fstinpt}
            style={{ display: "none" }}
          />
          <input
            type="text"
            value={this.state.scdinpt}
            style={{ display: "none" }}
          />
          <div className="both_div">
            <span>debit amount:</span>
            <input
              value={this.state.debit_money}
              onChange={this.debitMoney}
              className="reduce_inpt"
            />
          </div>
        </Modal>
      </div>
    );
  }
}
export default withRouter(MyReminOrder);
