import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import App from "./App";
import Home from "./pages/home/index";
import Welcome from "./components/welcome";
import OrderManagement from "./pages/credit_system/order_management/index";
import creditAuditime from "./pages/credit_system/credit_auditime/index";
import creditArrivetime from "./pages/credit_system/credit_arrivetime/index";
import MyOrder from "./pages/credit_system/my_order/index";
import settleOrder from "./pages/credit_system/settle_order/index";
import Login from "./pages/login/index";
import ModifyPassword from "./pages/modifyPassword/index";
import Details from "./pages/details/index";
import dayAudit from "./pages/credit_system/credit_auditime/day_audit";
import hourAudit from "./pages/credit_system/credit_auditime/hour_audit";
import dayArrive from "./pages/credit_system/credit_arrivetime/day_arrive";
import LoanSuccess from "./pages/order_management/loansuccess";
import Pending from "./pages/order_management/lending";
import LoanFail from "./pages/order_management/loanfail";
import TotalOrder from "./pages/order_management/total_order";
import UserInformation from "./pages/user_management/user_information";
import Blacklist from "./pages/user_management/blacklist";
import GreyList from "./pages/user_management/greylist";
import BankCertificate from "./pages/user_management/bank_certificate";
import SystemMessage from "./pages/message_management/system_message";
import PendingCard from "./pages/message_management/pendding_card";
import PendingLoan from "./pages/payment_management/pending_loan";
import RepaymentPlan from "./pages/payment_management/repayment_plan";
import collectionmanagement from "./pages/collection/getOrderManagement/index";
import collectionmanagement2 from "./pages/collection/getOrderManagement2/index";
import collectionOrderManagement from "./pages/collection/getCollectionOrderManagement/index";
import newcollectionOrderManagement from "./pages/collection/new_getCollectionOrderManagement/index";
import collectionOrder from "./pages/collection/getMyOrder/index";
import OverduePaid from "./pages/collection/getClearedOrderManagement/index";
import MyOverduePaid from "./pages/collection/getMyClearedOrderManagement/index";
import getRemindDataToday from "./pages/remind/getRemindDataToday/index";
import HistoryRemindData from "./pages/remind/getRemindDataHistory/index";
import ReduceAudit from "./pages/reduce/getReduceList/index";
import Abnormal from "./pages/order_management/abnormal";
import MyReminOrder from "./pages/remind/getRemindOrderList/index";
import CollectionWorkMonitor from "./pages/collection/getCollectionDataDay/index";
import ReceivingHistorical from "./pages/collection/getCollectionWorkHistory/index";
import RemindOrderManagement from "./pages/remind/getRemindOrderManage/index";
import monthManagement from "./pages/collection/getCollectionOrderManagement/month";
import newmonthManagement from "./pages/collection/new_getCollectionOrderManagement/month";
import getReminds0 from "./pages/remind/getRemindDataHistory/s0";
import monthPerson from "./pages/collection/getCollectionWorkHistory/personaudit";
import dayPerson from "./pages/collection/getCollectionDataDay/collectionDayAudit";
import MenuManagement from "./pages/menu_management/menu_management";
import SystemSetting from "./pages/menu_management/system_settings/index";
import RoleManagement from "./pages/menu_management/role_management";
import AccountManagement from "./pages/menu_management/account_management";
import Product from "./pages/product/product";
import BankManagement from "./pages/data_dictionary/bank_management";
import RegionalManagement from "./pages/data_dictionary/regional_management";
import OptionConfig from "./pages/data_dictionary/option_config";
import HotIssues from "./pages/menu_management/hot_issues";
import AppVersion from "./pages/menu_management/app_version";
import contact_us from "./pages/menu_management/contact_us";
import Channel from "./pages/menu_management/channel";
import Refund from "./pages/payment_management/refund_statements";
import extendRepay from "./pages/payment_management/extend_repay";
import extendOrder from "./pages/payment_management/extend_order";
import Banner from "./pages/marketing_management/banner/banner";
import Coupon from "./pages/marketing_management/coupon/coupon";
import Activity from "./pages/marketing_management/activity/activity";
import Data from "./pages/marketing_management/data/index";
import Inside from "./pages/marketing_management/activity/inside";
import Edit from "./pages/marketing_management/activity/edit";
import See from "./pages/marketing_management/activity/see";
import NewRepaymentPlan from "./pages/payment_management/new_repayment_plan";
import Bank from "./pages/payment_management/bank_statements";
import BankZenith from "./pages/payment_management/bank_statementsZenith";
import BankClaim from "./pages/payment_management/bank_claim";

import DebitRecord from "./pages/payment_management/debit_record";
import RepaymentRecord from "./pages/payment_management/repayment_record";
import OfflineQC from "./pages/paymentQC/offlineQC";
import OfflineDate from "./pages/paymentQC/offlineDate";
import SmsManage from "./pages/sms_marketing/sms_manage";
import SmsData from "./pages/sms_marketing/sms_data/index";
import SmsDataDetail from "./pages/sms_marketing/sms_data/detail";
import TotalTelemarket from "./pages/telemarketing/total_telemarket";
import TodayTelemarket from "./pages/telemarketing/today_telemarket";
import MyTelemarket from "./pages/telemarketing/my_telemarket";
import TelemarketData from "./pages/telemarketing/telemarket_data/index";

import HistoryRemindDataList from "./pages/remind/getHistoryRemindDataList/index";
import HistoryRemindDataDetail from "./pages/remind/getHistoryRemindDataList/month";
import dayDetail from "./pages/telemarketing/telemarket_data/dayDetail";
import monthDetail from "./pages/telemarketing/telemarket_data/monthDetail";
import Monnify from "./pages/payment_management/Monnify";

export default class IRouter extends React.Component {
  render() {
    return (
      <HashRouter>
        <div>
          <Switch>
            <Route path="/" exact={true} component={Login}></Route>
            <Route
              path="/modifyPassword"
              exact={true}
              component={ModifyPassword}
            ></Route>
            <Route
              path="/"
              render={() => (
                <App>
                  <Switch>
                    <Route
                      path="/home/index"
                      component={Home}
                      name={"Home"}
                    ></Route>
                    <Route path="/welcome" component={Welcome}></Route>
                    <Route
                      path="/details/index"
                      component={Details}
                      name={"Details"}
                    ></Route>
                    <Route
                      path="/credit_system/order_management"
                      component={OrderManagement}
                      name={"Review Order"}
                    ></Route>
                    <Route
                      path="/credit_system/credit_auditime/index"
                      component={creditAuditime}
                      name={"Review Data"}
                    ></Route>
                    <Route
                      path="/credit_system/my_order"
                      component={MyOrder}
                      name={"My Review Order"}
                    ></Route>
                    <Route
                      path="/credit_system/credit_arrivetime/index"
                      component={creditArrivetime}
                      name={"Lending Data"}
                    ></Route>
                    <Route
                      path="/collection/getOrderManagement"
                      component={collectionmanagement}
                      name={"Collection Order"}
                    ></Route>
                    <Route
                      path="/collection/getOrderManagement2"
                      component={collectionmanagement2}
                      name={"Collection Order2"}
                    ></Route>

                    <Route
                      path="/collection/getCollectionOrderManagement/index"
                      component={collectionOrderManagement}
                      name={"Collection Data"}
                    ></Route>
                    <Route
                      path="/collection/new_getCollectionOrderManagement/index"
                      component={newcollectionOrderManagement}
                      name={"Collection Data"}
                    ></Route>
                    <Route
                      path="/collection/getMyOrder"
                      component={collectionOrder}
                      name={"My Collection Order"}
                    ></Route>
                    <Route
                      path="/collection/getClearedOrderManagement"
                      component={OverduePaid}
                      name={"Paid Order"}
                    ></Route>
                    <Route
                      path="/collection/getMyClearedOrderManagement"
                      component={MyOverduePaid}
                      name={"My Paid Order"}
                    ></Route>
                    <Route
                      path="/remind/getRemindOrderManage"
                      component={RemindOrderManagement}
                      name={"Remind Order"}
                    ></Route>
                    <Route
                      path="/remind/getRemindDataToday"
                      component={getRemindDataToday}
                      name={"Today Remind Data"}
                    ></Route>
                    <Route
                      path="/remind/getRemindDataHistory/index"
                      component={HistoryRemindData}
                      name={"History Remind Data"}
                    ></Route>
                    <Route
                      path="/remind/getRemindOrderList"
                      component={MyReminOrder}
                      name={"My Remind Order"}
                    ></Route>
                    <Route
                      path="/collection/getCollectionWorkHistory/index"
                      component={ReceivingHistorical}
                      name={"History Collection Data"}
                    ></Route>
                    <Route
                      path="/reduce/getReduceList"
                      component={ReduceAudit}
                      name={"Reduce Audit"}
                    ></Route>
                    <Route
                      path="/collection/getCollectionDataDay/index"
                      component={CollectionWorkMonitor}
                      name={"Collection Monitor"}
                    ></Route>
                    <Route
                      path="/payment_management/pending_loan"
                      component={PendingLoan}
                      name={"Pending Loan"}
                    ></Route>
                    <Route
                      path="/paymentQC/offlineQC"
                      component={OfflineQC}
                      name={"Offline Payment QC"}
                    ></Route>
                    <Route
                      path="/paymentQC/offlineDate"
                      component={OfflineDate}
                      name={"Offline Payment Date"}
                    ></Route>
                    <Route
                      path="/payment_management/repayment_plan"
                      component={RepaymentPlan}
                      name={"Repayment Plan"}
                    ></Route>
                    <Route
                      path="/order_management/loansuccess"
                      component={LoanSuccess}
                      name={"Post Loan Order"}
                    ></Route>
                    <Route
                      path="/order_management/lending"
                      component={Pending}
                      name={"Lending Order"}
                    ></Route>
                    <Route
                      path="/order_management/total_order"
                      component={TotalOrder}
                      name={"Total Order"}
                    ></Route>
                    <Route
                      path="/order_management/loanfail"
                      component={LoanFail}
                      name={"Loan Failure"}
                    ></Route>
                    <Route
                      path="/user_management/user_information"
                      component={UserInformation}
                      name={"User Information"}
                    ></Route>
                    <Route
                      path="/user_management/blacklist"
                      component={Blacklist}
                      name={"Blacklist Management"}
                    ></Route>
                    <Route
                      path="/user_management/greylist"
                      component={GreyList}
                      name={"GreyList Management"}
                    ></Route>
                    <Route
                      path="/user_management/bank_certificate"
                      component={BankCertificate}
                      name={"Bank Certificate"}
                    ></Route>

                    <Route
                      path="/message_management/system_message"
                      component={SystemMessage}
                      name={"System Message"}
                    ></Route>
                    <Route
                      path="/message_management/pendding_card"
                      component={PendingCard}
                      name={"Pending Card"}
                    ></Route>
                    <Route
                      path="/order_management/abnormal"
                      component={Abnormal}
                      name={"Payment Abnormal"}
                    ></Route>
                    <Route
                      path="/payment_management/new_repayment_plan"
                      component={NewRepaymentPlan}
                      name={"New Repayment Plan"}
                    ></Route>
                    <Route
                      path="/payment_management/bank_statements"
                      component={Bank}
                      name={"Bank statements"}
                    ></Route>
                    <Route
                      path="/payment_management/bank_statementsZenith"
                      component={BankZenith}
                      name={"Bank statements(Zenith)"}
                    ></Route>
                    <Route
                      path="/payment_management/bank_claim"
                      component={BankClaim}
                      name={"Bank claim"}
                    ></Route>
                    <Route
                      path="/payment_management/repayment_record"
                      component={RepaymentRecord}
                      name={"Repayment Record"}
                    ></Route>
                    <Route
                      path="/payment_management/debit_record"
                      component={DebitRecord}
                      name={"Debit Repay Record"}
                    ></Route>
                    <Route
                      path="/menu_management/menu_management"
                      component={MenuManagement}
                      name={"Menu Management"}
                    ></Route>
                    <Route
                      path="/menu_management/system_settings"
                      component={SystemSetting}
                      name={"System Setting"}
                    ></Route>
                    <Route
                      path="/menu_management/role_management"
                      component={RoleManagement}
                      name={"Role Management"}
                    ></Route>
                    <Route
                      path="/menu_management/account_management"
                      component={AccountManagement}
                      name={"Account Management"}
                    ></Route>
                    <Route
                      path="/product/product"
                      component={Product}
                      name={"Product"}
                    ></Route>
                    <Route
                      path="/payment_management/refund_statements"
                      component={Refund}
                      name={"Refund statements"}
                    ></Route>

                    {/* 短信菜单 */}
                    <Route
                      path="/sms_marketing/sms_manage"
                      component={SmsManage}
                      name="SMS manage"
                    ></Route>
                    <Route
                      path="/sms_marketing/sms_data"
                      component={SmsData}
                      name="SMS data"
                    ></Route>
                    <Route
                      path="/sms_marketing/sms_dataDetail"
                      component={SmsDataDetail}
                      name="SMS dataDetail"
                    ></Route>

                    {/* 电销模块 */}
                    <Route
                      path="/telemarketing/total_telemarket"
                      component={TotalTelemarket}
                      name="Total telemarketing"
                    ></Route>
                    <Route
                      path="/telemarketing/my_telemarket"
                      component={MyTelemarket}
                      name="My telemarketing"
                    ></Route>
                    <Route
                      path="/telemarketing/telemarket_data"
                      component={TelemarketData}
                      name="Telemarketing data"
                    ></Route>
                    <Route
                      path="/telemarketing/today_telemarket"
                      component={TodayTelemarket}
                      name="Today telemarketing"
                    ></Route>
                    <Route
                      path="/telemarketing/telemarket_data/dayDetail"
                      component={dayDetail}
                      name="Today telemarketing"
                    ></Route>
                    <Route
                      path="/telemarketing/telemarket_data/monthDetail"
                      component={monthDetail}
                      name="Month telemarketing"
                    ></Route>

                    <Route
                      path="/payment_management/extend_repay"
                      component={extendRepay}
                      name={"Extend Repay"}
                    ></Route>
                    <Route
                      path="/payment_management/extend_order"
                      component={extendOrder}
                      name={"Extend Order"}
                    ></Route>
                    <Route
                      path="/menu_management/hot_issues"
                      component={HotIssues}
                      name={"Hot Issues"}
                    ></Route>
                    <Route
                      path="/data_dictionary/bank_management"
                      component={BankManagement}
                      name={"Bank Management"}
                    ></Route>
                    <Route
                      path="/data_dictionary/regional_management"
                      component={RegionalManagement}
                      name={"Regional Management"}
                    ></Route>
                    <Route
                      path="/menu_management/app_version"
                      component={AppVersion}
                      name={"App Version"}
                    ></Route>
                    <Route
                      path="/menu_management/contact_us"
                      component={contact_us}
                      name={"Contact Us"}
                    ></Route>
                    <Route
                      path="/menu_management/channel"
                      component={Channel}
                      name={"Channel"}
                    ></Route>
                    <Route
                      path="/data_dictionary/option_config"
                      component={OptionConfig}
                      name={"Option Config"}
                    ></Route>
                    <Route
                      path="/collection/getCollectionOrderManagement/month"
                      component={monthManagement}
                      name={"monthData"}
                    ></Route>
                    <Route
                      path="/collection/new_getCollectionOrderManagement/month"
                      component={newmonthManagement}
                      name={"newmonthData"}
                    ></Route>
                    <Route
                      path="/remind/getRemindDataHistory/s0"
                      component={getReminds0}
                      name={"getReminds0"}
                    ></Route>
                    <Route
                      path="/collection/getCollectionWorkHistory/personaudit"
                      component={monthPerson}
                      name={"monthPerson"}
                    ></Route>
                    <Route
                      path="/collection/getCollectionDataDay/collectionDayAudit"
                      component={dayPerson}
                      name={"dayPerson"}
                    ></Route>
                    <Route
                      path="/credit_system/credit_auditime/day_audit"
                      component={dayAudit}
                      name={"dayAudit"}
                    ></Route>
                    <Route
                      path="/credit_system/credit_arrivetime/day_arrive"
                      component={dayArrive}
                      name={"dayArrive"}
                    ></Route>
                    <Route
                      path="/credit_system/credit_auditime/hour_audit"
                      component={hourAudit}
                      name={"hourAudit"}
                    ></Route>
                    <Route
                      path="/credit_system/settle_order"
                      component={settleOrder}
                      name={"settleOrder"}
                    ></Route>
                    <Route
                      path="/marketing_management/banner/banner"
                      component={Banner}
                      name={"前端图片管理"}
                    ></Route>
                    <Route
                      path="/marketing_management/coupon/coupon"
                      component={Coupon}
                      name={"优惠券管理"}
                    ></Route>
                    <Route
                      path="/marketing_management/activity/activity"
                      component={Activity}
                      name={"活动管理"}
                    ></Route>
                    <Route
                      path="/marketing_management/data/index"
                      component={Data}
                      name={"数据统计"}
                    ></Route>
                    <Route
                      path="/marketing_management/activity/inside"
                      component={Inside}
                      name={"新增"}
                    ></Route>
                    <Route
                      path="/marketing_management/activity/edit"
                      component={Edit}
                      name={"编辑"}
                    ></Route>
                    <Route
                      path="/marketing_management/activity/see"
                      component={See}
                      name={"预览"}
                    ></Route>
                    <Route
                      path="/remind/getHistoryRemindDataList/index"
                      component={HistoryRemindDataList}
                      name={"History Remind Data-2"}
                    ></Route>
                    <Route
                      path="/remind/getHistoryRemindDataList/month"
                      component={HistoryRemindDataDetail}
                      name={"Month Remind"}
                    ></Route>
                    <Route
                        path="/payment_management/Monnify"
                        component={Monnify}
                        name={"monnify转账"}
                    ></Route>
                    {/* <Redirect to="/home"/>  */}
                  </Switch>
                </App>
              )}
            ></Route>
          </Switch>
        </div>
      </HashRouter>
    );
  }
}
