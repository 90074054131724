export function validateSymbol(rule, value,callback) {//匹配特殊字符
    const reg =/((?=[\x21-\x7e]+)[^A-Za-z0-9])+/;
      if ((reg.test(value)) && value != '') {
        callback(new Error('Please do not enter special characters'));
      } else {
        callback();
      }
  }
export   function validateNumber(rule, value, callback) {
    if (!/^[1-9]\d*$/.test(value)) {
      callback({message :'threshold should be number'})
    } else {
      callback();
    }
  }


export   function validateNumbers(rule, value, callback) {
  if (!/^[0-9]\d*$/.test(value)) {
    callback({message :'threshold should be number'})
  } else {
    callback();
  }
}
export   function hundredNumber(rule, value, callback) {
    if (!/^(?:[1-9]?\d|100)$/.test(value)) {
      callback({message :'threshold should be number'})
    } else {
      callback();
    }
  }
  
export const rules={
    threshold:[//阀值
        {required: true, message: 'please enter threshold '},
        {type:'number',message:'threshold is not number'}
    ],
    theme:[//主题
        {required: true, message: 'please enter theme '},
    ],
    copywriting:[//文案
        {required: true, message: 'please enter copywriting '},
    ],
    fileList:[//文件
        {required: true, message: 'please upload a file '},
    ],
    indate:[//有效时间
        {required: true, message: 'please enter indate '},
        {type:'number',message:'indateis not number'}
    ]
}