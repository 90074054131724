/*eslint-disable*/
import React from 'react';
import {Input, Button, Table, Modal, Pagination, DatePicker, Tooltip, message,Icon,Upload,Radio} from 'antd';
import './index.css';
import axios from 'axios'
import moment from 'moment';
import {withRouter} from 'react-router-dom'
import EventEmitter from '../../utils/events'
import { exportMethod } from '../../utils/axios'

function showTotal(total) {
    return `Total ${total} items`;
}

const {Search} = Input;
var getselect = []
var getSelect = []

class Blacklist extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            tableData: [],
            current: 1,
            total: 1,
            pageSize: 10,
            boolean: true,
            scdmodalSelect:0,
            selectedRowKeys:[],
            ids:[],
            fileList: [],
            deleteSvisible:false,
            modifyBounc:false,
            scdvisible:false,
            btnWord:'export',
        }
    }
    pageChange = (page) => {
        this.setState({
            current: page
        }, () => {
            this.searchClick()
        })
    }

    //页面初始化加载下面的table
    secondTable = () => {
        axios
            .post(`/api/grayList/getGreyList`, {
                pageSize: this.state.pageSize,
                current: this.state.current,
            })
            .then(res => {
                if (res.data.code === 200) {
                    const data1 = [];
                    const data = res.data.data.resultDate
                    if(data.length<1){
                        this.setState({
                            boolean: false
                        })
                    }else{
                        for (let i = 0; i < data.length; i++) {
                            data[i].key = i;
                            data1.push(data[i]);
                            localStorage.userId = data[i].reviewerId
                        }
                    }
                    this.setState({
                        tableScdData: data1,
                        total: res.data.data.page.total,
                        boolean: false
                    })
                } else if (res.data.code == 800) {
                    this.props.history.push({pathname: "/"});
                } else {
                }

            })
    }

    //搜索
    searchClick = () => {
        axios
            .post(`/api/grayList/getGreyList`, {
                pageSize: this.state.pageSize,
                current: this.state.current,
                bvn: this.state.bvn,
                mobile: this.state.mobile,
            })
            .then(res => {
                if (res.data.code === 200) {
                    const data1 = [];
                    const data = res.data.data.resultDate

                    for (let i = 0; i < data.length; i++) {
                        data[i].key = i;
                        data1.push(data[i]);
                    }
                    this.setState({
                        tableScdData: data1,
                        total: res.data.data.page.total,
                        boolean: false
                    })
                } else if (res.data.code == 800) {
                    this.props.history.push({pathname: "/"});
                } else {
                }
            })
    }

    downExcel=()=>{
      // console.log(this.state.operate)
      // if(this.state.operate == undefined){
      //   message.error('Select the export time first')
      // }else{
      //   this.state.isDisabled = true;
      //   this.export();
      //   let count = 60;
      //   let timer = setInterval(()=>{
      //     if(count <=0){
      //       this.setState({
      //         btnWord:'export',
      //         isDisabled:false
      //       })
      //       clearInterval(timer);
      //     }else{
      //       count --;
      //       this.setState({
      //         btnWord:count +'s'
      //       })
      //     }
      //   },1000)
      // }
      this.state.isDisabled = true;
        this.export();
        let count = 60;
        let timer = setInterval(()=>{
          if(count <=0){
            this.setState({
              btnWord:'export',
              isDisabled:false
            })
            clearInterval(timer);
          }else{
            count --;
            this.setState({
              btnWord:count +'s'
            })
          }
        },1000)
    }
    export=()=>{
      let myObj = {
        method: 'get',
        url:`/api/grayList/downloadGrayListTemplate`,
        fileName: '灰名单.xls',
        }
        exportMethod(myObj)
    }

    //批量删除
    hanldeDelete = () =>{
      console.log(this.state.selectedRowKeys)
      if(this.state.selectedRowKeys.length == 0){
        message.error('请先选择一条数据进行删除')
      }else{
        this.setState({
          deleteSvisible:true
        })
      }
    }

     //确认删除
     handleDeleteModalOk=()=>{
      let arr = []
      this.state.selectedRowKeys.map(row =>{
        arr.push(row)
      })
      axios({
          url: '/api/grayList/batchDelete?ids=' + arr,
          method: 'post',
          // data: params
      })
      .then(res => {
        console.log(res)
        if(res.data.code == 200){
          message.success(res.data.msg)
          this.handleDeleteModalCancel()
          // this.searchClick()
        }else if(res.data.code == -1){
          message.error(res.data.msg)
          this.handleDeleteModalCancel()
          // this.searchClick()
        }
      })
    }

    //取消批量删除
    handleDeleteModalCancel=()=>{
      this.setState({
        deleteSvisible:false,
        selectedRowKeys:[],
      })
      this.searchClick()
    }


    //第一个textarea
    fsttxarea=(e)=>{
        let value = e.target.value;
        this.setState({ fsttextarea: value })
    }

    handleSave = (record) => {
        this.setState({
          modifyBounc:true,
          mobile:record.mobile,
          bvn:record.bvn,
          status:record.status,
          id:record.id
        })
    }

    handleDetailsTable = (record) =>{
        axios({
          url:'/api/grayList/getLogOperateByGreyListId?greyListId=' + record.id,
          method:'get',
        }).then((res) =>{
          console.log(res)
          if(res.data.code == 200){
            this.setState({
              phoneTableData:res.data.data,
              scdvisible:true
            })
          }
        })
    }
    onShowSizeChange=(current, pageSize)=>{
        this.setState({
          pageSize: pageSize
        },()=>{
          this.secondTable()
        })
    }
    componentDidMount() {
        this.secondTable()
    }
    onSelectChange = selectedRowKeys => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };

    //确认修改灰名单状态
    handleReduceOk =() =>{
      axios({
        url:'/api/grayList/updateGrayListById',
        method:'post',
        data:{
          id:this.state.id,
          status:this.state.status
        }
      }).then((res) =>{
        console.log(res)
        if(res.data.code == 200){
            message.success(res.data.msg)
            this.handleCancel()
            this.searchClick()
          }else{
          message.error(res.data.msg)
        }
      })
    }

    //取消修改灰名单状态
    handleCancel =() =>{
      this.setState({
        modifyBounc:false,
        scdvisible:false,
        uploadvisible:false,
        mobile:'',
        bvn:''
      })
    }

    ///////导入
    uploadhandleOk=()=>{
      if(this.state.fileList==''){
        alert("Upload content cannot be empty")
        return false
      }
      this.setState({
        scdafter:true
      })
   
    const { fileList} = this.state;
    const formData = new FormData();
    formData.append('file', fileList[0]);
    axios({
        url: '/api/grayList/uploadGreyList',
        method: 'post',
        data: formData
    })
    .then(res => {
        if (res.data.code === 200) {        
            message.success('您上传了' + res.data.data.total +  '条数据,成功了' + res.data.data.success + '条数据，失败了' + res.data.data.error + '条数据')
            this.searchClick()
            this.setState({ 
                uploadvisible:false,
                fileList:'',
                scdafter:false,
                isdisabled:false
            })
        } else if (res.data.code == 800) {
            this.props.history.push({pathname: "/"});
        } else {
            message.error(res.data.msg)
            this.setState({
              uploadvisible:false,
              scdafter:false,
              fileList:'',
              isdisabled:false
            })
        }
    })
    }

    changeupload=()=>{
      this.setState({
        uploadvisible:true
      })
    }




    render() {
      const { selectedRowKeys } = this.state;
      const rowSelection = {
        selectedRowKeys,
        onChange: this.onSelectChange,
        columnWidth:20,
        getCheckboxProps: record => ({
        disabled: record.status === 0, // Column configuration not to be checked
        name: record.name,
      }),
    };
    const columns = [
      {
        title: '手机号',
        dataIndex: 'mobile',
        key: 'mobile',
      },
      {
        title: 'Bvn',
        dataIndex: 'bvn',
        key: 'bvn',
      },
      {
        title: '状态',
        dataIndex: 'status',
        key: 'status',
        render: (value) => {
          if (value == 0) {
              return '失效'
          } else if (value == 1) {
              return '有效'
          } else {
              return '——'
          }
        }
      },
      {
        title: '入灰时间',
        dataIndex: 'createdTime',
        key: 'createdTime',
        render: (value) => {
            let timeaudit= new Date(value).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
            let auditimes=moment(new Date(timeaudit)).format("YYYY-MM-DD HH:mm:ss");
            return auditimes
        }
      },
      {
          title: '操作人',
          dataIndex: 'operatorName',
          key: 'operatorName',
      },
      {
        title: '操作时间',
        dataIndex: 'updatedTime',
        key: 'updatedTime',
        render: (value) => {
            let timeaudit= new Date(value).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
            let auditimes=moment(new Date(timeaudit)).format("YYYY-MM-DD HH:mm:ss");
            return auditimes
        }
      },
      {
          title: '操作',
          key: 'operation',
          id: 0,
          uid:'uid',
          render: (record) => (<span style={{ display: 'flex' , flexDirection: 'row', justifyContent:'center',margin:0}}>
          <Button type="primary" style={{
              width: 80,
              height: 30,
              backgroundColor: '#775bee',
              border: 'none',
              marginRight:10
          }} onClick={() =>this.handleSave(record)}>修改</Button>
          <Button type="primary" style={{
              width: 80,
              height: 30,
              backgroundColor: '#775bee',
              border: 'none'
          }} onClick={() =>this.handleDetailsTable(record)}>记录</Button>
        </span>)
      }
    ];

    const phoneColumns = [
      {
        title: '新状态',
        dataIndex: 'newStatus',
        key: 'newStatus',
        render: (value) => {
          if (value == 0) {
              return '失效'
          } else if (value == 1) {
              return '有效'
          } else {
              return '——'
          }
        }
      },
      {
        title: '老状态',
        dataIndex: 'oldStatus',
        key: 'oldStatus',
        render: (value) => {
          if (value == 0) {
              return '失效'
          } else if (value == 1) {
              return '有效'
          } else {
              return '——'
          }
        }
      },
      {
          title: '操作人',
          dataIndex: 'operatorName',
          key: 'operatorName',
      },
      {
        title: '操作时间',
        dataIndex: 'createdTime',
        key: 'createdTime',
        render: (value) => {
            let timeaudit= new Date(value).getTime() + new Date().getTimezoneOffset() * 60 * 1000 - (0 * 60 * 60 * 1000)
            let auditimes=moment(new Date(timeaudit)).format("YYYY-MM-DD HH:mm:ss");
            return auditimes
        }
      },

    ];
    const { uploading, fileList } = this.state;
    const props = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        this.setState(state => ({
          fileList: [...state.fileList, file],
          isdisabled:true
        }));
        return false;
      },
      fileList,
    };
    return (
      <div className="audit_content">
        <div className="commom_top" style={{flexDirection: 'column'}}>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <div className="mid_left" style={{width: '25%'}}>
                <span className="description_new">BVN:</span>
                  <Search
                    placeholder="BVN"
                    onSearch={value => console.log(value)}
                    style={{width: 220}}
                    value={this.state.bvn}
                    onChange={(e) => {
                      this.setState({ bvn: e.target.value });
                    }}
                  />
                </div>
              <div className="mid_left" style={{width: '25%'}}>
                <span className="description_new">Phone:</span>
                  <Search
                    placeholder="Mobile"
                    onSearch={value => console.log(value)}
                    style={{width: 220}}
                    value={this.state.mobile}
                    onChange={(e) => {
                      this.setState({ mobile: e.target.value });
                    }}
                  />
              </div>
              </div>
            </div>
          <div style={{display: 'flex', justifyContent: 'flex-start', marginTop: 20}}>
              <Button 
                type="primary" 
                className='button_style'
                style={{width: 120, marginRight: 20, height: 40, backgroundColor: '#775bee'}}
                onClick={this.searchClick.bind(this)}
              >搜索</Button>
              <Button 
                type="primary" 
                className='button_style'
                style={{width: 170, marginRight: 20, height: 40, backgroundColor: '#775bee'}}
                onClick={this.hanldeDelete.bind(this)}
              >批量删除</Button>
              <Button type="primary"  style={{width:150,marginRight:20,height:40,backgroundColor:'#009688',border:'none'}} onClick={this.downExcel} disabled={this.state.isDisabled}>{this.state.btnWord}</Button>
              {/* <Upload  {...props} accept=".csv">
                  <Button disabled={this.state.fstdis} onClick={this.changeupload}  style={{width: 120,height: 40, backgroundColor: '#775bee', color:'#fff'}}>
                    <Icon type="upload" /> 导入
                  </Button>
              </Upload>  */}
              <Button type="primary" className="common_color" style={{width:150,marginRight:20,height:40,border:'none'}} onClick={this.changeupload}>
              upload
              </Button>
          </div>
        <div className="table">
            <Table 
              columns={columns}  
              rowKey={record=>record.id} 
              rowSelection={rowSelection} 
              dataSource={this.state.tableScdData}  
              loading={this.state.boolean}
              pagination={false}
            />,
        </div>
        <Pagination
          // showQuickJumper
          onShowSizeChange={this.onShowSizeChange} //pagesize改变时回调函数
          pageSizeOptions={["10","50","100"]}   //设置pagesize区间
          defaultCurrent={1}
          current={this.state.current}
          total={this.state.total}
          onChange={this.pageChange}
          showTotal={showTotal}
          style={{textAlign: 'right', marginTop: 20}}
          showSizeChanger={true}
          pageSize={this.state.pageSize}
        />
        {/* 批量删除 */}
        <Modal
          title="Delete"
          visible={this.state.deleteSvisible}
          onCancel={this.handleDeleteModalCancel}
          footer={[
            <Button key="back" onClick={this.handleDeleteModalCancel}>
              Cancel
            </Button>,
             <Button key="submit" type="primary"  onClick={this.handleDeleteModalOk}>
             Confirm
           </Button>,
          ]}
          >
          <p>您确定要将选中的用户移除灰名单么？</p>
        </Modal>


        {/* 修改弹框 */}
        <Modal
          title="修改灰名单内容"
          visible={this.state.modifyBounc}
          onOk={this.handleReduceOk}
          onCancel={this.handleCancel}
        >
          <div>
            <lable style={{ fontSize: 15, fontWeight: "bold" }}>
            手机号:{" "}
            </lable>
            <Input
              style={{ width: '50%',marginLeft:'9%' }}
              value={this.state.mobile} 
              onChange={(e) => {
                this.setState({ mobile: e.target.value });
              }}
              disabled
            ></Input>
          </div>
          <div style={{marginTop:15}}>
            <lable style={{ fontSize: 15, fontWeight: "bold" }}>
             BVN: {" "}
            </lable>
            <Input
              style={{ width: '50%',marginLeft:'12%' }}
              value={this.state.bvn} 
              onChange={(e) => {
                this.setState({ bvn: e.target.value });
              }}
              disabled
            ></Input>
          </div>
          <div style={{marginTop:15}}>
            <Radio.Group 
              onChange={(e) => {
                this.setState({ status: e.target.value });
              }} 
              value={this.state.status}>
              <Radio value={0}>置为失效</Radio>
              <Radio value={1}>置为有效</Radio>
            </Radio.Group>
          </div>
        </Modal>
        {/* 记录 */}
        <Modal
          width='750px'
          title="Record"
          visible={this.state.scdvisible}
          onCancel={this.handleCancel}
          
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Cancel
            </Button>,
          ]}
          >
          <Table  columns={phoneColumns} dataSource={this.state.phoneTableData} onChange={this.phoneHandleChange} pagination={false} />
        </Modal>
        <Modal
          title="upload"
          onCancel={this.handleCancel}
          visible={this.state.uploadvisible}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              cancel
            </Button>,
            <Button key="submit" type="primary" loading={this.state.scdafter} onClick={this.uploadhandleOk}>
              ok
            </Button>,
          ]}>
            <Upload {...props}
             accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,"
             disabled={this.state.isdisabled}>
              <Button type="primary" style={{width:120,marginRight:20,height:40,backgroundColor:'#009688',border:'none'}} onClick={this.changeupload}>
                <Icon type="upload" /> upload
              </Button>
            </Upload>
        </Modal>
      </div>
    )
  }
}
export default withRouter(Blacklist)