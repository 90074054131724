/*eslint-disable*/
import React from 'react'
import { Row, Col, Tabs } from 'antd';
// import 'antd/dist/antd.css';
import EventEmitter from './utils/events'
import Header from './components/Header';
import Footer from './components/Footer';
import NavLeft from './components/NavLeft';
import './style/common.css';
const { TabPane } = Tabs;

class App extends React.Component {
  state = {
    panes: [],
    activeKey: "",  //没有这一项的话默认指panes选中第一项 ,
  }
  //去重方法
  uniqObj = (data,name) => {
    let dataArr = [];
    let dataObj = {};
    let item;
    for (let i = 0; i < data.length; i++) {
    item= data[i].btnKey ? data[i].btnKey && data[i].btnKey: data[i].props && data[i].props.path;
      if (!dataObj[item]) {
        dataArr.push(data[i]);
        dataObj[item] = true;
      }
    }
    return dataArr;
  }
  componentDidMount() {
    // 获取title
    let _this = this
    //获取路由   data包含点击的导航节点信息  包括路由 名称参数
    EventEmitter.on('nav', function (data) {
      _this.findReactNode(data, _this.props.children.props.children)
      //第一个参数是navleft点击事件传来的参数，第二个参数是所有节点数组
    })
    EventEmitter.on('btn', function (data) {
      console.log('aaa',data)
      _this.findBtnNode(data, _this.props.children.props.children)
      //第一个参数是navleft点击事件传来的参数，第二个参数是所有节点数组
    })
    // 点击确定按钮的时候关闭tab标签
    EventEmitter.on('handleOk', function (data) {
      _this.onEdit(data)
    })

  }

//匹配按钮路由

  findBtnNode = (btnItem, reactNodes) => {
    let { panes } = this.state
    reactNodes.map((item, index) => {
      // if(item.props.path.includes('details/index')) {  //判断下传过来的路径和节点的路径是否相同，相同取出来重新组成数组
      //   panes.push(item)    //注意：panes数组需要去重，方法没有写
      // }   
      if (btnItem.path == item.props.path) { //就这块代码起作用了，相当于我匹配到路由之后，我给这个item添加一个路径属性，
        let obj = {
          ...item,
          btnKey: btnItem.key
        }
        panes.push(obj)
      }
    })
   let uniqueBtn = this.uniqObj(panes,'btnKey')
    // console.log(panes,uniqueBtn,'panes')
    this.setState({
      panes: uniqueBtn,
      number: btnItem.number,
      activeKey: btnItem.key,  //将左侧导航路径赋值给默认显示的tab
    })
  }

  onEdit = (targetKey) => {
    let { activeKey } = this.state
    const panes = this.state.panes.filter(
      pane => {
        if (pane.btnKey) {
          return pane.btnKey !== targetKey
        } else {
          return pane.props.path !== targetKey
        }
      }
    );
    if (panes.length && activeKey === targetKey) {
      if (panes.length >= 0) {
        activeKey = panes[panes.length - 1].btnKey ? panes[panes.length - 1].btnKey : panes[panes.length - 1].props.path;
      } else {
        activeKey = panes[0].btnKey ? panes[0].btnKey : panes[0].props.path;
      }
    }
    this.setState({
      panes: panes,
      activeKey: activeKey
    });
  };


  //匹配路由
  findReactNode = (navItem, reactNodes) => {
    let { panes } = this.state
    reactNodes.map(item => {
      if (navItem.props.eventKey == item.props.path) {  //判断下传过来的路径和节点的路径是否相同，相同取出来重新组成数组
        panes.push(item)    //注意：panes数组需要去重，方法没有写    
      }
    })
    let a = this.uniqObj(panes,'path')
    this.setState({
      panes: a,
      activeKey: navItem.props.eventKey,  //将左侧导航路径赋值给默认显示的tab
    })
  }




  onChange = activeKey => {
    this.setState({ activeKey });
  };

  render() {

    let { panes } = this.state
    return (

      <Row className="container">
        <Col span={3} className="nav-left">
          <NavLeft />
        </Col>
        <Col span={21} className="main">
          <Header></Header>
          <Row className="content">
          {this.props.children.props.children[1]}
            <Tabs
              onChange={this.onChange}
              type="editable-card"
              activeKey={this.state.activeKey}
              onEdit={this.onEdit}
            >
              {/* {this.props.children} */}
              {panes && panes.map(pane => (
                <TabPane tab={pane.props.name ? pane.props.name : pane.props.component.name} key={pane.btnKey ? pane.btnKey : pane.props.path}>
                  <pane.props.component />
                </TabPane>
              ))}
            </Tabs>
          </Row>

        </Col>
      </Row>
    )
  }
}
export default App
