/*eslint-disable*/
import React from 'react';
import { Button,Table,DatePicker,Pagination,message } from 'antd';
import './index.css';
import axios from 'axios'
import moment from 'moment';
import {withRouter} from 'react-router-dom'

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD'||undefined;

function showTotal(total) {
  return `Total ${total} items`;
}
function formatMoney(s, type){
  if (/[^0-9\.]/.test(s))
    return "0";
  if (s == null || s == "null" || s == "")
    return "0";
  s = s.toString().replace(/^(\d*)$/, "$1.");
  s = (s + "00").replace(/(\d*\.\d\d)\d*/, "$1");
  s = s.replace(".", ",");
  var re = /(\d)(\d{3},)/;
  while (re.test(s))
    s = s.replace(re, "$1,$2");
  s = s.replace(/,(\d\d)$/, ".$1");
  if (type == 0) {
    var a = s.split(".");
    if (a[1] == "00") {
      s = a[0];
    }
  }
  s = s.replace(".", ",");
  return s;
}
class newmonthManagement extends React.Component{
    constructor(props){
        super(props);
        this.state={
          from:'',
          to:'',
          tableData:[], 
          // current:1,
          // length:1,
          // pageSize:10,
          boolean:true
        }
      }
      componentDidMount(){
        this.secondTable();
      }
      //设置时间
      rangeOnChange=(dates, dateStrings)=>{
        this.setState({ from: dateStrings[0],to:dateStrings[1] })
      }
    showmodal=()=>{
        // alert(this.index)
    }
    pageChange=(page)=>{
      this.setState({
        current: page
        },()=>{
          this.searchClick()
        })
    }
    onShowSizeChange=(current, pageSize)=>{

      this.setState({
        pageSize: pageSize
      },()=>{
        this.secondTable()
      })
  }

    collector =(params)=>{
      var va = params.overduegrade
      if (va==1){return <span style={{color:'black'}}>S1</span>}
      if (va==2){return <span style={{color:'black'}}>S2</span>}
      if (va==3){return <span style={{color:'black'}}>M1</span>}
      if (va==4){return <span style={{color:'black'}}>M2</span>}
      if (va==5){return <span style={{color:'black'}}>M3</span>}
      if (va==6){return <span style={{color:'black'}}>M4</span>}
      else {return <span style={{color:'black'}}></span> }
      }

      Ordergap =(params)=>{
        var va = params.Ordergap
        if (va>=0){return <span style={{color:'black'}}>{params.Ordergap}</span>}
        else {return <span style={{color:'black'}}>0</span> }
        }
      Amountgap =(params)=>{
        var va = params.Amountgap
        if (va>=0){
          return  formatMoney(Math.ceil(va / 100),0)
        }
        else {return <span style={{color:'black'}}>0</span> }
        }
        
      Forecast =(params)=>{
        console.log('paramssssssssssssss',params)
        if (params.Allocateder<=0){
          return <span style={{color:'black'}}>0</span>
        }else if(params.Ordergap <= 0){
          return <span style={{color:'black'}}>15000</span>;
      }
        else {return <span style={{color:'black'}}>0</span> }
      }
      amount =(params)=>{
        var num1 = params.Totalount
        return  formatMoney(num1,0);
      }
      amount1 =(params)=>{
        var num1 = params.Totalamount
        return  formatMoney(num1,0);
      }
      amount2 =(params)=>{
        var num1 = params.Repaymentmnt
        return  formatMoney(num1,0);
      }
      amount3 =(params)=>{
        var num1 = params.Allocatemount
        return  formatMoney(num1,0);
      }
      amount4 =(params)=>{
        var num1 = params.Transfermount
        return  formatMoney(num1,0);
      }
      amount5 =(params)=>{
        var num1 = params.Todaymount
        return  formatMoney(num1,0);
      }

//页面初始化加载的table
  secondTable=()=>{

    let id = localStorage.id;
    axios
      .post(`/api/collectionData/getNewOrderDetails`,{

      pageSize: this.state.pageSize,
      current: this.state.current,
      uid:id,
    })
    .then(res=>{
      if(res.data.code === 200){
        const data1 = [];
        const data = res.data.data.resultDate
        if(data.length<1){
          this.setState({
            boolean:false
          })
        }else{
          for (let i = 0; i < data.length; i++) {
            data1.push({
              key: i,
              date:data[i].collectionMonth,
              Collector:data[i].userName,
              overduegrade:data[i].overdueGrade,
              Allocateder:data[i].totalCount,
              Totalount:data[i].totalAmount/100,
              Currentotal:data[i].curTotalCount,
              Amountotal:data[i].curTotalAmount/100,
              Totalorder:data[i].turnoverVolumeCount,
              Totalamount:data[i].turnoverAmount/100,
              Repaymentder:data[i].totalBillCollection,
              Repaymentmnt:data[i].totalAmountDue/100,
              Recoveryamount:data[i].paymentRecoveryRate,
              Recoveryrate:data[i].orderNumberRecoveryRate,
              Ratetarget:data[i].kpiRate,
              Ordergap:data[i].differCount,
              Amountgap:data[i].differAmount,
              Forecast:data[i].prospectiveBonus,
              Allocaterder:data[i].todayCount,
              Allocatemount:data[i].todayAmount/100,
              Transferder:data[i].turnoverTodayCount,
              Transfermount:data[i].turnoverTodayAmount,
              Todayrder:data[i].totalCollection,
              Todaymount:data[i].totalAmounts/100,
            });
          } 
        }
        
        this.setState({
          tableScdData:data1,
          boolean:false
        })
      }else if(res.data.code ==800){
        this.props.history.push({ pathname: "/"});
      }else{

      }
    })
    
  }


          //搜索
  searchClick=()=>{ 
    this.setState({
      boolean:true
    })
    let id = localStorage.id;
    axios
      .post(`/api/collectionData/getNewOrderDetails`,{
      beginTime:this.state.from,
      endTime:this.state.to,
      pageSize: this.state.pageSize,
      current: this.state.current,
      uid:id,
    })
    .then(res=>{
      if(res.data.code === 200){
        const data1 = [];
        const data = res.data.data.resultDate
        if(data.length < 1){
          this.setState({
            boolean:false
          })
        }else{
          for (let i = 0; i < data.length; i++) {
            data1.push({
              key: i,
              date:data[i].collectionMonth,
              Collector:data[i].userName,
              overduegrade:data[i].overdueGrade,
              Allocateder:data[i].totalCount,
              Totalount:data[i].totalAmount/100,
              Currentotal:data[i].curTotalCount,
              Amountotal:data[i].curTotalAmount/100,
  
              Totalorder:data[i].turnoverVolumeCount,
              Totalamount:data[i].turnoverAmount/100,
              Repaymentder:data[i].totalBillCollection,
              Repaymentmnt:data[i].totalAmountDue/100,
              Recoveryamount:data[i].paymentRecoveryRate,
              Recoveryrate:data[i].orderNumberRecoveryRate,
  
              Ratetarget:data[i].kpiRate,
              Ordergap:data[i].differCount,
              Amountgap:data[i].differAmount/100,
              Forecast:data[i].prospectiveBonus,
              Allocaterder:data[i].todayCount,
              Allocatemount:data[i].todayAmount/100,
  
              Transferder:data[i].turnoverTodayCount,
              Transfermount:data[i].turnoverTodayAmount/100,
              Todayrder:data[i].totalCollection,
              Todaymount:data[i].totalAmounts/100,
            });

          }
          this.setState({
            tableScdData:data1,
            // length:res.data.data.page.total,
            boolean:false
          }) 
        }
       
      }else if(res.data.code ==800){
        this.props.history.push({ pathname: "/"});
      }else{

      }
    })
                  // localStorage.removeItem('userId')
                  
                }
    render(){
        

        const columns = [
           {
              title: 'date',
              dataIndex: 'date',
              key: 'date',
              width:70

            }, 
            {
              title: 'Collector',
              dataIndex: 'Collector',
              key: 'Collector',

            }, 
            {
              title: 'Overdue grade',
              dataIndex: 'overduegrade',
              key: 'overduegrade',
              render:(text,record) =>  this.collector(record)

            }, 
            {
              title: 'Total Allocate Order',
              dataIndex: 'Allocateder',
              key: 'Allocateder'
            }, 
            {
              title: 'Total Allocate Amount',
              dataIndex: 'Totalount',
              key: 'Totalount',
              render:(text,record) =>  this.amount(record)

            },
            {
              title: 'Current Order Total',
              dataIndex: 'Currentotal',
              key: 'Currentotal'
            },
            {
                title: 'Current Amount Total',
                dataIndex: 'Amountotal',
                key: 'Amountotal'
            }, 
            {
                title: 'Total Transfer Order',
                dataIndex: 'Totalorder',
                key: 'Totalorder'
            },
            {
                title: 'Total Transfer Amount',
                dataIndex: 'Totalamount',
                key: 'Totalamount',
                render:(text,record) =>  this.amount1(record)

            },
            {
                title: 'Total Repayment Order',
                dataIndex: 'Repaymentder',
                key: 'Repaymentder'
            }, 
            {
                title: 'Total Repayment Amount',
                dataIndex: 'Repaymentmnt',
                key: 'Repaymentmnt',
                render:(text,record) =>  this.amount2(record)

            }, 
            {
                title: 'Amount Recovery Rate',
                dataIndex: 'Recoveryamount',
                key: 'Recoveryamount'
            },
            {
                title: 'Order Recovery Rate',
                dataIndex: 'Recoveryrate',
                key: 'Recoveryrate'
              }, 
              {
                title: 'Recovery Rate Target',
                dataIndex: 'Ratetarget',
                key: 'Ratetarget'
              },
              {
                title: 'Order Gap',
                dataIndex: 'Ordergap',
                key: 'Ordergap',
                render:(text,record) =>  this.Ordergap(record)
              },
              {
                  title: 'Amount Gap',
                  dataIndex: 'Amountgap',
                  key: 'Amountgap',
                  render:(text,record) =>  this.Amountgap(record)

              }, 
              {
                  title: 'Forecast Bonus',
                  dataIndex: 'Forecast',
                  key: 'Forecast',
                  render:(text,record) =>  this.Forecast(record)
              },
              {
                  title: 'Today Allocate Order',
                  dataIndex: 'Allocaterder',
                  key: 'Allocaterder'
              },
              {
                  title: 'Today Allocate Amount',
                  dataIndex: 'Allocatemount',
                  key: 'Allocatemount',
                  render:(text,record) =>  this.amount3(record)

              }, 
              {
                  title: 'Today Transfer Order',
                  dataIndex: 'Transferder',
                  key: 'Transferder'
              }, 
              {
                  title: 'Today Transfer Amount',
                  dataIndex: 'Transfermount',
                  key: 'Transfermount',
                  render:(text,record) =>  this.amount4(record)

              },
              {
                title: 'Today Repayment Order',
                dataIndex: 'Todayrder',
                key: 'Todayrder'
            }, 
            {
                title: 'Today Repayment Amount',
                dataIndex: 'Todaymount',
                key: 'Todaymount',
                render:(text,record) =>  this.amount5(record)

            },
          ];
        return(
            <div className="audit_content">
                <div className="commom_top">
                <div className="rangepick">
                    <span className="description mid_span">Created time:</span>
                    <RangePicker
                        style={{width: 290,}}
                        ranges={{
                        Today: [moment(), moment()],
                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                        }}
                        showTime
                        format={dateFormat}
                        onChange={this.rangeOnChange}
                        value={this.state.from===undefined||this.state.to===undefined||this.state.from===""||this.state.to===""?null:[moment(this.state.from, dateFormat), moment(this.state.to, dateFormat)]}
                            />
                        </div>
                <Button type="primary" style={{width:170,marginRight:20,marginLeft:'15%',height:40,border:'none'}} className="common_color" onClick={this.searchClick.bind(this)}>Search</Button>
                </div>
                <div className="table">
                <Table columns={columns} dataSource={this.state.tableScdData} loading={this.state.boolean} pagination={false} />
                </div>

                  {/* <Pagination
                  showQuickJumper
                  showSizeChanger                          //展示改变pagesize样式
                  onShowSizeChange={this.onShowSizeChange} //pagesize改变时回调函数
                  pageSizeOptions={["10","50","100"]}   //设置pagesize区间
                  defaultCurrent={1} 
                  current={this.state.current}
                  total={this.state.length} 
                  onChange={this.pageChange} 
                  pageSize={this.state.pageSize}
                  showTotal={showTotal}
                  style={{textAlign:'right',marginTop:20}}
                  /> */}
            </div>
        )
    }
}
export default withRouter(newmonthManagement)