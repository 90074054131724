import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";

import { getAdInfo, addAdInfo } from "../../../api/systemApi";
import { Form, Input, Icon, Button, Switch, Upload, message } from "antd";
const FormItem = Form.Item;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt5M = file.size / 1054 / 1054 < 5;
  if (!isLt5M) {
    message.error("Image must smaller than 5MB!");
  }
  return isJpgOrPng && isLt5M;
}

class FloatingForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 1,
      total: 1,
      pageSize: 10,
      tableData: [],
      boolean: true,
      btnLoading: false,
      controlBtn: 1,
      confirmDirty: false,
      autoCompleteResult: [],
      canEdit: false,
      data: {
        id: "",
        imgUrl: "",
        jumpUrl: "",
        status: 0,
      },
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
      }
    });
  };

  getData = () => {
    //获取表格数据
    this.setState({
      boolean: true,
    });
    getAdInfo().then((res) => {
      if (res.data.code === 200) {
        const data = res.data.data;
        this.setState({
          data: data,
          boolean: false,
        });
      } else if (res.data.code == 800) {
        this.props.history.push({ pathname: "/" });
      }
    });
  };

  onChange = (checked) => {
    let temp = this.state.data    
    if (checked) {
      temp.status = 1
      this.setState({
        data: temp,
      });
    } else {
      temp.status = 0
      this.setState({
        data: temp,
      });
    }
    
    if (!this.state.canEdit) {
      addAdInfo({
        status: this.state.data.status,      // 是否展示   1展示   0不展示
        type: this.state.data.id ? 'edit' : 'save',    //类型       edit 编辑  save保存
      }).then((res) => {
        if (res.data.code === 200) {
          message.success(res.data.msg)
          this.setState({
            canEdit: false,
          });
          this.getData()
        } else if (res.data.code == 800) {
          this.props.history.push({ pathname: "/" });
        }
      });      
    }
  };

  handleChange = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (imgUrl) => {        
        let temp = this.state.data
        temp.imgUrl = info.file.response.data
        this.setState({
          data: temp,
          loading: false,
        })
      });
    }
  };

  handleChangejumpUrl = e => {
    const { value } = e.target;
    let temp = this.state.data
    temp.jumpUrl = value
    this.setState({
      data: temp,
      loading: false,
    })
  };

  handleEdit() {
    this.setState({
      canEdit: true,
    });
  }
  submitForm() {
    console.log(localStorage.getItem('userId'));
    
    addAdInfo({
      userId: localStorage.getItem('userId'),
      id: this.state.data.id,
      imgUrl: this.state.data.imgUrl,    // 图片地址
      status: this.state.data.status,      // 是否展示   1展示   0不展示
      jumpUrl: this.state.data.jumpUrl,   // 跳转地址
      type: this.state.data.id ? 'edit' : 'save',    //类型       edit 编辑  save保存
    }).then((res) => {
      if (res.data.code === 200) {
        message.success(res.data.msg)
        this.setState({
          canEdit: false,
        });
        
        this.getData()
      } else if (res.data.code == 800) {
        this.props.history.push({ pathname: "/" });
      }
    });
  }

  componentWillMount() {
    this.getData();
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? "loading" : "plus"} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    return (
      <div>
        <Form className="floatForm" style={{ width: "600px" }}>
          <FormItem {...formItemLayout} label="悬浮窗管理">
            {getFieldDecorator("status", {
              initialValue: this.state.data.status,
            })(
              <Switch
                checked={this.state.data.status == 1}
                onChange={this.onChange}
              />
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="悬浮窗图片">
            {getFieldDecorator("file", {
              rules: [
                {
                  required: true,
                  message: "请选择悬浮窗图片!",
                },
              ],
            })(
              <Upload
                name="file"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action="/api/sysAd/upLoadFile"
                beforeUpload={beforeUpload}
                onChange={this.handleChange}
                disabled={!this.state.canEdit}
              >
                {this.state.data.imgUrl ? (
                  <img
                    src={this.state.data.imgUrl}
                    alt="avatar"
                    style={{ width: "100%" }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="悬浮窗跳转链接">
            {getFieldDecorator("jumpUrl", {
              initialValue: this.state.data.jumpUrl,
              rules: [
                {
                  required: true,
                  message: "请填写悬浮窗跳转链接",
                },
              ],
            })(<Input disabled={!this.state.canEdit} onChange={this.handleChangejumpUrl} />)}
          </FormItem>

          <FormItem {...tailFormItemLayout}>
            {this.state.canEdit ? (
              <Button type="primary" onClick={() => this.submitForm()}>
                确定
              </Button>
            ) : (
              <Button type="primary" onClick={() => this.handleEdit()}>
                编辑
              </Button>
            )}
          </FormItem>
        </Form>
      </div>
    );
  }
}
const Tab7 = Form.create({ name: "floating" })(FloatingForm);
export default withRouter(Tab7);
