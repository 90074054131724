
/*eslint-disable*/
import React from "react"
import './index.css';
import { Button,Table,DatePicker,Pagination,message } from 'antd';
import home_img from '../../../public/img/home_img.png';
import {withRouter} from 'react-router-dom'
import moment from 'moment';
import axios from 'axios'
import right from '../../../public/img/collection_imgright.png';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD'||undefined;
var btnval = ''

 class Home extends React.Component{
    constructor(props){
        super(props);
        this.state={
          from:'',
          to:'',
          tableData:[], 
          current:1,
          length:1,
          pageSize:10,
          isToggleOn: true,
          isToggleOff:false,
          total_today:'today',
          btnvalue:'All',
        }
      } 
   componentDidMount=()=>{
    this.searchClick();
    this.btn()
   }
   btn=()=>{
    let that = this;
    var p = document.getElementById("top").getElementsByTagName("p");
    var length = p.length;
    for (var i = 0; i < length; i++) {
        p[i].index = i;
        p[i].onclick = function() {
            p[this.index].setAttribute("class"," ");
            for (var j = 0; j < length; j++) {
                p[j].className = "home_common";
            };
            this.className = "home_active";
            var btnval = this.innerHTML
            that.setState({
                btnvalue:btnval,
 
            },()=>{
                that.searchClick()
            })
        }
        
    };

   }
   rangeOnChange=(dates, dateStrings)=>{
    this.setState({ from: dateStrings[0],to:dateStrings[1] })
  }
   whichActive=()=> {
    this.setState({
      isToggleOn: true,
      isToggleOff:false,
      thdSelect:2,
      total_today:'today',
      fstp3:this.state.fstp1,
      scdp3:this.state.scdp1,
      fivp3:this.state.fivp1,
      thdp3:this.state.thdp1,
      sixp3:this.state.sixp1,
      foup3:this.state.foup1,
      sevp3:this.state.sevp1,
    },()=>{
    //   this.searchClick()
    });
  }
  whichotherActive=()=> {
    this.setState({
      isToggleOff: true,
      isToggleOn:false,
      thdSelect:1,
      total_today:'today',
      fstp3:this.state.fstp2,
      scdp3:this.state.scdp2,
      fivp3:this.state.fivp2,
      thdp3:this.state.thdp2,
      sixp3:this.state.sixp2,
      foup3:this.state.foup2,
      sevp3:this.state.sevp2,

    },()=>{
    //   this.searchClick()
    });
  }
      //搜索
      search=()=>{
        axios
        .post(`/api/system/indexSearch`,{

        beginTime:this.state.from,
        endTime:this.state.to,
        appCode: this.state.btnvalue,
      })
      .then(res=>{
        if(res.data.code === 200){
          let data = res.data.data  
          this.setState({
            fstp:data.allRegisterNum,
            scdp:data.allPayOrder.num,
            fivp:data.allPayOrder.amount,
            thdp:data.allDueOrder.num,
            sixp:data.allDueOrder.amount,
            foup:data.allRepayOrder.num,
            sevp:data.allRepayOrder.amount,
          })
        }else if(res.data.code ==800){
          this.props.history.push({ pathname: "/"});
        }else{

        }
      })
      }
      searchClick=()=>{ 
            this.setState({
                from:'',
                to:''
            })
          axios
                .post(`/api/system/index`,{
                appCode: this.state.btnvalue,
              })
              .then(res=>{
                if(res.data.code === 200){
                  let data = res.data.data  
                  this.setState({
                    fstp:data.allRegisterNum,
                    scdp:data.allPayOrder.num,
                    fivp:data.allPayOrder.amount,
                    thdp:data.allDueOrder.num,
                    sixp:data.allDueOrder.amount,
                    foup:data.allRepayOrder.num,
                    sevp:data.allRepayOrder.amount,

                    fstp2:data.todayRegisterNum,
                    scdp2:data.todayPayOrder.num,
                    fivp2:data.todayPayOrder.amount,
                    thdp2:data.todayDueOrder.num,
                    sixp2:data.todayDueOrder.amount,
                    foup2:data.todayRepayOrder.num,
                    sevp2:data.todayRepayOrder.amount,

                    fstp1:data.allRegisterNum,
                    scdp1:data.allPayOrder.num,
                    fivp1:data.allPayOrder.amount,
                    thdp1:data.allDueOrder.num,
                    sixp1:data.allDueOrder.amount,
                    foup1:data.allRepayOrder.num,
                    sevp1:data.allRepayOrder.amount,


                    fstp3:data.allRegisterNum,
                    scdp3:data.allPayOrder.num,
                    fivp3:data.allPayOrder.amount,
                    thdp3:data.allDueOrder.num,
                    sixp3:data.allDueOrder.amount,
                    foup3:data.allRepayOrder.num,
                    sevp3:data.allRepayOrder.amount,


                    s1total:data.overDueNum.s1oderSum,
                    s2total:data.overDueNum.s2oderSum,
                    m1total:data.overDueNum.m1oderSum,
                    m2total:data.overDueNum.m2oderSum,
                    m3total:data.overDueNum.m3oderSum,
                    m4total:data.overDueNum.m4oderSum,
                    alloverdue:data.overDueNum.orderSum
                  })
                }else if(res.data.code ==800){
                  this.props.history.push({ pathname: "/"});
                }else{

                }
              })
              
            }
  render(){
      return(
          <div className="home">
              <div className="home_top" id='top'>
                <p className='home_common home_active'>All</p>
                <p className='home_common'>Sokoloan</p>
                <p className='home_common'>Eloan</p>
                <p className='home_common'>Getcash</p>
              </div>
              <div className='home_mid'>
                <div className='home_mid_top'>
                <div className="commom_top" style={{alignItems:'center'}}>
                <span style={{width:100}}>Create time:</span>
                <RangePicker
                ranges={{
                Today: [moment(), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                }}
                showTime
                format={dateFormat}
                onChange={this.rangeOnChange}
                value={this.state.from===undefined||this.state.to===undefined||this.state.from===""||this.state.to===""?null:[moment(this.state.from, dateFormat), moment(this.state.to, dateFormat)]}
                />
                <Button type="primary" style={{width:170,marginLeft:'3%',marginRight:50,height:40,border:'none'}} className="common_color" onClick={this.search.bind(this)}>Search</Button>
                </div>
                </div>
                <div className='home_mid_mid'>
                    <div className='home_mid_common'>
                        <p>{this.state.fstp}</p>
                        <span>Register num</span>
                    </div>
                    <div  className='home_mid_common'>
                        <p>{this.state.scdp}</p>
                        <span>Pay num</span>
                    </div>
                    <div className='home_mid_common'>
                         <p>{this.state.thdp}</p>
                        <span>Due num</span>
                    </div>
                    <div className='home_mid_common'>
                         <p>{this.state.foup}</p>
                        <span>Repay num</span>
                    </div>
                    <div className='home_mid_common'>
                         <p>₦{this.state.fivp}</p>
                        <span>Pay amount</span>
                    </div>
                    <div className='home_mid_common'>
                         <p>₦{this.state.sixp}</p>
                        <span>Due amount</span>
                    </div>
                    <div className='home_mid_common'>
                         <p>₦{this.state.sevp}</p>
                        <span>Repay amount</span>
                    </div>
                </div>
              </div>
              <div className='home_bot'>
              <div >
                    <div className="collection_mid_top_right">
                        <div className="right_btn">
                            <span className={`both_button ${this.state.isToggleOn ? 'active' : ''}`}  onClick={this.whichActive}>Total</span>
                            <span className={`both_button ${this.state.isToggleOff ? 'active' : ''}`} onClick={this.whichotherActive}>Today</span>
                        </div>
                    </div>
                </div>
                <div className='home_out'>
                <div className='home_mid_mid' style={{paddingBottom:15}}>
                    <div className='home_mid_common'>
                        <p>{this.state.fstp3}</p>
                        <span>Register num</span>
                    </div>
                    <div  className='home_mid_common'>
                        <p>{this.state.scdp3}</p>
                        <span>Pay num</span>
                    </div>
                    <div className='home_mid_common'>
                         <p>{this.state.thdp3}</p>
                        <span>Due num</span>
                    </div>
                    <div className='home_mid_common'>
                         <p>{this.state.foup3}</p>
                        <span>Repay num</span>
                    </div>
                    <div className='home_mid_common'>
                         <p>₦{this.state.fivp3}</p>
                        <span>Pay amount</span>
                    </div>
                    <div className='home_mid_common'>
                         <p>₦{this.state.sixp3}</p>
                        <span>Due amount</span>
                    </div>
                    <div className='home_mid_common'>
                         <p>₦{this.state.sevp3}</p>
                        <span>Repay amount</span>
                    </div>
                </div>
                </div>
              <div className='home_bot_bot'>
                
                  <div className='home_bot_bot_left'>
                  <div className="collection_top_right" style={{width:'100%'}}>
                    <div className="collection_top_right_bottom"  style={{marginTop:'7%'}}>
                    <div className="collection_top_right_bot_right">
                        <div className='circlr_img' ><img src={right}/></div>
                        </div>
                        <div className="collection_top_right_bot_left home_out_div">
                            <div className="collection_top_right_bot_left_one">
                                <span className="circle"></span>
                                <span className="right_span collection_adjust">S1</span>
                                <span className="span_number">1-7 Days</span>
                            </div>
                            <div className="collection_top_right_bot_left_two">
                                <span className="circle" style={{border:'4px solid rgba(69,186,163,1)'}}></span>
                                <span className="right_span collection_adjust" >S2</span>
                                <span className="span_number">8-15 Days</span>
                            </div>
                            <div className="collection_top_right_bot_left_three">
                                <span className="circle"  style={{border:'4px solid rgba(114,211,193,1)'}}></span>
                                <span className="right_span collection_adjust" >M1</span>
                                <span className="span_number">16-30 Days</span>
                            </div>
                        </div>
                        <div className="collection_top_right_bot_left home_out_div" style={{marginRight:50}}>
                            <div className="collection_top_right_bot_left_one">
                                <span className="circle"></span>
                                <span className="right_span collection_adjust" >M2</span>
                                <span className="span_number">31-60 Days</span>
                            </div>
                            <div className="collection_top_right_bot_left_two">
                                <span className="circle" style={{border:'4px solid rgba(69,186,163,1)'}}></span>
                                <span className="right_span collection_adjust" >M3</span>
                                <span className="span_number">61-90 Days</span>
                            </div>
                            <div className="collection_top_right_bot_left_three">
                                <span className="circle"  style={{border:'4px solid rgba(114,211,193,1)'}}></span>
                                <span className="right_span collection_adjust" >M4</span>
                                <span className="span_number">90+ Days</span>
                            </div>
                        </div>
                       
                    </div>
                </div>
                  </div>


                  <div className='home_bot_bot_right'>
                      <div className='bot_right_top'>
                          <span className='bot_right_top_leftspan'>OVERDUE</span>
                          {/* <span>= {this.state.overdue_amount}</span> */}
                          <span className='bot_right_top_rightspan'>= {this.state.alloverdue}</span>
                      </div>
                  <div className="bot_right_bot">
                    <div className="collection_mid_bot_div" id='home_special_div'>
                        <p className="p_white">{this.state.s1total}</p>
                        <span style={{color:'#fff'}}>S1 {this.state.total_today}</span>
                    </div>
                    <div className="collection_mid_bot_div" id='home_special_div' style={{background:'rgba(69,186,163,1)'}}>
                        <p className="p_white">{this.state.s2total}</p>
                        <span style={{color:'#fff'}}>S2 {this.state.total_today}</span>
                    </div>
                    <div className="collection_mid_bot_div" id='home_special_div'style={{background:'rgba(114,211,193,1)'}}>
                        <p className="p_white">{this.state.m1total}</p>
                        <span style={{color:'#fff'}}>M1 {this.state.total_today}</span>
                    </div>
                    <div className="collection_mid_bot_div" id='home_special_div'style={{background:'rgba(149,239,222,1)',marginTop:10}}>
                        <p className="p_number">{this.state.m2total}</p>
                        <span>M2 {this.state.total_today}</span>
                    </div>
                    <div className="collection_mid_bot_div" id='home_special_div'style={{background:'rgba(201,242,234,1)',marginTop:10}}>
                        <p className="p_number">{this.state.m3total}</p>
                        <span>M3 {this.state.total_today}</span>
                    </div>
                    <div className="collection_mid_bot_div" id='home_special_div'style={{background:'rgba(225,249,244,1)',marginTop:10}}>
                        <p className="p_number">{this.state.m4total}</p>
                        <span>M4 {this.state.total_today}</span>
                    </div>
                </div>
                  </div>
              </div>
              </div>
          </div>
      )
  }  
}
export default withRouter(Home)