// 使用自己封装的api
import request from './request'
export function getsmsData(data) { //短信设置页数据
    return request({
      url: '/sms/marketing/config/list',
      method: 'post',
      data
    })
}
export function editsmsData(data) { //短信设置页数据
    return request({
      url: '/sms/marketing/config/save',
      method: 'post',
      data
    })
}
export function rollOverData(data) { //展期页数据
    return request({
      url: '/sysConfig/listExtensionConfig',
      method: 'get',
      data
    })
}
export function rollOverStatusChange(data) { //展期页状态切换
    return request({
      url: '/sysConfig/updateExtensionConfig',
      method: 'post',
      data
    })
}
export function rollOverformInput(data) { //展期页表单提交
    return request({
      url: '/sysConfig/updateById',
      method: 'post',
      data
    })
}
export function collectSmsData(data) { //催收短信数据
    return request({
      url: '/urgeSMS/configList',
      method: 'post',
      data
    })
}
export function editSmsData(data) { //催收短信编辑
    return request({
      url: '/urgeSMS/save',
      method: 'post',
      data
    })
}
export function getTargetData(data) { //催收目标配置页数据
    return request({
      url: '/sysConfig/listKpi',
      method: 'get',
      data
    })
}
export function collectTargetForm(data) { //催收目标配置页表单
    return request({
      url: '/sysConfig/updateById',
      method: 'post',
      data
    })
}
export function getCollectSettingData(data) { //获取放款页配置数据
    return request({
      url: '/sysConfig/listByKeyType',
      method: 'get',
      data
    })
}

export function getAdInfo(data) { //获取AD信息
  return request({
    url: '/sysAd/getAdInfo ',
    method: 'get',
    data
  })
}

export function addAdInfo(data) { //添加/编辑AD信息
  return request({
    url: '/sysAd/addAdInfo',
    method: 'post',
    params: data
  })
}
